<div *ngIf="alertMessageService && alertMessageService.isShown">
    <div
        class="bg-alert-message"
        [ngClass]="{
            error_message: alertMessageService && alertMessageService.isError,
            info_message: alertMessageService && alertMessageService.isInfo
        }">
        <div class="success" *ngIf="!(alertMessageService && alertMessageService.isError)">
            <!-- <i class="theme-success-i"></i> -->
            <div class="{{ alertMessageService.iconClass }}"></div>
        </div>
        <div class="failure" *ngIf="alertMessageService && alertMessageService.isError">
            <!-- <i class="theme-close-i"></i> -->
            <div class="{{ alertMessageService.iconClass }}"></div>
        </div>
        <div class="info" *ngIf="alertMessageService && alertMessageService.isInfo">
            <!-- <i class="theme-close-i"></i> -->
            <div class="{{ alertMessageService.iconClass }}"></div>
        </div>
        <span class="message">
            {{ alertMessageService.message }}
        </span>
        <!--<span class="ui-icon ui-icon-size-lg ui-close bg-ui-close-primary theme-ex" (click)='hide()'></span>-->
    </div>
</div>
