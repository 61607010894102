import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { BingoCoreModule } from '@frontend/bingo/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';

import { BingoTournamentIconModule } from '../bingo-tournament-icon/bingo-tournament-icon.module';
import { SharedModule } from '../shared/shared.module';
import { BingoSlotRacesResultsComponent } from './bingo-results.component';

@NgModule({
    imports: [
        CommonModule,
        TrustAsHtmlPipe,
        MatDialogModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        SharedModule,
        BingoTournamentIconModule,
        BingoCoreModule,
        MatMomentDateModule,
    ],
    declarations: [BingoSlotRacesResultsComponent],
    exports: [BingoSlotRacesResultsComponent],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
    ],
})
export class BingoSlotRacesResultsModule {
    public static forRoot(): ModuleWithProviders<BingoSlotRacesResultsModule> {
        return {
            ngModule: BingoSlotRacesResultsModule,
        };
    }
}
