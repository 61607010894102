import { Component, Input, OnInit } from '@angular/core';

import { BingoManager, BingoTournamentsService } from '@frontend/bingo/core';
import { UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { filter } from 'rxjs';

@Component({
    selector: 'bgl-slot-races-wrapper',
    templateUrl: 'bingo-slot-races-wrapper.component.html',
})
export class BingoSlotRacesWrapperComponent implements OnInit {
    @Input() component: string;
    @Input() fromBingoHomepage: boolean;

    constructor(
        private slotracesService: BingoTournamentsService,
        private bingoManager: BingoManager,
        private user: UserService,
    ) {}

    ngOnInit() {
        this.bingoManager.setCurrentWidth();
        this.slotracesService.initSlotRaces(true, false, this.fromBingoHomepage);
        this.user.events.pipe(filter((e: any) => e instanceof UserLoginEvent)).subscribe(() => {
            this.slotracesService.initSlotRaces(true, false, this.fromBingoHomepage);
        });
    }
}
