<div *ngIf="data.isMultiPriceComponent || data.currentGame.isMultistake" class="teamSelectModal-new fab fluffyInfo">
    <div class="fluffy-title-block">
        <i class="theme-multistake-feature"></i>
        <span class="fluffy-title">{{ chartText }}</span>
        <i class="theme-ex fluffy-close" (click)="toggleSelectPriceInfo()"></i>
    </div>
    <div class="multi_price_prize_table">
        <table>
            <caption>
                &nbsp;
            </caption>
            <th></th>
            <tr class="t-head">
                <td>{{ data.multistakeContent?.Stake }}</td>
                <td *ngFor="let key of data.currentGame.keys">{{ key }}</td>
                <td>{{ data.multistakeContent?.Total }}</td>
            </tr>
            <tr
                *ngFor="let winning of data.currentGame?.game_context?.VariableCardStake?.winning_amount; let row_no = index"
                [ngClass]="selectedPrice == row_no ? 'active-row' : ''">
                <td>{{ winning.price | currency: data.currentGame.currency }}</td>
                <td *ngFor="let key of data.currentGame.keys">
                    {{
                        winning.per_pool_win_amount_split[key][data.currentGame.game_context.VariableCardStake.ticket_currency_key]
                            | currency: data.currentGame.currency
                    }}
                </td>
                <td>
                    {{
                        winning.winning_amount[data.currentGame.game_context.VariableCardStake.ticket_currency_key]
                            | currency: data.currentGame.currency
                    }}
                </td>
            </tr>
        </table>
    </div>
    <div class="content-container">
        <h4>{{ data.multistakeContent?.ParaText1 }}</h4>
        <p>{{ data.multistakeContent?.ParaText2 }} <br />{{ data.multistakeContent?.ParaText3 }}</p>
    </div>
</div>
<div *ngIf="!data.isMultiPriceComponent && data.currentGame.isSlingoMultiStake" class="teamSelectModal-new fab fluffyInfo">
    <div class="fluffy-title-block">
        <i class="theme-multistake-feature"></i>
        <span class="fluffy-title">{{ data.multistakeContent?.ChartText }}</span>
        <i class="theme-ex fluffy-close" (click)="toggleSelectPriceInfo()"></i>
    </div>
    <div class="multi_price_prize_table">
        <table>
            <caption>
                &nbsp;
            </caption>
            <th></th>
            <tr class="t-head">
                <td>{{ data.multistakeContent?.Stake }}</td>
                <td *ngFor="let key of data.currentGame.keys">{{ key }}</td>
                <td>{{ data.multistakeContent?.Total }}</td>
            </tr>
            <tr
                *ngFor="let priceList of data.currentGame?.game_context?.SlingoMultiStake?.price_points_config_in_player_currency; let row_no = index"
                [ngClass]="data.currentGame.selected_price == row_no ? 'active-row' : ''">
                <td>{{ priceList.price | currency: data.currentGame.currency }}</td>
                <td>
                    {{ data.currentGame.winning_amount.Cash | currency: data.currentGame.currency }}
                </td>
                <td>
                    {{ data.currentGame.winning_amount.Cash | currency: data.currentGame.currency }}
                </td>
            </tr>
        </table>
    </div>
    <div class="content-container">
        <h4>{{ data.multistakeContent?.SlingoParaText1 }}</h4>
        <p>{{ data.currentGame?.slingoSecondParaText }} <br />{{ data.multistakeContent?.SlingoParaText3 }}</p>
    </div>
</div>
