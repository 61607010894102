import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

import { CasinoManager, ConfigProviderService, Race, RaceRuleConfig, SitecoreContent, SlotRacesService } from '@casinocore/platform/core';
import { DeviceService } from '@frontend/vanilla/core';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { Subscription } from 'rxjs';
import { Swiper } from 'swiper/types';
import { SwiperOptions } from 'swiper/types/swiper-options';

@Component({
    selector: 'cc-slot-races-points',
    templateUrl: './points.component.html',
    standalone: true,
    imports: [NgIf, NgClass, MatExpansionModule, MatIconModule, NgFor, NgStyle, SwiperComponent],
})
export class SlotRacesPointsComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() showHeader: boolean;
    @Input() isCollapsible: boolean;
    @Input() isExpanded: boolean;
    @Input() race: Race;
    isVisible: boolean;
    racedata: boolean;
    hidepoint: boolean;
    panelOpenState: boolean;
    pointsdatalength: number;
    doExpanded: boolean;
    pointsgredientbg: string;
    pointsData: any;
    pointsContent: RaceRuleConfig[];
    sitecoreData: SitecoreContent;
    contentSubscription: any;
    isExpansionDisabled: string = '';
    showPointsV2: boolean;
    showCarousel: boolean;
    resizeSubscription: Subscription;
    pointsContentColor: string;

    swiperOptions: SwiperOptions = {
        speed: 400,
        autoHeight: true,
        spaceBetween: 1,
        slidesPerView: 2,
        pagination: true,
        navigation: true,
        keyboard: true,
        on: {
            init: (swiper) => this.onCarouselMove(swiper),
            slideChange: (swiper) => this.onCarouselMove(swiper),
        },
    };

    constructor(
        private slotRacesService: SlotRacesService,
        private casinoManager: CasinoManager,
        private configproviderservice: ConfigProviderService,
        private deviceService: DeviceService,
        private destroyRef: DestroyRef,
    ) {}

    ngOnInit() {
        const config = this.configproviderservice.provideSlotRacesConfig();
        this.hidepoint = config.hideGenericPoints;
        this.showCarouselArrows();
        this.resizeSubscription = this.casinoManager.reSizeObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((width: any) => {
            if (width) {
                this.showCarouselArrows();
            }
        });
        this.showPointsV2 = this.race && this.race.slotRaceRuleConfigV2 && this.race.slotRaceRuleConfigV2.length > 0;
        this.doExpanded = this.isExpanded;
        this.isVisible = false;
        this.changeCollapseState();
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.sitecoreData = content;
            this.pointsContent = JSON.parse(JSON.stringify(content.raceRuleConfigs));
            this.bindRuleContent();
            this.setSiteCoreStyles();
        });
        this.ExpansionState();
        this.pointsContentColor = this.sitecoreData.textTranslations.versionedList?.PointsContentColor;
    }

    ngAfterViewInit() {
        this.setSiteCoreStyles();
    }

    setSiteCoreStyles() {
        if (this.sitecoreData && this.sitecoreData.textTranslations && this.sitecoreData.textTranslations.sharedList) {
            if (document.querySelector('.casino-point-carousel') as HTMLElement) {
                (document.querySelector('.casino-point-carousel') as HTMLElement).style.cssText =
                    this.sitecoreData.textTranslations.sharedList.pointsV2CSSText;
            }
        }
    }

    showCarouselArrows() {
        this.showCarousel = this.casinoManager.isTouch()
            ? this.deviceService.windowWidth() >= 768
                ? !(window.matchMedia('(orientation: landscape)').matches && this.deviceService.windowWidth() < 900)
                : false
            : true;
    }

    bindRuleContent() {
        if (this.race && this.race.slotRaceRuleConfigV2 && this.race.slotRaceRuleConfigV2.length) {
            this.pointsData = this.race.slotRaceRuleConfigV2;
            this.pointsData.forEach((apiRaceConfig: any) => {
                const matchedcontent = this.pointsContent.find(
                    (p) => p?.metadata?.id?.itemName?.toLowerCase() === apiRaceConfig?.ruleType?.toLowerCase(),
                );
                apiRaceConfig.pointValue = apiRaceConfig.points;
                apiRaceConfig.pointsImage = matchedcontent?.pointsImage;
                apiRaceConfig.pointsText = matchedcontent?.pointsText?.replace('{points}', '');
                apiRaceConfig.pointsDescription = matchedcontent?.pointsDescription?.replace('{ruleConfig}', String(apiRaceConfig.ruleConfig));
                apiRaceConfig.ruleTitle = matchedcontent?.ruleConfigTitleV2;
                apiRaceConfig.ruleDescription = matchedcontent?.ruleConfigDescriptionV2;
                this.setPointsV2DescriptionTexts(apiRaceConfig);
            });
        } else if (this.race && this.race.slotRaceRuleConfig) {
            this.pointsData = this.race.slotRaceRuleConfig;
            this.pointsdatalength = this.pointsData.length;
            this.pointsData.forEach((apiRaceConfig: any) => {
                const matchedcontent = this.pointsContent.find(
                    (p) => p?.metadata?.id?.itemName?.toLowerCase() === apiRaceConfig?.ruleType?.toLowerCase(),
                );
                apiRaceConfig.pointValue = apiRaceConfig.points;
                apiRaceConfig.pointsImage = matchedcontent?.pointsImage;
                apiRaceConfig.pointsText = matchedcontent?.pointsText?.replace('{points}', '');
                apiRaceConfig.pointsDescription = matchedcontent?.pointsDescription?.replace('{ruleConfig}', String(apiRaceConfig.ruleConfig));
            });
        } else {
            this.pointsData = this.pointsContent;
            this.pointsdatalength = this.pointsData.length;
            this.pointsData.forEach((sitecoreRaceConfig: any) => {
                sitecoreRaceConfig.pointsText = sitecoreRaceConfig.pointsText.replace('{points}', '');
                sitecoreRaceConfig.pointsDescription = sitecoreRaceConfig.pointsDescription.replace(
                    '{ruleConfig}',
                    String(sitecoreRaceConfig.ruleConfigValue),
                );
            });
        }
    }

    private setPointsV2DescriptionTexts(ruleConfig: any) {
        switch (ruleConfig?.ruleType?.toLowerCase()) {
            case 'consecutive_wins': {
                ruleConfig.descSingle = this.sitecoreData.textTranslations.versionedList.WinSingle;
                ruleConfig.descMult = this.sitecoreData.textTranslations.versionedList.WinMultiple;
                break;
            }
            case 'consecutive_losses': {
                ruleConfig.descSingle = this.sitecoreData.textTranslations.versionedList.LossSingle;
                ruleConfig.descMult = this.sitecoreData.textTranslations.versionedList.LossMultiple;
                break;
            }
            case 'win_x_times_on_bet': {
                ruleConfig.descSingle = this.sitecoreData.textTranslations.versionedList.StakeWon;
                ruleConfig.descMult = this.sitecoreData.textTranslations.versionedList.StakeWon;
                break;
            }
        }
        if (ruleConfig && ruleConfig.slabList && ruleConfig.slabList.length > 4) {
            ruleConfig.pointSingle = this.sitecoreData.textTranslations.versionedList.PtSingle;
            ruleConfig.pointMult = this.sitecoreData.textTranslations.versionedList.PtMultiple;
        } else {
            ruleConfig.pointSingle = this.sitecoreData.textTranslations.versionedList.PointSingle;
            ruleConfig.pointMult = this.sitecoreData.textTranslations.versionedList.PointMultiple;
        }
    }

    onCarouselMove(swiper: Swiper): void {
        const firstVisibleSlide = swiper.activeIndex;
        const slides = swiper.slides;
        const lastVisibleSlide = swiper.activeIndex + slides.length - 1;
        slides.forEach((slide, i) => {
            slide.style.opacity = i >= firstVisibleSlide && i <= lastVisibleSlide ? '1' : '0';
        });
    }

    showTnC() {
        const tnc = document.getElementById('csSlotRaceTnC');
        if (tnc) {
            tnc.scrollIntoView();
        }
    }

    ngOnDestroy() {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
    }

    changeCollapseState() {
        this.isVisible = !this.isVisible;
    }

    ExpansionState() {
        if (!this.isCollapsible) {
            this.isExpansionDisabled = 'disabled-pointer';
        }
    }
}
