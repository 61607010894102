<div class="casino-coineconmy-overlay" *ngIf="showConfirmPopup">
    <div class="purchase-popup">
        <h2>{{ messages?.Purchase }}</h2>
        <a class="close theme-close-small" (click)="closeDialog('close')"></a>
        <div class="content">{{ messages?.Play }}</div>
        <div class="btn-section">
            <!-- <button class="btn btn-secondary btn-md" (click)="closeDialog('cancel')">{{ messages?.Cancel }}</button>
            <button class="btn btn-primary btn-md" (click)="onConfirmClick($event)">{{ messages?.Confrim }}</button> -->
            <cs-slotrace-cta
                [isLegacy]="isLegacy"
                [kind]="'secondary'"
                [size]="'medium'"
                [csDecoratorClass]="'btn-secondary btn-md'"
                [cta_text]="messages?.Cancel"
                (click)="closeDialog('cancel')"></cs-slotrace-cta>
            <cs-slotrace-cta
                [isLegacy]="isLegacy"
                [kind]="'primary'"
                [size]="'medium'"
                [csDecoratorClass]="'btn-md'"
                [cta_text]="messages?.Confrim"
                (click)="onConfirmClick($event)"></cs-slotrace-cta>
        </div>
    </div>
</div>
<div class="casino-lobby-container-main" *ngIf="!showConfirmPopup">
    <div class="casino-slotraces-notification-toster-container-coin">
        <div class="casino-slotraces-notification-toster-body">
            <div class="sry">{{ messages?.InSufficientBalToasterMsg }}</div>
            <span class="mes">{{ messages?.InsufficientCoinBalance }}</span>
            <!-- <div class="btn btn-md btn-primary cls" (click)="closeDialog('toasterClose')">{{ messages?.CloseBtnText }}</div> -->
            <cs-slotrace-cta
                [isLegacy]="isLegacy"
                [kind]="'primary'"
                [size]="'medium'"
                [cta_text]="messages?.CloseBtnText"
                [csDecoratorClass]="'btn-md cls'"
                (click)="closeDialog('toasterClose')">
            </cs-slotrace-cta>
        </div>
    </div>
</div>
