<div
    class="casino-news-feed-item-wrapper"
    (click)="itemCliked()"
    [ngStyle]="{ 'border-color': attributes.NewsFeedBoxOuterLineColor, 'background-color': attributes.NewsFeedItemBgcolor }">
    <div class="casino-news-feed-icon-v2 casino-news-valign">
        <img [src]="gameIcon" />
    </div>
    <div class="casino-news-feed-content-v2 casino-news-valign">
        <div class="news-feed-title-v2" [ngStyle]="{ color: attributes.HeaderTextColor }">{{ newsFeedItem.header }}</div>
        <div class="news-feed-name-v2" [ngStyle]="{ color: attributes.SubHeaderTextColor }">{{ gameName }}</div>
    </div>
</div>
