import { Injectable } from '@angular/core';

import { ApiBase, ApiServiceFactory, ClientConfigProductName, ProductService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    useFactory: apiServiceFactory,
    deps: [ApiServiceFactory, ProductService],
})
export class PlatformBingoApiService extends ApiBase {}

export function apiServiceFactory(apiServiceFactory: ApiServiceFactory, productService: ProductService) {
    return apiServiceFactory.createForProduct(PlatformBingoApiService, {
        product: ClientConfigProductName.BINGO,
        area: 'bingo',
        forwardProductApiRequestHeader: productService.getMetadata(ClientConfigProductName.BINGO).isEnabled,
    });
}
