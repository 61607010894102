<div
    *ngIf="race"
    class="casino-sr-card-widget"
    [ngClass]="[cardbackgroundimage != '' ? 'no-blur' : '', expandCard ? '' : 'collapse', race && race.beatBanker ? 'casino-beat-the-banker' : '']"
    [ngStyle]="
        showimage
            ? { color: cardTextColor }
            : promobackgroundcolor != ''
              ? { color: cardTextColor, background: promobackgroundcolor }
              : { color: cardTextColor, background: cardbackgroundcolor }
    ">
    <div class="theme-info" *ngIf="showRaceInfo" (click)="emitRaceInfo()"></div>
    <img *ngIf="showimage" src="{{ backgroundImageSrc }}" alt="game-name1" />
    <div *ngIf="showCardMenu" class="slot-list">
        <div class="list-item time">{{ race.startTimeAMPM }}</div>
        <div class="list-item red-dot" [ngClass]="first ? (last ? 'red-dot-first red-dot-last' : 'red-dot-first') : last ? 'red-dot-last' : ''"></div>
        <div class="list-item game-name">{{ cardTitle }}</div>
        <div class="list-item theme-minus" (click)="hideRaceCard()"></div>
    </div>

    <div class="sr-card-game-title" *ngIf="race.isLive">
        <div class="race-title">{{ cardTitle }}</div>
        <div class="expandcollaps" [ngClass]="{ expanded: expandCard, collapsed: !expandCard }" (click)="addOrremoveCollapseClass()"></div>
    </div>
    <div
        class="sr-card-upcoming-game-title"
        *ngIf="(!race.isLive && (!showRaceInfo || fromPickGameOverlay)) || (!race.isLive && showPlaceholderCard)">
        {{ cardTitle }}
    </div>
    <div class="sr-card-container-top">
        <div class="btn-section expandshow" *ngIf="enableOptin || race.isLive || (race.subType && race.subType.toLowerCase() != 'free_play_tech')">
            <!-- <button [disabled]="
                    fromPickGameOverlay
                        ? race.ctaList.indexOf('OPTIN') > -1
                            ? false
                            : true
                        : race.subType &&
                            race.subType.toLowerCase() === 'free_play_tech' &&
                            race.optinLimit !== 0 &&
                            playerCount === race.optinLimit &&
                            race.ctaList.indexOf('OPTIN') > -1
                          ? true
                          : race.isLive
                            ? race.ctaList.indexOf('OPTIN_EXPIRED') > -1 ||
                              (race.subType &&
                                  (race.subType.toLowerCase() === 'free_to_play' || race.subType.toLowerCase() === 'free_play_tech') &&
                                  spinCount <= 0)
                                ? true
                                : false
                            : race.ctaList.indexOf('OPTIN') > -1
                              ? false
                              : true
                " class="btn btn-primary sr-playbtn" (click)="ctaAction()">
                {{
                race.ctaList.length === 0
                ? playNowText
                : race?.isOptinWithCoins
                ? messages.OptinWithText
                : race.subType &&
                race.subType.toLowerCase() === 'free_play_tech' &&
                race.optinLimit !== 0 &&
                playerCount === race.optinLimit
                ? messages.OptinLimitReached
                : race.subType && (race.subType.toLowerCase() === 'free_to_play' || race.subType.toLowerCase() ===
                'free_play_tech')
                ? messages.Optin4Free
                : messages.Optin
                }}
                <ng-container
                    *ngIf="race?.isOptinWithCoins && (race.ctaList.indexOf('OPTIN') > -1 || race.ctaList.indexOf('OPTIN_EXPIRED') > -1)">
                    <span class="sr-card-total-coin">
                        <span>{{ race?.coinsForOptin }}</span>
                        <img class="coin-img" [src]="coinImg" alt="Coin Image" />
                    </span>
                </ng-container>
            </button> -->
            <cs-slotrace-cta
                [isLegacy]="isLegacy"
                [kind]="'primary'"
                [casinoDsClass]="'cs-srPlayBtn'"
                [disabled]="
                    fromPickGameOverlay
                        ? race.ctaList.indexOf('OPTIN') > -1
                            ? false
                            : true
                        : race.subType &&
                            race.subType.toLowerCase() === 'free_play_tech' &&
                            race.optinLimit !== 0 &&
                            playerCount === race.optinLimit &&
                            race.ctaList.indexOf('OPTIN') > -1
                          ? true
                          : race.isLive
                            ? race.ctaList.indexOf('OPTIN_EXPIRED') > -1 ||
                              (race.subType &&
                                  (race.subType.toLowerCase() === 'free_to_play' || race.subType.toLowerCase() === 'free_play_tech') &&
                                  spinCount <= 0)
                                ? true
                                : false
                            : race.ctaList.indexOf('OPTIN') > -1
                              ? false
                              : true
                "
                [cta_text]="prepareCardCtaText()[0]"
                [coinsForOptin]="prepareCardCtaText()[1]"
                [csDecoratorClass]="'sr-playbtn'"
                (click)="ctaAction()"
                [imgSrc]="coinImg">
            </cs-slotrace-cta>
        </div>
        <div class="sr-card-game-icon">
            <cc-slot-races-icon
                (openPickGameOverlay)="emitPickGameOverlay()"
                (launchSelectedGame)="launchGame()"
                [category]="category"
                [promoIcon]="race?.content?.promotion?.promoIcon"
                [lobbyType]="lobbyType"
                [spinProgress]="race?.spinProgress"
                [gameVariantList]="race.gameVariantList"
                [position]="position"
                [source]="containerLocation"
                [cta]="race.ctaList"
                [isClickable]="!fromPickGameOverlay"
                [isLive]="race.isLive"
                [isFreeTourney]="race.subType && (race.subType.toLowerCase() == 'free_to_play' || race.subType.toLowerCase() == 'free_play_tech')">
            </cc-slot-races-icon>
        </div>
        <div class="sr-card-total-prize" [ngClass]="{ 'sr-max-bet': displayMaxBet }">
            <div class="sr-card-total-prize-box">
                <span class="total-prize">{{ messages.TotalPrizes }}</span>
                <i class="sr-prize-icon" [ngClass]="content?.textTranslations?.sharedList[(race?.additionalParams | prize: true)]"></i>
                <span class="sr-card-total-coin" *ngIf="race?.additionalParams?.awardType?.toLowerCase() == 'coins'">
                    <img class="coin-img" [src]="coinImg" alt="Coin Image" />
                </span>
                <span class="sr-card-total-prize-value">
                    {{ race?.additionalParams | prize: false }}
                </span>
                <ng-container *ngIf="coinObj?.isDisplayCoin">
                    <span class="theme-plus"></span>
                    <span class="sr-card-total-coin">
                        <img class="coin-img" [src]="coinObj?.coinSrc" alt="Coin Image" />
                    </span>
                </ng-container>
            </div>

            <div class="sr-card-counter-bet-box" [ngClass]="{ 'sr-card-counter-bet-box-max': displayMaxBet }">
                <div class="time-section">
                    <span class="ends">{{ race.isLive ? messages.EndsIn : messages.StartsIn }}</span>
                    <div class="sr-card-counter" *ngIf="timeSpan < 86400000 || !race.isLive; else elseblock">
                        {{ timeSpan | timeformat }}
                    </div>
                    <ng-template #elseblock>
                        <div class="sr-card-counter">
                            {{ Days }} <span>{{ messages.Days }}</span>
                        </div>
                    </ng-template>
                </div>
                <span
                    *ngIf="
                        !race.subType ||
                        !(race.subType && (race.subType.toLowerCase() === 'free_to_play' || race.subType.toLowerCase() === 'free_play_tech'))
                    ">
                    <div class="bet-section">
                        <span class="min-bet" *ngIf="!enableMaxBet">{{ messages.MinimumBet }}</span>
                        <div class="src-card-min-bet" *ngIf="!enableMaxBet">
                            {{ race?.criteriaDtls?.total | currency: currencyCode : 'symbol-narrow' : '1.2-2' }}
                        </div>
                        <span class="min-bet" *ngIf="enableMaxBet">{{ messages.MinBet }}</span>
                        <div class="src-card-min-bet" *ngIf="enableMaxBet">
                            {{ race?.criteriaDtls?.min | currency: currencyCode : 'symbol-narrow' : '1.2-2' }}
                        </div>
                    </div>
                    <div class="bet-section" *ngIf="displayMaxBet">
                        <span class="min-bet">{{ messages.MaxBet }}</span>
                        <div class="src-card-min-bet">
                            {{ race?.criteriaDtls?.max | currency: currencyCode : 'symbol-narrow' : '1.2-2' }}
                        </div>
                    </div>
                </span>
                <span *ngIf="(race.subType && race.subType.toLowerCase() === 'free_to_play') || race.subType.toLowerCase() === 'free_play_tech'">
                    <div class="bet-section">
                        <span class="min-bet">{{ messages.MinBet }}</span>
                        <div class="src-card-min-bet">
                            {{ messages.Free2Play }}
                        </div>
                    </div>
                </span>
            </div>
        </div>
    </div>

    <div
        *ngIf="race && race.beatBanker"
        class="casino-beat-the-banker-container"
        [ngStyle]="{ background: bottombackground }"
        [ngClass]="[isprogressBarEnabled && !(race.ctaList.indexOf('OPTIN') > -1) ? 'btb-progress-bar' : '']">
        <div class="scrore-text">{{ messages.beatBankerTitle }}</div>

        <div class="casino-beat-the-banker-progress-bar">
            <mat-slider
                *ngIf="isprogressBarEnabled && !(race.ctaList.indexOf('OPTIN') > -1 || race.ctaList.indexOf('OPTIN_EXPIRED') > -1)"
                [disabled]="true"
                [max]="minPoints"
                class="casino-beat-the-banker-progress-bar-color">
                <input matSliderThumb [value]="currentPoints" />
            </mat-slider>
        </div>
        <div
            *ngIf="isprogressBarEnabled && !(race.ctaList.indexOf('OPTIN') > -1 || race.ctaList.indexOf('OPTIN_EXPIRED') > -1)"
            class="freespins-count">
            {{ currentPoints }}<span>/{{ minPoints }} Pts</span>
        </div>
        <div
            *ngIf="!(isprogressBarEnabled && !(race.ctaList.indexOf('OPTIN') > -1 || race.ctaList.indexOf('OPTIN_EXPIRED') > -1))"
            class="freespins-count">
            <span>{{ minPoints }} Pts</span>
        </div>
    </div>
    <div class="sr-card-progress-bar" *ngIf="race.isLive">
        <mat-slider [disabled]="true" [max]="sliderMaxValue" class="sr-progress-bar-color">
            <input matSliderThumb [value]="progressValue" />
        </mat-slider>
    </div>
    <div
        class="sr-card-container-bottom"
        [ngStyle]="{ background: bottombackground }"
        [ngClass]="{ 'optin-limit': race.subType && race.subType.toLowerCase() === 'free_play_tech' }">
        <div class="pleyer-count">
            <span class="cs-sr-players">{{ messages.PlayerTitle }}</span>
            <div class="sr-card-player-count">
                {{
                    race.subType && race.subType.toLowerCase() === 'free_play_tech' && race.optinLimit !== 0
                        ? playerCount + '/' + race.optinLimit
                        : playerCount
                }}
            </div>
        </div>
        <div class="time-sec">
            <span class="duration">{{ messages.Duration }}</span>
            <div class="sr-card-race-duration" [innerHtml]="slotRaceDuration"></div>
        </div>
        <div class="spin-section">
            <span class="spin-limit">
                {{
                    race.spinProgress && race.spinProgress.current > 0
                        ? race.subType && race.subType.toLowerCase() === 'free_play_tech'
                            ? messages.ChipsLeft
                            : messages.SpinsLeft
                        : race.subType && race.subType.toLowerCase() === 'free_play_tech'
                          ? messages.ChipsLimit
                          : messages.SpinsLimit
                }}
            </span>
            <div class="sr-card-spins-count">{{ spinCount }}</div>
        </div>

        <div class="btn-section" *ngIf="enableOptin || race.isLive || (race.subType && race.subType.toLowerCase() != 'free_play_tech')">
            <!-- <button *ngIf="
                    race.isNotify || race.isLive || !enableRaceNotification || (!race.isLive && !(race.ctaList.length == 0) && enableRaceNotification)
                " [disabled]="
                    fromPickGameOverlay
                        ? race.ctaList.indexOf('OPTIN') > -1
                            ? false
                            : true
                        : race.subType &&
                            race.subType.toLowerCase() === 'free_play_tech' &&
                            race.optinLimit !== 0 &&
                            playerCount === race.optinLimit &&
                            race.ctaList.indexOf('OPTIN') > -1
                          ? true
                          : race.isLive
                            ? race.ctaList.indexOf('OPTIN_EXPIRED') > -1 ||
                              (race.subType &&
                                  (race.subType.toLowerCase() === 'free_to_play' || race.subType.toLowerCase() === 'free_play_tech') &&
                                  spinCount <= 0)
                                ? true
                                : false
                            : race.ctaList.indexOf('OPTIN') > -1
                              ? false
                              : true
                " class="btn btn-primary sr-playbtn" (click)="ctaAction()">
                {{
                race.ctaList.length === 0
                ? playNowText
                : race?.isOptinWithCoins
                ? messages.OptinWithText
                : race.subType &&
                race.subType.toLowerCase() === 'free_play_tech' &&
                race.optinLimit !== 0 &&
                playerCount === race.optinLimit
                ? messages.OptinLimitReached
                : race.subType && (race.subType.toLowerCase() === 'free_to_play' || race.subType.toLowerCase() ===
                'free_play_tech')
                ? messages.Optin4Free
                : messages.Optin
                }}
                <ng-container
                    *ngIf="race?.isOptinWithCoins && (race.ctaList.indexOf('OPTIN') > -1 || race.ctaList.indexOf('OPTIN_EXPIRED') > -1)">
                    <span class="sr-card-total-coin">
                        <span>{{ race?.coinsForOptin }}</span>
                        <img class="coin-img" [src]="coinImg" alt="Coin Image" />
                    </span>
                </ng-container>
            </button> -->
            @if (race.isNotify || race.isLive || !enableRaceNotification || (!race.isLive && !(race.ctaList.length == 0) && enableRaceNotification)) {
                <cs-slotrace-cta
                    [isLegacy]="isLegacy"
                    [kind]="'primary'"
                    [casinoDsClass]="'cs-srPlayBtn'"
                    [disabled]="
                        fromPickGameOverlay
                            ? race.ctaList.indexOf('OPTIN') > -1
                                ? false
                                : true
                            : race.subType &&
                                race.subType.toLowerCase() === 'free_play_tech' &&
                                race.optinLimit !== 0 &&
                                playerCount === race.optinLimit &&
                                race.ctaList.indexOf('OPTIN') > -1
                              ? true
                              : race.isLive
                                ? race.ctaList.indexOf('OPTIN_EXPIRED') > -1 ||
                                  (race.subType &&
                                      (race.subType.toLowerCase() === 'free_to_play' || race.subType.toLowerCase() === 'free_play_tech') &&
                                      spinCount <= 0)
                                    ? true
                                    : false
                                : race.ctaList.indexOf('OPTIN') > -1
                                  ? false
                                  : true
                    "
                    [cta_text]="prepareCardCtaText()[0]"
                    [coinsForOptin]="prepareCardCtaText()[1]"
                    [csDecoratorClass]="'sr-playbtn'"
                    (click)="ctaAction()"
                    [imgSrc]="coinImg">
                </cs-slotrace-cta>
            }
        </div>

        <div
            class="btn btn-primary notification-btn"
            *ngIf="!race.isNotify && enableRaceNotification && !race.isLive && !(race.ctaList.indexOf('OPTIN') > -1)"
            [ngClass]="[race.notificationStatus ? slotRacesSharedList.notificationBellOnIcon : slotRacesSharedList.notificationBellOffIcon]"
            (click)="onNotificationClick(!race.notificationStatus)"></div>
    </div>
</div>
