<div class="yes-no-story">
    <h2
        [ngClass]="'yesnobtnposition-' + (subTitlePosition | lowercase)"
        *ngIf="storyItem.subTitle"
        [ngStyle]="{ 'color': subTitleTxtClr, 'font-size': subTitleFontSize }"
        class="yesnopoll-title"
        [textContent]="storyItem?.subTitle"></h2>
    <img [src]="storyItem?.backgroundImage?.src" [alt]="storyItem?.backgroundImage?.alt" />
    <span
        [ngClass]="'yesnobtnposition-' + (descriptionPosition | lowercase)"
        [ngStyle]="{ 'color': descriptionClr, 'font-size': descriptionFontSize }"
        class="story-descrip"
        [textContent]="storyItem?.description"></span>
    <div
        class="mobilestories-yesnopoll-container"
        *ngIf="storyItem?.yesNoPollOption?.length"
        [class.disabled]="isDisabled"
        [ngClass]="'yesnobtnposition-' + (storyItem.actionTypePosition | lowercase)">
        <div
            class="mobilestories-options"
            [style.width]="selectedKey && item.selectedValue ? item.defaultValue + '%' : ''"
            [ngClass]="{ active: selectedKey === item.key, activeclr: item.selectedValue }"
            *ngFor="let item of storyItem.yesNoPollOption"
            [ngStyle]="getYesNoPollStyles(item.key, selectedKey)"
            (click)="optionsClicked(item.key)">
            <div [vnDynamicHtml]="item.key"></div>
            <div *ngIf="selectedKey && item.selectedValue" [textContent]="item.selectedValue + '%'" class="yesnopoll-selectedvalue"></div>
        </div>
    </div>
</div>
