<div class="casino-mobilestories-box">
    <span class="title">{{ storyItem?.description }}</span>
    <!-- <div id="emoji" class="emoji" #emoji></div> -->
    <input
        #slider
        type="range"
        class="rangeSlider"
        [disabled]="slideDisable"
        [ngStyle]="{ '--background-emoji': 'url(' + emojiPath + ')' }"
        [(ngModel)]="sliderValue"
        (change)="sliderChange($event)" />
</div>
