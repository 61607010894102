import { Injectable } from '@angular/core';

import { BingoPlatformApiService } from '@frontend/bingo/core';
import { Observable } from 'rxjs';

import { RootServicesModule } from '../shared/root-services.module';

@Injectable({
    providedIn: RootServicesModule,
})
export class GamesService {
    constructor(private api: BingoPlatformApiService) {}
    jackpots(): Observable<any> {
        return this.api.get('casino/jackpot');
    }
    recentlyplayedgames(): Observable<any> {
        return this.api.get('casino/recentlyplayedgames');
    }
    favourites(): Observable<any> {
        return this.api.get('casino/favourites');
    }
    allgames(): Observable<any> {
        return this.api.get('casino/allgames');
    }
    recommended(): Observable<any> {
        return this.api.get('casino/recommended');
    }
    allCategories(): Observable<any> {
        return this.api.get('Casino/LMTFeed');
    }
    gameLanch(id: any): Observable<any> {
        return this.api.get('gamelaunch', { isBingoGame: false, variantName: id });
    }
}
