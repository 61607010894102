<div *ngIf="race" [ngClass]="[race.beatBanker?.length > 0 ? 'bingo-bt-beat-the-banker-prize' : '']">
    <div class="bt-prizes-expandable-wrapper">
        <mat-expansion-panel
            [expanded]="isExpanded"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            hideToggle="true"
            #dropdown
            [ngClass]="isExpansionDisabled">
            <mat-expansion-panel-header>
                <mat-panel-title class="panel-title" *ngIf="showHeader">
                    <div class="bingo-bt-title-container">
                        {{ title }}
                    </div>
                </mat-panel-title>
                <mat-panel-description *ngIf="isCollapsible">
                    <mat-icon *ngIf="!panelOpenState">
                        <span class="theme-plus"></span>
                    </mat-icon>

                    <mat-icon *ngIf="panelOpenState">
                        <span class="theme-minus"></span>
                    </mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <div class="bt-prizes-container">
                    <div class="bt-bingo-beat-the-banker" *ngIf="race && race.beatBanker && race.beatBanker?.length > 0">
                        <bgl-beat-the-banker-notification
                            [prizeValueForBeatBanker]="prizeValueForBeatBanker"
                            [race]="race"
                            [coinImg]="coinImg"></bgl-beat-the-banker-notification>
                    </div>
                    <mat-grid-list
                        *ngIf="prizesOne?.length"
                        cols="3"
                        rowHeight="113px"
                        gutterSize="0"
                        class="bt-prizes-row"
                        [ngStyle]="{ width: prizesOneWidth + 'px' }">
                        <mat-grid-tile
                            *ngFor="let prize of prizesOne"
                            class="bt-prizes-content"
                            [ngClass]="{ 'prize-tile-small': !isMobile, 'prize-tile-large': isMobile }">
                            <ng-container *ngFor="let item of prize; let i = index">
                                <div *ngIf="item.awardType != 'COINS'">
                                    <div class="bt-prizes-logo" *ngIf="item.Icon" [innerHtml]="item.Icon | trustAsHtml"></div>
                                    <div class="bt-prizes-text1">{{ item.rank }}</div>
                                    <div class="bt-prizes-text2" [innerHtml]="item.Description | trustAsHtml"></div>
                                </div>
                                <div *ngIf="item.awardType == 'COINS' || item?.isBeatBanker || item?.isCoinAward" [ngClass]="item?.beatBankerClass">
                                    <div *ngIf="item.isDisplayCoin" class="bgl-coin-reward">
                                        <div
                                            class="bt-prizes-logo"
                                            *ngIf="item.Icon && item?.showCoinPrize"
                                            [innerHtml]="item.Icon | trustAsHtml"></div>
                                        <div *ngIf="item?.showCoinPrize" class="bt-prizes-text1">{{ item.rank }}</div>
                                        <div class="coins-divs">
                                            <span *ngIf="!item?.showCoinPrize" class="theme-plus"></span>
                                            <span class="bgl-card-total-coin">
                                                <img class="coin-img" [src]="coinImg" alt="Coin Image" />
                                                <span>{{ item.coinValue }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </mat-grid-tile>
                    </mat-grid-list>
                    <div style="width: 100%"></div>
                    <mat-grid-list
                        *ngIf="prizesTwo?.length"
                        cols="{{ prizesTwo?.length }}"
                        rowHeight="113px"
                        gutterSize="0"
                        class="bt-prizes-row"
                        [ngStyle]="{ width: prizesTwoWidth + 'px' }">
                        <mat-grid-tile
                            *ngFor="let prize of prizesTwo"
                            [ngClass]="{ 'prize-tile-small': !isMobile, 'prize-tile-large': isMobile }"
                            class="bt-prizes-content">
                            <ng-container *ngFor="let item of prize; let i = index">
                                <div *ngIf="item.awardType != 'COINS'">
                                    <div class="bt-prizes-logo" *ngIf="item.Icon" [innerHtml]="item.Icon | trustAsHtml"></div>
                                    <div class="bt-prizes-text1">{{ item.rank }}</div>
                                    <div class="bt-prizes-text2" [innerHtml]="item.Description | trustAsHtml"></div>
                                </div>
                                <div *ngIf="item.awardType == 'COINS' || item?.isBeatBanker || item?.isCoinAward" [ngClass]="item?.beatBankerClass">
                                    <div *ngIf="item.isDisplayCoin" class="bgl-coin-reward coin">
                                        <div
                                            class="bt-prizes-logo"
                                            *ngIf="item.Icon && item?.showCoinPrize"
                                            [innerHtml]="item.Icon | trustAsHtml"></div>
                                        <div *ngIf="item?.showCoinPrize" class="btp-rank">{{ item.rank }}</div>
                                        <div class="coins-divs">
                                            <span *ngIf="!item?.showCoinPrize" class="theme-plus"></span>
                                            <span class="bgl-card-total-coin">
                                                <img class="coin-img" [src]="coinImg" alt="Coin Image" />
                                                <span>{{ item.coinValue }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </mat-grid-tile>
                    </mat-grid-list>
                    <mat-grid-list
                        *ngIf="participateReward"
                        cols="1"
                        rowHeight="113px"
                        gutterSize="0"
                        class="bt-prizes-row"
                        [ngStyle]="{ width: participationRewardWidth + 'px' }">
                        <mat-grid-tile [ngClass]="{ 'prize-tile-small': !isMobile, 'prize-tile-large': isMobile }" class="bt-prizes-content">
                            <ng-container>
                                <div class="bt-prizes-row">
                                    <div class="bt-prizes-content">
                                        <div class="bt-prizes-logo">
                                            <img class="coin-img" [src]="coinImg" alt="Coin Image" />
                                        </div>
                                        <div class="bt-prizes-text1">
                                            {{ participateReward?.value }}
                                            {{ data?.textTranslations?.versionedList?.Coin }}
                                        </div>
                                        <div class="bt-prizes-text2">({{ data?.textTranslations?.versionedList?.ParticipateReward }})</div>
                                    </div>
                                </div>
                            </ng-container>
                        </mat-grid-tile>
                    </mat-grid-list>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
</div>

<!-- <div *ngIf="race" [ngClass]="[race.beatBanker ?'bingo-bt-beat-the-banker-prize':'']">
    <div class="bt-prizes-expandable-wrapper">
        <mat-expansion-panel [expanded]="isExpanded" (opened)="panelOpenState = true" (closed)="panelOpenState = false"
            hideToggle="true" #dropdown [ngClass]="isExpansionDisabled">
            <mat-expansion-panel-header>
                <mat-panel-title class="panel-title" *ngIf="showHeader">
                    <div class="bingo-bt-title-container">
                        {{title}}
                    </div>
                </mat-panel-title>
                <mat-panel-description *ngIf="isCollapsible">
                    <mat-icon *ngIf="!panelOpenState">
                        <span class='theme-plus'></span>
                    </mat-icon>
                    <mat-icon *ngIf="panelOpenState">
                        <span class='theme-minus'></span>
                    </mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <div class="bt-prizes-container">
                    <div class="bt-prizes-row">
                        <div class="bt-prizes-content">
                            <div class="bt-prizes-logo">
                                <img src="https://scmedia.cms.test.env.works/$-$/b522ab0f04b64143bbfa4944fda0f3f3.png"
                                    alt="" />
                            </div>
                            <div class="bt-prizes-text1">Place from 1 to 5</div>
                            <div class="bt-prizes-text2">10 Bingo Tickets</div>
                        </div>
                        <div class="bt-prizes-content">
                            <div class="bt-prizes-logo">
                                <img src="https://scmedia.cms.test.env.works/$-$/b522ab0f04b64143bbfa4944fda0f3f3.png"
                                    alt="" />
                            </div>
                            <div class="bt-prizes-text1">Place from 6 to 8</div>
                            <div class="bt-prizes-text2">8 Bingo Tickets</div>
                        </div>
                        <div class="bt-prizes-content">
                            <div class="bt-prizes-logo">
                                <img src="https://scmedia.cms.test.env.works/$-$/b522ab0f04b64143bbfa4944fda0f3f3.png"
                                    alt="" />
                            </div>
                            <div class="bt-prizes-text1">Place from 9 to 10</div>
                            <div class="bt-prizes-text2">6 Bingo Tickets</div>
                        </div>
                    </div>
                    <div class="bt-prizes-row">
                        <div class="bt-prizes-content">
                            <div class="bt-prizes-logo">
                                <img src="https://scmedia.cms.test.env.works/$-$/b522ab0f04b64143bbfa4944fda0f3f3.png"
                                    alt="" />
                            </div>
                            <div class="bt-prizes-text1">Place from 11 to 15</div>
                            <div class="bt-prizes-text2">5 Bingo Tickets</div>
                        </div>
                        <div class="bt-prizes-content">
                            <div class="bt-prizes-logo">
                                <img src="https://scmedia.cms.test.env.works/$-$/b522ab0f04b64143bbfa4944fda0f3f3.png"
                                    alt="" />
                            </div>
                            <div class="bt-prizes-text1">Place from 16 to 20</div>
                            <div class="bt-prizes-text2">4 Bingo Tickets</div>
                        </div>
                        <div class="bt-prizes-content">
                            <div class="bt-prizes-logo">
                                <img src="https://scmedia.cms.test.env.works/$-$/b522ab0f04b64143bbfa4944fda0f3f3.png"
                                    alt="" />
                            </div>
                            <div class="bt-prizes-text1">Place from 21 to 25</div>
                            <div class="bt-prizes-text2">3 Bingo Tickets</div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
</div> -->
