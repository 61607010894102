import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: playingNowClientConfigFactory,
})
@LazyClientConfig({ key: 'bgPlayingNowConfig', product: ClientConfigProductName.BINGO })
export class PlayingNowClientConfig {
    playingNowIframeUrl: string;
    ssoKey: string;
}

export function playingNowClientConfigFactory(service: LazyClientConfigService) {
    return service.get(PlayingNowClientConfig);
}
