import { Injectable } from '@angular/core';

import { BingoConfigProviderService, FeaturesClientConfig } from '@frontend/bingo/core';

@Injectable({ providedIn: 'root' })
export class PerformanceMarker {
    isMarkedtti: boolean;
    constructor(private configProviderService: BingoConfigProviderService) {}

    mark(markerName: string): void {
        const featuresConfig: FeaturesClientConfig = this.configProviderService.provideFeaturesConfig();
        if (featuresConfig.enablePerformanceMarking && !this.isMarkedtti) {
            if (markerName == PerformanceMarkerIdentifiers.TimeToInteractivity) this.isMarkedtti = true;
            // console.log(markerName);
            window.performance &&
                window.performance.getEntriesByName &&
                window.performance.getEntriesByName(markerName).length <= 0 &&
                window.performance.mark(markerName);
        }
    }

    clearMarks(): void {
        window.performance && window.performance.clearMarks && window.performance.clearMarks();
    }
}

export enum PerformanceMarkerIdentifiers {
    LobbyLoadComplete = 'Lobby_Load_Complete',
    WidgetsViewInitialized = 'Widgets_View_Initialized',
    FirstMeaningfulPaint = 'vn_fmp',
    TimeToInteractivity = 'vn_tti',
}
