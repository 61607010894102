<div class="mobile-stories-quiz" style.background-image="{{ storyItem?.backgroundImage?.src ? 'url(' + storyItem?.backgroundImage?.src + ')' : '' }}">
    <div class="question-box" [class.disabled]="isDisabled">
        <div
            class="question-header"
            [ngClass]="{ activeheader: isSelected }"
            [vnDynamicHtml]="storyItem?.question"
            [ngStyle]="isSelected && { 'background-color': questionBgClr, 'color': questionTextClr }"></div>
        <ng-container *ngIf="storyItem?.answers?.length">
            <div
                class="answer-sec"
                [ngStyle]="{ color: answersTextClr }"
                [ngClass]="{ 'active-clr-onclick': selectedOption === item.key && isSelected && !item.selectedValue && haveSelectedValue }"
                *ngFor="let item of storyItem.answers"
                (click)="onSelect(item.key)">
                <div class="answer" *ngIf="!isSelected" [vnDynamicHtml]="item.title"></div>
                <div class="mb-progress" *ngIf="isSelected">
                    <div
                        class="mb-progressValue"
                        [style.width]="item.selectedValue + '%'"
                        [ngClass]="{ active: item.selectedValue }"
                        [ngStyle]="item.selectedValue && { 'background-color': answersBgClr, 'color': answersTextClr }">
                        <div class="progressInnerLabel">
                            <div class="ans-content" [vnDynamicHtml]="item.title"></div>
                            <div class="ans-percentage" [textContent]="item.selectedValue + '%'"></div>
                        </div>
                    </div>
                    <div class="progressLabel">
                        <div class="ans-content" [vnDynamicHtml]="item.title"></div>
                        <div class="ans-percentage" *ngIf="item.selectedValue" [textContent]="item.selectedValue + '%'"></div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
