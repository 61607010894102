import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: modularCasinoClientConfigFactory,
})
@LazyClientConfig({ key: 'bgModularCasinoConfig', product: ClientConfigProductName.BINGO })
export class ModularCasinoClientConfig {
    isEnabled: boolean;
    rpFavNpmEnabled: boolean;
    enableCasinoNpmGrid: boolean;
    casinoNpmDefaultCategoryroute: string;
    casinoNpmDefaultlobbyType: string;
    disableProductLobbyDataFetch: boolean;
    lobbyTypeMap: any;
    navigationLobbyType: string;
    enableCasinoNavCategories: boolean;
    enableNewsFeedNpm: boolean;
    newsFeedWidgetContainerPath: string;
    enableCasinoNavCategoriesInGameClient: boolean;
}

export function modularCasinoClientConfigFactory(service: LazyClientConfigService) {
    return service.get(ModularCasinoClientConfig);
}
