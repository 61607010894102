import { Component, Input } from '@angular/core';

@Component({
    selector: 'cc-news-feed-v2-item',
    templateUrl: 'news-feed-v2-item.component.html',
    standalone: true,
})
export class NewsFeedV2ItemComponent {
    @Input() item: any;
    @Input() attributes: any;
    gameIcon: any;
    constructor() {}
}
