import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { BingoTournamentsService, Race, SitecoreContent } from '@frontend/bingo/core';

@Component({
    selector: 'bgl-slot-races-tnc',
    templateUrl: './bingo-tnc.component.html',
})
export class BingoSlotRacesTnCComponent implements OnInit, OnDestroy {
    @Input() showHeader: boolean;
    @Input() isCollapsible: boolean;
    @Input() isExpanded: boolean;
    @Input() race: Race;

    isVisible: boolean;
    panelOpenState: boolean;
    contentSubscription: any;
    sitecoreData: any;
    tncData: any;
    constructor(private slotRacesService: BingoTournamentsService) {}

    ngOnInit() {
        this.isVisible = false;
        this.changeCollapseState();
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.sitecoreData = content;
            this.setTnc();
        });
    }
    ngOnDestroy() {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
    }
    changeCollapseState() {
        this.isVisible = !this.isVisible;
    }

    setTnc() {
        if (this.race) {
            if (this.race.content && this.race.content.promotion && this.race.content.promotion.termsAndConditionsText) {
                this.tncData = eval('`' + this.race.content.promotion.termsAndConditionsText + '`');
            } else {
                this.tncData = '';
            }
        }
    }
}
