import { CurrencyPipe, DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import {
    CasinoLobbyService,
    ConfigProviderService,
    DesignSystemEnablerService,
    ResultsHistory,
    SitecoreContent,
    SlotRacesResponseError,
    SlotRacesService,
} from '@casinocore/platform/core';
import { DecoratorClassDirective, SlotRaceCtaComponent } from '@frontend/casino-ui';
import { DeviceService } from '@frontend/vanilla/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { cloneDeep } from 'lodash-es';
import { Subscription } from 'rxjs';

import { SlotRacesInfoComponent } from '../race-info/race-info.component';
import { ResultsPipe } from '../shared/results.pipe';
import { TournamentIconComponent } from '../tournament-icon/tournament-icon.component';

@Component({
    selector: 'cc-slot-races-results',
    templateUrl: 'results.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        MatFormFieldModule,
        MatInputModule,
        MatNativeDateModule,
        MatDatepickerModule,
        NgClass,
        NgFor,
        TournamentIconComponent,
        TrustAsHtmlPipe,
        ResultsPipe,
        DecoratorClassDirective,
        SlotRaceCtaComponent,
    ],
    providers: [CurrencyPipe, DatePipe, provideNativeDateAdapter()],
})
export class SlotRacesResultsComponent implements OnInit, OnDestroy, AfterViewInit {
    title: string;
    showAwardsAndPrizes: boolean;
    showAll: boolean = true;
    maxDate: Date = new Date();
    toMinDate: Date;
    toMaxDate: Date = new Date();
    toDate: Date;
    fromMinDate: Date;
    fromMaxDate: Date = new Date();
    fromDate: Date;
    results: ResultsHistory[];
    initialLoad: boolean = true;
    showNoResults: boolean = false;
    messages: { [item: string]: string };
    resultsHistorySubscription: Subscription;
    contentSubscription: Subscription;
    isMobileView: boolean;
    coinSrc: string;
    isLegacy: boolean = false;

    content: SitecoreContent = {
        raceRuleConfigs: [],
        rules: [],
        rulesBanner: {},
        placeholderCardImage: {},
        coinEconomy: {},
        textTranslations: { sharedList: {}, versionedList: {} },
        tips: [],
        errorMessages: { sharedList: {}, versionedList: {} },
        entryDetails: { sharedList: {}, versionedList: {} },
    };
    resultsErrorMsg: string;
    category: any;
    public slotRacesConfig: any;

    constructor(
        public dialogRef: MatDialogRef<SlotRacesResultsComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private slotRacesService: SlotRacesService,
        private configProviderService: ConfigProviderService,
        private currencyPipe: CurrencyPipe,
        private deviceService: DeviceService,
        private datePipe: DatePipe,
        private dialog: MatDialog,
        private casinoLobbyService: CasinoLobbyService,
        private dsEnablerService: DesignSystemEnablerService,
    ) {
        this.title = data.title;
        this.category = data.category;
        this.coinSrc = data.coinImg;
    }

    ngOnInit() {
        this.slotRacesConfig = this.configProviderService.provideSlotRacesConfig();
        this.showAwardsAndPrizes = this.slotRacesConfig.showAwardsAndPrizes;
        this.getResults();
        this.isLegacy = !this.dsEnablerService.isEnabled();
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.content = content;
            this.messages = content && content.textTranslations ? content.textTranslations.versionedList : {};
        });
    }

    setResults() {
        this.results.forEach((result: any) => {
            // const gameConfig = this.configProviderService.provideGameConfig();
            result.gameIconSrc = this.casinoLobbyService.getImagePath(result.gameVariantName, 'square');
            // gameConfig.host + gameConfig.gameIconTemplate.replace('{0}', 'square').replace('{1}', result.gameVariantName) + '.' + 'jpg';
            result.dateStr = this.datePipe.transform(result.startDate, this.slotRacesConfig.resultsDateFormat);
            result?.configMaps?.forEach((configMap: any) => {
                if (configMap && configMap?.awardType) {
                    if (configMap && configMap?.awardType?.toLowerCase() != 'coins') {
                        if (configMap?.awardType && configMap?.value) {
                            result.awardType = configMap.awardType;
                        }
                        if (result.isWin && configMap?.value) {
                            if (!this.slotRacesConfig.displayBonusAsText) {
                                if (
                                    configMap?.awardType?.toLowerCase() == 'freespins' ||
                                    configMap?.awardType?.toLowerCase() == 'free_spin' ||
                                    !configMap?.currency ||
                                    !configMap?.currency?.length
                                ) {
                                    result.awardValue = Number(configMap.value);
                                } else {
                                    result.awardValue = this.currencyPipe.transform(configMap.value, configMap.currency, 'symbol-narrow', '1.2-2');
                                }
                            } else {
                                if (configMap && configMap.awardType && configMap.awardType?.toLowerCase().indexOf('cash') > -1) {
                                    result.awardValue = this.currencyPipe.transform(configMap.value, configMap.currency, 'symbol-narrow', '1.2-2');
                                } else {
                                    result.awardValue = Number(configMap.value);
                                }
                            }
                        } else {
                            result.awardValue = '--';
                        }
                    }
                    if (configMap && configMap?.awardType?.toLowerCase() == 'coins') {
                        if (
                            result?.configMaps.filter(function (e: any) {
                                return e.awardType.toLowerCase() != 'coins';
                            }).length == 0
                        ) {
                            result.awardType = configMap?.awardType.toLowerCase();
                        }
                        result.isDisplayCoin = true;
                        result.coinValue = result.isDisplayCoin ? configMap.value : '';
                    }
                } else {
                    result.awardValue = '--';
                }
            });
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }

    fromChangeDate(event: MatDatepickerInputEvent<Date>) {
        this.fromDate = event.value ? event.value : new Date();
        this.toMinDate = cloneDeep(this.fromDate);
        // this.toMinDate = {...this.fromDate};
        this.toMinDate.setDate(this.toMinDate.getDate() + 1);
    }

    toChangeDate(event: MatDatepickerInputEvent<Date>) {
        this.toDate = event.value ? event.value : new Date();
        this.toDate.setHours(23, 59, 59);
        this.fromMaxDate = cloneDeep(this.toDate);
        // this.fromMaxDate = {...this.toDate};
        this.fromMaxDate.setDate(this.fromMaxDate.getDate() - 1);
    }

    showAllResults() {
        this.showAll = true;
    }

    showWinResults() {
        this.showAll = false;
    }

    showResults() {
        this.initialLoad = false;
        this.getResults(this.toDate, this.fromDate);
    }

    private getResults(to?: Date, from?: Date): void {
        let noOfDays = 0;
        this.results = [];
        this.showNoResults = false;
        if (to && from) {
            noOfDays = Math.round((to.getTime() - from.getTime()) / (1000 * 3600 * 24));
        }
        if (noOfDays > this.slotRacesConfig.resultsForNoOfDays) {
            this.resultsErrorMsg = this.messages.ResultsNotMoreThan.replace('{noOfDays}', String(this.slotRacesConfig.resultsForNoOfDays));
            this.showNoResults = true;
        } else {
            let start: any, end: any;
            end = to ? to.toUTCString() : null;
            start = from ? from.toUTCString() : null;
            this.resultsHistorySubscription = this.slotRacesService.getResultsHistory(end, start).subscribe(
                (resultsHistory: ResultsHistory[]) => {
                    if (resultsHistory && resultsHistory.length > 0) {
                        this.results = this.initialLoad ? resultsHistory.slice(0, 5) : resultsHistory;
                        this.setResults();
                        this.showNoResults = false;
                    } else {
                        this.resultsErrorMsg = this.messages.NoResultsFound;
                        this.showNoResults = true;
                    }
                },
                (error: SlotRacesResponseError) => {
                    this.resultsErrorMsg = this.slotRacesService.getErrorMessage(error.errorCode);
                    this.showNoResults = true;
                },
            );
        }
    }

    ngOnDestroy() {
        this.resultsHistorySubscription.unsubscribe();
        this.contentSubscription.unsubscribe();
    }

    dialogProperties(dialogConfig: MatDialogConfig): void {
        if (!this.deviceService.isMobileWidth()) {
            dialogConfig.width = '668px';
        } else {
            dialogConfig.width = '100%';
            dialogConfig.height = '100%';
            dialogConfig.maxWidth = '100%';
            dialogConfig.maxHeight = '100%';
        }
    }

    openRaceInfoDialog(result: ResultsHistory) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        this.dialogProperties(dialogConfig);
        dialogConfig.data = {
            raceResult: result,
            resultsDialogRef: this.dialogRef,
            category: this.category,
            coinImg: this.coinSrc,
        };
        dialogConfig.panelClass = 'casino-sr-mat-dialog';
        const resultsDialog = document.getElementsByClassName('sr-results-dialog')[0] as HTMLElement;
        resultsDialog.style.display = 'none';
        this.dialog.open(SlotRacesInfoComponent, dialogConfig);
    }
    ngAfterViewInit() {
        const featuresConfig = this.configProviderService.provideFeaturesConfig();
        if (featuresConfig.enableOverlayMobileSearchTopPosition && this.deviceService.isTouch) {
            this.slotracesOverlayTopAllignment();
        }
    }
    slotracesOverlayTopAllignment() {
        if (document.querySelector('.content-messages-header-top .content-message-container')) {
            const contentMessageElement: HTMLElement =
                document.querySelector('.content-messages-header-top .content-message-container') || new HTMLElement();
            const sr2_overlay: HTMLElement = document.querySelector('cc-slot-races-results') || new HTMLElement();
            sr2_overlay.style.top = contentMessageElement.clientHeight + 'px';
        }
    }
}
