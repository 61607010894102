import { NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import {
    CasinoCoreGamelaunchService,
    CasinoLobbyService,
    CasinoManager,
    GameLaunch,
    LCTimerComponent,
    LiveCasinoService,
    TimeZoneService,
} from '@casinocore/platform/core';
import { TrackingService } from '@frontend/vanilla/core';

@Component({
    selector: 'cc-news-feed-v2-livecasino-api-item',
    templateUrl: 'news-feed-v2-livecasino-api-item.component.html',
    standalone: true,
    imports: [NgIf, NgStyle, NgFor, LCTimerComponent],
})
export class NewsFeedV2LiveCasinoAPIItemComponent implements OnInit, OnDestroy, OnChanges {
    @Input() newsFeedItem: any;
    @Input() attributes: any;
    @Input() index: number;
    @Input() lobbyType: string;
    @Input() data: any;
    liveCasinoApiSubscriber: any;
    liveGameInfo: any;
    resultsDisplayed: any;
    displayLiveStatus: boolean;
    displayDealerImage: boolean;
    dealerImage: any;
    gameName: string;
    categoryType: string;
    isTouch: boolean;
    isTableOnline: boolean;
    offlineTableTime: string;
    enablelctimer: boolean;
    constructor(
        private liveCasinoService: LiveCasinoService,
        private casinoLobbyService: CasinoLobbyService,
        private tracking: TrackingService,
        private casinoManager: CasinoManager,
        private gameLaunchService: CasinoCoreGamelaunchService,
        private timeZoneService: TimeZoneService,
    ) {}

    ngOnInit() {
        this.isTouch = this.casinoManager.isTouch();
        this.categoryType = 'NewsFeed';
        const gameInfo: any = this.casinoLobbyService.getGameInfo(this.newsFeedItem.gameID);
        if (gameInfo) {
            this.gameName = gameInfo.name;
        }

        this.liveCasinoApiSubscriber = this.liveCasinoService.liveGamesFeedObservable.subscribe((response) => {
            if (response && response.size > 0) {
                this.setLiveCasinoItemInfo();
            }
        });
    }
    setLiveCasinoItemInfo() {
        if (this.newsFeedItem && this.newsFeedItem.gameID) {
            this.liveGameInfo = this.liveCasinoService.getLiveGameLookUpInfo(this.newsFeedItem?.gameID?.toLowerCase());

            this.resultsDisplayed = this.liveGameInfo.results.slice(0, 3);
            if (this.newsFeedItem.displayLiveStatus) {
                this.displayLiveStatus = true;
            } else {
                if (!this.newsFeedItem.subTitle) {
                    this.displayLiveStatus = true;
                }
            }
            if (this.newsFeedItem.displayDealerImage) {
                this.displayDealerImage = true;
            } else {
                if (!this.newsFeedItem.iconClass) {
                    this.displayLiveStatus = true;
                }
            }

            this.dealerImage = this.liveGameInfo.dealerInfo.dealerPictureUrl;
            this.setTableOnlineStatus();
        }
    }
    lastResultNumberColor(item: number) {
        if (item == 0) {
            // green;
            return this.attributes['LiveCasinoGreenColor'];
        }

        if ((item >= 1 && item <= 10) || (item >= 19 && item <= 28)) {
            if (item % 2 == 0)
                // black;
                return this.attributes['LiveCasinoBlackColor'];
            else {
                // red;
                return this.attributes['LiveCasinoRedColor'];
            }
        }

        if ((item >= 11 && item <= 18) || (item >= 29 && item <= 36)) {
            if (item % 2 == 0) {
                // red;
                return this.attributes['LiveCasinoRedColor'];
            }
            // black;
            else return this.attributes['LiveCasinoBlackColor'];
        }
    }
    itemClicked() {
        const gameInfo: any = this.casinoLobbyService.getGameInfo(this.newsFeedItem.gameID);
        this.newsFeedItem.gameId = this.newsFeedItem.gameID;
        if (gameInfo) {
            this.itemTrackingOnClick(gameInfo.game, this.index, gameInfo.name);
        }
        const gameModel = new GameLaunch();
        gameModel.game = this.newsFeedItem;
        gameModel.isTouch = this.isTouch;
        gameModel.gamePosition = this.index;
        gameModel.categoryType = this.categoryType;
        gameModel.from = 'q';
        gameModel.replaceRouteInHistory = false;
        gameModel.isDemoLaunch = false;
        gameModel.skipUnfinGame = false;
        gameModel.gameLaunchedFrom = this.categoryType;
        gameModel.gridPosition = 1;
        gameModel.gameSection = this.categoryType;
        gameModel.gameContainer = '';
        gameModel.game.lobbyType = this.lobbyType;
        gameModel.returnUrl = window.location.href;
        gameModel.clickTimeStamp = this.gameLaunchService.currentTimeStamp();
        this.gameLaunchService.launchGame(gameModel);
    }
    itemTrackingOnClick(gameId: string, positionEvent: number, gameName: string) {
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'NewsFeed',
            'component.LabelEvent': 'LiveCasino',
            'component.ActionEvent': 'Click',
            'component.PositionEvent': ++positionEvent,
            'component.LocationEvent': this.data ? this.data.trackPos : '',
            'component.EventDetails': gameId,
            'component.URLClicked': 'not applicable',
            'component.DisplayGameName': gameName,
        });
    }
    ngOnChanges() {
        this.setTableOnlineStatus();
    }
    setTableOnlineStatus() {
        this.liveGameInfo = this.liveCasinoService.getLiveGameLookUpInfo(this.newsFeedItem?.gameID?.toLowerCase());
        if (this.liveGameInfo && this.liveGameInfo.tableStatus) {
            if (this.liveGameInfo.countdown && this.liveGameInfo.countdown.type) {
                if (this.liveGameInfo?.countdown?.type?.toLowerCase() === 'full time') {
                    this.isTableOnline = true;
                } else if (this.liveGameInfo?.countdown?.type?.toLowerCase() === 'bounded') {
                    this.startTimer();
                }
            }
        }
    }
    startTimer() {
        if (this.liveGameInfo.countdown.time) {
            this.offlineTableTime = this.timeZoneService.getTimerStartInstance(this.liveGameInfo.countdown.time + ':00', 'UTC') || '';
            if (!this.offlineTableTime) {
                this.isTableOnline = true;
            } else {
                this.enablelctimer = true;
            }
        }
    }
    onTimerEnd(value: any) {
        if (value) {
            this.isTableOnline = true;
        }
    }
    ngOnDestroy() {
        if (this.liveCasinoApiSubscriber) {
            this.liveCasinoApiSubscriber.unsubscribe();
        }
    }
}
