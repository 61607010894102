<div *ngIf="!showRaceCard" class="slot-list" [ngClass]="last ? 'slot-list-last' : ''">
    <div class="list-item time">{{ race.startTimeAMPM }}</div>
    <div class="list-item red-dot" [ngClass]="first ? (last ? 'red-dot-first red-dot-last' : 'red-dot-first') : last ? 'red-dot-last' : ''"></div>
    <div class="list-item game-name">
        {{ title }}
        <div class="optin-with-coin" *ngIf="race?.isOptinWithCoins">
            {{ content?.textTranslations?.versionedList?.OptinCoinText }}
        </div>
    </div>
    <div class="list-item prize-amount">{{ race?.additionalParams | prize: false }}</div>
    <div
        *ngIf="race?.additionalParams?.awardType?.toLowerCase() != 'coins'"
        class="list-item theme-prize-icon"
        [ngClass]="content?.textTranslations?.sharedList[(race?.additionalParams | prize: true)]"></div>

    <span class="sr-card-total-coin" *ngIf="race?.additionalParams?.awardType?.toLowerCase() == 'coins'">
        <img class="coin-img" [src]="coinImg" alt="Coin Image" />
    </span>
    <ng-container *ngIf="coinObj?.isDisplayCoin">
        <span class="theme-plus"></span>
        <span class="sr-card-total-coin">
            <img class="coin-img" [src]="coinObj?.coinSrc" alt="Coin Image" />
        </span>
    </ng-container>

    <div class="list-item theme-down plus" (click)="toggleRaceCardVisibility()"></div>
</div>
<div *ngIf="showRaceCard">
    <div class="casino-wrapper-sr-expand">
        <cc-slot-race-card
            [enableOptin]="enableOptin"
            (hideCard)="toggleRaceCardVisibility()"
            [race]="race"
            [category]="category"
            [showRaceInfo]="true"
            [lobbyType]="lobbyType"
            [showCardMenu]="true"
            [first]="first"
            [last]="last"
            (openRaceInfo)="openRaceInfoDialog()"
            (openPickGameOverlay)="openPickGameDialog()"
            (openNotificationOverlay)="openNotificationDialog()"
            [targetedPlayerCount]="race.targetedPlayerCount"
            [coinImg]="coinImg">
        </cc-slot-race-card>
    </div>
</div>
