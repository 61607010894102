export enum ModelDefault {
    NA = 'not applicable',
    Site = 'Bingo',
}
/**
 * @whatItDoes This model has all tracking optional properties. Default value will be 'not applicable'
 *
 * @stable
 */
export class TrackingModel {
    eventName: string;
    SiteSection?: string;
    CategoryEvent?: string;
    LabelEvent?: string;
    ActionEvent?: string;
    PositionEvent?: string;
    LocationEvent?: string;
    EventDetails?: string;
    URLClicked?: string;
}
