import { Injectable } from '@angular/core';

import { BingoNpmParams } from '../models/bingo-npm.params';

@Injectable({
    providedIn: 'root',
})
export class BingoNpmParamsService {
    private bingoNpmParams: BingoNpmParams;

    constructor() {}

    setBingoNpmParams(bingoNpmParams: BingoNpmParams) {
        this.bingoNpmParams = bingoNpmParams;
    }

    getBingoNpmParams(): BingoNpmParams {
        return this.bingoNpmParams;
    }
}
