import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { BingoConfigProviderService, BingoPlatformApiService, ScreenNameClientConfig } from '@frontend/bingo/core';
import { Logger } from '@frontend/vanilla/core';
import { Observable } from 'rxjs';

import { ScreenNameComponent } from '../../screen-name/screen-name.component';
import { RootServicesModule } from '../root-services.module';

@Injectable({
    providedIn: RootServicesModule,
})
export class ScreenNameService {
    screenChatName: string = '';
    isAPICallFetched = false;

    constructor(
        private configProviderService: BingoConfigProviderService,
        private dialog: MatDialog,
        private api: BingoPlatformApiService,
        private logger: Logger,
    ) {}

    saveChatName(chatName: string): Observable<any> {
        const body: any = {
            screenChatName: {
                chatName: chatName,
            },
        };
        return this.api.post('ScreenChatName', body);
    }

    getScreenChatName(): void {
        const screenNameConfig: ScreenNameClientConfig = this.configProviderService.provideScreenNameClientConfig();
        if (screenNameConfig.enableClientScreenName) {
            this.isAPICallFetched = false;
            this.api.get('ScreenChatName').subscribe((response) => {
                this.screenChatName = this.getScreenNameFromResponse(response);
            });
        }
    }

    async getChatName(): Promise<string> {
        if (this.isAPICallFetched) {
            return this.screenChatName;
        } else {
            return await this.api
                .get('ScreenChatName')
                .toPromise()
                .then((response) => {
                    return this.getScreenNameFromResponse(response);
                });
        }
    }

    setDefaultValuesScreenName() {
        this.isAPICallFetched = false;
        this.screenChatName = '';
    }

    getScreenNameFromResponse(response: any): string {
        if (response && response.statusMessage === 'SUCCESS') {
            if (response.data && response.data.responseMessage === 'success') {
                this.isAPICallFetched = true;
                this.screenChatName = response.data.chat_name;
            } else if (response.data && response.data.responseMessage === 'error') {
                if (response.data.Error) {
                    this.logger.error('Error in getting ScreenName details', response.data.Error.message);
                } else {
                    this.logger.error('No Proper details of Error in ScreenName details', response.data);
                }
                this.screenChatName = '';
                this.isAPICallFetched = false;
            }
        } else {
            this.logger.error('Error in getting ScreenName details', response);
            this.screenChatName = '';
            this.isAPICallFetched = false;
        }

        return this.screenChatName;
    }

    openScreenChatNameComponent(data: any) {
        this.dialog.open(ScreenNameComponent, {
            panelClass: 'screen-name-model',
            disableClose: true,
            data: data,
        });
    }

    async validateScreenNameChat() {
        const screenNameConfig: ScreenNameClientConfig = this.configProviderService.provideScreenNameClientConfig();
        if (screenNameConfig.enableClientScreenName) {
            const screenName = await this.getChatName();
            if (screenName == '' || screenName == null) {
                return false;
            }
        }
        return true;
    }
}
