import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { BingoConfigProviderService, BingoTournamentsService, SitecoreContent } from '@frontend/bingo/core';
import { NavigationService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'bgl-slot-races-widget',
    templateUrl: 'bingo-slot-races-widget.component.html',
})
export class BingoSlotRacesWidgetComponent implements OnInit, OnDestroy {
    @Input() fromBingoHomepage: boolean;
    isLobbyLoaded: boolean;
    loadWidget: boolean;
    header: any;
    contentSubscription: Subscription;
    lobbyLoadSubscription: Subscription;
    slotRacesUpdateSubscription: Subscription;
    isSlotRaceSubCategory: boolean;
    enableSlotRacesForOtherProducts: boolean;

    constructor(
        private configProvider: BingoConfigProviderService,
        private slotRacesService: BingoTournamentsService,
        private navigation: NavigationService,
    ) {}

    ngOnInit() {
        this.lobbyLoadSubscription = this.configProvider.bingoClientConfigObservable.subscribe((response: any) => {
            if (response) {
                this.setParams();
                this.isLobbyLoaded = true;
            }
        });
    }

    setParams() {
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.header = content && content.npmHeader;
        });
    }

    SeeAllTournaments() {
        this.navigation.goTo(this.header?.titleLink?.url, { replace: false, forceReload: true });
    }

    ngOnDestroy() {
        if (this.lobbyLoadSubscription) {
            this.lobbyLoadSubscription.unsubscribe();
        }
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
        if (this.slotRacesUpdateSubscription) {
            this.slotRacesUpdateSubscription.unsubscribe();
        }
    }
}
