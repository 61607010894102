<div id="casino-progressbar-container">
    <div
        [ngStyle]="{ 'background-color': progressBarBgClr }"
        class="progressbar"
        *ngFor="let storyItemData of activeStoryData.storyItems; let i = index">
        <span
            *ngIf="activeStoryData.storyItems.length == 1"
            [ngStyle]="{ 'background-color': progressBarClr }"
            class="singlestoryActiveProgressBar"
            [ngClass]="{ storyCompletionClass: checkStoryCompletion(i) }"
            [style.width]="getWidth()">
            <span
                *ngIf="checkStoryCompletion(i)"
                [ngStyle]="{ 'background-color': progressBarCompletionBgClr, 'color': progressBarCompletionClr }"
                class="theme-check">
            </span>
        </span>
        <span
            *ngIf="activeStoryData.storyItems.length > 1"
            [ngStyle]="{ 'background-color': progressBarClr }"
            [ngClass]="{ activeProgressBar: i == activeStoryItemIndex, storyCompletionClass: checkStoryCompletion(i) }"
            [style.width]="getWidth()">
            <span
                *ngIf="checkStoryCompletion(i)"
                [ngStyle]="{ 'background-color': progressBarCompletionBgClr, 'color': progressBarCompletionClr }"
                class="theme-check">
            </span>
        </span>
    </div>
</div>
