import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';

import { GamingStoriesPhase2Configuration, GamingStoriesService, IGamingStory, IGamingStoryContainer } from '@casinocore/platform/core';
import { TimerService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cc-gaming-story-progress-bar',
    templateUrl: 'gaming-story-progress-bar.component.html',
    standalone: true,
    imports: [NgFor, NgStyle, NgIf, NgClass],
})
export class GamingStoryProgressBarComponent implements OnInit, OnChanges, OnDestroy {
    @Input() storyContainerData: IGamingStoryContainer;
    @Input() activeStoriesIndex: number;
    @Input() activeStoryItemIndex: number;
    @Output() sendStoryCompletionEvent: EventEmitter<any> = new EventEmitter<any>();

    width: number = 0;
    progressBarBgClr: string;
    progressBarClr: string;
    progressBarCompletionBgClr: string;
    progressBarCompletionClr: string;
    activeStoryData: IGamingStory;
    progressBarWidth: any;

    private storyCompletionArray: Array<any> = new Array<any>();
    private setIntervalTimer: any;
    private progress = 0;
    private play: boolean;
    private storyInterval: number;
    private pauseStoryObservable: Subscription;
    private storyItemInterval: number;
    private videoPresentObservable: Subscription;
    private videoProgressObservable: Subscription;
    private gamingStoriesconfig: GamingStoriesPhase2Configuration;
    private firstTimeTrigger: boolean = false;

    constructor(
        private timerService: TimerService,
        private changeDetectorRef: ChangeDetectorRef,
        private gamingStoriesService: GamingStoriesService,
    ) {}

    ngOnChanges() {
        this.gamingStoriesconfig = this.gamingStoriesService.config;
        if (this.gamingStoriesconfig) {
            this.storyItemInterval = this.gamingStoriesconfig.storyItemInterval;
        }
        clearTimeout(this.setIntervalTimer);
        if (this.setIntervalTimer) {
            this.timerService.clearInterval(this.setIntervalTimer);
        }
        // this.setIntervalTimer = 0;
        this.progress = 0;
        this.play = true;
        this.width = 0;
        this.storyCompletionArray = [];
        this.activeStoryData = this.storyContainerData.storyList[this.activeStoriesIndex];
        this.progressBarWidth = (100 / this.activeStoryData.storyItems.length).toString() + '%';
        let i = this.activeStoryItemIndex - 1;
        this.storyInterval = this.activeStoryData.storyItems[this.activeStoryItemIndex].storyItemInterval
            ? this.activeStoryData.storyItems[this.activeStoryItemIndex].storyItemInterval
            : this.storyItemInterval;
        while (i >= 0) {
            this.storyCompletionArray.push(i);
            i--;
        }
        this.subscribeToVideoPresentObs();
        this.runProgressBar();
    }

    ngOnInit(): void {
        this.progressBarBgClr = this.storyContainerData.sharedList.progressBarBgClr;
        this.progressBarClr = this.storyContainerData.sharedList.progressBarClr;
        this.progressBarCompletionBgClr = this.storyContainerData.sharedList.progressBarCompletionBgClr;
        this.progressBarCompletionClr = this.storyContainerData.sharedList.progressBarCompletionClr;
        this.pauseStoryObservable = this.gamingStoriesService.pauseStoryObservable.subscribe((pauseStoryTrigger: boolean) => {
            if (pauseStoryTrigger) {
                this.pauseStory();
            } else {
                this.resumeStory();
            }
        });
        this.activeStoryData = this.storyContainerData.storyList[this.activeStoriesIndex];
    }

    getWidth() {
        return this.width + '%';
    }

    checkStoryCompletion(index: number) {
        return this.storyCompletionArray.indexOf(index) > -1;
    }

    ngOnDestroy() {
        this.storyCompletionArray = [];
        if (this.pauseStoryObservable) {
            this.pauseStoryObservable.unsubscribe();
        }
        if (this.videoPresentObservable) {
            this.videoPresentObservable.unsubscribe();
        }
        if (this.videoProgressObservable) {
            this.videoProgressObservable.unsubscribe();
        }
    }

    private resumeStory() {
        this.gamingStoriesService.playVideo();
        this.play = true;
    }

    private pauseStory() {
        this.gamingStoriesService.pauseVideo();
        this.play = false;
        const video = <HTMLVideoElement>document.getElementById('casino-gamingstory-video');
        if (video) {
            video.pause();
        }
    }

    private clearTimer() {
        clearTimeout(this.setIntervalTimer);
        if (this.setIntervalTimer) {
            this.timerService.clearInterval(this.setIntervalTimer);
            this.setIntervalTimer = undefined;
        }
    }

    private emitStoryCompletionEvent() {
        const activeStoryData = {
            activeStoriesIndex: this.activeStoriesIndex,
            activeStoryItemIndex: this.activeStoryItemIndex,
        };
        this.clearTimer();
        this.unsubscribeToVideoObservable();
        this.gamingStoriesService.videoPresentStatus(false);
        this.gamingStoriesService.videoProgressStatus(-1);
        this.sendStoryCompletionEvent.emit(activeStoryData);
    }

    private runProgressBar() {
        this.setIntervalTimer = this.timerService.setIntervalOutsideAngularZone(() => {
            if (this.play) {
                this.progress += 0.001;
                this.width = Math.floor(this.progress * 100);
                this.changeDetectorRef.detectChanges();
                if (this.progress >= 1) {
                    this.emitStoryCompletionEvent();
                }
            }
        }, this.storyInterval);
    }

    private unsubscribeToVideoObservable() {
        if (this.videoPresentObservable) {
            this.videoPresentObservable.unsubscribe();
        }
        if (this.videoProgressObservable) {
            this.videoProgressObservable.unsubscribe();
        }
    }

    private subscribeToVideoPresentObs() {
        this.videoPresentObservable = this.gamingStoriesService.videoPresentObservable.subscribe((isVideoPresent: boolean) => {
            if (isVideoPresent) {
                this.clearTimer();
                this.progress = 0;
                this.width = 0;
            }
        });

        this.videoProgressObservable = this.gamingStoriesService.videoProgressObservable.subscribe((progress: number) => {
            if (this.play && progress != -1) {
                this.width = progress;
                if (this.width == 100 && !this.firstTimeTrigger) {
                    this.firstTimeTrigger = true;
                    this.emitStoryCompletionEvent();
                }
            }
        });
    }
}
