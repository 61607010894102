import { Pipe, PipeTransform } from '@angular/core';

import { BingoConfigProviderService } from '@frontend/bingo/core';

@Pipe({ name: 'sitecore_image_resize' })
export class SitecoreImageResizePipe implements PipeTransform {
    constructor(private configProviderService: BingoConfigProviderService) {}

    transform(value: string): string {
        // let imageLoadOptimizationConfig = this.configProvider.provideImageLoadOptimizationClientConfig();
        const clientConfig = JSON.parse(localStorage.getItem('config')!);
        const imageLoadOptimizationConfig = clientConfig?.bingoParallax;
        if (
            imageLoadOptimizationConfig &&
            imageLoadOptimizationConfig.isDynamicResizingEnabled &&
            imageLoadOptimizationConfig.dynamicResizingQueryString &&
            imageLoadOptimizationConfig.dynamicResizingQueryString.length > 0 &&
            !this.isImageRezied(value)
        ) {
            const qs = (value.indexOf('?') !== -1 ? '&' : '?') + imageLoadOptimizationConfig.dynamicResizingQueryString;
            value = value + qs;
        }
        return value;
    }

    private isImageRezied(src: string): boolean {
        return src.indexOf('resized=true') !== -1;
    }
}
