import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { BingoConfigProviderService, FeaturesClientConfig, ScheduleService } from '@frontend/bingo/core';
import { CurrencyPipe } from '@frontend/vanilla/core';

import { PreBuyGridComponent } from './pre-buy-grid.component';

@Component({
    selector: 'bg-slider-ang',
    templateUrl: 'MultiPriceSlider.component.html',
    styleUrls: ['MultiPriceSlider.component.css'],
    providers: [CurrencyPipe],
})
export class MultiPriceSliderComponent implements OnInit, AfterViewInit {
    @Input() values: any[];
    @Input() currency: string;
    @Input() currentindex: number;
    @Input() isSlingoMultiStake: boolean;
    @Output()
    ValueChanged: EventEmitter<any> = new EventEmitter<any>();
    isCozyUser: boolean;
    tickInterval: any;
    min: any;
    max: any;
    step: any;
    value: any;
    numbers: number[];
    sliderMoved: boolean;
    tempVal: number;
    enableNewPrebuyIcons: boolean = false;
    featuresConfig: FeaturesClientConfig;

    @ViewChild(PreBuyGridComponent)
    PreBuyGridComponentChild: PreBuyGridComponent;

    constructor(
        private vnCurrency: CurrencyPipe,
        private configProviderService: BingoConfigProviderService,
        private scheduleService: ScheduleService,
    ) {
        this.featuresConfig = this.configProviderService.provideFeaturesConfig();
    }

    ngOnInit() {
        this.enableNewPrebuyIcons = this.featuresConfig.enableNewPrebuySliderIcons;
        if (this.isSlingoMultiStake) {
            this.values = this.values
                .map((e: any) => {
                    return e.price;
                })
                .map(Number);
        }
        this.numbers = this.values.map(Number);
        this.value = this.numbers[this.currentindex];
        this.min = this.numbers[0];
        this.max = this.numbers[this.numbers.length - 1];
        this.step = this.numbers[1] - this.numbers[0];
        for (let i = 2; i != this.numbers.length; i++) {
            this.step = Math.min(this.step, this.numbers[i] - this.numbers[i - 1]);
        }
    }

    ngAfterViewInit() {
        //let elem = document.querySelector('.fluffy .mat-slider-thumb-label-text');
        const timeout = this.featuresConfig.ticketIconTimeout ? this.featuresConfig.ticketIconTimeout : 3000;
        setTimeout(() => {
            const elem = document.querySelector('.fluffy .mdc-slider__value-indicator-text');
            if (elem && elem.classList.contains('theme-bingo-ticket')) return;
            else if (elem) {
                if (this.featuresConfig.enableNewPrebuySliderIcons) {
                    elem.classList.add('theme-bingo-gala-ticket');
                } else elem.classList.add('theme-bingo-ticket');
            }
        }, timeout);
    }

    formatLabel(value: any) {
        value = ' ' + this.vnCurrency.transform(this.value, this.currency);
        return value;
    }

    OnValueChanged(event: number) {
        this.tempVal = event;
        if (!this.sliderMoved) {
            this.sliderMoved = true;
            setTimeout(() => {
                const index = this.numbers.indexOf(this.tempVal);
                if (index != -1) {
                    this.currentindex = index;
                    this.value = this.tempVal;
                    this.emitValue();
                } else {
                    let tempindex = 0;
                    for (let i = 0; i != this.numbers.length; i++) {
                        if (this.tempVal > this.numbers[i]) tempindex = i;
                    }
                    if (tempindex >= this.currentindex) {
                        this.currentindex = tempindex;
                        this.onPlusclick();
                    } else {
                        this.currentindex = tempindex + 1;
                        this.onMinusclick();
                    }
                }
                this.sliderMoved = false;
            }, 250);
        }
    }

    AppendClass(param: number) {
        if (param == 0) {
            if (this.featuresConfig.enableNewPrebuySliderIcons) {
                if (this.value == this.min) {
                    return 'theme-bingo-gala-minus disabled';
                } else {
                    return 'theme-bingo-gala-minus';
                }
            } else {
                if (this.value == this.min) {
                    return 'theme-minus disabled';
                } else {
                    return 'theme-minus';
                }
            }
        } else if (param == 1) {
            if (this.featuresConfig.enableNewPrebuySliderIcons) {
                if (this.value == this.max) {
                    return 'theme-bingo-gala-plus disabled';
                } else {
                    return 'theme-bingo-gala-plus';
                }
            } else {
                if (this.value == this.max) {
                    return 'theme-plus disabled';
                } else {
                    return 'theme-plus';
                }
            }
        }
        return 0;
    }

    onPlusclick() {
        if (this.currentindex < this.numbers.length - 1) {
            this.currentindex = this.currentindex + 1;
            this.value = this.numbers[this.currentindex];
            this.scheduleService.sendSliderData(this.value);
            this.emitValue();
        }
        return;
    }

    onMinusclick() {
        if (this.currentindex > 0) {
            this.currentindex = this.currentindex - 1;
            this.value = this.numbers[this.currentindex];
            this.emitValue();
        }
    }
    emitValue() {
        const obj = { index: this.currentindex, price: this.values[this.currentindex] };
        this.ValueChanged.emit(obj);
    }
}
