import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    AppInfoConfig,
    ClaimsService,
    DeviceService,
    LoginDialogService,
    MessageQueueService,
    NavigationService,
    Page,
    ProductHomepagesConfig,
    SessionStoreService,
    TrackingService,
    UserService,
} from '@frontend/vanilla/core';
import { CurrentSessionConfig } from '@frontend/vanilla/shared/current-session';
import { BehaviorSubject, Observable } from 'rxjs';

import { BingoConfigProviderService } from '../../bingo-config-provider/services/bingo-config-provider.service';
import { BingoPlatformApiService } from '../../bingo-platform-api/services/bingo-platform-api.service';
import { FeaturesClientConfig } from '../../client-config/features-client.config';
import { ScheduleService } from '../../schedule/services/schedule.service';
import { ConfirmMessageService } from '../../shared/components/confirm-message/confirm-message.service';
import { BingoWidgetService } from '../../shared/services/bingo-widget.service';

@Injectable({
    providedIn: 'root',
})
export class GameLaunchService {
    isInGameClient: boolean = false;
    isRealPlayer: any;
    loadGameinBingoPortalIframe = new BehaviorSubject<any>(null);
    BingoPortalIframeGameLaunchObs: Observable<any> = this.loadGameinBingoPortalIframe.asObservable();
    isVisible = new BehaviorSubject<boolean>(false);
    overlayVisibilityStatus: Observable<boolean> = this.isVisible.asObservable();
    GameData: {};
    Game = new BehaviorSubject<any>(null);
    gameDataObject: Observable<any> = this.Game.asObservable();
    openedRooms: string[] = [];
    activeroom: string;
    globalMessages: any;
    _loadingIndicator: any;
    clientConfig: any = JSON.parse(localStorage.getItem('config') || '');
    constructor(
        private api: BingoPlatformApiService,
        private activatedRoute: ActivatedRoute,
        private user: UserService,
        private loginDialog: LoginDialogService,
        private messageQueue: MessageQueueService,
        private lhProductHomepagesConfig: ProductHomepagesConfig,
        private claimsConfig: ClaimsService,
        private navigator: NavigationService,
        private session: SessionStoreService,
        private confirmMessageService: ConfirmMessageService,
        private deviceService: DeviceService,
        private tracking: TrackingService,
        private bingoWidget: BingoWidgetService,
        private scheduleService: ScheduleService,
        private page: Page,
        private currentSessionConfig: CurrentSessionConfig,
        private appInfoConfig: AppInfoConfig,
        private clientConfigProvider: BingoConfigProviderService,
    ) {
        this.GameData = this.Game;
        if (this.activatedRoute.snapshot.queryParamMap.get('hideHeader') == 'true' || this.activatedRoute.snapshot.queryParamMap.get('.box')) {
            this.isInGameClient = true;
        }
    }
    setOverlayVisibility(isVisible: boolean) {
        this.isVisible.next(isVisible);
    }

    checkUserStatus(): boolean {
        const bingoPage = this.clientConfig?.pageConfig?.value;
        if (this.isRealPlayer === undefined || this.isRealPlayer == null) {
            this.isRealPlayer = this.claimsConfig.get('realplayer');
        }
        if (this.isRealPlayer == 'True') {
            return true;
        } else {
            if (this.bingoWidget.isBingoWidget()) {
                const message = {
                    type: 'bingoWidget',
                    redirectUrl: bingoPage?.depositUrl?.url,
                };
                this.bingoWidget.postMessage('UserNotReal', message);
                return false;
            }
            this.getConfigDataFromLocalstorage();
            const message = this.globalMessages['messages'].preBuyUserDepositMessage,
                header = this.globalMessages['messages'].preBuyUserDepositHeader,
                closeBtn = this.globalMessages['messages'].confirmCloseButton,
                purchaseButtonText = this.globalMessages['messages'].preBuyUserDepositBtn;
            this.confirmMessageService.confirmThis(
                header,
                message,
                '',
                purchaseButtonText,
                closeBtn,
                () => {
                    this.navigator.goTo(bingoPage?.depositUrl?.url);
                },
                () => {},
            );
        }
        return false;
    }

    initiateGameLaunch(
        gameName: string,
        provider: string,
        gameType: string,
        ballType: string,
        launchType: string,
        gameIcon: string,
        gameId: string,
        favstatus: boolean,
        position: number,
        gridSize: number,
        originUrl: string,
        specialproduct: string = '',
        themeId: any = null,
        originGameId: any = null,
        isReplaceGameSrc: boolean = false,
        showLobby: boolean = true,
    ): void {
        let launchedRoom: any;
        if (this.bingoWidget.isBingoWidget()) {
            this.isInGameClient = false;
        }
        if (this.scheduleService.checkforscheduleData()) {
            launchedRoom = this.scheduleService.getFeaturebyRoomId(gameId);
            this.processGameLaunch(
                gameName,
                provider,
                gameType,
                ballType,
                launchType,
                gameIcon,
                gameId,
                favstatus,
                position,
                gridSize,
                originUrl,
                specialproduct,
                themeId,
                originGameId,
                isReplaceGameSrc,
                launchedRoom,
                showLobby,
                launchedRoom?.tournament_enabled,
            );
        } else {
            this.scheduleService.getGameData((response: any) => {
                if (response) {
                    launchedRoom = this.scheduleService.getFeaturebyRoomId(gameId);
                    this.processGameLaunch(
                        gameName,
                        provider,
                        gameType,
                        ballType,
                        launchType,
                        gameIcon,
                        gameId,
                        favstatus,
                        position,
                        gridSize,
                        originUrl,
                        specialproduct,
                        themeId,
                        originGameId,
                        isReplaceGameSrc,
                        launchedRoom,
                        showLobby,
                        launchedRoom?.tournament_enabled,
                    );
                }
            });
        }
    }

    processGameLaunch(
        gameName: string,
        provider: string,
        gameType: string,
        ballType: string,
        launchType: string,
        gameIcon: string,
        gameId: string,
        favstatus: boolean,
        position: number,
        gridSize: number,
        originUrl: string,
        specialproduct: string = '',
        themeId: any = null,
        originGameId: any = null,
        isReplaceGameSrc: boolean = false,
        launchedRoom: any,
        showLobby: boolean = true,
        isBingoTournaments: boolean = false,
    ) {
        const featuresConfig: FeaturesClientConfig = this.clientConfigProvider.provideFeaturesConfig();
        this.getConfigDataFromLocalstorage();
        let featureType =
            launchedRoom &&
            (launchedRoom.bingo_type == '36' && launchedRoom.features.indexOf('prize_grab') > -1
                ? 'fluffy'
                : launchedRoom.bingo_variant && launchedRoom.bingo_variant == launchedRoom.combinedFeatureType
                  ? launchedRoom.combinedFeatureType
                  : 'bingo');
        if (!launchedRoom) {
            featureType = 'bingo';
        }
        const lockedRoomIds: any = this.scheduleService.lockedRoomIdsArray;
        let gameLaunchApproved: boolean = true;
        for (const val in lockedRoomIds) {
            if (lockedRoomIds[val] == gameId) {
                gameLaunchApproved = false;
            }
        }
        if (!gameLaunchApproved) {
            let apiUrl: string = '';
            apiUrl = this.lhProductHomepagesConfig.bingo + '/bingo/rooms';
            this.navigator.goTo(apiUrl);
        } else {
            let origin: string;
            if (specialproduct && specialproduct.trim().toLowerCase() == 'globalsearch') {
                this.tracking.triggerEvent('gameOpen', {
                    'component.gameName': gameName,
                    'component.gameType': gameType,
                    'component.Game.section': 'Not applicable',
                    'component.Game.position': 'Not applicable',
                    'component.Game.GridPosition': 'Not applicable',
                    'component.gameStatus': 'Not applicable',
                    'component.gameContainerLocation': 'Not applicable',
                    'component.gameContainerDescription': 'Not applicable',
                    'component.gameFavourite': 'Not applicable',
                    'component.gamePlayingStatus': 'Not applicale',
                });
            }
            //the originUrl will always be undefined except in the case of forced game launch. Value for the originUrl is passed only from the game-redirect component
            this.tracking.triggerEvent('gameOpen', {
                'component.Game.position': position + 1,
                'component.Game.section': 'BingoLobby',
                'component.gameName': gameName,
                'component.gameDisplayName': gameName,
                'component.gameType': provider != '' && provider != null && provider != undefined ? provider : gameType,
                'component.Game.GridPosition':
                    Math.ceil((position + 1) / gridSize) + ':' + ((position + 1) % gridSize == 0 ? gridSize : (position + 1) % gridSize),
            });
            if (this.bingoWidget.isBingoWidget()) {
                origin = this.lhProductHomepagesConfig.casino;
            } else {
                if (originUrl) {
                    origin = originUrl;
                } else {
                    origin = window.location.href;
                }
            }

            const playUrl = this.getPlayUrl(gameName, gameType, ballType, launchType, gameIcon, gameId, favstatus, origin, featureType, themeId);

            if (featuresConfig.disableUserValidationCheck) {
                if (!this.validateUser(playUrl)) {
                    return;
                }
                if (!this.checkUserStatus()) {
                    if (this.isForcedGameLaunch()) {
                        this.navigator.goTo(origin);
                    }
                    return;
                }
            }
            this.messageQueue.clear();

            if (this.deviceService.isMobilePhone && (gameType === 'Casino' || gameType === 'casino')) {
                this.getCasinoGameLaunchUrl(gameId).subscribe(
                    (response) => {
                        if (response.redirect) {
                            this.launchGame(response.redirect);
                        } else {
                            this.messageQueue.addError(this.globalMessages.messages['gameLaunchErrorMessage']);
                        }
                    },
                    (error) => {
                        if (error) this.messageQueue.addError(this.globalMessages.messages['gameLaunchErrorMessage']);
                    },
                );
            } else {
                this.gamelaunch(
                    gameName,
                    gameType,
                    ballType,
                    launchType,
                    gameIcon,
                    gameId,
                    favstatus,
                    origin,
                    themeId,
                    originGameId,
                    isReplaceGameSrc,
                    featureType,
                    showLobby,
                    isBingoTournaments,
                );
            }
        }
    }

    gamelaunch(
        gameName: string,
        gameType: string,
        ballType: string,
        launchType: string,
        gameIcon: string,
        gameId: string,
        favstatus: boolean,
        origin: string,
        themeId: any,
        originGameId: any,
        isReplaceGameSrc: boolean,
        featureType: string,
        showLobby: boolean,
        isBingoTournaments: boolean,
    ) {
        const featuresConfig: FeaturesClientConfig = this.clientConfigProvider.provideFeaturesConfig();
        let lastLoginTime: any = null;
        if (featuresConfig.enableLastLoginTimeCheck) {
            const timeNow = new Date().getTime();
            lastLoginTime = featuresConfig.enableVanillaLoginDuration
                ? timeNow - this.currentSessionConfig.loginDuration!
                : timeNow - this.user.loginDuration!;
        }
        const vnChannelId = featuresConfig?.enableChannelInLaunchURL ? this.appInfoConfig.channel : '';
        this.getGameLaunchUrl(
            gameName,
            gameType,
            ballType,
            launchType,
            gameIcon,
            gameId,
            favstatus,
            origin,
            featureType,
            themeId,
            originGameId,
            isReplaceGameSrc,
            lastLoginTime,
            showLobby,
            vnChannelId,
            isBingoTournaments,
        ).subscribe(
            (response: any) => {
                if (response.redirectUrl) {
                    this.launchDesktopGame(response.redirectUrl);
                } else if (response.responseCode && response.responseCode != 0) {
                    let errorMessage = this.globalMessages.messages['gl_' + response.responseCode];
                    if (!errorMessage) {
                        errorMessage = this.globalMessages.messages['gameLaunchErrorMessage'];
                    }
                    if (this.bingoWidget.isBingoWidget()) {
                        this.bingoWidget.postMessage('bg_Widget_Game_Launch_Error', { errorMessage: errorMessage });
                        return;
                    }
                    this.messageQueue.addError(errorMessage);
                    window.scrollTo(0, 0);
                    if (this.isForcedGameLaunch()) {
                        this.session.set('GameLaunchErrorCode', 'gl_' + response.responseCode);
                        this.navigator.goTo(origin);
                    }
                } else {
                    if (this.bingoWidget.isBingoWidget()) {
                        this.bingoWidget.postMessage('bg_Widget_Game_Launch_Error', {
                            errorMessage: this.globalMessages.messages['gameLaunchErrorMessage'],
                        });
                        return;
                    }
                    this.messageQueue.addError(this.globalMessages.messages['gameLaunchErrorMessage']);
                    window.scrollTo(0, 0);
                }
                setTimeout(() => this.messageQueue.clear(), 3000);
            },
            (error: any) => {
                if (error) {
                    let errorMessage = this.globalMessages.messages['gl_' + error.responseCode];
                    if (!errorMessage) {
                        errorMessage = this.globalMessages.messages['gameLaunchErrorMessage'];
                    }
                    if (this.bingoWidget.isBingoWidget()) {
                        this.bingoWidget.postMessage('bg_Widget_Game_Launch_Error', { errorMessage: errorMessage });
                        return;
                    }
                    this.messageQueue.addError(errorMessage);
                    window.scrollTo(0, 0);
                    if (this.isForcedGameLaunch()) {
                        this.session.set('GameLaunchErrorCode', 'gl_' + error.responseCode);
                        this.navigator.goTo(origin);
                    }
                }
            },
        );
    }

    isForcedGameLaunch() {
        if (window.location.href.indexOf('forcedgamelaunch') > -1) return true;
        else return false;
    }

    getPlayUrl(
        gameName: string,
        gameType: string,
        ballType: string,
        launchType: string,
        gameIcon: string,
        gameId: string,
        favstatus: boolean,
        origin: string,
        featureType: string,
        themeId: string,
    ): string {
        const bingoPage = this.clientConfig?.pageConfig?.value;
        const result =
            bingoPage?.bingoHomePage?.url +
            '/bingo/forcedgamelaunch/' +
            gameName +
            '?gameType=' +
            gameType +
            '&ballType=' +
            ballType +
            '&launchType=' +
            launchType +
            '&gameIcon=' +
            gameIcon +
            '&gameId=' +
            gameId +
            '&favstatus=' +
            favstatus +
            '&originUrl=' +
            origin +
            '&featureType=' +
            featureType +
            '&themeid=' +
            themeId;
        return result;
    }

    validateUser(playUrl: string): boolean {
        const featuresConfig: FeaturesClientConfig = this.clientConfigProvider.provideFeaturesConfig();
        const bingoPage = this.clientConfig?.pageConfig?.value;
        if (!this.user.isAuthenticated) {
            if (this.bingoWidget.isBingoWidget()) {
                this.bingoWidget.postMessage('UserAction', { promptLogin: true, redirectUrl: playUrl, loginMessageKey: 'GameLaunch' });
                return false;
            }
            if (this.isForcedGameLaunch()) {
                localStorage.setItem('forcedGameLaunch', 'true');
            }
            this.loginDialog.open({ returnUrl: playUrl });

            return false;
        }

        if (featuresConfig.enableAccountUpgrade && this.claimsConfig.get('accBusinessPhase') == 'in-shop') {
            //this.navigator.goTo('http://qa2.www.coral.co.uk/en/mobileportal/initaccountupgrade?source=guard&skip=false');
            this.navigator.goTo(bingoPage?.accountUpgradeUrl?.url);
            return false;
        }
        //Vanilla 6.9.1 Screen name interceptor bug fix.
        if (featuresConfig.enableScreenNameCheck && !this.user.screenname) {
            const screenNameCheckUrl = this.lhProductHomepagesConfig.portal + '/mobileportal/screenname';
            if (this.bingoWidget.isBingoWidget()) {
                this.bingoWidget.postMessage('redirectUrl_bingo_screen_name', { redirectUrl: screenNameCheckUrl, referrerUrl: playUrl });
            } else {
                this.navigator.goTo(screenNameCheckUrl, {
                    appendReferrer: playUrl,
                });
            }
            return false;
        }
        if (featuresConfig.enableAccountUpgrade && this.claimsConfig.get('accBusinessPhase') == 'in-shop') {
            //this.navigator.goTo('http://qa2.www.coral.co.uk/en/mobileportal/initaccountupgrade?source=guard&skip=false');
            this.navigator.goTo(bingoPage?.accountUpgradeUrl?.url);
        }

        return true;
    }

    launchDesktopGame(redirectUrl: any): void {
        this.launchGame(redirectUrl);
    }
    setToSession(key: any, value: any) {
        this.session.set(key, value);
    }

    launchGame(redirectUrl: any) {
        const featuresConfig: FeaturesClientConfig = this.clientConfigProvider.provideFeaturesConfig();
        redirectUrl = decodeURIComponent(redirectUrl);
        //add dynacon to fetch which labels need iframe approach
        if (this.isInGameClient) {
            window.parent.postMessage({ type: 'LaunchGame', data: redirectUrl }, '*');
            return true;
        }
        if (this.bingoWidget.isBingoWidget()) {
            const forceUrl = decodeURIComponent(redirectUrl);
            this.bingoWidget.postMessage('redirectUrl_bingo', { url: forceUrl });
            return true;
        }
        if (featuresConfig.enableGameLaunchinPortalIframe) {
            this.loadGameinBingoPortalIframe.next(redirectUrl);
        } else {
            this.navigator.goTo(redirectUrl);
        }
        return false;
    }

    getGameLaunchUrl(
        gameName: string,
        gameType: string,
        ballType: string,
        launchType: string,
        gameIcon: string,
        gameId: string,
        favstatus: boolean,
        originUrl: string,
        featureType: string,
        themeId: string,
        originGameId: string,
        isReplaceGameSrc: boolean,
        lastLoginTime: number,
        showLobby: boolean,
        vnChannel: string,
        isBingoTournaments: boolean,
    ): Observable<any> {
        const postObj = {
            gameName: gameName,
            gameType: gameType,
            ballType: ballType,
            launchType: launchType,
            gameIcon: gameIcon,
            gameId: gameId,
            favstatus: favstatus,
            originUrl: originUrl,
            featureType: featureType,
            themeId: themeId,
            originGameId: originGameId,
            isReplaceGameSrc: isReplaceGameSrc,
            lastLoginTime: lastLoginTime,
            isInGameClient: this.isInGameClient,
            showLobby,
            vnChannel: vnChannel,
            isBingoTournaments: isBingoTournaments,
        };
        return this.api.post('bingogamelaunch', postObj, { withCredentials: true });
    }

    getCasinoGameLaunchUrl(variantName: string): Observable<any> {
        return this.api.get('gamelaunch', { variantName: variantName });
    }

    getConfigDataFromLocalstorage() {
        if (!this.globalMessages && localStorage.getItem('config')) {
            const globalConfig = JSON.parse(localStorage.getItem('config') || '');
            this.globalMessages = globalConfig.global;
        }
    }
}
