<div class="mobile-stories-cta">
    <div class="with-cta">
        <!-- <img [src]="storyItem.backgroundImage.src" /> -->
        <h2
            [ngClass]="'optinbtnposition-' + (subTitlePosition | lowercase)"
            [ngStyle]="{ 'color': subTitleTxtClr, 'font-size': subTitleFontSize }"
            *ngIf="storyItem.subTitle"
            [textContent]="storyItem.subTitle"></h2>
        <div
            [ngClass]="'optinbtnposition-' + (descriptionPosition | lowercase)"
            [ngStyle]="{ 'color': descriptionClr, 'font-size': descriptionFontSize }"
            *ngIf="storyItem.description"
            class="story-descrip"
            [textContent]="storyItem.description"></div>
        <div class="mobile-stories-optin" [ngClass]="'optinbtnposition-' + (optinBtnPosition | lowercase)">
            <button
                *ngIf="displayOptIn"
                [ngStyle]="{ 'background-color': promoOptinBtnBgClr, 'color': promoOptinBtnClr }"
                class="btn btn-primary more-info-btn"
                [disabled]="optInDisabled"
                (click)="optInClicked()">
                {{ optInButtonText }}
            </button>
            <div class="optin-moreinfo" *ngIf="enableOptedInUrlBtn" (click)="onClick($event)">
                {{ optedInMoreInfoText }}
            </div>
            <span *ngIf="displayError" class="promoStatusError">{{ errorMessage }}</span>
        </div>
    </div>
</div>
