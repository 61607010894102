<div class="bingo-bt-betathebanker-prize-container">
    <div class="bt-info-container">
        <div class="info-title">{{ messages.beatBankerTitle }}</div>
        <div class="info-label">{{ messages.beatBankerDiscription }}</div>
    </div>

    <div class="bt-points-and-revards">
        <div class="bt-points">
            <span>{{ minPoints }} Pts</span>
        </div>
        <div class="bt-rewards">
            <span class="bt-reward1">{{ messages.beatBankerRevardText }}</span>
            <span *ngIf="race?.displayCash" class="bt-reward2">{{
                prizeValueForBeatBanker?.Description ? prizeValueForBeatBanker?.Description : prizeValueForBeatBanker?.value
            }}</span>
            <div *ngIf="race?.isDisplayCoin" class="bt-bingo-coin-reward">
                <span *ngIf="race?.displayCash" class="theme-plus"></span>
                <span class="bt-card-total-coin">
                    <img class="bt-coin-img" [src]="coinImg" alt="Coin Image" />
                    <span>{{ race?.coinValue }}</span>
                </span>
            </div>
        </div>
    </div>
</div>
