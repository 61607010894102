<div
    *ngIf="feature != 'ntogo' && feature.includes('togo'); else otherFeatures"
    [ngClass]="'feature-' + feature[0] + '-tg'"
    class="ui-icon-combo bingo-feature-icon-new feature-n-tg">
    <span class="ui-icon-combo__fg"> {{ feature[0] }}<span class="tg-text">TG</span> </span>
    <span class="ui-icon-combo__bg theme-bingo-badge"></span>
</div>

<ng-template #otherFeatures>
    <!-- For any new feature, add the image path in dynacon -->
    <div *ngIf="isFeatureImgPresent" class="bingo-feature-icon-new feature-{{ feature }}">
        <img class="feature-img" [src]="featureImgPath" alt="" />
    </div>

    <!-- Country mile feature has two featrue icons -->
    <div *ngIf="feature == 'country_miles'" class="bingo-feature-icon-new feature-country-miles-trophy">
        <img class="feature-img" [src]="countryMilesIcon" alt="" />
    </div>
</ng-template>

<span *ngIf="notaFeature"> - </span>
