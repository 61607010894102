import { Injectable } from '@angular/core';

import { BingoConfigProviderService } from '@frontend/bingo/core';
import { LoginDialogService, NavigationService, UserService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

import { RootServicesModule } from '../root-services.module';

@Injectable({
    providedIn: RootServicesModule,
})
export class LoginService {
    sessionBingofrocedUrl: string = '';
    packageBingofrocedUrl: string = '';
    constructor(
        private user: UserService,
        private loginDialog: LoginDialogService,
        private navigator: NavigationService,
        private configProviderService: BingoConfigProviderService,
        //private lhProductHomepagesConfig: ProductHomepagesConfig
    ) {}

    storesessionBingoForcedUrl(forcedUrl: string) {
        this.sessionBingofrocedUrl = forcedUrl;
    }
    getSessionBingoForcedUrl() {
        return this.sessionBingofrocedUrl;
    }
    clearnBingoforcedUrl() {
        this.sessionBingofrocedUrl = '';
        this.packageBingofrocedUrl = '';
    }
    storepackageBingoForcedUrl(forcedUrl: string) {
        this.packageBingofrocedUrl = forcedUrl;
    }
    getpackageBingoForcedUrl() {
        return this.packageBingofrocedUrl;
    }

    validateUser(redirectUrl: string): boolean {
        // if (!this.user.isAuthenticated) {

        //     this.loginDialog.open({ returnUrl: redirectUrl });
        //     return false;
        // }

        // return true;
        const bingoPageClientConfig = JSON.parse(localStorage.getItem('config')!)?.pageConfig?.value;

        if (!this.user.isAuthenticated) {
            if (redirectUrl.includes('menu/prebuy') || redirectUrl.includes('menu/launchroom') || redirectUrl.includes('menu/mytickets')) {
                this.loginDialog.whenReady.subscribe(() => {
                    const loginDialogRef = this.loginDialog.open({ returnUrl: redirectUrl });
                    const afterclosesubcription: Subscription = loginDialogRef.afterClosed().subscribe(() => {
                        if (afterclosesubcription) {
                            afterclosesubcription.unsubscribe();
                        }
                        if (!this.user.isAuthenticated) {
                            this.navigator.goTo(bingoPageClientConfig?.lobbyUrl?.url);
                        }
                    });
                });
                return false;
            } else {
                this.loginDialog.whenReady.subscribe(() => {
                    const loginDialogRef = this.loginDialog.open({ returnUrl: redirectUrl });
                    const afteropenessubcription: Subscription = loginDialogRef.afterOpened().subscribe(() => {
                        if (afteropenessubcription) {
                            const htmltag = document.getElementsByTagName('html');
                            if (htmltag && htmltag[0]) {
                                if (!htmltag[0].classList.contains('cdk-global-scrollblock')) {
                                    htmltag[0].classList.add('cdk-global-scrollblock');
                                }
                            }

                            afteropenessubcription.unsubscribe();
                        }
                    });
                    const afterclosesubcription: Subscription = loginDialogRef.afterClosed().subscribe(() => {
                        if (afterclosesubcription) {
                            const htmltag = document.getElementsByTagName('html');
                            if (htmltag && htmltag[0]) {
                                if (htmltag[0].classList.contains('cdk-global-scrollblock')) {
                                    htmltag[0].classList.remove('cdk-global-scrollblock');
                                }
                            }
                            afterclosesubcription.unsubscribe();
                        }
                    });
                });

                return false;
            }
        }
        //Vanilla 6.9.1 Screen name interceptor bug fix.
        // if (!this.user.screenname) {
        //     this.navigator.goTo(this.lhProductHomepagesConfig.portal + '/mobileportal/screenname', {
        //         appendReferrer: redirectUrl
        //     });

        //     return false;
        // }

        return true;
    }
}
