import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { BingoTournamentsService, SitecoreContent } from '@frontend/bingo/core';

@Component({
    selector: 'bgl-slot-races-tips',
    templateUrl: './bingo-tips.component.html',
})
export class BingoSlotRacesTipsComponent implements OnInit, OnDestroy {
    @Input() showHeader: boolean;
    @Input() isCollapsible: boolean;
    @Input() isExpanded: boolean;

    isVisible: boolean;
    panelOpenState: boolean;
    contentSubscription: any;
    sitecoreContent: SitecoreContent;
    isExpansionDisabled: string = '';

    constructor(private slotRacesService: BingoTournamentsService) {}

    ngOnInit() {
        this.showHeader = true;
        this.isVisible = false;
        this.changeCollapseState();
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.sitecoreContent = content;
        });
        this.ExpansionState();
    }
    ngOnDestroy() {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
    }

    changeCollapseState() {
        this.isVisible = !this.isVisible;
    }
    ExpansionState() {
        if (!this.isCollapsible) {
            this.isExpansionDisabled = 'disabled-pointer';
        }
    }
}
