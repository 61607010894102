import { Injectable } from '@angular/core';

import { ConfigProviderService } from '../config-provider/config-provider.service';
import { CozyBingoDataService } from './cozybingodata.service';

@Injectable({
    providedIn: 'root',
})
export class OtherProductService {
    constructor(
        private cozyBingoDataService: CozyBingoDataService,
        private configProviderService: ConfigProviderService,
    ) {}
    updateFavGamesForOtherProducts() {
        const cozybingoConfig = this.configProviderService.provideCozyBingoConfig();
        if (cozybingoConfig && cozybingoConfig.cozyBingoFavouritesInRpwidget) {
            this.cozyBingoDataService.getBingoData(false, false);
        }
    }
    updateRecentlyplayedGamesOnOtherProducts() {
        const cozybingoConfig = this.configProviderService.provideCozyBingoConfig();
        if (cozybingoConfig && cozybingoConfig.cozyBingoRecentGamesInRpwidget) {
            this.cozyBingoDataService.getBingoData(false, true);
        }
    }
}
