import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { CasinoPlatformLoaderService } from '@casinocore/loader';
import {
    BingoConfigProviderService,
    ConfirmMessageService,
    EventEmitService,
    FeaturesClientConfig,
    ScheduleService,
    TicketsResourceService,
} from '@frontend/bingo/core';
import { CurrencyPipe, DeviceService, NavigationService, Page, ProductHomepagesConfig, TrackingService } from '@frontend/vanilla/core';
import { BalancePropertiesService } from '@frontend/vanilla/features/balance-properties';
import { Subscription } from 'rxjs';

import { DesignSystemEnablerService } from '../../../frontend-lib/core/src/shared/services/ds-enabler.service';
import { TrackingModel } from './../shared/components/Tracking/TrackingModel';
import { TrackingModelService } from './../shared/components/Tracking/tracking-modal-service';
import { AlertMessageService } from './../shared/components/alert-message/alert-message.service';
import { BonusSupressionPopupComponent } from './../shared/components/bonus-supression/bonus-supression-popup.component';
import { IPrebuyFilterModel } from './models/prebuy-filter.model';
import { MyTicketsComponent } from './my-tickets/my-tickets.component';
import { PreBuyGridComponent } from './pre-buy-grid/pre-buy-grid.component';
import { OverlayComponent } from './purchase-error/overlay.component';

@Component({
    selector: 'bg-tickets',
    templateUrl: 'tickets.component.html',
    providers: [CurrencyPipe, TrackingModelService],
})
export class TicketsComponent implements IPrebuyFilterModel, OnInit, OnDestroy {
    selected_room_id: number;
    today_date: string;
    selectedDate: any;
    selectedGameSlot: any;
    dropdown_roomobj: any;
    showNumpad: boolean = false;
    prebuy_route_status: boolean = false;
    total_purchased_tickets: number = 0;
    total_purchase_price: any;
    tabSelectedIndex: number = 0;
    roomlist: any = [];
    datelist: any = [];
    date = new Date();
    timelist: any = [];
    selectedTicketsToBuy: any = [];
    ticketscollist: any;
    buyTicketsLabel: string;
    numpadSubscription: Subscription;
    isMobileView: boolean = false;
    showCloseBtn: boolean = true;
    purchase_status: any[] = [];
    isDataLoading: boolean = true;
    isDirectPrebuy: boolean = true;
    isLegacy: boolean = false;
    addFriendModalCss: boolean = false;
    bgRooms: any = [];
    globalClientConfig: any;
    EnableDesignSystems: boolean = false;
    validationService: any;
    disableAllPrebuy: boolean;
    clientConfig: any;
    button_text: string;
    @ViewChild(PreBuyGridComponent)
    PreBuyGridComponentChild: PreBuyGridComponent;
    @ViewChild(MyTicketsComponent)
    MyTicketsModalComponentChild: MyTicketsComponent;
    words: string[] = [
        '',
        'one',
        'two',
        'three',
        'four',
        'five',
        'six',
        'seven',
        'eight',
        'nine',
        'ten',
        'eleven',
        'twelve',
        'thirteen',
        'fourteen',
        'fifteen',
        'sixteen',
        'seventeen',
        'eighteen',
        'nineteen',
        'twenty',
        'thirty',
        'forty',
        'fifty',
        'sixty',
        'seventy',
        'eighty',
        'ninety',
    ];
    featuresConfig: FeaturesClientConfig;
    trackingModel: TrackingModel;

    constructor(
        private ticketResourceService: TicketsResourceService,
        public alertMessageService: AlertMessageService,
        public confirmMessageService: ConfirmMessageService,
        private router: ActivatedRoute,
        private eventEmitService: EventEmitService,
        private deviceService: DeviceService,
        private vnCurrency: CurrencyPipe,
        private balanceService: BalancePropertiesService,
        private scheduleService: ScheduleService,
        private casinoPlatformLoaderService: CasinoPlatformLoaderService,
        public dialogRef: MatDialogRef<OverlayComponent>,
        public dialogRefBonusSupression: MatDialogRef<BonusSupressionPopupComponent>,
        private dialog: MatDialog,
        public dialogRefTickets: MatDialogRef<TicketsComponent>,
        private productHomepagesConfig: ProductHomepagesConfig,
        private navigator: NavigationService,
        private designSystemEnablerService: DesignSystemEnablerService,
        private page: Page,
        private configProviderService: BingoConfigProviderService,
        private track: TrackingService,
        private tracking: TrackingModelService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.trackingModel = new TrackingModel();
        this.featuresConfig = this.configProviderService.provideFeaturesConfig();
        if (this.router.snapshot['_routerState'].url.includes('bingo/prebuy') && !this.router.snapshot.queryParams['hideHeader']) {
            let apiUrl: string = '';
            apiUrl = this.productHomepagesConfig.bingo + '/bingo/rooms';
            this.navigator.goTo(apiUrl);
        }
        if (this.router.snapshot.queryParamMap.has('roomId')) {
            this.selected_room_id = parseInt(this.router.snapshot.queryParamMap.get('roomId')!);
            this.showCloseBtn = false;
        }
        if (this.router.snapshot.queryParamMap.has('tabSelected')) {
            this.tabSelectedIndex = parseInt(this.router.snapshot.queryParamMap.get('tabSelected')!);
        }
    }

    ngOnInit() {
        this.isLegacy = this.designSystemEnablerService.isEnabled();
        if (localStorage.getItem('config')) {
            this.clientConfig = JSON.parse(localStorage.getItem('config')!);
            if (!this.globalClientConfig) this.globalClientConfig = this.clientConfig?.global;
        }
        this.disableAllPrebuy = this.featuresConfig.disableAllPrebuy;
        if (this.data && this.data.length != 0) {
            this.selected_room_id = this.data.selected_room_id;
            this.today_date = this.data.today_date;
            this.dropdown_roomobj = this.data.dropdown_roomobj;
            this.tabSelectedIndex = this.data.tabSelectedIndex;
            this.selectedDate = this.data.selectedDate;
            this.selectedGameSlot = this.data.selectedGameSlot;
        }
        const iframe_ingame_status = this.router.snapshot.queryParamMap.get('ingame');
        addEventListener('gs:productstub:entrypoint:active', this.entrypointactive);
        if (iframe_ingame_status !== null && iframe_ingame_status !== undefined) {
            if (iframe_ingame_status) {
                this.prebuy_route_status = true;
                document.getElementById('main-content')!.classList.add('iframe-custom-model');
                dispatchEvent(new CustomEvent('gs:hideFloatingEntry'));
            }
        }

        const sitecoreData: any[] = this.clientConfig?.bingoCategories;
        this.ticketscollist = sitecoreData?.find((t: any) => t['categoryType'] == 'tickets');
        this.buyTicketsLabel = this.stringFormat(this.ticketscollist?.columns?.buyTicketsCta, [this.total_purchased_tickets]);
        this.numpadSubscription = this.eventEmitService.getEventObservable().subscribe((event) => {
            if (event && event === 'ShowNumpad') {
                this.showNumpad = true;
            } else if (event && event === 'HideNumpad') {
                this.showNumpad = false;
            }
        });
        this.scheduleService.getGameData((response: any) => {
            if (response?.result?.games_list) {
                this.dropdown_roomobj = response.result.games_list;
                this.dropdown_roomobj = this.locationFilter(this.dropdown_roomobj);
                const lockedRoomsArray: any = this.scheduleService.getLockedRooms();
                this.dropdown_roomobj = this.dropdown_roomobj.filter(
                    (x: any) =>
                        lockedRoomsArray.indexOf(x.id) == -1 &&
                        !x.insurance &&
                        (!this.featuresConfig.enablePrivateRooms ||
                            !x.game_tags ||
                            (x.game_tags && !((x.game_tags.indexOf('newbie') > -1 || x.game_tags.indexOf('private') > -1) && !x.private_room))),
                );
                if (this.selected_room_id == 0 || this.selected_room_id === undefined) {
                    this.selected_room_id = this.dropdown_roomobj[0].id;
                }
                this.isDataLoading = false;
            }
        });
        this.today_date = '1';
        this.isMobileView = this.deviceService.isMobilePhone;
    }

    PurchaseStatus(purchase_arr: any) {
        for (const arr in purchase_arr) {
            if (!purchase_arr[arr]) return false;
        }
        return true;
    }

    BuyTickets() {
        if (this.selectedTicketsToBuy.length > 0) {
            this.purchase_status = [];
            this.selectedTicketsToBuy.forEach((element: any) => {
                const totalPurchasetickets = element.no_of_tickets + element.purchased_count;
                if (element.context.fomo_numbers != null) {
                    let message = '';
                    if (element.context.fomo_numbers.length < element.min_fomo_numbers) {
                        message = this.globalClientConfig['errors']['fabGrabNumbersErrorMessage']?.replace('{0}', element.min_fomo_numbers);
                        this.alertMessageService.error('checkmark_kick', message, true);
                        if (this.PreBuyGridComponentChild.Games != undefined) {
                            const foundIndex = this.PreBuyGridComponentChild.Games.find(
                                (x: any) => x.start_time == element.game_start_time && x.room_id == element.room_id,
                            );
                            foundIndex.isRedClsEnabled = true;
                        }
                        this.purchase_status.push(false);
                    }
                }
                if (element.purchased_count == 0) {
                    if (element.no_of_tickets < element.game_min_tickets) {
                        const message = this.globalClientConfig['errors']['minimumPurchasableCountOfTickets']?.replace(
                            '{0}',
                            element.game_min_tickets,
                        );
                        this.alertMessageService.error('checkmark_kick', message, true);
                        if (this.PreBuyGridComponentChild.Games != undefined) {
                            const foundIndex = this.PreBuyGridComponentChild.Games.find(
                                (x: any) => x.start_time == element.game_start_time && x.room_id == element.room_id,
                            );
                            foundIndex.isRedClsEnabled = true;
                        }
                        this.purchase_status.push(false);
                    } else if (totalPurchasetickets > element.game_max_tickets) {
                        const message = this.globalClientConfig['errors']['maximumPurchasableCountOfTickets']?.replace(
                            '{0}',
                            element.game_max_tickets,
                        );
                        this.alertMessageService.error('checkmark_kick', message, true);
                        this.purchase_status.push(false);
                        if (this.PreBuyGridComponentChild.Games.length > 0) {
                            const foundIndex = this.PreBuyGridComponentChild.Games.find(
                                (x: any) => x.start_time == element.game_start_time && x.room_id == element.room_id,
                            );
                            if (foundIndex != undefined) {
                                foundIndex.isRedClsEnabled = true;
                            }
                        }
                    } else {
                        this.purchase_status.push(true);
                    }
                } else if (totalPurchasetickets > element.game_max_tickets) {
                    const message = this.globalClientConfig['errors']['maximumPurchasableCountOfTickets']?.replace('{0}', element.game_max_tickets);
                    this.alertMessageService.error('checkmark_kick', message, true);
                    this.purchase_status.push(false);
                    if (this.PreBuyGridComponentChild.Games.length > 0) {
                        const foundIndex = this.PreBuyGridComponentChild.Games.find(
                            (x: any) => x.start_time == element.game_start_time && x.room_id == element.room_id,
                        );
                        if (foundIndex != undefined) {
                            foundIndex.isRedClsEnabled = true;
                        }
                    }
                } else {
                    this.purchase_status.push(true);
                }
            });
            const status_val = this.PurchaseStatus(this.purchase_status);
            if (status_val) {
                if (this.PreBuyGridComponentChild.fluffyRooms.indexOf(this.selectedTicketsToBuy[0].room_id) > -1)
                    this.PreBuyGridComponentChild.roomTracking(this.selectedTicketsToBuy[0].room_id, false, true);
                const cnfmessage =
                    this.total_purchased_tickets == 1 && this.globalClientConfig['messages'].confirmPurchaseSingleMessage
                        ? this.globalClientConfig['messages'].confirmPurchaseSingleMessage
                        : this.globalClientConfig['messages'].confirmPurchaseMessage;
                let message = this.stringFormat(cnfmessage, [this.total_purchased_tickets]);
                const header = this.globalClientConfig['messages'].confirmPurchaseHeader,
                    note = this.globalClientConfig['messages'].confirmPurchaseNote,
                    closeBtn = this.globalClientConfig['messages'].confirmCloseButton,
                    purchaseButtonText = this.globalClientConfig['messages'].confirmPreBuyButtonText;
                if (message.indexOf('$games$') > -1) {
                    message = message.replace('$games$', this.ConvertDigitsToWords(this.selectedTicketsToBuy.length));
                }
                if (message.indexOf('$price$') > -1) {
                    message = message.replace('$price$', this.vnCurrency.transform(this.total_purchase_price));
                }
                this.confirmMessageService.confirmThis(
                    header,
                    message,
                    note,
                    purchaseButtonText,
                    closeBtn,
                    () => {
                        if (this.PreBuyGridComponentChild.fluffyRooms.indexOf(this.selectedTicketsToBuy[0].room_id) > -1)
                            this.PreBuyGridComponentChild.cartTracking(this.selectedTicketsToBuy[0].room_id, true, false, false);
                        this.ticketResourceService
                            .buyTickets({
                                purchaseTickets: this.selectedTicketsToBuy,
                            })
                            .subscribe(
                                (response: any) => {
                                    let error_message = '';
                                    if (
                                        response != null &&
                                        response.responseCode == '0' &&
                                        (response.responseMessage == 'SUCCESS' || response.responseMessage == 'success')
                                    ) {
                                        if (response.result !== null) {
                                            this.showPurchaseSuccessAlert(response.result);
                                            this.balanceService.refresh();
                                            // that.alertMessageService.show('checkmark_kick', that.stringFormat(that.globalClientConfig['messages'].ticketsPurchasedSuccessfullyMessage, [that.total_purchased_tickets]));
                                            this.balanceService.refresh();
                                            this.PreBuyGridComponentChild.onSuccessfullPurchase(response.result);
                                            this.MyTicketsModalComponentChild.onSuccessfullPurchase(response.result);
                                        } else {
                                            error_message = this.globalClientConfig['errors']['technicalErrorMessage'];
                                        }
                                    } else if (response != null) {
                                        error_message = this.globalClientConfig['errors'][response.responseCode];
                                        if (error_message == undefined) error_message = this.globalClientConfig['errors']['technicalErrorMessage'];
                                    } else error_message = this.globalClientConfig['errors']['technicalErrorMessage'];
                                    if (response.responseCode == '104') {
                                        this.dialogRefBonusSupression = this.dialogRefBonusSupression = this.dialog.open(
                                            BonusSupressionPopupComponent,
                                            {
                                                panelClass: 'bonus-supression-container',
                                            },
                                        );
                                        this.dialogRefBonusSupression.afterClosed().subscribe(() => {});
                                    }
                                    if (error_message != '' && response.responseCode != 'MaxPlayerCapReached' && response.responseCode != '104') {
                                        this.alertMessageService.error('checkmark_kick', error_message, true);
                                    } else if (error_message != '' && response.responseCode == 'MaxPlayerCapReached') {
                                        this.dialogRef = this.dialog.open(OverlayComponent, {
                                            panelClass: 'purchase-failed-overlay',
                                            data: {
                                                heading: this.globalClientConfig['errors']['PurchaseFailed'],
                                                message: error_message,
                                            },
                                        });
                                        this.dialogRef.afterClosed().subscribe(() => {});
                                        this.PreBuyGridComponentChild.cartTracking(this.selectedTicketsToBuy[0].room_id, false, true, false);
                                    }
                                },
                                (error: any) => {
                                    if (error) {
                                        this.alertMessageService.error(
                                            'checkmark_kick',
                                            this.globalClientConfig['messages'].errorOccuredWhilePurchaseMessage,
                                        );
                                    }
                                },
                            );
                    },
                    () => {},
                );
                this.ticketsConfirmPopupTracking(this.selectedTicketsToBuy[0]?.room_id);
            }
        }
        this.trackPreBuyOverlay(this.tabSelectedIndex, '-buy tickets');
    }

    ticketsConfirmPopupTracking(roomid: any) {
        let currentRoom = this.dropdown_roomobj.filter((r: any) => {
            return r.id == roomid;
        })[0];
        this.trackingModel.eventName = 'contentView';
        this.trackingModel.CategoryEvent = this.prebuy_route_status ? 'bingo room' : 'bingo';
        this.trackingModel.LabelEvent = this.prebuy_route_status ? 'room interaction' : this.tabSelectedIndex == 0 ? 'pre-buy' : 'my tickets';
        this.trackingModel.ActionEvent = 'load';
        this.trackingModel.PositionEvent = currentRoom?.name;
        this.trackingModel.LocationEvent = 'buy tickets screen';
        this.trackingModel.EventDetails = 'buy tickets confirmation screen';
        this.tracking.submitTracking(this.trackingModel);
    }

    showPurchaseSuccessAlert(result: any) {
        let successCount = 0;
        let error_message = '';
        result.prebuy_responses.forEach((element: any) => {
            if (element.status == 'success') {
                successCount += element.prebuy_response.purchase_info[0].requested_ticket_count;
            } else if (element.status == 'error' && element.reason) error_message = element.reason.message;
        });
        if (successCount > 0)
            this.alertMessageService.show(
                'checkmark_kick',
                this.stringFormat(this.globalClientConfig['messages'].ticketsPurchasedSuccessfullyMessage, [successCount]),
            );
        else {
            this.alertMessageService.error('checkmark_kick', error_message);
        }
    }
    ConvertDigitsToWords(amount: string): string {
        amount = amount.toString();
        const n_length = amount.length;
        let words_string = '';
        if (n_length <= 9) {
            const n_array = [0, 0, 0, 0, 0, 0, 0, 0, 0];
            const received_n_array = [];
            for (let i = 0; i < n_length; i++) {
                received_n_array[i] = amount.substr(i, 1);
            }
            for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
                n_array[i] = parseInt(received_n_array[j]);
            }
            for (let i = 0, j = 1; i < 9; i++, j++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    if (n_array[i] == 1) {
                        n_array[j] = 10 + parseInt(n_array[j].toString());
                        n_array[i] = 0;
                    }
                }
            }
            let value = 0;
            for (let i = 0; i < 9; i++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    value = n_array[i] * 10;
                } else {
                    value = n_array[i];
                }
                if (value != 0) {
                    words_string += this.words[value] + ' ';
                }
                if (i == 6 && value != 0) {
                    words_string += 'hundred';
                }
            }
            words_string = words_string.split('  ').join(' ');
        }
        return words_string;
    }
    ClearTickets() {
        this.dialogRefTickets.close();
    }

    ClearSelection(tabChange?: boolean) {
        tabChange == true ? this.trackPreBuyOverlay(this.tabSelectedIndex, '') : this.trackPreBuyOverlay(this.tabSelectedIndex, '-clear');
        if (this.tabSelectedIndex == 0) this.PreBuyGridComponentChild.OnClearSelection();
        else if (this.tabSelectedIndex == 1) this.MyTicketsModalComponentChild.OnClearSelection();
    }

    Close() {
        // Clear tickets
        window.parent.postMessage('close', '*');
        if (this.PreBuyGridComponentChild) {
            this.PreBuyGridComponentChild.LoaderStatusChange();
        }
        this.dialogRefTickets.close();
    }

    locationFilter(gameData: any) {
        for (let i = 0; i < gameData.length; i++) {
            const roomid = gameData[i].id;
            if (this.bgRooms.length == 0 && localStorage.getItem('config')) {
                const roomsConfig = JSON.parse(localStorage.getItem('config') || '');
                this.bgRooms = roomsConfig.rooms;
            }
            this.bgRooms.forEach((data: any) => {
                if (roomid == data.roomId && data.disableRoom) {
                    gameData.splice(i, 1);
                    this.scheduleService.setlocationFilterRooms(data.roomId);
                    i = i - 1;
                }
            });
        }
        return gameData;
    }

    entrypointactive = (customEvent: CustomEvent) => {
        if (customEvent && customEvent.detail && customEvent.detail.activeFrom && customEvent.detail.activeFrom.toLowerCase() === 'bottom-sticky') {
            if (this.prebuy_route_status) dispatchEvent(new CustomEvent('gs:hideFloatingEntry'));
        }
    };
    TotalTicketsSelected(obj: any) {
        this.total_purchased_tickets = obj.totalPurchasedTickets;
        this.scheduleService.sendLocalData(obj.totalPurchasedTickets);
        this.total_purchase_price = obj.totalPrice;
        this.selectedTicketsToBuy = obj.purchaseTickets;
        this.scheduleService.sendPopupData(this.ticketscollist?.columns?.preBuy);
        this.buyTicketsLabel = this.stringFormat(this.ticketscollist?.columns?.buyTicketsCta, [this.total_purchased_tickets]);
        if (this.total_purchased_tickets == 1) this.buyTicketsLabel = this.buyTicketsLabel.slice(0, -1);
        // if (obj.totalPurchasedTickets > 0) {
        //     this.PreBuyGridComponentChild?.preBuySliderTracking();
        // }
    }

    TeamModalOpend(state: any) {
        this.addFriendModalCss = state;
    }

    OnTabSelected(index: number) {
        this.tabSelectedIndex = index;
        this.total_purchased_tickets = 0;
        this.total_purchase_price = 0;
        this.selectedTicketsToBuy = [];
        this.buyTicketsLabel = this.stringFormat(this.ticketscollist?.columns?.buyTicketsCta, [this.total_purchased_tickets]);
        this.ClearSelection(true);
    }

    TotalMyTicketsSelected(obj: any) {
        this.total_purchased_tickets = obj.totalPurchasedTickets;
        this.total_purchase_price = obj.totalPrice;
        this.selectedTicketsToBuy = obj.purchaseTickets;
        this.buyTicketsLabel = this.stringFormat(this.ticketscollist?.columns?.buyTicketsCta, [this.total_purchased_tickets]);
        if (this.total_purchased_tickets == 1) this.buyTicketsLabel = this.buyTicketsLabel.slice(0, -1);
    }

    stringFormat(str: string, objs: any[]) {
        let i = 0;
        for (const arg of objs) {
            const strToReplace = '{' + i++ + '}';
            str = str.replace(strToReplace, arg.toString() || '');
        }
        return str;
    }

    ngOnDestroy() {
        if (this.numpadSubscription) {
            this.numpadSubscription.unsubscribe();
        }
        removeEventListener('gs:productstub:entrypoint:active', this.entrypointactive);
    }

    trackPreBuyOverlay(index: any, action: string) {
        let id: any = this.selectedTicketsToBuy[0]?.room_id ? this.selectedTicketsToBuy[0]?.room_id : this.selected_room_id;
        let tab: string = index == 0 ? 'prebuy' : 'my tickets';
        let currentRoom = this.dropdown_roomobj.filter((r: any) => {
            return r.id == id;
        })[0];
        this.track.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': this.prebuy_route_status ? 'bingo room' : 'bingo',
            'component.LabelEvent': tab == 'prebuy' ? 'pre-buy' : tab,
            'component.ActionEvent': 'click',
            'component.PositionEvent': 'pre-buy overlay',
            'component.LocationEvent': currentRoom?.name,
            'component.EventDetails': tab + action,
            'component.URLClicked': 'not applicable',
        });
    }
}
