import { Component, OnInit } from '@angular/core';

import { DesignSystemEnablerService } from '../../../shared/services/ds-enabler.service';
import { ConfirmMessageService } from './confirm-message.service';

@Component({
    //moduleId: module.id,
    selector: 'bgl-confirm-message',
    templateUrl: './confirm-message.component.html',
})
export class ConfirmMessageComponent implements OnInit {
    message: any;
    isLegacy: boolean = false;
    constructor(
        private confirmMessageService: ConfirmMessageService,
        private designSystemEnablerService: DesignSystemEnablerService,
    ) {}
    ngOnInit() {
        this.isLegacy = this.designSystemEnablerService.isEnabled();
        this.confirmMessageService.getMessage().subscribe((message) => {
            this.message = message;
        });
    }
}
