import { NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';

import {
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    ITournamentIcon,
    JackpotDataService,
    UrlUtilityService,
} from '@casinocore/platform/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cc-slot-races-icon',
    templateUrl: 'tournament-icon.component.html',
    standalone: true,
    imports: [NgIf, MatGridListModule, NgFor, NgStyle],
})
export class TournamentIconComponent implements OnInit, OnDestroy {
    @Input() isDraggable: boolean = true;
    @Input() promoIcon: any;
    @Input() gameVariantList: string[];
    @Input() position: number;
    @Input() source: string;
    @Input() lobbyType: string;
    @Input() category: any;
    @Input() isClickable: boolean;
    @Input() isFreeTourney: boolean = false;
    @Input() isLive: boolean = false;
    @Input() spinProgress: any = {};
    @Input() cta: string[];

    @Output() openPickGameOverlay = new EventEmitter();
    @Output() launchSelectedGame = new EventEmitter<any>();

    defaultSrc: string;
    isMultiIcon: boolean = false;
    subIconCount: number = 1;
    displayJackpotRibbon: boolean = true;
    overlayCount: number = 0;
    overlayApplicable: boolean = false;
    gameVariantIconList: ITournamentIcon[] = [];
    singleIconSrc: string;
    gameConfig: any;
    activeCategory: string;
    jackpotDataSubscription: Subscription;
    jackpotTickingSubscription: Subscription;
    jackpotValue: string;
    jackpotConfig: any;
    showJackpots: boolean;
    enableJPTicking: boolean;
    showJackpotRibbon: boolean;
    jpBgColor: any;
    jpTextCOlor: any;
    jpRibbonStyles: { jpBgColor: string; jpTextColor: string };

    constructor(
        private configProviderService: ConfigProviderService,
        private casinoManager: CasinoManager,
        private urlUtilityService: UrlUtilityService,
        private jackpotDataService: JackpotDataService,
        private casinoLobbyService: CasinoLobbyService,
    ) {
        this.gameConfig = this.configProviderService.provideGameConfig();
    }

    ngOnInit() {
        //no promo icon to be shown for promos that have single games
        const feature: any = this.configProviderService.provideFeaturesConfig();
        this.defaultSrc = this.urlUtilityService.getGrayImageUrl();
        this.promoIcon = this.gameVariantList && this.gameVariantList.length ? this.promoIcon : null;
        this.activeCategory = this.category && this.category.categoryInfo && this.category.categoryInfo.categoryid;
        this.jackpotConfig = this.configProviderService.provideJackpotConfig();
        this.showJackpots = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, this.jackpotConfig.showJackpotOnGameTilesLobbyType);
        this.enableJPTicking = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, this.jackpotConfig.enableJpTickingForLobbyType);
        if (this.promoIcon && this.gameVariantList && this.promoIcon.src && this.promoIcon.alt !== 'DoNotDisplay') {
            this.singleIconSrc = this.promoIcon.src;
        } else {
            if (this.gameVariantList && this.gameVariantList.length) {
                if (this.gameVariantList.length > 1) {
                    this.isMultiIcon = true;
                    this.initIconAttributes();
                } else {
                    this.singleIconSrc = this.casinoLobbyService.getImagePath(this.gameVariantList[0], 'square');
                }
            }
        }
        this.showJackpotRibbon = this.showJackpots && !this.isMultiIcon && !this.promoIcon;
        if (!this.configProviderService.provideSlotRacesConfig().enableJpRibbonOnFreeTourney) {
            this.showJackpotRibbon = !this.isFreeTourney && this.showJackpotRibbon;
        }
        if (this.showJackpotRibbon) {
            this.jackpotDataSubscription = this.jackpotDataService.jackpotFeedObservable.subscribe((jackpots: any) => {
                if (jackpots) {
                    this.setJackpotValue(jackpots.get(this.gameVariantList[0]));
                }
            });
            if (this.enableJPTicking) {
                this.jackpotTickingSubscription = this.jackpotDataService.jpTickingObservable.subscribe((jackpots: any) => {
                    if (jackpots) {
                        this.setJackpotValue(jackpots.get(this.gameVariantList[0]));
                    }
                });
            }
        }
        if (feature.enableGameIconTheming) this.singleIconSrc = this.casinoLobbyService.appendTheme(this.singleIconSrc, this.gameVariantList?.[0]);
    }

    setJackpotValue(jackpot: any) {
        this.jackpotValue = jackpot && jackpot.amount;
        const enableRibbonConfig = this.configProviderService.provideJackpotConfig().enableRibbonConfig;
        if (enableRibbonConfig) {
            this.jpRibbonStyles = this.jackpotDataService.getJackpotRibbonStyles(this.jackpotValue);
        }
    }

    initIconAttributes() {
        if (this.gameVariantList.length > 4) {
            this.subIconCount = 4;
            this.overlayCount = this.gameVariantList.length - 3;
            this.overlayApplicable = true;
        } else {
            this.subIconCount = this.gameVariantList.length;
        }
        this.generateIconList();
    }

    generateIconList() {
        for (let i = 0; i < this.subIconCount; i++) {
            const feature: any = this.configProviderService.provideFeaturesConfig();
            const colspan: number = this.subIconCount === 2 || (this.subIconCount === 3 && i + 1 === 1) ? 2 : 1;
            let iconType = colspan == 2 ? 'landscape' : 'square';
            let icon = this.casinoLobbyService.getImagePath(this.gameVariantList[i], iconType);
            if (feature.enableGameIconTheming) icon = this.casinoLobbyService.appendTheme(icon, this.gameVariantList?.[i]);
            this.gameVariantIconList.push({
                src: icon,
                colspan: colspan,
            });
        }
        if (this.gameVariantIconList.length === 1) {
            this.singleIconSrc = this.gameVariantIconList[0].src;
            this.gameVariantIconList = []; //never use 'undefined' as assignment value
        }
    }

    ctaClick() {
        if (this.isClickable) {
            if (this.isMultiIcon || this.promoIcon) {
                this.openPickGameOverlay.emit();
            } else {
                this.launchSelectedGame.emit({ game: this.gameVariantList && this.gameVariantList[0], position: this.position });
            }
        }
        setTimeout(() => {
            this.ctaClickOverlayTopAllignment();
        }, 0);
    }

    onGameIconError(src: any): void {
        this.defaultSrc = src;
    }

    ngOnDestroy() {
        if (this.jackpotDataSubscription) {
            this.jackpotDataSubscription.unsubscribe();
        }
        if (this.jackpotTickingSubscription) {
            this.jackpotTickingSubscription.unsubscribe();
        }
    }

    attachClickEvent() {
        if (
            this.isFreeTourney &&
            (!this.isLive ||
                (this.isLive &&
                    ((this.cta && this.cta.length) || (this.cta && !this.cta.length && this.spinProgress.current == this.spinProgress.total))))
        )
            return false;
        else return true;
    }
    ctaClickOverlayTopAllignment() {
        const featuresConfig = this.configProviderService.provideFeaturesConfig();
        if (featuresConfig.enableOverlayMobileSearchTopPosition) {
            if (document.querySelector('.content-messages-header-top .content-message-container')) {
                const contentMessageElement: HTMLElement = document.querySelector('.content-messages-header-top .content-message-container')!;
                const srtc_overlay: HTMLElement = document.querySelector('.cdk-overlay-container')!;
                srtc_overlay.style.top = contentMessageElement.clientHeight + 'px';
            }
        }
    }
}
