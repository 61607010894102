import { Injectable } from '@angular/core';

import { BingoNpmManagerService } from '../bingo-npm-manager/services/bingo-npm-manager.service';

@Injectable({
    providedIn: 'root',
})
export class OnAppInitHandler {
    constructor(private bingoNpmManagerService: BingoNpmManagerService) {}

    execute() {
        this.bingoNpmManagerService.onAppInit();
    }
}
