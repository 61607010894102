import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import {
    CasinoCoreNpmParamsService,
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    DesignSystemEnablerService,
    Race,
    SitecoreContent,
    SlotRacesLive,
    SlotRacesService,
    SlotRacesTrackingService,
    UrlUtilityService,
} from '@casinocore/platform/core';
import { DecoratorClassDirective, SlotRaceCtaComponent } from '@frontend/casino-ui';
import { DeviceService, NavigationService, Page, RtmsService } from '@frontend/vanilla/core';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { Subscription, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { SlotRacesLeaderboardComponent } from '../leaderboard/leaderboard.component';
import { NotificationOverlayComponent } from '../notification-overlay/notification-overlay.component';
import { PickGameOverlayComponent } from '../pick-game-overlay/pick-game-overlay.component';
import { SlotRacesInfoComponent } from '../race-info/race-info.component';
import { SlotRaceCardComponent } from './card/card.component';

@Component({
    selector: 'cc-slot-races-cards',
    templateUrl: 'cards-wrapper.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        NgFor,
        SlotRaceCardComponent,
        SlotRacesLeaderboardComponent,
        SwiperComponent,
        DecoratorClassDirective,
        SlotRaceCtaComponent,
    ],
})
export class SlotRacesCardsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() coinImg: string;
    @Input() selectedOption: string;
    races: Race[] = [];
    showCarousel: boolean = false;
    enableJPTicking: boolean;
    showJackpots: boolean;
    jackpots: Map<string, any>;
    isNextButtonVisible: boolean;
    isPreButtonVisible: boolean;
    jackpotConfig: any;
    features: any;
    raceAddedDesktop: any;
    categoryName: string;
    slotRacesConfig: any;
    isUkHeading: boolean;
    contentSubscription: Subscription;
    racesSubscription: Subscription;
    jackpotSubscription: Subscription;
    resizeSubscription: Subscription;
    jackpotTickingSubscription: Subscription;
    rtmsSubscription: Subscription;
    currentLobbyType: string;
    isFreeplaytechinLive: boolean = false;
    messages: { [item: string]: string } = {};
    content: SitecoreContent = {
        raceRuleConfigs: [],
        rules: [],
        rulesBanner: {},
        placeholderCardImage: {},
        coinEconomy: {},
        textTranslations: { sharedList: {}, versionedList: {} },
        tips: [],
        errorMessages: { sharedList: {}, versionedList: {} },
        entryDetails: { sharedList: {}, versionedList: {} },
    };

    totalLeaderboardRanks: number;
    updateLeaderboard: boolean;
    nextUpcoming: Race | undefined;
    nearestEndingTime: number;
    enableUpcomingOptinForPlaytech: boolean;
    @Input() showLeaderboard: boolean = false;
    @Input() addUpcoming: boolean = false;
    @Input() showPlaceholderCard: boolean = false;
    @Input() lobbyType: string;
    @Input() category: any;
    @Input() liveRacesList: any;
    @Input() enableLiveRacesFilter: boolean;

    @Output() isPlaytechinLive = new EventEmitter<boolean>();
    elms: number;
    trackingClass: string = '';
    isSubCategory: boolean;
    enableBetterVisualization: boolean;
    isLegacy: boolean = false;

    swiperOptions: SwiperOptions = {
        autoHeight: true,
        allowTouchMove: false,
        spaceBetween: 1,
        slidesPerView: 3,
        navigation: true,
        preventClicks: false,
        preventClicksPropagation: false,
    };

    readonly windowWidth = this.deviceService.windowWidth;

    constructor(
        private slotRacesService: SlotRacesService,
        private dialog: MatDialog,
        private casinoManager: CasinoManager,
        private configProviderService: ConfigProviderService,
        private navigation: NavigationService,
        private slotRacesTracking: SlotRacesTrackingService,
        private page: Page,
        private urlUtilityService: UrlUtilityService,
        private casinoLobbyService: CasinoLobbyService,
        private rtmsService: RtmsService,
        private casinoCoreNpmParamsService: CasinoCoreNpmParamsService,
        private deviceService: DeviceService,
        private dsEnablerService: DesignSystemEnablerService,
        private destroyRef: DestroyRef,
    ) {}

    ngOnInit() {
        this.isSubCategory = this.category?.categoryInfo?.subcategoryid ? true : false;
        this.raceAddedDesktop = this.raceAdded.bind(this);
        this.slotRacesService.setSlotRaces(this.lobbyType);
        this.categoryName = (this.category && this.category.categoryInfo && this.category.categoryInfo.categoryname) || 'Casino Tournaments';
        this.features = this.configProviderService.provideFeaturesConfig();
        this.slotRacesConfig = this.configProviderService.provideSlotRacesConfig();
        this.enableUpcomingOptinForPlaytech = this.slotRacesConfig.enableUpcomingOptinForPlaytech;
        this.slotRacesService.startLiveSlotPolling();

        this.initShowCarousel();
        this.enableBetterVisualization = this.slotRacesConfig.enableBetterVisualization;
        this.subscribeToObservables();
        this.isLegacy = !this.dsEnablerService.isEnabled();
    }

    initShowCarousel() {
        this.showCarousel = this.casinoManager.isTouch()
            ? this.windowWidth() === 1024 && this.showPlaceholderCard
                ? true
                : (this.enableBetterVisualization ? this.windowWidth() === 310 : this.windowWidth() === 320) || this.windowWidth() > 1023
                  ? !this.showPlaceholderCard
                  : this.windowWidth() === 768
                    ? this.showPlaceholderCard
                    : false
            : true;
    }

    showCarouselArrows() {
        this.initShowCarousel();
        if (this.showCarousel) {
            // this.setCarouselConfigs();
        }
    }

    subscribeToObservables(): void {
        this.initRaces();
        this.resizeSubscription = this.casinoManager.reSizeObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((width: any) => {
            if (width) {
                this.showCarouselArrows();
            }
        });

        this.rtmsSubscription = this.rtmsService.messages.pipe(filter((m) => m.type === 'FREE_TECH_OPTIN_LIMIT_EXCEED')).subscribe((message) => {
            if (message.type == 'FREE_TECH_OPTIN_LIMIT_EXCEED' && this.races.length > 0 && message.payload) {
                this.races.forEach((race: Race) => {
                    if (race.promoId == message.payload.promoId && race.slotUniqueId == message.payload.slotId) {
                        race.targetedPlayerCount = race.optinLimit;
                        return;
                    }
                });
            }
        });

        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.content = content;
            this.messages = content && content.textTranslations ? content.textTranslations.versionedList : {};
            this.coinImg = this.content?.coinEconomy?.image?.src;
        });
    }

    ngOnChanges() {
        this.features = this.configProviderService.provideFeaturesConfig();
        this.jackpotConfig = this.configProviderService.provideJackpotConfig();
        if (this.currentLobbyType !== this.lobbyType) {
            this.currentLobbyType = this.lobbyType;
            this.isUkHeading = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, this.features.isUkHeading);
            this.showJackpots = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, this.jackpotConfig.showJackpotOnGameTilesLobbyType);
            this.enableJPTicking = this.casinoManager.IsFeatureEnabledforLobbytype(this.lobbyType, this.jackpotConfig.enableJpTickingForLobbyType);
            this.slotRacesService.setSlotRacesParams(this.currentLobbyType);
        }
        if (this.races && this.races.length > 0) {
            if (this.enableLiveRacesFilter) {
                this.races = [...this.liveRacesList];
            }
            this.updateRaces();
        }
    }

    initRaces(): void {
        if (this.addUpcoming) {
            this.racesSubscription = combineLatest(this.slotRacesService.liveRacesData, this.slotRacesService.upcomingRacesData).subscribe(
                (races: any) => {
                    //copying data in order to avoid reference sharing
                    let liveRaces = races[0] && races[0].liveNow && races[0].liveNow.slice(0, races[0].liveNow.length);
                    if (this.enableLiveRacesFilter) {
                        liveRaces = [...this.liveRacesList];
                    }
                    const upcomingToday = races[1] && races[1].upcomingToday && races[1].upcomingToday.slice(0, races[1].upcomingToday.length);
                    const upcomingTomorrow =
                        races[1] && races[1].upcomingTomorrow && races[1].upcomingTomorrow.slice(0, races[1].upcomingTomorrow.length);

                    const totalRaceCards = this.slotRacesConfig.noOfCards - 1;
                    if (liveRaces && liveRaces.length >= totalRaceCards) {
                        this.races = liveRaces.slice(0, totalRaceCards);
                    } else {
                        if (liveRaces) {
                            this.races = liveRaces;
                        }
                        if (upcomingToday && upcomingToday.length) {
                            const optedInRaces = upcomingToday.filter((r: Race) => {
                                return r.ctaList.indexOf('OPTIN') === -1;
                            });
                            this.races = this.races.concat(optedInRaces.slice(0, totalRaceCards - this.races.length));
                            if (this.races.length < totalRaceCards) {
                                const notOptedInRaces = upcomingToday.filter((r: Race) => {
                                    return r.ctaList.indexOf('OPTIN') !== -1;
                                });
                                this.races = this.races.concat(notOptedInRaces.slice(0, totalRaceCards - this.races.length));
                            }
                        }
                        if (this.races.length < totalRaceCards) {
                            if (upcomingTomorrow && upcomingTomorrow.length) {
                                const optedInRaces = upcomingTomorrow.filter((r: Race) => {
                                    return r.ctaList.indexOf('OPTIN') === -1;
                                });
                                this.races = this.races.concat(optedInRaces.slice(0, totalRaceCards - this.races.length));
                                if (this.races.length < totalRaceCards) {
                                    const notOptedInRaces = upcomingTomorrow.filter((r: Race) => {
                                        return r.ctaList.indexOf('OPTIN') !== -1;
                                    });
                                    this.races = this.races.concat(notOptedInRaces.slice(0, totalRaceCards - this.races.length));
                                }
                            }
                        }
                    }
                    this.updateRaces();
                },
            );
        } else {
            this.racesSubscription = this.slotRacesService.liveRacesData.subscribe((races: SlotRacesLive) => {
                if (races && races.liveNow) {
                    this.races = races.liveNow.slice(0, races.liveNow.length);
                    if (this.enableLiveRacesFilter) {
                        this.races = [...this.liveRacesList];
                    }
                    this.updateRaces();
                }
            });
        }
        this.showCarouselArrows();
    }

    updateRaces(): void {
        this.isFreeplaytechinLive = false;
        if (this.showCarousel && this.races && this.races.length && this.showPlaceholderCard) {
            this.races.push(this.races[0]);
        }

        if (this.showCarousel) {
            this.setCarouselConfigs();
        }
        if (this.races && this.races.length > 0) {
            this.setUpdateStatus();
            if (this.category && this.category.categoryInfo) this.trackingClass = 'track_' + this.category.categoryInfo.categoryid;
            if (this.enableUpcomingOptinForPlaytech) {
                for (let i = 0; i < this.races.length; i++) {
                    if (this.races[i].isLive && this.races[i]?.subType?.toLowerCase() === 'free_play_tech') {
                        this.isFreeplaytechinLive = true;
                        break;
                    }
                }
                if (!this.isFreeplaytechinLive) {
                    for (let i = 0; i < this.races.length; i++) {
                        if (!this.races[i].isLive && this.races[i]?.subType?.toLowerCase() === 'free_play_tech') {
                            this.races[i].firstPlayTech = true;
                            break;
                        }
                    }
                }
            }
        }
        this.isPlaytechinLive.emit(this.isFreeplaytechinLive);
    }

    setUpdateStatus(): void {
        let totalRanks = 0;
        for (let i = 0; i < this.races.length; i++) {
            totalRanks += this.races[i].rankDetails && this.races[i].rankDetails.length;
        }
        if (totalRanks !== this.totalLeaderboardRanks) {
            this.totalLeaderboardRanks = totalRanks;
            this.updateLeaderboard = !this.updateLeaderboard;
        }
        this.nextUpcoming = this.races.find((r) => r.startDate.getTime() > Date.now());
        this.nearestEndingTime = this.races[0].endDate.getTime();
        for (let i = 0; i < this.races.length; i++) {
            if (this.races[i].endDate.getTime() < this.nearestEndingTime) {
                this.nearestEndingTime = this.races[i].endDate.getTime();
            }
        }
    }

    showFullLeaderboard(race: Race): void {
        this.openDialog(race, 'race-info');
    }

    gotoSlotRaces(): void {
        const activeCategory = this.casinoManager.getActiveCategory();
        this.slotRacesTracking.track('See upcoming - CTA Clicked', 'not applicable', activeCategory, 'User clicked on see upcoming Link');

        const casinoBaseUrl = this.urlUtilityService?.getCategoryNavigationBaseUrl();
        let categoryRoute;

        //this would be the case when the carousel is loaded inside casino lobby from a subcategory and hence the linked category info from lmt would be available
        if (this.category && this.category.categoryInfo && this.category.categoryInfo.linkedCategory) {
            const categoryInfo = this.casinoLobbyService.getCategoryInfoOnLMTId(this.lobbyType, this.category.categoryInfo.linkedCategory);
            categoryRoute = categoryInfo.route;
        } else {
            //this is the case when only the category id is passed ( as configured in dynacon ) through the npm package flow from slot races widget component
            categoryRoute = this.category && this.category.categoryInfo && this.category.categoryInfo.route;
        }

        //for the case of npm package, either keep the configured lobby type for other products on widget the same ans the lobby type where slot races is configured.. or else define the same product in the lobby type maps for that lobby type and invoker product as is defined for wherever slot races is configured
        const url = casinoBaseUrl + '/' + this.page?.lang + '/' + this.casinoManager?.getLobbyUrlPrefix(this.lobbyType) + '/c/' + categoryRoute;
        if (url) {
            this.navigation.goTo(url);
        }

        /* if (this.category.categoryInfo.linkedCategory) {
            let casinoBaseUrl = this.urlUtilityService.getCategoryNavigationBaseUrl();
            let categoryInfo = this.casinoLobbyService.getCategoryInfoOnLMTId(this.lobbyType, this.category.categoryInfo.linkedCategory);
            let catRoute = categoryInfo.route;
            let url = casinoBaseUrl + '/' + this.page.lang + '/' + this.casinoManager.getLobbyUrlPrefix(this.lobbyType) + '/c/' + catRoute;
            this.navigation.goTo(url);
        } */
    }

    openDialog(race: Race, dialog: 'race-info' | 'pick-game' | 'notification') {
        const component =
            dialog === 'race-info' ? SlotRacesInfoComponent : dialog === 'pick-game' ? PickGameOverlayComponent : NotificationOverlayComponent;
        this.openDialogOverlay(race, component);
    }

    openDialogOverlay(race: Race, component: any) {
        this.dialog.closeAll();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        this.dialogProperties(dialogConfig);
        dialogConfig.data = {
            race: race,
            category: this.category,
            lobbyType: this.lobbyType,
            enableOptin: this.enableUpcomingOptinForPlaytech && race.firstPlayTech && !this.isFreeplaytechinLive,
            coinImg: this.coinImg,
        };
        if (component == NotificationOverlayComponent) {
            dialogConfig.panelClass = 'casino-snotification-mat-dialog';
        } else {
            dialogConfig.panelClass = 'casino-sr-mat-dialog';
        }
        const dialogRef = this.dialog.open(component, dialogConfig);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                if (result.openRaceInfo) {
                    this.openDialog(race, 'race-info');
                } else if (result.openPickGameOverlay) {
                    this.openDialog(race, 'pick-game');
                } else if (result.openNotificationOverlay) {
                    this.openDialog(race, 'notification');
                } else if (result.errorCode) {
                    this.slotRacesService.addErrorMessage(result.errorCode);
                }
            }
        });
    }

    dialogProperties(dialogConfig: MatDialogConfig): void {
        if (window.matchMedia('(orientation: landscape)').matches && this.windowWidth() < 900) {
            dialogConfig.width = '100%';
            dialogConfig.height = '100%';
            dialogConfig.maxWidth = '100%';
            dialogConfig.maxHeight = '100%';
        } else if (this.windowWidth() >= 768) {
            dialogConfig.width = '668px';
        } else {
            dialogConfig.width = '100%';
            dialogConfig.height = '100%';
            dialogConfig.maxWidth = '100%';
            dialogConfig.maxHeight = '100%';
        }
    }

    setCarouselConfigs(): void {
        const casinoNpmParams = this.casinoCoreNpmParamsService.getcasinoCoreNpmParams();
        if (casinoNpmParams.isDll) {
            this.elms =
                this.windowWidth() === 320
                    ? 1
                    : this.windowWidth() === 768
                      ? 2
                      : this.races.length === 1 && !this.showPlaceholderCard
                        ? 1
                        : this.races.length >= 2 && !this.showPlaceholderCard
                          ? 2
                          : 3;
        } else {
            this.elms =
                this.windowWidth() === 320
                    ? 1
                    : this.windowWidth() === 768
                      ? 2
                      : this.windowWidth() === 1024
                        ? 3
                        : this.races.length === 1 && !this.showPlaceholderCard
                          ? 1
                          : this.races.length >= 2 && !this.showPlaceholderCard
                            ? 2
                            : 3.2;
        }
    }

    raceAdded(index: number, item: Race): string {
        if (item) {
            return item.isLive + '_' + item.promoId + '_' + item.slotUniqueId;
        } else {
            //this is a fix (workaround) added for the desktop carousel issue where the cards were not getting destroyed
            //until the actual trackBy in the carousel code is fixed - when (if) it is fixed - remove the else part
            //and the method raceAddedDesktop created with this method and bind()
            if (this.races) {
                let trackingString = '_';
                trackingString = this.nearestEndingTime + '_';
                if (this.nextUpcoming) {
                    trackingString += this.nextUpcoming.isLive + '_' + this.nextUpcoming.promoId + '_' + this.nextUpcoming.slotUniqueId;
                }
                trackingString += this.updateLeaderboard;
                return trackingString;
            }
            return 'no_races';
        }
    }

    ngOnDestroy() {
        this.slotRacesService.stopLiveSlotPolling();

        if (this.contentSubscription) this.contentSubscription.unsubscribe();
        if (this.jackpotSubscription) this.jackpotSubscription.unsubscribe();
        if (this.jackpotTickingSubscription) this.jackpotTickingSubscription.unsubscribe();
        if (this.resizeSubscription) this.resizeSubscription.unsubscribe();
        if (this.racesSubscription) {
            this.racesSubscription.unsubscribe();
        }
        if (this.rtmsSubscription) {
            this.rtmsSubscription.unsubscribe();
        }
    }
}
