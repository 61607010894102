import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BingoConfigProviderService, FeaturesClientConfig } from '@frontend/bingo/core';

@Component({
    selector: 'bg-collapsible-container',
    templateUrl: './collapsible-container.component.html',
})
export class CollapsibleContainerComponent implements OnInit {
    showIconImage: boolean = false;
    @Input()
    isCollapsed = true;
    @Input()
    title = '';
    @Input()
    subTitle = '';
    @Input()
    gameCategory = '';
    @Input()
    jackpot = false;
    @Input()
    image = '';
    @Input()
    iconimage = '';
    @Output()
    toggle: EventEmitter<boolean> = new EventEmitter<boolean>();
    featureConfig: FeaturesClientConfig;
    constructor(private configProviderService: BingoConfigProviderService) {
        this.featureConfig = this.configProviderService.provideFeaturesConfig();
    }

    ngOnInit() {
        this.showIconImage = this.featureConfig.showIconImage;
    }

    toggleContainer(event: any) {
        // Check to see if space or enter were pressed
        if (event.key === ' ' || event.key === 'Enter') {
            // Prevent the default action to stop scrolling when space is pressed
            event.preventDefault();
        }
        this.isCollapsed = !this.isCollapsed;
        this.toggle.emit(this.isCollapsed);
    }
}
