import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { BingoConfig, BingoConfigProviderService, ConfirmMessageService, FeaturesClientConfig, RoomsInfoService } from '@frontend/bingo/core';
import { LoadingIndicatorService, MessageQueueService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

import { TrackingModel } from '../../shared/components/Tracking/TrackingModel';
import { TrackingModelService } from '../../shared/components/Tracking/tracking-modal-service';
import { MyRoom, MyTicket } from '../models/my-tickets-models';
import { TeamModalPopupComponent } from '../pre-buy-grid/team-modal-popup/team-modal-popup.component';
import { TopandtailPopupComponent } from '../pre-buy-grid/topandtail-popup/topandtail-popup.component';
import { AlertMessageService } from './../../shared/components/alert-message/alert-message.service';
import { MyTicketsResourceService } from './my-tickets-resource.service';

@Component({
    selector: 'bg-my-tickets',
    templateUrl: 'my-tickets.component.html',
    providers: [DatePipe],
})
export class MyTicketsComponent implements OnInit, OnDestroy {
    @Output()
    OnTotalMyTicketsSelected: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    OnTeamModalOpen: EventEmitter<any> = new EventEmitter<any>();
    rooms: MyRoom[] = [];
    roomClientConfig: any = [];
    isCollapsed = true;
    currentGame: any;
    myticketscollist: any;
    maximumTicketsReached: string;
    sitecoreContent: any[];
    roomImageDataList: any[];
    hasRecords: boolean = true;
    noTicketsPurchasedMessage: string;
    numpadSubscription: Subscription;
    showNumpad: boolean = false;
    showAddTickets: boolean = true;
    rowIndex: string;
    gameId: number;
    prebuyIconslist: any;
    timestamp_string: any;
    bingoGameMappings: any;
    prebuy_route_status: boolean = false;
    default_purchased_count: number = 0;
    teamColors: any;
    currentRow_Id: any;
    prebuynavlist: any;
    currentCol_Id: any;
    _selectedTickets: any = [];
    _totalPurchasedTickets: number = 0;
    _totalPrice: number = 0;
    randomTeamText: string;
    friendsRoomIds: number[];
    fabGrabRoomIds: number[];
    physicalPrizeText: string;
    bonusPrizeText: string;
    enableBonusImplementation: boolean;
    prebuynavcolitems: any;
    collapse: boolean;
    globalClientConfig: any;
    disableAllPrebuy: boolean;
    clientConfig: any;
    //imageDataList:any;
    bingoClientConfig: BingoConfig;
    featureConfig: FeaturesClientConfig;
    trackingModel: TrackingModel;

    // @ViewChild(PreBuyGridComponent)
    // PreBuyGridComponentChild: PreBuyGridComponent;

    constructor(
        private myTicketService: MyTicketsResourceService,
        private alertMessageService: AlertMessageService,
        private loadingIndicatorService: LoadingIndicatorService,
        private messageQueueService: MessageQueueService,
        private confirmMessageService: ConfirmMessageService,
        private datePipe: DatePipe,
        private roomsInfoService: RoomsInfoService,
        private activeroute: ActivatedRoute,
        private configProviderService: BingoConfigProviderService,
        private dialog: MatDialog,
        private tracking: TrackingModelService,
        //private route: ActivatedRoute
    ) {
        this.bingoClientConfig = this.configProviderService.provideBingoConfig();
        this.featureConfig = this.configProviderService.provideFeaturesConfig();
        this.getMyTickets();
        this.trackingModel = new TrackingModel();
    }

    ngOnInit() {
        if (localStorage.getItem('config')) {
            this.clientConfig = JSON.parse(localStorage.getItem('config')!);
            if (!this.globalClientConfig) this.globalClientConfig = this.clientConfig?.global;
            if (this.roomClientConfig.length == 0) this.roomClientConfig = this.clientConfig?.rooms;
        }
        const iframe_ingame_status = this.activeroute.snapshot.queryParamMap.get('ingame');
        if (iframe_ingame_status !== null && iframe_ingame_status !== undefined) {
            if (iframe_ingame_status) {
                this.prebuy_route_status = true;
            }
        }
        const sitecoreData: any[] = this.clientConfig?.bingoCategories;
        this.myticketscollist = sitecoreData?.find((t: any) => t['categoryType'] == 'mytickets');
        this.maximumTicketsReached = this.globalClientConfig['messages'].maximumTicketsPurchased;
        this.noTicketsPurchasedMessage = this.globalClientConfig['messages'].noTicketsPurchasedMessage;
        this.roomImageDataList = JSON.parse(JSON.stringify(this.roomClientConfig)!);
        this.messageQueueService.clear();
        this.bingoGameMappings = this.clientConfig?.pageConfig?.value['bingoGameMappings'];
        this.prebuynavcolitems = sitecoreData?.find((t: any) => t['categoryType'] == 'prebuytab');
        if (this.prebuynavcolitems?.columns?.teamColors) this.teamColors = JSON.parse(this.prebuynavcolitems?.columns?.teamColors);
        this.randomTeamText = this.prebuynavcolitems?.columns?.randomTeamtxt;
        if (this.globalClientConfig?.messages?.friendsRoomIds) {
            this.friendsRoomIds = this.globalClientConfig?.messages?.friendsRoomIds?.split(',')?.map((item: any) => {
                return parseInt(item, 10);
            });
        } else {
            this.friendsRoomIds = [];
        }
        if (this.globalClientConfig?.messages?.fabGrabRoomIds) {
            this.fabGrabRoomIds = this.globalClientConfig?.messages?.fabGrabRoomIds?.split(',')?.map((item: any) => {
                return parseInt(item, 10);
            });
        } else {
            this.fabGrabRoomIds = [];
        }

        this.enableBonusImplementation = this.featureConfig.enableBonusImplementation;
        this.prebuynavlist = this.clientConfig?.bingoCategories;
        this.physicalPrizeText = this.globalClientConfig?.messages?.physicalPrizeText;
        this.bonusPrizeText = this.globalClientConfig?.messages?.bonusPrizeText;
        this.prebuyIconslist = this.prebuynavlist?.find((x: any) => x.categoryType == 'rooms');
        this.disableAllPrebuy = this.featureConfig.disableAllPrebuy;
    }

    getGameNameFromMappings(id: number, name: string): string {
        return this.bingoGameMappings[id] == null || this.bingoGameMappings[id] == undefined ? name : this.bingoGameMappings[id];
    }

    dateMinHourformatter(unixTimeStamp: any): Date {
        const dt = new Date(unixTimeStamp * 1000);
        return dt;
    }

    onSuccessfullPurchase(result: any) {
        this.OnClearSelection();
        result.prebuy_responses.forEach((element: any) => {
            if (element.status == 'success') {
                const room = this.rooms.find((x: any) => x.room_id == element.prebuy_response.purchase_info[0].room_id);
                if (room) {
                    this.currentGame = room.my_tickets.find((y: any) => y.start_time == element.prebuy_response.purchase_info[0].start_time);
                    if (this.currentGame != undefined) {
                        this.currentGame.purchased_count = element.prebuy_response.purchase_info[0].purchased_count;
                        if (this.currentGame.game_context && this.currentGame.game_context.TeamBingo)
                            this.currentGame.game_context.TeamBingo = element.prebuy_response.purchase_info[0].game_context.TeamBingo;
                        if (this.currentGame.game_context && this.currentGame.game_context.FomoBingo) {
                            this.currentGame.game_context.FomoBingo = element.prebuy_response.purchase_info[0].game_context.FomoBingo;
                        }
                        if (element.prebuy_response.purchase_info[0].winning_amount.Cash)
                            this.currentGame.winning_amount.Cash = element.prebuy_response.purchase_info[0].winning_amount.Cash;
                        if (this.currentGame.game_context && this.currentGame.game_context.VariableCardStake) {
                            this.currentGame.game_context.VariableCardStake = element.prebuy_response.purchase_info[0].game_context.VariableCardStake;
                            const g = this.currentGame.game_context.VariableCardStake.winning_amount.filter((w: any) => {
                                return (
                                    w.price ==
                                    this.currentGame.game_context.VariableCardStake.price[
                                        this.currentGame.game_context.VariableCardStake.ticket_currency_key
                                    ]
                                );
                            });
                            this.currentGame.winning_amount =
                                g[0].winning_amount[this.currentGame.game_context.VariableCardStake.ticket_currency_key];
                        }
                        if (this.currentGame.game_context && this.currentGame.game_context.SlingoMultiStake) {
                            this.currentGame.game_context.SlingoMultiStake = element.prebuy_response.purchase_info[0].game_context.SlingoMultiStake;
                            this.currentGame.winning_amount = element.prebuy_response.purchase_info[0].winning_amount.Cash;
                        }
                    }
                }
            }
        });
    }

    OnClearSelection() {
        this.getMyTickets();
        this.OnTotalMyTicketsSelected.emit({
            totalPurchasedTickets: 0,
            totalPrice: 0,
            purchaseTickets: [],
        });
        this._totalPurchasedTickets = 0;
        this._selectedTickets = [];
        this._totalPrice = 0;
    }

    ngOnDestroy() {
        if (this.numpadSubscription) this.numpadSubscription.unsubscribe();
    }

    keyHandler(evt: any) {
        const pattern = /[0-9]/;
        const inputChar = String.fromCharCode(evt.charCode);
        if (!pattern.test(inputChar)) {
            evt.preventDefault();
        }
    }
    clearValue(evt: any) {
        evt.target.value = '';
    }

    onChange(evt: any, row_no: number, col_no: number) {
        const obj = {
            id: +row_no,
            selectedValue: +evt.target.value,
        };
        const eventValue = evt.target.value;
        if (eventValue.toString().includes('.') || isNaN(obj.selectedValue)) {
            this.clearValue(evt);
            evt.preventDefault();
        } else {
            this.Selected(row_no, col_no, obj);
        }
    }

    onBlurDetect(evt: any, row_no: number, col_no: number) {
        if (evt.target.value == '' || evt.target.value == null) {
            evt.target.value = 0;
        }
        const obj = {
            id: +row_no,
            selectedValue: +evt.target.value,
        };
        this.Selected(row_no, col_no, obj);
        //}
    }

    textBoxOnChange(row_id: any, col_id: any, max_tickets: number, purchased_count: number, evt: any) {
        if (+evt.target.value > +max_tickets - +purchased_count || +evt.target.value < 0) {
            this.alertMessageService.isError = true;
            evt.target.value = +max_tickets - +purchased_count;
            const message = this.globalClientConfig['messages']?.maximumPurchasableTickets?.replace('{0}', evt.target.value);
            this.alertMessageService.error('checkmark_kick', message, true);
        }
        evt.selectedValue = evt.target.value;
        this.Selected(row_id, col_id, evt);
    }

    Selected(row_id: any, col_id: any, obj: any) {
        if (!obj.selectedValue) obj.selectedValue = 0;
        this.currentGame = this.rooms[row_id].my_tickets[col_id];
        const prevVal = this.currentGame.current_purchased_count === undefined ? 0 : this.currentGame.current_purchased_count;
        this.currentGame.current_purchased_count = obj.selectedValue;
        //let _totalPurchasedTickets = 0;
        //let _totalPrice = 0;
        const currEnterTicketCount = parseInt(obj.selectedValue);
        const totalPurchasableTickets = this.currentGame.purchased_count + currEnterTicketCount;
        if (totalPurchasableTickets == 0) {
            if (currEnterTicketCount < this.currentGame.minimum_tickets) {
                this.currentGame.istxtRedCls = true;
            } else if (totalPurchasableTickets > this.currentGame.maximum_tickets) {
                this.currentGame.istxtRedCls = true;
            } else {
                this.currentGame.istxtRedCls = false;
            }
        } else if (totalPurchasableTickets > this.currentGame.maximum_tickets) {
            this.currentGame.istxtRedCls = true;
        } else {
            this.currentGame.istxtRedCls = false;
        }
        if (obj.selectedValue.toString().toLowerCase().includes('e')) {
            this.currentGame.istxtRedCls = true;
        }
        this._totalPurchasedTickets = this._totalPurchasedTickets - prevVal + this.currentGame.current_purchased_count;
        if (this.currentGame.isMultistake)
            this.currentGame.ticket_cost.Cash = parseFloat(
                this.currentGame.game_context.VariableCardStake.ticket_prices[this.currentGame.selected_price],
            );
        else if (this.currentGame.isSlingoMultiStake)
            this.currentGame.ticket_cost.Cash = parseFloat(
                this.currentGame.game_context?.SlingoMultiStake?.price_points_config[this.currentGame.selected_price],
            );

        this._totalPrice =
            this._totalPrice -
            prevVal * this.currentGame.ticket_cost.Cash +
            this.currentGame.current_purchased_count * this.currentGame.ticket_cost.Cash;
        const foundIndex = this._selectedTickets.findIndex((x: any) => x.game_start_time == this.currentGame.start_time);
        if (foundIndex >= 0) {
            if (this.currentGame.current_purchased_count == '0') this._selectedTickets.splice(foundIndex, 1);
            else {
                this._selectedTickets[foundIndex].no_of_tickets = this.currentGame.current_purchased_count;
                this._selectedTickets[foundIndex].context.team = this.currentGame.game_context.TeamBingo
                    ? this.currentGame.game_context.TeamBingo.current_team
                    : null;
                this._selectedTickets[foundIndex].context.fomo_numbers = this.currentGame.game_context.FomoBingo
                    ? this.currentGame.game_context.FomoBingo.fomo_numbers
                    : null;
                if (this.currentGame.isMultistake || this.currentGame.isSlingoMultiStake) {
                    const price = {};
                    if (this.currentGame.isMultistake)
                        price[this.currentGame.game_context.VariableCardStake.ticket_currency_key] = parseFloat(
                            this.currentGame.game_context.VariableCardStake.price[
                                this.currentGame.game_context.VariableCardStake.ticket_currency_key
                            ],
                        );
                    else
                        price[this.currentGame.game_context.SlingoMultiStake.ticket_currency_key] = parseFloat(
                            this.currentGame.game_context.SlingoMultiStake.price_points_config[this.currentGame.selected_price].price,
                        );
                    this._selectedTickets[foundIndex].context['price'] = price;
                }
            }
        } else {
            if (this.currentGame.current_purchased_count != '0') {
                const ticket: any = {
                    room_id: this.currentGame.room_id,
                    bingo_type: this.currentGame.bingo_type,
                    game_config_id: this.currentGame.game_config_id,
                    no_of_tickets: this.currentGame.current_purchased_count,
                    game_start_time: this.currentGame.start_time,
                    game_min_tickets: this.currentGame.minimum_tickets,
                    game_max_tickets: this.currentGame.maximum_tickets,
                    purchased_count: this.currentGame.purchased_count,
                    min_fomo_numbers:
                        this.currentGame.game_context.FomoBingo != null &&
                        this.currentGame.game_context.FomoBingo.minimum_numbers != null &&
                        this.currentGame.game_context.FomoBingo.minimum_numbers != ''
                            ? this.currentGame.game_context.FomoBingo.minimum_numbers
                            : 1,
                    context: {
                        team: this.currentGame.game_context.TeamBingo ? this.currentGame.game_context.TeamBingo.current_team : null,
                        fomo_numbers: this.currentGame.game_context.FomoBingo ? this.currentGame.game_context.FomoBingo.fomo_numbers : null,
                    },
                };
                if (this.currentGame.isMultistake || this.currentGame.isSlingoMultiStake) {
                    const price = {};
                    if (this.currentGame.isMultistake)
                        price[this.currentGame.game_context.VariableCardStake.ticket_currency_key] = parseFloat(
                            this.currentGame.game_context.VariableCardStake.price[
                                this.currentGame.game_context.VariableCardStake.ticket_currency_key
                            ],
                        );
                    else
                        price[this.currentGame.game_context.SlingoMultiStake.ticket_currency_key] = parseFloat(
                            this.currentGame.game_context.SlingoMultiStake.price_points_config[this.currentGame.selected_price].price,
                        );
                    ticket.context['price'] = price;
                }
                this._selectedTickets.push(ticket);
            }
        }

        // this.rooms.forEach((Room) => {
        //     Room.my_tickets.forEach((ticket) => {
        //         if (!ticket['current_purchased_count'])
        //             ticket['current_purchased_count'] = 0;
        //         if (ticket.ticket_cost) {
        //             _totalPurchasedTickets = +_totalPurchasedTickets + +ticket['current_purchased_count'];
        //             _totalPrice = _totalPrice + (ticket['current_purchased_count'] * ticket.ticket_cost.Cash);
        //             if (ticket['current_purchased_count'] > 0)
        //                 this._selectedTickets.push(
        //                     {
        //                         room_id: ticket.room_id, bingo_type: ticket.bingo_type,
        //                         game_config_id: ticket.game_config_id, no_of_tickets: ticket['current_purchased_count'],
        //                         game_start_time: ticket.start_time, game_min_tickets: ticket.minimum_tickets,
        //                         game_max_tickets: ticket.maximum_tickets, purchased_count: ticket.purchased_count,
        //                         context: { team: this.currentGame.game_context.TeamBingo ? this.currentGame.game_context.TeamBingo.current_team : null }
        //                     });
        //         }
        //     });
        // });
        this.myTicketsSliderTracking(this._totalPurchasedTickets);
        this.OnTotalMyTicketsSelected.emit({
            totalPurchasedTickets: this._totalPurchasedTickets,
            totalPrice: this._totalPrice,
            purchaseTickets: this._selectedTickets,
        });
    }

    addTickets(rowNum: any, row_no: any, col_no: any, purchased_count: any) {
        document.getElementById(rowNum)!.getElementsByClassName('ticket-mobile-purchase-hidden')[0].classList.add('ticket-purchase-cntr-show');
        document.getElementById(rowNum)!.getElementsByClassName('jackpot-col')[0].classList.add('ticket-purchase-cntr-hide');
        document.getElementById(rowNum)!.getElementsByClassName('ticket-mobile-purchase-display')[0].classList.add('ticket-purchase-cntr-hide');
        const field_class = rowNum + 'cls';
        const fieldclass_element = document.getElementsByClassName(field_class)[0] as HTMLTextAreaElement;
        fieldclass_element.blur();
        purchased_count = parseInt(fieldclass_element.value);
        const obj = {
            id: +row_no,
            selectedValue: purchased_count,
        };
        this.Selected(row_no, col_no, obj);
    }

    removeTickets(rowNum: any, row_no: any, col_no: any, purchased_count: any) {
        document.getElementById(rowNum)!.getElementsByClassName('ticket-mobile-purchase-hidden')[0].classList.remove('ticket-purchase-cntr-show');
        document.getElementById(rowNum)!.getElementsByClassName('jackpot-col')[0].classList.remove('ticket-purchase-cntr-hide');
        document.getElementById(rowNum)!.getElementsByClassName('ticket-mobile-purchase-display')[0].classList.remove('ticket-purchase-cntr-hide');
        const field_class = rowNum + 'cls';
        const fieldclass_element = document.getElementsByClassName(field_class)[0] as HTMLTextAreaElement;
        fieldclass_element.value = '0';
        fieldclass_element.blur();
        purchased_count = parseInt(fieldclass_element.value);
        const obj = {
            id: +row_no,
            selectedValue: purchased_count,
        };
        this.Selected(row_no, col_no, obj);
    }

    getMyTickets() {
        this.messageQueueService.clear();
        const _loadingIndicator = this.loadingIndicatorService.start();
        this.myTicketService.getTickets().subscribe(
            (response: any) => {
                _loadingIndicator.done();
                const responseData: any = response;
                if (response == null)
                    this.alertMessageService.error('checkmark_kick', this.globalClientConfig['messages']?.errorOccuredWhileretrieveMyTickets);
                if (responseData.responseCode == 0) {
                    //     let mapItems=responseData.result.history.filter( (obj:any)=>{
                    //     if(!obj.features.includes('session_bingo',0))
                    //         return obj;
                    // });
                    this.maptoRooms(responseData.result.history);
                    // this.maptoRooms(mapItems);
                } else {
                    this.alertMessageService.error('checkmark_kick', this.globalClientConfig['messages']?.errorOccuredWhileretrieveMyTickets);
                }
            },
            (error) => {
                if (error) {
                    _loadingIndicator.done();
                    this.alertMessageService.error('checkmark_kick', this.globalClientConfig['messages']?.errorOccuredWhileretrieveMyTickets);
                }
            },
        );
    }

    DateFormatChange(dateval: any) {
        const date = new Date(dateval * 1000);
        //let date_array = date_string.split('/');
        //let formatted_date;
        // if (date.getDate() < 10) {
        const formatted_date = (date.getDate() < 10 ? '0' : '') + date.getDate() + '/' + (+date.getMonth() + 1) + '/' + date.getFullYear();
        // } else {
        //     formatted_date = date.getDay() + '/' + date.getMonth() + '/' + date.getFullYear();
        // }
        return formatted_date;
    }

    selectTeam(row_id: any, col_id: any) {
        this.addzIndexClass();
        this.currentCol_Id = col_id;
        this.currentRow_Id = row_id;
        this.currentGame = this.rooms[row_id].my_tickets[col_id];
        if (this.currentGame != undefined) {
            const requiredModalData = {
                currentGame: this.currentGame,
                teamColors: this.teamColors,
            };
            const dialogRef = this.dialog.open(TeamModalPopupComponent, {
                data: requiredModalData,
                panelClass: 'team-modal-popup',
            });
            dialogRef.componentInstance.onteamSelected.subscribe((response) => {
                if (response) {
                    this.onTeamSelected(response.team);
                }
            });
            dialogRef.afterClosed().subscribe(() => {
                this.onNumbersClose();
            });
            this.OnTeamModalOpen.emit(true);
            // this.PreBuyGridComponentChild?.preBuySliderTracking();
        }
    }

    onTeamSelected(team: any) {
        this.removezIndexClass();
        if (team == '') {
            if (this.currentGame.game_context.TeamBingo.current_team == null) {
                this.onRandomSelected();
            }
        } else this.currentGame.game_context.TeamBingo.current_team = team;
        this.OnTeamModalOpen.emit(false);
        this.Selected(this.currentRow_Id, this.currentCol_Id, {
            selectedValue: +this.currentGame.current_purchased_count,
        });
    }

    onRandomSelected() {
        const RandomSelectIndex = Math.floor(Math.random() * this.currentGame.game_context.TeamBingo.teams.length);
        this.currentGame.game_context.TeamBingo.current_team = this.currentGame.game_context.TeamBingo.teams[RandomSelectIndex];
        this.OnTeamModalOpen.emit(false);
    }

    selectNumbers(row_id: any, col_id: any) {
        this.addzIndexClass();
        this.currentCol_Id = col_id;
        this.currentRow_Id = row_id;
        this.currentGame = this.rooms[row_id].my_tickets[col_id];
        this.currentGame = this.rooms[row_id].my_tickets[col_id];
        if (this.currentGame != undefined) {
            if (this.currentGame.game_context.FomoBingo.fomo_numbers == null) this.currentGame.game_context.FomoBingo.fomo_numbers = [];
            if (this.currentGame.game_context.FomoBingo.maximum_numbers == null)
                this.currentGame.game_context.FomoBingo.maximum_numbers = this.globalClientConfig?.messages?.fabGrabMaxNumbers;
            const requiredModalData = {
                currentGame: this.currentGame,
                prebuynavcolitems: this.prebuynavcolitems,
            };
            const dialogRef = this.dialog.open(TopandtailPopupComponent, {
                data: requiredModalData,
                panelClass: 'top-n-tail-popup',
            });
            dialogRef.componentInstance.onSelected.subscribe((response) => {
                if (response) {
                    this.Selected(this.currentRow_Id, this.currentCol_Id, {
                        selectedValue: +response.currentGame.current_purchased_count,
                    });
                }
            });
            dialogRef.afterClosed().subscribe(() => {
                this.onNumbersClose();
            });
            this.OnTeamModalOpen.emit(true);
        }
    }

    onNumbersClose() {
        this.OnTeamModalOpen.emit(false);
        this.removezIndexClass();
    }

    toggle(value: boolean) {
        this.collapse = value;
    }
    addzIndexClass() {
        if (document.querySelector('.tickets-component')) {
            document.querySelector('.tickets-component')!.classList.add('prebuy-zindex');
        }
    }
    removezIndexClass() {
        if (document.querySelector('.tickets-component')) {
            document.querySelector('.tickets-component')!.classList.remove('prebuy-zindex');
        }
    }
    private maptoRooms(ticketsList: MyTicket[]): void {
        this.rooms = ticketsList
            .map((obj) => {
                return {
                    room_id: obj.room_id,
                    room_name: this.getGameNameFromMappings(obj.room_id, obj.display_name),
                    start_time: new Date(obj.start_time * 1000).toLocaleDateString(),
                    //formatted_start_time: this.DateFormatChange(obj.start_time),
                    formatted_start_time: this.datePipe.transform(this.dateMinHourformatter(obj.start_time), this.featureConfig.dateFormat),
                    my_tickets: [],
                    bingo_type: obj.bingo_type,
                    isJackpot: false,
                    isFriendsRoom: this.friendsRoomIds.indexOf(obj.room_id) > -1,
                    isFabGrabRoom: this.fabGrabRoomIds.indexOf(obj.room_id) > -1,
                    image: this.roomImageDataList[
                        this.roomImageDataList?.findIndex((x: any) => x.roomId == obj.room_id) != -1
                            ? this.roomImageDataList?.findIndex((x: any) => x.roomId == obj.room_id)
                            : this.roomImageDataList?.findIndex((x: any) => x.roomId == 'default')
                    ]?.mobileImage?.src,
                    iconimage: this.featureConfig.showIconImage
                        ? this.roomsInfoService.getIconImage(obj.room_id.toString(), this.roomClientConfig)
                        : '',
                    features: obj.features,
                };
            })
            .sort((a: MyRoom, b: MyRoom) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime());
        this.rooms = this.getUniqueItems(this.rooms);
        ticketsList.forEach((ticket: MyTicket) => {
            const room: any = this.rooms.find((room: MyRoom) => {
                return room.room_id == ticket.room_id && room.start_time == new Date(ticket.start_time * 1000).toLocaleDateString();
            });
            if (room && room.my_tickets && room.my_tickets.length > -1) room.my_tickets.push(ticket);
        });
        this.rooms.forEach((room: MyRoom) => {
            if (room.my_tickets && room.my_tickets.length > 0) {
                room.isJackpot =
                    room.my_tickets.filter((ticket: MyTicket) => {
                        return ticket?.jackpot && ticket?.jackpot?.length > 0;
                    }).length > 0;
                room.my_tickets = this.getUniqueTickets(room.my_tickets);
                room.my_tickets.sort((a: any, b: any) => a.start_time - b.start_time);
                room.my_tickets.forEach((ticket: any) => {
                    if (ticket.features.indexOf('session_bingo') > -1) {
                        room['displayHeaderForSessionRoom'] = false;
                        ticket['displayHeaderForSessionRoom'] = false;
                    } else {
                        room['displayHeaderForSessionRoom'] = true;
                        ticket['displayHeaderForSessionRoom'] = true;
                    }
                });
            }
        });
        this.hasRecords = this.rooms.length > 0;
    }

    private getUniqueTickets(tickets: any): any {
        const _result: any = [];
        tickets.forEach((ticket: any) => {
            const index = _result.findIndex((item: any) => item.start_time == ticket.start_time);
            if (index == -1) {
                const date = ticket.start_time ? this.dateMinHourformatter(ticket.start_time) : '';
                ticket.displayDate = ticket.start_time ? this.datePipe.transform(date, 'dd/MM/yyyy') : 'N/A';
                ticket.displayTime = ticket.start_time ? this.datePipe.transform(date, 'HH:mm') : 'N/A';
                ticket.display_name = this.getGameNameFromMappings(ticket.room_id, ticket.display_name);
                ticket.istxtRedCls = false;
                ticket.selected_price = 0;
                if (ticket.game_context.VariableCardStake && ticket.game_context.VariableCardStake.winning_amount) {
                    ticket.isMultistake = true;
                    ticket.selected_price = ticket.game_context.VariableCardStake.ticket_prices_player_currency.findIndex((e: any) => {
                        return e == ticket.game_context.VariableCardStake.price[ticket.game_context.VariableCardStake.ticket_currency_key];
                    });
                    const g = ticket.game_context.VariableCardStake.winning_amount.filter((w: any) => {
                        return w.price == ticket.game_context.VariableCardStake.price[ticket.game_context.VariableCardStake.ticket_currency_key];
                    });
                    ticket.winning_amount = g[0].winning_amount[ticket.game_context.VariableCardStake.ticket_currency_key];
                } else if (ticket.game_context.SlingoMultiStake && ticket.game_context.SlingoMultiStake?.price_points_config_in_player_currency) {
                    ticket.isSlingoMultiStake = true;
                    ticket.selected_price = ticket.game_context.SlingoMultiStake.price_points_config_in_player_currency.findIndex((e: any) => {
                        return e.price == ticket.game_context.SlingoMultiStake.price[ticket.game_context.SlingoMultiStake.ticket_currency_key];
                    });
                    ticket.winning_amount = ticket.winning_amount.Cash;
                }
                _result.push(ticket);
            } else {
                _result[index].purchased_count = _result[index].purchased_count + ticket.purchased_count;
            }
        });
        return _result;
    }

    private getUniqueItems(roomsList: MyRoom[]): MyRoom[] {
        const _result: MyRoom[] = [];
        roomsList.forEach((room: MyRoom) => {
            const isPresent = _result.filter((item: MyRoom) => {
                return (
                    item.room_id === room.room_id && new Date(item.start_time).toLocaleDateString() === new Date(room.start_time).toLocaleDateString()
                );
            });
            if (isPresent.length == 0) {
                _result.push(room);
            }
        });
        return _result;
    }

    prevCount: number = 0;
    myTicketsSliderTracking(count: number) {
        if (count != undefined) {
            let action: string = count > this.prevCount ? 'add' : 'minus';
            this.prevCount = count;
            this.trackingModel.eventName = 'Event.Tracking';
            this.trackingModel.CategoryEvent = this.prebuy_route_status ? 'bingo room' : 'bingo';
            this.trackingModel.LabelEvent = 'my tickets';
            this.trackingModel.ActionEvent = 'click';
            this.trackingModel.PositionEvent = 'pre-buy overlay';
            this.trackingModel.LocationEvent = this.currentGame.display_name;
            this.trackingModel.EventDetails = 'my tickets-' + action + ' ticket';
            this.trackingModel.URLClicked = 'not applicable';
            this.tracking.submitTracking(this.trackingModel);
        }
    }
}
