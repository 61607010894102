import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DesignSystemEnablerService } from '@casinocore/platform/core';
import { DecoratorClassDirective, SlotRaceCtaComponent } from '@frontend/casino-ui';

@Component({
    selector: 'cc-coin-economy-confirm-popup',
    templateUrl: 'coin-economy-confirm-popup.component.html',
    standalone: true,
    imports: [NgIf, DecoratorClassDirective, SlotRaceCtaComponent],
})
export class CoinEconomyConfirmPopupComponent {
    messages: any;
    showConfirmPopup: boolean;
    isLegacy: boolean = false;

    constructor(
        private dialogRef: MatDialogRef<CoinEconomyConfirmPopupComponent>,
        private dsEnablerService: DesignSystemEnablerService,
        @Inject(MAT_DIALOG_DATA) data: any,
    ) {
        this.messages = data?.messages;
        this.showConfirmPopup = data?.showConfirmPopup;
    }

    ngOnInit() {
        this.isLegacy = !this.dsEnablerService.isEnabled();
    }

    onConfirmClick(event: any) {
        this.dialogRef.close({ type: 'confirm', event: event });
    }

    closeDialog(ctaText: string) {
        this.dialogRef.close({ type: ctaText });
    }
}
