<div class="casino-rules-and-tc">
    <mat-expansion-panel
        [expanded]="isExpanded"
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        hideToggle="true"
        [ngClass]="this.isExpansionDisabled">
        <mat-expansion-panel-header>
            <mat-panel-title class="panel-title">
                {{ sitecoreContent?.textTranslations?.versionedList?.Tips }}
            </mat-panel-title>
            <mat-panel-description *ngIf="isCollapsible">
                <mat-icon *ngIf="!panelOpenState">
                    <span class="theme-plus"></span>
                </mat-icon>
                <mat-icon *ngIf="panelOpenState">
                    <span class="theme-minus"></span>
                </mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="dropdown-text" id="tips-dropdown" [style.color]="tipsContentColor">
            <div *ngFor="let data of sitecoreContent.tips">
                <div class="tips" id="tips-content">
                    <div class="casino-tips-image">
                        <img src="{{ data.image.src }}" width="80px" height="90px" draggable="false" />
                    </div>
                    <div class="tips-text">
                        <div class="tips-title">{{ data.title }}</div>
                        <div class="tips-title-text" [innerHTML]="data.text | trustAsHtml"></div>
                    </div>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</div>
