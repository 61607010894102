import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';

import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';

import { BingoBeatTheBankerNotificationModule } from '../beat-the-banker-notification/bingo-beat-the-banker-notification.module';
import { BingoSlotRacesPrizesComponent } from './bingo-prizes.component';

@NgModule({
    imports: [CommonModule, TrustAsHtmlPipe, MatGridListModule, MatExpansionModule, MatIconModule, BingoBeatTheBankerNotificationModule],
    declarations: [BingoSlotRacesPrizesComponent],
    exports: [BingoSlotRacesPrizesComponent],
})
export class BingoSlotRacesPrizesModule {
    public static forRoot(): ModuleWithProviders<BingoSlotRacesPrizesModule> {
        return {
            ngModule: BingoSlotRacesPrizesModule,
        };
    }
}
