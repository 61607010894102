import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: teaserClientConfigFactory,
})
@LazyClientConfig({ key: 'bgTeaser', product: ClientConfigProductName.BINGO })
export class TeaserClientConfig {
    teaserOptimization: boolean;
    teaserDefaultHorizontalAlignment: string;
    teaserDefaultVerticalAlignment: string;
    teaserDefaultImageAlignment: string;
    teaserDefaultHorizontalTextAlignment: string;
    teaserDefaultTermsTextAlignment: string;
    maxRenderedTeasers: number;
    desktopViewTeaserHeight: number;
    mobileViewTeaserHeight: number;
    tabletViewTeaserHeight: number;
    enableTickingForJackpotOnTeasers: boolean;
    enableOptMiniTeasers: boolean;
    enableLobbyBackgroundImage: boolean;
    teaserSetIndexTimeout: any;
    iconTypeMapping: string[];
    normalTeasers: any;
    enableBingoNpm: boolean;
    showEmbeddedTeasersAboveGames: boolean;
}
export function teaserClientConfigFactory(service: LazyClientConfigService) {
    return service.get(TeaserClientConfig);
}
