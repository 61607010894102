import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: prebuyOptimizationClientConfigFactory,
})
@LazyClientConfig({ key: 'bgPrebuyOptimizationConfig', product: ClientConfigProductName.BINGO })
export class PrebuyOptimizationClientConfig {
    enableLazyPrebuyFeed: boolean;
    prebuyListTimeInterval: string;
    timeoutInteravalForEachCall: string;
}
export function prebuyOptimizationClientConfigFactory(service: LazyClientConfigService) {
    return service.get(PrebuyOptimizationClientConfig);
}
