import { NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { CasinoCoreGamelaunchService, CasinoManager, GameLaunch } from '@casinocore/platform/core';
import { TrackingService } from '@frontend/vanilla/core';

@Component({
    selector: 'cc-news-feed-v2-jackpot',
    templateUrl: 'news-feed-v2-jackpot.component.html',
    standalone: true,
    imports: [NgStyle, NgIf],
})
export class NewsFeedV2JackpotComponent implements OnInit {
    @Input() newsFeedItem: any;
    @Input() jackpotObj: any;
    @Input() index: number;
    @Input() lobbyType: string;
    @Input() attributes: any;
    @Input() data: any;
    jackpotHeader: string;
    jackpotValue: string;
    jackpotGameName: string;
    isTouch: boolean;
    categoryType: string;
    constructor(
        private tracking: TrackingService,
        private casinoManager: CasinoManager,
        private gameLaunchService: CasinoCoreGamelaunchService,
    ) {}

    ngOnInit() {
        this.isTouch = this.casinoManager.isTouch();
        this.categoryType = 'NewsFeed';
        if (this.jackpotObj) {
            this.jackpotHeader = this.jackpotObj.jackpotHeader;
            this.jackpotValue = this.jackpotObj.jackpotValue;
            this.jackpotGameName = this.jackpotObj.jackpotGameName;
        }
    }

    itemCliked() {
        this.newsFeedItem.gameId = this.newsFeedItem.gameID;
        this.itemTrackingOnClick(this.index);
        const gameModel = new GameLaunch();
        gameModel.game = this.newsFeedItem;
        gameModel.isTouch = this.isTouch;
        gameModel.gamePosition = this.index;
        gameModel.categoryType = this.categoryType;
        gameModel.from = 'q';
        gameModel.replaceRouteInHistory = false;
        gameModel.isDemoLaunch = false;
        gameModel.skipUnfinGame = false;
        gameModel.gameLaunchedFrom = this.categoryType;
        gameModel.gridPosition = 1;
        gameModel.gameSection = this.categoryType;
        gameModel.gameContainer = '';
        gameModel.game.lobbyType = this.lobbyType;
        gameModel.returnUrl = window.location.href;
        gameModel.clickTimeStamp = this.gameLaunchService.currentTimeStamp();
        this.gameLaunchService.launchGame(gameModel);
    }
    itemTrackingOnClick(positionEvent: number) {
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'NewsFeed',
            'component.LabelEvent': this.newsFeedItem.type,
            'component.ActionEvent': 'Click',
            'component.PositionEvent': ++positionEvent,
            'component.LocationEvent': this.data ? this.data.trackPos : '',
            'component.EventDetails': this.newsFeedItem.jackpotGroupName,
            'component.URLClicked': 'not applicable',
        });
    }
}
