import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: jackpotDataClientConfigFactory,
})
@LazyClientConfig({ key: 'bgJackpotData', product: ClientConfigProductName.BINGO })
export class JackpotDataClientConfig {
    showOnTiles: boolean;
    jpInterval: number;
    showJackpotCurrencyOnRight: boolean;
}
export function jackpotDataClientConfigFactory(service: LazyClientConfigService) {
    return service.get(JackpotDataClientConfig);
}
