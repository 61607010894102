<div class="bingo-bt-coineconmy-overlay" *ngIf="showConfirmPopup">
    <div class="purchase-popup">
        <h2>{{ messages?.Purchase }}</h2>
        <a class="close theme-close-small" (click)="closeDialog('close')"></a>
        <div class="bt-content">{{ messages?.Play }}</div>
        <div class="btn-section">
            <button class="btn btn-secondary btn-md" (click)="closeDialog('cancel')">{{ messages?.Cancel }}</button>
            <button class="btn btn-primary btn-md" (click)="onConfirmClick($event)">{{ messages?.Confrim }}</button>
        </div>
    </div>
</div>
<div class="bingo-lobby-container-main" *ngIf="!showConfirmPopup">
    <div class="bingo-bt-notification-toster-container-coin">
        <div class="bingo-bt-notification-toster-body">
            <div class="sry">{{ messages?.InSufficientBalToasterMsg }}</div>
            <span class="mes">{{ messages?.InsufficientCoinBalance }}</span>
            <div class="btn btn-md btn-primary cls" (click)="closeDialog('toasterClose')">{{ messages?.CloseBtnText }}</div>
        </div>
    </div>
</div>
