import { CurrencyPipe, NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { Race, SitecoreContent, SlotRacesService, SlotRacesTrackingService } from '@casinocore/platform/core';

import { SlotRaceCardComponent } from './cards-wrapper/card/card.component';
import { PrizePipe } from './shared/prize.pipe';

@Component({
    selector: 'cc-slot-races-item',
    templateUrl: 'timeline-item.component.html',
    standalone: true,
    imports: [NgIf, NgClass, SlotRaceCardComponent, PrizePipe],
    providers: [CurrencyPipe],
})
export class SlotRacesTimelineItemComponent implements OnInit, OnChanges {
    @Input() race: Race;
    @Input() selectedOption: string;
    @Input() coinImg: string;
    @Input() content: SitecoreContent;
    @Input() first: boolean;
    @Input() last: boolean;
    @Input() toggleFirstCardOn: boolean;
    @Input() lobbyType: string;
    @Input() category: any;
    @Input() targetedPlayerCount: any;
    @Input() enableOptin: any;
    @Input() fromPickGameOverlay: boolean = false;
    isDisplayCoin: boolean;
    coinSrc: string;
    enableRaceNotification: boolean;
    value: any;
    forceOptinSubscription: any;
    playerCount: number;
    spinCount: number;
    playNowText: string;
    entryData: any = {};

    coinImageSrc: string | null = null;

    title: string;
    messages: { [item: string]: string } = {};
    showRaceCard: boolean;
    @Output() openRaceInfo = new EventEmitter<Race>();
    @Output() openPickGameOverlay = new EventEmitter();
    @Output() openNotificationOverlay = new EventEmitter();
    coinEconomyImageSrc: any;
    coinObj: any;

    constructor(
        private slotRacesTracking: SlotRacesTrackingService,
        private slotRacesService: SlotRacesService,
    ) {}

    ngOnInit() {
        this.showCard();
        this.title =
            this.race.gameVariantList.length > 1
                ? this.race.content?.promotion?.promoName
                    ? this.race.content.promotion.promoName
                    : this.race.gameId
                : this.race?.content?.promotion?.promoName.startsWith('#')
                  ? this.race.content.promotion.promoName.replace('#', '').trim()
                  : this.race.gameId;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.showCard();

        if (changes?.targetedPlayerCount?.currentValue && this.race) {
            this.race.targetedPlayerCount = changes.targetedPlayerCount.currentValue;
        }
        if (changes?.race?.currentValue) {
            const raceCurrentValue = changes.race.currentValue;
            this.title =
                raceCurrentValue?.gameVariantList?.length > 1
                    ? raceCurrentValue?.content?.promotion?.promoName
                        ? raceCurrentValue.content.promotion.promoName
                        : raceCurrentValue.gameId
                    : raceCurrentValue.content?.promotion?.promoName.startsWith('#')
                      ? raceCurrentValue.content.promotion.promoName.replace('#', '').trim()
                      : raceCurrentValue.gameId;
            this.coinObj = this.slotRacesService.bindCoinImg(changes?.race?.currentValue, this.coinImg);
        }
    }

    toggleRaceCardVisibility() {
        const activeCategory = this.category?.categoryInfo?.categoryid;

        if (this.coinObj?.isDisplayCoin) {
            if (this.showRaceCard) {
                this.slotRacesTracking.track('Collapse', this.race.gameId, activeCategory, 'with coin icon');
            } else {
                this.slotRacesTracking.track('Expand', this.race.gameId, activeCategory, 'with coin icon');
            }
        } else {
            if (this.showRaceCard) {
                this.slotRacesTracking.track('Collapse', this.race.gameId, activeCategory, 'User clicked on Collapse to hide the slot race details');
            } else {
                this.slotRacesTracking.track('Expand', this.race.gameId, activeCategory, 'User clicked on Expand to see the slot race details');
            }
        }
        this.showRaceCard = !this.showRaceCard;
    }

    openRaceInfoDialog(): void {
        this.openRaceInfo.emit(this.race);
    }

    openPickGameDialog() {
        this.openPickGameOverlay.emit(this.race);
    }
    openNotificationDialog() {
        this.openNotificationOverlay.emit(this.race);
    }
    showCard() {
        if (this.toggleFirstCardOn && this.first) {
            this.showRaceCard = true;
        } else {
            this.showRaceCard = false;
        }
        this.slotRacesService.forceRace.subscribe((response: { race: Race }) => {
            if (response && response.race && response.race.slotUniqueId == this.race.slotUniqueId && response.race.promoId == this.race.promoId) {
                this.showRaceCard = true;
            }
        });
        this.slotRacesService.forceCoinBalanceObs.subscribe((coinBalRes: any) => {
            if (
                coinBalRes &&
                coinBalRes.race &&
                coinBalRes.race.promoId == this.race.promoId &&
                coinBalRes.race.slotUniqueId == this.race.slotUniqueId &&
                coinBalRes?.coinBal?.balance
            ) {
                this.showRaceCard = true;
            }
        });
    }
}
