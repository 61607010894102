import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';

import { BingoCoreModule } from '@frontend/bingo/core';

import { BingoTournamentIconComponent } from './bingo-tournament-icon.component';

@NgModule({
    imports: [CommonModule, MatGridListModule, BingoCoreModule],
    declarations: [BingoTournamentIconComponent],
    exports: [BingoTournamentIconComponent],
})
export class BingoTournamentIconModule {
    public static forRoot(): ModuleWithProviders<BingoTournamentIconModule> {
        return {
            ngModule: BingoTournamentIconModule,
        };
    }
}
