import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';

import { BingoSlotRacesRulesComponent } from './bingo-rules.component';

@NgModule({
    imports: [CommonModule, TrustAsHtmlPipe, MatExpansionModule, MatIconModule],
    declarations: [BingoSlotRacesRulesComponent],
    exports: [BingoSlotRacesRulesComponent],
})
export class BingoSlotRacesRulesModule {
    public static forRoot(): ModuleWithProviders<BingoSlotRacesRulesModule> {
        return {
            ngModule: BingoSlotRacesRulesModule,
        };
    }
}
