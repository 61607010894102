import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

import { SitecoreContent, SlotRacesService } from '@casinocore/platform/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';

@Component({
    selector: 'cc-slot-races-tips',
    templateUrl: './tips.component.html',
    standalone: true,
    imports: [MatExpansionModule, NgClass, NgIf, MatIconModule, NgFor, TrustAsHtmlPipe],
})
export class SlotRacesTipsComponent implements OnInit, OnDestroy {
    @Input() showHeader: boolean;
    @Input() isCollapsible: boolean;
    @Input() isExpanded: boolean;

    isVisible: boolean;
    panelOpenState: boolean;
    contentSubscription: any;
    sitecoreContent: SitecoreContent;
    isExpansionDisabled: string = '';
    tipsContentColor: string;

    constructor(private slotRacesService: SlotRacesService) {}

    ngOnInit() {
        this.showHeader = true;
        this.isVisible = false;
        this.changeCollapseState();
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.sitecoreContent = content;
        });
        this.ExpansionState();
        this.tipsContentColor = this.sitecoreContent.textTranslations.versionedList?.TipsContentColor;
    }
    ngOnDestroy() {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
    }

    changeCollapseState() {
        this.isVisible = !this.isVisible;
    }
    ExpansionState() {
        if (!this.isCollapsible) {
            this.isExpansionDisabled = 'disabled-pointer';
        }
    }
}
