import { ModuleWithProviders, NgModule } from '@angular/core';

import { SlotRacesWidgetComponent } from './slot-races-widget.component';
import { SlotRacesWrapperComponent } from './slot-races-wrapper.component';
import { SlotRacesComponent } from './slot-races.component';

@NgModule({
    imports: [SlotRacesComponent, SlotRacesWidgetComponent],
    exports: [SlotRacesComponent, SlotRacesWidgetComponent],
})
export class SlotRacesModule {
    static rootComponent = SlotRacesWrapperComponent;
    public static forRoot(): ModuleWithProviders<SlotRacesModule> {
        return {
            ngModule: SlotRacesModule,
        };
    }
}
