import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TrustAsHtmlPipe, TrustAsResourceUrlPipe } from '@frontend/vanilla/shared/browser';

import { BonusSupressionPopupModule } from './../shared/components/bonus-supression/bonus-supression-popup.module';
import { TimerComponent } from './../shared/components/timer/timer.component';
import { BallFilterPipe } from './../shared/filters/balltypefilter';
import { GameListSortPipe } from './../shared/filters/gameListSort';
import { HiddenFilterPipe } from './../shared/filters/hiddenfilter';
import { KeyValueFilterPipe } from './../shared/filters/keyvaluefilter';
import { SortFilterPipe } from './../shared/filters/sortfilter';
import { BgFloatTruncatePipe } from './../shared/pipes/bg-float-truncate.pipe';
import { ReplacePipe } from './../shared/pipes/replace.pipe';
import { SitecoreImageResizePipe } from './../shared/sitecore-image-resize.pipe';
import { TimerToggleViewDirective } from './components/timer/timertoggleview.directive';
import { bingoTournamentFilterPipe } from './filters/bingoTournamentFilter';

@NgModule({
    imports: [CommonModule, TrustAsHtmlPipe, TrustAsResourceUrlPipe],
    declarations: [
        TimerComponent,
        BallFilterPipe,
        KeyValueFilterPipe,
        SortFilterPipe,
        GameListSortPipe,
        TimerToggleViewDirective,
        ReplacePipe,
        HiddenFilterPipe,
        bingoTournamentFilterPipe,
        SitecoreImageResizePipe,
        BgFloatTruncatePipe,
    ],
    exports: [
        ReplacePipe,
        TimerComponent,
        BallFilterPipe,
        KeyValueFilterPipe,
        SortFilterPipe,
        GameListSortPipe,
        TimerToggleViewDirective,
        SitecoreImageResizePipe,
        HiddenFilterPipe,
        bingoTournamentFilterPipe,
        BgFloatTruncatePipe,
        BonusSupressionPopupModule,
    ],
})
export class SharedModule {}
