<div class="teamSelectModal fab">
    <div class="head-part">{{ fabgrabPopupHeader }}<i class="theme-ex" (click)="onNumbersClose()"></i></div>
    <div class="sel-numbers">
        <p>{{ fabgrabPopupMessage }}</p>
        <div class="specs">
            <span class="lucky-dip" [class.backGround]="data.currentGame.game_context.FomoBingo.fomo_numbers.length > 0" (click)="selectPattern()">{{
                luckyDipText
            }}</span>
            <span class="clear" (click)="clearNumbers()">{{ clearText }}</span>
        </div>
        <span
            *ngFor="let number of numbers"
            (click)="onNumberSelect(number)"
            class="numballs"
            [ngClass]="data.currentGame.game_context.FomoBingo.fomo_numbers.indexOf(number) > -1 ? 'active' : ''">
            {{ number }}</span
        >
    </div>
    <div class="select-btn">
        <span class="select" (click)="onNumbersClose()">{{ selectText }}</span>
    </div>
</div>
