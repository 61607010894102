import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import {
    BingoConfig,
    BingoConfigProviderService,
    CacheSettingsClientConfig,
    ConfirmMessageService,
    FeaturesClientConfig,
    PrebuyOptimizationClientConfig,
    RoomsInfoService,
    ScheduleService,
    TicketsResourceService,
} from '@frontend/bingo/core';
import { ClaimsService, CurrencyPipe, DeviceService, LoadingIndicatorService, Message, MessageQueueService } from '@frontend/vanilla/core';
import * as _ from 'lodash-es';
import { BehaviorSubject, Subscription } from 'rxjs';

import { Games } from '../../models/games';
import { CartItem, CartTrackingModel } from '../../shared/components/Tracking/CartTrackingModel';
import { TrackingModel } from '../../shared/components/Tracking/TrackingModel';
import { TrackingModelService } from '../../shared/components/Tracking/tracking-modal-service';
import { DropdownModel } from '../../tickets/models/dropdown.model';
import { TicketsComponent } from '../tickets.component';
import { AlertMessageService } from './../../shared/components/alert-message/alert-message.service';
import { PriceModalPopupComponent } from './pricemodal-popup/price-modal-popup.component';
import { TeamModalPopupComponent } from './team-modal-popup/team-modal-popup.component';
import { TopandtailPopupComponent } from './topandtail-popup/topandtail-popup.component';

enum SORTING {
    DESC = 'arrow-up',
    ASC = 'arrow-down',
    EMPTY = '',
}
@Component({
    selector: 'bg-pre-buy-grid',
    templateUrl: './pre-buy-grid.component.html',
    providers: [DatePipe, TrackingModelService],
})
export class PreBuyGridComponent implements OnInit, OnDestroy {
    delay: number;
    blockScrolling: boolean;
    bingoGameMappings: any;
    intervalArray: Array<any> = new Array<any>();
    dateFormat: string;
    dataLoaded: boolean;
    dataObs$: BehaviorSubject<any> = new BehaviorSubject<any>(0);

    @Output()
    OnTotalTicketsSelected: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    OnTeamModalOpen: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    DropDownRoomData: any;

    @Input()
    SelectedRoomId: number;

    @Input()
    TodayDate: any;

    @Input()
    Date: string = '';

    @Input()
    GameSlot: any;
    SelectedDate: Date = new Date();
    SelectedTime: any;
    selected_bingo_type: string;
    Games: any[] = [];
    currentRoom: Games;
    UnsortedGames: any[] = [];
    SortingData: any = {};
    prebuynavlist: any;
    prebuynavcolitems: any;
    imageDataList: any = [];
    PrebuyRoomErrorMessage: any;
    dropdownForm: UntypedFormGroup;
    roomList: DropdownModel[] = [];
    dateList: any[] = [];
    timeArr: any[] = [];
    timeList: any[] = [];
    date_timestamp_list: any = [];
    date_string_list: any = [];
    timestamp: any;
    unformatted_datedata: any;
    formatted_datedata: any;
    _totalPurchasedTickets: number = 0;
    _selectedTickets: any = [];
    _totalPrice: number = 0;
    currentGame: any;
    isprebuyfarApply: boolean;
    prebuytime_configured: number;
    _loadingIndicator: any;
    loading_status: boolean = true;
    prebuy_route_status: boolean = false;
    isGamesAvailCurrRoom: boolean = false;
    isLoading: boolean = true;
    currentgame_val: number;
    showNumpad: boolean = false;
    ticket_list_arr: any;
    teamColors: any;
    randomTeamText: any;
    maximumTicketsReached: any;
    freeGamesText: any;
    FreeGamesPriceText: any;
    friendsRoomIds: number[];
    fabGrabRoomIds: number[];
    fluffyRooms: number[];
    isFriendsRoom: boolean;
    isFabGrabRoom: boolean;
    isCappedRoom: boolean;
    showGameDate: boolean;
    prebuyIconslist: any;
    prebuycloseApply: any[] = ['0'];
    physicalPrizeText: string;
    bonusPrizeText: string;
    trackingModel: TrackingModel;
    cartTrackingModel: CartTrackingModel;
    isFluffyFavRoom: boolean = false;
    isPreBuyBonusDetails: boolean;
    enableBonusImplementation: boolean;
    indexVar: number;
    preBuyInterval: any;
    isPreBuyDates: boolean;
    enableNewPrebuyIcons: boolean = false;
    purchasedGames: any[] = [];
    roomIdsData: any = [];
    globalClientConfig: any;
    bonusInfoSubscription: Subscription;
    from_time: number;
    end_time: number;
    isScrolled: boolean = false;
    scrollDate: any;
    multistakeContent: any;
    clientConfig: any;
    isJackpotAvailable: boolean = false;
    PREBUY_SCOPE: string = 'prebuy-toastr-message';
    PREBUY_NODATA_SCOPE: string = 'prebuy-nodata';
    @ViewChild('prebuyScroll', { static: false }) prebuyScroll: ElementRef<HTMLDivElement>;
    lazyPrebuyConfig: PrebuyOptimizationClientConfig;
    cacheSettingsConfig: CacheSettingsClientConfig;
    bingoClientConfig: BingoConfig;
    featureConfig: FeaturesClientConfig;

    @ViewChild(TicketsComponent)
    TicketsComponentChild: TicketsComponent;
    val: string;
    sliderNum: string;
    multiSliderNum: string;

    constructor(
        private datePipe: DatePipe,
        private _formBuilder: UntypedFormBuilder,
        private alertMessageService: AlertMessageService,
        private loadingIndicatorService: LoadingIndicatorService,
        private messageQueueService: MessageQueueService,
        private route: ActivatedRoute,
        private elem: ElementRef,
        private ticketsResource: TicketsResourceService,
        private confirmMessageService: ConfirmMessageService,
        private tracking: TrackingModelService,
        private roomsInfoService: RoomsInfoService,
        private scheduleService: ScheduleService,
        private claimsService: ClaimsService,
        private vnCurrency: CurrencyPipe,
        private deviceService: DeviceService,
        private configProviderService: BingoConfigProviderService,
        private dialog: MatDialog,
    ) {
        this.lazyPrebuyConfig = this.configProviderService.providePrebuyOptimizationClientConfig();
        this.cacheSettingsConfig = this.configProviderService.provideCacheSettingsClientConfig();
        this.bingoClientConfig = this.configProviderService.provideBingoConfig();
        this.featureConfig = this.configProviderService.provideFeaturesConfig();
        this.trackingModel = new TrackingModel();
        this.cartTrackingModel = new CartTrackingModel();
    }

    ngOnInit() {
        if (localStorage.getItem('config')) {
            this.clientConfig = JSON.parse(localStorage.getItem('config')!);
            if (!this.globalClientConfig) this.globalClientConfig = this.clientConfig?.global;
            if (this.imageDataList?.length == 0) this.imageDataList = this.clientConfig?.rooms;
        }
        this.multistakeContent = this.clientConfig?.multiStakeGlobalMessages;
        this.enableNewPrebuyIcons = this.featureConfig.enableNewPrebuySliderIcons;
        this.dateFormat = this.featureConfig.dateFormat;
        const iframe_ingame_status = this.route.snapshot.queryParamMap.get('ingame');
        if (iframe_ingame_status !== null && iframe_ingame_status !== undefined) {
            if (iframe_ingame_status) {
                this.prebuy_route_status = true;
                document.getElementById('main-content')!.classList.add('iframe-custom-model');
                // dispatchEvent(new CustomEvent('gs:hideFloatingEntry'));
            }
        }
        this.bingoGameMappings = this.clientConfig?.pageConfig?.value?.bingoGameMappings;
        this.bindDropDown();
        const selRoom = this.SelectedRoomId.toString();
        const selectedRoom = this.roomList.find((x: any) => x.id == selRoom);
        if (!selectedRoom) {
            this.SelectedRoomId = Number(this.roomList[0].id);
        }
        this.currentgame_val = 0;
        this.dropdownForm = this._formBuilder.group({
            SelectedRoomId: [this.SelectedRoomId],
            TodayDate: [this.TodayDate],
            SelectedTime: [this.SelectedTime],
        });

        this.prebuynavlist = this.clientConfig?.bingoCategories;
        this.prebuynavlist?.forEach((val: any) => {
            if (val.categoryType == 'prebuytab') {
                this.prebuynavcolitems = val;
            }
        });
        this.isPreBuyBonusDetails = this.featureConfig.isEnablePrebuyBonusDetails;
        this.prebuytime_configured = Number(this.bingoClientConfig['scheduleTimerColorScheme']['specifiedtime']['timevalue']);
        this.maximumTicketsReached = this.globalClientConfig['messages']?.maximumTicketsPurchased;
        this.freeGamesText = this.globalClientConfig['messages']?.freeGamesText;
        this.FreeGamesPriceText = this.globalClientConfig['messages']?.FreeGamesPriceText;
        this.timeArr = this.clientConfig?.pageConfig?.value?.prebuyGameTime;
        this.BindDatesToBingoRoom(this.SelectedRoomId);

        //this.teams_arr = ['chandler' ,'joey','monica','phoebe','rachel','ross'];
        //sitecore integration

        if (this.prebuynavcolitems?.columns?.teamColors) this.teamColors = JSON.parse(this.prebuynavcolitems.columns.teamColors);
        this.prebuyIconslist = this.prebuynavlist?.find((x: any) => x.categoryType == 'rooms');
        this.randomTeamText = this.prebuynavcolitems?.columns?.randomTeamtxt;
        this.ticket_list_arr = this.elem.nativeElement.getElementsByClassName('pb-tickets-value');
        this.friendsRoomIds = [];
        this.fabGrabRoomIds = [];
        if (this.globalClientConfig?.messages?.friendsRoomIds) {
            this.friendsRoomIds = this.globalClientConfig?.messages?.friendsRoomIds?.split(',')?.map((item: any) => {
                return parseInt(item, 10);
            });
            if (this.friendsRoomIds.indexOf(this.SelectedRoomId) > -1) {
                this.isFriendsRoom = true;
            }
        }
        if (this.globalClientConfig?.messages?.fabGrabRoomIds) {
            this.fabGrabRoomIds = this.globalClientConfig?.messages?.fabGrabRoomIds?.split(',')?.map((item: any) => {
                return parseInt(item, 10);
            });
            if (this.fabGrabRoomIds.indexOf(this.SelectedRoomId) > -1) {
                this.isFabGrabRoom = true;
            }
        }
        const currentRoom = this.DropDownRoomData.filter((r: Games) => {
            return r.id == this.SelectedRoomId;
        })[0];
        if (currentRoom.max_player_limit == null) {
            this.isCappedRoom = false;
        } else {
            this.isCappedRoom = true;
        }
        this.fluffyRooms = this.DropDownRoomData.map((room: Games) => {
            if (room.features.indexOf('multi_price') > -1 || room.features.indexOf('slingo_multi_stake') > -1) return parseInt(room.id);
            else return 0;
        });
        if (this.fluffyRooms.indexOf(this.SelectedRoomId) > -1) {
            this.isFluffyFavRoom = true;
            this.roomTracking(this.SelectedRoomId, false, false);
        }
        this.enableBonusImplementation = this.featureConfig.enableBonusImplementation;
        this.physicalPrizeText = this.globalClientConfig?.messages?.physicalPrizeText;
        this.bonusPrizeText = this.globalClientConfig?.messages?.bonusPrizeText;

        this.showGameDate = this.featureConfig.enablePrebuyDate;
        this.SortData('start_time');
    }

    roomTracking(roomid: any, isMultiStakePopup: boolean, buyNow: boolean) {
        if (roomid != this.SelectedRoomId)
            this.currentRoom = this.DropDownRoomData.filter((r: Games) => {
                return r.id == roomid;
            })[0];
        this.trackingModel.eventName = 'Event.Tracking';
        this.trackingModel.LabelEvent = 'Games';
        this.trackingModel.ActionEvent = isMultiStakePopup
            ? 'Multi Stake Popup - Displayed'
            : buyNow
              ? 'Buy Now CTA - Clicked'
              : 'PreBuy Screen - Displayed';
        this.trackingModel.PositionEvent = this.currentRoom.name;
        this.trackingModel.LocationEvent = this.prebuy_route_status ? 'InGameClient' : 'Lobby';
        this.trackingModel.EventDetails = this.prebuy_route_status ? 'InGameClient' : 'Rooms';
        this.tracking.submitTracking(this.trackingModel);
    }
    cartTracking(roomid: any, isClear: boolean, isConfirmPopup: boolean, isCancel: boolean) {
        if (roomid != this.SelectedRoomId)
            this.currentRoom = this.DropDownRoomData.filter((r: Games) => {
                return r.id == roomid;
            })[0];
        // this.cartTrackingModel.eventName = isClear ? 'Cart.betremove' : 'Cart.purchase';
        if (isConfirmPopup) {
            this.cartTrackingModel.eventName = 'Confirm Popup - Displayed';
        } else if (isCancel) {
            this.cartTrackingModel.eventName = 'cancel-clicked';
        } else if (isClear) {
            this.cartTrackingModel.eventName = 'Cart.betremove';
        } else {
            this.cartTrackingModel.eventName = 'Cart.purchase';
        }
        this.cartTrackingModel.name = this.currentRoom.name;
        this.cartTrackingModel.price = this._totalPrice;
        this.cartTrackingModel.quantity = this._totalPurchasedTickets;

        this.cartTrackingModel.item = this._selectedTickets.map((t: any) => {
            return new CartItem(t.game_start_time, this.currentRoom.name, t.price, t.winning, t.jackpot, t.no_of_tickets);
        });
        this.tracking.submitCartTracking(this.cartTrackingModel);
    }
    getGameNameFromMappings(id: number, name: string): string {
        return this.bingoGameMappings[id] == null || this.bingoGameMappings[id] == undefined ? name : this.bingoGameMappings[id];
    }

    bindDropDown() {
        if (this.roomList.length == 0) {
            for (let i = 0; i < this.DropDownRoomData.length; i++) {
                if (
                    this.DropDownRoomData[i].prebuy_enabled == true &&
                    !this.DropDownRoomData[i].isHidden &&
                    !this.DropDownRoomData[i].tournament_enabled
                ) {
                    if (this.DropDownRoomData[i].isCombinedRoom == false)
                        this.DropDownRoomData[i].name = this.getGameNameFromMappings(this.DropDownRoomData[i].id, this.DropDownRoomData[i].name);
                    this.roomList.push({
                        icon: this.DropDownRoomData[i].bingo_type,
                        id: this.DropDownRoomData[i].id,
                        display: this.DropDownRoomData[i].name.toUpperCase(), //(this.getGameNameFromMappings(this.DropDownRoomData[i].id, this.DropDownRoomData[i].name)).toUpperCase(),
                        image: this.imageDataList[
                            this.imageDataList?.findIndex((x: any) => x.roomId == this.DropDownRoomData[i].id) != -1
                                ? this.imageDataList?.findIndex((x: any) => x.roomId == this.DropDownRoomData[i].id)
                                : this.imageDataList?.findIndex((x: any) => x.roomId == 'default')
                        ]?.mobileImage?.src,
                        iconimage: this.roomsInfoService.getIconImage(this.DropDownRoomData[i].id, this.imageDataList),
                    });
                }
            }
        }
        this.dropdownForm = this._formBuilder.group({
            SelectedRoomId: [this.SelectedRoomId],
            TodayDate: [this.TodayDate],
            SelectedTime: [this.SelectedTime],
        });
        this.dataLoaded = true;
    }
    getDateDataFromSession() {
        const datesData = JSON.parse(sessionStorage.getItem('PrebuyScheduleDates')!);
        return datesData?.preBuyScheduleDatesData?.result?.schedule_dates[this.SelectedRoomId] ? datesData : null;
    }

    BindDatesToBingoRoom(SelectedRoomID: number) {
        this._loadingIndicator = this.loadingIndicatorService.start();
        const current_dt = new Date();
        this.dateList = [];
        this.formatted_datedata = [];
        this.date_string_list = [];
        this.currentRoom = this.DropDownRoomData.filter((r: Games) => {
            return r.id == SelectedRoomID;
        })[0];
        let launchedRoom: any;
        if (this.scheduleService.checkforscheduleData()) {
            this.roomIdsData = this.scheduleService.getroomIdsData();
            launchedRoom = this.scheduleService.getFeatureTypesbyRoomId(SelectedRoomID);
        } else {
            this.scheduleService.getGameData((response: any) => {
                if (response) {
                    launchedRoom = this.scheduleService.getFeatureTypesbyRoomId(SelectedRoomID);
                }
            });
        }
        const gameTypeId = launchedRoom != undefined ? launchedRoom.gvc_game_type_id : null;
        const data = this.getDateDataFromSession();
        if (data) {
            this.processScheduleDatesData(data, current_dt, SelectedRoomID, gameTypeId, launchedRoom);
        } else {
            this.ticketsResource.getPrebuyDateData(this.SelectedRoomId, gameTypeId, false).subscribe(
                (datedata) => {
                    this.bindDropDown();
                    if (
                        (this.cacheSettingsConfig.preBuy.enableMemoryCache || this.cacheSettingsConfig.preBuy.enableDistributedCache) &&
                        datedata &&
                        _.size(datedata.preBuyScheduleDatesData.result.schedule_dates) == 1
                    ) {
                        this.scheduleService.triggerPreBuyDatesData();
                    } else if (datedata && this.cacheSettingsConfig.preBuy.enableSessionStorage) {
                        sessionStorage.setItem('PrebuyScheduleDates', JSON.stringify(datedata));
                    }
                    this.processScheduleDatesData(datedata, current_dt, SelectedRoomID, gameTypeId, launchedRoom);
                },
                () => {
                    this.bindDropDown();
                    this._loadingIndicator.done();
                    this.isLoading = false;
                    this.isGamesAvailCurrRoom = false;
                    this.alertMessageService.error('checkmark_kick', this.globalClientConfig['errors']['technicalErrorMessage']);
                },
            );
        }
    }
    processScheduleDatesData(datedata: any, current_dt: any, SelectedRoomID: any, gameTypeId: any, launchedRoom: any = null) {
        this.bindDropDown();
        this.clearMessages(this.PREBUY_NODATA_SCOPE);
        if (datedata && datedata.preBuyScheduleDatesData != null && datedata.preBuyScheduleDatesData.result !== null) {
            this._loadingIndicator.done();
            if (
                datedata.preBuyScheduleDatesData.result == undefined ||
                datedata.preBuyScheduleDatesData.result.schedule_dates[this.SelectedRoomId].length == 0 ||
                (!launchedRoom.prebuy_enabled && launchedRoom.isSuperLink)
            ) {
                this.messageQueueService.addError(this.prebuynavcolitems?.columns?.gamesAvailCurrRoomText, undefined, this.PREBUY_NODATA_SCOPE);
                this.isLoading = false;
                this.isGamesAvailCurrRoom = false;
            } else {
                this.formatted_datedata = datedata.preBuyScheduleDatesData.result.schedule_dates[this.SelectedRoomId];
                const date_timestamp_list = this.formatted_datedata;
                const date_timestamp_count = date_timestamp_list.length;
                this.formatted_datedata = this.formatted_datedata.sort((x: any, y: any): any => {
                    return x - y;
                });
                const nowDate = new Date();
                nowDate.setHours(0, 0, 0, 0);
                const now = nowDate.getTime();
                for (let date_tm_counter = 0; date_tm_counter < date_timestamp_count; date_tm_counter++) {
                    this.timestamp = new Date(this.formatted_datedata[date_tm_counter] * 1000).toString();
                    const time = this.formatted_datedata[date_tm_counter] * 1000;
                    if (time >= now) this.date_string_list.push(this.timestamp);
                }

                let s_Date = '';
                if (this.Date != undefined && this.Date !== '') {
                    this.SelectedDate = new Date(this.Date);
                }
                if (!this.SelectedDate) {
                    const f_date = new Date(this.formatted_datedata[0] * 1000);
                    if (f_date > current_dt) this.SelectedDate = f_date;
                    else this.SelectedDate = current_dt;
                }
                s_Date = this.transformDate(this.SelectedDate);
                for (let date_str_counter = 0; date_str_counter < this.date_string_list?.length; date_str_counter++) {
                    this.dateList.push({ id: date_str_counter + 1, display: this.transformDate(this.date_string_list[date_str_counter]) });
                    if (this.SelectedDate && s_Date == this.dateList[date_str_counter].display) {
                        this.TodayDate = date_str_counter + 1;
                    }
                }

                if (this.transformDate(current_dt) != this.transformDate(this.SelectedDate)) {
                    current_dt = this.SelectedDate;
                    current_dt.setHours(0, 0, 0, 0);
                }
                this.GenerateTimeOfDayData(this.timeArr, current_dt);
                // let current_hour = current_dt.getHours();
                // this.SelectedTime = current_hour;
                const date = this.dateList.find((x) => x.id == this.TodayDate).display;
                this.GetSelectedRoomData(this.SelectedRoomId, date);
            }
        } else {
            this._loadingIndicator.done();
            this.isLoading = false;
            this.isGamesAvailCurrRoom = false;
            this.alertMessageService.error('checkmark_kick', this.globalClientConfig['errors']['technicalErrorMessage']);
        }
        if (
            this.isPreBuyBonusDetails &&
            datedata &&
            datedata.preBuyScheduleDatesData.result.schedule_dates[this.SelectedRoomId].length > 0 &&
            !(!launchedRoom.prebuy_enabled && launchedRoom.isSuperLink)
        ) {
            this.bonusInfoSubscription = this.ticketsResource.getPreBuyBonusInfo(this.SelectedRoomId, gameTypeId).subscribe((bonusInfo: any) => {
                this.preBuyBonusDetailsData(bonusInfo?.bingoBonusDetails, SelectedRoomID, gameTypeId);
                this.isGamesAvailCurrRoom = true;
            });
        }
    }
    /* Don't change this date format, this is for internal */
    transformDate(date: Date): string {
        return this.datePipe.transform(date, 'yyyy-MM-dd')!;
    }

    transformUnixToTime(unixTimeStamp: any): Date {
        const dt = new Date(unixTimeStamp * 1000);
        const prebuy_timestamp = dt.getTime();
        const prebuy_current_timestamp = new Date().getTime();
        const prebuy_time_variation = prebuy_timestamp - prebuy_current_timestamp;
        const minutesvariation = prebuy_time_variation / (1000 * 60);
        if (minutesvariation < this.prebuytime_configured) {
            this.prebuycloseApply.push(this.datePipe.transform(dt, 'HH:mm'));
        }
        return dt;
    }

    ngOnDestroy() {
        this.clearMessages(this.PREBUY_NODATA_SCOPE);
        this.clearMessages(this.PREBUY_SCOPE);
        // if (this.numpadSubscription)
        //   this.numpadSubscription.unsubscribe();
        this.Games = [];
        this.UnsortedGames = [];
        this.intervalArray.forEach((x) => {
            clearInterval(x);
        });
        if (this.preBuyInterval) clearInterval(this.preBuyInterval);
        if (this.bonusInfoSubscription) {
            this.bonusInfoSubscription.unsubscribe();
        }
    }

    GenerateTimeOfDayData(_timeArr: any[], dt: Date) {
        this.timeList = [];
        let hour = dt.getHours();
        this.SelectedTime = hour;
        for (let i = 0; i <= 23; i++) {
            const obj = _timeArr.find((x) => x.id == hour);
            if (this.prebuynavcolitems?.columns?.timeAfter) obj.display = obj.display.replace('After', this.prebuynavcolitems?.columns?.timeAfter);
            this.timeList.push(obj);
            if (hour == 23) break;
            else hour = hour + 1;
        }
    }

    Selected(obj: any) {
        this.currentGame = this.Games[obj.id];
        const prevVal = this.currentGame.current_purchased_count === undefined ? 0 : this.currentGame.current_purchased_count;
        this.currentGame.current_purchased_count = obj.selectedValue;
        const totaltickets = this.currentGame.purchased_count + this.currentGame.current_purchased_count;
        if (this.currentGame.purchased_count == 0) {
            if (this.currentGame.current_purchased_count == 0) this.currentGame.isRedClsEnabled = false;
            else if (this.currentGame.current_purchased_count < this.currentGame.minimum_tickets) {
                this.currentGame.isRedClsEnabled = true;
            } else if (totaltickets > this.currentGame.maximum_tickets) {
                this.currentGame.isRedClsEnabled = true;
            } else {
                this.currentGame.isRedClsEnabled = false;
            }
        } else if (totaltickets > this.currentGame.maximum_tickets) {
            this.currentGame.isRedClsEnabled = true;
        } else {
            this.currentGame.isRedClsEnabled = false;
        }
        if (this.isFabGrabRoom && this.currentGame.game_context.FomoBingo.fomo_numbers == null) {
            this.currentGame.game_context.FomoBingo.fomo_numbers = [];
        }

        if (this.isFriendsRoom && this.currentGame.game_context.TeamBingo.current_team == null) this.onRandomSelected();
        this._totalPurchasedTickets = this._totalPurchasedTickets - prevVal + this.currentGame.current_purchased_count;
        if (this.currentGame.isMultistake || this.currentGame.isSlingoMultiStake) {
            this._totalPrice = this._totalPrice - prevVal * this.currentGame.ticket_cost.Cash;
            if (this.currentGame.isMultistake)
                this.currentGame.ticket_cost.Cash = parseFloat(
                    this.currentGame.game_context.VariableCardStake.ticket_prices_player_currency[this.currentGame.selected_price],
                );
            else
                this.currentGame.ticket_cost.Cash = parseFloat(
                    this.currentGame.game_context.SlingoMultiStake.price_points_config_in_player_currency[this.currentGame.selected_price].price,
                );
            this._totalPrice = this._totalPrice + this.currentGame.current_purchased_count * this.currentGame.ticket_cost.Cash;
        } else
            this._totalPrice =
                this._totalPrice -
                prevVal * this.currentGame.ticket_cost.Cash +
                this.currentGame.current_purchased_count * this.currentGame.ticket_cost.Cash;
        const foundIndex = this._selectedTickets.findIndex((x: any) => x.game_start_time == this.currentGame.start_time);
        if (foundIndex >= 0) {
            if (this.currentGame.current_purchased_count == '0') this._selectedTickets.splice(foundIndex, 1);
            else {
                const ticket = this._selectedTickets[foundIndex];
                ticket.no_of_tickets = this.currentGame.current_purchased_count;
                ticket.context.team = this.currentGame.game_context.TeamBingo ? this.currentGame.game_context.TeamBingo.current_team : null;
                ticket.context.fomo_numbers = this.currentGame.game_context.FomoBingo ? this.currentGame.game_context.FomoBingo.fomo_numbers : null;
                ticket.context.fomo_numbers = this.currentGame.game_context.FomoBingo ? this.currentGame.game_context.FomoBingo.fomo_numbers : null;

                if (this.currentGame.isMultistake || this.currentGame.isSlingoMultiStake) {
                    const price = {};
                    if (this.currentGame.isMultistake)
                        price[this.currentGame.game_context.VariableCardStake.ticket_currency_key] = parseFloat(
                            this.currentGame.game_context.VariableCardStake.ticket_prices[this.currentGame.selected_price],
                        );
                    else
                        price[this.currentGame.game_context.SlingoMultiStake.ticket_currency_key] = parseFloat(
                            this.currentGame.game_context.SlingoMultiStake.price_points_config[this.currentGame.selected_price].price,
                        );
                    ticket.context['price'] = price;
                    ticket.price = this.currentGame.ticket_cost.Cash;
                    ticket.winning = this.currentGame.selected_win;
                }
            }
        } else {
            if (this.currentGame.current_purchased_count != '0') {
                const ticket: any = {
                    room_id: this.currentGame.room_id,
                    bingo_type: this.selected_bingo_type,
                    game_config_id: this.currentGame.game_config_id,
                    no_of_tickets: this.currentGame.current_purchased_count,
                    game_start_time: this.currentGame.start_time,
                    game_min_tickets: this.currentGame.minimum_tickets,
                    game_max_tickets: this.currentGame.maximum_tickets,
                    purchased_count: this.currentGame.purchased_count,
                    min_fomo_numbers:
                        this.currentGame.game_context.FomoBingo != null &&
                        this.currentGame.game_context.FomoBingo.minimum_numbers != null &&
                        this.currentGame.game_context.FomoBingo.minimum_numbers != ''
                            ? this.currentGame.game_context.FomoBingo.minimum_numbers
                            : 1,
                    context: {
                        team: this.currentGame.game_context.TeamBingo ? this.currentGame.game_context.TeamBingo.current_team : null,
                        fomo_numbers: this.currentGame.game_context.FomoBingo ? this.currentGame.game_context.FomoBingo.fomo_numbers : null,
                    },
                };
                if (this.currentGame.isMultistake || this.currentGame.isSlingoMultiStake) {
                    const price = {};
                    if (this.currentGame.isMultistake)
                        price[this.currentGame.game_context.VariableCardStake.ticket_currency_key] = parseFloat(
                            this.currentGame.game_context.VariableCardStake.ticket_prices[this.currentGame.selected_price],
                        );
                    else
                        price[this.currentGame.game_context.SlingoMultiStake.ticket_currency_key] = parseFloat(
                            this.currentGame.game_context.SlingoMultiStake.price_points_config[this.currentGame.selected_price].price,
                        );
                    ticket.context['price'] = price;
                    ticket.price = this.currentGame.ticket_cost.Cash;
                    ticket.winning = this.currentGame.selected_win;
                    ticket.jackpot = this.currentGame?.jackpot?.length > 0 ? this.currentGame?.jackpot[0]?.amount?.Cash : null;
                }
                this._selectedTickets.push(ticket);
            }
        }
        // console.log(this._selectedTickets);
        this.preBuySliderTracking(this._totalPurchasedTickets);
        this.OnTotalTicketsSelected.emit({
            totalPurchasedTickets: this._totalPurchasedTickets,
            totalPrice: this._totalPrice,
            purchaseTickets: this._selectedTickets,
        });
    }

    OnClearSelection() {
        if (this.fluffyRooms.indexOf(this.SelectedRoomId) > -1) this.cartTracking(this.SelectedRoomId, false, false, true);
        this.ClearFn(this.TodayDate, this.SelectedTime);
        //let date = this.dateList.find(x => x.id == this.TodayDate).display;
    }

    onSuccessfullPurchase(result: any) {
        this.OnClearSelection();
        result.prebuy_responses.forEach((element: any) => {
            if (element.status == 'success') {
                const foundIndex = this.Games.findIndex((x: any) => x.start_time == element.prebuy_response.purchase_info[0].start_time);
                if (foundIndex >= 0) {
                    this.currentGame = this.Games[foundIndex];
                    this.currentGame.purchased_count = element.prebuy_response.purchase_info[0].purchased_count;
                    this.currentGame.requested_ticket_count = element.prebuy_response.purchase_info[0].requested_ticket_count;
                    let purchaseFlag: number = 0;
                    if (this.purchasedGames && this.purchasedGames.length > 0) {
                        this.purchasedGames.forEach((item: any) => {
                            if (item.start_time == this.currentGame.start_time) purchaseFlag += 1;
                        });
                    }
                    if (purchaseFlag == 0 && this.currentGame.purchased_count == this.currentGame.requested_ticket_count) {
                        this.currentGame.number_of_players = Number(this.currentGame.number_of_players) + 1;
                        this.purchasedGames.push(this.currentGame);
                    }
                    if (this.currentGame.game_context && this.currentGame.game_context.TeamBingo) {
                        this.currentGame.game_context.TeamBingo = element.prebuy_response.purchase_info[0].game_context.TeamBingo;
                    }
                    if (this.currentGame.game_context && this.currentGame.game_context.FomoBingo) {
                        this.currentGame.game_context.FomoBingo = element.prebuy_response.purchase_info[0].game_context.FomoBingo;
                        this.Games.forEach((g) => {
                            if (g.purchased_count == 0)
                                g.game_context.FomoBingo.fomo_numbers =
                                    element.prebuy_response.purchase_info[0].game_context.FomoBingo.fomo_numbers.slice(
                                        0,
                                        g.game_context.FomoBingo.maximum_numbers,
                                    );
                        });
                    }
                    if (this.currentGame.game_context && this.currentGame.game_context.VariableCardStake) {
                        //this.currentGame.game_context.VariableCardStake = element.prebuy_response.purchase_info[0].game_context.VariableCardStake;
                        const price = {};
                        price[this.currentGame.game_context.VariableCardStake.ticket_currency_key] = parseFloat(
                            this.currentGame.game_context.VariableCardStake.ticket_prices_player_currency[this.currentGame.selected_price],
                        );
                        this.currentGame.game_context.VariableCardStake.price = price;
                        this.currentGame.selected_win = this.getWiiningAmount(
                            this.currentGame.game_context.VariableCardStake.price[
                                this.currentGame.game_context.VariableCardStake.ticket_currency_key
                            ],
                            this.currentGame.game_context.VariableCardStake.winning_amount,
                        )[this.currentGame.game_context.VariableCardStake.ticket_currency_key];
                    } else if (
                        this.currentGame.game_context &&
                        this.currentGame.game_context.SlingoMultiStake?.price_points_config_in_player_currency
                    ) {
                        const price = {};
                        price[this.currentGame.game_context.SlingoMultiStake.ticket_currency_key] = parseFloat(
                            this.currentGame.game_context.SlingoMultiStake.price_points_config_in_player_currency[this.currentGame.selected_price]
                                .price,
                        );
                        this.currentGame.game_context.SlingoMultiStake.price = price;
                        this.currentGame.selected_win = element.prebuy_response.purchase_info[0].winning_amount.Cash;
                    }
                    if (element.prebuy_response.purchase_info[0].winning_amount.Cash)
                        this.currentGame.winning_amount.Cash = element.prebuy_response.purchase_info[0].winning_amount.Cash;
                    this.currentgame_val = 0;
                }
            }
        });
    }

    ClearFn(dateID: number, timeID: number) {
        this.OnTotalTicketsSelected.emit({
            totalPurchasedTickets: 0,
            totalPrice: 0,
            purchaseTickets: [],
        });
        this._totalPurchasedTickets = 0;
        this._selectedTickets = [];
        this._totalPrice = 0;
        this.Games.forEach((game) => {
            game.current_purchased_count = 0;
            game.isRedClsEnabled = false;
            if (game.purchased_count == 0 && game.game_context.TeamBingo) game.game_context.TeamBingo.current_team = null;
        });
        this.currentgame_val = 0;
        this.ApplyFilterFn(dateID, timeID);
    }

    SortData(key: string) {
        if (this.SortingData[key] != '') {
            this.SortingData[key] = this.SortingData[key] == SORTING.DESC ? SORTING.ASC : SORTING.DESC;
        } else {
            this.SortingData[key] = SORTING.ASC;
        }

        Object.keys(this.SortingData).forEach((x) => {
            if (x !== key) {
                this.SortingData[x] = '';
            }
        });

        this.Games = this.Games.sort((a, b) => {
            if (key == 'ticket_cost') {
                if (this.SortingData[key] == SORTING.ASC) {
                    if (a['ticket_cost'].Cash < b['ticket_cost'].Cash) return -1;
                    else if (a['ticket_cost'].Cash > b['ticket_cost'].Cash) return 1;
                    else return 0;
                } else if (this.SortingData[key] == SORTING.DESC) {
                    if (a['ticket_cost'].Cash < b['ticket_cost'].Cash) return 1;
                    else if (a['ticket_cost'].Cash > b['ticket_cost'].Cash) return -1;
                    else return 0;
                }
            } else if (key == 'winning_amount') {
                if (this.SortingData[key] == SORTING.ASC) {
                    if (a['winning_amount'].Cash < b['winning_amount'].Cash) return -1;
                    else if (a['winning_amount'].Cash > b['winning_amount'].Cash) return 1;
                    else return 0;
                } else if (this.SortingData[key] == SORTING.DESC) {
                    if (a['winning_amount'].Cash < b['winning_amount'].Cash) return 1;
                    else if (a['winning_amount'].Cash > b['winning_amount'].Cash) return -1;
                    else return 0;
                }
            } else {
                if (this.SortingData[key] == SORTING.ASC) {
                    if (a[key] < b[key]) return -1;
                    else if (a[key] > b[key]) return 1;
                    else return 0;
                } else if (this.SortingData[key] == SORTING.DESC) {
                    if (a[key] < b[key]) return 1;
                    else if (a[key] > b[key]) return -1;
                    else return 0;
                }
            }
            return 0;
        });
    }

    clearValue(evt: any) {
        if (evt.target.value == 0 || evt.target.value == '') {
            evt.target.value = '';
        }
    }

    onChange(evt: any, row_no: number) {
        //if (evt.target.value == '' || evt.target.value == null || evt.target.value == NaN || evt.target.value == 'NaN') {
        //  evt.target.value = 0;
        //}
        if (evt.target.value == '' || evt.target.value == null || Number.isNaN(evt.target.value) || evt.target.value == 'NaN') {
            evt.target.value = 0;
        }
        const regx = /^\d+$/;
        //let regx=/[0-9]/;
        if (regx.test(evt.target.value)) {
            const obj = {
                id: +row_no,
                selectedValue: +evt.target.value,
            };
            this.Selected(obj);
        } else {
            evt.target.value = 0;
            return false;
        }
        return;
    }

    keyHandler(evt: any) {
        if (evt.keyCode == 13) {
            const nxtId = parseInt(evt.srcElement.id) + 1;
            const nIdex = nxtId + '';
            if (this.ticket_list_arr[nIdex] != null || this.ticket_list_arr[nIdex] != undefined) this.ticket_list_arr[nIdex].focus();
        }
        if (evt.keyCode >= 48 && evt.keyCode <= 57) {
            return true;
        } else if (evt.keyCode == 8 || evt.keyCode == 46) {
            return true;
        } else {
            evt.preventDefault();
        }
        return;
    }

    onRoomChanged(roomID: number) {
        this.isLoading = true;
        this.SelectedRoomId = roomID;
        if (this.fabGrabRoomIds.indexOf(this.SelectedRoomId) > -1) {
            this.isFabGrabRoom = true;
        } else {
            this.isFabGrabRoom = false;
        }
        if (this.friendsRoomIds.indexOf(this.SelectedRoomId) > -1) {
            this.isFriendsRoom = true;
        } else {
            this.isFriendsRoom = false;
        }
        const currentRoom = this.DropDownRoomData.filter((r: Games) => {
            return r.id == roomID;
        })[0];
        if (currentRoom.max_player_limit == null) {
            this.isCappedRoom = false;
        } else {
            this.isCappedRoom = true;
        }
        if (this.fluffyRooms.indexOf(this.SelectedRoomId) > -1) {
            this.isFluffyFavRoom = true;
            this.roomTracking(this.SelectedRoomId, false, false);
        } else this.isFluffyFavRoom = false;
        //let _games = JSON.parse(JSON.stringify(this.dataObs$.value));
        this.SelectedDate = new Date();
        this.TodayDate = 1;
        this.Date = '';
        this.GameSlot = '';
        this.ClearFn(this.TodayDate, this.SelectedTime);
        this.BindDatesToBingoRoom(this.SelectedRoomId);
    }

    onDateChanged(dateId: number) {
        this.isGamesAvailCurrRoom = false;
        this.GameSlot = '';
        this.PrebuyRoomErrorMessage = '';
        this.TodayDate = dateId;
        const dt = new Date().setHours(0, 0, 0, 0);
        const date = this.dateList.find((x) => x.id == dateId).display;
        const selected_date = new Date(date).setHours(0, 0, 0, 0);
        if (selected_date == dt) {
            this.GenerateTimeOfDayData(this.timeArr, new Date());
        } else {
            this.GenerateTimeOfDayData(this.timeArr, new Date(new Date(selected_date).setHours(0)));
        }
        this.GetSelectedRoomData(this.SelectedRoomId, date);
    }

    onTimeChanged(timeId: any) {
        this.SelectedTime = timeId;
        this.ApplyFilterFn(this.TodayDate, this.SelectedTime);
        this.prebuyScroll.nativeElement.scrollTo(0, 0);
    }

    ApplyFilterFn(dateID: number, timeID: number) {
        this.clearMessages(this.PREBUY_NODATA_SCOPE);
        // for (let i = 0; i < this.Games.length; i++) {
        //     const game = this.Games[i];
        //     const keysArr = Object.keys(game);
        //     keysArr.forEach((key) => {
        //         this.SortingData[key] = SORTING.EMPTY;
        //     });
        //     break;
        // }
        if (this.Games.length > 0) {
            const firstGame = this.Games[0];
            const keysArr = Object.keys(firstGame);

            keysArr.forEach((key) => {
                this.SortingData[key] = SORTING.EMPTY;
            });
        }
        this.ApplyDateFilterFn(dateID, timeID);
    }

    ApplyDateFilterFn(dateId: number, timeId: number) {
        if (this.dateList.length > 0) {
            this.Games = [];
            const _date = this.dateList.find((x) => x.id == dateId);
            const darray = _date.display.split('-');
            const selected_time = new Date(new Date(darray[0], darray[1] - 1, darray[2]).setHours(timeId, 0, 0));
            const end_time = new Date(new Date(darray[0], darray[1] - 1, darray[2]).setHours(23, 59, 59));
            this.Games = this.UnsortedGames.filter((item) => {
                const _d = new Date(item.start_time * 1000);
                return _d >= selected_time && _d <= end_time;
            });
            if (this.Games.length > 0) {
                this.OnTotalTicketsSelected.emit({
                    totalPurchasedTickets: 0,
                    totalPrice: 0,
                    purchaseTickets: [],
                });
                this._totalPurchasedTickets = 0;
                this._selectedTickets = [];
                this._totalPrice = 0;
                this.Games.forEach((game) => {
                    game.current_purchased_count = 0;
                    if (game.purchased_count == 0 && game.game_context.TeamBingo) game.game_context.TeamBingo.current_team = null;
                });
                this.currentgame_val = 0;
            }

            if (this.Games != null && this.Games.length > 0) this.isGamesAvailCurrRoom = true;
            else this.isGamesAvailCurrRoom = false;
        } else {
            this.isGamesAvailCurrRoom = false;
        }
        if (!this.isGamesAvailCurrRoom) {
            //that.messageQueueService.addError(that.prebuynavcolitems.columns.gamesAvailCurrRoomText);
            if (this.PrebuyRoomErrorMessage && this.PrebuyRoomErrorMessage != '') {
                this.messageQueueService.addError(this.PrebuyRoomErrorMessage, undefined, this.PREBUY_NODATA_SCOPE);
            } else {
                this.messageQueueService.addError(this.prebuynavcolitems?.columns?.gamesAvailCurrRoomText, undefined, this.PREBUY_NODATA_SCOPE);
            }
        }
    }

    scrollHandler() {
        if (this.lazyPrebuyConfig.enableLazyPrebuyFeed) {
            // this.ticketsResource.remainingPrebuyListObservable.subscribe((response: any) => {
            //   if (!response) {
            //     if (!this.isScrolled) {
            //       this.isScrolled = true;
            //       //this.GetSelectedRoomData(this.SelectedRoomId, this.scrollDate, true);
            //     }
            //   }
            // })
        }
    }

    getSelectRoomPrebuyData(SelectedRoomID: any, from_time: any, end_time: any) {
        if (this.lazyPrebuyConfig.enableLazyPrebuyFeed) {
            this.getLazyPrebuyData(SelectedRoomID, from_time, end_time);
        } else {
            this.getPrebuyData(SelectedRoomID, from_time, end_time);
        }
    }
    sortParentAndChildOnTime(key: string) {
        this.UnsortedGames = this.UnsortedGames.sort((a: any, b: any) => {
            if (a[key] < b[key]) return -1;
            else if (a[key] > b[key]) return 1;
            else return 0;
        });
    }

    getPrebuyData(SelectedRoomID: any, from_time: any, end_time: any) {
        this.ticketsResource.getPrebuyData(SelectedRoomID, from_time, end_time).subscribe(
            (data) => {
                this.isScrolled = false;
                this.isLoading = false;
                this.clearMessages(this.PREBUY_NODATA_SCOPE);
                if (data != null && data['result'] != null) {
                    const responseData = data['result'];
                    if (
                        responseData != null &&
                        responseData.schedule != null &&
                        responseData.schedule[SelectedRoomID] &&
                        responseData.schedule[SelectedRoomID].length > 0
                    ) {
                        this.isGamesAvailCurrRoom = true;
                        this.UnsortedGames = this.UnsortedGames.concat(responseData.schedule[SelectedRoomID]);
                        this.sortParentAndChildOnTime('start_time');
                        this.UnsortedGames.forEach((game) => {
                            game.date = this.transformUnixToTime(game.start_time);
                            game.DisplayTime = this.datePipe.transform(game.date, 'HH:mm'); //this.datePipe.transform(dt, 'HH:mm');
                            game.DisplayDate = this.datePipe.transform(game.date, 'dd/MM/yyyy');
                            game.display_name = this.getGameNameFromMappings(game.room_id, game.display_name);
                            game.current_purchased_count = 0;
                            game.selected_price = 0;
                            game.selected_win = 0;
                            const defaultMultiStakeValue = game.game_context?.VariableCardStake?.default_multi_price;
                            game.keys = [];
                            if (game.game_context.VariableCardStake && game.game_context.VariableCardStake.winning_amount) {
                                game.isMultistake = true;
                                game.selected_price = defaultMultiStakeValue
                                    ? game.game_context.VariableCardStake.ticket_prices_player_currency.findIndex((e: any) => {
                                          return e == defaultMultiStakeValue;
                                      })
                                    : 0;
                                if (game.game_context.VariableCardStake.price == null) {
                                    //game.selected_win = this.getWiiningAmount(game.game_context.VariableCardStake.ticket_prices_player_currency[0], game.game_context.VariableCardStake.winning_amount)[game.game_context.VariableCardStake.ticket_currency_key];
                                    const selectedWinIndex = defaultMultiStakeValue
                                        ? game.game_context.VariableCardStake.winning_amount.findIndex((w: any) => {
                                              return w.price == defaultMultiStakeValue;
                                          })
                                        : 0;
                                    game.selected_win = this.getWiiningAmount(
                                        game.game_context.VariableCardStake.ticket_prices_player_currency[selectedWinIndex],
                                        game.game_context.VariableCardStake.winning_amount,
                                    )[game.game_context.VariableCardStake.ticket_currency_key];
                                } else
                                    game.selected_win = this.getWiiningAmount(
                                        game.game_context.VariableCardStake.price[game.game_context.VariableCardStake.ticket_currency_key],
                                        game.game_context.VariableCardStake.winning_amount,
                                    )[game.game_context.VariableCardStake.ticket_currency_key];
                                if (game.game_context.VariableCardStake.winning_amount[0].per_pool_win_amount_split) {
                                    game.keys = Object.keys(game.game_context.VariableCardStake.winning_amount[0].per_pool_win_amount_split);
                                }
                            } else if (
                                game.game_context.SlingoMultiStake &&
                                game.game_context.SlingoMultiStake?.price_points_config_in_player_currency
                            ) {
                                game.isSlingoMultiStake = true;
                                game.selected_price = 0;
                                game.selected_win = game?.winning_amount?.Cash;
                                game.slingoSecondParaText = this.multistakeContent?.SlingoParaText2?.replace(
                                    '{0}',
                                    this.vnCurrency.transform(game.winning_amount.Cash, game.currency),
                                );
                                game.keys = this.multistakeContent?.SlingoKeys?.split(',');
                            }
                            game.isRedClsEnabled = false;
                            if (
                                game.game_context.FomoBingo &&
                                game.game_context.FomoBingo.fomo_numbers == null &&
                                game.game_context.FomoBingo.previous_fomo_numbers != null
                            ) {
                                game.game_context.FomoBingo.fomo_numbers = game.game_context.FomoBingo.previous_fomo_numbers.slice(
                                    0,
                                    game.game_context.FomoBingo.maximum_numbers,
                                );
                            }
                        });
                        this.Games = [];
                        if (this.GameSlot) {
                            //let timeOffset = new Date().getTimezoneOffset();
                            const slots = this.GameSlot.split(',');
                            let range = [];
                            let slotStart: Date, slotEnd: Date;
                            slots.forEach((slot: string) => {
                                range = slot.split('-');
                                //slotStart = new Date(this.SelectedDate.setHours(+range[0].split(':')[0], +range[0].split(':')[1], 0));
                                slotStart = new Date(this.Date + ' ' + range[0] + ' GMT');
                                if (range.length == 1) slotEnd = slotStart;
                                else slotEnd = new Date(this.Date + ' ' + range[1] + ' GMT');
                                this.UnsortedGames.filter((g: any) => {
                                    return g.date >= slotStart && g.date <= slotEnd;
                                }).forEach((g: any) => {
                                    this.Games.push(g);
                                });
                            });
                            this.UnsortedGames = this.Games;
                            if (this.Games.length == 0) {
                                this.isGamesAvailCurrRoom = false;
                                this.messageQueueService.addError(
                                    this.prebuynavcolitems?.columns?.gamesAvailCurrRoomText,
                                    undefined,
                                    this.PREBUY_NODATA_SCOPE,
                                );
                            }
                        } else {
                            this.Games = this.UnsortedGames;
                        }
                        this.ApplyFilterFn(this.TodayDate, this.SelectedTime);
                        this.SortingData['start_time'] = SORTING.ASC;
                    } else {
                        this.isGamesAvailCurrRoom = false;
                        this.messageQueueService.addError(
                            this.prebuynavcolitems?.columns?.gamesAvailCurrRoomText,
                            undefined,
                            this.PREBUY_NODATA_SCOPE,
                        );
                    }
                } else if (data != null && data['responseMessage'].toLowerCase() != 'success') {
                    this.isGamesAvailCurrRoom = false;
                    let errorMessage = this.prebuynavcolitems?.columns[data['responseCode']];
                    if (!errorMessage) {
                        errorMessage = this.prebuynavcolitems?.columns?.gamesAvailCurrRoomText;
                    }
                    this.PrebuyRoomErrorMessage = errorMessage;
                    this.messageQueueService.addError(errorMessage, undefined, this.PREBUY_NODATA_SCOPE);
                } else {
                    this.isGamesAvailCurrRoom = false;
                    this.messageQueueService.addError(this.prebuynavcolitems?.columns?.gamesAvailCurrRoomText, undefined, this.PREBUY_NODATA_SCOPE);
                }
                this.jackpotToggle();
            },
            (error) => {
                if (error) {
                    this.isLoading = false;
                    this.messageQueueService.addError(this.prebuynavcolitems?.columns?.gamesAvailCurrRoomText, undefined, this.PREBUY_NODATA_SCOPE);
                }
            },
        );
    }
    getLazyPrebuyData(SelectedRoomID: any, from_time: any, end_time: any) {
        this.ticketsResource.getPrebuyData(SelectedRoomID, from_time, end_time).subscribe(
            (data) => {
                this.isScrolled = false;
                this.isLoading = true;
                this.clearMessages(this.PREBUY_NODATA_SCOPE);
                if (data != null && data['result'] != null) {
                    const responseData = data['result'];
                    if (
                        responseData != null &&
                        responseData.schedule != null &&
                        responseData.schedule[SelectedRoomID] &&
                        responseData.schedule[SelectedRoomID].length > 0
                    ) {
                        this.UnsortedGames = this.UnsortedGames.concat(responseData.schedule[SelectedRoomID]);
                        this.UnsortedGames.forEach((game) => {
                            game.date = this.transformUnixToTime(game.start_time);
                            game.DisplayTime = this.datePipe.transform(game.date, 'HH:mm'); //this.datePipe.transform(dt, 'HH:mm');
                            game.DisplayDate = this.datePipe.transform(game.date, 'dd/MM/yyyy');
                            game.display_name = this.getGameNameFromMappings(game.room_id, game.display_name);
                            game.current_purchased_count = 0;
                            game.selected_price = 0;
                            game.selected_win = 0;
                            game.TodayDate = this.TodayDate;
                            const defaultMultiStakeValue = game.game_context?.VariableCardStake?.default_multi_price;
                            game.keys = [];
                            if (game.game_context.VariableCardStake && game.game_context.VariableCardStake.winning_amount) {
                                game.isMultistake = true;
                                game.selected_price = defaultMultiStakeValue
                                    ? game.game_context.VariableCardStake.ticket_prices_player_currency.findIndex((e: any) => {
                                          return e == defaultMultiStakeValue;
                                      })
                                    : 0;
                                if (game.game_context.VariableCardStake.price == null) {
                                    //game.selected_win = this.getWiiningAmount(game.game_context.VariableCardStake.ticket_prices_player_currency[0], game.game_context.VariableCardStake.winning_amount)[game.game_context.VariableCardStake.ticket_currency_key];
                                    const selectedWinIndex = defaultMultiStakeValue
                                        ? game.game_context.VariableCardStake.winning_amount.findIndex((w: any) => {
                                              return w.price == defaultMultiStakeValue;
                                          })
                                        : 0;
                                    game.selected_win = this.getWiiningAmount(
                                        game.game_context.VariableCardStake.ticket_prices_player_currency[selectedWinIndex],
                                        game.game_context.VariableCardStake.winning_amount,
                                    )[game.game_context.VariableCardStake.ticket_currency_key];
                                } else
                                    game.selected_win = this.getWiiningAmount(
                                        game.game_context.VariableCardStake.price[game.game_context.VariableCardStake.ticket_currency_key],
                                        game.game_context.VariableCardStake.winning_amount,
                                    )[game.game_context.VariableCardStake.ticket_currency_key];
                                if (game.game_context.VariableCardStake.winning_amount[0].per_pool_win_amount_split) {
                                    game.keys = Object.keys(game.game_context.VariableCardStake.winning_amount[0].per_pool_win_amount_split);
                                }
                            } else if (
                                game.game_context.SlingoMultiStake &&
                                game.game_context.SlingoMultiStake?.price_points_config_in_player_currency
                            ) {
                                game.isSlingoMultiStake = true;
                                game.selected_price = 0;
                                game.selected_win = game?.winning_amount?.Cash;
                                game.slingoSecondParaText = this.multistakeContent?.SlingoParaText2?.replace(
                                    '{0}',
                                    this.vnCurrency.transform(game.winning_amount.Cash, game.currency),
                                );
                                game.keys = this.multistakeContent?.SlingoKeys?.split(',');
                            }
                            game.isRedClsEnabled = false;
                            if (
                                game.game_context.FomoBingo &&
                                game.game_context.FomoBingo.fomo_numbers == null &&
                                game.game_context.FomoBingo.previous_fomo_numbers != null
                            ) {
                                game.game_context.FomoBingo.fomo_numbers = game.game_context.FomoBingo.previous_fomo_numbers.slice(
                                    0,
                                    game.game_context.FomoBingo.maximum_numbers,
                                );
                            }
                        });
                        this.Games = [];
                        if (this.GameSlot) {
                            //let timeOffset = new Date().getTimezoneOffset();
                            const slots = this.GameSlot.split(',');
                            let range = [];
                            let slotStart: Date, slotEnd: Date;
                            slots.forEach((slot: string) => {
                                range = slot.split('-');
                                //slotStart = new Date(this.SelectedDate.setHours(+range[0].split(':')[0], +range[0].split(':')[1], 0));
                                slotStart = new Date(this.Date + ' ' + range[0] + ' GMT');
                                if (range.length == 1) slotEnd = slotStart;
                                else slotEnd = new Date(this.Date + ' ' + range[1] + ' GMT');
                                this.UnsortedGames.filter((g: any) => {
                                    return g.date >= slotStart && g.date <= slotEnd;
                                }).forEach((g: any) => {
                                    this.Games.push(g);
                                });
                            });
                            this.UnsortedGames = this.Games;
                            if (this.Games.length == 0) {
                                this.isGamesAvailCurrRoom = false;
                                this.messageQueueService.addError(
                                    this.prebuynavcolitems?.columns?.gamesAvailCurrRoomText,
                                    undefined,
                                    this.PREBUY_NODATA_SCOPE,
                                );
                            }
                        }
                        this.getRemainingPrebuyData(SelectedRoomID, from_time, end_time);
                    } else {
                        this.getRemainingPrebuyData(SelectedRoomID, from_time, end_time);
                    }
                } else if (data != null && data['responseMessage'].toLowerCase() != 'success') {
                    this.isGamesAvailCurrRoom = false;
                    let errorMessage = this.prebuynavcolitems?.columns[data['responseCode']];
                    if (!errorMessage) {
                        errorMessage = this.prebuynavcolitems?.columns?.gamesAvailCurrRoomText;
                    }
                    this.PrebuyRoomErrorMessage = errorMessage;
                    this.messageQueueService.addError(errorMessage, undefined, this.PREBUY_NODATA_SCOPE);
                } else {
                    this.isGamesAvailCurrRoom = false;
                    this.messageQueueService.addError(this.prebuynavcolitems?.columns?.gamesAvailCurrRoomText, undefined, this.PREBUY_NODATA_SCOPE);
                }
            },
            (error) => {
                if (error) {
                    this.isLoading = false;
                    this.messageQueueService.addError(this.prebuynavcolitems?.columns?.gamesAvailCurrRoomText, undefined, this.PREBUY_NODATA_SCOPE);
                }
            },
        );
    }
    getRemainingPrebuyData(SelectedRoomID: any, from_time: any, end_time: any) {
        const date = this.dateList.find((x) => x.id == this.TodayDate).display;
        let FromDate = new Date(new Date(date));
        FromDate = new Date(
            FromDate.getUTCFullYear(),
            FromDate.getUTCMonth(),
            FromDate.getUTCDate(),
            FromDate.getUTCHours(),
            FromDate.getUTCMinutes(),
            FromDate.getUTCSeconds(),
        );
        let EndDate = new Date(new Date(date));
        EndDate = new Date(
            EndDate.getUTCFullYear(),
            EndDate.getUTCMonth(),
            EndDate.getUTCDate(),
            EndDate.getUTCHours(),
            EndDate.getUTCMinutes(),
            EndDate.getUTCSeconds(),
        );
        EndDate.setDate(FromDate.getDate() + 1);
        const revisedFromTime = end_time;
        const revisedEndTime = new Date(date).setHours(23, 59, 59) / 1000;
        let intervelEndTime = revisedFromTime + Number(this.lazyPrebuyConfig.prebuyListTimeInterval);
        intervelEndTime = intervelEndTime < revisedEndTime ? intervelEndTime : revisedEndTime;
        if (intervelEndTime <= revisedEndTime && revisedFromTime != intervelEndTime) {
            this.from_time = revisedFromTime;
            this.end_time = intervelEndTime;
            this.checkGameSlotAndGetPrebuy(SelectedRoomID, FromDate, EndDate);
        } else {
            this.isGamesAvailCurrRoom = true;
            this.isLoading = false;
            this.UnsortedGames = this.removeDuplicatesFromGameData();
            this.UnsortedGames = this.UnsortedGames.filter((e) => {
                return e.TodayDate == this.TodayDate;
            });
            this.sortParentAndChildOnTime('start_time');
            this.Games = this.UnsortedGames;
            // if (this._loadingIndicatorPrebuy.inProgress) {
            //   this._loadingIndicatorPrebuy.done();
            // }
            if (this.Games.length == 0) {
                this.isGamesAvailCurrRoom = false;
                this.messageQueueService.addError(this.prebuynavcolitems?.columns?.gamesAvailCurrRoomText, undefined, this.PREBUY_NODATA_SCOPE);
            }
            this.ApplyFilterFn(this.TodayDate, this.SelectedTime);
            this.SortingData['start_time'] = SORTING.ASC;
        }
    }
    removeDuplicatesFromGameData() {
        const uniqueGames = this.UnsortedGames.filter((value, index, self) => index === self.findIndex((t) => t.start_time === value.start_time));
        return uniqueGames;
    }
    GetSelectedRoomData(SelectedRoomID: number, date: string, isScrollEvent: boolean = false) {
        const timeNow = new Date().getTime() / 1000;
        let FromDate = new Date(new Date(date));
        FromDate = new Date(
            FromDate.getUTCFullYear(),
            FromDate.getUTCMonth(),
            FromDate.getUTCDate(),
            FromDate.getUTCHours(),
            FromDate.getUTCMinutes(),
            FromDate.getUTCSeconds(),
        );
        let EndDate = new Date(new Date(date));
        EndDate = new Date(
            EndDate.getUTCFullYear(),
            EndDate.getUTCMonth(),
            EndDate.getUTCDate(),
            EndDate.getUTCHours(),
            EndDate.getUTCMinutes(),
            EndDate.getUTCSeconds(),
        );
        EndDate.setDate(FromDate.getDate() + 1);
        const nextDayTime = EndDate.getTime() / 1000;
        this.scrollDate = date;
        if (this.end_time != nextDayTime || !isScrollEvent) {
            if (!isScrollEvent) {
                this.isLoading = true;
                this.ngOnDestroy();
                this.from_time = FromDate.getTime() / 1000;
                this.from_time = Math.ceil(timeNow > this.from_time ? timeNow : this.from_time);
                if (this.lazyPrebuyConfig.enableLazyPrebuyFeed) {
                    //this._loadingIndicatorPrebuy = this.loadingIndicatorService.start();
                    this.end_time = this.from_time + Number(this.lazyPrebuyConfig.prebuyListTimeInterval);
                    this.end_time = this.end_time < nextDayTime ? this.end_time : nextDayTime;
                    this.checkGameSlotAndGetPrebuy(SelectedRoomID, FromDate, EndDate);
                } else {
                    this.end_time = nextDayTime;
                    this.checkGameSlotAndGetPrebuy(SelectedRoomID, FromDate, EndDate);
                }
            } else if (isScrollEvent && !this.deviceService.isMobile && !this.deviceService.isTablet) {
                this.from_time = this.end_time;
                this.end_time += Number(this.lazyPrebuyConfig.prebuyListTimeInterval);
                this.end_time = this.end_time < nextDayTime ? this.end_time : nextDayTime;
                this.checkGameSlotAndGetPrebuy(SelectedRoomID, FromDate, EndDate);
            }
        }
    }

    checkGameSlotAndGetPrebuy(SelectedRoomID: any, FromDate: any, EndDate: any, fromTime?: any, endTime?: any) {
        if (this.GameSlot) {
            const slots = this.GameSlot.split(',');
            let range = [];
            let slotStart: Date, slotEnd: Date;
            this.Date = this.datePipe.transform(this.SelectedDate, 'MM/dd/yyyy')!;
            slots.forEach((slot: string, index: number) => {
                range = slot.split('-');
                //slotStart = new Date(this.SelectedDate.setHours(+range[0].split(':')[0], +range[0].split(':')[1], 0));
                slotStart = new Date(this.Date + ' ' + range[0] + ' GMT');
                if (index == 0) {
                    this.from_time = slotStart.getTime() / 1000;
                    if (this.datePipe.transform(slotStart, 'MM/dd/yyyy') != this.datePipe.transform(FromDate, 'MM/dd/yyyy')) {
                        let s_Date = '';
                        this.SelectedDate = slotStart;
                        s_Date = this.transformDate(this.SelectedDate);
                        this.dateList.forEach((d: any, i: number) => {
                            if (d.display == s_Date) this.TodayDate = i + 1;
                        });
                        this.SelectedTime = slotStart.getHours();
                    }
                    this.GenerateTimeOfDayData(this.timeArr, slotStart);
                }
                if (range.length == 1) slotEnd = slotStart;
                else slotEnd = new Date(this.Date + ' ' + range[1] + ' GMT');
                if (slotEnd > EndDate) this.end_time = slotEnd.getTime() / 1000;
            });
        }
        if (fromTime && endTime) this.getSelectRoomPrebuyData(SelectedRoomID, fromTime, endTime);
        else this.getSelectRoomPrebuyData(SelectedRoomID, this.from_time, this.end_time);
    }

    getWiiningAmount(price: any, winning_amount: any) {
        const g = winning_amount.filter((w: any) => {
            return w.price == price;
        });
        return g[0].winning_amount;
    }
    selectTeam(index: any) {
        // let game = this.Games.filter(item => {
        //   return item.start_time == start_time;
        // });

        this.currentGame = this.Games[index];
        this.addzIndexClass();
        if (this.currentGame != undefined) {
            const requiredModalData = {
                currentGame: this.currentGame,
                teamColors: this.teamColors,
            };
            const dialogRef = this.dialog.open(TeamModalPopupComponent, {
                data: requiredModalData,
                panelClass: 'team-modal-popup',
            });
            dialogRef.componentInstance.onteamSelected.subscribe((response) => {
                if (response) {
                    this.onTeamSelected(response.team);
                }
            });
            dialogRef.afterClosed().subscribe(() => {
                this.onNumbersClose();
            });
            this.OnTeamModalOpen.emit(true);
        }
    }

    selectPrice(index: any) {
        this.addzIndexClass();
        this.currentGame = this.Games[index];
        if (this.currentGame != undefined) {
            const requiredModalData = {
                currentGame: this.currentGame,
                multistakeContent: this.multistakeContent,
                enableNewPrebuyIcons: this.enableNewPrebuyIcons,
                roomName: this.currentRoom.name,
                iframeFlag: this.prebuy_route_status,
            };
            const dialogRef = this.dialog.open(PriceModalPopupComponent, {
                data: requiredModalData,
                panelClass: 'price-modal-popup',
            });
            dialogRef.componentInstance.toggleSelectedPriceInfoEmit.subscribe(() => {
                this.addzIndexClass();
            });
            dialogRef.componentInstance.onClosePrice.subscribe(() => {
                this.closePrice();
            });
            dialogRef.componentInstance.onPriceSelectedEmit.subscribe((obj) => {
                this.onPriceSelected(obj);
            });
            this.multiStakePopupTracking(this.SelectedRoomId, false);
            this.OnTeamModalOpen.emit(true);
        }
    }

    onPriceSelected(obj: any) {
        if (obj.price != '') {
            this.addzIndexClass();
            this.currentGame.selected_price = obj.index;
            if (this.currentGame.isMultistake)
                this.currentGame.selected_win = this.getWiiningAmount(obj.price, this.currentGame.game_context.VariableCardStake.winning_amount)[
                    this.currentGame.game_context.VariableCardStake.ticket_currency_key
                ];
            else this.currentGame.selected_win = this.currentGame.winning_amount.Cash;
            this.Selected({
                id: +this.Games.indexOf(this.currentGame),
                selectedValue: +this.currentGame.current_purchased_count,
            });
        }
    }
    closePrice() {
        this.OnTeamModalOpen.emit(false);
        this.removezIndexClass();
        this.multiStakePopupTracking(this.SelectedRoomId, true);
    }
    onTeamSelected(team: any) {
        if (team != '') {
            this.currentGame.game_context.TeamBingo.current_team = team;
            this.Selected({
                id: +this.Games.indexOf(this.currentGame),
                selectedValue: +this.currentGame.current_purchased_count,
            });
        }
        this.removezIndexClass();
        this.OnTeamModalOpen.emit(false);
    }

    onRandomSelected() {
        const RandomSelectIndex = Math.floor(Math.random() * this.currentGame.game_context.TeamBingo.teams.length);
        this.currentGame.game_context.TeamBingo.current_team = this.currentGame.game_context.TeamBingo.teams[RandomSelectIndex];
        this.OnTeamModalOpen.emit(false);
    }

    LoaderStatusChange() {
        if (this.fluffyRooms && this.fluffyRooms.indexOf(this.SelectedRoomId) > -1) this.cartTracking(this.SelectedRoomId, true, false, true);
        // this.preBuySliderTracking();
        if (this.isLoading && this._loadingIndicator.inProgress) {
            this._loadingIndicator.done();
        }
    }

    selectNumbers(index: any) {
        this.currentGame = this.Games[index];
        this.addzIndexClass();
        if (this.currentGame != undefined) {
            if (this.currentGame.game_context.FomoBingo.fomo_numbers == null) this.currentGame.game_context.FomoBingo.fomo_numbers = [];
            const requiredModalData = {
                currentGame: this.currentGame,
                prebuynavcolitems: this.prebuynavcolitems,
            };
            const dialogRef = this.dialog.open(TopandtailPopupComponent, {
                data: requiredModalData,
                panelClass: 'top-n-tail-popup',
            });
            dialogRef.componentInstance.onSelected.subscribe((response) => {
                if (response) {
                    this.Selected({
                        id: +this.Games.indexOf(response.currentGame),
                        selectedValue: +response.currentGame.current_purchased_count,
                    });
                }
            });
            this.OnTeamModalOpen.emit(true);
            dialogRef.afterClosed().subscribe(() => {
                this.onNumbersClose();
            });
        }
    }

    onNumbersClose() {
        this.OnTeamModalOpen.emit(false);
        this.removezIndexClass();
    }

    //selectPattern(_p: string) {
    // this.currentGame.game_context.FomoBingo.fomo_numbers = [];
    // // let pattern = JSON.parse(this.globalClientConfig.messages.fabGrabPatterns)[p];
    // // if (!pattern) {
    // //let pattern = this.GetRandomPattern(1, 90, this.currentGame.game_context.FomoBingo.maximum_numbers, []);
    // //}
    // this.currentGame.game_context.FomoBingo.fomo_numbers = pattern;
    // this.Selected({
    //   id: +this.Games.indexOf(this.currentGame),
    //   selectedValue: +this.currentGame.current_purchased_count
    // });
    //}

    showInfo(maxnumers: any) {
        const message = this.globalClientConfig?.messages?.fabGrabInfoMessage?.replace('XX', maxnumers),
            header = this.globalClientConfig?.messages?.fabGrabInfoHeader,
            closeBtn = this.globalClientConfig?.messages?.confirmCloseButton;
        this.confirmMessageService.confirmThis(
            header,
            message,
            '',
            '',
            closeBtn,
            () => {},
            () => {},
        );
    }
    preBuyBonusDetailsData(responseData: any, roomId: number, gameTypeId: any) {
        let bonusDetailsItems: any[] = [];
        const itemData: any[] = [];
        let message: any;
        this.indexVar = 0;
        if (
            responseData &&
            this.globalClientConfig?.messages?.preBuyBonusDetailsText != null &&
            this.globalClientConfig?.messages?.preBuyBonusDetailsText != undefined
        ) {
            bonusDetailsItems = responseData;
            const bonusItemcurrency = this.claimsService.get('currency')!;
            let counter = 1;
            bonusDetailsItems.forEach((item) => {
                const issuedDate = new Date(item.issuedExpiryDate).getTime(); //item.issuedExpiryDate;
                //let date = new Date(issuedDate).toLocaleDateString('en-us');
                const issuedHours = new Date(item.issuedExpiryDate).getHours();
                const ampm = issuedHours >= 12 ? 'PM' : 'AM';
                const dateFormat = this.datePipe.transform(issuedDate, 'dd-MM-yyyy');
                const timeFormat = this.datePipe.transform(issuedDate, 'HH:mm');
                const amountCurrency = this.vnCurrency.transform(item.wargeringAmount, bonusItemcurrency);
                message = decodeURI(this.globalClientConfig.messages.preBuyBonusDetailsText)
                    .replace('{0}', item.bonusCode)
                    .replace('{1}', amountCurrency)
                    .replace('{2}', dateFormat + ' ' + timeFormat + ampm)
                    .replace('{roomId}', roomId.toString())
                    .replace('{gameTypeId}', gameTypeId);
                const loopItem: any = {};
                loopItem.id = counter;
                loopItem.message = message;
                itemData.push(loopItem);
                counter++;
            });
            if (itemData.length > 0) {
                this.getItemData(itemData);
            }
        }
    }
    addzIndexClass() {
        if (document.querySelector('.tickets-component')) {
            document.querySelector('.tickets-component')!.classList.add('prebuy-zindex');
        }
    }
    removezIndexClass() {
        if (document.querySelector('.tickets-component')) {
            document.querySelector('.tickets-component')!.classList.remove('prebuy-zindex');
        }
    }

    getItemData(itemData: any) {
        this.preBuyInterval = setInterval(() => {
            this.clearMessages(this.PREBUY_SCOPE);
            if (itemData.length == 0) {
                clearInterval(this.preBuyInterval);
            }
            while (itemData.length > 0) {
                let filterData: any;
                if (itemData.length > 2) {
                    filterData = itemData.slice(0, 2);
                } else {
                    filterData = itemData;
                }
                itemData = itemData.filter((val: any) => !filterData.find((rm: any) => rm.id == val.id));
                const messages: any = filterData.map((a: any) => a.message);
                this.clearMessages(this.PREBUY_SCOPE);
                this.messageQueueService.addMultiple(messages);
            }
        }, this.featureConfig.preBuyBonusDetailsSetInterval);
    }

    clearMessages(scopes: string) {
        if (this.messageQueueService.messages().find((message: Message) => message.scope == scopes)) {
            this.messageQueueService.clear({ scope: scopes, clearPersistent: true });
        }
    }

    jackpotToggle() {
        if (this.Games[0]?.jackpot) {
            this.isJackpotAvailable = true;
        }
    }

    prevCount: number = 0;
    preBuySliderTracking(count: number) {
        if (count != undefined) {
            let action: string = count > this.prevCount ? 'add' : 'minus';
            this.prevCount = count;
            this.trackingModel.eventName = 'Event.Tracking';
            this.trackingModel.CategoryEvent = this.prebuy_route_status ? 'bingo room' : 'bingo';
            this.trackingModel.LabelEvent = 'pre-buy';
            this.trackingModel.ActionEvent = 'click';
            this.trackingModel.PositionEvent = 'pre-buy overlay';
            this.trackingModel.LocationEvent = this.currentRoom.name;
            this.trackingModel.EventDetails = 'prebuy-' + action + ' ticket';
            this.trackingModel.URLClicked = 'not applicable';
            this.tracking.submitTracking(this.trackingModel);
        }
    }

    multiStakePopupTracking(roomid: any, close: boolean) {
        if (roomid != this.SelectedRoomId)
            this.currentRoom = this.DropDownRoomData.filter((r: Games) => {
                return r.id == roomid;
            })[0];
        this.trackingModel.eventName = close ? 'Event.Tracking' : 'contentView';
        this.trackingModel.CategoryEvent = this.prebuy_route_status ? 'bingo room' : 'bingo';
        this.trackingModel.LabelEvent = this.prebuy_route_status ? 'room interaction' : 'pre-buy';
        this.trackingModel.ActionEvent = close ? 'close' : 'load';
        this.trackingModel.PositionEvent = 'multi stake overlay';
        this.trackingModel.LocationEvent = this.currentRoom.name;
        this.trackingModel.EventDetails = 'multi stake overlay';
        this.tracking.submitTracking(this.trackingModel);
    }
    stringFormat(str: string, objs: any[]) {
        let i = 0;
        for (const arg of objs) {
            const strToReplace = '{' + i++ + '}';
            str = str.replace(strToReplace, arg.toString() || '');
        }
        return str;
    }
}
