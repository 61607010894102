import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { CasinoLobbyService, ConfigProviderService, GamingStoriesService } from '@casinocore/platform/core';
import { Subscription } from 'rxjs';

import { GamingStoriesContainerComponent } from '../gaming-story-container/gaming-stories-container.component';

@Component({
    selector: 'cc-gaming-stories-phase2-wrapper',
    templateUrl: 'gaming-stories-phase2-wrapper.component.html',
    standalone: true,
    imports: [NgIf, GamingStoriesContainerComponent],
})
export class GamingStoriesPhase2WrapperComponent implements OnInit {
    @Input() lobbyType: string;
    @Input() data: any;
    @Input() product: string;
    @Input() productPath: string;

    isLoadingGamingStories: boolean = false;

    private routeCurrentProduct: string;
    private lobbyLoadSubscription: Subscription;

    constructor(
        private gamingStoriesService: GamingStoriesService,
        private configProviderService: ConfigProviderService,
        private casinoLobbyService: CasinoLobbyService,
    ) {}

    ngOnInit(): void {
        this.initialize();
    }

    ngOnDestroy() {
        if (this.lobbyLoadSubscription) {
            this.lobbyLoadSubscription.unsubscribe();
        }
    }

    private initialize(): void {
        this.lobbyLoadSubscription = this.casinoLobbyService.lobbyLoadedObservable.subscribe((response: any) => {
            if (response) {
                this.gamingStoriesService.getGamingStories('', this.product, this.productPath);
                this.getConfig();
            }
        });
    }

    private getConfig(): void {
        const casinoConfig = this.configProviderService.provideGamingStoriesPhase2Config();
        if (casinoConfig && casinoConfig.enabled) {
            this.isLoadingGamingStories = true;
        }
    }
}
