import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { BtnToggleComponent } from './btn-toggle.component';

@NgModule({
    declarations: [BtnToggleComponent],
    exports: [BtnToggleComponent],
    imports: [CommonModule],
})
export class BtnToggleModule {
    public static forRoot(): ModuleWithProviders<BtnToggleModule> {
        return {
            ngModule: BtnToggleModule,
        };
    }
}
