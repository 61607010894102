import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hiddenfilter',
})
export class HiddenFilterPipe implements PipeTransform {
    transform(items: Array<any>): Array<any> {
        if (items != null || items != undefined) {
            const filteredResults = items.filter((item) => !item.isHidden).length;
            if (filteredResults > 0) {
                return items.filter((item) => !item.isHidden);
            } else {
                return [];
            }
        }
        return [];
    }
}
