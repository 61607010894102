import { NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { CasinoLobbyService } from '@casinocore/platform/core';
import { Subscription } from 'rxjs';

import { NewsFeedV2WrapperComponent } from '../news-feed-v2-wrapper/news-feed-v2-wrapper.component';

@Component({
    selector: 'cc-news-feed-v2-widget',
    templateUrl: 'news-feed-v2-widget.component.html',
    standalone: true,
    imports: [NgIf, NewsFeedV2WrapperComponent],
})
export class NewsFeedV2WidgetComponent implements OnInit, OnDestroy {
    @Input() containerPath: string;
    @Input() trackData: any;
    isLobbyLoaded: boolean;
    lobbyLoadSubscription: Subscription;
    constructor(private casinoLobbyService: CasinoLobbyService) {}
    ngOnInit() {
        this.lobbyLoadSubscription = this.casinoLobbyService.lobbyLoadedObservable.subscribe((response: any) => {
            if (response) {
                this.isLobbyLoaded = true;
            }
        });
    }
    ngOnDestroy() {
        if (this.lobbyLoadSubscription) {
            this.lobbyLoadSubscription.unsubscribe();
        }
    }
}
