import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { BingoConfigProviderService, BingoHelperService } from '@frontend/bingo/core';

/*how this pipe can be used:

<div [ngClass]="content.textTranslations.sharedList[race.additionalParameters | prize: true]">hello</div>

*/

@Pipe({ name: 'prize' })
export class PrizePipe implements PipeTransform {
    constructor(
        private currencyPipe: CurrencyPipe,
        private configprovider: BingoConfigProviderService,
        private bingoHelperService: BingoHelperService,
    ) {}

    transform(value: any, givePrizeType: boolean, prizeString?: string): any {
        //for the results page
        if (value.value && !value.totaL_PRIZE && !value.totaL_NUMBER_OF_PRIZE) {
            value.totaL_PRIZE = value.value;
        }
        if (givePrizeType) {
            if (value && value.awardType) return value?.awardType?.toLowerCase() == 'coins' ? '' : value?.awardType?.toLowerCase();
            else if (!value.awardType && prizeString) {
                //added for results and leaderboard from results history as additional params with award type are not available there ( as results contains user specific data and not promo specific and leaderboard does not contains value in additional params)
                const prizes = JSON.parse(prizeString);
                let isHomogeneous = true;
                const prizeType = prizes && prizes[0] && prizes[0].AwardType;
                prizes.every((prize: any) => {
                    if (prize.AwardType !== prizeType) {
                        isHomogeneous = false;
                        return false;
                    }
                    return true;
                });
                if (isHomogeneous) return prizeType && prizeType?.toLowerCase();
                else return 'mixed-awards';
            } else {
                return 'mixed-awards';
            }
        } else {
            const configs = this.configprovider.provideBingoTournamentsClientConfig();
            // as per new changes from CRM, bonus promos can have cash as the bonus award. For that we need to diplay award value as cash with currency. Added toggle to avoid issue in case of different rollout dates between CRM and Casino Portal
            if (!this.bingoHelperService.getConfigBasedOnInvokerProduct(configs.displayBonusAsText)) {
                if (
                    value &&
                    (!value.currency ||
                        !value.currency.length ||
                        !value.awardType ||
                        (value.awardType &&
                            (value.awardType?.toLowerCase() == 'freespins' ||
                                value?.awardType?.toLowerCase() == 'free_spin' ||
                                value?.awardType?.toLowerCase() == 'golden_chip' ||
                                value.awardType?.toLowerCase() == 'coins' ||
                                value?.awardType?.toLowerCase() == 'bingoticket')))
                ) {
                    return value && Number(value.totaL_PRIZE || value.totaL_NUMBER_OF_PRIZE);
                } else {
                    return this.currencyPipe.transform(value && value.totaL_PRIZE, value && value.currency, 'symbol-narrow', '1.2-2');
                }
            } else if (value && value.awardType && value.awardType?.toLowerCase().indexOf('coins') > -1) {
                return value && Number(value.totaL_PRIZE ? value.totaL_PRIZE : 0);
            } else {
                if (value && value.awardType && value.awardType?.toLowerCase().indexOf('cash') > -1) {
                    return this.currencyPipe.transform(value && value.totaL_PRIZE, value && value.currency, 'symbol-narrow', '1.2-2');
                }
                return value && Number(value.totaL_PRIZE || value.totaL_NUMBER_OF_PRIZE);
            }
        }
        //on request, the prizes are now supposed to be picked only from API
    }
}
