import { CurrencyPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';

import {
    CacheManagerService,
    CasinoCoreGamelaunchService,
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    DesignSystemEnablerService,
    GameLaunch,
    OpenLoginDialog,
    Race,
    SitecoreContent,
    SlotRacesLive,
    SlotRacesResponseError,
    SlotRacesService,
    SlotRacesTrackingService,
} from '@casinocore/platform/core';
import { DecoratorClassDirective, SlotRaceCtaComponent } from '@frontend/casino-ui';
import { ClaimsService, MessageQueueService, UserService } from '@frontend/vanilla/core';
import { Subject, Subscription } from 'rxjs';

import { CoinEconomyConfirmPopupComponent } from '../../coin-economy-confirm-popup/coin-economy-confirm-popup.component';
import { NotificationPopUpComponent } from '../../notification-popup/notification-popup.component';
import { PrizePipe } from '../../shared/prize.pipe';
import { TimeFormatPipe } from '../../shared/time-format.pipe';
import { TournamentIconComponent } from '../../tournament-icon/tournament-icon.component';

@Component({
    selector: 'cc-slot-race-card',
    templateUrl: 'card.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        NgStyle,
        TournamentIconComponent,
        MatSliderModule,
        CurrencyPipe,
        PrizePipe,
        TimeFormatPipe,
        SlotRaceCtaComponent,
        DecoratorClassDirective,
    ],
    providers: [CurrencyPipe],
})
export class SlotRaceCardComponent implements OnInit, OnDestroy, OnChanges {
    @Input() race: Race;
    @Input() coinImg: string;
    @Input() selectedOption: string;
    // @Input() sourceInfo:SourceInfo;
    @Input() showRaceInfo: boolean = false;
    @Input() showCardMenu: boolean = false;
    @Input() first: boolean;
    @Input() last: boolean;
    @Input() position: number;
    @Input() showPlaceholderCard: boolean = false;
    @Input() lobbyType: string;
    @Input() category: any;
    @Input() fromPickGameOverlay: boolean = false;
    @Input() firstItem: boolean = false;
    @Input() targetedPlayerCount: number;
    @Input() enableOptin: boolean;
    @Input() raceNotificationStatus: boolean = false;
    @Output() openRaceInfo = new EventEmitter<Race>();
    @Output() closeRaceInfo = new EventEmitter<any>();
    @Output() hideCard = new EventEmitter();
    @Output() gameLaunched = new EventEmitter();
    @Output() openPickGameOverlay = new EventEmitter();
    @Output() closePickGameOverlay = new EventEmitter<any>();
    @Output() openNotificationOverlay = new EventEmitter();
    notificationStatus: boolean;
    timeSpan: number = 0;
    Days: number = 0;
    millisec: number = 86400000;
    durationdays: number;
    sliderMaxValue: number;
    bottombackground: string;
    progressValue: number = 0;
    duration: number;
    gameIconSrc: string;
    backgroundImageSrc: string;
    cardbackgroundimage: string;
    cardbackgroundcolor: string;
    optinExpiryTimeOut: any;
    cardTextColor: string;
    currencyCode: string;
    spinsText: string;
    spinsCount: number = 0;
    forceOptinSubscription: any;
    forceCoinBalanceSubscription: Subscription;
    playerCount: number;
    spinCount: number;
    isprogressBarEnabled: boolean;
    showCoinBalancePopup: boolean = false;
    optInSubscription: Subscription;
    optInWithCoinsSubscription: Subscription;
    timerSubscription: Subscription;
    dataUpdateSubscription: Subscription;
    notificationSubscription: Subscription;
    raceNotifySubscription: Subscription;
    messages: { [item: string]: string } = {};
    slotRacesSharedList: { [item: string]: string } = {};
    contentSubscription: Subscription;
    notificationStatusSubcription: Subscription;
    content: SitecoreContent = {
        raceRuleConfigs: [],
        rules: [],
        rulesBanner: {},
        placeholderCardImage: {},
        coinEconomy: {},
        textTranslations: { sharedList: {}, versionedList: {} },
        tips: [],
        errorMessages: { sharedList: {}, versionedList: {} },
        entryDetails: { sharedList: {}, versionedList: {} },
    };
    showimage: boolean = true;
    activeCategory: string;
    displayMaxBet: boolean = false;
    enableMaxBet: boolean = false;
    isNotify: boolean;
    playNowText: string;
    cardTitle: string;
    containerLocation: string;
    promobackgroundimage: string;
    promobackgroundcolor: string;
    isSubCategory: boolean;
    expandCard: boolean;
    enableBetterVisualization: boolean;
    enableRaceNotification: boolean;
    slotRacesConfig: any;
    currentPoints: any;
    minPoints: any;
    configs: any = {};
    beatBankerPromoIcon: string;
    private destroy$ = new Subject<void>();
    coinObj: any;
    showToasterMessage: boolean;
    isLegacy: boolean = false;

    constructor(
        private slotRacesService: SlotRacesService,
        private gameLaunchService: CasinoCoreGamelaunchService,
        private claimsService: ClaimsService,
        private casinoManager: CasinoManager,
        private messageQueue: MessageQueueService,
        private user: UserService,
        private openLoginDialog: OpenLoginDialog,
        private configProviderService: ConfigProviderService,
        private slotRacesTracking: SlotRacesTrackingService,
        private cacheManager: CacheManagerService,
        private dialog: MatDialog,
        private casinoLobbyService: CasinoLobbyService,
        private dsEnablerService: DesignSystemEnablerService,
    ) {}

    ngOnInit() {
        this.configs = this.configProviderService.provideSlotRacesConfig();
        this.isSubCategory = this.category?.categoryInfo?.subcategoryid ? true : false;
        this.activeCategory = this.category && this.category.categoryInfo && this.category.categoryInfo.categoryid;
        this.currencyCode = (this.race?.additionalParams && this.race?.additionalParams.currency) || this.claimsService.get('currency')!;
        this.currentPoints = parseFloat(this.race?.beatBanker?.currPoints);
        this.minPoints = this.race?.beatBanker?.minPoints;
        this.sliderMaxValue = this.race?.endDate.getTime() - this.race?.startDate.getTime();
        this.playerCount = this.race?.targetedPlayerCount;
        this.spinCount = this.race?.spinProgress && this.race?.spinProgress.total;
        this.cardTitle =
            this.race?.gameVariantList && this.race?.gameVariantList.length > 1
                ? this.race.content?.promotion?.promoName
                    ? this.race.content.promotion.promoName
                    : this.race.gameId
                : this.race?.content?.promotion?.promoName?.startsWith('#')
                  ? this.race.content.promotion.promoName.replace('#', '').trim()
                  : this.race.gameId;
        this.containerLocation = this.showRaceInfo ? 'Page Conatiner' : 'Overlay';
        this.containerLocation = this.containerLocation.concat('|Slot Races');
        this.slotRacesConfig = this.configProviderService.provideSlotRacesConfig();
        this.enableBetterVisualization = this.slotRacesConfig.enableBetterVisualization;
        this.enableRaceNotification = this.slotRacesConfig.enableRaceNotification;
        this.setIconSrc();
        this.forceOptin();
        this.subscribeToDataUpdates();
        this.initializeTimerCountdown();
        this.setOptinExpiryTimeOut();
        this.subscribeToNotifyStatus();
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.content = content;
            this.messages = content && content.textTranslations ? content.textTranslations.versionedList : {};
            this.slotRacesSharedList = content && content.textTranslations ? content.textTranslations.sharedList : {};
            this.playNowText =
                this.messages && (this.race.gameVariantList && this.race.gameVariantList.length > 1 ? this.messages.PickGame : this.messages.PlayNow);
            this.duration = Math.ceil(this.sliderMaxValue / (1000 * 60));
            this.coinObject();
            this.updateCardSitecoreData();
            this.forceCoinRaceBalance();
        });
        this.enableMaxBet = this.configProviderService.provideSlotRacesConfig().enableMaxBet;
        this.displayMaxBet = this.enableMaxBet && this.race.criteriaDtls.max;
        this.isLegacy = !this.dsEnablerService.isEnabled();
        if (!this.coinImg) this.coinImg = this.content?.coinEconomy?.image?.src;
    }

    private subscribeToNotifyStatus() {
        this.notificationStatusSubcription = this.slotRacesService.notifyObservable.subscribe((response: any) => {
            if (
                !(this.race?.ctaList.indexOf('OPTIN') > -1) &&
                response &&
                this.race?.promoId === response?.promoId &&
                this.race?.slotUniqueId === response?.slotUniqueId
            ) {
                this.race.notificationStatus = response.status;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.firstItem) {
            this.expandCard = true;
        }
        if (changes?.targetedPlayerCount?.currentValue) {
            this.playerCount = changes.targetedPlayerCount.currentValue;
        }
        this.coinObject();
        this.subscribeToNotifyStatus();
        if (!this.coinImg) this.coinImg = this.content?.coinEconomy?.image?.src;
    }
    coinObject() {
        this.coinObj = this.slotRacesService.bindCoinImg(this.race, this.coinImg);
        if (this.coinObj?.isDisplayCoin) this.coinObj.coinSrc = this.content?.coinEconomy?.image?.src;
    }

    get slotRaceDuration() {
        if (this.duration < 1440) {
            return `${this.duration} <span>${this.messages.Minutes}</span>`;
        } else {
            const days = Math.floor(this.duration / 1440);
            return `${days} <span>${this.messages.Days}</span>`;
        }
    }

    ngOnDestroy() {
        if (this.optinExpiryTimeOut) {
            clearTimeout(this.optinExpiryTimeOut);
        }
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
        if (this.forceOptinSubscription) {
            this.forceOptinSubscription.unsubscribe();
        }
        this.forceCoinBalanceSubscription?.unsubscribe();
        if (this.optInSubscription) {
            this.optInSubscription.unsubscribe();
        }
        if (this.optInWithCoinsSubscription) {
            this.optInWithCoinsSubscription.unsubscribe();
        }
        if (this.dataUpdateSubscription) {
            this.dataUpdateSubscription.unsubscribe();
        }
        if (this.notificationSubscription) {
            this.notificationSubscription.unsubscribe();
        }
        if (this.notificationStatusSubcription) {
            this.notificationStatusSubcription.unsubscribe();
        }
        this.destroy$.complete();
    }

    setIconSrc(): void {
        this.gameIconSrc = this.casinoLobbyService.getImagePath(this.race?.gameVariantList[0], 'square');
    }

    ctaAction(): void {
        this.messageQueue.clear();

        if (this.race.ctaList.indexOf('OPTIN') > -1) {
            if (this.race?.isOptinWithCoins) {
                this.slotRacesTracking.track(
                    'click',
                    this.race.gameId,
                    this.activeCategory,
                    this.messages?.OptinWithText ? this.messages?.OptinWithText + ' coin cta' : 'Optin With coin cta',
                );
            } else {
                const ctaText = this.getCTATextBasedOnRace(this.race);
                this.slotRacesTracking.track('click', this.race.gameId, this.activeCategory, ctaText + ' cta');
            }

            if (!this.user.isAuthenticated) {
                const race: any = this.race;
                race.lobbyType = this.lobbyType;
                if (race?.isOptinWithCoins) {
                    sessionStorage.setItem('forceCoinBalance', JSON.stringify(race));
                } else {
                    sessionStorage.setItem('ForceOptin', JSON.stringify(race));
                }
                this.openLoginDialog.open({ loginMessageKey: 'OptInPromotion' });
                if (!this.showRaceInfo) {
                    this.closeRaceInfo.emit();
                }
            } else {
                if (this.race?.isOptinWithCoins) {
                    if (!this.showCoinBalancePopup) {
                        this.checkCoinBalace();
                    }
                } else {
                    this.optin();
                }
            }
        } else {
            if (this.race && this.race.gameVariantList && this.race.gameVariantList.length > 1 && !this.fromPickGameOverlay) {
                this.emitPickGameOverlay('cta');
            } else {
                this.launchGame();
            }
        }
    }

    getCTATextBasedOnRace(race: any) {
        if (race.subType && race.subType.toLowerCase() === 'free_play_tech' && race.optinLimit !== 0 && this.playerCount === race.optinLimit) {
            return this.messages.OptinLimitReached;
        }
        if (race.subType && (race.subType.toLowerCase() === 'free_to_play' || race.subType.toLowerCase() === 'free_play_tech')) {
            return this.messages.Optin4Free;
        } else {
            return this.messages.Optin;
        }
    }
    closePopup(): void {
        this.showToasterMessage = false;
    }

    checkCoinBalace() {
        this.showCoinBalancePopup = true;
        if (this.race.isOptinWithCoins) {
            this.slotRacesService.GetCoinBalnce().subscribe(
                (coinBalRes: any) => {
                    this.showConfirmToaster(coinBalRes);
                },
                () => {
                    this.showCoinBalancePopup = false;
                },
            );
        }
    }

    showConfirmToaster(coinBalRes: any) {
        if ((this?.race?.coinsForOptin ?? 0) <= coinBalRes?.balance) {
            this.openConfirmToasterDialog(true);
        } else {
            this.showCoinBalancePopup = false;
            this.openConfirmToasterDialog(false);
        }
    }

    openConfirmToasterDialog(showConfirmPopup: boolean): void {
        const requiredModalData = {
            messages: this.messages,
            showConfirmPopup: showConfirmPopup,
        };
        if (showConfirmPopup) {
            this.slotRacesTracking.track('load', 'optin with coin', this.activeCategory + ',' + this.race.gameId, this.messages?.Purchase);
        } else {
            this.slotRacesTracking.track('load', 'optin with coin', this.activeCategory + ',' + this.race.gameId, 'oops! toaster popup');
        }

        const dialogRef = this.dialog.open(CoinEconomyConfirmPopupComponent, {
            data: requiredModalData,
            panelClass: 'casino-sr-confirm-toaster',
        });

        dialogRef.afterClosed().subscribe((event: any) => {
            this.showCoinBalancePopup = false;
            this.confirmPopupTracking(event?.type);
            if (event?.type == 'confirm') {
                this.optin(true);
            } else {
                this.slotRacesService.forceCoinBalancepublisher.next({});
            }
        });
    }

    confirmPopupTracking(ctaType: string) {
        if (ctaType == 'confirm') {
            this.slotRacesTracking.track('click', 'optin with coin', this.activeCategory + ',' + this.race.gameId, this.messages?.Confrim + ' cta');
        } else if (ctaType == 'close') {
            this.slotRacesTracking.track('close', 'optin with coin', this.activeCategory + ',' + this.race.gameId, 'confirmation popup');
        } else if (ctaType == 'cancel') {
            this.slotRacesTracking.track('click', 'optin with coin', this.activeCategory + ',' + this.race.gameId, this.messages?.Cancel + ' cta');
        } else if (ctaType == 'toasterClose') {
            this.slotRacesTracking.track('close', 'optin with coin', this.activeCategory + ',' + this.race.gameId, 'oops! toaster popup');
        }
    }

    launchGame(): void {
        const colorCode = this.cardbackgroundimage || (!this.cardbackgroundcolor && !this.cardbackgroundimage) ? 'Image' : this.cardbackgroundcolor;
        const containerDescription: string = 'Highlighted|' + colorCode;
        const game = this.cacheManager.getGameToStickersMap();
        const gameSticker = game[this.race.gameVariantList && this.race.gameVariantList[0]];
        let isNewGame = false;
        if (gameSticker && gameSticker.indexOf('New') > -1) {
            isNewGame = true;
        }
        this.gameLaunched.emit();
        const gameData = {
            gameId: this.race.gameVariantList && this.race.gameVariantList[0],
            gameVariantName: this.race.gameId,
            gameContainerDescription: containerDescription,
            gameContainerLocation: this.containerLocation,
            isSignPostedGame: isNewGame,
            lobbyType: this.lobbyType,
        };
        const launchModel = new GameLaunch();
        launchModel.game = gameData;
        launchModel.isTouch = this.casinoManager.isTouch();
        launchModel.gamePosition = this.position;
        launchModel.categoryType = this.activeCategory;
        launchModel.gameLaunchedFrom = 'slotraces';
        launchModel.gameSection = 'slotraces';
        launchModel.returnUrl = window.location.href;
        launchModel.clickTimeStamp = this.gameLaunchService.currentTimeStamp();
        launchModel.promoId = this.race?.promoId;
        launchModel.slotUniqueId = this.race?.slotUniqueId;
        this.gameLaunchService.launchGame(launchModel);
    }

    forceOptin(): void {
        this.forceOptinSubscription = this.slotRacesService.forceRace.subscribe((response: { race: Race }) => {
            if (response.race && response.race.promoId == this.race.promoId && response.race.slotUniqueId == this.race.slotUniqueId) {
                const index = this.race.ctaList.findIndex((i) => i === 'OPTIN');
                if (!this.race.isLive && this.enableRaceNotification && !this.race.isNotify) {
                    this.openNotificationOverlay.emit(this.race);
                }
                if (index > -1) {
                    this.race.ctaList.splice(index, 1);
                }
                this.slotRacesService.forcRacepublisher.next({});
            }
        });
    }

    forceCoinRaceBalance(): void {
        this.forceCoinBalanceSubscription = this.slotRacesService.forceCoinBalanceObs.subscribe((coinBalRes: any) => {
            if (
                coinBalRes &&
                coinBalRes.race &&
                coinBalRes.race.promoId == this.race.promoId &&
                coinBalRes.race.slotUniqueId == this.race.slotUniqueId &&
                coinBalRes?.coinBal?.balance
            ) {
                this.showConfirmToaster(coinBalRes.coinBal);
            }
        });
    }
    addOrremoveCollapseClass() {
        if (this.expandCard) {
            this.slotRacesTracking.track(
                'Collapse (-) - CTA Clicked',
                this.race.gameId,
                this.activeCategory,
                'User clicked on Collapse(-) to see the slot race details',
            );
        } else {
            this.slotRacesTracking.track(
                'Expand (+) - CTA Clicked',
                this.race.gameId,
                this.activeCategory,
                'User clicked on Expand(+) to see the slot race details',
            );
        }
        this.expandCard = !this.expandCard;
    }

    optin(coinBalanceOptin: boolean = false): void {
        this.optInSubscription = this.slotRacesService
            .updateOptinStatus(this.race.promoId, this.race.slotUniqueId, this.race.coinsForOptin)
            .subscribe(
                () => {
                    const index = this.race.ctaList.findIndex((i) => i === 'OPTIN');
                    this.race.ctaList.splice(index, 1);
                    if (!this.race.isLive && this.enableRaceNotification && !this.race.isNotify) {
                        this.openNotificationOverlay.emit(this.race);
                    }
                    if (coinBalanceOptin) {
                        this.slotRacesService.forceCoinBalancepublisher.next({});
                    }
                },
                (error: SlotRacesResponseError) => {
                    if (coinBalanceOptin) {
                        this.slotRacesService.forceCoinBalancepublisher.next({});
                    }
                    this.slotRacesService.addErrorMessage(error.errorCode);
                    this.closePickGameOverlay.emit({ errorCode: error.errorCode });
                    this.closeRaceInfo.emit({ errorCode: error.errorCode });
                },
            );
    }

    // optin expiry
    setOptinExpiryTimeOut(): void {
        if (this.race.ctaList.indexOf('OPTIN_EXPIRED') < 0) {
            const expiryTime: number = this.race && this.race.optinExpiryDate && this.race.optinExpiryDate.getTime();
            if (expiryTime && expiryTime < this.race.endDate.getTime() && expiryTime > Date.now()) {
                this.optinExpiryTimeOut = setTimeout(() => {
                    const optinIndex = this.race.ctaList.indexOf('OPTIN');
                    if (optinIndex > -1) {
                        this.race.ctaList.splice(optinIndex, 1, 'OPTIN_EXPIRED');
                    }
                }, expiryTime - Date.now());
            } else if (expiryTime && expiryTime < Date.now()) {
                const index = this.race.ctaList.indexOf('OPTIN');
                if (index > -1) {
                    this.race.ctaList.splice(index, 1, 'OPTIN_EXPIRED');
                }
            }
        } else {
            if (this.optinExpiryTimeOut) {
                clearTimeout(this.optinExpiryTimeOut);
            }
        }
    }

    initializeTimerCountdown(): void {
        if (this.race.isLive) {
            this.timeSpan = this.race.endDate.getTime() - Date.now();
            if (this.timeSpan == 0) {
                this.race.isLive = false;
                this.slotRacesService.updateRacesArrays();
            }
        } else {
            this.timeSpan = this.race.startDate.getTime() - Date.now();
        }
        this.progressValue = Date.now() - this.race.startDate.getTime();
        this.timerSubscription = this.slotRacesService.globalTimerObservable.subscribe((response: any) => {
            if (this.timeSpan > 1000) {
                if (response && response.timerInterval) {
                    if (this.race.isLive) {
                        this.timeSpan = this.race.endDate.getTime() - Date.now();
                        if (this.timeSpan > this.millisec) {
                            this.Days = this.timeSpan / (1000 * 60 * 60 * 24);
                            this.Days = Math.floor(this.Days);
                        }
                    } else {
                        this.timeSpan = this.race.startDate.getTime() - Date.now();
                    }
                    this.progressValue = Date.now() - this.race.startDate.getTime();
                }
            } else {
                this.race.isLive = false;
                if (this.timerSubscription) {
                    this.timerSubscription.unsubscribe();
                }
                this.slotRacesService.updateRacesArrays();
            }
        });
    }

    updateCardSitecoreData(): void {
        this.bottombackground = this.messages.CardBottomColor ? this.messages.CardBottomColor : '';
        this.promobackgroundimage = this.race?.content?.promotion?.promoBackgroundImage?.src ?? '';
        this.promobackgroundcolor = this.race?.content?.promotion?.promoBackgroundColor ?? '';
        this.cardbackgroundimage = this.messages?.CardBackgroundImage ?? '';
        this.cardbackgroundcolor = this.messages?.CardBackgroundColor ?? '';
        this.cardTextColor = this.messages.CardTextColor ? this.messages.CardTextColor : '';
        if (this.promobackgroundimage) {
            this.showimage = true;
            this.backgroundImageSrc = this.promobackgroundimage;
        } else if (this.promobackgroundcolor) {
            this.showimage = false;
        } else if (this.cardbackgroundimage) {
            this.showimage = true;
            this.backgroundImageSrc = this.cardbackgroundimage;
        } else if (this.cardbackgroundcolor) {
            this.showimage = false;
        } else {
            this.showimage = true;
            this.backgroundImageSrc = this.casinoLobbyService.getImagePath(this.race?.gameVariantList[0], 'landscape');
        }
    }

    emitRaceInfo() {
        this.slotRacesTracking.track(
            'Quick Info Overlay (I) - Clicked',
            this.race.gameId,
            this.activeCategory,
            'User Clicked on (i) to open quick info overlay',
        );
        this.openRaceInfo.emit(this.race);
        setTimeout(() => {
            this.cardOverlayTopAllignment();
        }, 0);
    }

    hideRaceCard(): void {
        this.hideCard.emit();
    }

    subscribeToDataUpdates(): void {
        this.dataUpdateSubscription = this.slotRacesService.liveRacesData.subscribe((liveRaces: SlotRacesLive) => {
            if (liveRaces && liveRaces.liveNow && liveRaces.liveNow.length) {
                const updatedRace: Race = liveRaces.liveNow.find(
                    (r) => r.promoId === this.race.promoId && r.slotUniqueId === this.race.slotUniqueId,
                )!;
                if (updatedRace) {
                    this.isprogressBarEnabled = this.race.isLive && this.user.isAuthenticated && this.race.ctaList.indexOf('OPTIN_EXPIRED') < 0;
                    this.playerCount = updatedRace.targetedPlayerCount;
                    this.currentPoints = parseFloat(updatedRace?.beatBanker?.currPoints);
                    this.spinCount =
                        updatedRace.spinProgress && updatedRace.spinProgress.total && updatedRace.spinProgress.current > 0
                            ? updatedRace.spinProgress.total - updatedRace.spinProgress.current
                            : updatedRace.spinProgress.total;
                    this.race.ctaList = updatedRace.ctaList;
                }
            }
        });
    }

    cardOverlayTopAllignment() {
        const featuresConfig = this.configProviderService.provideFeaturesConfig();
        if (featuresConfig.enableOverlayMobileSearchTopPosition) {
            if (document.querySelector('.content-messages-header-top .content-message-container')) {
                const contentMessageElement: HTMLElement = document.querySelector('.content-messages-header-top .content-message-container')!;
                const srtc_overlay: HTMLElement = document.querySelector('.cdk-overlay-container')!;
                srtc_overlay.style.top = contentMessageElement.clientHeight + 'px';
            }
        }
    }
    onNotificationClick(status: boolean) {
        this.notificationSubscription = this.slotRacesService.updateNotificationStatus(this.race.promoId, this.race.slotUniqueId, status).subscribe(
            (response: any) => {
                if (response) {
                    this.race.notificationStatus = status;
                    this.slotRacesService.setNotifyStatus(this.race.promoId, this.race.slotUniqueId, status);
                    this.openDialog(this.race.notificationStatus);
                }
            },
            (error: SlotRacesResponseError) => {
                this.slotRacesService.addErrorMessage(error.errorCode);
            },
        );
    }
    openDialog(dialogResult: any): void {
        const requiredModalData = {
            messages: this.messages,
            status: dialogResult,
        };
        const dialogRef = this.dialog.open(NotificationPopUpComponent, {
            width: '790px',
            data: requiredModalData,
            panelClass: 'casino-sr-notification-toaster',
        });

        dialogRef.afterClosed().subscribe(() => {
            // console.log('The dialog was closed');
        });
    }

    emitPickGameOverlay(actionLocation: string = 'promo icon') {
        this.slotRacesTracking.track(
            `Pick Game - ${actionLocation} clicked`,
            this.race && this.race.content && this.race.content.promotion && this.race.content.promotion.promoName,
            this.activeCategory,
            `User opened the pick-game overlay from the ${actionLocation} for the Slots Race`,
        );
        this.openPickGameOverlay.emit(this.race);
    }

    prepareCardCtaText(): [string, number | undefined] {
        // race.ctaList.length === 0
        //                     ? playNowText
        //                     : race?.isOptinWithCoins
        //                       ? messages.OptinWithText
        //                       : race.subType &&
        //                           race.subType.toLowerCase() === 'free_play_tech' &&
        //                           race.optinLimit !== 0 &&
        //                           playerCount === race.optinLimit
        //                         ? messages.OptinLimitReached
        //                         : race.subType && (race.subType.toLowerCase() === 'free_to_play' || race.subType.toLowerCase() === 'free_play_tech')
        //                           ? messages.Optin4Free
        //                           : messages.Optin
        if (this.race?.isOptinWithCoins && (this.race.ctaList.indexOf('OPTIN') > -1 || this.race.ctaList.indexOf('OPTIN_EXPIRED') > -1)) {
            return ['', this.race?.coinsForOptin];
        } else {
            // return stringed_text;
            this.coinImg = this.content?.coinEconomy?.image?.src;
            return [
                this.race.ctaList.length === 0
                    ? this.playNowText
                    : this.race?.isOptinWithCoins
                      ? this.messages.OptinWithText
                      : this.race.subType &&
                          this.race.subType.toLowerCase() === 'free_play_tech' &&
                          this.race.optinLimit !== 0 &&
                          this.playerCount === this.race.optinLimit
                        ? this.messages.OptinLimitReached
                        : this.race.subType &&
                            (this.race.subType.toLowerCase() === 'free_to_play' || this.race.subType.toLowerCase() === 'free_play_tech')
                          ? this.messages.Optin4Free // need to check for this at runtim, and adjust type acordingly
                          : this.messages.Optin,
                undefined,
            ];
        }
    }
}
