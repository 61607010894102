import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { BingoConfigProviderService, FeaturesClientConfig } from '@frontend/bingo/core';

@Component({
    selector: 'bg-bonus-supression-popup',
    templateUrl: './bonus-supression-popup.component.html',
})
export class BonusSupressionPopupComponent implements OnInit {
    globalClientConfig: any;
    title: string;
    bonusSupressionMessage: string;
    closeBtnText: string;
    enablGalaIcons: boolean = false;
    featuresConfig: FeaturesClientConfig;
    constructor(
        public dialogRef: MatDialogRef<BonusSupressionPopupComponent>,
        private configProviderService: BingoConfigProviderService,
    ) {
        this.featuresConfig = this.configProviderService.provideFeaturesConfig();
    }

    ngOnInit(): void {
        this.enablGalaIcons = this.featuresConfig.enableNewPrebuySliderIcons;
        if (localStorage.getItem('config')) {
            const clientConfig = JSON.parse(localStorage.getItem('config') || '');
            if (!this.globalClientConfig) this.globalClientConfig = clientConfig.global.errors;
        }
        this.title = this.globalClientConfig['bonusSupressionTitle'];
        this.bonusSupressionMessage = this.globalClientConfig['bonusSupressionMessage'];
        this.closeBtnText = this.globalClientConfig['bonusSupressionCloseText'];
    }

    close() {
        this.dialogRef.close();
    }
}
