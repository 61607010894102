import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { ITournamentIcon, RoomsInfoService } from '@frontend/bingo/core';
import { SessionStoreService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'bgl-slot-races-icon',
    templateUrl: 'bingo-tournament-icon.component.html',
})
export class BingoTournamentIconComponent implements OnInit, OnDestroy {
    @Input() isDraggable: boolean = true;
    @Input() promoIcon: any;
    @Input() gameVariants: string[];
    @Input() position: number;
    @Input() source: string;
    @Input() isClickable: boolean;
    @Input() isFreeTourney: boolean = false;
    @Input() isLive: boolean = false;
    @Input() spinProgress: any = {};
    @Input() cta: string[];

    @Output() openPickGameOverlay = new EventEmitter();
    @Output() launchSelectedGame = new EventEmitter<any>();

    isMultiIcon: boolean = false;
    subIconCount: number = 1;
    displayJackpotRibbon: boolean = true;
    overlayCount: number = 0;
    overlayApplicable: boolean = false;
    gameVariantIconList: ITournamentIcon[] = [];
    singleIconSrc: string;
    gameConfig: any;
    jackpotDataSubscription: Subscription;
    jackpotTickingSubscription: Subscription;
    jackpotValue: string;
    jackpotConfig: any;
    showJackpots: boolean;
    enableJPTicking: boolean;
    showJackpotRibbon: boolean;
    jpBgColor: any;
    jpTextCOlor: any;
    roomData: any;

    constructor(
        private roomInfo: RoomsInfoService,
        private session: SessionStoreService,
    ) {}

    ngOnInit() {
        this.roomData = JSON.parse(localStorage.getItem('config') || '').rooms;
        const scheduleFeedResponse: any = this.session.get('ScheduleFeed');
        if (scheduleFeedResponse && scheduleFeedResponse.result && scheduleFeedResponse.result.games_list) {
            //no promo icon to be shown for promos that have single games
            this.promoIcon = this.gameVariants && this.gameVariants.length ? this.promoIcon : null;
            if (this.promoIcon && this.gameVariants && this.promoIcon.src && this.promoIcon.alt !== 'DoNotDisplay') {
                this.singleIconSrc = this.promoIcon?.src;
            } else {
                if (this.gameVariants && this.gameVariants.length) {
                    if (this.gameVariants.length > 1) {
                        this.isMultiIcon = true;
                        this.initIconAttributes();
                    } else {
                        if (this.roomData) {
                            const roomImage = this.roomInfo.getImage(this.gameVariants[0], this.roomData);
                            this.singleIconSrc = roomImage;
                        }
                    }
                } else {
                    if (this.roomData) {
                        const roomImage = this.roomInfo.getImage(0, this.roomData);
                        this.singleIconSrc = roomImage;
                    }
                }
            }
        }
    }

    initIconAttributes() {
        if (this.gameVariants.length > 4) {
            this.subIconCount = 4;
            this.overlayCount = this.gameVariants.length - 3;
            this.overlayApplicable = true;
        } else {
            this.subIconCount = this.gameVariants.length;
        }
        this.generateIconList();
    }

    generateIconList() {
        for (let i = 0; i < this.subIconCount; i++) {
            const colspan: number = this.subIconCount === 2 || (this.subIconCount === 3 && i + 1 === 1) ? 2 : 1;
            let icon = this.promoIcon?.src;
            if (this.roomData) {
                const roomImage = this.roomInfo.getImage(this.gameVariants[i], this.roomData);
                icon = roomImage ? roomImage : icon;
            }
            this.gameVariantIconList.push({
                src: icon,
                colspan: colspan,
            });
        }
        if (this.gameVariantIconList.length === 1) {
            this.singleIconSrc = this.gameVariantIconList[0].src;
            this.gameVariantIconList = null || []; //never use 'undefined' as assignment value
        }
    }

    ctaClick() {
        if (this.isClickable) {
            if (this.isMultiIcon || this.promoIcon) {
                this.openPickGameOverlay.emit();
            } else {
                this.launchSelectedGame.emit({ game: this.gameVariants && this.gameVariants[0], position: this.position });
            }
        }
    }

    ngOnDestroy() {
        if (this.jackpotDataSubscription) {
            this.jackpotDataSubscription.unsubscribe();
        }
        if (this.jackpotTickingSubscription) {
            this.jackpotTickingSubscription.unsubscribe();
        }
    }

    attachClickEvent() {
        if (
            this.isFreeTourney &&
            (!this.isLive ||
                (this.isLive &&
                    ((this.cta && this.cta.length) || (this.cta && !this.cta.length && this.spinProgress.current == this.spinProgress.total))))
        )
            return false;
        else return true;
    }
}
