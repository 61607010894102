import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { BingoConfigProviderService, FeaturesClientConfig } from '@frontend/bingo/core';
import { BehaviorSubject, Observable, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

const KEY_CODE = {
    enter: 13,
    arrowUp: 38,
    arrowDown: 40,
    esc: 27,
};

const CSS_CLASS_NAMES = {
    highLight: 'dd-highlight-item',
};
@Component({
    selector: 'bg-select-dropdown',
    templateUrl: './select-dropdown.component.html',
    //styleUrls: ['./select-dropdown.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: SelectDropdownComponent,
            multi: true,
        },
    ],
})
export class SelectDropdownComponent implements ControlValueAccessor, OnInit {
    @Output()
    onSelectChange: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    DateFormat: string = '';
    @Input() styleClassVar: string;
    IsDateFormat: boolean = false;
    @ViewChild('filterInput') filterInput: ElementRef;
    @ViewChild('displayLabel') displayLabel: ElementRef;
    @ViewChildren('listItems') listItems: QueryList<ElementRef>;

    _items: any[] = [];

    _list = new BehaviorSubject<any[]>([]);
    @Input() placeholder = '';
    showIconImage: boolean = false;
    _value: string;
    _display: string = '';
    _image: string;
    _icon: any;
    _iconimage: string;
    date = new Date();

    isListHide = true;

    searchText = '';
    keyDowns: Observable<KeyboardEvent>;

    pressEnterKey: Observable<KeyboardEvent>;
    featureConfig: FeaturesClientConfig;

    onChange: any = () => {};
    onTouched: any = () => {};
    @HostListener('document:click', ['$event'])
    onClick(ev: MouseEvent) {
        const clickInside = this.elemRef.nativeElement.contains(ev.target);
        if (!clickInside) {
            this.isListHide = true;
        }
    }
    @Input()
    set list(list: any) {
        this._list.next(list);
    }

    set items(list) {
        this._items = list;
    }
    get items(): Array<{ id: number; display: string; icon: any; image: any; iconimage: any }> {
        return this._items;
    }
    get value() {
        return this._value;
    }

    set value(val) {
        this._value = val;
    }

    get display() {
        return this._display;
    }
    set display(value) {
        this._display = value;
    }
    get image() {
        return this._image;
    }

    set image(value) {
        this._image = value;
    }

    get icon() {
        return this._icon;
    }

    set icon(value) {
        this._icon = value;
    }

    get iconimage() {
        return this._iconimage;
    }

    set iconimage(value) {
        this._iconimage = value;
    }

    get format() {
        return this.DateFormat;
    }

    constructor(
        private elemRef: ElementRef,
        private configProviderService: BingoConfigProviderService,
    ) {
        this.featureConfig = this.configProviderService.provideFeaturesConfig();
        this.keyDowns = fromEvent(this.elemRef.nativeElement, 'keydown');
        this.pressEnterKey = this.keyDowns.pipe(filter((e: KeyboardEvent) => e.key === 'Enter'));
    }

    ngOnInit() {
        this.showIconImage = this.featureConfig.showIconImage;
        this.IsDateFormat = this.DateFormat != '' ? true : false;
        this._list.subscribe((list: any) => {
            this.items = list;
            this.setItem(this.findItem(this.value));
        });

        this.pressEnterKey.pipe(filter(() => !this.isListHide)).subscribe(() => {
            const hightLightItem = this.listItems.find((elem: any) => elem.nativeElement.classList.contains(CSS_CLASS_NAMES.highLight));
            if (hightLightItem) {
                const item = JSON.parse(hightLightItem.nativeElement.getAttribute('data-dd-value')!);
                this.setItem(item);
                this.onChange(item.id);
            }
        });

        this.pressEnterKey.subscribe(() => {
            this.toggle();
        });

        this.keyDowns.pipe(filter((e: any) => e.keyCode === KEY_CODE.esc)).subscribe(() => {
            this.isListHide = true;
            this.focus();
        });
        this.keyDowns
            .pipe(filter((e: any) => (e.keyCode === KEY_CODE.arrowDown || e.keyCode === KEY_CODE.arrowUp) && !this.isListHide))
            .subscribe((e: any) => {
                this.moveUpAndDown(e.keyCode);
            });
    }

    scrollToView(elem?: HTMLElement) {
        if (elem) {
            setTimeout(() => elem.scrollIntoView(false), 0);
        } else {
            const selectedItem = this.listItems.find(
                (item: any) => JSON.parse(item.nativeElement.getAttribute('data-dd-value')!)['id'] === this.value,
            );
            if (selectedItem) {
                setTimeout(() => selectedItem.nativeElement.scrollIntoView(false), 0);
            }
        }
    }

    toggle() {
        this.isListHide = !this.isListHide;
        this.searchText = '';
        if (!this.isListHide) {
            //setTimeout(() => this.filterInput.nativeElement.focus(), 0);
            this.listItems.forEach((item: any) => {
                if (JSON.parse(item.nativeElement.getAttribute('data-dd-value')!)['id'] === this.value) {
                    this.addHightLightClass(item.nativeElement);
                    this.scrollToView(item.nativeElement);
                } else {
                    this.removeHightLightClass(item.nativeElement);
                }
            });
        }
    }

    focus() {
        setTimeout(() => this.displayLabel.nativeElement.focus(), 0);
    }

    onItemSelect(item: any) {
        this.setItem(item);
        this.toggle();
        if (item !== undefined) {
            this.onChange(item.id);
            this.onSelectChange.emit(+item.id);
        } else {
            this.onChange('');
            this.onSelectChange.emit(null);
        }
        this.focus();
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    findItem(value: any) {
        return this.items.find((item) => +item.id === +value);
    }

    writeValue(value: any) {
        const item = this.findItem(value);
        this.value = value;
        this.display = item ? item.display : '';
        this.icon = item ? item.icon : '';
        this.image = item ? item.image : '';
        this.iconimage = item && this.showIconImage ? item.iconimage : '';
    }

    setItem(item: any) {
        if (item) {
            if (item.id) {
                this.value = item.id;
            }
            if (item.display) {
                this.display = item.display;
            }
            if (item.image) {
                this.image = item.image;
            }
            if (item.icon) {
                this.icon = item.icon;
            }
            if (item.iconimage && this.showIconImage) {
                this.iconimage = item.iconimage;
            }
        } else {
            this.value = '';
            this.display = this.placeholder;
        }
    }

    onKeyPress(e: KeyboardEvent) {
        if (e.key === 'Enter') {
            this.focus();
            return false;
        }
        return true;
    }

    addHightLightClass(elem: HTMLElement) {
        elem.classList.add(CSS_CLASS_NAMES.highLight);
    }

    removeHightLightClass(elem: HTMLElement) {
        elem.classList.remove(CSS_CLASS_NAMES.highLight);
    }

    moveUpAndDown(key: number) {
        const selectedItem = this.listItems.find((li: any) => li.nativeElement.classList.contains(CSS_CLASS_NAMES.highLight));
        if (selectedItem) {
            let hightLightedItem: HTMLElement = new HTMLElement();
            if (key === KEY_CODE.arrowUp) {
                //check for first element
                if (selectedItem !== this.listItems.first) {
                    hightLightedItem = selectedItem.nativeElement.previousSibling;
                }
            } else if (key === KEY_CODE.arrowDown) {
                //check for last element
                if (selectedItem !== this.listItems.last) {
                    hightLightedItem = selectedItem.nativeElement.nextSibling;
                }
            }
            if (hightLightedItem) {
                this.clearHlightClass();
                this.removeHightLightClass(selectedItem.nativeElement);
                this.addHightLightClass(hightLightedItem);
                this.scrollToView(hightLightedItem);
            }
        } else {
            let highLightedItem: ElementRef = new ElementRef(null);
            if (key === KEY_CODE.arrowUp) {
                highLightedItem = this.listItems.last;
            } else if (key === KEY_CODE.arrowDown) {
                highLightedItem = this.listItems.first;
            }
            if (highLightedItem) {
                this.addHightLightClass(highLightedItem.nativeElement);
                this.scrollToView(highLightedItem.nativeElement);
            }
        }
    }

    isSelected(item: { id: number; display: string; icon: any; image: any; iconimage: any }) {
        if (item) return +item.id === +this.value;
        else return false;
    }

    stringify(item: any) {
        return JSON.stringify(item);
    }

    onHover(event: MouseEvent) {
        this.clearHlightClass();
        const target = event.target as HTMLElement;
        if (event.type === 'mouseover') {
            target.classList.add(CSS_CLASS_NAMES.highLight);
        } else {
            target.classList.remove(CSS_CLASS_NAMES.highLight);
        }
    }

    clearHlightClass() {
        this.listItems.forEach((item: any) => {
            this.removeHightLightClass(item.nativeElement);
        });
    }
}
