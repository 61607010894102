import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

import { SharedModule } from '../../shared/shared.module';
import { BingoSlotRacesPointsComponent } from './bingo-points.component';

@NgModule({
    imports: [CommonModule, MatExpansionModule, MatIconModule, SharedModule],
    declarations: [BingoSlotRacesPointsComponent],
    exports: [BingoSlotRacesPointsComponent],
})
export class BingoSlotRacesPointsModule {
    public static forRoot(): ModuleWithProviders<BingoSlotRacesPointsModule> {
        return {
            ngModule: BingoSlotRacesPointsModule,
        };
    }
}
