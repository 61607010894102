import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import {
    BingoConfigProviderService,
    BingoHelperService,
    BingoTournamentsService,
    LEADERBOARD_COLUMNS_DISPLAYED,
    Leaderboard,
    Race,
    SitecoreContent,
    SlotRacesLive,
    SlotRacesResponseError,
} from '@frontend/bingo/core';
import { ClaimsService, TimerService, UserService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable, Subscription, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

import { IBingoLeaderboardItem, IBingoLeaderboardSitecoreConfigurations } from './bingo-leaderboard-sitecore-configurations';

@Component({
    selector: 'bgl-slot-races-leaderboard',
    templateUrl: 'bingo-leaderboard.component.html',
})
export class BingoSlotRacesLeaderboardComponent implements OnInit, OnChanges, OnDestroy {
    @Input() showTopThree: boolean = false;
    @Input() coinImg: string;
    @Input() race: Race;
    @Input() results: any;
    readonly displayedColumns = LEADERBOARD_COLUMNS_DISPLAYED;
    stats: any = [];
    playerStats: any;
    prizesString: string;
    currencyCode: string;
    visibleStats: any;
    leaderboardPollingInterval: number;
    leaderboardPollingStarted: boolean;
    pollingInterval: any;
    slotRacesConfig: any;
    userIsVisible: boolean = true;
    userEnrolled: boolean;
    userName: string;
    height: any;
    dataUpdateSubscription: Subscription;
    enableResultsHistoryPage: boolean = false;
    currencysymbol: string;
    private leaderboardVisiblePublisher = new BehaviorSubject<any>(null);
    leaderboardVisible: Observable<void> = this.leaderboardVisiblePublisher.asObservable();
    leaderboardSubscription: Subscription;
    leaderboardConfigs: IBingoLeaderboardSitecoreConfigurations = {
        leaderboardHeaderBgColor: '',
        leaderboardHeaderTextColor: '',
        leaderboardContentTextColor: '',
        leaderboardAwardTypeBgColor: '',
        leaderboardAwardTypeTextColor: '',
        leaderboardPlayerPositionBgColor: '',
        leaderboardPlayerPositionTextColor: '',
        leaderboardPlayerPositionAwardTypeBgColor: '',
        leaderboardPlayerPositionAwardTypeTextColor: '',
        playerTitleText: '',
        rankTitleText: '',
        prizeTitleText: '',
        pointsTitleText: '',
        coinEconomy: '',
        leaderboardPlayerPositionBgColorNotNull: false,
        leaderboardPlayerPositionTextColorNotNull: false,
        leaderboardPlayerPositionAwardTypeBgColorNotNull: false,
        leaderboardPlayerPositionAwardTypeTextColorNotNull: false,
        sharedList: {},
        versionedList: {},
    };
    private scrollSubscription: Subscription;
    private leaderboardVisibleSubscription: Subscription;
    coinObj: any;

    constructor(
        private timerService: TimerService,
        private userService: UserService,
        private slotRacesService: BingoTournamentsService,
        private claimsService: ClaimsService,
        private currencyPipe: CurrencyPipe,
        private configProviderService: BingoConfigProviderService,
        private bingoHelperService: BingoHelperService,
    ) {
        this.userName = this.userService.screenname ? this.userService.screenname : this.userService.id!;
    }

    ngOnInit() {
        this.slotRacesConfig = this.configProviderService.provideBingoTournamentsClientConfig();
        this.leaderboardPollingInterval = this.bingoHelperService.getConfigBasedOnInvokerProduct(this.slotRacesConfig.leaderboardPollingInterval);
        this.enableResultsHistoryPage = this.bingoHelperService.getConfigBasedOnInvokerProduct(this.slotRacesConfig.enableResultsHistoryPage);
        this.subscribeToDataUpdates();
        if (!this.results) {
            this.currencyCode = (this.race.additionalParameters && this.race.additionalParameters.currency) || this.claimsService.get('currency')!;
            this.userEnrolled = !(this.race.ctaCollection.indexOf('OPTIN') > -1);
        } else if (this.enableResultsHistoryPage && this.results) {
            const configMap = this.results?.configMaps ? this.results?.configMaps[0] : this.results?.configMap;
            this.currencyCode = this.results && configMap && configMap.currency ? configMap.currency : this.claimsService.get('currency');
            this.userEnrolled = true;
        }

        if (this.showTopThree) {
            if (this.race.rankDetails && this.race.rankDetails.length > 0) {
                const leaderboard: Leaderboard = { rankDetails: this.race.rankDetails, content: this.race.content };
                this.setStats(leaderboard);
            }
        } else {
            this.pollLeaderBoard();
        }

        this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            if (content) {
                this.getSitecoreConfigurations(content);
            }
        });

        this.leaderboardVisibleSubscription = this.leaderboardVisible.subscribe(() => {
            this.onLeaderboardInit();
        });
    }

    ngOnChanges() {
        if (this.showTopThree) {
            if (this.race.rankDetails && this.race.rankDetails.length > 0) {
                const leaderboard: Leaderboard = { rankDetails: this.race.rankDetails, content: this.race.content };
                this.setStats(leaderboard);
            }
        }
    }

    pollLeaderBoard() {
        if (!this.results) {
            if (!this.leaderboardPollingStarted) {
                this.leaderboardPollingStarted = true;
                this.getLeaderboard(this.race.promoId, this.race.slotUniqueId, false);
                this.pollingInterval = this.timerService.setIntervalOutsideAngularZone(() => {
                    this.getLeaderboard(this.race.promoId, this.race.slotUniqueId, false);
                }, this.leaderboardPollingInterval);
            }
        } else if (this.enableResultsHistoryPage && this.results) {
            const configMap = this.results?.configMaps ? this.results?.configMaps[0] : this.results?.configMap;
            if (configMap) {
                this.getLeaderboard(+configMap.promoId, +configMap.slotId, true);
            }
        }
    }

    getLeaderboard(promoId: number, slotId: number, fromResultsHistory: boolean): void {
        const maxRows =
            this.enableResultsHistoryPage && this.results
                ? 0
                : this.bingoHelperService.getConfigBasedOnInvokerProduct(this.slotRacesConfig.maxLeaderboardRows);
        //history results api irrespective of maxrows all rows r returned
        this.leaderboardSubscription = this.slotRacesService.getLeaderboard(promoId, slotId, maxRows, fromResultsHistory).subscribe(
            (leaderboard: Leaderboard) => {
                if (leaderboard.rankDetails && leaderboard.rankDetails.length) {
                    this.setStats(leaderboard);
                }
                this.coinObj = this.slotRacesService.bindCoinImg(leaderboard, this.coinImg);
            },
            (error: SlotRacesResponseError) => {
                this.slotRacesService.addErrorMessage(error.errorCode);
            },
        );
    }

    setStats(data: Leaderboard) {
        this.slotRacesConfig = this.slotRacesConfig ?? this.configProviderService.provideBingoTournamentsClientConfig();
        let prizes: any = [];
        this.stats = data.rankDetails;
        if (this.enableResultsHistoryPage && this.results) {
            this.stats = this.stats.filter(
                (stat: any) => stat.rank <= this.bingoHelperService.getConfigBasedOnInvokerProduct(this.slotRacesConfig.maxLeaderboardRows),
            );
        }
        if (data.content && data.content.promotion && data.content.promotion.prizes) {
            this.prizesString = data.content.promotion.prizes;
        }
        if (this.prizesString) {
            prizes = JSON.parse(this.prizesString);
        }
        if (data.content?.promotion?.beatBanker) {
            const beatBankerContent = JSON.parse(data.content.promotion.beatBanker);
            beatBankerContent.forEach((beatBanker: any) => {
                beatBanker.isBeatBanker = true;
            });
            prizes = prizes.concat(beatBankerContent);
        }

        this.stats.forEach((item: any) => {
            const award = prizes.find((prize: any) => Number(item.rank) >= Number(prize.FromRank) && Number(item.rank) < Number(prize.ToRank)) || {};
            this.fetchConfigMaps(item, award);
        });
        this.setPlayerStat(this.userName);
        if (this.showTopThree) {
            this.visibleStats = this.stats.slice(0, 3);
        } else {
            this.visibleStats = this.stats;
        }
        if (this.showTopThree) {
            this.height = this.calculateHeight(this.visibleStats.length);
        }
        this.leaderboardVisiblePublisher.next(this.visibleStats.length);
    }

    fetchConfigMaps(item: any, award: any = null) {
        item?.configMaps?.forEach((configMap: any) => {
            if (configMap && configMap.awardType && configMap.value) {
                item.icon = award?.Icon;
                if (configMap.awardType?.toLowerCase() != 'coins') {
                    if (!this.bingoHelperService.getConfigBasedOnInvokerProduct(this.slotRacesConfig.displayBonusAsText)) {
                        //leaderboard capsule is supposed to show bonus value instead of just "bonus" as text
                        if (configMap.awardType?.toLowerCase() == 'freebet' || configMap.awardType?.toLowerCase() == 'oddsboost_token') {
                            item.prize = this.leaderboardConfigs.versionedList[configMap.awardType?.toLowerCase()];
                        } else if (
                            configMap.awardType?.toLowerCase() == 'freespins' ||
                            configMap.awardType?.toLowerCase() == 'free_spin' ||
                            !configMap.currency ||
                            !configMap.currency.length
                        ) {
                            item.prize = Number(configMap.value);
                        } else {
                            item.prize = this.currencyPipe.transform(
                                configMap.value,
                                configMap.currency || this.currencyCode,
                                'symbol-narrow',
                                '1.2-2',
                            );
                        }
                    } else {
                        item.prize =
                            configMap.awardType?.toLowerCase() === 'bonus'
                                ? configMap.awardType?.toLowerCase()
                                : configMap && configMap.awardType && configMap.awardType?.toLowerCase().indexOf('cash') > -1
                                  ? this.currencyPipe.transform(configMap.value, configMap.currency || this.currencyCode, 'symbol-narrow', '1.2-2')
                                  : Number(configMap?.value);
                    }
                }
                if (configMap.awardType?.toLowerCase() == 'coins') {
                    item.isDisplayCoin = true;
                    item.coinValue = item.isDisplayCoin ? configMap.value : '';
                }
            }
        });
    }

    setPlayerStat(name: string) {
        if (this.stats) {
            this.playerStats = this.stats.find((player: any) => player.playerName == name);
            if (!this.playerStats && this.race && !this.results) {
                const playerDetails = this.race.rankDetails.find((player: any) => player.playerName == name);
                if (playerDetails) {
                    this.fetchConfigMaps([playerDetails]);
                    this.stats.push(playerDetails);
                }
                this.playerStats = playerDetails ? playerDetails : {};
            } else if (this.enableResultsHistoryPage && !this.playerStats && this.results) {
                const playerDetails: IBingoLeaderboardItem = {
                    rank: this.results.rank,
                    playerName: this.userName,
                    points: this.results.points,
                    icon: this.results.prizeIcon,
                    prize: this.results.awardValue == '-' ? undefined : this.results.awardValue,
                };
                if (playerDetails) {
                    this.stats.push(playerDetails);
                }
                this.playerStats = playerDetails ? playerDetails : {};
            }
        }
    }

    onLeaderboardInit() {
        const content = document.querySelector('.bingo-bt-mat-dialog');
        if (content) {
            const scroll$ = fromEvent(content, 'scroll').pipe(map(() => content));
            if (this.visibleStats) {
                this.setUserVisibility(this.visibleStats.length, content);
            }
            this.scrollSubscription = scroll$.subscribe(() => {
                const leaderboardContainer = document.querySelector('.bingo-bt-mat-dialog bingo-bt-races-leaderboard');
                if (leaderboardContainer && leaderboardContainer.getBoundingClientRect().bottom > 0 && this.visibleStats) {
                    this.setUserVisibility(this.visibleStats.length, content);
                }
            });
        }
    }

    setUserVisibility(count: number, container: any) {
        const element = document.getElementById('false-' + this.playerStats.playerName);
        if (element && container) {
            this.userIsVisible = this.isElementInViewport(container, element);
        }
        this.height = this.calculateHeight(count);
    }

    isElementInViewport(container: any, element: any) {
        const elementRect = element.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        return (
            elementRect.top >= containerRect.top &&
            elementRect.left >= containerRect.left &&
            elementRect.bottom <= containerRect.bottom &&
            elementRect.right <= containerRect.right
        );
    }

    calculateHeight(count: number) {
        if (!this.showTopThree) {
            const userRankGreaterThanThreshold =
                this.bingoHelperService.getConfigBasedOnInvokerProduct(this.slotRacesConfig.maxLeaderboardRows) < this.playerStats.rank;
            if (this.userEnrolled) {
                if (this.userIsVisible || userRankGreaterThanThreshold || this.playerStats != null) {
                    return 40 * (count + 1);
                } else {
                    return 40 * count;
                }
            }
        }
        return 40 * count + 40;
    }

    getSitecoreConfigurations(content: SitecoreContent) {
        if (content && content.textTranslations) {
            if (content.textTranslations.sharedList) {
                this.leaderboardConfigs.sharedList = content.textTranslations.sharedList;
            }
            if (content.textTranslations.versionedList) {
                this.leaderboardConfigs.versionedList = content.textTranslations.versionedList;
                this.leaderboardConfigs.playerTitleText = content.textTranslations.versionedList.PlayerTitle || 'Player';
                this.leaderboardConfigs.prizeTitleText = content.textTranslations.versionedList.PrizeIconTitle || 'Prize';
                this.leaderboardConfigs.pointsTitleText = content.textTranslations.versionedList.PointsTitle || 'Points';
                this.leaderboardConfigs.rankTitleText = content.textTranslations.versionedList.RankTitle || 'Rank';
            }
            if (content.textTranslations.sharedList) {
                if (content.textTranslations.sharedList.leaderboardHeaderBgColor !== '') {
                    this.leaderboardConfigs.leaderboardHeaderBgColor = content.textTranslations.sharedList.leaderboardHeaderBgColor;
                }
                if (content.textTranslations.sharedList.leaderboardHeaderTextColor !== '') {
                    this.leaderboardConfigs.leaderboardHeaderTextColor = content.textTranslations.sharedList.leaderboardHeaderTextColor;
                }
                if (content.textTranslations.sharedList.leaderboardContentTextColor !== '') {
                    this.leaderboardConfigs.leaderboardContentTextColor = content.textTranslations.sharedList.leaderboardContentTextColor;
                }
                if (content.textTranslations.sharedList.leaderboardAwardTypeBgColor !== '') {
                    this.leaderboardConfigs.leaderboardAwardTypeBgColor = content.textTranslations.sharedList.leaderboardAwardTypeBgColor;
                }
                if (content.textTranslations.sharedList.leaderboardAwardTypeTextColor !== '') {
                    this.leaderboardConfigs.leaderboardAwardTypeTextColor = content.textTranslations.sharedList.leaderboardAwardTypeTextColor;
                }
                if (content.textTranslations.sharedList.leaderboardPlayerPositionBgColor !== '') {
                    this.leaderboardConfigs.leaderboardPlayerPositionBgColorNotNull = true;
                    this.leaderboardConfigs.leaderboardPlayerPositionBgColor = content.textTranslations.sharedList.leaderboardPlayerPositionBgColor;
                    document.documentElement.style.setProperty(
                        '--player-position-bg-color',
                        this.leaderboardConfigs.leaderboardPlayerPositionBgColor,
                    );
                }
                if (content.textTranslations.sharedList.leaderboardPlayerPositionTextColor !== '') {
                    this.leaderboardConfigs.leaderboardPlayerPositionTextColorNotNull = true;
                    this.leaderboardConfigs.leaderboardPlayerPositionTextColor =
                        content.textTranslations.sharedList.leaderboardPlayerPositionTextColor;
                    document.documentElement.style.setProperty(
                        '--player-position-text-color',
                        this.leaderboardConfigs.leaderboardPlayerPositionTextColor,
                    );
                }
                if (content.textTranslations.sharedList.leaderboardPlayerPositionAwardTypeBgColor !== '') {
                    this.leaderboardConfigs.leaderboardPlayerPositionAwardTypeBgColorNotNull = true;
                    this.leaderboardConfigs.leaderboardPlayerPositionAwardTypeBgColor =
                        content.textTranslations.sharedList.leaderboardPlayerPositionAwardTypeBgColor;
                    document.documentElement.style.setProperty(
                        '--player-position-award-type-bg-color',
                        this.leaderboardConfigs.leaderboardPlayerPositionAwardTypeBgColor,
                    );
                }
                if (content.textTranslations.sharedList.leaderboardPlayerPositionAwardTypeTextColor !== '') {
                    this.leaderboardConfigs.leaderboardPlayerPositionAwardTypeTextColorNotNull = true;
                    this.leaderboardConfigs.leaderboardPlayerPositionAwardTypeTextColor =
                        content.textTranslations.sharedList.leaderboardPlayerPositionAwardTypeTextColor;
                    document.documentElement.style.setProperty(
                        '--player-position-award-type-text-color',
                        this.leaderboardConfigs.leaderboardPlayerPositionAwardTypeTextColor,
                    );
                }
            }
        }
    }

    subscribeToDataUpdates(): void {
        if (this.showTopThree) {
            this.dataUpdateSubscription = this.slotRacesService.liveRacesData.subscribe((liveRaces: SlotRacesLive) => {
                if (liveRaces && liveRaces.liveNow && liveRaces.liveNow.length) {
                    const updatedRace = liveRaces.liveNow.find((r) => r.promoId === this.race.promoId && r.slotUniqueId === this.race.slotUniqueId);
                    if (updatedRace && updatedRace.rankDetails && updatedRace.rankDetails.length > 0) {
                        const leaderboard: Leaderboard = { rankDetails: updatedRace.rankDetails, content: updatedRace.content };
                        this.setStats(leaderboard);
                    }
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.pollingInterval) {
            this.timerService.clearInterval(this.pollingInterval);
        }
        if (this.leaderboardSubscription) {
            this.leaderboardSubscription.unsubscribe();
        }
        if (this.leaderboardVisibleSubscription) {
            this.leaderboardVisibleSubscription.unsubscribe();
        }
        if (this.scrollSubscription) {
            this.scrollSubscription.remove(this.scrollSubscription);
            this.scrollSubscription.unsubscribe();
        }
        if (this.dataUpdateSubscription) {
            this.dataUpdateSubscription.unsubscribe();
        }
    }
}
