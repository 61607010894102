<div class="casino-lobby-container-main">
    <div class="casino-slot-races-pick-game casino-race-info-container">
        <div class="race-info-title-container">
            <span mat-dialog-title class="race-info-title">{{ messages.PickGameOverlayTitle }}</span>
            <span class="theme-ex race-info-close" (click)="close()"></span>
        </div>
        <div>
            <cc-slot-race-card
                [fromPickGameOverlay]="true"
                [race]="race"
                [category]="category"
                [showRaceInfo]="true"
                [enableOptin]="raceinfoopt"
                [lobbyType]="lobbyType"
                [showCardMenu]="false"
                (openRaceInfo)="openRaceInfoDialog()"
                (openNotificationOverlay)="openNotificationDialog()"
                [showPlaceholderCard]="false"
                [position]="1"
                [coinImg]="coinImg"
                (closePickGameOverlay)="close($event)"></cc-slot-race-card>
        </div>
        <div class="cs-sr-overlay-games-section">
            <mat-grid-list [cols]="breakpoint()" rowHeight="1:1" [gutterSize]="'10px'">
                <mat-grid-tile *ngFor="let game of race.gameVariantList; let i = index">
                    <cc-slot-races-icon
                        class="cc-slot-races-icon"
                        (launchSelectedGame)="launchGame($event)"
                        [gameVariantList]="[game]"
                        [lobbyType]="lobbyType"
                        [category]="category"
                        [source]="'Overlay|Slot Races'"
                        [position]="i"
                        [isClickable]="
                            !race.isLive &&
                            race.subType &&
                            (race.subType.toLowerCase() == 'free_to_play' || race.subType.toLowerCase() == 'free_play_tech')
                                ? false
                                : true
                        "
                        [isLive]="race.isLive"
                        [cta]="race.ctaList"
                        [spinProgress]="race?.spinProgress"
                        [isFreeTourney]="
                            race.subType && (race.subType.toLowerCase() == 'free_to_play' || race.subType.toLowerCase() == 'free_play_tech')
                        "></cc-slot-races-icon>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>
</div>
