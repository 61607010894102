import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { CasinoLobbyService, CasinoManager, GameDataManager, JackpotDataService, LiveCasinoService } from '@casinocore/platform/core';

import { NewsFeedV2JackpotComponent } from '../news-feed-v2-jackpot/news-feed-v2-jackpot.component';
import { NewsFeedV2LiveCasinoAPIItemComponent } from '../news-feed-v2-livecasino-api-item/news-feed-v2-livecasino-api-item.component';
import { NewsFeedV2NewgamesComponent } from '../news-feed-v2-newgames/news-feed-v2-newgames.component';
import { NewsFeedV2PromoMarketingComponent } from '../news-feed-v2-promo-marketing/news-feed-v2-promo-marketing.component';

@Component({
    selector: 'cc-news-feed-v2-custom-item',
    templateUrl: 'news-feed-v2-custom-item.component.html',
    standalone: true,
    imports: [
        NgIf,
        NgSwitch,
        NgSwitchCase,
        NewsFeedV2JackpotComponent,
        NewsFeedV2NewgamesComponent,
        NewsFeedV2PromoMarketingComponent,
        NewsFeedV2LiveCasinoAPIItemComponent,
    ],
})
export class NewsFeedV2CustomItemComponent implements OnInit, OnDestroy, OnChanges {
    @Input() item: any;
    @Input() index: number;
    @Input() lobbyType: string;
    @Input() attributes: any;
    @Input() data: any;
    displayEnabled: boolean;
    isDisabledGame: boolean;
    showJackpotItem: boolean;
    gameData: any;
    isTouch: boolean;
    jackpotSubscriber: any;
    jackpotObj: any = {};
    liveCasinoApiSubscriber: any;
    liveGameInfo: any;
    isLiveCasinoDisplay: boolean;
    promoCaseText: string;
    constructor(
        private casinoLobbyService: CasinoLobbyService,
        private gameDataManager: GameDataManager,
        private casinoManager: CasinoManager,
        private jackpotDataService: JackpotDataService,
        private liveCasinoService: LiveCasinoService,
    ) {}

    ngOnInit() {
        this.isTouch = this.casinoManager.isTouch();
        if (this.item.type == 'jackpot') {
            this.jackpotObj.jackpotHeader = this.item.header;
            this.jackpotSubscription();
        }
    }
    ngOnChanges() {
        this.setDisplayCriteria();
    }

    ngOnDestroy() {
        if (this.jackpotSubscriber) {
            this.jackpotSubscriber.unsubscribe();
        }
        if (this.liveCasinoApiSubscriber) {
            this.liveCasinoApiSubscriber.unsubscribe();
        }
    }

    setDisplayCriteria() {
        switch (this.item.type) {
            case 'jackpot':
                this.displayEnabled = this.isJackpotValid();
                break;
            case 'newgame':
                this.displayEnabled = this.isgameValid(this.item.gameID);
                break;
            case 'promo_marketing_bigwinner':
                this.displayEnabled = true;
                break;
            case 'livecasinonewsfeeditem':
                const isValidGame = this.isgameValid(this.item.gameID);
                this.liveCasinoApiSubscriber = this.liveCasinoService.liveGamesFeedObservable.subscribe((response) => {
                    if (response && response.size >= 0) {
                        this.liveGameInfo = this.liveCasinoService.getLiveGameLookUpInfo(this.item?.gameID?.toLowerCase());
                        if (isValidGame && this.liveGameInfo && this.liveGameInfo.tableStatus) {
                            this.displayEnabled = true;
                        }
                    }
                });
                break;
        }
    }

    isJackpotValid() {
        if (this.showJackpotItem) {
            this.findDisabledGame(this.item.gameID);
            return true;
        }
        return false;
    }

    isgameValid(gameID: any) {
        if (this.item) {
            this.findDisabledGame(gameID);
            const gameObj: any = this.casinoLobbyService.getGameInfo(gameID);
            if (gameObj) {
                const gameInfo: any = this.gameDataManager.setGameLobbyModel(gameObj, this.isTouch);
                this.gameData = gameInfo;
                return true;
            }
        }
        return false;
    }
    findDisabledGame(gameID: string) {
        const disabledGames = this.casinoLobbyService.getDisabledGames();
        disabledGames.forEach((game: string) => {
            if (game?.toLowerCase() == gameID?.toLowerCase()) {
                this.isDisabledGame = true;
            }
        });
    }
    jackpotSubscription() {
        if (this.item.type === 'jackpot') {
            if (this.item.header === '') {
                this.jackpotObj.jackpotHeader = this.item.jackpotGroupName;
            }
            if (!this.jackpotSubscriber) {
                this.jackpotSubscriber = this.jackpotDataService.jackpotFeedObservable.subscribe((jackpotResponse) => {
                    if (jackpotResponse) {
                        const jackpotData = this.jackpotDataService.getJackpotByGroupName(this.item?.jackpotGroupName?.toLowerCase());
                        if (jackpotData) {
                            this.jackpotObj.jackpotValue = jackpotData.amount;
                            const gameList = jackpotData.gamesAvailable;
                            gameList.forEach((gameItem: any) => {
                                if (gameItem['game'] === this.item.gameID) {
                                    const gameInfo = this.casinoLobbyService.getGameInfo(this.item.gameID);
                                    if (gameInfo != null) {
                                        this.jackpotObj.jackpotGameName = gameItem['name'];
                                        this.showJackpotItem = true;
                                        this.setDisplayCriteria();
                                    }
                                }
                            });
                        }
                    }
                });
            }
        }
    }
}
