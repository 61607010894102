import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatSliderModule } from '@angular/material/slider';

import { BingoTournamentIconModule } from '../../bingo-tournament-icon/bingo-tournament-icon.module';
import { BingoCoinEconomyConfirmPopupModule } from '../../coin-economy-confirm-popup/bingo-coin-economy-confirm-popup.module';
import { SharedModule } from '../../shared/shared.module';
import { BingoSlotRaceCardComponent } from './bingo-card.component';

@NgModule({
    imports: [CommonModule, MatSliderModule, SharedModule, BingoTournamentIconModule, BingoCoinEconomyConfirmPopupModule],
    declarations: [BingoSlotRaceCardComponent],
    exports: [BingoSlotRaceCardComponent],
})
export class BingoSlotRaceCardModule {
    public static forRoot(): ModuleWithProviders<BingoSlotRaceCardModule> {
        return {
            ngModule: BingoSlotRaceCardModule,
            providers: [],
        };
    }
}
