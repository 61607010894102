export enum ModelDefault {
    NA = 'not applicable',
    Site = 'Promo Hub',
}
/**
 * @whatItDoes This model has all tracking optional properties. Default value will be 'not applicable'
 *
 * @stable
 */

export class CartTrackingModel {
    eventName: string;
    name?: string;
    price?: number;
    transactionId?: string;
    quantity?: number;
    item: CartItem[];
}

export class CartItem {
    time?: string;
    title?: string;
    price?: string;
    winprize?: string;
    jackpot?: string;
    tickets?: string;
    constructor(Time: string, Title: string, price: any, winprize: any, jackpot: string, tickets: any) {
        this.time = Time;
        this.title = Title;
        this.price = price;
        this.winprize = winprize;
        this.jackpot = jackpot;
        this.tickets = tickets;
    }
}
