<ng-container *ngIf="displayEnabled && !isDisabledGame">
    <div class="news-feed-custom-item" [ngSwitch]="item.type">
        <cc-news-feed-v2-jackpot
            *ngSwitchCase="'jackpot'"
            [newsFeedItem]="item"
            [jackpotObj]="jackpotObj"
            [index]="index"
            [lobbyType]="lobbyType"
            [attributes]="attributes"
            [data]="data"></cc-news-feed-v2-jackpot>
        <cc-news-feed-v2-newgames
            *ngSwitchCase="'newgame'"
            [newsFeedItem]="item"
            [gameData]="gameData"
            [index]="index"
            [lobbyType]="lobbyType"
            [attributes]="attributes"
            [data]="data">
        </cc-news-feed-v2-newgames>
        <cc-news-feed-v2-promo-marketing
            *ngSwitchCase="'promo_marketing_bigwinner'"
            [newsFeedItem]="item"
            [index]="index"
            [lobbyType]="lobbyType"
            [attributes]="attributes"
            [data]="data">
        </cc-news-feed-v2-promo-marketing>
        <cc-news-feed-v2-livecasino-api-item
            *ngSwitchCase="'livecasinonewsfeeditem'"
            [newsFeedItem]="item"
            [attributes]="attributes"
            [lobbyType]="lobbyType"
            [index]="index"
            [data]="data">
        </cc-news-feed-v2-livecasino-api-item>
    </div>
</ng-container>
