<div class="bt-points-expandable-wrapper">
    <mat-expansion-panel [expanded]="doExpanded" (opened)="panelOpenState = true" (closed)="panelOpenState = false" hideToggle="true">
        <mat-expansion-panel-header>
            <mat-panel-title class="panel-title">
                {{ sitecoreData?.textTranslations?.versionedList?.Points }}
            </mat-panel-title>
            <mat-panel-description>
                <mat-icon *ngIf="!panelOpenState">
                    <span class="bt-theme-icon theme-plus"></span>
                </mat-icon>
                <mat-icon *ngIf="panelOpenState">
                    <span class="bt-theme-icon theme-minus"></span>
                </mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="bt-points-row" *ngFor="let ruleInfo of pointsData; let lastItem = last">
            <div class="bt-points-column">
                <div class="bt-points-content" [ngStyle]="{ 'background-image': 'url(' + ruleInfo?.pointsImage?.src + ')' }">
                    <div class="bt-points-text1">
                        {{ ruleInfo.pointValue }}
                        <span class="bt-points-text2">{{ ruleInfo.pointsText }}</span>
                    </div>
                </div>
                <div class="bt-points-singleline">{{ ruleInfo.pointsDescription }}</div>
            </div>
            <div class="bt-points-straight-line" *ngIf="!lastItem">
                <span></span>
            </div>
        </div>
    </mat-expansion-panel>
</div>
