import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SlotRacesService } from '@casinocore/platform/core';

@Component({
    selector: 'cc-notification-popup',
    templateUrl: 'notification-popup.component.html',
    standalone: true,
})
export class NotificationPopUpComponent implements OnInit {
    notificationOnMsg: string;
    notificationOffMsg: string;
    constructor(
        private dialogRef: MatDialogRef<NotificationPopUpComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private slotRacesService: SlotRacesService,
    ) {}

    ngOnInit() {
        if (this.data.status === true) {
            this.notificationOnMsg = this.data.messages.NotificationMsgOnText;
        } else {
            this.notificationOffMsg = this.data.messages.NotificationMsgOffText;
        }

        const timeout = this.slotRacesService.notificationPopupTimeout();

        setTimeout(() => {
            this.close();
        }, timeout);
    }

    close() {
        this.dialogRef.close();
    }
}
