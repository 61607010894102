import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SelectDropdownComponent } from './select-dropdown.component';

@NgModule({
    declarations: [SelectDropdownComponent],
    exports: [SelectDropdownComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class SelectDropdownModule {
    public static forRoot(): ModuleWithProviders<SelectDropdownModule> {
        return {
            ngModule: SelectDropdownModule,
        };
    }
}
