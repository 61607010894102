<div class="chat-name-modal-container">
    <button *ngIf="showCloseBtn" class="close" (click)="onCancel()"></button>
    <form [formGroup]="chatNameForm">
        <div class="chat-nmae-header">
            <h1>{{ screenNameMessages.ChatNameHeading }}</h1>
            <div class="lineforheader"></div>
        </div>
        <div [ngClass]="bindClass()">
            <input id="chatText" type="text" formControlName="chatText" />
            <i class="theme-success-i"></i>
            <i class="theme-error-i"></i>
        </div>
        <div class="chat-name-tandcs">
            <p>- {{ screenNameMessages.CharacterLengthMessage }}</p>
            <p>- {{ screenNameMessages.MaximumNamesPerMonth }}</p>
            <p>- {{ screenNameMessages.SensitiveInformationMessage }}</p>
        </div>
        <div class="chat-name-footer">
            <div class="lineforfooter"></div>
            <input type="button" class="btn btn-light" (click)="onCancel()" value="{{ screenNameMessages.CancelBtn | uppercase }}" />
            <input
                type="button"
                class="btn btn-primary"
                (click)="validateChatName()"
                [disabled]="disableConfirmButton"
                value="{{ screenNameMessages.ConfirmBtn | uppercase }}" />
        </div>
    </form>
</div>
