<div class="bingo-bt-lobby-container-main">
    <div *ngIf="showTitleHeader">
        <div class="bt-rules-header">
            <div class="bt-header-text">
                {{ sitecoreContent.textTranslations.versionedList.TermsAndConditionsTitle }}
            </div>
            <div class="ui-icon-size-lg theme-ex" (click)="closeButton()"></div>
        </div>
        <div class="bt-rules-header-image">
            <div class="rules-header-image-section" [ngStyle]="{ 'background-image': 'url(' + sitecoreContent.rulesBanner.image.src + ')' }">
                <div class="rules-image-title">
                    {{ sitecoreContent.rulesBanner.title }}
                </div>
            </div>
        </div>
    </div>
    <div class="bingo-bt-rules-tnc-popup">
        <div class="bingo-prizes-panel" id="bingo-prizes-container">
            <bgl-slot-races-prizes [showHeader]="true" [isCollapsible]="true" [isExpanded]="true" [race]="race" [coinImg]="coinImg">
            </bgl-slot-races-prizes>
        </div>

        <div class="bingo-bt-rules-panel">
            <bgl-slot-races-rules [showHeader]="true" [isCollapsible]="true" [isExpanded]="true" [race]="race"> </bgl-slot-races-rules>
        </div>
        <div class="bingo-bt-points-panel" [ngClass]="{ 'bingo-disable-tnc-points': !disableBingoTournamentsPoints }">
            <bgl-slot-races-points [showHeader]="true" [isCollapsible]="true" [isExpanded]="true" [race]="race"> </bgl-slot-races-points>
        </div>
        <div class="bingo-bt-tips-panel">
            <bgl-slot-races-tips [showHeader]="true" [isCollapsible]="true" [isExpanded]="true"> </bgl-slot-races-tips>
        </div>
        <div class="bingo-bt-tnc-panel">
            <bgl-slot-races-tnc [showHeader]="true" [isCollapsible]="false" [isExpanded]="true" [race]="race"> </bgl-slot-races-tnc>
        </div>
    </div>
</div>
