import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BingoPlatformApiService } from '../../bingo-platform-api/services/bingo-platform-api.service';

@Injectable({
    providedIn: 'root',
})
export class TicketsResourceService {
    constructor(private api: BingoPlatformApiService) {}

    buyTickets(body: any): Observable<any> {
        return this.api.post('prebuy', body);
    }

    getPrebuyData(selectedRoomId: number, from_time: number, end_time: number): Observable<any> {
        return this.api.get('prebuy' + '?rooms=' + selectedRoomId + '&start_time=' + from_time + '&end_time=' + end_time);
    }

    getPrebuyDataforConfigTime(roomIds: number[], from_time: number, end_time: number): Observable<any> {
        return this.api.get('prebuy', { rooms: roomIds, start_time: from_time, end_time: end_time });
    }

    getPrebuyDateData(Ids: any, gameTypeId: string, allRoomsData: boolean): Observable<any> {
        return this.api.get('PrebuyScheduleDates' + '?rooms=' + Ids + '&gameTypeId=' + gameTypeId + '&allRoomsData=' + allRoomsData);
    }

    getPreBuyBonusInfo(Id: any, gameTypeId: string): Observable<any> {
        return this.api.get('PrebuyBonusInfo' + '?id=' + Id + '&gameTypeId=' + gameTypeId);
    }
}
