import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ConfirmMessageService {
    public confirmFunction: () => void;
    public cancelFunction: () => void;
    public subject = new Subject<any>();

    constructor() {}
    confirmThis(
        header: string,
        message: string,
        note: string,
        confirmButtonText: string,
        closeButton: string,
        confirmFn: () => void,
        cancelFn: () => void,
    ) {
        this.setConfirmation(header, message, note, confirmButtonText, closeButton, confirmFn, cancelFn);
    }
    setConfirmation(
        header: string,
        message: string,
        note: string,
        confirmButtonText: string,
        closeButton: string,
        confirmFn: () => void,
        cancelFn: () => void,
    ) {
        this.confirmFunction = confirmFn;
        this.cancelFunction = cancelFn;
        this.subject.next({
            type: 'confirm',
            header: header,
            text: message,
            note: note,
            confirmButtonText: confirmButtonText,
            closeButton: closeButton,
            confirmFn: this.confirmFn,
            cancelFn: this.cancelFn,
        });
    }

    confirmFn(that: any): void {
        that.confirmMessageService.subject.next();
        that.confirmMessageService.confirmFunction();
    }

    cancelFn(that: any): void {
        that.confirmMessageService.subject.next();
        that.confirmMessageService.cancelFunction();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
