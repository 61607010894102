import { Injectable } from '@angular/core';

import { TrackingService } from '@frontend/vanilla/core';

import { CartTrackingModel } from './CartTrackingModel';
import { ModelDefault, TrackingModel } from './TrackingModel';

/**
 * @whatItDoes This service is for tracking, and created to remove duplicate code.
 *
 * @stable
 */
@Injectable()
export class TrackingModelService {
    constructor(private tracking: TrackingService) {}
    /**
     * @whatItDoes Default value of each TrackingModel property is 'not applicable'. Change value according to the requirement.
     *
     * @stable
     */
    submitTracking(trackingModel: TrackingModel) {
        trackingModel.SiteSection = ModelDefault.Site;
        trackingModel.CategoryEvent = trackingModel.CategoryEvent ? trackingModel.CategoryEvent : ModelDefault.Site;
        trackingModel.LabelEvent = trackingModel.LabelEvent ? trackingModel.LabelEvent : ModelDefault.NA;
        trackingModel.ActionEvent = trackingModel.ActionEvent ? trackingModel.ActionEvent : ModelDefault.NA;
        trackingModel.PositionEvent = trackingModel.PositionEvent ? trackingModel.PositionEvent : ModelDefault.NA;
        trackingModel.LocationEvent = trackingModel.LocationEvent ? trackingModel.LocationEvent : ModelDefault.NA;
        trackingModel.EventDetails = trackingModel.EventDetails ? trackingModel.EventDetails : ModelDefault.NA;
        trackingModel.URLClicked = trackingModel.URLClicked ? trackingModel.URLClicked : ModelDefault.NA;
        this.tracking.triggerEvent(trackingModel.eventName, {
            'page.siteSection': trackingModel.SiteSection,
            'component.CategoryEvent': trackingModel.CategoryEvent,
            'component.LabelEvent': trackingModel.LabelEvent,
            'component.ActionEvent': trackingModel.ActionEvent,
            'component.PositionEvent': trackingModel.PositionEvent,
            'component.LocationEvent': trackingModel.LocationEvent,
            'component.EventDetails': trackingModel.EventDetails,
            'component.URLClicked': trackingModel.URLClicked,
        });
    }

    submitCartTracking(trackingModel: CartTrackingModel) {
        trackingModel.transactionId = trackingModel.transactionId ? trackingModel.transactionId : ModelDefault.NA;
        this.tracking.triggerEvent(trackingModel.eventName, {
            name: trackingModel.name,
            price: trackingModel.price,
            quantity: trackingModel.quantity,
            transactionId: trackingModel.transactionId,
            item: trackingModel.item,
        });
    }

    SliderTacking(trackingModel: TrackingModel) {
        this.tracking.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': trackingModel.CategoryEvent,
            'component.LabelEvent': trackingModel.LabelEvent,
            'component.ActionEvent': trackingModel.ActionEvent,
            'component.PositionEvent': trackingModel.LocationEvent,
            'component.LocationEvent': trackingModel.LocationEvent,
            'component.EventDetails': trackingModel.EventDetails,
            'component.URLClicked': trackingModel.URLClicked,
        });
    }
}
