import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalize',
    standalone: true,
    pure: true,
})
export class CapitalizePipe implements PipeTransform {
    transform(str: string): string {
        return str?.toLowerCase().replace(/(^|\s|-)(\S)/g, (match, separator, char) => separator + char.toUpperCase());
    }
}
