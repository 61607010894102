<div class="casino-lobby-container-main">
    <div class="casino-rules-and-tc">
        <mat-expansion-panel
            [expanded]="isExpanded"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            hideToggle="true"
            #dropdown
            [ngClass]="this.isExpansionDisabled">
            <mat-expansion-panel-header>
                <mat-panel-title class="panel-title" *ngIf="showHeader">
                    {{ sitecoreData?.textTranslations?.versionedList?.Rules }}
                </mat-panel-title>
                <mat-panel-description *ngIf="isCollapsible">
                    <mat-icon *ngIf="!panelOpenState">
                        <span class="theme-plus"></span>
                    </mat-icon>
                    <mat-icon *ngIf="panelOpenState">
                        <span class="theme-minus"></span>
                    </mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="dropdown-text rules-sec" [style.color]="rulesContentColor">
                <div *ngFor="let rule of rulesData" class="edge-allign">
                    <div class="rules" id="rule-content">
                        <img class="rules-image" src="{{ rule.image.src }}" draggable="false" />
                        <div class="rules-title">{{ rule.title }}</div>
                        <div class="rules-title-text" [innerHTML]="rule.text | trustAsHtml"></div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
</div>
