import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: bingoConfigFactory,
})
@LazyClientConfig({ key: 'bgConfig', product: ClientConfigProductName.BINGO })
export class BingoConfig {
    alertMessageTimeout: number;
    friendsBingoRoom: number;
    hiddenGames: any;
    pinnedRoomId: number;
    brandID: string;
    bingoMultiline: boolean;
    invokerProduct: string;
    SessionBingoDetailsEndPoint: string;
    SessionBingoListEndPoint: string;
    SessionBingoPurchaseEndPoint: string;
    SessionBingoCancelEndPoint: string;
    enableSchedulePostMethod: boolean;
    scheduleRequestContent: any;
}
export function bingoConfigFactory(service: LazyClientConfigService) {
    return service.get(BingoConfig);
}
