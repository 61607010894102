import { Directive, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[bgTimer]',
})
export class TimerToggleViewDirective {
    @Input() timerBgColor: string = '';
    @Input() timerColor: string = '';
    @HostBinding('class.toggle-timer-set') _toggleTimerClass = false;
    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
    ) {}

    addClass() {
        this._toggleTimerClass = true;
        if (this.timerBgColor != '') {
            this.renderer.setStyle(this.elementRef.nativeElement, 'background', this.timerBgColor);
        }
        if (this.timerColor != '') {
            this.renderer.setStyle(this.elementRef.nativeElement, 'color', this.timerColor);
        }
    }

    removeClass() {
        this._toggleTimerClass = false;
    }
}
