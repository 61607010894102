import { Injectable } from '@angular/core';

import { ApiOptions, ClientConfigService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { BingoPlatformApiService } from '../../bingo-platform-api/services/bingo-platform-api.service';
import { BingoUrlUtilityService } from '../../bingo-url-utility/services/bingo-url-utility.service';
import { BingoApiPathsClientConfig } from '../../client-config/bingo-api-paths.config';
import { BingoModularConfigurationClientConfig } from '../../client-config/bingo-modular-configuration-client.config';
import { BingoModularConfigurationFeaturesClientConfig } from '../../client-config/bingo-modular-configuration-features-client.config';
import { BingoTournamentsClientConfig } from '../../client-config/bingo-tournaments-clients.config';
import { BingoConfig } from '../../client-config/bingo.config';
import { CacheSettingsClientConfig } from '../../client-config/cache-settings-client.config';
import { CasinoClientConfig } from '../../client-config/casino-client.config';
import { FeaturesClientConfig } from '../../client-config/features-client.config';
import { JackpotDataClientConfig } from '../../client-config/jackpot-data-client.config';
import { ModularCasinoClientConfig } from '../../client-config/modular-casino-client.config';
import { PlayingNowClientConfig } from '../../client-config/playing-now-client.config';
import { PrebuyOptimizationClientConfig } from '../../client-config/prebuy-optimization-client.config';
import { ScreenNameClientConfig } from '../../client-config/screen-name-client.config';
import { SingleEntryPointClientConfig } from '../../client-config/single-entry-point-client.config';
import { SuperLinksClientConfig } from '../../client-config/super-links-client.config';
import { TagManagerClientConfig } from '../../client-config/tag-manager-client.config';
import { TeaserClientConfig } from '../../client-config/teaser-client.config';
import { ThunderBiteClientConfig } from '../../client-config/thunder-bite-client.config';

@Injectable({
    providedIn: 'root',
})
export class BingoConfigProviderService {
    bingoApiPathsClientConfig: BingoApiPathsClientConfig;
    bingoConfig: BingoConfig;
    bingoTournamentsClientConfig: BingoTournamentsClientConfig;
    cacheSettingsClientConfig: CacheSettingsClientConfig;
    casinoClientConfig: CasinoClientConfig;
    featuresClientConfig: FeaturesClientConfig;
    jackpotDataClientConfig: JackpotDataClientConfig;
    modularCasinoClientConfig: ModularCasinoClientConfig;
    playingNowClientConfig: PlayingNowClientConfig;
    prebuyOptimizationClientConfig: PrebuyOptimizationClientConfig;
    screenNameClientConfig: ScreenNameClientConfig;
    singleEntryPointClientConfig: SingleEntryPointClientConfig;
    superLinksClientConfig: SuperLinksClientConfig;
    tagManagerClientConfig: TagManagerClientConfig;
    teaserClientConfig: TeaserClientConfig;
    thunderBiteClientConfig: ThunderBiteClientConfig;
    bingoClientConfigLoadedSubject = new BehaviorSubject<boolean>(false);
    //Subscribe to this observable to get the status of the bingo client config
    bingoClientConfigObservable: Observable<boolean> = this.bingoClientConfigLoadedSubject.asObservable();
    bingoModularConfigurationClientConfig: BingoModularConfigurationClientConfig;
    bingoModularConfigurationFeaturesClientConfig: BingoModularConfigurationFeaturesClientConfig;
    constructor(
        private api: BingoPlatformApiService,
        private urlUtility: BingoUrlUtilityService,
        private clientConfigService: ClientConfigService,
    ) {}

    initiateBingo(isDll: boolean) {
        this.fillClientConfig(isDll);
    }

    fillClientConfig(isDll: boolean) {
        if (isDll) {
            this.bingoApiPathsClientConfig = this.clientConfigService.get(BingoApiPathsClientConfig);
            this.bingoConfig = this.clientConfigService.get(BingoConfig);
            this.bingoTournamentsClientConfig = this.clientConfigService.get(BingoTournamentsClientConfig);
            this.cacheSettingsClientConfig = this.clientConfigService.get(CacheSettingsClientConfig);
            this.casinoClientConfig = this.clientConfigService.get(CasinoClientConfig);
            this.featuresClientConfig = this.clientConfigService.get(FeaturesClientConfig);
            this.jackpotDataClientConfig = this.clientConfigService.get(JackpotDataClientConfig);
            this.modularCasinoClientConfig = this.clientConfigService.get(ModularCasinoClientConfig);
            this.bingoModularConfigurationClientConfig = this.clientConfigService.get(BingoModularConfigurationClientConfig);
            this.bingoModularConfigurationFeaturesClientConfig = this.clientConfigService.get(BingoModularConfigurationFeaturesClientConfig);
            this.playingNowClientConfig = this.clientConfigService.get(PlayingNowClientConfig);
            this.prebuyOptimizationClientConfig = this.clientConfigService.get(PrebuyOptimizationClientConfig);
            this.screenNameClientConfig = this.clientConfigService.get(ScreenNameClientConfig);
            this.singleEntryPointClientConfig = this.clientConfigService.get(SingleEntryPointClientConfig);
            this.superLinksClientConfig = this.clientConfigService.get(SuperLinksClientConfig);
            this.tagManagerClientConfig = this.clientConfigService.get(TagManagerClientConfig);
            this.teaserClientConfig = this.clientConfigService.get(TeaserClientConfig);
            this.thunderBiteClientConfig = this.clientConfigService.get(ThunderBiteClientConfig);
            this.setClientConfigLoadedObservable();
        } else {
            const apiurl: string = this.urlUtility.getClientConfigUrl();
            const options: ApiOptions = { headers: {} };
            if (options && options.headers) {
                options.headers['Cache-Control'] = 'no-cache';
                options.headers['Pragma'] = 'no-cache';
                options.headers['Expires'] = '0';
                options.withCredentials = true;
            }
            this.api.get(apiurl, {}, options).subscribe((response: any) => {
                if (response) {
                    this.bingoApiPathsClientConfig = response.bgApiPathsConfig;
                    this.bingoConfig = response.bgConfig;
                    this.bingoTournamentsClientConfig = response.bgBingoTournaments;
                    this.cacheSettingsClientConfig = response.bgCacheSettingsConfig;
                    this.featuresClientConfig = response.bgFeatureConfig;
                    this.bingoModularConfigurationClientConfig = response.bgBingoModularConfiguration;
                    this.bingoModularConfigurationFeaturesClientConfig = response.bgBingoModularConfigurationFeatures;
                    this.playingNowClientConfig = response.bgPlayingNowConfig;
                    this.prebuyOptimizationClientConfig = response.bgPrebuyOptimizationConfig;
                    this.screenNameClientConfig = response.bgScreenName;
                    this.singleEntryPointClientConfig = response.bgSingleEntryPoint;
                    this.superLinksClientConfig = response.bgSuperLinks;
                    this.casinoClientConfig = response.bgCasinoConfig;
                    this.jackpotDataClientConfig = response.bgJackpotData;
                    this.modularCasinoClientConfig = response.bgModularCasinoConfig;
                    this.tagManagerClientConfig = response.bgTagManagerConfig;
                    this.teaserClientConfig = response.bgTeaser;
                    this.thunderBiteClientConfig = response.bgThunderBiteConfig;
                    this.setClientConfigLoadedObservable();
                }
            });
        }
    }

    setClientConfigLoadedObservable() {
        this.bingoClientConfigLoadedSubject.next(true);
    }

    // provideBingoPageClientConfig() {
    //     if(this.bingoPageClientConfig == undefined)
    //         this.initiateBingo(true);
    //     return this.bingoPageClientConfig;
    // }

    provideBingoConfig() {
        return this.bingoConfig;
    }

    provideBingoTournamentsClientConfig() {
        return this.bingoTournamentsClientConfig;
    }

    provideCacheSettingsClientConfig() {
        return this.cacheSettingsClientConfig;
    }

    provideCasinoClientConfig() {
        return this.casinoClientConfig;
    }

    provideFeaturesConfig() {
        // if(this.bingoPageClientConfig == undefined)
        //     this.initiateBingo(true);
        return this.featuresClientConfig;
    }

    provideJackpotDataClientConfig() {
        return this.jackpotDataClientConfig;
    }

    provideModularCasinoClientConfig() {
        // if(this.bingoPageClientConfig == undefined)
        //     this.initiateBingo(true);
        return this.modularCasinoClientConfig;
    }

    providePlayingNowClientConfig() {
        return this.playingNowClientConfig;
    }

    providePrebuyOptimizationClientConfig() {
        return this.prebuyOptimizationClientConfig;
    }

    provideScreenNameClientConfig() {
        return this.screenNameClientConfig;
    }

    provideSingleEntryPointClientConfig() {
        return this.singleEntryPointClientConfig;
    }

    provideSuperLinksClientConfig() {
        return this.superLinksClientConfig;
    }

    provideTagManagerClientConfig() {
        return this.tagManagerClientConfig;
    }

    provideTeaserClientConfig() {
        return this.teaserClientConfig;
    }

    provideThunderBiteClientConfig() {
        return this.thunderBiteClientConfig;
    }

    provideApiPathsConfig() {
        return this.bingoApiPathsClientConfig;
    }

    provideBingoModularConfigurationClientConfig() {
        return this.bingoModularConfigurationClientConfig;
    }

    provideBingoModularConfigurationFeaturesClientConfig() {
        return this.bingoModularConfigurationFeaturesClientConfig;
    }
}
