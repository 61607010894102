import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { BingoCoinEconomyConfirmPopupComponent } from './bingo-coin-economy-confirm-popup.component';

@NgModule({
    imports: [CommonModule],
    declarations: [BingoCoinEconomyConfirmPopupComponent],
    exports: [BingoCoinEconomyConfirmPopupComponent],
})
export class BingoCoinEconomyConfirmPopupModule {
    public static forRoot(): ModuleWithProviders<BingoCoinEconomyConfirmPopupModule> {
        return {
            ngModule: BingoCoinEconomyConfirmPopupModule,
        };
    }
}
