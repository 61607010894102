import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { CasinoCoreNpmParamsService, CasinoLobbyService } from '@casinocore/platform/core';

import { SlotRacesCardsComponent } from './cards-wrapper/cards-wrapper.component';
import { SlotRacesComponent } from './slot-races.component';

@Component({
    selector: 'cc-slot-races-wrapper',
    templateUrl: 'slot-races-wrapper.component.html',
    standalone: true,
    imports: [NgIf, SlotRacesComponent, SlotRacesCardsComponent],
})
export class SlotRacesWrapperComponent implements OnInit {
    @Input() component: string;
    @Input() lobbyType: string;
    @Input() category: any;

    constructor(
        private npmParamService: CasinoCoreNpmParamsService,
        private lobbyService: CasinoLobbyService,
    ) {}

    ngOnInit() {
        const npmParams = this.npmParamService.getcasinoCoreNpmParams();
        if (npmParams && npmParams.disableSlotracesCallsOnBootstrap) {
            //if slot race init calls are disabled through npm then they would need to be called from init here.. as the init calls are necessary for slot races to function
            this.lobbyService.initializeSlotRaces(true);
        }
    }
}
