import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { BingoConfigProviderService, BingoPlatformApiService, FeaturesClientConfig } from '@frontend/bingo/core';
import { TrackingService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { BuyNowGridComponent } from '../../session-bingo/buy-now-grid/buy-now-grid.component';
import { RootServicesModule } from '../root-services.module';
import { LoginService } from './login-Service';
import { ScreenNameService } from './screen-name.service';

@Injectable({
    providedIn: RootServicesModule,
})
export class SessionPackageService {
    finalGameDetails = new BehaviorSubject<any>(null);
    finalGameDetailsObsevable: Observable<any> = this.finalGameDetails.asObservable();
    multistakePrice = new BehaviorSubject<any>(null);
    multistakePriceObservable: Observable<any> = this.multistakePrice.asObservable();
    iosClassChanged = new BehaviorSubject<any>(null);
    iosClassChangedObservable: Observable<any> = this.iosClassChanged.asObservable();
    finalTopAndTailObj: any;
    totalPrice: number;
    scheduleIds: string;
    purchasedPackageId = new BehaviorSubject<any>(null);
    purchasedPackageIdObsevable: Observable<any> = this.purchasedPackageId.asObservable();
    isPurchase = new BehaviorSubject<any>(null);
    isPurchaseObsevable: Observable<any> = this.isPurchase.asObservable();
    data: any;
    iosClass: boolean;
    isInGameClient: boolean = false;
    featuresConfig: FeaturesClientConfig;

    constructor(
        private api: BingoPlatformApiService,
        private screenNameService: ScreenNameService,
        private configProviderService: BingoConfigProviderService,
        private activeroute: ActivatedRoute,
        public loginService: LoginService,
        private dialog: MatDialog,
        private tracking: TrackingService,
    ) {
        if (this.activeroute.snapshot.queryParamMap.get('ingame=true')) {
            this.isInGameClient = true;
        }
    }

    updateTicketsSelectedDetails(obj: any) {
        if (obj && obj.totalPrice) {
            this.totalPrice = obj.totalPrice;
        } else {
            obj.totalPrice = this.totalPrice;
        }
        this.finalTopAndTailObj = obj;
        this.finalGameDetails.next(obj);
    }
    purshaseValidation(data: any) {
        if (data) {
            const maxValuePackageDiscount = data.package_discount_info[data.package_discount_info.length - 1]?.number_of_tickets;
            const noOfTicketsBought = data.purchase_status?.total_purchased_tickets;
            if (maxValuePackageDiscount && noOfTicketsBought) {
                for (let i = 0; i < data.package_discount_info.length; i++) {
                    if (data.package_discount_info[i].number_of_tickets <= maxValuePackageDiscount - noOfTicketsBought) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    updateMultiStakePrice(price: any) {
        this.multistakePrice.next(price);
    }

    getFinalGameObj() {
        return this.finalTopAndTailObj;
    }
    setClassStatusforIOS(value: any) {
        this.iosClass = value;
        this.iosClassChanged.next(this.iosClass);
    }

    getSessionList(package_type: string, gameData: any): Observable<any> {
        this.featuresConfig = this.configProviderService.provideFeaturesConfig();
        const startTime = new Date().getTime() / 1000;
        // let endTime = startTime + 604800;
        const endTime = startTime + 86400 * this.featuresConfig.sessionDays;
        const schIds: string[] = [];
        gameData.result.games_list.forEach((item: any) => {
            schIds.push(item.id);
        });
        this.scheduleIds = schIds.toString();
        //this.scheduleIds = ['186','251','521','364'].toString();
        return this.api.get(
            'SessionList?packageType=' + package_type + '&rooms=' + this.scheduleIds + '&startTime=' + startTime + '&endTime=' + endTime,
        );
    }

    getPackageDetails(package_type: string, packageId: string): Observable<any> {
        return this.api.get('GetPackageDetails?packageType=' + package_type + '&packageId=' + packageId);
    }

    getConfirmationDetails(purchageDetails: any) {
        return this.api.post('PurchasePackage', purchageDetails);
    }

    getSitecoreContent(PackageType: any): Observable<any> {
        return this.api.get('GetSessionOrPackageContent?packageType=' + PackageType);
    }
    getSessionGlobalMessages(): Observable<any> {
        return this.api.get('SessionGlobalMassages');
    }
    getPackageInfo() {
        return this.data;
    }
    setPackageInfo(data: any) {
        this.data = data;
    }
    setPackageId(Id: any) {
        this.purchasedPackageId.next(Id);
    }
    setIsPurchase(Id: any, isPurchase: any) {
        this.isPurchase.next({ Id: Id, isPurchase: isPurchase });
    }
    packageTracking(CategoryEvent: any, packageOrSession: any, packageids: any, LocationEvent: any, EventDetails: any) {
        this.tracking.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': CategoryEvent,
            'component.LabelEvent': packageOrSession,
            'component.ActionEvent': ' click',
            'component.PositionEvent': packageids,
            'component.LocationEvent': LocationEvent,
            'component.EventDetails': EventDetails,
            'component.URLClicked': 'not applicable',
        });
    }
    iconClick(gamename: any, action: any, infomode: any, package_type: any) {
        this.tracking.updateDataLayer({
            'component.CategoryEvent': 'Bingo',
            'component.LabelEvent': package_type,
            'component.ActionEvent': action,
            'component.PositionEvent': 'not applicable',
            'component.LocationEvent': gamename,
            'component.EventDetails': infomode,
            'component.URLClicked': 'not applicable',
        });
    }
    buySessionAandPackage(package_type: any, action: any, gamename: any, EventDetails: any) {
        this.tracking.updateDataLayer({
            'component.CategoryEvent': 'Bingo',
            'component.LabelEvent': package_type,
            'component.ActionEvent': action,
            'component.PositionEvent': 'confirm purchase overlay',
            'component.LocationEvent': gamename,
            'component.EventDetails': EventDetails,
            'component.URLClicked': 'not applicable',
        });
    }

    openBuyNowGrid(data: any) {
        this.loginService.clearnBingoforcedUrl();
        const DialogRef = this.dialog.open(BuyNowGridComponent, {
            panelClass: 'session-bingo-popup',
            data: data,
        });
        const afteropenessubcription: Subscription = DialogRef.afterOpened().subscribe(() => {
            if (afteropenessubcription) {
                const htmltag = document.getElementsByTagName('html');
                if (htmltag && htmltag[0]) {
                    if (!htmltag[0].classList.contains('cdk-global-scrollblock')) {
                        htmltag[0].classList.add('cdk-global-scrollblock');
                    }
                }

                afteropenessubcription.unsubscribe();
            }
        });
        const afterclosesubcription: Subscription = DialogRef.afterClosed().subscribe(() => {
            if (afterclosesubcription) {
                const htmltag = document.getElementsByTagName('html');
                if (htmltag && htmltag[0]) {
                    if (htmltag[0].classList.contains('cdk-global-scrollblock')) {
                        htmltag[0].classList.remove('cdk-global-scrollblock');
                    }
                }
                afterclosesubcription.unsubscribe();
            }
        });
    }

    loadPopupsBasedOnCondition(popUpData: any) {
        if (this.isInGameClient) {
            this.openBuyNowGrid(popUpData.data);
        } else {
            this.screenNameService.validateScreenNameChat().then((response) => {
                if (response) {
                    this.openBuyNowGrid(popUpData.data);
                } else {
                    this.screenNameService.openScreenChatNameComponent(popUpData);
                }
            });
        }
    }

    cancelSessionAandPackage(package_type: any, action: any, gamename: any, EventDetails: any) {
        if (localStorage.getItem('confirm') !== 'true') {
            this.tracking.updateDataLayer({
                'component.CategoryEvent': 'Bingo',
                'component.LabelEvent': package_type,
                'component.ActionEvent': action,
                'component.PositionEvent': 'confirm purchase overlay',
                'component.LocationEvent': gamename,
                'component.EventDetails': EventDetails,
                'component.URLClicked': 'not applicable',
            });
            localStorage.removeItem('confirm');
        }
    }
}
