import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { FeatureIconComponent } from './feature-icon.component';

@NgModule({
    declarations: [FeatureIconComponent],
    exports: [FeatureIconComponent],
    imports: [CommonModule],
})
export class FeatureIconModule {
    public static forRoot(): ModuleWithProviders<FeatureIconModule> {
        return {
            ngModule: FeatureIconModule,
        };
    }
}
