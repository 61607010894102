import { NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { CasinoCoreGamelaunchService, CasinoManager, ConfigProviderService, GameLaunch } from '@casinocore/platform/core';
import { NavigationService, TrackingService } from '@frontend/vanilla/core';

@Component({
    selector: 'cc-news-feed-v2-promo-marketing',
    templateUrl: 'news-feed-v2-promo-marketing.component.html',
    standalone: true,
    imports: [NgStyle, NgIf],
})
export class NewsFeedV2PromoMarketingComponent implements OnInit {
    @Input() newsFeedItem: any;
    @Input() index: number;
    @Input() lobbyType: string;
    @Input() attributes: any;
    @Input() data: any;
    isTouch: boolean;
    categoryType: string;
    videoLaunchRealtiveUri: string;
    constructor(
        private tracking: TrackingService,
        private casinoManager: CasinoManager,
        private configProviderService: ConfigProviderService,
        private gameLaunchService: CasinoCoreGamelaunchService,
        private navigation: NavigationService,
    ) {}

    ngOnInit() {
        this.isTouch = this.casinoManager.isTouch();
        this.categoryType = 'NewsFeed';
        const mcPageConfig = this.configProviderService.provideMcPageConfig();
        this.videoLaunchRealtiveUri = '/' + mcPageConfig.labelPrefix + '/launchng/';
    }

    itemCliked() {
        this.itemTrackingOnClick(this.index);
        this.newsFeedItem.gameId = this.getGameIdFromLaunchUrl(this.newsFeedItem.link);
        const gameModel = new GameLaunch();
        gameModel.game = this.newsFeedItem;
        gameModel.isTouch = this.isTouch;
        gameModel.gamePosition = this.index;
        gameModel.categoryType = this.categoryType;
        gameModel.from = 'q';
        gameModel.replaceRouteInHistory = false;
        gameModel.isDemoLaunch = false;
        gameModel.skipUnfinGame = false;
        gameModel.gameLaunchedFrom = this.categoryType;
        gameModel.gridPosition = 1;
        gameModel.gameSection = this.categoryType;
        gameModel.gameContainer = '';
        gameModel.game.lobbyType = this.lobbyType;
        gameModel.returnUrl = window.location.href;
        gameModel.clickTimeStamp = this.gameLaunchService.currentTimeStamp();
        if (this.newsFeedItem.link) {
            if (this.isGameUrl(this.newsFeedItem.link)) {
                this.gameLaunchService.launchGame(gameModel);
            } else {
                this.navigation.goTo(this.newsFeedItem?.link);
            }
        }
    }
    itemTrackingOnClick(positionEvent: number) {
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'NewsFeed',
            'component.LabelEvent': 'Promotion',
            'component.ActionEvent': 'Click',
            'component.PositionEvent': ++positionEvent,
            'component.LocationEvent': this.data ? this.data.trackPos : '',
            'component.EventDetails': this.newsFeedItem.header,
            'component.URLClicked': 'not applicable',
        });
    }
    getGameIdFromLaunchUrl(link: string) {
        return link.substr(link.indexOf(this.videoLaunchRealtiveUri) + this.videoLaunchRealtiveUri.length);
    }

    isGameUrl(link: string) {
        return link.indexOf(this.videoLaunchRealtiveUri) > 0;
    }
}
