<div class="mobile-stories-cta">
    <div class="with-cta">
        <img [src]="storyItem?.backgroundImage?.src" />
        <h2
            [ngClass]="'actiontypeposition-' + (subTitlePosition | lowercase)"
            [ngStyle]="{ 'color': subTitleTxtClr, 'font-size': subTitleFontSize }"
            *ngIf="storyItem.subTitle"
            [textContent]="storyItem.subTitle"></h2>
        <div
            [ngClass]="'actiontypeposition-' + (descriptionPosition | lowercase)"
            [ngStyle]="{ 'color': descriptionClr, 'font-size': descriptionFontSize }"
            *ngIf="storyItem.description"
            class="story-descrip"
            [textContent]="storyItem.description"></div>
        <div class="customCTAbtn" [ngClass]="'actiontypeposition-' + (actionTypePosition | lowercase)">
            <a
                [ngStyle]="{ 'background-color': OptinBtnBgClr, 'border-color': OptinBtnBgClr, 'color': OptinBtnTxtClr }"
                [href]="customCTAUrl"
                class="btn btn-primary more-info-btn btn-lg"
                >{{ customCTAText }}</a
            >
        </div>
    </div>
</div>
