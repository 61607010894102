import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
//import { VanillaCoreModule } from '@frontend/vanilla/core';
import { RouterModule } from '@angular/router';

import { DsButton } from '@frontend/ui/button';
import { CurrencyPipe } from '@frontend/vanilla/core';
import { MessagePanelComponent } from '@frontend/vanilla/features/message-panel';
import { CustomButtonComponent } from 'packages/bingo/frontend-lib/core/src/shared/components/designsystems/custombutton.component';
import { DecoratorClassDirective } from 'packages/bingo/frontend-lib/core/src/shared/directives/decorator-class.directive';

import { BonusSupressionPopupModule } from '../shared/components/bonus-supression/bonus-supression-popup.module';
import { BtnToggleModule } from '../shared/components/btn-toggle/btn-toggle.module';
import { bgCollapsibleContainerModule } from '../shared/components/collapsible-container/collapsible-container.module';
import { FeatureIconModule } from '../shared/components/feature-icon/feature-icon.module';
import { SelectDropdownModule } from '../shared/components/select-dropdown/select-dropdown.module';
import { SliderModule } from '../shared/components/slider/slider.module';
import { TouchSpinModule } from '../shared/components/touch-spin/touch-spin.module';
import { PreBuyGridComponent } from '../tickets/pre-buy-grid/pre-buy-grid.component';
import { MyTicketsComponent } from './my-tickets/my-tickets.component';
import { MultiPriceSliderComponent } from './pre-buy-grid/MultiPriceSlider.component';
import { PreBuySliderComponent } from './pre-buy-grid/preBuy-slider.component';
import { PriceModalPopupComponent } from './pre-buy-grid/pricemodal-popup/price-modal-popup.component';
import { PriceInfoModalPopupComponent } from './pre-buy-grid/pricemodalinfo-popup/price-modal-info-popup.component';
import { TeamModalPopupComponent } from './pre-buy-grid/team-modal-popup/team-modal-popup.component';
import { TopandtailPopupComponent } from './pre-buy-grid/topandtail-popup/topandtail-popup.component';
import { TicketsComponent } from './tickets.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        //VanillaCoreModule,
        MatTabsModule,
        bgCollapsibleContainerModule,
        BtnToggleModule,
        TouchSpinModule,
        FormsModule,
        FeatureIconModule,
        ReactiveFormsModule,
        SelectDropdownModule,
        MatSliderModule,
        SliderModule,
        MessagePanelComponent,
        CurrencyPipe,
        BonusSupressionPopupModule,
        DsButton,
        CustomButtonComponent,
        DecoratorClassDirective,
    ],
    declarations: [
        TicketsComponent,
        PreBuyGridComponent,
        MultiPriceSliderComponent,
        PreBuySliderComponent,
        MyTicketsComponent,
        TopandtailPopupComponent,
        PriceInfoModalPopupComponent,
        PriceModalPopupComponent,
        TeamModalPopupComponent,
    ],
    exports: [
        TicketsComponent,
        PreBuyGridComponent,
        MultiPriceSliderComponent,
        PreBuySliderComponent,
        MyTicketsComponent,
        TopandtailPopupComponent,
        PriceInfoModalPopupComponent,
        PriceModalPopupComponent,
        TeamModalPopupComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TicketsModule {
    public static forRoot(): ModuleWithProviders<TicketsModule> {
        return {
            ngModule: TicketsModule,
            providers: [],
        };
    }
}
