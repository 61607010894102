<div class="teamSelectModal-new fab fluffy">
    <div class="fluffy-title">{{ priceAndPrizeText }}<i class="theme-ex fluffy-close" (click)="closePrice()"></i></div>
    <div class="mid-section">
        <div class="multi_price_prize">
            <span class="bingo-feature-icon feature-fluffy-bingo">
                <i class="theme-multistake-feature"></i>
            </span>
            <span class="multistake-content">{{ multiStakeText }}</span>
            <i class="theme-info" (click)="toggleModelSelectPriceInfo()"></i>
            <span class="right-value">{{ data?.currentGame?.selected_win | currency: data?.currentGame?.currency }}</span>
            <i [ngClass]="data?.enableNewPrebuyIcons ? 'theme-bingo-gala-trophy' : 'theme-bingo-trophy'"></i>
        </div>
        <bg-slider-ang
            (ValueChanged)="onPriceSelected($event)"
            [currency]="data.currentGame.currency"
            [values]="
                data?.currentGame?.isMultistake || data?.isMultiPriceComponent
                    ? data?.currentGame?.game_context?.VariableCardStake?.ticket_prices_player_currency
                    : data?.currentGame?.game_context?.SlingoMultiStake?.price_points_config_in_player_currency
            "
            [currentindex]="currentIndex"
            [isSlingoMultiStake]="data?.currentGame?.isSlingoMultiStake">
        </bg-slider-ang>
    </div>
    <div class="bottom-section">
        <span (click)="closePrice()" class="btn fluffy-ok">{{ data.multistakeContent?.ok }}</span>
    </div>
</div>
