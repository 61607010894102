import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: screenNameClientConfigFactory,
})
@LazyClientConfig({ key: 'bgScreenName', product: ClientConfigProductName.BINGO })
export class ScreenNameClientConfig {
    //messages: any;
    host: string;
    screenNameGetApi: string;
    screenNameSetApi: string;
    enableClientScreenName: boolean;
}
export function screenNameClientConfigFactory(service: LazyClientConfigService) {
    return service.get(ScreenNameClientConfig);
}
