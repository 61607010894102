import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ConfirmMessageService } from '@frontend/bingo/core';

@Component({
    selector: 'bg-topandtail-popup',
    templateUrl: './topandtail-popup.component.html',
})
export class TopandtailPopupComponent implements OnInit {
    fabgrabPopupHeader: string;
    fabgrabPopupMessage: string;
    globalClientConfig: any;
    luckyDipText: string;
    clearText: string;
    selectText: string;
    numbers: number[] = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
        39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73,
        74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90,
    ];
    GetRandomPattern = (min: number, max: number, count: number, result: number[]): number[] => {
        const n = Math.floor(Math.random() * max) + min;
        if (result.indexOf(n) < 0) {
            count--;
            result.push(n);
        }
        if (count != 0) this.GetRandomPattern(min, max, count, result);
        return result;
    };
    constructor(
        public dialogRef: MatDialogRef<TopandtailPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private confirmMessageService: ConfirmMessageService,
    ) {}

    onSelected = new EventEmitter();
    onNumbersClosed = new EventEmitter();

    ngOnInit(): void {
        this.luckyDipText = this.data?.luckyDipText ? this.data.luckyDipText : this.data?.prebuynavcolitems?.columns?.luckyDipText;
        this.clearText = this.data?.content?.FabGrabClear ? this.data.content.FabGrabClear : this.data?.prebuynavcolitems?.columns?.clearText;
        this.selectText = this.data?.content?.FabGrabSelect ? this.data.content.FabGrabSelect : this.data?.prebuynavcolitems?.columns?.selectText;
        if (localStorage.getItem('config')) {
            const clientConfig = JSON.parse(localStorage.getItem('config') || '');
            if (!this.globalClientConfig) this.globalClientConfig = clientConfig.global;
        }
        this.fabgrabPopupHeader = this.globalClientConfig['messages']?.fabgrabPopupHeader;
        this.fabgrabPopupMessage = this.globalClientConfig['messages']?.fabgrabPopupMessage;
    }

    selectPattern() {
        this.data.currentGame.game_context.FomoBingo.fomo_numbers = [];
        const pattern = this.GetRandomPattern(1, 90, this.data.currentGame.game_context.FomoBingo.maximum_numbers, []);
        this.data.currentGame.game_context.FomoBingo.fomo_numbers = pattern;
        this.onSelected.emit({
            currentGame: this.data.currentGame,
        });
    }

    onNumberSelect(n: number) {
        const index = this.data.currentGame.game_context.FomoBingo.fomo_numbers.indexOf(n);
        if (index !== -1) this.data.currentGame.game_context.FomoBingo.fomo_numbers.splice(index, 1);
        else {
            if (this.data.currentGame.game_context.FomoBingo.fomo_numbers.length == this.data.currentGame.game_context.FomoBingo.maximum_numbers) {
                const message = this.globalClientConfig.messages.fabGrabMaxSelectMessage,
                    header = this.globalClientConfig.messages.fabGrabAlertHeader,
                    closeBtn = this.globalClientConfig.messages.confirmCloseButton;
                this.confirmMessageService.confirmThis(
                    header,
                    message,
                    '',
                    '',
                    closeBtn,
                    () => {},
                    () => {},
                );
                return;
            } else this.data.currentGame.game_context.FomoBingo.fomo_numbers.push(n);
        }
        this.onSelected.emit({
            currentGame: this.data.currentGame,
        });
    }

    onNumbersClose() {
        if (this.data.isFabGrabComponent) {
            this.onNumbersClosed.emit({
                currentGame: this.data.currentGame,
            });
        } else {
            this.closeModal();
        }
    }

    closeModal() {
        this.dialogRef.close();
    }

    clearNumbers() {
        this.data.currentGame.game_context.FomoBingo.fomo_numbers = [];
    }
}
