import { Component } from '@angular/core';

import { AlertMessageService } from './alert-message.service';

@Component({
    selector: 'bg-alert-message',
    templateUrl: './alert-message.component.html',
})
export class AlertMessageComponent {
    constructor(public alertMessageService: AlertMessageService) {}

    hide(): void {
        this.alertMessageService.isShown = false;
    }
}
