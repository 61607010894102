import { Injectable } from '@angular/core';

import { SessionStoreService, UserService } from '@frontend/vanilla/core';
import * as __ from 'lodash-es';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { BingoConfigProviderService } from '../../bingo-config-provider/services/bingo-config-provider.service';
import { BingoPlatformApiService } from '../../bingo-platform-api/services/bingo-platform-api.service';
import { BingoConfig } from '../../client-config/bingo.config';
import { CacheSettingsClientConfig } from '../../client-config/cache-settings-client.config';
import { FeaturesClientConfig } from '../../client-config/features-client.config';
import { SingleEntryPointClientConfig } from '../../client-config/single-entry-point-client.config';
import { SuperLinksClientConfig } from '../../client-config/super-links-client.config';
import { TicketsResourceService } from '../../my-tickets/services/tickets-resource.service';

@Injectable({
    providedIn: 'root',
})
export class ScheduleService {
    gameData: any;
    toBeRemoved: any;
    PlayerSegmentData: {};
    globalClientConfig: any;
    UserFreeTickets: any;
    playerSegmentionSubject = new BehaviorSubject<any>(null);
    UserFreeTicketsSubject = new BehaviorSubject<any>(null);
    iscozyUserSubject = new BehaviorSubject<boolean>(false);
    scheduleDataSubject = new BehaviorSubject<any>(null);
    lockedRoomIdsArray: number[] = [];
    locationFilterIdsArray: number[] = [];
    scheduleData: any;
    insuranceRoomIds: number[] = [];
    clientConfig: any = JSON.parse(localStorage.getItem('config') || '');

    playerSegmentDataObservable: Observable<any> = this.playerSegmentionSubject.asObservable();
    UserFreeTicketsDataObservable: Observable<any> = this.UserFreeTicketsSubject.asObservable();
    scheduleDataObservable: Observable<any> = this.scheduleDataSubject.asObservable();
    differentVariantTypeOfRooms: any = [];

    isCozyUserObservable: Observable<boolean> = this.iscozyUserSubject.asObservable();
    public dataSubject = new Subject<any>();
    public dataSubjectval = new Subject<any>();
    public dataSubjectslider = new Subject<any>();

    constructor(
        private api: BingoPlatformApiService,
        private bingoConfigProviderService: BingoConfigProviderService,
        private user: UserService,
        private session: SessionStoreService,
        private ticketsResource: TicketsResourceService,
    ) {}
    checkforscheduleData() {
        return this.scheduleData && this.scheduleData.length > 0;
    }

    getFeatureTypesbyRoomId(roomId: any) {
        for (let i = 0; i < this.scheduleData.length; i++) {
            if (this.scheduleData[i].id == roomId && !this.scheduleData[i].isHidden) {
                return this.scheduleData[i];
            }
        }
    }
    getFeaturebyRoomId(roomId: any) {
        for (let i = 0; i < this.scheduleData.length; i++) {
            if (this.scheduleData[i].id == roomId) {
                return this.scheduleData[i];
            }
        }
    }
    getRoomsbyRoomId(roomId: any) {
        const room = this.getFeatureTypesbyRoomId(roomId);
        const roomIdsArray: any = [];
        if (room?.isCombinedRoom || room?.isHidden) {
            for (let i = 0; i < this.scheduleData.length; i++) {
                if (this.scheduleData[i].bingo_variant == room?.bingo_variant) {
                    roomIdsArray.push(this.scheduleData[i].id);
                }
            }
        }
        return roomIdsArray;
    }
    getroomIdsData() {
        const bingoConfig: BingoConfig = this.bingoConfigProviderService.provideBingoConfig();
        const hiddenGameList = bingoConfig?.hiddenGames?.Id;
        return this.scheduleData.reduce((ids: any, game: any) => {
            if (!ids.includes(game.id) && !game.isHidden && game.prebuy_enabled && hiddenGameList.indexOf(game.id) < 0) {
                ids.push(game.id);
            }
            return ids;
        }, []);
    }
    getGameData(callback: any) {
        const bingoConfig: BingoConfig = this.bingoConfigProviderService.provideBingoConfig();
        this.toBeRemoved = [];
        if (bingoConfig.enableSchedulePostMethod) {
            this.api.post('schedulePost').subscribe((response) => {
                if (response && response.result && response.result.games_list) {
                    this.initializeScheduleFeatures(response);
                }
                return callback(response);
            });
        } else {
            this.api.get('schedule').subscribe((response) => {
                if (response && response.result && response.result.games_list) {
                    this.initializeScheduleFeatures(response);
                }
                return callback(response);
            });
        }
    }
    initializeScheduleFeatures(response: any) {
        const bingoConfig: BingoConfig = this.bingoConfigProviderService.provideBingoConfig();
        const singleEntryPointConfig: SingleEntryPointClientConfig = this.bingoConfigProviderService.provideSingleEntryPointClientConfig();
        const superLinksConfig: SuperLinksClientConfig = this.bingoConfigProviderService.provideSuperLinksClientConfig();
        const hiddenGameList = bingoConfig?.hiddenGames?.Id;
        //let nonGroupRooms: any[]=[];
        let roomsDataFromDynacon: any[] = [];
        let roomNamesFromDynacon: string[] = [];
        let parentIdsFromDynacon: string[] = [];
        this.emitScheduleData(response.result.games_list);
        this.differentVariantTypeOfRooms = [];
        const parentDataFromDynacon: any = superLinksConfig.superLinksData;
        const parentDataFromSitecore: any = this.clientConfig?.superLinksSitecoreData;
        if (superLinksConfig.enableSuperLinks && parentDataFromDynacon && parentDataFromDynacon.length > 0) {
            parentDataFromDynacon.forEach((parent: any) => {
                parentIdsFromDynacon.push(parent?.parentId);
            });
        }
        if (singleEntryPointConfig.enableRoomsGrouping && singleEntryPointConfig.enableGroupingForMultiVariants) {
            roomsDataFromDynacon = singleEntryPointConfig.groupingRoomsData;
            if (roomsDataFromDynacon.length > 0) {
                roomsDataFromDynacon.forEach((singleRoom: any) => {
                    roomNamesFromDynacon.push(singleRoom?.variantName);
                });
            }
        }
        if (singleEntryPointConfig.enableRoomsGrouping && !singleEntryPointConfig.enableGroupingForMultiVariants) {
            roomNamesFromDynacon = ['bingobeats'];
            roomsDataFromDynacon = [{ variantName: 'bingobeats' }];
        }
        response.result.games_list.forEach((item: any) => {
            // by default all rooms are non grouped
            item.isCombinedRoom = false;
            if (hiddenGameList?.indexOf(item.id) != -1) {
                this.toBeRemoved.push(item);
            } else {
                if (this.user.isAuthenticated && response.result.favouriteRooms != undefined) {
                    item.isFavourite = response.result.favouriteRooms.indexOf(item.id) >= 0;
                }
                item.feedname = item.name;
                item.name = this.getGameNameFromMappings(item.id, item.name);

                if (item.insurance) {
                    this.insuranceRoomIds.push(Number(item.id));
                }
            }
            if (
                (singleEntryPointConfig.enableRoomsGrouping || singleEntryPointConfig.enableGroupingForMultiVariants) &&
                roomNamesFromDynacon.includes(item?.bingo_variant)
            ) {
                roomsDataFromDynacon.forEach((singleRoom: any) => {
                    if (singleRoom.variantName == item?.bingo_variant) {
                        item.isHidden = true;
                        item.combinedFeatureType = item.bingo_variant;
                        this.differentVariantTypeOfRooms.push(item);
                    }
                });
            }
            if (
                superLinksConfig.enableSuperLinks &&
                parentIdsFromDynacon?.length > 0 &&
                (parentIdsFromDynacon.includes(item.superLink?.parentRoomId) || (parentIdsFromDynacon.includes(item.id) && item.superLink))
            ) {
                parentDataFromDynacon.forEach((parent: any) => {
                    if (parent.parentId == item.superLink?.parentRoomId || parent.parentId == item.id) {
                        const superLinksData = parentDataFromSitecore[parent.sitecoreFolder];
                        if (superLinksData.length > 0) {
                            superLinksData.forEach((data: any) => {
                                if (item.id == data.childId) {
                                    item.isSuperLink = true;
                                    item.roomImage = data.roomImage?.src;
                                    item.bgImage = data.backgroundImage?.src;
                                    item.childPlayDescription = data.childGameplayDescription;
                                }
                            });
                        }
                    }
                });
            }
        });
        //response.result.games_list=[];
        //response.result.games_list=nonGroupRooms;
        if (this.differentVariantTypeOfRooms.length > 0) {
            this.getConfigDataFromLocalstorage();
            let similarVariantTypeOfRooms: any[];
            //let groupedVariantRoomsArray: any[];
            //groupedVariantRoomsArray = [];

            roomsDataFromDynacon.forEach((singleRoom: any) => {
                let sitecoreObject: any = {};
                if (singleEntryPointConfig.enableRoomsGrouping && singleEntryPointConfig.enableGroupingForMultiVariants) {
                    sitecoreObject = this.clientConfig?.singleEntryPointData?.find(
                        (data: any) => data.bingoVariantName === singleRoom.sitecoreFolder,
                    );
                    if (sitecoreObject) {
                        sitecoreObject.variantName = singleRoom.variantName;
                        sitecoreObject.hasPrize = singleRoom.hasPrize;
                        sitecoreObject.hasPrice = singleRoom.hasPrice;
                        sitecoreObject.combinedFeature = singleRoom.combinedFeature;
                    }
                } else {
                    sitecoreObject = {
                        combinedRoomName: this.globalClientConfig.messages?.CombinedRoomName,
                        bingoVariantName: 'bingobeats',
                        preBuyBonusId: this.globalClientConfig.messages?.PreBuyBonusId,
                        variantName: 'bingobeats',
                        hasPrize: true,
                        hasPrice: true,
                        combinedFeature: 'multi_price',
                    };
                }
                similarVariantTypeOfRooms = [];
                this.differentVariantTypeOfRooms.forEach((item: any) => {
                    if (singleRoom.variantName == item?.bingo_variant) {
                        similarVariantTypeOfRooms.push(item);
                    }
                });
                //groupedVariantRoomsArray.push(similarVariantTypeOfRooms);
                if (sitecoreObject && similarVariantTypeOfRooms && similarVariantTypeOfRooms.length > 0) {
                    const combinedRoomExists = response.result.games_list?.find((x: any) => x.name == sitecoreObject?.combinedRoomName);

                    if (!combinedRoomExists) {
                        response.result.games_list.push(this.createCombinedRoom(similarVariantTypeOfRooms, sitecoreObject));
                    }
                }
            });
        }
        this.scheduleData = response.result.games_list;
        this.toBeRemoved.forEach((item: any) => {
            const index = response.result.games_list.indexOf(item);
            if (index > -1) response.result.games_list.splice(index, 1);
        });
        // if (this.insuranceRoomIds && this.insuranceRoomIds.length > 0) {
        //     this.insuranceRoomIds.forEach((item: any) => {
        //         let index = response.result.games_list.indexOf(item);
        //         if (index > 0) {
        //             response.result.games_list.splice(index, 1);
        //             response.result.games_list.push(item);
        //         }
        //     });
        // }
        this.session.set('ScheduleFeed', response);
    }
    getConfigDataFromLocalstorage() {
        if (!this.globalClientConfig && localStorage.getItem('config')) {
            const globalConfig = JSON.parse(localStorage.getItem('config') || '');
            this.globalClientConfig = globalConfig.global;
        }
    }
    createCombinedRoom(newCombinedRooms: any, sitecoreData: any) {
        // considered 1 item from the combinedRooms for its schema
        let combinedRoom: any;
        const combinedRooms = __.cloneDeep(newCombinedRooms);
        if (sitecoreData.preBuyBonusId) {
            const siteCoreRooms = combinedRooms.filter((x: any) => x.id == sitecoreData.preBuyBonusId);
            if (siteCoreRooms.length == 0) {
                combinedRoom = this.getRandomCombinedRoom(combinedRooms);
            } else {
                combinedRoom = siteCoreRooms[0];
            }
        } else {
            combinedRoom = this.getRandomCombinedRoom(combinedRooms);
        }
        const featuresSet = new Set();
        //combinedRoom.id=this.globalClientConfig.messages?.PreBuyBonusId ? this.globalClientConfig.messages?.PreBuyBonusId : combinedRoom.id;
        combinedRoom.isFavourite = false;
        combinedRoom.isCombinedRoom = true;
        combinedRoom.isHidden = false;
        combinedRoom.gameCount = combinedRooms.length;
        combinedRoom.number_of_players = 0;
        combinedRoom.name = sitecoreData?.combinedRoomName;
        combinedRoom.combinedRoomPrebuyId = sitecoreData?.preBuyBonusId;
        combinedRoom.combinedVariantName = sitecoreData?.bingoVariantName;
        combinedRoom.combinedFeatureType = sitecoreData?.variantName;
        combinedRoom.roomImage = sitecoreData.roomImage?.src;
        combinedRoom.combinedRoomHasPrize = sitecoreData?.hasPrize;
        combinedRoom.combinedRoomHasPrice = sitecoreData?.hasPrice;
        combinedRoom.combinedRoomFeature = sitecoreData?.combinedFeature;
        combinedRoom.sit_n_go_enabled = false;
        combinedRooms?.forEach((element: any) => {
            // If any of the combined room is favorite then the grouped room is also favorite
            if (element?.isFavourite) combinedRoom.isFavourite = true;
            // If any of the combined room is sitngo then the grouped room is also sitngo
            if (element?.sit_n_go_enabled) combinedRoom.sit_n_go_enabled = true;
            // player count of combined room is summataion of all rooms players count
            combinedRoom.number_of_players += element.number_of_players;
            // clubbing all feature icons and assigning to this room
            element.features?.forEach((ele: any) => {
                featuresSet.add(ele);
            });
        });
        if (featuresSet && featuresSet.size > 0) combinedRoom.features = Array.from(featuresSet);
        //combinedRoom.status.state_end_time=
        return combinedRoom;
    }

    getRandomCombinedRoom(combinedRooms: any) {
        // sort to get minimum time start time
        let combinedRoom: any;
        const roomsWaiting = combinedRooms.filter((x: any) => x.status.state_end_time != null || x.status.state_end_time != undefined);
        if (roomsWaiting?.length > 0) {
            combinedRoom = __.cloneDeep(roomsWaiting?.sort((a: any, b: any) => (a.status.state_end_time > b.status.state_end_time ? 1 : -1))[0]);
        } else {
            combinedRoom = __.cloneDeep(combinedRooms[0]);
        }
        return combinedRoom;
    }

    getGameNameFromMappings(id: any, name: string): string {
        const bingoPage: any = this.clientConfig?.pageConfig?.value;
        const bingoGameMappings = bingoPage['bingoGameMappings'];
        return bingoGameMappings[id] == null || bingoGameMappings[id] == undefined ? name : bingoGameMappings[id];
    }

    setPlayerSegmentationData() {
        const features: FeaturesClientConfig = this.bingoConfigProviderService.provideFeaturesConfig();
        if (this.user.isAuthenticated && features.enableOptimove) {
            this.api.get('PosContent', {}, { withCredentials: true }).subscribe((response) => {
                this.PlayerSegmentData = response;
                this.playerSegmentionSubject.next(response);
            });
        }
    }
    // setUserFreeBingoTickets() {
    //     if (this.user.isAuthenticated && this.features.enableFreeBingoTickets)
    //         this.api
    //             .get('WalletBalance', {}, { withCredentials: true })
    //             .subscribe((response) => {
    //                 this.UserFreeTickets = response;
    //                 this.UserFreeTicketsSubject.next(response);
    //             });
    // }
    getPlayerSegmentationData() {
        return this.UserFreeTickets;
    }
    storeLockedRooms(roomId: any) {
        this.lockedRoomIdsArray.push(roomId);
        this.lockedRoomIdsArray = this.lockedRoomIdsArray.filter((v, i, a) => a.indexOf(v) === i);
    }
    getLockedRooms(): any {
        return this.lockedRoomIdsArray;
    }

    getInsuranceRoomIds() {
        return this.insuranceRoomIds;
    }
    setlocationFilterRooms(roomId: any) {
        this.locationFilterIdsArray.push(roomId);
        this.locationFilterIdsArray = this.locationFilterIdsArray.filter((v, i, a) => a.indexOf(v) === i);
    }
    getlocationFilterRooms() {
        return this.locationFilterIdsArray;
    }
    segmentationRules(bgRoom: any, playerValueSegment: any) {
        const str = bgRoom.values;
        const attribute: string = bgRoom.attributes;
        if (str && attribute) {
            if (str.startsWith('>')) {
                const value = Number(str.substring(1));
                if (playerValueSegment[attribute] > value) {
                    return true;
                }
                return false;
            } else if (str.startsWith('<')) {
                const value = Number(str.substring(1));
                if (playerValueSegment[attribute] < value) {
                    return true;
                }
                return false;
            } else if (str.match('-?[0-9]+,-?[0-9]+')) {
                const array: string[] = str.split(',');
                for (let i = 0; i < array.length; i++) {
                    if (Number(array[i]) === Number(playerValueSegment[attribute])) {
                        return true;
                    }
                }
                return false;
            } else if (str.match('-?[0-9]+--?[0-9]+')) {
                const array: string[] = str.split('-');
                const num = Number(playerValueSegment[attribute]);
                if (num > Number(array[0]) && num < Number(array[1])) {
                    return true;
                }
                return false;
            } else {
                if (String(playerValueSegment[attribute]) === str) {
                    return true;
                }
                return false;
            }
        }
        return true;
    }
    setIsCozyUser(value: boolean) {
        this.iscozyUserSubject.next(value);
    }
    triggerPreBuyDatesData() {
        const cacheSettingsConfig: CacheSettingsClientConfig = this.bingoConfigProviderService.provideCacheSettingsClientConfig();
        const Ids = this.getroomIdsData().reduce((ids: String, ele: any) => {
            ids = ids.length > 0 ? ids + ',' + ele.toString() : ele.toString();
            return ids;
        }, '');
        this.ticketsResource.getPrebuyDateData(Ids, '', true).subscribe((response) => {
            if (response && cacheSettingsConfig.preBuy.enableSessionStorage) {
                sessionStorage.setItem('PrebuyScheduleDates', JSON.stringify(response));
            }
        });
    }
    emitScheduleData(games_list: any) {
        this.scheduleDataSubject.next(games_list);
    }

    sendPopupData(data: any) {
        this.dataSubject.next(data);
    }
    sendLocalData(data: any) {
        this.dataSubjectval.next(data);
    }
    sendSliderData(data: any) {
        this.dataSubjectslider.next(data);
    }
}
