import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    ClaimsService,
    DeviceService,
    Message,
    MessageLifetime,
    MessageQueueService,
    MessageType,
    SessionStoreService,
    TimerService,
    UserService,
} from '@frontend/vanilla/core';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BingoConfigProviderService } from '../../bingo-config-provider/services/bingo-config-provider.service';
import { BingoPlatformApiService } from '../../bingo-platform-api/services/bingo-platform-api.service';
import { GameLaunchService } from '../../game-launch/services/game-launch.service';
import { ScheduleService } from '../../schedule/services/schedule.service';
import { BingoHelperService } from '../../shared/services/bingo-helper.service';
import { RoomsInfoService } from '../../shared/services/rooms-info.service';
import {
    Race,
    ResultsHistory,
    SitecoreContent,
    SlotApiParams,
    SlotRacesLive,
    SlotRacesResponseError,
    SlotRacesUpcoming,
} from '../models/bingo-tournaments.models';

@Injectable({
    providedIn: 'root',
})
export class BingoTournamentsService {
    isSlotRacesEnabled: boolean;
    isNotificationEnabled: boolean;
    isCoin: boolean;
    selectedFilteredtOption: string;
    stickerMap: Map<string, any> = new Map<string, any>();
    raceGamesMap: Map<string, any> = new Map<string, any>();
    messages: { [item: string]: string };
    private contentPublisher = new BehaviorSubject<any>({});
    content: Observable<any> = this.contentPublisher.asObservable();
    contentData: SitecoreContent = {
        bingoRaceRulesConfigs: [],
        rules: [],
        rulesBanner: {},
        placeholderCardImage: {},
        coinEconomy: {},
        textTranslations: { sharedList: {}, versionedList: {} },
        tips: [],
        errorMessages: { sharedList: {}, versionedList: {} },
        entryDetails: { sharedList: {}, versionedList: {} },
    };

    private notifyPublisher = new BehaviorSubject<{ promoId: number; slotUniqueId: number; status: boolean }>({
        promoId: 0,
        slotUniqueId: 0,
        status: false,
    });
    notifyObservable: Observable<{ promoId: number; slotUniqueId: number; status: boolean }> = this.notifyPublisher.asObservable();
    private liveRacesPublisher = new BehaviorSubject<SlotRacesLive>({ liveNow: [] });
    liveRacesData: Observable<SlotRacesLive> = this.liveRacesPublisher.asObservable();

    forcRacepublisher = new BehaviorSubject<any>({});
    forceRace: Observable<any> = this.forcRacepublisher.asObservable();

    forceCoinBalancepublisher = new BehaviorSubject<any>({});
    forceCoinBalanceObs: Observable<any> = this.forceCoinBalancepublisher.asObservable();

    private upcomingRacesPublisher = new BehaviorSubject<SlotRacesUpcoming>({ upcomingToday: [], upcomingTomorrow: [] });
    upcomingRacesData: Observable<SlotRacesUpcoming> = this.upcomingRacesPublisher.asObservable();

    private globalTimerPublisher = new BehaviorSubject<any>({});
    globalTimerObservable: Observable<any> = this.globalTimerPublisher.asObservable();

    private updateNavigationCategories = new BehaviorSubject<boolean>(true);
    updateNavigation: Observable<boolean> = this.updateNavigationCategories.asObservable();

    private stickerRacesPublisher = new BehaviorSubject<any>({});
    stickerRacesData: Observable<any> = this.stickerRacesPublisher.asObservable();

    private rtmsDatPublisher = new BehaviorSubject<any>({});
    rtmsData: Observable<any> = this.rtmsDatPublisher.asObservable();

    coinDetailsPublisher = new BehaviorSubject<any>(false);
    coinDetails: Observable<any> = this.coinDetailsPublisher.asObservable();

    selectedEntryOption = new Subject<string>();
    liveSlotPollingStarted: boolean;
    pollForUser: boolean = true;
    private pollingInterval: any;
    private globalTimerInterval: number = 1000; //take this value from dynacon if possible
    private gameMetadata: { [game: string]: any };
    private isGameMetadataAvailable: boolean;
    private slotRaces: Race[];
    private disabledGameNames: string[] = [];
    private isSlotRacesInitiated: boolean;
    private promoPrizesMap: any = {};
    private timer: any;
    optInButtonText: string;
    schedulePolling: any;
    scheduleDataSubscription: Subscription;
    isBingoHomepage: boolean;
    private isNavCategoryVisible: boolean;

    constructor(
        private api: BingoPlatformApiService,
        private timerService: TimerService,
        private messageQueue: MessageQueueService,
        private claims: ClaimsService,
        //private casinoManager: BingoManager,
        private user: UserService,
        private configProviderService: BingoConfigProviderService,
        private scheduleService: ScheduleService,
        private session: SessionStoreService,
        private deviceService: DeviceService,
        private gameLaunchService: GameLaunchService,
        private activeroute: ActivatedRoute,
        private roomsInfoService: RoomsInfoService,
        private bingoHelperService: BingoHelperService,
    ) {}

    setNotifyStatus(promoId: number, slotUniqueId: number, status: boolean): void {
        this.notifyPublisher.next({ promoId: promoId, slotUniqueId: slotUniqueId, status: status });
    }

    // initSlotRaces(gameDataMap: any, disabledGames: any, lmtData: LobbyItem, isDLL: boolean, isInItSlotRaces: boolean): void {
    initSlotRaces(isDLL: boolean, isInItSlotRaces: boolean, fromBingoHomepage: boolean): void {
        if (isInItSlotRaces) {
            this.emptySlotRacesData();
        }
        if (!this.isSlotRacesInitiated) {
            this.isSlotRacesInitiated = true;
            this.getSitecoreData(!isDLL);
        }
        this.forceOptin();
        const configs = this.configProviderService.provideBingoTournamentsClientConfig();
        this.isBingoHomepage = fromBingoHomepage;
        this.scheduleFeedCall(fromBingoHomepage);
        // this.populateRacesObject();
        if (this.bingoHelperService.getConfigBasedOnInvokerProduct(configs.enableSlotRacesStickers)) {
            this.slotRaceStickersData();
        }
    }

    scheduleFeedCall(fromBingoHomepage: boolean, windowVar: boolean = false) {
        if (this.schedulePolling) clearInterval(this.schedulePolling);
        if (this.pollingInterval) clearInterval(this.pollingInterval);
        if (fromBingoHomepage) {
            this.scheduleDataSubscription = this.scheduleService.scheduleDataObservable.subscribe((schedulePostResponse: any) => {
                if (schedulePostResponse) {
                    const configs = this.configProviderService.provideBingoTournamentsClientConfig();
                    const filteredSchedule = schedulePostResponse.filter((roomList: any) => {
                        return roomList.tournament_enabled;
                    });
                    if (
                        (this.user.isAuthenticated || this.bingoHelperService.getConfigBasedOnInvokerProduct(configs.enablePreLogin)) &&
                        filteredSchedule?.length > 0
                    ) {
                        this.getLiveRaces();
                        this.getUpcomingRaces();
                    }
                }
            });
        } else {
            if (windowVar) {
                this.ReloadFeed(fromBingoHomepage);
            } else {
                this.scheduleService.getGameData((response: any) => {
                    if (response && response.result && response.result.games_list) {
                        this.session.set('ScheduleFeed', response);
                        const configs = this.configProviderService.provideBingoTournamentsClientConfig();
                        const filteredSchedule = response.result.games_list.filter((roomList: any) => {
                            return roomList.tournament_enabled;
                        });
                        if (
                            (this.user.isAuthenticated || this.bingoHelperService.getConfigBasedOnInvokerProduct(configs.enablePreLogin)) &&
                            filteredSchedule?.length > 0
                        ) {
                            this.getLiveRaces();
                            this.getUpcomingRaces();
                        }
                        this.ReloadFeed(fromBingoHomepage);
                    }
                });
            }
        }
    }

    ReloadFeed(fromBingoHomepage: boolean) {
        const scheduleFeedTimeOut = this.bingoHelperService.getConfigBasedOnInvokerProduct(
            this.configProviderService.provideBingoTournamentsClientConfig()?.scheduleFeedTimeInterval,
        );
        const windowVar = (window as any).Disable_SchedulePolling ? (window as any).Disable_SchedulePolling : false;
        this.schedulePolling = setTimeout(() => {
            this.scheduleFeedCall(fromBingoHomepage, windowVar);
        }, scheduleFeedTimeOut);
    }

    LaunchBingoGame(race: Race) {
        const scheduleFeedResponse: any = this.session.get('ScheduleFeed');
        const RoomData = JSON.parse(localStorage.getItem('config') || '').rooms;
        if (scheduleFeedResponse && scheduleFeedResponse.result && scheduleFeedResponse.result.games_list) {
            const filteredSchedule = scheduleFeedResponse.result.games_list.filter((roomList: any) => {
                return roomList.tournament_enabled && race.gameVariants.indexOf(roomList.id) > -1;
            });
            if (filteredSchedule && filteredSchedule.length > 0) {
                this.gameLaunchService.initiateGameLaunch(
                    filteredSchedule[0].name,
                    'Bingo',
                    'Bingo',
                    filteredSchedule[0].bingo_type,
                    'REGULAR',
                    this.roomsInfoService.getImage(filteredSchedule[0].id, RoomData),
                    filteredSchedule[0].id,
                    filteredSchedule[0].isFavourite,
                    0,
                    this.getgridSize(),
                    '',
                    '',
                    filteredSchedule[0].themeid,
                    filteredSchedule[0].tournament_enabled,
                );
            }
        }
    }

    private emptySlotRacesData(): void {
        this.slotRaces = [];
        this.raceGamesMap.clear();
        this.liveRacesPublisher.next({ liveNow: [] });
        this.upcomingRacesPublisher.next({ upcomingToday: [], upcomingTomorrow: [] });
        if (this.pollingInterval) {
            clearInterval(this.pollingInterval);
        }
        if (this.schedulePolling) {
            clearInterval(this.schedulePolling);
        }
    }

    setSlotRaces(): void {
        const configs = this.configProviderService.provideBingoTournamentsClientConfig();
        if (this.isPrizesMapEmpty()) {
            if (
                !this.bingoHelperService.getConfigBasedOnInvokerProduct(configs.disablePrizesApiCall) ||
                (this.bingoHelperService.getConfigBasedOnInvokerProduct(configs.disablePrizesApiCall) && this.user.isAuthenticated)
            ) {
                this.getPrizes().subscribe();
            }
        }
        if (!this.timer) {
            this.globalTimerCountDown();
        }
    }

    getRaces(): void {
        if (this.isSlotRacesEnabled) {
            this.getLiveRaces();
            this.getUpcomingRaces();
        }
    }

    startLiveSlotPolling() {
        if (!this.liveSlotPollingStarted && this.pollForUser) {
            const configs = this.configProviderService.provideBingoTournamentsClientConfig();

            this.liveSlotPollingStarted = true;
            this.pollingInterval = this.timerService.setIntervalOutsideAngularZone(
                () => this.getLiveRaces(),
                this.bingoHelperService.getConfigBasedOnInvokerProduct(configs.racesListPollingInterval),
            );
        }
    }

    stopLiveSlotPolling() {
        this.liveSlotPollingStarted = false;
        this.timerService.clearInterval(this.pollingInterval);
    }

    private getSitecoreData(fetchNPMData: boolean): void {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        this.api.get(apiPathsConfig.bingoTournaments['racesContent'], { fetchNPMData: fetchNPMData }).subscribe((content: SitecoreContent) => {
            this.contentData = content;
            this.contentPublisher.next(content);
            if (
                content &&
                content.textTranslations &&
                content.textTranslations.sharedList &&
                content.textTranslations.sharedList.progressBarColor &&
                content.textTranslations.sharedList.progressBarColor != ''
            ) {
                const style = document.createElement('style');
                style.innerHTML =
                    '.sr-progress-bar-color.mat-accent .mat-slider-thumb,.mat-slider-thumb-label,.mat-slider-track-fill { background-color:' +
                    content.textTranslations.sharedList.progressBarColor +
                    ' !important } ';
                document.getElementsByTagName('head')[0].appendChild(style);
            }
        });
    }

    getPrizes(): Observable<any> {
        return this.getPrizesFromAPI().pipe(
            map((data: any) => {
                let prizesMap;
                if (data?.offers?.length > 0) {
                    prizesMap = this.createPrizesMap(data.offers);
                }
                if (prizesMap) {
                    this.promoPrizesMap = prizesMap;
                }
                return prizesMap;
            }),
            catchError((error: any) => {
                return error;
            }),
        );
    }

    private createPrizesMap(prizes: any): any {
        const promoPrizes: { [promoId: number]: any } = {};
        prizes = prizes.filter((prize: any) => prize && prize.promoId && prize.rankDetails && prize.rankDetails.length);
        for (let n = 0; n < prizes.length; n++) {
            if (!promoPrizes[prizes[n].promoId]) {
                const prizesArray: any = [];
                for (let i = 0; i < prizes[n].rankDetails.length; i++) {
                    prizesArray.push(prizes[n].rankDetails[i].configMaps);
                }

                if (prizes[n].beatBanker && prizes[n].beatBanker.length > 0) {
                    prizes[n].beatBanker.isBeatBanker = true;
                    prizesArray.push([prizes[n].beatBanker]);
                }
                promoPrizes[prizes[n].promoId] = prizesArray;
            }
        }
        return promoPrizes;
    }

    getPrizesForPromo(promoId: number): any {
        if (this.promoPrizesMap) {
            const prize = this.promoPrizesMap[promoId];
            if (prize && prize.length) {
                const currencyPrize = prize.find((p: any) => p?.currency && p?.currency?.length);
                if (currencyPrize) {
                    if (currencyPrize.currency === this.claims.get('currency')) {
                        return prize;
                    } else {
                        return []; // so that a downstream call is made to fetch latest data for prizes with new currency
                    }
                } else {
                    return prize; //return prize if it does not involve any prize with currency
                }
            }
        }
        return [];
    }

    private isPrizesMapEmpty(): boolean {
        for (const promo in this.promoPrizesMap) {
            if (this.promoPrizesMap.hasOwnProperty(promo)) {
                return false;
            }
        }

        return JSON.stringify(this.promoPrizesMap) === JSON.stringify({});
    }

    getLiveRaces(): void {
        const params: SlotApiParams = {
            slotInput: 'LIVE',
        };
        this.getRacesList(params);
    }

    getUpcomingRaces(): void {
        const configs = this.configProviderService.provideBingoTournamentsClientConfig();

        const params: SlotApiParams = {
            slotInput: 'UPCOMING',
            slotDuration: this.bingoHelperService.getConfigBasedOnInvokerProduct(configs.upcomingSlotsDuration),
        };
        this.getRacesList(params);
    }

    private getRacesList(params: any): void {
        const configs = this.configProviderService.provideBingoTournamentsClientConfig();
        if (
            !this.bingoHelperService.getConfigBasedOnInvokerProduct(configs.disablePreloginApiCalls) ||
            (this.bingoHelperService.getConfigBasedOnInvokerProduct(configs.disablePreloginApiCalls) && this.user.isAuthenticated)
        ) {
            this.getRacesFromAPI(params).subscribe((races: any) => {
                if (races) {
                    if (races.statusDetails && races.statusDetails.statusCode == 1016 && this.liveSlotPollingStarted) {
                        this.pollForUser = false;
                        this.stopLiveSlotPolling();
                    } else {
                        if (!this.pollForUser) {
                            this.pollForUser = true;
                        }
                    }
                    if (races.offers && races.offers.length > 0) {
                        this.makeDateConversions(races.offers, params.slotInput);
                    }
                }
            });
        }
    }

    private makeDateConversions(races: Race[], slotInput: string) {
        const finalRaces: Race[] = [];
        const now = Date.now();
        races.forEach((race: Race) => {
            const startDate = new Date(race.startTime); // as date coming from API is epoch UTC
            const endDate = new Date(race.endTime);
            const optinExpiryDate = new Date(race.optinExpiryTime);
            race.startDate = startDate;
            race.endDate = endDate;
            race.optinExpiryDate = optinExpiryDate;
            //adding following if-else block to eliminate older data showing up in response from pre-login cache
            if (slotInput === 'LIVE' && race.startDate.getTime() < now && race.endDate.getTime() > now) {
                race.startTimeAMPM = this.getAMPMTime(race.startDate);
                finalRaces.push(race);
            } else if (slotInput === 'UPCOMING' && race.startDate.getTime() > now) {
                race.startTimeAMPM = this.getAMPMTime(race.startDate);
                finalRaces.push(race);
            }
        });
        this.slotRaces = finalRaces;
        this.populateRacesObject();
    }

    getAMPMTime(date: Date): string {
        const features = this.configProviderService.provideBingoTournamentsClientConfig();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        if (this.bingoHelperService.getConfigBasedOnInvokerProduct(features.enable24HrTimeFormat)) {
            return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2);
        }
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const mnts = minutes < 10 ? '0' + minutes : minutes;
        const startTime = hours + ':' + mnts + ampm;
        return startTime;
    }

    showraceList(race: Race) {
        const scheduleFeedResult: any = this.session.get('ScheduleFeed');
        if (scheduleFeedResult && scheduleFeedResult.result && scheduleFeedResult.result.games_list) {
            const showRoomListItem = scheduleFeedResult.result.games_list.filter((roomlist: any) => {
                return roomlist.tournament_enabled && race.gameVariants.indexOf(roomlist.id) > -1;
            });
            if (showRoomListItem) return showRoomListItem;
            else return null;
        }
        return null;
    }

    private populateRacesObject(): void {
        if (this.slotRaces && this.slotRaces.length) {
            const tomorrow = new Date();
            tomorrow.setHours(24, 0, 0, 0);
            const dayAfter = new Date();
            dayAfter.setHours(48, 0, 0, 0);
            const now = Date.now();
            const liveSlots: SlotRacesLive = { liveNow: [] };
            const upcomingSlots: SlotRacesUpcoming = { upcomingToday: [], upcomingTomorrow: [] };
            this.slotRaces.every((race: Race) => {
                const showRoomListItem = this.showraceList(race);
                if (this.slotRaces.length > 0 && showRoomListItem && showRoomListItem.length > 0 && race.endDate.getTime() > now) {
                    race.gameId = showRoomListItem[0].name;
                    race.ballType = showRoomListItem[0]?.bingo_type;
                    if (race.startDate.getTime() <= now) {
                        race.isLive = true;
                        liveSlots.liveNow.push(race);
                        return true;
                    } else if (race.startDate < tomorrow) {
                        upcomingSlots.upcomingToday.push(race);
                        return true;
                    } else if (race.startDate < dayAfter) {
                        upcomingSlots.upcomingTomorrow.push(race);
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            });
            if (liveSlots && liveSlots.liveNow && liveSlots.liveNow.length) {
                if (
                    upcomingSlots &&
                    ((upcomingSlots.upcomingToday && upcomingSlots.upcomingToday.length) ||
                        (upcomingSlots.upcomingTomorrow && upcomingSlots.upcomingTomorrow.length))
                ) {
                    this.publishRaces(liveSlots, upcomingSlots);
                } else {
                    this.publishRaces(liveSlots, null);
                }
            } else {
                this.publishRaces(null, upcomingSlots);
            }
        }
    }
    getLeaderboard(promoId: any, slotId: any, maxRank: number = 0, fromResultsHistory: boolean): Observable<any> {
        return this.getLeaderboardFromAPI(promoId, slotId, maxRank, fromResultsHistory);
    }

    updateRacesArrays(): void {
        const upcomingRaces = this.upcomingRacesPublisher.value;
        const liveRaces = this.liveRacesPublisher.value;
        const now = Date.now();
        const newLiveRaces: Race[] = upcomingRaces.upcomingToday.filter((r) => r.startDate.getTime() <= now);
        newLiveRaces.forEach((race: Race) => {
            race.isLive = true;
            upcomingRaces.upcomingToday.splice(upcomingRaces.upcomingToday.indexOf(race), 1);
        });
        const endedRaces: Race[] = liveRaces.liveNow.filter((r) => r.endDate.getTime() <= now);
        endedRaces.forEach((race: Race) => {
            liveRaces.liveNow.splice(liveRaces.liveNow.indexOf(race), 1);
        });
        liveRaces.liveNow = liveRaces.liveNow.concat(newLiveRaces);

        this.publishRaces(liveRaces, upcomingRaces);
    }

    private publishRaces(liveRaces: SlotRacesLive | null, upcomingRaces: SlotRacesUpcoming | null): void {
        if (upcomingRaces) {
            if (upcomingRaces.upcomingToday && upcomingRaces.upcomingToday.length) {
                const timeoutInterval = upcomingRaces.upcomingToday[0].startDate.getTime() - Date.now();
                setTimeout(() => {
                    this.updateRacesArrays();
                }, timeoutInterval);
            } else if (upcomingRaces.upcomingTomorrow && upcomingRaces.upcomingTomorrow.length) {
                const timeoutInterval = upcomingRaces.upcomingTomorrow[0].startDate.getTime() - Date.now();
                setTimeout(() => {
                    this.updateRacesArrays();
                }, timeoutInterval);
            }
        }

        if (liveRaces && liveRaces.liveNow) {
            this.liveRacesPublisher.next(liveRaces);
        }
        if (upcomingRaces && (upcomingRaces.upcomingToday || upcomingRaces.upcomingTomorrow)) {
            this.upcomingRacesPublisher.next(upcomingRaces);
        }
    }
    //To Show Stickers on Game Tile
    slotRaceStickersData() {
        this.content.subscribe((content: SitecoreContent) => {
            this.contentData = content;
            this.messages = content && content.textTranslations ? content.textTranslations.versionedList : {};
            combineLatest(this.liveRacesData, this.upcomingRacesData).subscribe((races: any) => {
                const liveRaces = races[0] && races[0].liveNow && races[0].liveNow.slice(0, races[0].liveNow.length);
                const upcomingToday = races[1] && races[1].upcomingToday && races[1].upcomingToday.slice(0, races[1].upcomingToday.length);
                this.raceData(liveRaces, upcomingToday, this.contentData);
            });
        });
    }

    raceData(liveRaces: Race[], upcomingToday: Race[], contentData: any) {
        if (liveRaces.length > 0 || upcomingToday.length > 0) {
            for (let i = 0; i < upcomingToday.length; i++) {
                const corrLive = liveRaces.find((r) => r.promoId === upcomingToday[i].promoId);
                if (corrLive) {
                    if (corrLive.optinExpiryDate.getTime() < Date.now()) {
                        continue;
                    } else {
                        this.stickersDataMap(upcomingToday[i], contentData, 'upcomingRaces');
                    }
                } else {
                    this.stickersDataMap(upcomingToday[i], contentData, 'upcomingRaces');
                }
            }
            for (let i = 0; i < liveRaces.length; i++) {
                if (liveRaces[i].optinExpiryDate.getTime() < Date.now()) {
                    continue;
                } else {
                    this.stickersDataMap(liveRaces[i], contentData, 'liveRaces');
                }
            }
            if (this.stickerMap.size > 0) {
                this.stickerRacesPublisher.next(this.stickerMap);
            }
        }
    }
    stickersDataMap(raceItem: Race, contentData: any, races: string) {
        const configs = this.configProviderService.provideBingoTournamentsClientConfig();
        const milliseconds = raceItem.startDate.getTime() - Date.now();
        const seconds: number = Math.floor(milliseconds / 1000);
        const minutes: number = Math.floor(seconds / 60);
        if (races === 'upcomingRaces') {
            const upcomingSlotStickersDuration = this.bingoHelperService.getConfigBasedOnInvokerProduct(configs.upcomingSlotStickersDuration);
            if (minutes > upcomingSlotStickersDuration) {
                return;
            }
            if (minutes && minutes <= upcomingSlotStickersDuration) {
                this.getUpdatedSlotStickerDuration(raceItem, contentData);
            }
        } else if (races === 'liveRaces') {
            if (minutes > 30) {
                return;
            }
            if (minutes && minutes <= 30) {
                this.getUpdatedSlotStickerDuration(raceItem, contentData);
            }
        }
    }
    getUpdatedSlotStickerDuration(raceItem: Race, contentData: any) {
        this.messages = contentData && contentData.textTranslations ? contentData.textTranslations.versionedList : {};
        const configs = this.configProviderService.provideBingoTournamentsClientConfig();
        let gameName: string;
        let stickerText: string = '';
        const awardType: string = raceItem.additionalParameters.awardType;
        const awardTypeSticker: string = 'sticker_' + (awardType == null ? '' : awardType?.toLowerCase());
        const subType: string = raceItem.subType;
        const milliseconds = raceItem.startDate.getTime() - Date.now();
        const seconds: number = Math.floor(milliseconds / 1000);
        const minutes: number = Math.floor(seconds / 60);
        for (let i = 0; i < raceItem.gameVariants.length; i++) {
            gameName = raceItem.gameVariants[i];
            if (subType == 'CASINO_LEADERBOARD' && awardType) {
                stickerText = this.messages[awardTypeSticker];
            } else if (configs.enableStickersFreeToPlay && subType == 'FREE_TO_PLAY') {
                stickerText = this.messages.sticker_free_to_play;
            } else if (
                this.bingoHelperService.getConfigBasedOnInvokerProduct(configs.enableStickersFreePlayTech) &&
                subType?.toLowerCase() == 'free_play_tech'
            ) {
                stickerText = this.messages.sticker_free_play_tech;
            }
            const stickerItem = {
                minutes: minutes,
                optinExpiryDate: raceItem.optinExpiryDate,
                optinExpiryTime: raceItem.optinExpiryTime,
                endDate: raceItem.endDate,
                stickerText: [stickerText],
            };
            if (stickerText != undefined) {
                this.stickerMap.set(gameName, { data: stickerItem });
            }
        }
    }

    getResultsHistory(to: string, from: string): Observable<ResultsHistory[] | null | undefined> {
        return this.getResultsHistoryFromAPI(to, from).pipe(
            map((data: any) => {
                if (data && data.rankDetails && data.rankDetails.length > 0) {
                    const resultHistory = this.formatResults(data.rankDetails);
                    return resultHistory;
                }
                return null;
            }),
            catchError((error: any) => {
                return throwError(error);
            }),
        );
    }

    private formatResults(results: ResultsHistory[]) {
        let prizes: any;
        let beatBanker: any;
        results.forEach((result: ResultsHistory) => {
            //this.filterPromoGames(result);
            result.startDate = new Date(result.slotStartDate);
            result.endDate = new Date(result.slotEndDate);
            result.dateStr = result.startDate.getDate() + '-' + (result.startDate.getMonth() + 1) + '-' + result.startDate.getFullYear();
            if ((result.content && result.content.promotion && result.content.promotion.prizes) || result?.content?.promotion?.beatBanker) {
                if (result && result.configMaps && result.configMaps[0]?.awardType) {
                    result.isWin = true;
                } else {
                    result.isWin = false;
                }
                prizes = JSON.parse(result.content.promotion.prizes);
                if (result?.content?.promotion?.beatBanker) {
                    result.isBeatBanker = true;
                    beatBanker = JSON.parse(result.content.promotion.beatBanker);
                }
                prizes.forEach((prize: any) => {
                    if (result.rank >= Number(prize.FromRank) && result.rank < Number(prize.ToRank)) {
                        result.prizeIcon = prize.Icon;
                    }
                });
                beatBanker.forEach((banker: any) => {
                    if (result.configMaps && result?.configMaps[0]?.awardType && result?.configMaps[0]?.value) {
                        result.prizeIcon = banker.Icon;
                    }
                });
            }
        });
        return results;
    }

    // private buildGameIdBasedMetadataIndex(metadata: Array<any>): { [game: string]: object } {
    //     const indexedMetadata: { [game: string]: object } = {};
    //     if (metadata && metadata.length) {
    //         this.isGameMetadataAvailable = true;
    //         metadata
    //             .filter(item => item)
    //             .forEach(metadataItem => {
    //                 if (!indexedMetadata[metadataItem.game.trim()]) {
    //                     indexedMetadata[metadataItem.game.trim()] = metadataItem;
    //                 }
    //             });
    //         return indexedMetadata;
    //     }
    //     return indexedMetadata;
    // }

    private getRacesFromAPI(params: any): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.api.get(apiPathsConfig.bingoTournaments['racesList'], params, { withCredentials: true });
    }

    private getLeaderboardFromAPI(promoId: number, slotId: number, maxRank: number, fromResultsHistory: boolean): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.api.get(
            apiPathsConfig.bingoTournaments['racesLeaderboard'],
            { prmId: promoId, slotId: slotId, maxRank: maxRank, fromResultsHistory: fromResultsHistory },
            { withCredentials: true },
        );
    }

    private getResultsHistoryFromAPI(to?: string, from?: string): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.api.get(apiPathsConfig.bingoTournaments['racesResults'], { fromDate: from, endDate: to }, { withCredentials: true });
    }

    private getPrizesFromAPI(): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.api.get(apiPathsConfig.bingoTournaments['racesPrizes'], {}, { withCredentials: true });
    }

    updateOptinStatus(promoId: number, slotId: number, coinsForOptin: number): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.api.get(apiPathsConfig.bingoTournaments['racesOptin'], { prmId: promoId, slotId, coinsForOptin }, { withCredentials: true });
    }

    GetCoinBalnce(): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.api.get(apiPathsConfig.bingoTournaments['racesCoinBalance'], {}, { withCredentials: true });
    }

    updateNotificationStatus(promoId: number, slotId: number, notificationStatus: boolean): Observable<any> {
        const apiPathsConfig = this.configProviderService.provideApiPathsConfig();
        return this.api.get(
            apiPathsConfig.bingoTournaments['raceUpdateNotification'],
            { promoId, slotId, notificationStatus },
            { withCredentials: true },
        );
    }

    notificationPopupTimeout(): number {
        const config = this.configProviderService.provideBingoTournamentsClientConfig();
        return this.bingoHelperService.getConfigBasedOnInvokerProduct(config.notificationPopupTimeout);
    }

    addErrorMessage(errCode: number): void {
        this.messageQueue.clear();
        const msg = this.getErrorMessage(errCode);
        if (msg) {
            const message: Message = {
                html: msg,
                type: MessageType.Error,
                lifetime: MessageLifetime.Single,
                scope: 'casinocorelobby',
            };
            this.messageQueue.add(message);
            window.scrollTo(0, 0); //added so that the error message is visible to the user as vanilla scroll is not wokring properly here. Assumption agreed upon after discussion is that the error message panel would always be at the top of the lobby
        }
    }

    getErrorMessage(errCode: number): string | null {
        if (this.contentData && this.contentData.errorMessages && this.contentData.errorMessages.versionedList) {
            if (this.contentData.errorMessages.versionedList[errCode]) return this.contentData.errorMessages.versionedList[errCode];
            else return this.contentData.errorMessages.versionedList['defaultErrorCode'];
            //add this to sitecore
        }
        return null;
    }

    globalTimerCountDown(): void {
        this.timer = setInterval(() => {
            this.globalTimerPublisher.next({ timerInterval: this.globalTimerInterval });
        }, this.globalTimerInterval);
    }

    forceOptin() {
        const forceOptinRace = sessionStorage.getItem('ForceOptin');
        if (forceOptinRace) {
            const parsedForceOptinRace = (forceOptinRace && JSON.parse(forceOptinRace)) || {};
            sessionStorage.removeItem('ForceOptin');
            const promoId = parsedForceOptinRace.promoId;
            const slotId = parsedForceOptinRace.slotUniqueId;
            const coinsForOptin = parsedForceOptinRace.coinsForOptin;
            this.updateOptinStatus(promoId, slotId, coinsForOptin).subscribe(
                () => {
                    this.forcRacepublisher.next({ race: parsedForceOptinRace });
                },
                (error: SlotRacesResponseError) => {
                    this.addErrorMessage(error.errorCode);
                },
            );
        }
    }
    forceCoinBalance() {
        const forceCoinBalance = sessionStorage.getItem('forceCoinBalance');
        if (forceCoinBalance) {
            const parsedForceCoinBalance = (forceCoinBalance && JSON.parse(forceCoinBalance)) || {};
            sessionStorage.removeItem('forceCoinBalance');
            this.GetCoinBalnce().subscribe(
                (coinBal: any) => {
                    this.forceCoinBalancepublisher.next({ coinBal: coinBal, race: parsedForceCoinBalance });
                },
                (error: SlotRacesResponseError) => {
                    this.addErrorMessage(error.errorCode);
                },
            );
        }
    }

    getCurrentRaces(): any {
        const liveRaces = this.liveRacesPublisher.value;
        const upcomingRaces = this.upcomingRacesPublisher.value;
        const data = {
            liveNow: liveRaces.liveNow || [],
            upcomingToday: upcomingRaces.upcomingToday || [],
            upcomingTomorrow: upcomingRaces.upcomingTomorrow || [],
        };
        return data;
    }

    gameClose(): void {
        if (this.isSlotRacesEnabled) {
            const cardsCarousel = document.getElementById('casinotournamentscards');
            if (cardsCarousel) {
                this.startLiveSlotPolling();
                this.getRaces();
            }
        }
    }
    updateCoinIcon(value: boolean) {
        if (value) {
            this.isCoin = value;
        }
    }
    getcoinValue() {
        return this.isCoin;
    }

    selectedEntryFilter(entryFilter: any) {
        if (entryFilter) {
            this.selectedFilteredtOption = entryFilter;
        }
    }
    getselectedEntryFilter() {
        return this.selectedFilteredtOption;
    }

    getgridSize() {
        if (this.deviceService.isMobile) return 1;
        else if (this.deviceService.isTablet && window.innerWidth <= 768) return 3;
        else if (this.deviceService.isTablet) return 4;
        else if (window.innerWidth < 1201) return 4;
        else return 5;
    }

    bindCoinImg(race: any, coinImg: string) {
        const raceObj = {
            isDisplayCoin: false,
            coinSrc: '',
        };
        if (race) {
            if (race?.additionalParameters?.awardType !== 'COINS' && race?.additionalParameters?.isCoinAward === 'YES') {
                raceObj.isDisplayCoin = true;
                raceObj.coinSrc = coinImg;
            } else {
                raceObj.isDisplayCoin = false;
                raceObj.coinSrc = '';
            }
        }
        return raceObj;
    }
    getCoinDetails(): Observable<any> {
        return this.api.get('CoinAnimation/GetCoinDetails', {}, { responseType: 'json', withCredentials: true, showSpinner: false });
    }
    getActiveCategory() {
        const configs = this.configProviderService.provideBingoTournamentsClientConfig();
        return this.isBingoHomepage || this.bingoHelperService.getConfigBasedOnInvokerProduct(configs.enableBingoTournamentsAsWidget)
            ? 'home'
            : 'tournaments';
    }
}
