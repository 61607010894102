import { Injectable } from '@angular/core';

import { BingoPlatformApiService } from '../bingo-platform-api/services/bingo-platform-api.service';

@Injectable({
    providedIn: 'root',
})
export class BingoClientConfigsService {
    constructor(private api: BingoPlatformApiService) {}

    fetchClientConfigs() {
        this.api.get('BingoClientConfigs').subscribe((response: any) => {
            if (response) {
                this.setConfig(response);
            }
        });
    }

    setConfig(response: any) {
        localStorage.removeItem('config');
        localStorage.setItem('config', JSON.stringify(response));
    }
}
