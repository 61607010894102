<div
    id="casinotournamentscards"
    class="casino-wrapper-sr"
    [ngClass]="{
        'sr1-card-leaderboard': !enableBetterVisualization && showLeaderboard && races.length == 1,
        'sr2-card-leaderboard': !enableBetterVisualization && showLeaderboard && races.length == 2
    }">
    <div
        *ngIf="(!showCarousel && isSubCategory) || (!showCarousel && !enableBetterVisualization)"
        class="casino-sr-card-wrapper"
        [ngClass]="{ 'sr-card-leaderboard': showLeaderboard }">
        <div class="card-width" *ngFor="let race of races; index as pos; trackBy: raceAdded">
            <cc-slot-race-card
                class="cc-slot-race-card"
                [race]="race"
                [category]="category"
                [showRaceInfo]="true"
                [lobbyType]="lobbyType"
                [showCardMenu]="false"
                (openRaceInfo)="openDialog($event, 'race-info')"
                [showPlaceholderCard]="showPlaceholderCard"
                (openPickGameOverlay)="openDialog($event, 'pick-game')"
                (openNotificationOverlay)="openDialog($event, 'notification')"
                [targetedPlayerCount]="race.targetedPlayerCount"
                [enableOptin]="enableUpcomingOptinForPlaytech && race.firstPlayTech && !isFreeplaytechinLive"
                [coinImg]="coinImg"
                [selectedOption]="selectedOption">
            </cc-slot-race-card>
            <div *ngIf="showLeaderboard && race.rankDetails && race.rankDetails.length > 0" class="casino-subpage-leaderboard">
                <cc-slot-races-leaderboard
                    [race]="race"
                    [showTopThree]="true"
                    [lobbyType]="lobbyType"
                    [coinImg]="coinImg"></cc-slot-races-leaderboard>
                <div class="See-full-leaderboard" (click)="showFullLeaderboard(race)">
                    {{ messages.SeeLeaderboard }}
                </div>
            </div>
        </div>
        <div *ngIf="showPlaceholderCard">
            <div class="casino-sr-card-widget">
                <img alt="game-name1" src="{{ content?.placeholderCardImage?.image?.src }}" draggable="false" />
                <div class="casino-new-races-soon">
                    <div class="casino-new-races-soon-icon theme-slots-race-coming-soon"></div>
                    <div class="casino-new-races-soon-title">{{ messages.SoonToBeAnnounced }}</div>
                </div>

                <div class="sr-card-container-bottom sr-card-placeholder">
                    <div class="btn-section" (click)="gotoSlotRaces()">
                        <!-- <div class="btn btn-primary sr-playbtn">
                            {{ messages.SeeAllRaces }}
                        </div> -->
                        <cs-slotrace-cta [isLegacy]="isLegacy" [kind]="'primary'" [cta_text]="messages.SeeAllRaces" [csDecoratorClass]="'sr-playbtn'">
                        </cs-slotrace-cta>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        *ngIf="showCarousel && (isSubCategory || !enableBetterVisualization)"
        class="casino-sr-card-wrapper-non-touch"
        [ngClass]="{ 'sr-card-leaderboard': showLeaderboard }">
        <vn-swiper *ngIf="races && races.length" [swiperOptions]="swiperOptions">
            <div class="swiper-slide" *ngFor="let race of races; let pos = index; trackBy: raceAddedDesktop">
                <div *ngIf="(showPlaceholderCard && pos != races.length - 1) || !showPlaceholderCard">
                    <cc-slot-race-card
                        class="cc-slot-race-card"
                        [race]="race"
                        [category]="category"
                        [showRaceInfo]="true"
                        [lobbyType]="lobbyType"
                        [showCardMenu]="false"
                        (openRaceInfo)="openDialog($event, 'race-info')"
                        (openPickGameOverlay)="openDialog($event, 'pick-game')"
                        (openNotificationOverlay)="openDialog($event, 'notification')"
                        [showPlaceholderCard]="showPlaceholderCard"
                        [position]="pos"
                        [targetedPlayerCount]="race.targetedPlayerCount"
                        [enableOptin]="enableUpcomingOptinForPlaytech && race.firstPlayTech && !isFreeplaytechinLive"
                        [coinImg]="coinImg"
                        [selectedOption]="selectedOption">
                    </cc-slot-race-card>
                    <div *ngIf="showLeaderboard && race.rankDetails && race.rankDetails.length > 0" class="casino-subpage-leaderboard">
                        <cc-slot-races-leaderboard [race]="race" [showTopThree]="true" [lobbyType]="lobbyType" [coinImg]="coinImg">
                        </cc-slot-races-leaderboard>
                        <div class="See-full-leaderboard" (click)="showFullLeaderboard(race)">
                            {{ messages.SeeLeaderboard }}
                        </div>
                    </div>
                </div>

                <div *ngIf="showPlaceholderCard && pos == races.length - 1" class="casino-sr-card-widget">
                    <img alt="game-name1" src="{{ content?.placeholderCardImage?.image?.src }}" draggable="false" />
                    <div class="casino-new-races-soon">
                        <div class="casino-new-races-soon-icon theme-slots-race-coming-soon"></div>
                        <div class="casino-new-races-soon-title">{{ messages.SoonToBeAnnounced }}</div>
                    </div>
                    <div class="sr-card-container-bottom sr-card-placeholder">
                        <div class="btn-section" (click)="gotoSlotRaces()">
                            <!-- <div class="btn btn-primary sr-playbtn">
                                {{ messages.SeeAllRaces }}
                            </div> -->
                            <cs-slotrace-cta
                                [isLegacy]="isLegacy"
                                [kind]="'primary'"
                                [cta_text]="messages.SeeAllRaces"
                                [csDecoratorClass]="'sr-playbtn'">
                            </cs-slotrace-cta>
                        </div>
                    </div>
                </div>
            </div>
        </vn-swiper>
        <div *ngIf="showPlaceholderCard && races.length == 0" class="casino-sr-card-widget">
            <img alt="game-name1" src="{{ content?.placeholderCardImage?.image?.src }}" draggable="false" />
            <div class="casino-new-races-soon">
                <div class="casino-new-races-soon-icon theme-slots-race-coming-soon"></div>
                <div class="casino-new-races-soon-title">{{ messages.SoonToBeAnnounced }}</div>
            </div>
            <div class="sr-card-container-bottom sr-card-placeholder">
                <div class="btn-section" (click)="gotoSlotRaces()">
                    <!-- <div class="btn btn-primary sr-playbtn">
                        {{ messages.SeeAllRaces }}
                    </div> -->
                    <cs-slotrace-cta [isLegacy]="isLegacy" [kind]="'primary'" [cta_text]="messages.SeeAllRaces" [csDecoratorClass]="'sr-playbtn'">
                    </cs-slotrace-cta>
                </div>
            </div>
        </div>
    </div>
    <div
        *ngIf="!showCarousel && !isSubCategory && enableBetterVisualization"
        class="casino-sr-card-wrapper mobile"
        [ngClass]="{ 'sr-card-leaderboard': showLeaderboard }">
        <div class="card-width" *ngFor="let race of races; index as pos; trackBy: raceAdded; let f = first">
            <cc-slot-race-card
                [race]="race"
                [category]="category"
                [showRaceInfo]="true"
                [lobbyType]="lobbyType"
                [showCardMenu]="false"
                (openRaceInfo)="openDialog($event, 'race-info')"
                [showPlaceholderCard]="showPlaceholderCard"
                (openPickGameOverlay)="openDialog($event, 'pick-game')"
                [firstItem]="f"
                [targetedPlayerCount]="race.targetedPlayerCount"
                [enableOptin]="enableUpcomingOptinForPlaytech && race.firstPlayTech && !isFreeplaytechinLive"
                [coinImg]="coinImg"
                [selectedOption]="selectedOption">
            </cc-slot-race-card>
        </div>
        <div *ngIf="showPlaceholderCard">
            <div class="casino-sr-card-widget">
                <img alt="game-name1" src="{{ content?.placeholderCardImage?.image?.src }}" draggable="false" />
                <div class="casino-new-races-soon">
                    <div class="casino-new-races-soon-icon theme-slots-race-coming-soon"></div>
                    <div class="casino-new-races-soon-title">{{ messages.SoonToBeAnnounced }}</div>
                </div>

                <div class="sr-card-container-bottom sr-card-placeholder">
                    <div class="btn-section" (click)="gotoSlotRaces()">
                        <!-- <div class="btn btn-primary sr-playbtn">
                            {{ messages.SeeAllRaces }}
                        </div> -->
                        <cs-slotrace-cta [isLegacy]="isLegacy" [kind]="'primary'" [cta_text]="messages.SeeAllRaces" [csDecoratorClass]="'sr-playbtn'">
                        </cs-slotrace-cta>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        *ngIf="showCarousel && !isSubCategory && enableBetterVisualization"
        class="casino-sr-card-wrapper-non-touch"
        [ngClass]="{ 'sr-card-leaderboard': showLeaderboard, 'sr-card-odd': races.length % 2 !== 0 }">
        <div *ngFor="let race of races; index as pos; trackBy: raceAddedDesktop" [attr.data-index]="pos" class="sr-new-cards">
            <div *ngIf="(showPlaceholderCard && pos != races.length - 1) || !showPlaceholderCard">
                <cc-slot-race-card
                    [race]="race"
                    [category]="category"
                    [showRaceInfo]="true"
                    [lobbyType]="lobbyType"
                    [showCardMenu]="false"
                    (openRaceInfo)="openDialog($event, 'race-info')"
                    (openPickGameOverlay)="openDialog($event, 'pick-game')"
                    [showPlaceholderCard]="showPlaceholderCard"
                    [position]="pos"
                    [targetedPlayerCount]="race.targetedPlayerCount"
                    [enableOptin]="enableUpcomingOptinForPlaytech && race.firstPlayTech && !isFreeplaytechinLive"
                    [coinImg]="coinImg"
                    [selectedOption]="selectedOption">
                </cc-slot-race-card>
            </div>
            <div *ngIf="showPlaceholderCard && pos == races.length - 1" class="casino-sr-card-widget">
                <img alt="game-name1" src="{{ content?.placeholderCardImage?.image?.src }}" draggable="false" />
                <div class="casino-new-races-soon">
                    <div class="casino-new-races-soon-icon theme-slots-race-coming-soon"></div>
                    <div class="casino-new-races-soon-title">{{ messages.SoonToBeAnnounced }}</div>
                </div>
                <div class="sr-card-container-bottom sr-card-placeholder">
                    <div class="btn-section" (click)="gotoSlotRaces()">
                        <!-- <div class="btn btn-primary sr-playbtn">
                            {{ messages.SeeAllRaces }}
                        </div> -->
                        <cs-slotrace-cta [isLegacy]="isLegacy" [kind]="'primary'" [cta_text]="messages.SeeAllRaces" [csDecoratorClass]="'sr-playbtn'">
                        </cs-slotrace-cta>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showPlaceholderCard && races.length == 0" class="casino-sr-card-widget">
            <img alt="game-name1" src="{{ content?.placeholderCardImage?.image?.src }}" draggable="false" />
            <div class="casino-new-races-soon">
                <div class="casino-new-races-soon-icon theme-slots-race-coming-soon"></div>
                <div class="casino-new-races-soon-title">{{ messages.SoonToBeAnnounced }}</div>
            </div>
            <div class="sr-card-container-bottom sr-card-placeholder">
                <div class="btn-section" (click)="gotoSlotRaces()">
                    <!-- <div class="btn btn-primary sr-playbtn">
                        {{ messages.SeeAllRaces }}
                    </div> -->
                    <cs-slotrace-cta [isLegacy]="isLegacy" [kind]="'primary'" [cta_text]="messages.SeeAllRaces" [csDecoratorClass]="'sr-playbtn'">
                    </cs-slotrace-cta>
                </div>
            </div>
        </div>
    </div>
</div>
