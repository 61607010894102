import { NgFor } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, computed } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';

import {
    CacheManagerService,
    CasinoCoreGamelaunchService,
    CasinoManager,
    ConfigProviderService,
    GameLaunch,
    Race,
    SitecoreContent,
    SlotRacesService,
} from '@casinocore/platform/core';
import { DeviceService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

import { SlotRaceCardComponent } from '../cards-wrapper/card/card.component';
import { TournamentIconComponent } from '../tournament-icon/tournament-icon.component';

@Component({
    selector: 'cc-slot-races-pick-game',
    templateUrl: 'pick-game-overlay.component.html',
    standalone: true,
    imports: [SlotRaceCardComponent, MatGridListModule, NgFor, TournamentIconComponent],
})
export class PickGameOverlayComponent implements OnInit, OnDestroy {
    race: Race;
    isLive: boolean;
    isUpcoming: boolean;
    lobbyType: string;
    category: any;
    raceinfoopt: any;
    messages: { [item: string]: string } = {};
    contentSubscription: Subscription;
    liveRacesSubscription: Subscription;
    activeCategory: string;
    isMobileView: boolean;
    coinImg: string;

    readonly breakpoint = computed(() => {
        return this.deviceService.isMobileWidth() ? 3 : 4;
    });

    constructor(
        private dialogRef: MatDialogRef<PickGameOverlayComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private slotRacesService: SlotRacesService,
        private gameLaunchService: CasinoCoreGamelaunchService,
        private casinoManager: CasinoManager,
        private configProviderService: ConfigProviderService,
        private deviceService: DeviceService,
        private cacheManager: CacheManagerService,
    ) {
        this.race = data.race;
        this.isLive = !!this.race.isLive;
        this.isUpcoming = !this.isLive;
        this.category = data.category;
        this.lobbyType = data.lobbyType;
        this.raceinfoopt = data.enableOptin;
        this.coinImg = data.coinImg;
    }

    ngOnInit() {
        this.activeCategory = this.category && this.category.categoryInfo && this.category.categoryInfo.categoryid;
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.messages = content && content.textTranslations ? content.textTranslations.versionedList : {};
        });
        this.liveRacesSubscription = this.slotRacesService.liveRacesData.subscribe((data) => {
            if (data) {
                this.updateRaceStatus(data);
            }
        });
    }

    close(dialogResult: any = {}) {
        this.dialogRef.close(dialogResult);
    }

    openRaceInfoDialog(): void {
        this.close({ openRaceInfo: true });
    }
    openNotificationDialog() {
        this.close({ openNotificationOverlay: true });
    }
    launchGame(event: any) {
        let containerLocation: string = 'Overlay|Slot Races';
        let containerDescription: string = 'Highlighted|Image';
        let game = this.cacheManager.getGameToStickersMap();
        let gameSticker = game[event.game];
        let isNewGame = false;
        if (gameSticker && gameSticker.indexOf('New') > -1) {
            isNewGame = true;
        }
        let gameData = {
            gameId: event.game,
            gameVariantName: event.game,
            gameContainerDescription: containerDescription,
            gameContainerLocation: containerLocation,
            isSignPostedGame: isNewGame,
            lobbyType: this.lobbyType,
        };
        let launchModel = new GameLaunch();
        launchModel.game = gameData;
        launchModel.isTouch = this.casinoManager.isTouch();
        launchModel.lobbyType = this.lobbyType;
        launchModel.gamePosition = event.position;
        launchModel.categoryType = this.activeCategory;
        launchModel.gameLaunchedFrom = 'slotraces';
        launchModel.gameSection = 'slotraces';
        launchModel.returnUrl = window.location.href;
        launchModel.clickTimeStamp = this.gameLaunchService.currentTimeStamp();
        launchModel.promoId = this.race?.promoId;
        launchModel.slotUniqueId = this.race?.slotUniqueId;
        this.gameLaunchService.launchGame(launchModel);
    }

    updateRaceStatus(data: any) {
        let liveRaces: any[];
        liveRaces = data.liveNow;
        let isLiveRace = liveRaces.some((race: Race) => {
            if (race.promoId == this.race.promoId && race.slotUniqueId == this.race.slotUniqueId) {
                this.race.isLive = true;
            }
            return this.race.isLive;
        });
        let isUpcomingRace = !isLiveRace;
        if (this.isLive !== isLiveRace || this.isUpcoming !== isUpcomingRace) {
            this.close();
        }
    }

    ngOnDestroy() {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
        if (this.liveRacesSubscription) {
            this.liveRacesSubscription.unsubscribe();
        }
    }
    ngAfterViewInit() {
        let featuresConfig = this.configProviderService.provideFeaturesConfig();
        if (featuresConfig.enableOverlayMobileSearchTopPosition && this.deviceService.isTouch) {
            this.slotRaceGameOverlayTopAllignment();
        }
    }
    slotRaceGameOverlayTopAllignment() {
        if (document.querySelector('.content-messages-header-top .content-message-container')) {
            let contentMessageElement: HTMLElement = document.querySelector('.content-messages-header-top .content-message-container')!;
            let sr_overlay: HTMLElement = document.querySelector('.casino-slot-races-pick-game casino-race-info-container')!;
            sr_overlay.style.top = contentMessageElement.clientHeight + 'px';
        }
    }
}
