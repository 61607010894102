import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ballfilter',
})
export class BallFilterPipe implements PipeTransform {
    transform(items: Array<any>, bingo_type: number): Array<any> {
        if (bingo_type) {
            if (items != null || items != undefined) {
                const filteredResults = items.filter((item) => item.bingo_type === String(bingo_type)).length;
                if (filteredResults > 0) {
                    return items.filter((item) => item.bingo_type === String(bingo_type));
                } else {
                    return [];
                }
            }
        } else {
            return items;
        }
        return [];
    }
}
