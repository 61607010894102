import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { BonusSupressionPopupComponent } from './bonus-supression-popup.component';

@NgModule({
    declarations: [BonusSupressionPopupComponent],
    exports: [BonusSupressionPopupComponent],
    imports: [CommonModule],
})
export class BonusSupressionPopupModule {
    public static forRoot(): ModuleWithProviders<BonusSupressionPopupModule> {
        return {
            ngModule: BonusSupressionPopupModule,
        };
    }
}
