import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: featuresClientConfigFactory,
})
@LazyClientConfig({ key: 'bgFeatureConfig', product: ClientConfigProductName.BINGO })
export class FeaturesClientConfig {
    enableRoomsGrouping: boolean;
    EnableDesktopGameLaunch: boolean;
    HekatonCache: boolean;
    ShowJackpotOnGameTiles: boolean;
    RecentlyPlayedGames: string;
    UseLMTFeed: boolean;
    UseDisabledGames: string;
    EnableMultiGameView: string;
    EGVFavourites: boolean;
    BingoFavourites: boolean;
    isFavouriteEnabled: boolean;
    isPreBuyEnabled: boolean;
    showIconImage: boolean;
    isRoomTabEnabled: boolean;
    casinoPane: boolean;
    isFilterSortEnabled: boolean;
    showCasinoPane: boolean;
    enableAccountUpgrade: boolean;
    showRoomTilesOnly: boolean;
    enableSubNavigation: boolean;
    enablePrebuyDate: boolean;
    enableCasinoWidget: boolean;
    enableV6Navigation: boolean;
    enableCasinoRPWidget: boolean;
    enablePlayBingoButtonOnTeasers: boolean;
    enableScreenNameCheck: boolean;
    enableProgressBar: boolean;
    progressbarTime: number;
    enableOptimove: boolean;
    enablePerformanceMarking: boolean;
    enableFreeBingoTickets: boolean;
    enableGameLaunchinPortalIframe: boolean;
    bingoPinningRoom: boolean;
    enableRTMSSubscription: boolean;
    disableUserValidationCheck: boolean;
    sessionDays: number;
    dateFormat: string;
    displayTimerInClient: boolean;
    timeMilliseconds: any;
    enableScreenNameForPrebuy: boolean;
    enableNewCozyLobby: boolean;
    grayedOutConvertion: number;
    preBuyTimeDuration: number;
    isEnablePrebuyBonusDetails: boolean;
    //preBuyBonusDetailsLink: any;
    preBuyBonusDetailsSetInterval: any;
    bingoWidgetGridCount: number;
    enablePrivateRooms: boolean;
    enableTeasersInRooms: boolean;
    enableNewPrebuySliderIcons: boolean;
    enableLastLoginTimeCheck: boolean;
    timeOutForRoomsFilter: number;
    enableScrollForRoomFilters: boolean;
    disableTimeSessions: boolean;
    timeOutForPostMessage: number;
    disableUnnecessaryCalls: boolean;
    isEnableFullTakeOverStaticImage: boolean;
    enable2By1RoomTile: boolean;
    displayTeasersAboveNav: boolean;
    enableHomeTeasers: boolean;
    showVerticalRoomsInHomePage: boolean;
    enableVanillaLoginDuration: boolean;
    friendsBingoCopyRightToggle: boolean;
    sessionBingoMaxPurchaseEnable: boolean;
    navigationBarTimeFrame: number;
    enableBonusImplementation: boolean;
    enableBallTypeImages: boolean;
    enableChannelInLaunchURL: boolean;
    displaySeeAllOnLobby: boolean;
    enableNavigationInIframe: boolean;
    enableClickInteraction: boolean;
    enableStickerSchedule: boolean;
    playerCount: number;
    potPlayerCurrencyDigits: number;
    isEnablePromotionsBanner: boolean;
    showPromotionsCount: boolean;
    disableAllPrebuy: boolean;
    ticketIconTimeout: number;
    enableInfoIconOnRooms: boolean;
    featureIconImgPaths: any;
    activateDesignSystem: any;
}

export function featuresClientConfigFactory(service: LazyClientConfigService) {
    return service.get(FeaturesClientConfig);
}
