import { Injectable } from '@angular/core';

import { BingoPlatformApiService } from '@frontend/bingo/core';
import { Observable } from 'rxjs';

import { RootServicesModule } from '../../shared/root-services.module';

@Injectable({
    providedIn: RootServicesModule,
})
export class MyTicketsResourceService {
    constructor(private api: BingoPlatformApiService) {}

    getTickets(): Observable<any> {
        return this.api.get('MyTickets');
    }
}
