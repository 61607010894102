import { Pipe, PipeTransform } from '@angular/core';

/*how this pipe can be used:

<div  *ngFor="let result of results | resultsType:showAll">hello</div>

*/

@Pipe({ name: 'timeformat' })
export class TimeFormatPipe implements PipeTransform {
    transform(timespan: number): any {
        if (timespan > 1000) {
            const seconds = Math.floor(timespan / 1000),
                minutes = Math.floor(seconds / 60),
                hours = Math.floor(minutes / 60);
            return `${('0' + hours).slice(-2)}:${('0' + (minutes % 60)).slice(-2)}:${('0' + (seconds % 60)).slice(-2)}`;
        } else {
            return '00:00:00';
        }
    }
}
