import { Pipe, PipeTransform } from '@angular/core';

/*how this pipe can be used:

<div  *ngFor="let result of results | resultsType:showAll">hello</div>

*/

@Pipe({ name: 'resultsType' })
export class ResultsPipe implements PipeTransform {
    transform(results: any, showAll: boolean): any {
        if (showAll) {
            return results;
        } else {
            return results.filter((item: any) => {
                return item.isWin;
            });
        }
    }
}
