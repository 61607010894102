<div
    *ngIf="race || !hidepoint"
    class="casino-rules-and-tc"
    [ngClass]="{ 'casino-point-carousel': showPointsV2, 'points-panel-closed': !panelOpenState }">
    <mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        hideToggle="true"
        [expanded]="doExpanded"
        [ngClass]="this.isExpansionDisabled">
        <mat-expansion-panel-header>
            <mat-panel-title class="panel-title">
                {{ sitecoreData?.textTranslations?.versionedList?.Points }}
            </mat-panel-title>
            <mat-panel-description *ngIf="isCollapsible">
                <mat-icon *ngIf="!panelOpenState">
                    <span class="theme-plus"></span>
                </mat-icon>
                <mat-icon *ngIf="panelOpenState">
                    <span class="theme-minus"></span>
                </mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div
            *ngIf="!showPointsV2"
            class="dropdown-text"
            id="points-dropdown"
            [style.color]="pointsContentColor"
            [ngClass]="{ 'sr1-points': pointsdatalength == 1, 'sr2-points': pointsdatalength == 2, 'sr3-points': pointsdatalength == 3 }">
            <div class="explain-div">{{ this.sitecoreData?.textTranslations?.versionedList?.Pointstext }}</div>
            <div class="points-panel-data" *ngFor="let ruleInfo of pointsData; let lastItem = last">
                <div class="total-points">
                    <div class="points-image-n-text">
                        <div class="background-image-section" [ngStyle]="{ 'background-image': 'url(' + ruleInfo?.pointsImage?.src + ')' }">
                            <div class="total-points-value">
                                {{ ruleInfo?.pointValue }}
                                <span class="total-points-text">
                                    {{ ruleInfo?.pointsText }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="total-points-text2">
                        {{ ruleInfo?.pointsDescription }}
                    </div>
                </div>
                <div class="seperator-class" *ngIf="!lastItem"></div>
            </div>
        </div>
        <!--Multiple Trigger Points-->
        <div *ngIf="showPointsV2">
            <div class="main-title">{{ sitecoreData?.textTranslations?.versionedList?.PointsDescriptionTitleV2 }}</div>
            <div class="main-discription">
                {{ sitecoreData?.textTranslations?.versionedList?.PointsDescriptionV2 }}
                <span style="text-decoration: underline; cursor: pointer" (click)="showTnC()">{{
                    sitecoreData?.textTranslations?.versionedList?.TnCApply
                }}</span>
            </div>
            <div class="casino-point-carousel-container" [ngClass]="{ 'mobile-mode': !showCarousel, 'one-stresk': pointsData.length == 1 }">
                <div *ngIf="!showCarousel" class="innerscrolable">
                    <div
                        *ngFor="let ruleInfo of pointsData"
                        class="points-new"
                        [ngClass]="{
                            'point-typetwo': ruleInfo.slabList.length > 4,
                            'points-counts-one': ruleInfo.slabList.length == 1,
                            'points-counts-two': ruleInfo.slabList.length == 2,
                            'points-counts-three': ruleInfo.slabList.length == 3,
                            'points-counts-four': ruleInfo.slabList.length == 4,
                            'points-counts-five': ruleInfo.slabList.length == 5,
                            'points-counts-six': ruleInfo.slabList.length == 6,
                            'points-counts-seven': ruleInfo.slabList.length == 7,
                            'points-counts-eight': ruleInfo.slabList.length == 8,
                            'points-counts-nine': ruleInfo.slabList.length == 9,
                            'points-counts-ten': ruleInfo.slabList.length == 10
                        }">
                        <div class="sub-title">{{ ruleInfo?.ruleTitle }}</div>
                        <div class="sub-discription">
                            {{ ruleInfo?.ruleDescription }}
                            <span style="text-decoration: underline; cursor: pointer" (click)="showTnC()">{{
                                sitecoreData?.textTranslations?.versionedList?.MoreOnTnC
                            }}</span>
                        </div>
                        <div class="total-points-new" *ngFor="let slab of ruleInfo.slabList">
                            <div class="background-image-section" [ngStyle]="{ 'background-image': 'url(' + ruleInfo?.pointsImage?.src + ')' }">
                                <div *ngIf="!(ruleInfo.slabList.length > 4)">
                                    <div class="typetwo-points">
                                        <div class="type-one-linetwo">
                                            <span class="pt-value">{{ slab.points }}</span>
                                            <span class="points-lh">{{ slab.points > 1 ? ruleInfo.pointMult : ruleInfo.pointSingle }}</span>
                                        </div>
                                        <div class="type-one">
                                            <span *ngIf="ruleInfo.ruleType !== 'WIN_X_TIMES_ON_BET'">{{ slab.ruleConfig }}&nbsp;</span>
                                            <span class="total-points-text">{{ slab.ruleConfig > 1 ? ruleInfo.descMult : ruleInfo.descSingle }}</span>
                                            <span *ngIf="ruleInfo.ruleType == 'WIN_X_TIMES_ON_BET'">{{ slab.ruleConfig }}</span>
                                        </div>
                                        <span *ngIf="ruleInfo.slabList.length > 4"> =&nbsp;</span>
                                    </div>
                                </div>
                                <div *ngIf="ruleInfo.slabList.length > 4">
                                    <div class="typetwo-points">
                                        <div class="type-one">
                                            <span *ngIf="ruleInfo.ruleType !== 'WIN_X_TIMES_ON_BET'">{{ slab.ruleConfig }}&nbsp;</span>
                                            <span class="total-points-text">{{ slab.ruleConfig > 1 ? ruleInfo.descMult : ruleInfo.descSingle }}</span>
                                            <span *ngIf="ruleInfo.ruleType == 'WIN_X_TIMES_ON_BET'">{{ slab.ruleConfig }}</span>
                                        </div>
                                        <span *ngIf="ruleInfo.slabList.length > 4"> =&nbsp;</span>
                                        <div class="type-one-linetwo">
                                            <span class="pt-value">{{ slab.points }}</span>
                                            {{ slab.points > 1 ? ruleInfo.pointMult : ruleInfo.pointSingle }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <vn-swiper *ngIf="showCarousel" [swiperOptions]="swiperOptions">
                    <div class="swiper-slide" *ngFor="let ruleInfo of pointsData">
                        <div
                            class="points-new"
                            [ngClass]="{
                                'point-typetwo': ruleInfo.slabList.length > 4,
                                'points-counts-one': ruleInfo.slabList.length == 1,
                                'points-counts-two': ruleInfo.slabList.length == 2,
                                'points-counts-three': ruleInfo.slabList.length == 3,
                                'points-counts-four': ruleInfo.slabList.length == 4,
                                'points-counts-five': ruleInfo.slabList.length == 5,
                                'points-counts-six': ruleInfo.slabList.length == 6,
                                'points-counts-seven': ruleInfo.slabList.length == 7,
                                'points-counts-eight': ruleInfo.slabList.length == 8
                            }">
                            <div class="sub-title">{{ ruleInfo.ruleTitle }}</div>
                            <div class="sub-discription">
                                {{ ruleInfo.ruleDescription }}
                                <span style="text-decoration: underline; cursor: pointer" (click)="showTnC()">{{
                                    sitecoreData?.textTranslations?.versionedList?.MoreOnTnC
                                }}</span>
                            </div>
                            <div class="total-points-new" *ngFor="let slab of ruleInfo.slabList">
                                <div class="background-image-section" [ngStyle]="{ 'background-image': 'url(' + ruleInfo.pointsImage.src + ')' }">
                                    <div *ngIf="!(ruleInfo.slabList.length > 4)">
                                        <div class="typetwo-points">
                                            <div class="type-one-linetwo">
                                                <span class="pt-value">{{ slab.points }}</span>
                                                <span class="points-lh">{{ slab.points > 1 ? ruleInfo.pointMult : ruleInfo.pointSingle }}</span>
                                            </div>
                                            <div class="type-one">
                                                <span *ngIf="ruleInfo.ruleType !== 'WIN_X_TIMES_ON_BET'">{{ slab.ruleConfig }}&nbsp;</span>
                                                <span class="total-points-text">{{
                                                    slab.ruleConfig > 1 ? ruleInfo.descMult : ruleInfo.descSingle
                                                }}</span>
                                                <span *ngIf="ruleInfo.ruleType == 'WIN_X_TIMES_ON_BET'">{{ slab.ruleConfig }}</span>
                                            </div>
                                            <span *ngIf="ruleInfo.slabList.length > 4"> =&nbsp; </span>
                                        </div>
                                    </div>
                                    <div *ngIf="ruleInfo.slabList.length > 4">
                                        <div class="typetwo-points">
                                            <div class="type-one">
                                                <span *ngIf="ruleInfo.ruleType !== 'WIN_X_TIMES_ON_BET'">{{ slab.ruleConfig }}&nbsp;</span>
                                                <span class="total-points-text">{{
                                                    slab.ruleConfig > 1 ? ruleInfo.descMult : ruleInfo.descSingle
                                                }}</span>
                                                <span *ngIf="ruleInfo.ruleType == 'WIN_X_TIMES_ON_BET'">{{ slab.ruleConfig }}</span>
                                            </div>
                                            <span *ngIf="ruleInfo.slabList.length > 4"> =&nbsp; </span>
                                            <div class="type-one-linetwo">
                                                <span class="pt-value">{{ slab.points }}</span>
                                                {{ slab.points > 1 ? ruleInfo.pointMult : ruleInfo.pointSingle }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </vn-swiper>
            </div>
        </div>
        <!--End Multiple Trigger Points-->
    </mat-expansion-panel>
</div>
