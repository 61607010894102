<cc-news-feed-v2 [attributes]="newsFeedAttributes">
    <ng-container *ngFor="let arr of fillingArray">
        <div
            class="news-feed-v2-wrapper"
            (touchcancel)="touchReleased()"
            (touchstart)="touchStarted()"
            (touchend)="touchReleased()"
            [ngClass]="{ 'touch-on-items': !triggerForMobileDevices }">
            <cc-news-feed-v2-item *ngFor="let item of newsFeeedItems | dsl | async; let i = index">
                <cc-news-feed-v2-custom-item
                    [item]="item"
                    [index]="i"
                    [lobbyType]="lobbyType"
                    [attributes]="newsFeedAttributes"
                    [data]="data"></cc-news-feed-v2-custom-item>
            </cc-news-feed-v2-item>
        </div>
    </ng-container>
</cc-news-feed-v2>
