import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { BingoTournamentsService, BingoTournamentsTrackingService, Race, SitecoreContent } from '@frontend/bingo/core';

@Component({
    selector: 'bgl-slot-races-item',
    templateUrl: 'bingo-timeline-item.component.html',
})
export class BingoSlotRacesTimelineItemComponent implements OnInit, OnChanges {
    @Input() race: Race;
    @Input() selectedOption: string;
    @Input() coinImg: string;
    @Input() content: SitecoreContent;
    @Input() first: boolean;
    @Input() last: boolean;
    @Input() toggleFirstCardOn: boolean;
    @Input() targetedPlayerCount: any;
    @Input() enableOptin: any;
    isDisplayCoin: boolean;
    coinSrc: string;
    value: any;
    playerCount: number;

    coinImageSrc: string | null = null;

    title: string;
    messages: { [item: string]: string } = {};
    showRaceCard: boolean;
    @Output() openRaceInfo = new EventEmitter<Race>();
    @Output() openPickGameOverlay = new EventEmitter();
    @Output() openNotificationOverlay = new EventEmitter();
    coinEconomyImageSrc: any;
    coinObj: any;
    noncurrenyValue: boolean = false;
    activeCategory: string;

    constructor(
        private slotRacesTracking: BingoTournamentsTrackingService,
        private slotRacesService: BingoTournamentsService,
    ) {}

    ngOnInit() {
        this.showCard();
        this.activeCategory = this.slotRacesService.getActiveCategory();
        this.title =
            this.race.gameVariants.length > 1
                ? this.race.content?.promotion?.promoName
                    ? this.race.content.promotion.promoName
                    : this.race.gameId
                : this.race?.content?.promotion?.promoName.startsWith('#')
                  ? this.race.content.promotion.promoName.replace('#', '').trim()
                  : this.race.gameId;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.showCard();

        if (changes?.targetedPlayerCount?.currentValue && this.race) {
            this.race.targetedPlayerCount = changes.targetedPlayerCount.currentValue;
        }
        if (changes?.race?.currentValue) {
            const raceCurrentValue = changes.race.currentValue;
            this.title =
                raceCurrentValue?.gameVariants?.length > 1
                    ? raceCurrentValue?.content?.promotion?.promoName
                        ? raceCurrentValue.content.promotion.promoName
                        : raceCurrentValue.gameId
                    : raceCurrentValue.content?.promotion?.promoName.startsWith('#')
                      ? raceCurrentValue.content.promotion.promoName.replace('#', '').trim()
                      : raceCurrentValue.gameId;
            this.coinObj = this.slotRacesService.bindCoinImg(changes?.race?.currentValue, this.coinImg);
        }
        const awardTypeArray = ['bingotickets', 'freespins', 'free_spins'];
        if (
            !this.race?.additionalParameters?.awardType ||
            (this.race?.additionalParameters?.awardType && awardTypeArray[0].indexOf(this.race?.additionalParameters?.awardType.toLowerCase()) > -1)
        )
            this.noncurrenyValue = true;
    }

    toggleRaceCardVisibility() {
        // this.activeCategory = this.slotRacesService.getActiveCategory();

        if (this.coinObj?.isDisplayCoin) {
            if (this.showRaceCard) {
                this.slotRacesTracking.track('Collapse', this.race.gameId, this.activeCategory, 'with coin icon');
            } else {
                this.slotRacesTracking.track('Expand', this.race.gameId, this.activeCategory, 'with coin icon');
            }
        } else {
            if (this.showRaceCard) {
                this.slotRacesTracking.track('Collapse', this.race.gameId, this.activeCategory, 'tournament details');
            } else {
                this.slotRacesTracking.track('Expand', this.race.gameId, this.activeCategory, 'tournament details');
            }
        }
        this.showRaceCard = !this.showRaceCard;
    }

    openRaceInfoDialog(): void {
        this.openRaceInfo.emit(this.race);
    }

    openPickGameDialog() {
        this.openPickGameOverlay.emit(this.race);
    }
    openNotificationDialog() {
        this.openNotificationOverlay.emit(this.race);
    }
    showCard() {
        if (this.toggleFirstCardOn && this.first) {
            this.showRaceCard = true;
        } else {
            this.showRaceCard = false;
        }
        this.slotRacesService.forceRace.subscribe((response: { race: Race }) => {
            if (response && response.race && response.race.slotUniqueId == this.race.slotUniqueId && response.race.promoId == this.race.promoId) {
                this.showRaceCard = true;
            }
        });
        this.slotRacesService.forceCoinBalanceObs.subscribe((coinBalRes: any) => {
            if (
                coinBalRes &&
                coinBalRes.race &&
                coinBalRes.race.promoId == this.race.promoId &&
                coinBalRes.race.slotUniqueId == this.race.slotUniqueId &&
                coinBalRes?.coinBal?.balance
            ) {
                this.showRaceCard = true;
            }
        });
    }
}
