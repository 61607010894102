<div class="bingo-prebuy-slider" *ngIf="!disableAllPrebuy">
    <button [ngClass]="AppendClass(0)" (click)="onMinusclick()"></button>
    <!-- <mat-slider thumbLabel (change)="OnValueChanged($event)" tickInterval="auto"
      min="{{min}}" step="{{step}}" max="{{maxium}}" value="{{value}}"  class="cdk-focused"></mat-slider> -->
    <!-- <mat-slider thumbLabel (change)="OnValueChanged($event)" tickInterval="auto"
      min="{{min}}" step="{{step}}" max="{{maxium}}" class="cdk-focused">
      <input matSliderThumb thumbLabel value="{{value}}" min="{{min}}" step="{{step}}" max="{{maxium}}"> -->
    <mat-slider min="{{ min }}" max="{{ maxium }}" step="{{ step }}" discrete tickInterval="auto" class="cdk-focused">
        <input matSliderThumb thumbLabel value="{{ value }}" (valueChange)="OnValueChanged($event)" />
    </mat-slider>
    <!-- </mat-slider> -->
    <!-- <i class="theme-plus" (click)="onPlusclick()"></i> -->
    <button [ngClass]="AppendClass(1)" (click)="onPlusclick()"></button>
</div>
