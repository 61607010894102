import { LowerCasePipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {
    ConfigProviderService,
    FetchUserRequestAPIModel,
    FetchUserResponseAPIModel,
    GamingStoriesPhase2Configuration,
    GamingStoriesService,
    GamingStoriesStorageCloseModel,
    IGamingStory,
    IGamingStoryContainer,
    IGamingStoryItem,
    OptInStoriesTrack,
    PostUserRequestAPIModel,
    ReqStoryIdsModel,
    StoriesLocalStorageModel,
    StoryInfoModel,
} from '@casinocore/platform/core';
import { DeviceService, Message, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FreeMode, Mousewheel } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { GamingStoryItemsComponent } from '../gaming-story-items/gaming-story-items.component';

@Component({
    selector: 'cc-gaming-stories-container',
    templateUrl: 'gaming-stories-container.component.html',
    standalone: true,
    imports: [NgIf, NgStyle, NgClass, SwiperComponent, NgFor, LowerCasePipe],
})
export class GamingStoriesContainerComponent implements OnChanges, OnInit, OnDestroy {
    storyContainerData: IGamingStoryContainer;
    isTouch: boolean;
    storyIconShape: string;
    viewedPromotionBrClr: string;
    notviewedPromotionBrClr: string;
    promotionTxtClr: string;
    promotionsBgClr: string;
    viewedpromotionTxtClr: string;
    storyTitlePosition: string;
    storyContainerLoad: boolean = false;
    tiltingMessage: string;
    tiltingImageUrl: string;

    private destroy$ = new Subject<void>();
    private gamingStoriesconfig: GamingStoriesPhase2Configuration;
    private expirationTime: number;
    private themeCheckClr: string;
    private themeCheckBgClr: string;
    private changePromoStatusObservable: Subscription;
    private storyContainerCloseData: GamingStoriesStorageCloseModel;
    private casinoConfig: any;
    private fetchUserPreparedRequest: FetchUserRequestAPIModel;
    private haveEmojieSelector: boolean = false;
    private haveEmojieSlider: boolean = false;
    private haveAnswers: boolean = false;
    private haveYesOrNoPoll: boolean = false;
    private skipFilterViewedAndUnviewed: boolean = false;
    private fetchFirstTime: boolean = false;
    private calledOnce: number = 1;
    private isStoryContainerAssigned: boolean;
    private isApiCalled: boolean;
    private fetchingGamingStoriesAPIRes: FetchUserResponseAPIModel;
    private fetchAPIObservable: Subscription;
    private expiryTimeReached: boolean = false;
    private sessionStorageServiceStoryData: any;
    private currentEpocTime: number = 0;
    private gamingstoriesExpirationTimeInterval: number = 10;
    private isAlreadyResponseFired: boolean = false;
    private isAlreadyFailedResponseFired: boolean = false;

    swiperOptions: SwiperOptions = {
        modules: [FreeMode, Mousewheel],
        speed: 500,
        autoHeight: true,
        allowTouchMove: false,
        spaceBetween: 5,
        slidesPerView: 13,
        slidesPerGroup: 3,
        preventClicks: false,
        preventClicksPropagation: false,
    };

    constructor(
        private matDialog: MatDialog,
        private gamingStoriesService: GamingStoriesService,
        private deviceService: DeviceService,
        private user: UserService,
        private configProviderService: ConfigProviderService,
    ) {}

    ngOnChanges() {
        this.renderstoryContainerData();
    }

    ngOnInit(): void {
        this.isTouch = this.deviceService.isTouch;
        this.casinoConfig = this.configProviderService.provideCasinoConfig();
        this.getGamingStoriesData();
        if (this.isTouch) {
            this.swiperOptions.navigation = false;
            this.swiperOptions.allowTouchMove = true;
            this.swiperOptions.speed = 100;
            this.swiperOptions.navigation = false;
            this.swiperOptions.touchReleaseOnEdges = true;
            this.swiperOptions.freeMode = {
                enabled: true,
                minimumVelocity: 0.1,
            };
            this.swiperOptions.mousewheel = {
                forceToAxis: true,
            };
            this.swiperOptions.slidesPerView = 5;
            //for ipad/tab in portrait
            if (document.body.offsetWidth >= 768 && document.body.offsetWidth < 1024) {
                this.swiperOptions.slidesPerView = 10;
            }
            //for ipad/tab in landscape
            else if (document.body.offsetWidth >= 1024 && document.body.offsetWidth < 1200) {
                this.swiperOptions.slidesPerView = 13;
            }
        } else {
            this.swiperOptions.allowTouchMove = false;
            this.swiperOptions.navigation = true;
            this.swiperOptions.touchReleaseOnEdges = false;
            this.swiperOptions.freeMode = false;
            this.swiperOptions.mousewheel = false;
            this.swiperOptions.speed = 500;
            if (this.swiperOptions.slidesPerView === 13) {
                this.swiperOptions.slidesPerGroup = 13;
            }
        }
    }

    storyClick(index: number) {
        if (this.storyContainerData) {
            if (this.storyContainerData.storyList[index].storyViewed) this.skipFilterViewedAndUnviewed = true;
            else this.skipFilterViewedAndUnviewed = false;
        }
        if (this.user.isAuthenticated) {
            this.fetchAPIAfterExpiry(index);
        } else {
            this.prepareDialogRef(index);
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private prepareDialogRef(index: any) {
        const dialogData = {
            storyContainerData: this.storyContainerData,
            storyItem: this.storyContainerData.storyList[index].storyItems,
            activeStoriesIndex: index,
            fetchUserPreparedRequest: this.fetchUserPreparedRequest,
            fetchingGamingStoriesAPIRes: this.fetchingGamingStoriesAPIRes,
        };
        this.gamingStoriesService.pauseStoryBehaviourSubject.next(false);
        const dialogRef = this.matDialog.open(GamingStoryItemsComponent, {
            width: '375px',
            height: '667px',
            data: dialogData,
            panelClass: 'mobile-stories-overlay',
        });
        dialogRef.afterClosed().subscribe((storyContainerData: IGamingStoryContainer) => {
            if (this.gamingStoriesService.postStoryInfoRequestItemsList.length > 0 && this.user.isAuthenticated) {
                let postUserRequestAPIModel: PostUserRequestAPIModel = new PostUserRequestAPIModel();
                postUserRequestAPIModel.userId = this.user.id ? this.user.id : '';
                postUserRequestAPIModel.brandId = this.casinoConfig.brandId;
                postUserRequestAPIModel.storyInfo = new Array<StoryInfoModel>();
                for (let storyInfoData of this.gamingStoriesService.postStoryInfoRequestItemsList) {
                    const storyInfoObj = new StoryInfoModel();
                    storyInfoObj.storyId = storyInfoData.storyId;
                    storyInfoObj.subStoryId = storyInfoData.subStoryId;
                    storyInfoObj.storyType = storyInfoData.storyType;
                    storyInfoObj.templateType = storyInfoData.templateType;
                    storyInfoObj.userInput = storyInfoData.userInput;
                    storyInfoObj.expTime = this.utcConverter(storyInfoData.expTime);
                    postUserRequestAPIModel.storyInfo.push(storyInfoObj);
                }
                this.gamingStoriesService.postGamingStoriesAPI(postUserRequestAPIModel);
                this.gamingStoriesService.storyInfoPostRequestList = [];
            }
            this.updateStoryFilterData(storyContainerData);
        });
    }

    private utcConverter(dateToday: any) {
        return this.epoch(dateToday);
    }

    private epoch(date: any) {
        return Date.parse(date);
    }

    private prepareSwipeUpData() {
        const swipeUpStoryListArray: Array<IGamingStoryItem> = new Array<IGamingStoryItem>();
        this.storyContainerData.storyList.forEach((stories) => {
            stories.storyItems.forEach((storyItem) => {
                if (
                    storyItem.enableSwipeup &&
                    storyItem.storyItemActionType !== 'EmojiSlider' &&
                    storyItem.storyItemActionType !== 'EmojiSelector' &&
                    storyItem.storyItemActionType !== 'SelectList' &&
                    storyItem.storyItemActionType !== 'YesNo' &&
                    storyItem.swipeupStoryItemContent.length !== 0
                ) {
                    swipeUpStoryListArray.push(storyItem.swipeupStoryItemContent[0].path);
                }
                storyItem.storyId = stories.storyId;
            });
        });
        const swipeUpStoryListInString = swipeUpStoryListArray.join(',');
        this.gamingStoriesService.getSwipeUpContent(swipeUpStoryListInString);
    }

    private fetchAPIAfterExpiry(index: any) {
        // fetch session storage for Exipry time
        if (sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage')) {
            this.currentEpocTime = Math.round(new Date().getTime() / 1000);
            this.sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage') as string);
        }
        if (
            sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage') &&
            this.currentEpocTime > this.sessionStorageServiceStoryData[this.sessionStorageServiceStoryData.length - 1].expiryTime
        ) {
            this.expiryTimeReached = true;
            this.getFetchingUserResponseAPIData(index);
            this.gamingStoriesService.fetchGamingStoriesAPI(this.fetchUserPreparedRequest, this.expirationTime);
        } else if ((!this.fetchFirstTime && !this.expiryTimeReached) || !sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage')) {
            this.getFetchingUserResponseAPIData(index);
            this.gamingStoriesService.fetchGamingStoriesAPI(this.fetchUserPreparedRequest, this.expirationTime);
            this.fetchFirstTime = true;
        } else {
            this.prepareDialogRef(index);
        }
    }

    private getFetchingUserResponseAPIData(index?: any): void {
        this.subscribeFetchUserResponse(index);
        this.isAlreadyResponseFired = false;
        this.gamingStoriesService.fetchGamingStoriesAPIObservable.pipe(takeUntil(this.destroy$)).subscribe((isReady: boolean) => {
            if (isReady) {
                const fetchGamingStoriesAPIResData: FetchUserResponseAPIModel = this.gamingStoriesService.fetchGamingStoriesAPIRes;
                if (fetchGamingStoriesAPIResData) {
                    this.fetchingGamingStoriesAPIRes = fetchGamingStoriesAPIResData;
                    if (this.fetchingGamingStoriesAPIRes?.response && !this.isAlreadyResponseFired) {
                        this.prepareDialogRef(index);
                        this.isAlreadyResponseFired = true;
                    }
                }
            }
        });
    }

    private subscribeFetchUserResponse(index?: any) {
        this.isAlreadyFailedResponseFired = false;
        this.fetchAPIObservable = this.gamingStoriesService.fetchAPIObservable.pipe(takeUntil(this.destroy$)).subscribe((message: Message) => {
            if (message?.type === 'Error' && !this.isAlreadyFailedResponseFired) {
                this.prepareDialogRef(index);
                this.isAlreadyFailedResponseFired = true;
            }
        });
    }

    private setViewedPromo(activeStoriesIndex: number) {
        const activeStoryData = this.storyContainerCloseData.storyList[activeStoriesIndex];
        let storiesHasNotViewedStory: boolean = false;
        activeStoryData.storyItems.forEach((story: IGamingStoryItem) => {
            if (!story.storyItemViewed) {
                storiesHasNotViewedStory = true;
            }
        });
        if (!storiesHasNotViewedStory) {
            this.storyContainerData.storyList[activeStoriesIndex].storyViewed = true;
        }
    }

    private setViewedStory(activeStoriesIndex: number, activeStoryItemIndex: number) {
        this.storyContainerCloseData.storyList[activeStoriesIndex].storyItems[activeStoryItemIndex].storyItemViewed = true;
    }

    private filterViewedAndUnviewed(storyList: Array<IGamingStory>) {
        const viewedGamingStoriesList: Array<IGamingStory> = new Array<IGamingStory>();
        const notViewedGamingStoriesList: Array<IGamingStory> = new Array<IGamingStory>();
        storyList.forEach((stories: IGamingStory) => {
            if (stories.storyViewed) {
                viewedGamingStoriesList.push(stories);
            } else {
                notViewedGamingStoriesList.push(stories);
            }
        });
        if (this.user.isAuthenticated) {
            this.storeStoriesViewedStateInLocalStorage(viewedGamingStoriesList);
            this.filterStories();
        } else {
            let reversedViewedStories = [...viewedGamingStoriesList].reverse();
            this.storyContainerData.storyList = notViewedGamingStoriesList.concat(reversedViewedStories);
        }
    }

    private renderstoryContainerData() {
        if (this.storyContainerData) {
            this.storyIconShape = this.storyContainerData.storyIconType ? this.storyContainerData.storyIconType : 'Square';
            this.viewedPromotionBrClr = this.storyContainerData.sharedList.viewedPromotionBrClr;
            this.notviewedPromotionBrClr = this.storyContainerData.sharedList.notviewedPromotionBrClr;
            this.themeCheckClr = this.storyContainerData.sharedList.themeCheckClr;
            this.themeCheckBgClr = this.storyContainerData.sharedList.themeCheckBgClr;
            this.viewedpromotionTxtClr = this.storyContainerData.sharedList.viewedpromotionTxtClr;
            this.promotionTxtClr = this.storyContainerData.sharedList.promotionTxtClr;
            this.promotionsBgClr = this.storyContainerData.sharedList.promotionsBgClr;
            this.storyTitlePosition = this.storyContainerData.titlePosition;
            this.filterStories();
        }
    }

    private filterStories() {
        const gamingStoriesArrayFromLocalStorage = this.gamingStoriesService.readGamingStoriesPhase2Array();
        if (gamingStoriesArrayFromLocalStorage) {
            let viewedGamingStoriesList: Array<IGamingStory> = new Array<IGamingStory>();
            const unViewedGamingStoriesList: Array<IGamingStory> = new Array<IGamingStory>();
            let gamingStoriesLocalStorageArray: Array<StoriesLocalStorageModel> = new Array<StoriesLocalStorageModel>();
            this.storyContainerData.storyList.forEach((storyListData: IGamingStory) => {
                let isViewedStory: boolean = false;
                let localstorageStory: StoriesLocalStorageModel;
                for (let i = 0; i < gamingStoriesArrayFromLocalStorage.length; i++) {
                    localstorageStory = gamingStoriesArrayFromLocalStorage[i];
                    if (storyListData.storyId === localstorageStory.storyId && this.user.id === localstorageStory.userId) {
                        if (this.checkViewedStoryExpiration(localstorageStory.viewedTimeStamp)) {
                            isViewedStory = true;
                            storyListData.storyViewed = true;
                            viewedGamingStoriesList[i] = storyListData;
                            gamingStoriesLocalStorageArray[i] = localstorageStory;
                            break;
                        }
                    }
                }
                if (!isViewedStory) {
                    unViewedGamingStoriesList.push(storyListData);
                }
            });
            viewedGamingStoriesList = viewedGamingStoriesList.filter((story) => story !== undefined);
            gamingStoriesLocalStorageArray = gamingStoriesLocalStorageArray.filter((storyId) => storyId !== undefined);
            this.storyContainerData.storyList = unViewedGamingStoriesList.concat(viewedGamingStoriesList);
            this.gamingStoriesService.writeGamingStoriesPhase2Array(gamingStoriesLocalStorageArray); //Updating local storage array
        }
    }

    private checkViewedStoryExpiration(storyTimeStamp: number) {
        //Function to check if local storage has expired for a particular promo
        const expirationTimeStamp = this.gamingstoriesExpirationTimeInterval * 24 * 60 * 60 * 1000; //Converting days to milliseconds
        return new Date().getTime() - storyTimeStamp < expirationTimeStamp;
    }

    private storeStoriesViewedStateInLocalStorage(viewedGamingStoriesList: Array<IGamingStory>) {
        const gamingStoriesArrayFromLocalStorage = this.gamingStoriesService.readGamingStoriesPhase2Array();
        let gamingStoriesLocalStorageArray: Array<StoriesLocalStorageModel> = gamingStoriesArrayFromLocalStorage
            ? gamingStoriesArrayFromLocalStorage
            : new Array<StoriesLocalStorageModel>();
        viewedGamingStoriesList.forEach((stories: IGamingStory) => {
            if (gamingStoriesArrayFromLocalStorage) {
                let storiesAlreadyInLocalStorageArray: boolean = false;
                for (let gamingStoriesArrayFromLocalStorageItem of gamingStoriesArrayFromLocalStorage) {
                    if (stories.storyId === gamingStoriesArrayFromLocalStorageItem.storyId) {
                        storiesAlreadyInLocalStorageArray = true;
                        break;
                    }
                }
                if (!storiesAlreadyInLocalStorageArray) {
                    gamingStoriesLocalStorageArray = this.pushViewedStoryItemToArray(gamingStoriesLocalStorageArray, stories.storyId);
                }
            } else {
                gamingStoriesLocalStorageArray = this.pushViewedStoryItemToArray(gamingStoriesLocalStorageArray, stories.storyId);
            }
        });
        if (gamingStoriesLocalStorageArray.length > 0) {
            this.gamingStoriesService.writeGamingStoriesPhase2Array(gamingStoriesLocalStorageArray);
        }
    }
    private pushViewedStoryItemToArray(gamingStoriesLocalStorageArray: Array<StoriesLocalStorageModel>, storyId: string) {
        const localStorageStories: StoriesLocalStorageModel = new StoriesLocalStorageModel();
        localStorageStories.userId = this.user.id ? this.user.id : '';
        localStorageStories.storyId = storyId;
        localStorageStories.viewedTimeStamp = new Date().getTime();
        gamingStoriesLocalStorageArray.push(localStorageStories);
        return gamingStoriesLocalStorageArray;
    }

    //private setCaourselOptions(storyTitlePosition: string) {
    // if (storyTitlePosition.toLowerCase() === 'bottom') {
    //     this.swiperOptionsGamingStories = {
    //         // grid: { sm: 14, md: 14, lg: 14, all: 0 },
    //         slidesPerView: 14,
    //         loop: false,
    //         allowTouchMove: false,
    //     };
    // } else {
    //     this.swiperOptionsGamingStories = {
    //         // grid: { xs: 7, sm: 7, md: 7, lg: 7, all: 0 },
    //         slidesPerView: 7,
    //         loop: false,
    //         allowTouchMove: false,
    //     };
    // }
    //}

    private getGamingStoriesData(): void {
        this.gamingStoriesService.observable.pipe(takeUntil(this.destroy$)).subscribe((isReady: any) => {
            if ((isReady && !this.user.isAuthenticated) || (this.user.isAuthenticated && isReady.AfterApiCall && !this.isStoryContainerAssigned)) {
                this.isStoryContainerAssigned = true;
                const storyContainer: IGamingStoryContainer = this.gamingStoriesService.getStoryContainerData;
                if (storyContainer) {
                    const fetchUserRequestAPIModel: FetchUserRequestAPIModel = new FetchUserRequestAPIModel();
                    fetchUserRequestAPIModel.userId = this.user.id ? this.user.id : '';
                    fetchUserRequestAPIModel.brandId = this.casinoConfig.brandId;
                    fetchUserRequestAPIModel.reqStoryIds = new Array<ReqStoryIdsModel>();
                    this.storyContainerData = storyContainer;
                    this.storyContainerLoad = true;
                    const optInStoriesTrackList = new Array<OptInStoriesTrack>();
                    this.storyContainerData.storyList.forEach((stories) => {
                        stories.storyViewed = false;
                        stories.storyItems.forEach((storyItem) => {
                            storyItem.storyItemViewed = false;
                            storyItem.storyImage = stories.storyImage;
                            storyItem.title = stories.title;
                            storyItem.storyId = stories.storyId;
                            const optInStoriesTrack = new OptInStoriesTrack();
                            // if (!this.user.isAuthenticated && storyItem.showOptIn && storyItem.targetPromoId != null && storyItem.storyItemActionType === 'Promos') {
                            //     optInStoriesTrack.activeStoriesIndex = storyItem.activeStoriesIndex;
                            //     optInStoriesTrack.activeStoryItemIndex = storyItem.activeStoryItemIndex;
                            //     optInStoriesTrackList.push(optInStoriesTrack);
                            // }
                            // else
                            if (
                                this.user.isAuthenticated &&
                                !storyItem.isValid &&
                                storyItem.showOptIn &&
                                storyItem.targetPromoId != null &&
                                storyItem.storyItemActionType === 'Promos'
                            ) {
                                optInStoriesTrack.activeStoriesIndex = storyItem.activeStoriesIndex;
                                optInStoriesTrack.activeStoryItemIndex = storyItem.activeStoryItemIndex;
                                optInStoriesTrackList.push(optInStoriesTrack);
                            }
                            const reqStoryIdsModel = new ReqStoryIdsModel();
                            if (
                                storyItem.storyItemActionType === 'EmojiSelector' ||
                                storyItem.storyItemActionType === 'YesNoPoll' ||
                                storyItem.storyItemActionType === 'EmojiSlider' ||
                                storyItem.storyItemActionType === 'QuestionAndAnswer'
                            ) {
                                reqStoryIdsModel.storyId = storyItem.storyId;
                                reqStoryIdsModel.subStoryId = storyItem.storyItemId;
                                fetchUserRequestAPIModel.reqStoryIds.push(reqStoryIdsModel);
                            }
                            if (storyItem.enableSwipeup) {
                                this.prepareSwipeUpData();
                            }
                        });
                    });
                    this.fetchUserPreparedRequest = fetchUserRequestAPIModel;
                    this.gamingStoriesconfig = this.gamingStoriesService.config;
                    if (this.gamingStoriesconfig) {
                        this.expirationTime = this.gamingStoriesconfig.expirationTime;
                        this.gamingstoriesExpirationTimeInterval = this.gamingStoriesconfig.localStorageExpirationDuration;
                    }
                    this.user.events.pipe(filter((e: any) => e instanceof UserLoginEvent)).subscribe(() => {
                        if (sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage')) {
                            this.fetchUserPreparedRequest.userId = this.user.id ? this.user.id : '';
                            this.gamingStoriesService.fetchGamingStoriesAPI(this.fetchUserPreparedRequest, this.expirationTime);
                        }
                    });
                    if (optInStoriesTrackList.length > 0) {
                        optInStoriesTrackList.forEach((optinStories) => {
                            const storyItemIndex = this.storyContainerData.storyList[optinStories.activeStoriesIndex].storyItems.findIndex(
                                (x) => x.activeStoryItemIndex === optinStories.activeStoryItemIndex,
                            );
                            if (storyItemIndex !== optinStories.activeStoryItemIndex) {
                                optinStories.activeStoryItemIndex = storyItemIndex;
                            }
                            this.storyContainerData.storyList[optinStories.activeStoriesIndex].storyItems.splice(
                                optinStories.activeStoryItemIndex,
                                1,
                            );
                        });
                    }
                    this.renderstoryContainerData();
                }
            }
        });
    }
    private updateStoryFilterData(storyContainerData: IGamingStoryContainer): void {
        if (storyContainerData) {
            if (!this.skipFilterViewedAndUnviewed) {
                this.filterViewedAndUnviewed(storyContainerData.storyList);
            }
        } else {
            this.storyContainerCloseData = this.gamingStoriesService.getStoryContainerCloseList;
            if (this.storyContainerCloseData) {
                this.setViewedStory(this.storyContainerCloseData.activeStoriesIndex, this.storyContainerCloseData.activeStoryItemIndex);
                if (
                    this.storyContainerCloseData.storyList[this.storyContainerCloseData.activeStoriesIndex].storyItems.length ==
                    this.storyContainerCloseData.activeStoryItemIndex + 1
                ) {
                    this.setViewedPromo(this.storyContainerCloseData.activeStoriesIndex);
                }
                if (!this.skipFilterViewedAndUnviewed) {
                    this.filterViewedAndUnviewed(this.storyContainerCloseData.storyList);
                }
            }
        }
    }
}
