import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import {
    BingoConfigProviderService,
    BingoHelperService,
    BingoManager,
    BingoTournamentsService,
    Race,
    RaceRuleConfig,
    SitecoreContent,
} from '@frontend/bingo/core';
import { DeviceService } from '@frontend/vanilla/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { Subscription } from 'rxjs';

@Component({
    selector: 'bgl-slot-races-points',
    templateUrl: './bingo-points.component.html',
})
export class BingoSlotRacesPointsComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() showHeader: boolean;
    @Input() isCollapsible: boolean;
    @Input() isExpanded: boolean;
    @Input() race: Race;
    isVisible: boolean;
    racedata: boolean;
    hidepoint: boolean;
    panelOpenState: boolean;
    pointsdatalength: number;
    doExpanded: boolean;
    pointsgredientbg: string;
    pointsData: any;
    pointsContent: RaceRuleConfig[];
    sitecoreData: SitecoreContent;
    contentSubscription: any;
    isExpansionDisabled: string = '';
    showPointsV2: boolean;
    config: NguCarouselConfig;
    showCarousel: boolean;
    resizeSubscription: Subscription;

    @ViewChild('pointsV2Carousel')
    private carousel: any;

    constructor(
        private slotRacesService: BingoTournamentsService,
        private deviceManager: DeviceService,
        private configproviderservice: BingoConfigProviderService,
        private bingoManager: BingoManager,
        private bingoHelperService: BingoHelperService,
    ) {
        this.config = {
            grid: { xs: 1, sm: 2, md: 2, lg: 2, all: 0 },
            slide: 1,
            speed: 400,
            interval: { timing: 4000, initialDelay: 1000 },
            point: {
                visible: true,
            },
            touch: true,
            velocity: 0.5,
        };
    }

    ngOnInit() {
        const config = this.configproviderservice.provideBingoTournamentsClientConfig();
        this.hidepoint = this.bingoHelperService.getConfigBasedOnInvokerProduct(config.hideGenericPoints);
        this.showCarouselArrows();
        this.resizeSubscription = this.bingoManager.reSizeObservable.subscribe((width: any) => {
            if (width) {
                this.showCarouselArrows();
            }
        });
        this.showPointsV2 = this.race && this.race.slotRaceRuleConfigV2 && this.race.slotRaceRuleConfigV2.length > 0;
        this.doExpanded = this.isExpanded;
        this.isVisible = false;
        this.changeCollapseState();
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.sitecoreData = content;
            this.pointsContent = JSON.parse(JSON.stringify(content.bingoRaceRulesConfigs)!);
            this.bindRuleContent();
            this.setSiteCoreStyles();
        });
        this.expansionState();
    }

    ngAfterViewInit() {
        this.onCarouselMove();
        this.setSiteCoreStyles();
    }

    setSiteCoreStyles() {
        if (this.sitecoreData && this.sitecoreData.textTranslations && this.sitecoreData.textTranslations.sharedList) {
            if (document.querySelector('.casino-point-carousel') as HTMLElement) {
                (document.querySelector('.casino-point-carousel') as HTMLElement).style.cssText =
                    this.sitecoreData.textTranslations.sharedList.pointsV2CSSText;
            }
        }
    }

    showCarouselArrows() {
        this.showCarousel = this.deviceManager.isTouch
            ? window.innerWidth >= 768
                ? window.matchMedia('(orientation: landscape)').matches && window.innerWidth < 900
                    ? false
                    : true
                : false
            : true;
        if (this.showCarousel) {
            this.setCarouselConfigs();
        }
    }

    setCarouselConfigs() {
        this.config = {
            grid: { xs: 1, sm: 2, md: 2, lg: 2, all: 0 },
            slide: 1,
            speed: 400,
            interval: { timing: 4000, initialDelay: 1000 },
            point: {
                visible: true,
            },
            touch: true,
            velocity: 0.5,
        };
        this.onCarouselMove();
    }

    bindRuleContent() {
        if (this.race && this.race.slotRaceRuleConfigV2 && this.race.slotRaceRuleConfigV2.length) {
            this.pointsData = this.race.slotRaceRuleConfigV2.sort((pointSortA: any, pointSortB: any) => {
                return pointSortA.points - pointSortB.points;
            });
            this.pointsData.forEach((apiRaceConfig: any) => {
                const matchedcontent = this.pointsContent.find(
                    (p) => p?.metadata?.id?.itemName?.toLowerCase() === apiRaceConfig?.ruleType?.replaceAll(' ', '')?.toLowerCase(),
                );
                apiRaceConfig.pointValue = apiRaceConfig.points;
                apiRaceConfig.pointsImage = matchedcontent?.pointsImage;
                apiRaceConfig.pointsText = matchedcontent?.pointsText?.replace('{points}', '');
                apiRaceConfig.pointsDescription = matchedcontent?.pointsDescription?.replace('{ruleConfig}', String(apiRaceConfig.ruleConfig));
                apiRaceConfig.ruleTitle = matchedcontent?.ruleConfigTitleV2;
                apiRaceConfig.ruleDescription = matchedcontent?.ruleConfigDescriptionV2;
                this.setPointsV2DescriptionTexts(apiRaceConfig);
            });
        } else if (this.race && this.race.slotRaceRuleConfig && this.race.slotRaceRuleConfig.length) {
            this.pointsData = this.race.slotRaceRuleConfig.sort((pointSortA: any, pointSortB: any) => {
                return pointSortA.points - pointSortB.points;
            });
            this.pointsdatalength = this.pointsData.length;
            this.pointsData?.forEach((apiRaceConfig: any) => {
                if (apiRaceConfig.criteriaType == 'Normal') {
                    apiRaceConfig.pointsDescription =
                        apiRaceConfig?.ruleType?.substring(0, apiRaceConfig?.ruleType?.indexOf('_'))?.toUpperCase() ?? '';
                } else {
                    apiRaceConfig.pointsDescription =
                        (apiRaceConfig?.ruleType?.substring(0, apiRaceConfig?.ruleType?.indexOf('_'))?.toUpperCase() ?? '') +
                        ' : ' +
                        apiRaceConfig.criteriaType.toUpperCase();
                }
                const matchedcontent = this.pointsContent.find((p) => p?.metadata?.id?.itemName?.toLowerCase() === 'default');
                apiRaceConfig.pointValue = apiRaceConfig.points;
                apiRaceConfig.pointsImage = matchedcontent?.pointsImage;
                apiRaceConfig.pointsText = matchedcontent?.pointsText?.replace('{points}', '');
            });
        } else {
            this.pointsData = this.pointsContent.filter((p) => p?.metadata?.id?.itemName?.toLowerCase() != 'default');
            this.pointsdatalength = this.pointsData.length;
        }
    }

    private setPointsV2DescriptionTexts(ruleConfig: any) {
        switch (ruleConfig?.ruleType?.toLowerCase()) {
            case 'consecutive_wins': {
                ruleConfig.descSingle = this.sitecoreData.textTranslations.versionedList.WinSingle;
                ruleConfig.descMult = this.sitecoreData.textTranslations.versionedList.WinMultiple;
                break;
            }
            case 'consecutive_losses': {
                ruleConfig.descSingle = this.sitecoreData.textTranslations.versionedList.LossSingle;
                ruleConfig.descMult = this.sitecoreData.textTranslations.versionedList.LossMultiple;
                break;
            }
            case 'win_x_times_on_bet': {
                ruleConfig.descSingle = this.sitecoreData.textTranslations.versionedList.StakeWon;
                ruleConfig.descMult = this.sitecoreData.textTranslations.versionedList.StakeWon;
                break;
            }
        }
        if (ruleConfig && ruleConfig.slabList && ruleConfig.slabList.length > 4) {
            ruleConfig.pointSingle = this.sitecoreData.textTranslations.versionedList.PtSingle;
            ruleConfig.pointMult = this.sitecoreData.textTranslations.versionedList.PtMultiple;
        } else {
            ruleConfig.pointSingle = this.sitecoreData.textTranslations.versionedList.PointSingle;
            ruleConfig.pointMult = this.sitecoreData.textTranslations.versionedList.PointMultiple;
        }
    }

    onCarouselMove() {
        if (this.showCarousel && this.carousel) {
            const firstVisibleSlide = this.carousel?.currentSlide;
            const lastVisibleSlide = this.carousel?.currentSlide + this.carousel?.items - 1;
            const slideItems = this.carousel?.nguItemsContainer?.nativeElement?.children;

            for (let i = 0; i < slideItems?.length; i++) {
                if (i >= firstVisibleSlide && i <= lastVisibleSlide) {
                    this.carousel.nguItemsContainer.nativeElement.children[i].style.opacity = 1;
                } else {
                    this.carousel.nguItemsContainer.nativeElement.children[i].style.opacity = 0.5;
                }
            }
        }
    }

    showTnC() {
        const tnc = document.getElementById('csSlotRaceTnC');
        if (tnc) {
            tnc.scrollIntoView();
        }
    }

    ngOnDestroy() {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
    }

    changeCollapseState() {
        this.isVisible = !this.isVisible;
    }

    expansionState() {
        if (!this.isCollapsible) {
            this.isExpansionDisabled = 'disabled-pointer';
        }
    }
}
