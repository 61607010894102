<div class="teamSelectModal-new">
    <div class="syt">
        Select your team
        <i class="theme-arrow-down" (click)="onTeamSelected('')"></i>
    </div>
    <ul>
        <li
            *ngFor="let team of data.currentGame.game_context.TeamBingo.teams"
            (click)="onTeamSelected(team)"
            [ngStyle]="{
                'color': data.currentGame.game_context.TeamBingo.current_team == team ? 'white' : data.teamColors[team],
                'background-color': data.currentGame.game_context.TeamBingo.current_team == team ? data.teamColors[team] : ''
            }">
            {{ team | lowercase }}
        </li>
    </ul>
</div>
