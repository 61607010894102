import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortfilter',
})
export class SortFilterPipe implements PipeTransform {
    transform(
        array: Array<any>,
        args: string,
        order: number,
        pinnedRoomId: number[],
        insuranceRoomIds: number[],
        sitNGoRoomIds: number[],
    ): Array<any> {
        if (order == undefined || args == 'bingo_type' || args == 'number_of_players' || args == 'prize') {
            order = -1;
        } else {
            order = 1;
        }
        // if (!pinnedRoomId) {
        //     pinnedRoomId = 0;
        // }
        if (array) {
            array.sort((a: any, b: any) => {
                if (args == '') {
                    return 0;
                } else if (args === 'status.state_end_time') {
                    const ta = this.getTimeVal(a['status'].state_end_time);
                    const tb = this.getTimeVal(b['status'].state_end_time);
                    if (ta === 'started' || ta == undefined) {
                        return 1;
                    } else if (tb == 'started' || tb == undefined) {
                        return -1;
                    } else if (Number(this.convertTimeToSec(ta)) > Number(this.convertTimeToSec(tb))) {
                        return 1;
                    } else {
                        return -1;
                    }
                } else if (typeof args === 'string') {
                    return a[args].localeCompare(b[args]);
                } else if (typeof args === 'number') {
                    return this.sortNumber(a, b, order);
                } else if (typeof args === 'boolean' && args == false) {
                    return -1 * order;
                } else if (typeof args === 'boolean' && args == true) {
                    return 1 * order;
                } else {
                    return 0;
                }
            });
            const sitNGoRooms = array.filter((game) => {
                return sitNGoRoomIds.indexOf(Number(game.id)) > -1;
            });
            sitNGoRooms.reverse();
            if (sitNGoRooms.length > 0) {
                sitNGoRooms.forEach((g: any) => {
                    const sitNGoIndex = array.indexOf(g);
                    array.splice(sitNGoIndex, 1);
                    array.unshift(g);
                });
            }

            const item = array.filter((game) => {
                return pinnedRoomId.indexOf(Number(game.id)) > -1;
            });
            item.reverse();
            if (item.length > 0) {
                item.forEach((g: any) => {
                    const pinnedIndex = array.indexOf(g);
                    array.splice(pinnedIndex, 1);
                    array.unshift(g);
                });
            }

            const Insuranceitem = array.filter((game) => {
                return insuranceRoomIds.indexOf(Number(game.id)) > -1;
            });
            Insuranceitem.reverse();
            if (Insuranceitem.length > 0) {
                Insuranceitem.forEach((g: any) => {
                    const insuranceIndex = array.indexOf(g);
                    array.splice(insuranceIndex, 1);
                    array.push(g);
                });
            }
        }

        return array;
    }

    sortNumber(val1: number, val2: number, sortDirection: any): number {
        if (val1 < val2) {
            return -1 * sortDirection;
        } else if (val1 > val2) {
            return 1 * sortDirection;
        } else {
            return 0;
        }
    }
    convertTimeToSec(ta: any): number {
        let timeinSec = 0;
        if (ta.indexOf('d') > 0) {
            timeinSec = ta.slice(0, ta.indexOf('d')) * 86400;
        }
        if (ta.indexOf('h') > 0) {
            if (ta.indexOf('d') > 0) {
                timeinSec = timeinSec + ta.slice(ta.indexOf('d') + 1, ta.indexOf('h')) * 3600;
            } else {
                timeinSec = timeinSec + ta.slice(0, ta.indexOf('h')) * 3600;
            }
        }
        if (ta.indexOf('m') > 0) {
            if (ta.indexOf('h') > 0) {
                timeinSec = timeinSec + ta.slice(ta.indexOf('h') + 1, ta.indexOf('m')) * 60;
            } else {
                timeinSec = timeinSec + ta.slice(0, ta.indexOf('m')) * 60;
            }
        }
        if (ta.indexOf('s') > 0) {
            if (ta.indexOf('m') > 0) {
                timeinSec = timeinSec + ta.slice(ta.indexOf('m') + 1, ta.indexOf('s'));
            } else {
                timeinSec = timeinSec + ta.slice(0, ta.indexOf('s'));
            }
        }
        return timeinSec;
    }

    getTimeVal(timestring: any): any {
        const timestamp = new Date(timestring * 1000).getTime();
        const current_timestamp = new Date().getTime();
        const time_variation = timestamp - current_timestamp;
        let time_computed;
        if (time_variation <= 0 || timestring === undefined) {
            time_computed = 'started';
        } else {
            const current_date = new Date(time_variation);
            const current_day = Math.floor(time_variation / (1000 * 60 * 60 * 24));
            if (current_day >= 1) {
                if (current_date.getUTCMinutes() <= 0) {
                    time_computed = current_day + 'd' + current_date.getUTCHours() + 'h' + current_date.getUTCSeconds();
                } else {
                    time_computed =
                        current_day +
                        'd' +
                        current_date.getUTCHours() +
                        'h' +
                        current_date.getUTCMinutes() +
                        'm' +
                        current_date.getUTCSeconds() +
                        's';
                }
            } else {
                if (current_date.getUTCHours() >= 1) {
                    if (current_date.getUTCHours() < 0) {
                        time_computed = current_date.getUTCHours() + 'h' + current_date.getUTCSeconds() + 's';
                    } else {
                        time_computed = current_date.getUTCHours() + 'h' + current_date.getUTCMinutes() + 'm' + current_date.getUTCSeconds() + 's';
                    }
                } else {
                    if (current_date.getUTCMinutes() > 0) {
                        time_computed = current_date.getUTCMinutes() + 'm' + current_date.getUTCSeconds() + 's';
                    } else if (current_date.getUTCSeconds() > 0) {
                        time_computed = current_date.getUTCSeconds() + 's';
                    }
                }
            }
        }
        return time_computed;
    }
}
