import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import {
    FetchUserRequestAPIModel,
    FetchUserResponseAPIModel,
    GamingStoriesPhase2Configuration,
    GamingStoriesService,
    IGamingStoryContainer,
    IGamingStoryItem,
    IOption,
    ResponseDataModel,
    StoryInfoData,
    StoryInfoDataInSessionStorage,
} from '@casinocore/platform/core';
import { DynamicHtmlDirective, LoginDialogService, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'cc-gaming-story-quiz',
    templateUrl: 'gaming-story-quiz.component.html',
    standalone: true,
    imports: [NgClass, DynamicHtmlDirective, NgStyle, NgIf, NgFor],
})
export class GamingStoryQuizComponent implements OnInit, OnDestroy, OnChanges {
    @Input() storyItem: IGamingStoryItem;
    @Input() fetchUserPreparedRequest: FetchUserRequestAPIModel;
    @Input() storyContainerData: IGamingStoryContainer;
    @Input() fetchingGamingStoriesAPIRes: FetchUserResponseAPIModel;

    haveSelectedValue: boolean = false;
    isSelected: boolean = false;
    questionTextClr: string;
    questionBgClr: string;
    answersBgClr: string;
    answersTextClr: string;
    isDisabled: boolean;
    selectedOption: string;
    // gamingStoriesconfig: GamingStoriesPhase2Configuration;
    // expirationTime: number;

    private userSessionStorageData: StoryInfoDataInSessionStorage;
    private gamingStoriesconfig: GamingStoriesPhase2Configuration;
    private expirationTime: number;
    private templateType: string = 'QuesAndAns';
    private uAvgResponse: any;
    private userSubscription: Subscription;
    private showSiteCoreData: boolean = false;
    private trackStoriesSkip: boolean = false;

    constructor(
        private gamingStoriesService: GamingStoriesService,
        private user: UserService,
        private loginDialog: LoginDialogService,
    ) {}

    ngOnChanges() {
        this.setAttributes();
        this.selectedOption = '';
        this.isDisabled = false;
    }

    ngOnInit(): void {
        this.setAttributes();
        this.userSubscription = this.user.events.pipe(filter((e: any) => e instanceof UserLoginEvent)).subscribe(() => {
            setTimeout(() => {
                this.appendUserIdToPreLoginSession();
                this.validatePreLoginSessionToStoreInPostLoginSession();
            }, 1000);
        });
        this.postvalidationCheck();
    }

    onSelect(key: string): void {
        this.gamingStoriesconfig = this.gamingStoriesService.config;
        if (this.gamingStoriesconfig) {
            this.expirationTime = this.gamingStoriesconfig.expirationTime;
        }
        this.selectedOption = key;
        if (this.user.isAuthenticated) {
            if (key !== undefined) {
                this.gamingStoriesService.trackingGamingStoriesPhase2Event(
                    'teaser - embedded video',
                    'stories',
                    'click',
                    this.storyItem.question,
                    this.storyItem.title,
                    key,
                    this.storyItem.actionTypePosition,
                    'not applicable',
                );
                const storyInfo: StoryInfoData = new StoryInfoData();
                storyInfo.storyId = this.storyItem.storyId;
                storyInfo.subStoryId = this.storyItem.storyItemId;
                storyInfo.storyType = this.storyItem.storyType;
                storyInfo.templateType = this.templateType;
                storyInfo.userInput = key;
                storyInfo.expTime = this.storyItem.expiryTime;
                storyInfo.storyIndex = 1;
                // this.gamingStoriesService.storyInfoPostRequestList = this.gamingStoriesService.storyInfoPostRequestList.filter(
                //     (x) => x.storyIndex !== storyInfo.storyIndex,
                // );
                this.gamingStoriesService.storyInfoRequestItemsList(storyInfo);
                this.isSelected = true;
                this.isDisabled = true;
                this.haveSelectedValue = true;
                this.appendInputOrUavgbasedonSelectedPostData(this.selectedOption);
            }
            this.gamingStoriesService.pauseStory(false);
        } else {
            this.gamingStoriesService.storyInfoPostRequestList = [];
            this.gamingStoriesService.pauseStory(true);
            const loginDialogRef = this.loginDialog.open();
            const afterclosesubcription: Subscription = loginDialogRef.afterClosed().subscribe(() => {
                if (afterclosesubcription) {
                    if (!this.user.isAuthenticated) {
                        this.gamingStoriesService.pauseStory(false);
                        this.isDisabled = false;
                    }
                    afterclosesubcription.unsubscribe();
                }
            });
        }
        this.storeDataToPreLoginSession(key);
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    private setAttributes() {
        this.questionTextClr = this.storyContainerData.sharedList.questionTextClr;
        this.questionBgClr = this.storyContainerData.sharedList.questionBgClr;
        this.answersTextClr = this.storyContainerData.sharedList.answersTextClr;
        this.answersBgClr = this.storyContainerData.sharedList.answersBgClr;
    }

    private storeDataToPreLoginSession(eleName: string) {
        const currentEpocTime = new Date().getTime() / 1000;
        const storyInfoDataInSessionStorage: StoryInfoDataInSessionStorage = new StoryInfoDataInSessionStorage();
        storyInfoDataInSessionStorage.response = new ResponseDataModel();
        storyInfoDataInSessionStorage.response.sId = this.storyItem.storyId;
        storyInfoDataInSessionStorage.response.subSId = this.storyItem.storyItemId;
        storyInfoDataInSessionStorage.response.uInput = eleName;
        storyInfoDataInSessionStorage.response.tType = this.templateType;
        storyInfoDataInSessionStorage.response.uAvg = this.uAvgResponse;
        storyInfoDataInSessionStorage.expiryTime = Math.round(currentEpocTime + this.expirationTime * 60);
        storyInfoDataInSessionStorage.userId = this.user.id ? this.user.id : '';
        storyInfoDataInSessionStorage.storyIndex = 1;
        if (!this.user.isAuthenticated) {
            this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList =
                this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList.filter(
                    (x) => x.storyIndex !== storyInfoDataInSessionStorage.storyIndex,
                );
            this.gamingStoriesService.storingDataInPreLoginSessionStorage(storyInfoDataInSessionStorage);
        }
    }

    private appendInputOrUavgbasedonSelectedPostData(selectedOption: string) {
        if (sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage')) {
            const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage') as string);
            sessionStorageServiceStoryData.forEach((qsessionres: any) => {
                if (
                    qsessionres.userId === this.user.id &&
                    qsessionres.response.sId === this.storyItem.storyId &&
                    qsessionres.response.subSId === this.storyItem.storyItemId &&
                    qsessionres.response.uInput === undefined &&
                    qsessionres.response.uAvg === undefined
                ) {
                    qsessionres.response.uInput = selectedOption;
                    qsessionres.response.uAvg = new Map();
                    qsessionres.response.uAvg[qsessionres.response.uInput.toLowerCase()] = 100;
                    this.bindQuizData(qsessionres.response);
                } else if (
                    qsessionres.userId === this.user.id &&
                    qsessionres.response.sId === this.storyItem.storyId &&
                    qsessionres.response.subSId === this.storyItem.storyItemId &&
                    qsessionres.response.uInput === undefined &&
                    qsessionres.response.uAvg !== undefined
                ) {
                    qsessionres.response.uInput = selectedOption;
                    this.bindQuizData(qsessionres.response);
                }
            });
            sessionStorage.setItem('GS:StoryItemsInPostLoginSessionStorage', JSON.stringify(sessionStorageServiceStoryData));
        }
        // if (sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage')) {
        //   let sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage'));
        //   sessionStorageServiceStoryData.forEach(qsessionres => {
        //     if (qsessionres.userId === this.user.id && qsessionres.response.sId === this.storyItem.storyId && qsessionres.response.subSId === this.storyItem.storyItemId
        //       && qsessionres.response.uInput === undefined && qsessionres.response.uAvg !== undefined) {
        //       qsessionres.response.uInput = selectedOption;
        //       this.bindQuizData(qsessionres.response);
        //     }
        //   });
        //   sessionStorage.setItem('GS:StoryItemsInPostLoginSessionStorage', JSON.stringify(sessionStorageServiceStoryData));
        // }
    }

    private appendUserIdToPreLoginSession() {
        if (sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage')) {
            this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList.forEach((res) => {
                if (res.response.sId === this.storyItem.storyId && res.response.subSId === this.storyItem.storyItemId && res.userId === '') {
                    res.userId = this.user.id ? this.user.id : '';
                    this.userSessionStorageData = res;
                }
            });
            if (this.userSessionStorageData !== undefined) {
                this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList =
                    this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList.filter(
                        (x) => x.storyIndex !== this.userSessionStorageData.storyIndex,
                    );
                this.gamingStoriesService.storingDataInPreLoginSessionStorage(this.userSessionStorageData);
                this.preparePreLoginSelectedDataForPostAPI(this.userSessionStorageData);
            }
        }
    }

    private preparePreLoginSelectedDataForPostAPI(storyInfoData: StoryInfoDataInSessionStorage) {
        const storyInfo: StoryInfoData = new StoryInfoData();
        storyInfo.storyId = storyInfoData.response.sId;
        storyInfo.subStoryId = storyInfoData.response.subSId;
        storyInfo.storyType = this.storyItem.storyType;
        storyInfo.templateType = this.templateType;
        storyInfo.userInput = storyInfoData.response.uInput;
        storyInfo.expTime = this.storyItem.expiryTime;
        storyInfo.storyIndex = 1;
        this.gamingStoriesService.storyInfoPostRequestList = this.gamingStoriesService.storyInfoPostRequestList.filter(
            (x) => x.storyIndex !== storyInfo.storyIndex,
        );
        this.gamingStoriesService.storyInfoRequestItemsList(storyInfo);
    }

    private validatePreLoginSessionToStoreInPostLoginSession() {
        // if fetch API response available.
        // we are not storing selected data any where when it matches with fetch api response.
        if (this.fetchingGamingStoriesAPIRes) {
            if (sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage')) {
                const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage') as string);
                this.fetchingGamingStoriesAPIRes.response.forEach((fetchData: any) => {
                    sessionStorageServiceStoryData.forEach((res: any) => {
                        if (
                            res.userId === this.fetchingGamingStoriesAPIRes.uId &&
                            res.response.sId === fetchData.sId &&
                            res.response.subSId === fetchData.subSId
                        ) {
                            this.storePreLoginSessionToPostLoginSession(res);
                        }
                    });
                });
            }
        }
        // if fetch API response not available or failed scenario.
        // storing selected data in Service level and session level in post-login key.
        else {
            if (sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage')) {
                const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage') as string);
                sessionStorageServiceStoryData.forEach((res: any) => {
                    this.fetchUserPreparedRequest.reqStoryIds.forEach((fetchReq) => {
                        if (res.response.sId === fetchReq.storyId && res.response.subSId === fetchReq.subStoryId) {
                            this.storePreLoginSessionToPostLoginSession(res);
                        }
                    });
                });
            }
        }
    }

    private storePreLoginSessionToPostLoginSession(res: StoryInfoDataInSessionStorage) {
        if (sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage')) {
            const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage') as string);
            sessionStorageServiceStoryData.forEach((sessionRes: any) => {
                if (
                    sessionRes.userId === res.userId &&
                    sessionRes.response.sId === res.response.sId &&
                    sessionRes.response.subSId === res.response.subSId &&
                    sessionRes.response.uInput === undefined
                ) {
                    sessionRes.response.uInput = res.response.uInput;
                    this.gamingStoriesService.storeFetchAPIDataRes(sessionRes);
                }
            });
            sessionStorage.removeItem('GS:StoryItemsInPreLoginSessionStorage');
            this.preLoginCheckDataBind();
        }
    }

    private preLoginCheckDataBind() {
        if (this.user.isAuthenticated) {
            // fetch call response
            // if (this.fetchingGamingStoriesAPIRes) {
            //   this.fetchingGamingStoriesAPIRes.response.forEach(qfetchres => {
            //     if (this.fetchingGamingStoriesAPIRes.uId === this.user.id && qfetchres.sId === this.storyItem.storyId && qfetchres.subSId === this.storyItem.storyItemId && qfetchres.uAvg !== undefined && qfetchres.uInput !== undefined) {
            //       this.bindQuizData(qfetchres);
            //     }
            //   });
            // }
            // service level data check bind
            if (this.gamingStoriesService.storeFetchAPIDataResList.length > 0) {
                this.gamingStoriesService.storeFetchAPIDataResList.forEach((qserviceres: any) => {
                    if (
                        qserviceres.userId === this.user.id &&
                        qserviceres.response.sId === this.storyItem.storyId &&
                        qserviceres.response.subSId === this.storyItem.storyItemId &&
                        qserviceres.response.uInput !== undefined &&
                        qserviceres.response.uAvg !== undefined
                    ) {
                        this.bindQuizData(qserviceres.response);
                    }
                });
            }
            // session storage check bind
            else if (sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage')) {
                const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage') as string);
                sessionStorageServiceStoryData.forEach((qsessionres: any) => {
                    if (
                        qsessionres.userId === this.user.id &&
                        qsessionres.response.sId === this.storyItem.storyId &&
                        qsessionres.response.subSId === this.storyItem.storyItemId &&
                        qsessionres.response.uInput !== undefined &&
                        qsessionres.response.uAvg !== undefined
                    ) {
                        this.bindQuizData(qsessionres.response);
                    }
                });
            }
            this.gamingStoriesService.pauseStory(false);
        }
    }

    private postvalidationCheck() {
        if (this.user.isAuthenticated) {
            // session storage check bind
            this.showSiteCoreData = false;
            if (sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage')) {
                if (this.fetchingGamingStoriesAPIRes) {
                    const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage') as string);
                    this.fetchingGamingStoriesAPIRes.response.forEach((fetchData: any) => {
                        sessionStorageServiceStoryData.forEach((res: any) => {
                            if (
                                res.userId === this.fetchingGamingStoriesAPIRes.uId &&
                                res.response.sId === fetchData.sId &&
                                res.response.subSId === fetchData.subSId
                            ) {
                                this.storePreLoginSessionToPostLoginSession(res);
                            }
                        });
                    });
                }
            }
            // if (sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage') && sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage')) {
            //   let preSessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage'));
            //   let postSessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage'));
            //   postSessionStorageServiceStoryData.forEach(qsessionres => {
            //     if (qsessionres.userId === preSessionStorageServiceStoryData[0].userId && qsessionres.response.sId === preSessionStorageServiceStoryData[0].response.sId &&
            //       qsessionres.response.subSId === preSessionStorageServiceStoryData[0].response.subSId) {
            //         this.bindQuizData(qsessionres.response);
            //       }
            //   });
            // }
            if (sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage')) {
                const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage') as string);
                sessionStorageServiceStoryData.forEach((qsessionres: any) => {
                    if (
                        this.user.id === qsessionres.userId &&
                        this.storyItem.storyId === qsessionres.response.sId &&
                        this.storyItem.storyItemId === qsessionres.response.subSId
                    ) {
                        if (
                            (qsessionres.response.uInput === undefined && qsessionres.response.uAvg === undefined) ||
                            (qsessionres.response.uInput === undefined && qsessionres.response.uAvg !== undefined)
                        ) {
                            this.showSiteCoreData = true;
                        }
                    }
                });
            }
            if (sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage') && !this.showSiteCoreData) {
                const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage') as string);
                sessionStorageServiceStoryData.forEach((qsessionres: any) => {
                    if (
                        qsessionres.userId === this.user.id &&
                        qsessionres.response.sId === this.storyItem.storyId &&
                        qsessionres.response.subSId === this.storyItem.storyItemId &&
                        (qsessionres.response.uInput !== undefined || qsessionres.response.uAvg !== undefined)
                    ) {
                        this.bindQuizData(qsessionres.response);
                    }
                });
            }
            this.gamingStoriesService.pauseStory(false);
        }
    }

    private bindQuizData(res: any) {
        this.isSelected = true;
        this.haveSelectedValue = true;
        this.isDisabled = true;
        this.uAvgResponse = res.uAvg;
        this.storyItem.answers.forEach((item: IOption) => {
            if (this.uAvgResponse !== undefined) {
                const selectedAnswer = this.uAvgResponse[(item?.key || '').toLowerCase()];
                if (
                    (selectedAnswer === 100 || selectedAnswer === undefined) &&
                    this.storyItem.yesNoPollOption.length !== Object.keys(this.uAvgResponse).length
                ) {
                    const keys: any = Object.keys(this.uAvgResponse);
                    if (keys && keys.length > 0) {
                        for (let i = 0; i < keys.length; i++) {
                            if (item.key.toLowerCase() !== keys[i].toLowerCase() && this.uAvgResponse[item.key.toLowerCase()] === 0) {
                                this.uAvgResponse[item.key.toLowerCase()] = 0;
                            }
                        }
                    }
                }
                if (selectedAnswer === 0) {
                    item.selectedValue = 0;
                    this.selectedOption = res.uInput;
                } else if (selectedAnswer === 100) {
                    item.selectedValue = parseFloat(selectedAnswer);
                    if (this.selectedOption === undefined || this.selectedOption === '') this.selectedOption = item.key;
                } else if (selectedAnswer) {
                    item.selectedValue = parseFloat(selectedAnswer);
                    this.haveSelectedValue = false;
                    this.selectedOption = res.uInput;
                }
            } else {
                if (item.key.toLowerCase() === res.uInput.toLowerCase()) {
                    item.selectedValue = 100;
                    this.selectedOption = item.key;
                }
            }
        });
        this.storyItem.answers.forEach((item: IOption) => {
            if (item.key?.toLowerCase() === this.selectedOption?.toLowerCase() && !this.trackStoriesSkip) {
                this.trackStoriesSkip = true;
                this.gamingStoriesService.trackingGamingStoriesPhase2Event(
                    'teaser - embedded video',
                    'stories',
                    'click',
                    this.storyItem.question,
                    this.storyItem.title,
                    item.key + ' ' + ' - ' + ' ' + item.selectedValue + '%',
                    this.storyItem.actionTypePosition,
                    'not applicable',
                );
            }
        });
    }
}
