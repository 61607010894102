import { CurrencyPipe, NgClass, NgIf } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';

import { ConfigProviderService, Race, SitecoreContent, SlotRacesService } from '@casinocore/platform/core';
import { DeviceService } from '@frontend/vanilla/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { Subscription } from 'rxjs';

import { SlotRaceCardComponent } from '../cards-wrapper/card/card.component';
import { SlotRacesLeaderboardComponent } from '../leaderboard/leaderboard.component';
import { SlotRacesResultsComponent } from '../results/results.component';
import { SlotRacesRulesAndTnCComponent } from '../rules-points-tips-tnc/rules-and-tnc.component';
import { TournamentIconComponent } from '../tournament-icon/tournament-icon.component';

@Component({
    selector: 'cc-slot-races-info',
    templateUrl: 'race-info.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        MatDialogTitle,
        NgClass,
        TournamentIconComponent,
        SlotRacesLeaderboardComponent,
        SlotRaceCardComponent,
        SlotRacesRulesAndTnCComponent,
        TrustAsHtmlPipe,
    ],
    providers: [CurrencyPipe],
})
export class SlotRacesInfoComponent implements OnInit, OnDestroy, AfterViewInit {
    title: string;
    versionedData: any;
    sharedData: any;
    race: Race;
    category: any;
    isLive: boolean = false;
    contentSubscription: Subscription;
    liveRacesSubscription: Subscription;
    isUpcoming: boolean;
    lobbyType: string;
    fromResult: boolean = false;
    raceResult: any;
    raceinfoopt: any;
    displayBonusAsText: boolean;
    resultDialogRef: MatDialogRef<SlotRacesResultsComponent>;
    enableResultsHistoryPage: boolean = false;
    isMobileView: boolean;
    coinImg: String;
    coinObj: any;

    constructor(
        private dialogRef: MatDialogRef<SlotRacesInfoComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private slotRacesService: SlotRacesService,
        private currencyPipe: CurrencyPipe,
        private configProviderService: ConfigProviderService,
        private deviceService: DeviceService,
    ) {
        let configs = this.configProviderService.provideSlotRacesConfig();
        this.enableResultsHistoryPage = configs.enableResultsHistoryPage;
        this.displayBonusAsText = configs.displayBonusAsText;
        this.coinImg = data.coinImg;

        if (this.enableResultsHistoryPage && data.raceResult) {
            this.raceResult = data.raceResult;
            this.fromResult = true;
            this.resultDialogRef = data.resultsDialogRef;
            this.raceResult?.configMaps?.forEach((configMap: any) => {
                if (configMap && configMap?.awardType) {
                    if (configMap && configMap?.awardType.toLowerCase() != 'coins') {
                        if (this.raceResult.isWin && configMap?.value) {
                            this.raceResult.awardType = configMap.awardType;
                            if (!this.displayBonusAsText) {
                                if (
                                    configMap?.awardType?.toLowerCase() == 'freespins' ||
                                    configMap?.awardType?.toLowerCase() == 'free_spin' ||
                                    !configMap?.currency ||
                                    !configMap?.currency?.length
                                ) {
                                    this.raceResult.awardValue = Number(configMap.value);
                                } else {
                                    this.raceResult.awardValue = this.currencyPipe.transform(
                                        configMap?.value,
                                        configMap?.currency,
                                        'symbol-narrow',
                                        '1.2-2',
                                    );
                                }
                            } else {
                                if (configMap && configMap.awardType && configMap.awardType?.toLowerCase().indexOf('cash') > -1) {
                                    this.raceResult.awardValue = this.currencyPipe.transform(
                                        configMap.value,
                                        configMap.currency,
                                        'symbol-narrow',
                                        '1.2-2',
                                    );
                                } else {
                                    this.raceResult.awardValue = Number(configMap.value);
                                }
                            }
                        } else {
                            this.raceResult.awardValue = '--';
                        }
                    }
                    if (configMap && configMap?.awardType.toLowerCase() == 'coins') {
                        this.raceResult.isDisplayCoin = true;
                        this.raceResult.coinValue = this.raceResult.isDisplayCoin ? configMap.value : '';
                    }
                } else {
                    this.raceResult.awardValue = '--';
                }
            });
        }

        this.race = data.race;
        this.isLive = !!this.race?.isLive;
        this.isUpcoming = !this.isLive;
        this.category = data.category;
        this.lobbyType = data.lobbyType;
        this.raceinfoopt = data.enableOptin;
        this.coinObj = this.slotRacesService.bindCoinImg(this.race, data.coinImg);
    }

    content: SitecoreContent = {
        raceRuleConfigs: [],
        rules: [],
        rulesBanner: {},
        placeholderCardImage: {},
        coinEconomy: {},
        textTranslations: { sharedList: {}, versionedList: {} },
        tips: [],
        errorMessages: { sharedList: {}, versionedList: {} },
        entryDetails: { sharedList: {}, versionedList: {} },
    };

    ngOnInit() {
        if (!this.fromResult) {
            this.liveRacesSubscription = this.slotRacesService.liveRacesData.subscribe((data) => {
                if (data) {
                    this.updateRaceStatus(data);
                }
            });
        } else if (this.enableResultsHistoryPage && this.fromResult) {
            this.dialogRef.backdropClick().subscribe(() => {
                if (this.resultDialogRef) {
                    this.resultDialogRef.close();
                }
            });
            this.dialogRef.keydownEvents().subscribe((event) => {
                if (event.keyCode === 27) {
                    this.resultDialogRef.close();
                }
            });
        }
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.content = content;
            this.versionedData = content.textTranslations.versionedList;
            this.sharedData = content.textTranslations.sharedList;
            if (this.versionedData) {
                if (this.race && !this.race.isLive) {
                    this.title = this.versionedData.UpcomingRaceOverlayTitle ? this.versionedData.UpcomingRaceOverlayTitle : '';
                } else {
                    this.title = this.versionedData.LiveRaceOverlayTitle ? this.versionedData.LiveRaceOverlayTitle : '';
                }
            }
        });
    }

    close(dialogResult: any = {}) {
        if (this.enableResultsHistoryPage && this.fromResult) {
            let resultsDialog = document.getElementsByClassName('sr-results-dialog')[0] as HTMLElement;
            resultsDialog.style.display = '';
        }
        this.dialogRef.close(dialogResult);
    }

    openPickGameOverlay() {
        this.close({ openPickGameOverlay: true });
    }
    openNotificationDialog() {
        this.close({ openNotificationOverlay: true });
    }

    updateRaceStatus(data: any) {
        let liveRaces: any[];
        liveRaces = data.liveNow;
        let isLiveRace = liveRaces.some((race: Race) => {
            if (race.promoId == this.race.promoId && race.slotUniqueId == this.race.slotUniqueId) {
                this.race.isLive = true;
            }
            return this.race.isLive;
        });
        let isUpcomingRace = !isLiveRace;
        if (this.isLive !== isLiveRace || this.isUpcoming !== isUpcomingRace) {
            this.close();
        }
    }

    ngOnDestroy() {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
        if (this.liveRacesSubscription) {
            this.liveRacesSubscription.unsubscribe();
        }
    }
    ngAfterViewInit() {
        let featuresConfig = this.configProviderService.provideFeaturesConfig();
        if (featuresConfig.enableOverlayMobileSearchTopPosition && this.deviceService.isTouch) {
            this.slotRaceOverlayTopAllignment();
        }
    }
    slotRaceOverlayTopAllignment() {
        if (document.querySelector('.content-messages-header-top .content-message-container')) {
            let contentMessageElement: HTMLElement = document.querySelector('.content-messages-header-top .content-message-container')!;
            let sr1_overlay: HTMLElement = document.querySelector('.casino-race-info-container')!;
            sr1_overlay.style.top = contentMessageElement?.clientHeight + 'px';
        }
    }
}
