<div id="csSlotRaceTnC" *ngIf="!isCollapsible" class="non-collapsable-content terms-and-cond">
    <div class="casino-slotraces-tnc">
        <div class="tnc-title">
            <div *ngIf="showHeader" class="panel-title" id="tnc-panel-title">
                {{ sitecoreData?.textTranslations?.versionedList?.TermsAndConditions }}
            </div>
        </div>
        <div class="panel-text">
            <div class="dropdown-text">
                <div class="tnc-text" *ngIf="!race">
                    <vn-page-matrix [path]="'MobileCasino-v1.0/CasinoSlotRaces/TermsAndConditions/TnC/TnCHtml'"> </vn-page-matrix>
                </div>
                <div class="tnc-text" *ngIf="race">
                    <div [innerHtml]="tncData | trustAsHtml"></div>
                </div>
            </div>
        </div>
    </div>
</div>
