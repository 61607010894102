<div *ngIf="hasRecords">
    <div *ngFor="let room of rooms; let row_no = index">
        <bg-collapsible-container
            id="contactInformation"
            title="{{ room.room_name }}"
            gameCategory="{{ room.bingo_type }}"
            [jackpot]="room.isJackpot"
            subTitle="{{ room.formatted_start_time }}"
            [image]="myticketscollist.columns.showImage == 'true' ? room.image : ''"
            [iconimage]="room?.iconimage"
            [isCollapsed]="true"
            (toggle)="toggle($event)">
            <div
                class="my-ticket-grid-container"
                id="my-ticket-grid-container-{{ row_no }}"
                [ngClass]="room.isFriendsRoom ? 'friends-my-tickets' : room.isFabGrabRoom ? 'frabgrab-mytickets friends-my-tickets' : ''">
                <div class="grid-row grid-header">
                    <div class="grid-header-cell"><span class="theme-calendar"></span>{{ myticketscollist.columns.gamestart }}</div>
                    <div class="grid-header-cell mytickets-title">
                        {{ myticketscollist.columns.title }}
                    </div>
                    <div class="grid-header-cell col-13" *ngIf="room.isFriendsRoom">
                        {{ myticketscollist.columns.teamText }}
                    </div>
                    <div class="grid-header-cell mytickets-feature">{{ myticketscollist.columns.feature }}</div>
                    <div class="grid-header-cell mytickets-price">
                        <span [ngClass]="prebuyIconslist.columns.priceIcon"></span>{{ myticketscollist.columns.price }}
                    </div>
                    <div class="grid-header-cell mytickets-minprize">
                        <span [ngClass]="prebuyIconslist.columns.winPrizeIcon"></span>{{ myticketscollist.columns.minprize }}
                    </div>
                    <div class="grid-header-cell mytickets-purchased">
                        <span [ngClass]="prebuyIconslist.columns.PurchaseIcon"></span>{{ myticketscollist.columns.purchased }}
                    </div>
                    <!-- <div class="grid-header-cell mytickets-tapandtail">{{myticketscollist.columns.toptailText}}</div> -->
                    <div class="grid-header-cell mytickets-tapandtail" *ngIf="room.isFabGrabRoom">&nbsp;</div>
                    <!-- <div class="grid-header-cell mytickets-buying"  *ngIf="room.displayHeaderForSessionRoom">
                        <span class="theme-ticket"></span>{{myticketscollist.columns.buyingText}}</div> -->
                    <div class="grid-header-cell mytickets-slider"><span [ngClass]="prebuyIconslist.columns.ticketsIcon"></span>Tickets</div>
                </div>
                <div *ngFor="let ticket of room.my_tickets; let col_no = index">
                    <div *ngIf="room.isFriendsRoom">
                        <div
                            class="teamList team-dd-mob"
                            [ngStyle]="{
                                'color': ticket.game_context && ticket.game_context.TeamBingo.current_team != null ? 'white' : '',
                                'background-color': teamColors[ticket.game_context.TeamBingo.current_team]
                            }"
                            (click)="selectTeam(row_no, col_no)">
                            <span class="teamName" *ngIf="ticket.game_context && ticket.game_context.TeamBingo.current_team == null">
                                Select your team</span
                            >
                            <span class="teamName" *ngIf="ticket.game_context && ticket.game_context.TeamBingo.current_team != null">{{
                                ticket.game_context.TeamBingo.current_team | lowercase
                            }}</span>
                            <span class="teamddArrow theme-arrow-down"></span>
                        </div>
                    </div>
                    <div class="grid-row" id="{{ row_no + '_' + col_no }}" [ngClass]="rowIndex === row_no + '_' + col_no ? 'focusclass' : ''">
                        <div class="grid-inline">
                            <span> {{ ticket.displayTime }}</span>
                        </div>
                        <div class="grid-hide-col mytickets-title">{{ ticket.display_name }}</div>
                        <div class="grid-hide-col col-13" *ngIf="room.isFriendsRoom">
                            <div
                                class="teamList team-dd"
                                [ngStyle]="{
                                    'color': ticket.game_context && ticket.game_context.TeamBingo.current_team != null ? 'white' : '',
                                    'background-color': teamColors[ticket.game_context.TeamBingo.current_team]
                                }"
                                (click)="selectTeam(row_no, col_no)">
                                <span class="teamName" *ngIf="ticket.game_context && ticket.game_context.TeamBingo.current_team == null"
                                    >Select team</span
                                >
                                <span class="teamName" *ngIf="ticket.game_context && ticket.game_context.TeamBingo.current_team != null">{{
                                    ticket.game_context.TeamBingo.current_team | lowercase
                                }}</span>
                                <span class="teamddArrow theme-arrow-down"></span>
                            </div>
                        </div>
                        <div class="grid-hide-col pb-feature mytickets-feature">
                            <span *ngFor="let item of ticket.features" class="prebuy-feature-icons my-tickets-icons">
                                <span class="new-only" *ngIf="item == 'new'">{{ myticketscollist.columns.featureNewText }}</span>
                                <span *ngIf="item == 'cashonly'" class="cash-only"> {{ myticketscollist.columns.featureCashonlyText }}</span>

                                <bg-feature-icon [feature]="item"></bg-feature-icon>
                            </span>
                            <span *ngIf="ticket.features === undefined || ticket.features.length == 0">-</span>
                        </div>
                        <div class="grid-inline mytickets-price">
                            <i [ngClass]="prebuyIconslist.columns.priceIcon"></i>
                            <span *ngIf="ticket.ticket_cost && !ticket.isMultistake && !ticket.isSlingoMultiStake">{{
                                ticket.ticket_cost.Cash | vnCurrency
                            }}</span>
                            <span *ngIf="ticket.isMultistake">
                                {{
                                    ticket.game_context.VariableCardStake.price[ticket.game_context.VariableCardStake.ticket_currency_key]
                                        | currency: ticket.currency
                                }}
                            </span>
                            <span *ngIf="ticket.isSlingoMultiStake">
                                {{
                                    ticket.game_context?.SlingoMultiStake?.price[ticket.game_context?.SlingoMultiStake?.ticket_currency_key]
                                        | currency: ticket.currency
                                }}
                            </span>
                        </div>
                        <div class="grid-inline jackpot-col mytickets-minprize">
                            <i [ngClass]="prebuyIconslist.columns.winPrizeIcon"></i>
                            <span
                                *ngIf="
                                    (ticket.features
                                        ? ticket.features.indexOf('physical_prize') == -1 && !(enableBonusImplementation && ticket.bonus_info)
                                        : true) &&
                                    ticket.winning_amount &&
                                    !ticket.isMultistake &&
                                    !ticket.isSlingoMultiStake
                                ">
                                {{ ticket.winning_amount.Cash | vnCurrency }}
                            </span>
                            <span *ngIf="ticket.isMultistake || ticket.isSlingoMultiStake">
                                {{ ticket.winning_amount | vnCurrency }}
                            </span>
                            <span
                                *ngIf="
                                    ticket.features &&
                                    ticket.features.indexOf('physical_prize') > -1 &&
                                    !(enableBonusImplementation && ticket.bonus_info)
                                "
                                >{{ physicalPrizeText }}</span
                            >
                            <span *ngIf="ticket.features && enableBonusImplementation && ticket.bonus_info">{{ bonusPrizeText }}</span>
                        </div>
                        <div class="ticket-mobile-purchase ticket-mobile-purchase-display" *ngIf="!disableAllPrebuy">
                            <span>{{ ticket.purchased_count }}</span>
                            <span class="bg-slash-span">&#47;</span>
                            <span class="max-tickets">{{ ticket.maximum_tickets }}</span>
                            <span
                                *ngIf="ticket.maximum_tickets > ticket.purchased_count && ticket.displayHeaderForSessionRoom"
                                [class]="ticket.maximum_tickets != ticket.purchased_count ? 'theme-plus' : ''"
                                [ngStyle]="{
                                    'background-color':
                                        ticket.game_context && ticket.game_context.TeamBingo && ticket.game_context.TeamBingo.current_team != null
                                            ? teamColors[ticket.game_context.TeamBingo.current_team]
                                            : ''
                                }"
                                (click)="addTickets(row_no + '_' + col_no, row_no, col_no, ticket.purchased_count)"></span>
                            <!-- <span class="theme-plus" (click)="addTickets(col_no) "></span> -->
                        </div>
                        <div class="ticket-mobile-purchase ticket-mobile-purchase-hidden textbox-col">
                            <span>{{ ticket.purchased_count }}</span
                            >&nbsp;+&nbsp;
                            <input
                                type="tel"
                                min="0"
                                max="{{ ticket.maximum_tickets - ticket.purchased_count }}"
                                [ngModel]="default_purchased_count"
                                (blur)="onBlurDetect($event, row_no, col_no)"
                                (focus)="clearValue($event)"
                                (input)="onChange($event, row_no, col_no)"
                                class="{{ row_no }}_{{ col_no }}cls"
                                (keypress)="keyHandler($event)"
                                [class.txtRedColor]="ticket.istxtRedCls"
                                [class.txtborderGreen]="!ticket.istxtRedCls && ticket.purchased_count > 0"
                                [ngStyle]="{
                                    'border-color':
                                        ticket.game_context && ticket.game_context.TeamBingo && ticket.game_context.TeamBingo.current_team != null
                                            ? teamColors[ticket.game_context.TeamBingo.current_team]
                                            : ''
                                }" />

                            <span class="bg-slash-span">&#47;</span>
                            <span class="max-tickets">{{ ticket.maximum_tickets }}</span>
                            <span class="theme-ex" (click)="removeTickets(row_no + '_' + col_no, row_no, col_no, ticket.purchased_count)"></span>
                        </div>
                        <div class="grid-hide-col mytickets-purchased">
                            <span>{{ ticket.purchased_count }}</span>
                            <span class="bg-slash-span">&#47;</span>
                            <span>{{ ticket.maximum_tickets }}</span>
                        </div>
                        <div class="pb-fab grid-hide-col" *ngIf="room.isFabGrabRoom">
                            <div
                                class="teamList fb-list team-fb"
                                [ngClass]="
                                    ticket.game_context.FomoBingo.fomo_numbers && ticket.game_context.FomoBingo.fomo_numbers.length > 0
                                        ? 'fab-selected'
                                        : ''
                                ">
                                <span class="teamName">{{ prebuynavcolitems.columns.topnTailNoText }}</span>
                                <span
                                    class="numberSelect"
                                    *ngIf="ticket.maximum_tickets > ticket.purchased_count"
                                    (click)="selectNumbers(row_no, col_no)"
                                    >{{
                                        ticket.game_context.FomoBingo.fomo_numbers && ticket.game_context.FomoBingo.fomo_numbers.length > 0
                                            ? prebuynavcolitems.columns.editText
                                            : prebuynavcolitems.columns.selectText
                                    }}</span
                                >
                                <span *ngIf="ticket.maximum_tickets == ticket.purchased_count" class="numberSelect locked">
                                    {{ prebuynavcolitems.columns.lockedText }}</span
                                >
                                <span
                                    class="selectCount"
                                    *ngIf="ticket.game_context.FomoBingo.fomo_numbers && ticket.game_context.FomoBingo.fomo_numbers.length > 0"
                                    >{{ ticket.game_context.FomoBingo.fomo_numbers.length }}</span
                                >
                            </div>
                        </div>
                        <div
                            class="grid-hide-col mytickets-slider"
                            *ngIf="ticket.maximum_tickets > ticket.purchased_count && ticket.displayHeaderForSessionRoom">
                            <bg-prebuy-slider
                                [id]="row_no"
                                [value]="ticket.current_purchased_count == null ? 0 : ticket.current_purchased_count"
                                [min]="0"
                                [maxium]="ticket.maximum_tickets - ticket.purchased_count"
                                (onSelected)="Selected(row_no, col_no, $event)"
                                [toggle]="collapse"></bg-prebuy-slider>
                        </div>
                        <!-- <div class="pbg-row-column slider grid-hide-col col-13 mytickets-buying"
                            *ngIf="ticket.maximum_tickets > ticket.purchased_count && ticket.displayHeaderForSessionRoom">
                            <bg-slider id="{{row_no}}_{{col_no}}" min="0"
                                max="{{ticket.maximum_tickets - ticket.purchased_count}}"
                                [value]="ticket.current_purchased_count == null ? 0 : ticket.current_purchased_count"
                                (onSelected)="Selected(row_no, col_no, $event)"></bg-slider>
                        </div> -->
                        <!-- <div class="pbg-row-column btnSpin grid-hide-col pb-btns col-13"
                            *ngIf="ticket.maximum_tickets > ticket.purchased_count && ticket.displayHeaderForSessionRoom">
                            <bg-touch-spin id="{{row_no}}_{{col_no}}" min="0"
                                [color]="ticket.game_context && ticket.game_context.TeamBingo && ticket.game_context.TeamBingo.current_team != null?teamColors[ticket.game_context.TeamBingo.current_team]:''"
                                max="{{ticket.maximum_tickets - ticket.purchased_count}}"
                                [value]="ticket.current_purchased_count == null ? 0 : ticket.current_purchased_count"
                                (onSelected)="Selected(row_no, col_no, $event)"></bg-touch-spin>
                        </div> -->
                        <div class="pbg-row-column max-reached-col grid-hide-col" *ngIf="ticket.maximum_tickets == ticket.purchased_count">
                            <!-- <span>{{maximumTicketsReached}}</span> -->
                            <span
                                class="max-purchased"
                                [ngStyle]="{
                                    'background-color':
                                        ticket.game_context && ticket.game_context.TeamBingo && ticket.game_context.TeamBingo.current_team != null
                                            ? teamColors[ticket.game_context.TeamBingo.current_team] + '10'
                                            : ''
                                }">
                                <i class="theme-not-available"></i>{{ maximumTicketsReached }}</span
                            >
                        </div>
                    </div>
                    <div *ngIf="room.isFabGrabRoom" class="team-dd-mob">
                        <div
                            *ngIf="ticket.game_context.FomoBingo.fomo_numbers == null || ticket.game_context.FomoBingo.fomo_numbers.length == 0"
                            class="teamList fb-list team-fb-mob">
                            <span class="teamName">{{ prebuynavcolitems.columns.topnTailNoText }}</span>
                            <span class="numberSelect" (click)="selectNumbers(row_no, col_no)">{{ prebuynavcolitems.columns.selectNumbersText }}</span
                            ><i class="theme-info"></i>
                        </div>
                        <div class="fab" *ngIf="ticket.game_context.FomoBingo.fomo_numbers && ticket.game_context.FomoBingo.fomo_numbers.length > 0">
                            <span class="teamName">{{ prebuynavcolitems.columns.topnTailNumberText }}</span>
                            <div class="sel-numbers">
                                <span *ngFor="let number of ticket.game_context.FomoBingo.fomo_numbers" class="numballs active"> {{ number }}</span>
                                <span
                                    *ngIf="ticket.maximum_tickets > ticket.purchased_count"
                                    class="numberSelect"
                                    (click)="selectNumbers(row_no, col_no)"
                                    >{{ prebuynavcolitems.columns.editText }}</span
                                >
                                <span *ngIf="ticket.maximum_tickets == ticket.purchased_count" class="numberSelect">{{
                                    prebuynavcolitems.columns.lockedText
                                }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </bg-collapsible-container>
    </div>
</div>
<div *ngIf="!hasRecords" class="noRecords">
    {{ noTicketsPurchasedMessage }}
</div>
