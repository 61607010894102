import { LowerCasePipe, NgClass, NgIf, NgStyle, NgSwitch, NgSwitchCase } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
    ConfigProviderService,
    FetchUserRequestAPIModel,
    FetchUserResponseAPIModel,
    GamingStoriesService,
    GamingStoriesStorageCloseModel,
    IGamingStory,
    IGamingStoryContainer,
    IGamingStoryItem,
    UrlUtilityService,
} from '@casinocore/platform/core';
import { addClass } from '@casinocore/platform/utils';
import { CloudflareStreamComponent, CloudflareStreamModule } from '@cloudflare/stream-angular';
import { DeviceService, Message, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { Subject, Subscription, filter, takeUntil } from 'rxjs';

import { GamingStoryCtaComponent } from '../gaming-story-cta/gaming-story-cta.component';
import { GamingStoryEmojiSliderComponent } from '../gaming-story-emoji-slider/gaming-story-emoji-slider.component';
import { GamingStoryEmojisAnimationsComponent } from '../gaming-story-emojis-animations/gaming-story-emojis-animations.component';
import { GamingStoryOptInComponent } from '../gaming-story-optin/gaming-story-optin.component';
import { GamingStoryProgressBarComponent } from '../gaming-story-progress-bar/gaming-story-progress-bar.component';
import { GamingStoryQuizComponent } from '../gaming-story-quiz/gaming-story-quiz.component';
import { GamingStoryYesNoPollComponent } from '../gaming-story-yesno-poll/gaming-story-yesno-poll.component';

export enum StoryItemActionType {
    EmojiSelector = 'EmojiSelector',
    YesNoPoll = 'YesNoPoll',
    EmojiSlider = 'EmojiSlider',
    QuestionAndAnswer = 'QuestionAndAnswer',
    CustomCTA = 'CustomCTA',
    Promos = 'Promos',
}

@Component({
    selector: 'cc-gaming-story-items',
    templateUrl: 'gaming-story-items.component.html',
    standalone: true,
    imports: [
        NgIf,
        NgStyle,
        NgClass,
        CloudflareStreamModule,
        GamingStoryProgressBarComponent,
        NgSwitch,
        NgSwitchCase,
        GamingStoryEmojisAnimationsComponent,
        GamingStoryYesNoPollComponent,
        GamingStoryEmojiSliderComponent,
        GamingStoryQuizComponent,
        GamingStoryCtaComponent,
        GamingStoryOptInComponent,
        LowerCasePipe,
    ],
})
export class GamingStoryItemsComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('storyItemsContainer', { read: ElementRef }) scrollContainer: ElementRef;
    @ViewChild('swipeupContentFrame', { read: ElementRef }) swipeUpContentContainer: ElementRef;
    @ViewChild('casinoAudio', { static: false }) casinoAudio: ElementRef<HTMLAudioElement>;
    @ViewChild('casinoAudioButton', { static: false }) casinoAudioButton: ElementRef<HTMLDivElement>;
    @ViewChild('gamingStoriesItemsdrag', { static: false }) gamingStoriesItemsdraggable: ElementRef<HTMLDivElement>;
    @ViewChild('cmsVideoPlayer') cmsVideoPlayer: CloudflareStreamComponent;

    defaultSrc: string;
    closebtnClr: string;
    pause: boolean;
    activeStoriesIndex: number;
    storyContainerData: IGamingStoryContainer;
    activeStoryItemIndex: number;
    storyItemList: Array<IGamingStoryItem>;
    storyItem: IGamingStoryItem;
    showPrevButton: boolean = true;
    showNextButton: boolean = true;
    displaySwipeUp: boolean;
    displayDownArrow: boolean;
    swipeupClr: string;
    swipeupTextColor: string;
    swipeupText: string;
    storyIconShape: string;
    displaySwipeUpContent: boolean;
    promotionTitleClr: string;
    promotionTitleBgClr: string;
    swipeUpContentData: IGamingStoryItem;
    videoURL: string;
    cmsVideoURL: string;
    storyHasVideo: boolean;
    muteunmuteClr: string;
    mute: boolean = true;
    soundDisabled: boolean;
    storyItemBackgroundClr: string;
    userAuthentication: boolean;
    swipeUpBtnPosition: string;
    fetchingGamingStoriesAPIRes: FetchUserResponseAPIModel;
    storyItemActionTypes = StoryItemActionType;
    loadFeatures: boolean;
    fetchUserPreparedRequest: FetchUserRequestAPIModel;
    zIndex: number;
    private readonly SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight', UP: 'swipeUp', TAP: 'tap' };
    private destroy$ = new Subject<void>();
    private gamingStoriesClientConfig: any;
    private disableTap: boolean;
    private counter: number = 0;
    private enableSwipeUp: boolean;
    private swipeUpContentDataList: Array<IGamingStoryItem>;
    private activeStoryData: IGamingStory;
    private fetchAPIObservable: Subscription;
    private playpauseObservable: Subscription;

    constructor(
        public matDialogRef: MatDialogRef<GamingStoryItemsComponent>,
        private gamingStoriesService: GamingStoriesService,
        private deviceservice: DeviceService,
        private user: UserService,
        private configProviderService: ConfigProviderService,
        private changeDetectorRef: ChangeDetectorRef,
        private urlUtilityService: UrlUtilityService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.playPauseObservable();
    }

    ngOnInit(): void {
        addClass('mobile-stories-enables');
        this.defaultSrc = this.urlUtilityService.getGrayImageUrl();
        this.user.events
            .pipe(takeUntil(this.destroy$))
            .pipe(filter((e: any) => e instanceof UserLoginEvent))
            .subscribe(() => {
                setTimeout(() => {
                    this.loadFeatures = false;
                    this.getFetchingUserResponseAPIData('loginEvent');
                }, 1000);
            });
        this.identifyLandscapeToPortrait();
        this.gamingStoriesClientConfig = this.configProviderService.provideGamingStoriesConfig();
        this.storyContainerData = this.data.storyContainerData;
        this.storyItemList = this.data.storyItem;
        this.fetchUserPreparedRequest = this.data.fetchUserPreparedRequest;
        this.activeStoriesIndex = this.data.activeStoriesIndex;
        this.fetchingGamingStoriesAPIRes = this.data.fetchingGamingStoriesAPIRes;
        this.activeStoryItemIndex = this.storyItemList[0].activeStoryItemIndex;
        this.storyItem = this.data.storyContainerData.storyList[this.activeStoriesIndex].storyItems[this.activeStoryItemIndex];
        if (this.storyItem === undefined) {
            this.activeStoryItemIndex = this.activeStoryItemIndex - 1;
            this.storyItem = this.data.storyContainerData.storyList[this.activeStoriesIndex].storyItems[this.activeStoryItemIndex];
        }
        this.closebtnClr = this.data.storyContainerData.sharedList.closebtnClr;
        this.swipeupClr = this.data.storyContainerData.sharedList.swipeupClr;
        this.swipeupText = this.data.storyContainerData.versionedList.swipeupText;
        this.promotionTitleClr = this.data.storyContainerData.sharedList.promotionTitleClr;
        this.promotionTitleBgClr = this.data.storyContainerData.sharedList.promotionTitleBgClr;
        this.swipeupTextColor = this.data.storyContainerData.sharedList.swipeupTextColor;
        this.muteunmuteClr = this.data.storyContainerData.sharedList.muteunmuteClr;
        this.activeStoryData = this.data.storyContainerData.storyList[this.activeStoriesIndex];
        this.userAuthentication = this.user.isAuthenticated;
        this.storyItemBackgroundClr = this.storyItem.configurations.storyItemBackgroundClr;
        this.swipeUpContentDataList = this.data.swipeUpContentDataList;
        this.storyIconShape = this.storyContainerData.storyIconType ? this.storyContainerData.storyIconType : 'Square';
        this.fetchSwipeUpContent();
        this.gamingStoriesService.muteVideo = 'muted';
        this.toggleMute(this.storyItem, null);
        this.setVideoAndAudio(this.storyItem);
        let storyContainerList = new GamingStoriesStorageCloseModel();
        storyContainerList.storyList = this.data.storyContainerData.storyList;
        storyContainerList.activeStoriesIndex = this.activeStoriesIndex;
        storyContainerList.activeStoryItemIndex = this.activeStoryItemIndex;
        this.gamingStoriesService.storeGamingStoriesCloseContainer(storyContainerList);
        this.swipeUpBtnPosition = this.storyItem.actionTypePosition;
        this.gamingStoriesService.trackingGamingStoriesPhase2Event(
            'teaser - embedded video',
            'stories',
            'click',
            'not applicable',
            this.storyItem.title,
            'Story icon',
            this.storyItem.actionTypePosition,
            'not applicable',
        );
        this.loadFeatures = true;
    }

    ngAfterViewInit() {
        this.setupSwipeAndTapEvents();
        this.setVideoAndAudio(this.storyItem);
    }

    videoLoaded() {
        this.gamingStoriesService.playVideo();
        if (this.gamingStoriesService.storyPaused) {
            this.gamingStoriesService.pauseVideo();
        }
    }

    toggleMute(storyItem: IGamingStoryItem, $event: Event | null) {
        if (this.gamingStoriesService.muteVideo == 'muted') {
            this.gamingStoriesService.muteVideo = 'not_muted';
        } else {
            this.gamingStoriesService.muteVideo = 'muted';
        }
        if ($event !== null) {
            if (this.gamingStoriesService.muteVideo === 'muted') {
                this.gamingStoriesService.trackingGamingStoriesPhase2Event(
                    'teaser - embedded video',
                    'stories',
                    'click',
                    'not applicable',
                    storyItem.title,
                    'mute',
                    storyItem.actionTypePosition,
                    'not applicable',
                );
            } else {
                this.gamingStoriesService.trackingGamingStoriesPhase2Event(
                    'teaser - embedded video',
                    'stories',
                    'click',
                    'not applicable',
                    storyItem.title,
                    'Unmute',
                    storyItem.actionTypePosition,
                    'not applicable',
                );
            }
        }
        this.gamingStoriesService.videoMuteBehaviourSubject.next(this.gamingStoriesService.muteVideo);
        $event && $event.stopImmediatePropagation();
    }

    togglePausePlay(storyItem: IGamingStoryItem, $event: Event) {
        this.gamingStoriesService.pausePlayStory = !this.pause;
        if (this.gamingStoriesService.pausePlayStory) {
            this.casinoAudio?.nativeElement.pause();
            this.soundDisabled = true;
            this.gamingStoriesService.trackingGamingStoriesPhase2Event(
                'teaser - embedded video',
                'stories',
                'click',
                'not applicable',
                storyItem.title,
                'pause',
                storyItem.actionTypePosition,
                'not applicable',
            );
        } else {
            if (!this.casinoAudio?.nativeElement?.ended) {
                this.casinoAudio?.nativeElement.play();
            }
            this.soundDisabled = false;
            this.gamingStoriesService.trackingGamingStoriesPhase2Event(
                'teaser - embedded video',
                'stories',
                'click',
                'not applicable',
                storyItem.title,
                'play',
                storyItem.actionTypePosition,
                'not applicable',
            );
        }
        this.gamingStoriesService.pauseStoryBehaviourSubject.next(this.gamingStoriesService.pausePlayStory);
        $event && $event.stopImmediatePropagation();
    }

    onGameIconError(storyItem: any): void {
        storyItem.storyImage.src = this.defaultSrc;
    }

    onAudioPlay(storyItem: IGamingStoryItem, $event: Event): void {
        this.gamingStoriesService.trackingGamingStoriesPhase2Event(
            'teaser - embedded video',
            'stories',
            'click',
            'not applicable',
            storyItem.title,
            'play',
            storyItem.actionTypePosition,
            'not applicable',
        );
        if (this.mute) {
            this.mute = !this.mute;
            if (!this.casinoAudio?.nativeElement?.ended) {
                this.casinoAudio?.nativeElement.play();
            }
        } else {
            this.mute = !this.mute;
            this.casinoAudio?.nativeElement.pause();
        }
        $event && $event.stopImmediatePropagation();
    }

    storyCompletionEventFromProgressBar(event: any) {
        let activeStoriesIndex = event.activeStoriesIndex;
        let activeStoryItemIndex = event.activeStoryItemIndex;
        if (activeStoriesIndex + 1 < this.data.storyContainerData.storyList.length) {
            if (activeStoryItemIndex + 1 < this.data.storyContainerData.storyList[this.activeStoriesIndex].storyItems.length) {
                this.goToNextStory(activeStoriesIndex, activeStoryItemIndex);
            } else {
                this.goToNextPromo(activeStoriesIndex, activeStoryItemIndex);
            }
        } else if (activeStoriesIndex + 1 == this.data.storyContainerData.storyList.length) {
            if (activeStoryItemIndex + 1 < this.data.storyContainerData.storyList[this.activeStoriesIndex].storyItems.length) {
                this.goToNextStory(activeStoriesIndex, activeStoryItemIndex);
            } else {
                this.closeModal(null);
            }
        } else {
            this.closeModal(null);
        }
        this.gamingStoriesService.trackingGamingStoriesPhase2Event(
            'teaser - embedded video',
            'stories',
            'click',
            'not applicable',
            this.storyItem.title,
            'auto',
            this.storyItem.actionTypePosition,
            'not applicable',
        );
        // if (this.data.storyContainerData.storyList[activeStoriesIndex].storyItems[activeStoryItemIndex].showVideo) {
        //     this.storyHasVideo = false;
        //     this.gamingStoriesService.storyHasVideo = false;
        // }
    }

    timeUpdate() {
        let counter: number = 0;
        let video = <HTMLVideoElement>document.getElementById('casino-gamingstory-video');
        if (video) {
            let percentage = (video.currentTime / video.duration) * 100;
            if (percentage == 100) {
                counter++;
            }
            if (counter <= 1) {
                this.gamingStoriesService.videoProgressStatus(percentage);
            }
        }
    }

    cmsTimeUpdate() {
        let counter: number = 0;
        const stream: any = document.querySelector('#casino-gamingstory-cmsvideo stream');
        if (stream) {
            let percentage = (stream.currentTime / stream.duration) * 100;
            if (percentage == 100) {
                counter++;
            }
            if (counter <= 1) {
                this.gamingStoriesService.videoProgressStatus(percentage);
            }
        }
    }

    closeModal($event: Event | null): void {
        this.gamingStoriesService.pauseVideo();
        this.setViewedStory(this.activeStoriesIndex, this.activeStoryItemIndex);
        this.setViewedPromo(this.activeStoriesIndex);
        $event && $event.stopImmediatePropagation();
        this.matDialogRef.close(this.data.storyContainerData);
        this.gamingStoriesService.trackingGamingStoriesPhase2Event(
            'teaser - embedded video',
            'stories',
            'click',
            'not applicable',
            this.storyItem.title,
            'Close',
            this.storyItem.actionTypePosition,
            'not applicable',
        );
    }

    swipeToBottom() {
        if (this.displaySwipeUp) {
            this.getSwipeUpContentData();
            this.displaySwipeUpContent = true;
            this.toggleDisplayArrows();
            let swipeUpContent = this.swipeUpContentContainer.nativeElement;
            this.pauseStory();
            if (!this.deviceservice.isiOS) {
                swipeUpContent.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            }
            if (this.deviceservice.isiOS) {
                swipeUpContent.scrollIntoView(true);
            }
            this.zIndex = 1;
        }
        this.gamingStoriesService.trackingGamingStoriesPhase2Event(
            'teaser - embedded video',
            'stories',
            'click',
            'not applicable',
            this.storyItem.title,
            'swipeBottom',
            this.storyItem.actionTypePosition,
            'not applicable',
        );
    }

    swipeToTop() {
        this.getSwipeUpContentData();
        this.displaySwipeUpContent = false;
        this.toggleDisplayArrows();
        let scrollContent = this.scrollContainer.nativeElement;
        this.resumeStory();
        this.zIndex = 0;
        if (!this.deviceservice.isiOS) {
            scrollContent.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
        if (this.deviceservice.isiOS) {
            scrollContent.scrollIntoView(true);
        }
        this.gamingStoriesService.trackingGamingStoriesPhase2Event(
            'teaser - embedded video',
            'stories',
            'click',
            'not applicable',
            this.storyItem.title,
            'swipeUp',
            this.storyItem.actionTypePosition,
            'not applicable',
        );
    }

    ngOnDestroy() {
        this.casinoAudio?.nativeElement.pause();
        this.destroy$.next();
        this.destroy$.complete();
    }

    private identifyLandscapeToPortrait() {
        this.deviceservice.orientation.pipe(takeUntil(this.destroy$)).subscribe({
            next: (orientation: string) => {
                if (orientation) {
                    if (this.deviceservice.isTouch && orientation === 'landscape') {
                        this.pauseStory();
                    } else {
                        this.resumeStory();
                    }
                }
            },
            error: () => {},
        });
    }

    private pauseStory() {
        this.gamingStoriesService.pauseStory(true);
    }

    private resumeStory() {
        this.gamingStoriesService.pauseStory(false);
    }

    private onLeftSwipe() {
        this.gamingStoriesService.pauseVideo();
        this.setStoryForLeftSwipe();
    }

    private onRightSwipe() {
        this.gamingStoriesService.pauseVideo();
        this.setStoryForRightSwipe();
    }

    private onTap(evt: any) {
        let gamingstoriesItemsContainer = document.getElementById('gamingstoriesItemsContainer')!;
        let tapHeightLimit = gamingstoriesItemsContainer?.offsetHeight / 4.5;
        if (tapHeightLimit < evt.changedPointers[0].clientY) {
            if (this.disableTap) {
                return;
            }
            this.disableTap = true;
            if (this.gamingStoriesService.storyHasVideo) {
                let video = <HTMLVideoElement>document.getElementById('casino-gamingstory-video');
                let stream: HTMLMediaElement = {} as HTMLMediaElement;
                if (!video) stream = document.querySelector('#casino-gamingstory-cmsvideo stream') as HTMLMediaElement;
                if (video && (video.readyState == 3 || video.readyState == 4) && video.currentTime >= 1) {
                    video.pause();
                    this.processOnTap(evt);
                } else if (stream && stream.currentTime >= 1) {
                    stream.pause();
                    this.processOnTap(evt);
                } else {
                    this.disableTap = false;
                    this.processOnTap(evt);
                }
            } else {
                this.processOnTap(evt);
            }
        }
    }

    private playPauseObservable() {
        this.playpauseObservable = this.gamingStoriesService.pauseStoryObservable.pipe(takeUntil(this.destroy$)).subscribe((isPaused: boolean) => {
            if (isPaused) {
                this.pause = true;
            } else {
                this.pause = false;
            }
        });
    }

    private setVideoAndAudio(gamingStoryItemsModel: IGamingStoryItem) {
        if (this.counter == 0 && this.storyItem.videoURL) {
            this.counter++;
            this.gamingStoriesService.videoPresentStatus(true);
        }
        if (gamingStoryItemsModel.showVideo && gamingStoryItemsModel?.cmsVideoURL?.id) {
            this.cmsVideoURL = gamingStoryItemsModel.cmsVideoURL.id;
            this.storyHasVideo = true;
            this.gamingStoriesService.storyHasVideo = true;
            this.videoURL = '';
        } else if (gamingStoryItemsModel.showVideo && gamingStoryItemsModel?.cmsVideoURL?.src) {
            this.cmsVideoURL = gamingStoryItemsModel.cmsVideoURL.src;
            this.storyHasVideo = true;
            this.gamingStoriesService.storyHasVideo = true;
            this.videoURL = '';
        } else if (gamingStoryItemsModel.showVideo && gamingStoryItemsModel.videoURL) {
            this.videoURL = gamingStoryItemsModel.videoURL;
            this.storyHasVideo = true;
            this.gamingStoriesService.storyHasVideo = true;
            this.cmsVideoURL = '';
        }
        this.gamingStoriesService.videoMuteObservable.pipe(takeUntil(this.destroy$)).subscribe((isMuted: string) => {
            if (isMuted) {
                if (isMuted == 'muted') {
                    this.mute = true;
                } else {
                    this.mute = false;
                }
            }
        });
    }

    private setActivePromoAndStory(storiesIndex: number, storyItemIndex: number, setCurrentStoriesIndex: number, setCurrentStoryItemIndex: number) {
        this.activeStoriesIndex = storiesIndex;
        this.activeStoryItemIndex = storyItemIndex;
        this.activeStoryData = this.data.storyContainerData.storyList[this.activeStoriesIndex];
        this.storyItem = this.data.storyContainerData.storyList[this.activeStoriesIndex].storyItems[this.activeStoryItemIndex];
        this.fetchSwipeUpContent();
        this.storyItemBackgroundClr = this.storyItem.configurations.storyItemBackgroundClr;
        this.gamingStoriesService.pauseStoryBehaviourSubject.next(false);
        this.soundDisabled = false;
        this.setViewedStory(setCurrentStoriesIndex, setCurrentStoryItemIndex);
        if (this.data.storyContainerData.storyList[setCurrentStoriesIndex].storyItems.length == setCurrentStoryItemIndex + 1) {
            this.setViewedPromo(setCurrentStoriesIndex);
        }
        this.displaySwipeUpArrows(this.enableSwipeUp);
        if (this.data.storyContainerData.storyList[setCurrentStoriesIndex].storyItems[setCurrentStoryItemIndex].showVideo) {
            this.storyHasVideo = false;
            this.gamingStoriesService.storyHasVideo = false;
        }
        this.gamingStoriesService.muteVideo = 'muted';
        this.toggleMute(this.storyItem, null);
        this.setVideoAndAudio(this.storyItem);
        let storyContainerList = new GamingStoriesStorageCloseModel();
        storyContainerList.storyList = this.data.storyContainerData.storyList;
        storyContainerList.activeStoriesIndex = this.activeStoriesIndex;
        storyContainerList.activeStoryItemIndex = this.activeStoryItemIndex;
        this.gamingStoriesService.storeGamingStoriesCloseContainer(storyContainerList);
        this.userAuthentication = this.user.isAuthenticated;
        this.changeDetectorRef.detectChanges();
        setTimeout(() => {
            let playNowCTA = this.scrollContainer?.nativeElement?.querySelector('.more-info-btn');
            playNowCTA?.addEventListener('click', () => {
                this.closeModal(null);
            });
        }, this.gamingStoriesClientConfig.gameLaunchSetTimeOut);
    }

    private fetchSwipeUpContent() {
        this.enableSwipeUp = this.data.storyContainerData.storyList[this.activeStoriesIndex].storyItems[this.activeStoryItemIndex].enableSwipeup;
        let storyItemActionType =
            this.data.storyContainerData.storyList[this.activeStoriesIndex].storyItems[this.activeStoryItemIndex].storyItemActionType;
        if (
            this.enableSwipeUp &&
            storyItemActionType !== 'EmojiSlider' &&
            storyItemActionType !== 'EmojiSelector' &&
            storyItemActionType !== 'SelectList' &&
            storyItemActionType !== 'YesNo'
        ) {
            this.displaySwipeUpArrows(this.enableSwipeUp);
        }
    }

    private setViewedStory(activeStoriesIndex: number, activeStoryItemIndex: number) {
        this.data.storyContainerData.storyList[activeStoriesIndex].storyItems[activeStoryItemIndex].storyItemViewed = true;
    }

    private setViewedPromo(activeStoriesIndex: number) {
        let activeStoryData = this.data.storyContainerData.storyList[activeStoriesIndex];
        let promoHasNotViewedStory: boolean = false;
        activeStoryData.storyItems.forEach((story: IGamingStoryItem) => {
            if (!story.storyItemViewed) {
                promoHasNotViewedStory = true;
            }
        });
        if (!promoHasNotViewedStory) {
            this.data.storyContainerData.storyList[activeStoriesIndex].storyViewed = true;
        }
    }

    private displaySwipeUpArrows(enableSwipeUp: boolean) {
        this.displaySwipeUp = enableSwipeUp;
        this.displayDownArrow = false;
    }

    private toggleDisplayArrows() {
        this.displaySwipeUp = !this.displaySwipeUp;
        this.displayDownArrow = !this.displayDownArrow;
    }

    private getSwipeUpContentData(): void {
        this.gamingStoriesService.swipeUpObservable.pipe(takeUntil(this.destroy$)).subscribe((isReady: boolean) => {
            if (isReady) {
                const swipeUpContentDataList: Array<IGamingStoryItem> = this.gamingStoriesService.swipeUpContentList;
                if (swipeUpContentDataList) {
                    this.swipeUpContentDataList = swipeUpContentDataList;
                    this.swipeUpContentDataList.forEach((swipeUpdata: IGamingStoryItem) => {
                        if (swipeUpdata.storyItemsPath.path === this.storyItem.swipeupStoryItemContent[0].path) {
                            this.swipeUpContentData = swipeUpdata;
                            this.swipeUpContentData.storyImage = this.storyItem.storyImage;
                        }
                    });
                }
            }
        });
    }

    private getFetchingUserResponseAPIData(loginStatus?: any): void {
        this.subscribeFetchUserResponse();
        this.gamingStoriesService.fetchGamingStoriesAPIObservable.pipe(takeUntil(this.destroy$)).subscribe((isReady: boolean) => {
            if (isReady) {
                const fetchGamingStoriesAPIResData: FetchUserResponseAPIModel = this.gamingStoriesService.fetchGamingStoriesAPIRes;
                if (fetchGamingStoriesAPIResData) {
                    this.fetchingGamingStoriesAPIRes = fetchGamingStoriesAPIResData;
                    if (this.fetchingGamingStoriesAPIRes?.response !== undefined && loginStatus === 'loginEvent') {
                        this.loadFeatures = true;
                    }
                }
            }
        });
    }

    private subscribeFetchUserResponse() {
        this.fetchAPIObservable = this.gamingStoriesService.fetchAPIObservable.pipe(takeUntil(this.destroy$)).subscribe((message: Message) => {
            if (message?.type === 'Error') {
                this.loadFeatures = true;
            }
        });
    }

    private setStoryAndStoryItemForLeftTap() {
        if (this.activeStoriesIndex == 0 && this.activeStoryItemIndex == 0) {
            this.gamingStoriesService.playVideo();
            this.closeModal(null);
        } else if (this.activeStoryItemIndex > 0) {
            this.goToPreviousStory(this.activeStoriesIndex, this.activeStoryItemIndex);
        } else if (this.activeStoryItemIndex == 0) {
            this.goToPreviousPromo(this.activeStoriesIndex);
        }
        this.gamingStoriesService.trackingGamingStoriesPhase2Event(
            'teaser - embedded video',
            'stories',
            'click',
            'not applicable',
            this.storyItem.title,
            'LeftTap',
            this.storyItem.actionTypePosition,
            'not applicable',
        );
    }

    private setStoryAndStoryItemForRightTap() {
        if (
            this.activeStoriesIndex + 1 == this.data.storyContainerData.storyList.length &&
            this.activeStoryItemIndex + 1 == this.data.storyContainerData.storyList[this.activeStoriesIndex].storyItems.length
        ) {
            this.gamingStoriesService.playVideo();
            this.closeModal(null);
        } else if (this.activeStoryItemIndex + 1 < this.data.storyContainerData.storyList[this.activeStoriesIndex].storyItems.length) {
            this.goToNextStory(this.activeStoriesIndex, this.activeStoryItemIndex);
        } else if (this.activeStoryItemIndex + 1 == this.data.storyContainerData.storyList[this.activeStoriesIndex].storyItems.length) {
            this.goToNextPromo(this.activeStoriesIndex, this.activeStoryItemIndex);
        }
        this.gamingStoriesService.trackingGamingStoriesPhase2Event(
            'teaser - embedded video',
            'stories',
            'click',
            'not applicable',
            this.storyItem.title,
            'RightTap',
            this.storyItem.actionTypePosition,
            'not applicable',
        );
    }

    private processOnTap(evt: any) {
        let gamingstoriesItemsContainer = document.getElementById('gamingstoriesItemsContainer')!;
        let clickXPosition = evt.changedPointers[0].clientX - (window.innerWidth - gamingstoriesItemsContainer.offsetWidth) / 2;
        if (clickXPosition < gamingstoriesItemsContainer.offsetWidth / 5) {
            this.setStoryAndStoryItemForLeftTap();
        } else if (clickXPosition > 4 * (gamingstoriesItemsContainer.offsetWidth / 5)) {
            this.setStoryAndStoryItemForRightTap();
        } else {
            this.gamingStoriesService.playVideo();
        }
        this.disableTap = false;
    }

    private setStoryForRightSwipe() {
        if (this.activeStoriesIndex > 0) {
            this.goToPreviousPromo(this.activeStoriesIndex);
        } else {
            this.closeModal(null);
        }
        this.gamingStoriesService.trackingGamingStoriesPhase2Event(
            'teaser - embedded video',
            'stories',
            'click',
            'not applicable',
            this.storyItem.title,
            'RightSwipe',
            this.storyItem.actionTypePosition,
            'not applicable',
        );
    }

    private setStoryForLeftSwipe() {
        if (this.activeStoriesIndex + 1 < this.data.storyContainerData.storyList.length) {
            this.goToNextPromo(this.activeStoriesIndex, this.activeStoryItemIndex);
        } else {
            this.closeModal(null);
        }
        this.gamingStoriesService.trackingGamingStoriesPhase2Event(
            'teaser - embedded video',
            'stories',
            'click',
            'not applicable',
            this.storyItem.title,
            'LeftSwipe',
            this.storyItem.actionTypePosition,
            'not applicable',
        );
    }

    private goToNextPromo(currentStoriesIndex: number, currentStoryItemIndex: number) {
        this.setActivePromoAndStory(currentStoriesIndex + 1, 0, currentStoriesIndex, currentStoryItemIndex);
    }

    private goToPreviousPromo(currentStoryItemIndex: number) {
        this.setActivePromoAndStory(currentStoryItemIndex - 1, 0, currentStoryItemIndex, 0);
    }

    private goToNextStory(currentStoriesIndex: number, currentStoryItemIndex: number) {
        this.setActivePromoAndStory(currentStoriesIndex, currentStoryItemIndex + 1, currentStoriesIndex, currentStoryItemIndex);
    }

    private goToPreviousStory(currentStoriesIndex: number, currentStoryItemIndex: number) {
        this.setActivePromoAndStory(currentStoriesIndex, currentStoryItemIndex - 1, currentStoriesIndex, currentStoryItemIndex);
    }

    private setupSwipeAndTapEvents(): void {
        const manager = new Hammer.Manager(this.gamingStoriesItemsdraggable?.nativeElement);

        const swipe = new Hammer.Swipe();
        const tap = new Hammer.Tap();

        manager.add(swipe);
        manager.add(tap);

        manager.on(this.SWIPE_ACTION.LEFT, () => {
            this.onLeftSwipe();
        });
        manager.on(this.SWIPE_ACTION.RIGHT, () => {
            this.onRightSwipe();
        });
        manager.on(this.SWIPE_ACTION.UP, () => {
            this.swipeToBottom();
        });
        manager.on(this.SWIPE_ACTION.TAP, (event) => {
            this.onTap(event);
        });
    }
}
