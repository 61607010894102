<div
    class="bingo-tournament-container"
    [ngClass]="{
        'bt1-card-leaderboard': !enableBetterVisualization && showLeaderboard && races.length == 1,
        'bt2-card-leaderboard': !enableBetterVisualization && showLeaderboard && races.length == 2
    }">
    <div
        *ngIf="showCarousel && enableBetterVisualization && (enableBingoTournamentsAsWidget || fromBingoHomepage)"
        class="bingo-bt-card-wrapper-non-touch"
        [ngClass]="{ 'bt-card-leaderboard': showLeaderboard }">
        <vn-swiper *ngIf="races && races.length" [swiperOptions]="swiperOptions" #bingoTournamentsCarousel>
            <div class="swiper-slide" *ngFor="let race of races; let pos = index; trackBy: raceAdded">
                <div *ngIf="(showPlaceholderCard && pos != races.length - 1) || !showPlaceholderCard">
                    <bgl-slot-race-card
                        class="bgl-slot-race-card"
                        [race]="race"
                        [showRaceInfo]="true"
                        [showCardMenu]="false"
                        (openRaceInfo)="openDialog($event, 'race-info')"
                        (openPickGameOverlay)="openDialog($event, 'pick-game')"
                        (openNotificationOverlay)="openDialog($event, 'notification')"
                        [showPlaceholderCard]="showPlaceholderCard"
                        [position]="pos"
                        [targetedPlayerCount]="race.targetedPlayerCount"
                        [enableOptin]="enableUpcomingOptinForPlaytech && race.firstPlayTech && !isFreeplaytechinLive"
                        [coinImg]="coinImg"
                        [selectedOption]="selectedOption">
                    </bgl-slot-race-card>
                    <div *ngIf="showLeaderboard && race.rankDetails && race.rankDetails.length > 0" class="bingo-subpage-leaderboard">
                        <bgl-slot-races-leaderboard [race]="race" [showTopThree]="true" [coinImg]="coinImg"> </bgl-slot-races-leaderboard>
                        <div class="bingo-See-full-leaderboard" (click)="showFullLeaderboard(race)">
                            {{ messages.SeeLeaderboard }}
                        </div>
                    </div>
                </div>

                <div *ngIf="showPlaceholderCard && pos == races.length - 1" class="bingo-bt-card-placeholder bingo-bt-card">
                    <img alt="game-name1" src="{{ content?.placeholderCardImage?.image?.src }}" draggable="false" />
                    <div class="bingo-new-races-soon">
                        <div class="bingo-new-races-soon-icon theme-slots-race-coming-soon"></div>
                        <div class="bingo-new-races-soon-title">{{ messages.SoonToBeAnnounced }}</div>
                    </div>
                    <div class="bt-card-container-bottom bt-card-placeholder">
                        <div class="btn-section" (click)="gotoSlotRaces()">
                            <div class="btn btn-primary bt-playbtn btn-md">
                                {{ messages.SeeAllRaces }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vn-swiper>
        <div *ngIf="showPlaceholderCard && races.length == 0" class="bingo-bt-card-placeholder bingo-bt-card">
            <img alt="game-name1" src="{{ content?.placeholderCardImage?.image?.src }}" draggable="false" />
            <div class="bingo-new-races-soon">
                <div class="bingo-new-races-soon-icon theme-slots-race-coming-soon"></div>
                <div class="bingo-new-races-soon-title">{{ messages.SoonToBeAnnounced }}</div>
            </div>
            <div class="bt-card-container-bottom bt-card-placeholder">
                <div class="btn-section" (click)="gotoSlotRaces()">
                    <div class="btn btn-primary bt-playbtn btn-md">
                        {{ messages.SeeAllRaces }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        *ngIf="showCarousel && enableBetterVisualization && !fromBingoHomepage && !enableBingoTournamentsAsWidget"
        class="bingo-bt-card-wrapper-non-touch"
        [ngClass]="{ 'bt-card-leaderboard': showLeaderboard, 'bt-card-odd': races.length % 2 !== 0 }">
        <div *ngFor="let race of races; index as pos; trackBy: raceAddedDesktop" [attr.data-index]="pos" class="bt-new-cards">
            <div *ngIf="(showPlaceholderCard && pos != races.length - 1) || !showPlaceholderCard">
                <bgl-slot-race-card
                    [race]="race"
                    [showRaceInfo]="true"
                    [showCardMenu]="false"
                    (openRaceInfo)="openDialog($event, 'race-info')"
                    (openPickGameOverlay)="openDialog($event, 'pick-game')"
                    [showPlaceholderCard]="showPlaceholderCard"
                    [position]="pos"
                    [targetedPlayerCount]="race.targetedPlayerCount"
                    [enableOptin]="enableUpcomingOptinForPlaytech && race.firstPlayTech && !isFreeplaytechinLive"
                    [coinImg]="coinImg"
                    [selectedOption]="selectedOption">
                </bgl-slot-race-card>
            </div>
            <div *ngIf="showPlaceholderCard && pos == races.length - 1" class="bingo-bt-card-placeholder bingo-bt-card">
                <img alt="game-name1" src="{{ content?.placeholderCardImage?.image?.src }}" draggable="false" />
                <div class="bingo-new-races-soon">
                    <div class="bingo-new-races-soon-icon theme-slots-race-coming-soon"></div>
                    <div class="bingo-new-races-soon-title">{{ messages.SoonToBeAnnounced }}</div>
                </div>
                <div class="bt-card-container-bottom bt-card-placeholder">
                    <div class="btn-section" (click)="gotoSlotRaces()">
                        <div class="btn btn-primary bt-playbtn">
                            {{ messages.SeeAllRaces }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showPlaceholderCard && races.length == 0" class="bingo-bt-card-placeholder bingo-bt-card">
            <img alt="game-name1" src="{{ content?.placeholderCardImage?.image?.src }}" draggable="false" />
            <div class="bingo-new-races-soon">
                <div class="bingo-new-races-soon-icon theme-slots-race-coming-soon"></div>
                <div class="bingo-new-races-soon-title">{{ messages.SoonToBeAnnounced }}</div>
            </div>
            <div class="bt-card-container-bottom bt-card-placeholder">
                <div class="btn-section" (click)="gotoSlotRaces()">
                    <div class="btn btn-primary bt-playbtn">
                        {{ messages.SeeAllRaces }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        *ngIf="!showCarousel && enableBetterVisualization"
        class="bingo-bt-card-wrapper mobile"
        [ngClass]="{ 'bt-card-leaderboard': showLeaderboard, 'bt-mobile-card-odd': races.length % 2 !== 0 }">
        <div class="card-width" *ngFor="let race of races; index as pos; trackBy: raceAdded; let f = first">
            <bgl-slot-race-card
                [race]="race"
                [showRaceInfo]="true"
                [showCardMenu]="false"
                (openRaceInfo)="openDialog($event, 'race-info')"
                [showPlaceholderCard]="showPlaceholderCard"
                (openPickGameOverlay)="openDialog($event, 'pick-game')"
                [firstItem]="f"
                [targetedPlayerCount]="race.targetedPlayerCount"
                [enableOptin]="enableUpcomingOptinForPlaytech && race.firstPlayTech && !isFreeplaytechinLive"
                [coinImg]="coinImg"
                [selectedOption]="selectedOption">
            </bgl-slot-race-card>
        </div>
        <div *ngIf="showPlaceholderCard" class="bingo-bt-card-placeholder bingo-bt-card">
            <img alt="game-name1" src="{{ content?.placeholderCardImage?.image?.src }}" draggable="false" />
            <div class="bingo-new-races-soon">
                <div class="bingo-new-races-soon-icon theme-slots-race-coming-soon"></div>
                <div class="bingo-new-races-soon-title">{{ messages.SoonToBeAnnounced }}</div>
            </div>
            <div class="bt-card-container-bottom bt-card-placeholder">
                <div class="btn-section" (click)="gotoSlotRaces()">
                    <div class="btn btn-primary bt-playbtn">
                        {{ messages.SeeAllRaces }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
