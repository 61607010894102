import { Injectable } from '@angular/core';

import { TrackingService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
})
export class BingoTournamentsTrackingService {
    constructor(private trackingService: TrackingService) {}

    track(actionEvent: string, positionEvent: string, locationEvent: string, eventDetails: string, url: string = '') {
        this.trackingService?.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'bingo tournaments',
            'component.LabelEvent': 'race Cards',
            'component.ActionEvent': actionEvent,
            'component.PositionEvent': positionEvent,
            'component.LocationEvent': locationEvent,
            'component.EventDetails': eventDetails,
            'component.URLClicked': url ? url : 'not applicable',
        });
    }
}
