import { Component, Input, OnInit } from '@angular/core';

import { BingoConfig, BingoConfigProviderService, FeaturesClientConfig, SuperLinksClientConfig } from '@frontend/bingo/core';

@Component({
    selector: 'bg-feature-icon',
    templateUrl: './feature-icon.component.html',
})
export class FeatureIconComponent implements OnInit {
    @Input() feature: any;
    // featureIcons = [
    //     'everyone_wins',
    //     'hot_ball',
    //     'roll_on_part',
    //     'cashout',
    //     'physical_prize',
    //     'multi_price',
    //     'free_tickets',
    //     'cashpot',
    //     'timed_jackpot',
    //     'deal_or_nodeal',
    //     'beat_the_chaser',
    //     'scratch_card',
    //     'session_bingo',
    //     'SuperBooks',
    //     'country_miles',
    //     'togo',
    //     'ntogo',
    //     '2togo',
    //     'side_bets',
    //     'live_streaming',
    //     'bonus_spins',
    //     'bonus_cash',
    //     'bonus_tickets',
    //     'slots-race-podium',
    //     'royale_insurance',
    //     'sliding_pot',
    //     'jumping_pot',
    //     'Superlinks',
    //     'slingo_extra_ball',
    //     'slingo_multi_stake',
    //     'whack_a_bingo',
    //     'prize_drop',
    //     'lucky_number',
    //     'losing_tickets',
    //     'double_numbers',
    //     'special_number',
    //     'win_in_x_call',
    //     'multiplier_bingo',
    // ];
    featureIcons: string[] = [];
    notaFeature: boolean = false;
    @Input() featureList?: any;
    prebuy_tickets: any;
    no_of_tickets: number;
    enablGalaIcons: boolean = false;
    enableSuperLinks: boolean = false;
    sitecoreIconConfigData: any;
    featuresConfig: FeaturesClientConfig;
    superLinksConfig: SuperLinksClientConfig;
    clientConfig: any;
    bingoclientconfig: BingoConfig;
    featureImgPath: string;
    countryMilesIcon: string;
    isFeatureImgPresent: boolean = false;
    constructor(private configProviderService: BingoConfigProviderService) {
        this.featuresConfig = this.configProviderService.provideFeaturesConfig();
        this.superLinksConfig = this.configProviderService.provideSuperLinksClientConfig();
        this.bingoclientconfig = this.configProviderService.provideBingoConfig();
    }
    ngOnInit() {
        if (this.featuresConfig.featureIconImgPaths) {
            this.featureIcons = Object.keys(this.featuresConfig.featureIconImgPaths);
        }
        this.clientConfig = JSON.parse(localStorage.getItem('config')!);
        this.enablGalaIcons = this.featuresConfig.enableNewPrebuySliderIcons;
        this.enableSuperLinks = this.superLinksConfig.enableSuperLinks;
        this.sitecoreIconConfigData = this.clientConfig?.featureIconConfiguration;
        this.isFeatureImgPresent = this.featuresConfig?.featureIconImgPaths?.[this.feature] ? true : false;
        if (this.isFeatureImgPresent) {
            if (this.feature == 'Superlinks') {
                if (!this.enableSuperLinks) {
                    this.isFeatureImgPresent = false;
                }
            }
        }
        this.countryMilesIcon =
            this.bingoclientconfig['bingoClientAssetsDomain'] + this.featuresConfig?.featureIconImgPaths?.['country_miles_trophy'];
        this.featureImgPath = this.bingoclientconfig['bingoClientAssetsDomain'] + this.featuresConfig?.featureIconImgPaths?.[this.feature];

        if (this.featureList != undefined) {
            const filtered = this.featureIcons.filter(function (e: any) {
                return this.indexOf(e) < 0;
            }, this.featureList);
            if (!this.featureIcons.includes(this.feature) && filtered?.length == this.featureIcons.length) {
                const notFoundIcons = this.featureList.splice(0, this.featureList.indexOf(this.feature));
                //console.log(notFoundIcons);
                if (notFoundIcons.length >= 0) this.notaFeature = true;
            }
        }
    }
}
