@if (isLegacy) {
    <button
        ds-button
        [kind]="kind"
        [variant]="variant"
        [inverse]="inverse"
        [size]="size"
        (click)="clickEmit.emit()"
        [disabled]="disabled"
        [style]="customStyle"
        data-decoratorclass-host>
        <ng-content slot="start" select="[slot=start]"></ng-content>
        <!-- <ng-content></ng-content> -->
        {{ button_text | capitalize }}
        <ng-content slot="end" select="[slot=end]"></ng-content>
    </button>
} @else {
    <button [class.btn-primary]="kind === 'primary'" [disabled]="disabled" class="btn" (click)="clickEmit.emit()" [class]="customClass">
        {{ button_text }}
    </button>
}

<!-- keeping all this for future use -->
<!-- [style.color]="customTextColor"
        [style.background-color]="customBgColor"
        [style.width.%]="ctaWidth" -->
