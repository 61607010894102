import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BingoConfigProviderService, ScreenNameClientConfig } from '@frontend/bingo/core';
import { NavigationService } from '@frontend/vanilla/core';

import { AlertMessageService } from '../shared/components/alert-message/alert-message.service';
import { ScreenNameService } from '../shared/services/screen-name.service';
import { SessionPackageService } from '../shared/services/session-package.service';

@Component({
    selector: 'bg-screen-name',
    templateUrl: './screen-name.component.html',
})
export class ScreenNameComponent implements OnInit {
    config: any;
    chatNameForm: UntypedFormGroup;
    chatText: any;
    isScreenNameSucces: boolean = false;
    showCloseBtn: boolean = true;
    disableConfirmButton = false;
    screenNameConfig: ScreenNameClientConfig;
    screenNameMessages: any;

    constructor(
        public dialogRef: MatDialogRef<ScreenNameComponent>,
        @Inject(MAT_DIALOG_DATA) public popUPdata: any,
        private fb: UntypedFormBuilder,
        public alertMessageService: AlertMessageService,
        private navigator: NavigationService,
        private screenNameService: ScreenNameService,
        private configProviderService: BingoConfigProviderService,
        private sessionPackageService: SessionPackageService,
    ) {
        this.screenNameConfig = this.configProviderService.provideScreenNameClientConfig();
    }

    ngOnInit(): void {
        this.config = this.screenNameConfig;
        const clientConfig = JSON.parse(localStorage.getItem('config')!);
        this.screenNameMessages = clientConfig?.screenNameMessages;
        this.chatNameForm = this.fb.group({
            chatText: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
        });
    }

    onCancel(): void {
        // this.ticketService.storePresbuyData(null);
        this.dialogRef.close();
    }

    bindClass(): string {
        let cls: string = 'chat-name-input-area';
        if (this.chatNameForm.get('chatText')!.invalid && (this.chatNameForm.get('chatText')!.touched || this.chatNameForm.get('chatText')!.dirty))
            cls = 'chat-name-input-area error';
        else if (this.chatNameForm.get('chatText')!.valid && (this.chatNameForm.get('chatText')!.touched || this.chatNameForm.get('chatText')!.dirty))
            cls = 'chat-name-input-area success';
        return cls;
    }

    validateChatName() {
        this.disableConfirmButton = true;
        if (this.chatNameForm.get('chatText')!.errors) {
            this.disableConfirmButton = false;
            return;
        }
        //Logic to call chatname update service
        this.isScreenNameSucces = true;
        const chatName = this.chatNameForm.get('chatText')!.value;

        if (chatName == null || chatName == undefined || chatName == '') return;

        this.screenNameService.saveChatName(chatName).subscribe((response) => {
            if (response && response.statusMessage === 'SUCCESS') {
                if (response.data && response.data.responseMessage === 'success') {
                    this.screenNameService.screenChatName = chatName;
                    this.alertMessageService.show('checkmark_kick', 'Saved Successfully!!');
                    if (this.popUPdata) {
                        if (this.popUPdata.from == 'preBuyForced') {
                            if (this.popUPdata.url != '') {
                                this.navigator.goTo(this.popUPdata.url);
                            }
                        } else {
                            this.sessionPackageService.openBuyNowGrid(this.popUPdata.data);
                        }
                    }
                    this.dialogRef.close();
                } else if (response.data && response.data.responseMessage === 'error') {
                    if (response.data.Error) {
                        this.alertMessageService.error('checkmark_kick', response.data.Error.message);
                    } else {
                        this.alertMessageService.error('checkmark_kick', 'Error Occured');
                    }
                    this.disableConfirmButton = false;
                }
            } else {
                this.alertMessageService.error('checkmark_kick', 'Error Occured');
                this.disableConfirmButton = false;
            }
        });
    }
}
