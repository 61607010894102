import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { BingoConfigProviderService, BingoHelperService, BingoTournamentsService, Race } from '@frontend/bingo/core';

@Component({
    selector: 'bgl-slot-races-prizes',
    templateUrl: 'bingo-prizes.component.html',
})
export class BingoSlotRacesPrizesComponent implements OnInit {
    @Input() race: Race;
    @Input() coinImg: string;
    @Input() showHeader: boolean;
    @Input() isCollapsible: boolean;
    @Input() isExpanded: boolean;
    isExpansionDisabled: string = '';
    panelOpenState: boolean;
    data: any;
    title: string;
    prizesOne: any;
    prizesTwo: any;
    isMobile: boolean;
    prizesOneWidth: any;
    prizesTwoWidth: any;
    prizeValueForBeatBanker: any;
    configs: any = {};
    participateReward: any;
    participationRewardWidth: number;

    constructor(
        private slotRacesService: BingoTournamentsService,
        private currencyPipe: CurrencyPipe,
        private configProvider: BingoConfigProviderService,
        private bingoHelperService: BingoHelperService,
    ) {}

    ngOnInit() {
        this.configs = this.configProvider.provideBingoTournamentsClientConfig();
        let prizesArr: any = [];
        if (this.race) {
            prizesArr = this.slotRacesService.getPrizesForPromo(this.race.promoId);
            if (!prizesArr || !prizesArr.length) {
                this.slotRacesService.getPrizes().subscribe((data: any) => {
                    if (data) {
                        prizesArr = data[this.race.promoId];
                        if (prizesArr && prizesArr.length) {
                            this.setPrizes(prizesArr);
                        }
                    }
                });
            }
            this.slotRacesService.content.subscribe((data: any) => {
                this.data = data;
            });
            if (prizesArr && prizesArr.length) {
                this.setPrizes(prizesArr);
            }
        }
    }

    setPrizes(prizesArr: any[]): void {
        let prizesContent = JSON.parse(this.race.content.promotion.prizes);
        if (this.race.content?.promotion?.beatBanker) {
            const beatBankerContent = JSON.parse(this.race.content.promotion.beatBanker);
            beatBankerContent.forEach((beatBanker: any) => {
                beatBanker.isBeatBanker = true;
            });
            prizesContent = prizesContent.concat(beatBankerContent);
        }
        prizesArr?.forEach((prizeList: any) => {
            prizeList?.forEach((prize: any) => {
                if (prize.froM_RANK && prize.tO_RANK && Number(prize.froM_RANK) + 1 !== Number(prize.tO_RANK)) {
                    prize.rank = this.data.textTranslations.versionedList.PrizePositionFromTo.replace('{0}', prize.froM_RANK).replace(
                        '{1}',
                        Number(prize.tO_RANK) - 1,
                    );
                } else if (prize.froM_RANK) {
                    let suffix;
                    switch (prize.froM_RANK) {
                        case '1':
                            suffix = this.data.textTranslations.versionedList.FirstPositionSuffix;
                            break;
                        case '2':
                            suffix = this.data.textTranslations.versionedList.SecondPositionSuffix;
                            break;
                        case '3':
                            suffix = this.data.textTranslations.versionedList.ThirdPositionSuffix;
                            break;
                        default:
                            suffix = this.data.textTranslations.versionedList.RemainingPositionSuffix;
                            break;
                    }
                    prize.rank = prize.froM_RANK + suffix + ' ' + this.data.textTranslations.versionedList.PrizePosition;
                } else {
                    prize.rank = this.data.textTranslations.versionedList.BeatBankerPrizeText;
                }
                const prizeContent = prizesContent.find(
                    (p: any) =>
                        (p.FromRank == prize.froM_RANK && p.ToRank == prize.tO_RANK) || (prize.isBeatBanker && prize.isBeatBanker == p.isBeatBanker),
                );
                if (prize?.awardType?.toLowerCase() != 'coins') {
                    if (prizeContent && prize?.value) {
                        prize.Icon = prizeContent.Icon;

                        if (!this.bingoHelperService.getConfigBasedOnInvokerProduct(this.configs.displayBonusAsText)) {
                            if (
                                prize?.awardType?.toLowerCase() == 'freespins' ||
                                prize?.awardType?.toLowerCase() == 'free_spin' ||
                                prize?.awardType?.toLowerCase() == 'bingoticket' ||
                                !prize?.currency ||
                                !prize?.currency.length
                            ) {
                                prize.Description = prizeContent.Description?.replace('#value#', prize.value - 0);
                            } else {
                                const prizeValue = this.currencyPipe.transform(prize.value, prize.currency, 'symbol-narrow', '1.2-2');
                                prize.Description = prizeContent.Description?.replace('#value#', prizeValue);
                            }
                        } else {
                            if (prize.awardType && prize.awardType?.toLowerCase() === 'bonus') {
                                prize.Description = prizeContent.Description?.replace('#value#', this.data?.textTranslations?.versionedList?.Bonus);
                            } else if (prize.awardType && prize.awardType?.toLowerCase().indexOf('cash') > -1) {
                                const prizeValue = this.currencyPipe.transform(prize.value, prize.currency, 'symbol-narrow', '1.2-2');
                                prize.Description = prizeContent.Description?.replace('#value#', prizeValue);
                            } else {
                                prize.Description = prizeContent.Description?.replace('#value#', prize.value - 0);
                            }
                        }
                    }
                    if (prize.isBeatBanker && prize?.isCoinAward) {
                        prize.isDisplayCoin = true;
                        prize.coinValue = prize.totalCoins;
                    }
                }
                if (prizeContent && prize?.value && prize?.awardType?.toLowerCase() == 'coins') {
                    prize.showCoinPrize =
                        prizeList?.filter((e: any) => {
                            return e?.awardType?.toLowerCase() != 'coins';
                        })?.length == 0;
                    prize.isDisplayCoin = true;
                    prize.coinValue = prize.value;
                    prize.Icon = prizeContent.Icon;
                    if (prize.isBeatBanker) prize.beatBankerClass = 'beatbankeronlycoin';
                }
                if (prize.isBeatBanker) this.prizeValueForBeatBanker = prize;
            });
        });
        this.title = this.data.textTranslations.versionedList.PrizesTitle;
        if (document && document.documentElement && document.documentElement.clientWidth) {
            this.isMobile = document.documentElement.clientWidth <= 480;
        }
        const tileWidth = this.isMobile ? 121 : 98;
        this.participationRewardWidth = tileWidth;
        const filterprizeArray = JSON.parse(JSON.stringify(prizesArr)!);
        this.participateReward = this.getparticaipationReward(prizesArr);
        const rewardIndex = this.prizeValueForBeatBanker ? prizesArr.length - 2 : prizesArr.length - 1;
        if (this.participateReward) {
            filterprizeArray.splice(rewardIndex, 1);
        }
        const length = filterprizeArray.length;
        const rowCount = Math.trunc(length / 3);
        this.prizesOne = filterprizeArray.slice(0, 3 * rowCount);
        this.prizesTwo = filterprizeArray.slice(3 * rowCount, length);
        this.prizesOneWidth = tileWidth * 3;
        this.prizesTwoWidth = tileWidth * this.prizesTwo.length;
    }

    getparticaipationReward(prizesArr: any): any {
        const participateAwardValue = this.data?.textTranslations?.versionedList?.ParticipationAwardValue;
        let participateReward = null;
        prizesArr?.forEach((data: any) => {
            data?.forEach((e: any) => {
                if (e.awardType == 'COINS' && Number(e.tO_RANK) - 1 == Number(participateAwardValue)) {
                    participateReward = e;
                    return;
                }
            });
        });

        return participateReward;
    }

    expansionState() {
        if (!this.isCollapsible) {
            this.isExpansionDisabled = 'disabled-pointer';
        }
    }
}
