import { Directive, Input, NgModuleRef, OnChanges, OnDestroy, OnInit, Renderer2, ViewContainerRef } from '@angular/core';

import { CasinoPlatformLoaderService } from './casino-platform-loader.service';

@Directive({
    selector: '[bgCasinoModuleLoad]',
    standalone: true,
})
export class CasinoModuleLoadDirective implements OnInit, OnChanges, OnDestroy {
    @Input('bgCasinoModuleLoad') selectorName: string;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('data') jsonData: any;
    casinoModuleLoaded: boolean;
    componentRef_: any;

    private ngModuleRef: NgModuleRef<any>;

    constructor(
        private casinoPlatformLoaderService: CasinoPlatformLoaderService,
        private viewContainerRef: ViewContainerRef,
        private renderer2: Renderer2,
    ) {}

    ngOnChanges(): void {
        if (this.componentRef_ && this.jsonData) {
            this.assignDatatoComponent(this.jsonData);
        }
    }
    ngOnInit(): void {
        this.casinoPlatformLoaderService.casinoModuleLoadedObservable.subscribe((moduleLoaded) => {
            this.casinoModuleLoaded = moduleLoaded;
            if (moduleLoaded) {
                this.createComponent();
            }
        });
    }
    createComponent() {
        const cmpFactory = this.casinoPlatformLoaderService.getLazyComponentFactory(this.selectorName);
        this.componentRef_ = this.viewContainerRef.createComponent(cmpFactory);
        this.renderer2.addClass(this.componentRef_.location.nativeElement, 'casino-lobby-container-main');
        if (this.componentRef_ && this.jsonData) {
            this.assignDatatoComponent(this.jsonData);
        }
    }
    assignDatatoComponent(jsonData: any) {
        Object.keys(jsonData).forEach((key: string) => {
            this.componentRef_.instance[key] = jsonData[key];
        });
    }

    ngOnDestroy(): void {
        this.ngModuleRef && this.ngModuleRef.destroy();
    }
}
