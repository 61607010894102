import { CurrencyPipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
    BingoConfigProviderService,
    BingoHelperService,
    BingoTournamentsService,
    BingoTournamentsTrackingService,
    Race,
    SitecoreContent,
} from '@frontend/bingo/core';
import { Subscription } from 'rxjs';

import { BingoSlotRacesResultsComponent } from '../results/bingo-results.component';

@Component({
    selector: 'bgl-slot-races-info',
    templateUrl: 'bingo-race-info.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class BingoSlotRacesInfoComponent implements OnInit, OnDestroy {
    title: string;
    versionedData: any;
    sharedData: any;
    race: Race;
    isLive: boolean = false;
    contentSubscription: Subscription;
    liveRacesSubscription: Subscription;
    isUpcoming: boolean;
    fromResult: boolean = false;
    raceResult: any;
    raceinfoopt: any;
    displayBonusAsText: boolean;
    resultDialogRef: MatDialogRef<BingoSlotRacesResultsComponent>;
    enableResultsHistoryPage: boolean = false;
    isMobileView: boolean;
    coinImg: string;
    coinObj: any;
    currencysymbol: string;
    isInfoOpened: boolean;
    activeCategory: string;

    constructor(
        private dialogRef: MatDialogRef<BingoSlotRacesInfoComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private slotRacesService: BingoTournamentsService,
        private currencyPipe: CurrencyPipe,
        private configProviderService: BingoConfigProviderService,
        private slotRacesTracking: BingoTournamentsTrackingService,
        private bingoHelperService: BingoHelperService,
    ) {
        const configs = this.configProviderService.provideBingoTournamentsClientConfig();
        this.enableResultsHistoryPage = this.bingoHelperService.getConfigBasedOnInvokerProduct(configs.enableResultsHistoryPage);
        this.displayBonusAsText = this.bingoHelperService.getConfigBasedOnInvokerProduct(configs.displayBonusAsText);
        this.coinImg = data.coinImg;
        this.activeCategory = this.isInfoOpened ? 'info overlay' : this.slotRacesService.getActiveCategory();

        if (this.enableResultsHistoryPage && data.raceResult) {
            this.raceResult = data.raceResult;
            this.fromResult = true;
            this.resultDialogRef = data.resultsDialogRef;
            this.raceResult?.configMaps?.forEach((configMap: any) => {
                if (configMap && configMap?.awardType) {
                    this.currencysymbol = configMap.currency;
                    if (configMap && configMap?.awardType.toLowerCase() != 'coins') {
                        if (this.raceResult.isWin && configMap?.value) {
                            this.raceResult.awardType = configMap.awardType;
                            if (!this.displayBonusAsText) {
                                if (
                                    configMap?.awardType?.toLowerCase() == 'freespins' ||
                                    configMap?.awardType?.toLowerCase() == 'free_spin' ||
                                    configMap?.awardType?.toLowerCase() == 'bingoticket' ||
                                    !configMap?.currency ||
                                    !configMap?.currency?.length
                                ) {
                                    this.raceResult.awardValue = Number(configMap.value);
                                } else {
                                    this.raceResult.awardValue = this.currencyPipe.transform(
                                        configMap?.value,
                                        configMap?.currency,
                                        'symbol-narrow',
                                        '1.2-2',
                                    );
                                }
                            } else {
                                if (configMap && configMap.awardType && configMap.awardType?.toLowerCase().indexOf('cash') > -1) {
                                    this.raceResult.awardValue = this.currencyPipe.transform(
                                        configMap.value,
                                        configMap.currency,
                                        'symbol-narrow',
                                        '1.2-2',
                                    );
                                } else {
                                    this.raceResult.awardValue = Number(configMap.value);
                                }
                            }
                        } else {
                            this.raceResult.awardValue = '--';
                        }
                    }
                    if (configMap && configMap?.awardType.toLowerCase() == 'coins') {
                        this.raceResult.isDisplayCoin = true;
                        this.raceResult.coinValue = this.raceResult.isDisplayCoin ? configMap.value : '';
                    }
                } else {
                    this.raceResult.awardValue = '--';
                }
            });
        }

        this.race = data.race;
        this.isLive = !!this.race?.isLive;
        this.isUpcoming = !this.isLive;
        this.raceinfoopt = data.enableOptin;
        this.coinObj = this.slotRacesService.bindCoinImg(this.race, data.coinImg);
    }

    content: SitecoreContent = {
        bingoRaceRulesConfigs: [],
        rules: [],
        rulesBanner: {},
        placeholderCardImage: {},
        coinEconomy: {},
        textTranslations: { sharedList: {}, versionedList: {} },
        tips: [],
        errorMessages: { sharedList: {}, versionedList: {} },
        entryDetails: { sharedList: {}, versionedList: {} },
    };

    ngOnInit() {
        if (!this.fromResult) {
            this.liveRacesSubscription = this.slotRacesService.liveRacesData.subscribe((data: any) => {
                if (data) {
                    this.updateRaceStatus(data);
                }
            });
        } else if (this.enableResultsHistoryPage && this.fromResult) {
            this.dialogRef.backdropClick().subscribe(() => {
                if (this.resultDialogRef) {
                    this.resultDialogRef.close();
                }
            });
            this.dialogRef.keydownEvents().subscribe((event: any) => {
                if (event.keyCode === 27) {
                    this.resultDialogRef.close();
                }
            });
        }
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.content = content;
            this.versionedData = content.textTranslations.versionedList;
            this.sharedData = content.textTranslations.sharedList;
            if (this.versionedData) {
                if (this.race && !this.race.isLive) {
                    this.title = this.versionedData.UpcomingRaceOverlayTitle ? this.versionedData.UpcomingRaceOverlayTitle : '';
                } else {
                    this.title = this.versionedData.LiveRaceOverlayTitle ? this.versionedData.LiveRaceOverlayTitle : '';
                }
            }
        });
    }

    close(dialogResult: any = {}) {
        if (this.enableResultsHistoryPage && this.fromResult) {
            const resultsDialog = document.getElementsByClassName('bt-results-dialog')[0] as HTMLElement;
            resultsDialog.style.display = '';
        }
        this.slotRacesTracking.track('close', this.race?.gameId, this.activeCategory, 'info overlay');
        this.dialogRef.close(dialogResult);
    }

    openPickGameOverlay() {
        this.close({ openPickGameOverlay: true });
    }
    openNotificationDialog() {
        this.close({ openNotificationOverlay: true });
    }

    updateRaceStatus(data: any) {
        const liveRaces: any[] = data.liveNow;
        const isLiveRace = liveRaces.some((race: Race) => {
            if (race.promoId == this.race.promoId && race.slotUniqueId == this.race.slotUniqueId) {
                this.race.isLive = true;
                return this.race.isLive;
            }
            return null;
        });
        const isUpcomingRace = !isLiveRace;
        if (this.isLive !== isLiveRace || this.isUpcoming !== isUpcomingRace) {
            this.close();
        }
    }

    ngOnDestroy() {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
        if (this.liveRacesSubscription) {
            this.liveRacesSubscription.unsubscribe();
        }
    }
}
