<div class="sessionbingo-confirm-container">
    <div class="confirm-model-container">
        <span class="confirm-model-title">{{ content.Confirmpopuppurchase }}</span>
        <p class="confirm-model-msg">
            {{ content.ConfirmpopupUpareAboutTo }} {{ noOfTickets }} {{ content.ConfirmpopupUpTicketsfor }} {{ noOfGames }}
            {{ content.ConfirmpopupUpgamesforpurchaseamount }} {{ totalPrice | vnCurrency }}
        </p>
        <div class="confirm-model-btns">
            <!-- <div class="btn confirm-cancel-btn" (click)="close('cancel')">{{ content.ConfirmpopupCancelButtonText }}</div> -->
            <bg-custombutton
                (click)="close('cancel')"
                [variant]="'outline'"
                [kind]="'tertiary'"
                [size]="'medium'"
                [customClass]="'btn confirm-cancel-btn'"
                [button_text]="content.ConfirmpopupCancelButtonText"
                >{{ content.ConfirmpopupCancelButtonText }}
            </bg-custombutton>
            <!-- <div class="btn confirm-btn" (click)="confirm()">{{ content.ConfirmpopupConfirmButtonText }}</div> -->
            <bg-custombutton
                (click)="confirm()"
                [variant]="'filled'"
                [size]="'medium'"
                [kind]="'primary'"
                [customClass]="'btn confirm-btn'"
                [button_text]="content.ConfirmpopupConfirmButtonText"
                >{{ content.ConfirmpopupConfirmButtonText }}
            </bg-custombutton>
        </div>
    </div>
</div>
