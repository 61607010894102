import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: thunderBiteClientConfigFactory,
})
@LazyClientConfig({ key: 'bgThunderBiteConfig', product: ClientConfigProductName.BINGO })
export class ThunderBiteClientConfig {
    gameIdPath: string;
    isThunderBiteEnabled: boolean;
    thunderBiteIframeHeight: number;
}
export function thunderBiteClientConfigFactory(service: LazyClientConfigService) {
    return service.get(ThunderBiteClientConfig);
}
