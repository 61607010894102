import { NgStyle } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    FetchUserRequestAPIModel,
    FetchUserResponseAPIModel,
    GamingStoriesPhase2Configuration,
    GamingStoriesService,
    IGamingStoryItem,
    ResponseDataModel,
    StoryInfoData,
    StoryInfoDataInSessionStorage,
} from '@casinocore/platform/core';
import { LoginDialogService, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'cc-gaming-story-emoji-slider',
    templateUrl: 'gaming-story-emoji-slider.component.html',
    standalone: true,
    imports: [ReactiveFormsModule, NgStyle, FormsModule],
})
export class GamingStoryEmojiSliderComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    // @ViewChild('emoji', { static: false }) emoji: ElementRef<HTMLDivElement>;
    @ViewChild('slider', { static: false }) slider: ElementRef;
    @Input() storyItem: IGamingStoryItem;
    @Input() fetchUserPreparedRequest: FetchUserRequestAPIModel;
    @Input() fetchingGamingStoriesAPIRes: FetchUserResponseAPIModel;

    sliderValue: number = 0;
    slideDisable: boolean = false;
    emojiPath: string;
    isAuthenticated: boolean;

    private userSessionStorageData: StoryInfoDataInSessionStorage;
    private gamingStoriesconfig: GamingStoriesPhase2Configuration;
    private expirationTime: number;
    private templateType: string = 'EmojiSlider';
    private isInitialSelect: boolean = false;
    private isFetchValueAvailable: boolean = false;
    private isServiceValueAvailable: boolean = false;
    private uInput: string = '';
    private userSubscription: Subscription;

    constructor(
        private gamingStoriesService: GamingStoriesService,
        private user: UserService,
        private loginDialog: LoginDialogService,
        private changeDetection: ChangeDetectorRef,
    ) {}

    ngOnChanges() {
        this.emojiPath = this.storyItem.emojies[0].emojiPath;
        this.sliderValue = 0;
        this.slider?.nativeElement?.style.setProperty('--range', `${this.sliderValue}%`);
        this.slideDisable = false;
        this.changeDetection.detectChanges();

        this.validationCheck();
    }

    ngOnInit(): void {
        this.emojiPath = this.storyItem.emojies[0].emojiPath;
    }

    ngAfterViewInit(): void {
        this.userSubscription = this.user.events.pipe(filter((e: any) => e instanceof UserLoginEvent)).subscribe(() => {
            setTimeout(() => {
                this.isAuthenticated = this.user.isAuthenticated;
                this.bindPreLoginSessionData();
                this.validatePreLoginSessionToStoreInPostLoginSession();
                this.validationCheck();
            }, 1000);
        });
        this.validationCheck();
    }

    // sliderUp() {
    //   this.slideDisable = true;
    //   // this.emoji ?.nativeElement.classList.add("emoji-hide");
    //   // this.emoji.nativeElement.style.opacity = "0";
    // }

    sliderChange(event: Event) {
        const target = event.target as HTMLInputElement;
        this.gamingStoriesconfig = this.gamingStoriesService.config;
        if (this.gamingStoriesconfig) {
            this.expirationTime = this.gamingStoriesconfig.expirationTime;
        }
        if (this.user.isAuthenticated) {
            this.gamingStoriesService.trackingGamingStoriesPhase2Event(
                'teaser - embedded video',
                'stories',
                'click',
                this.storyItem.description.replace(/<[^>]*>/g, ''),
                this.storyItem.title,
                this.storyItem.emojies[0].emojiName + '-' + target.value,
                this.storyItem.actionTypePosition,
                'not applicable',
            );
            this.isAuthenticated = this.user.isAuthenticated;
            this.gamingStoriesService.pauseStory(false);
            this.slideDisable = true;
            this.slider?.nativeElement?.style.setProperty('--range', `${target.value}%`);
            const storyInfo: StoryInfoData = new StoryInfoData();
            storyInfo.storyId = this.storyItem.storyId;
            storyInfo.subStoryId = this.storyItem.storyItemId;
            storyInfo.storyType = this.storyItem.storyType;
            storyInfo.templateType = this.templateType;
            storyInfo.userInput = target.value.toString();
            storyInfo.expTime = this.storyItem.expiryTime;
            storyInfo.storyIndex = 3;
            // this.gamingStoriesService.storyInfoPostRequestList = this.gamingStoriesService.storyInfoPostRequestList.filter(
            //     (x) => x.storyIndex !== storyInfo.storyIndex,
            // );
            this.gamingStoriesService.storyInfoRequestItemsList(storyInfo);
        } else {
            this.gamingStoriesService.storyInfoPostRequestList = [];
            this.gamingStoriesService.pauseStory(true);
            const loginDialogRef = this.loginDialog.open();
            const afterclosesubcription: Subscription = loginDialogRef.afterClosed().subscribe(() => {
                if (afterclosesubcription) {
                    if (!this.user.isAuthenticated) {
                        this.gamingStoriesService.pauseStory(false);
                        this.sliderValue = 0;
                        this.slider?.nativeElement?.style.setProperty('--range', `${this.sliderValue}%`);
                        this.slideDisable = false;
                    }
                    afterclosesubcription.unsubscribe();
                }
            });
        }
        this.storeDataToSession(target.value);
        //this.showEmoji(this.sliderValue);
        //this.moveEmoji(this.sliderValue);
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    private storeDataToSession(eleName: string) {
        const currentEpocTime = new Date().getTime() / 1000;
        const storyInfoDataInSessionStorage: StoryInfoDataInSessionStorage = new StoryInfoDataInSessionStorage();
        storyInfoDataInSessionStorage.response = new ResponseDataModel();
        storyInfoDataInSessionStorage.response.sId = this.storyItem.storyId;
        storyInfoDataInSessionStorage.response.subSId = this.storyItem.storyItemId;
        storyInfoDataInSessionStorage.response.uInput = eleName;
        storyInfoDataInSessionStorage.response.tType = this.templateType;
        storyInfoDataInSessionStorage.expiryTime = Math.round(currentEpocTime + this.expirationTime * 60);
        storyInfoDataInSessionStorage.userId = this.user.id ? this.user.id : '';
        storyInfoDataInSessionStorage.storyIndex = 3;
        if (!this.user.isAuthenticated) {
            this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList =
                this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList.filter(
                    (x) => x.storyIndex !== storyInfoDataInSessionStorage.storyIndex,
                );
            this.gamingStoriesService.storingDataInPreLoginSessionStorage(storyInfoDataInSessionStorage);
        } else {
            this.gamingStoriesService.storeFetchAPIDataRes(storyInfoDataInSessionStorage);
        }
    }

    private bindPreLoginSessionData() {
        if (sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage')) {
            this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList.forEach((res) => {
                if (res.response.sId === this.storyItem.storyId && res.response.subSId === this.storyItem.storyItemId && res.userId === '') {
                    res.userId = this.user.id ? this.user.id : '';
                    this.userSessionStorageData = res;
                }
            });
            this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList =
                this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList.filter(
                    (x) => x.storyIndex !== this.userSessionStorageData.storyIndex,
                );
            this.gamingStoriesService.storingDataInPreLoginSessionStorage(this.userSessionStorageData);
            this.preparePreLoginSelectedDataForPostAPI(this.userSessionStorageData);
        }
    }

    private preparePreLoginSelectedDataForPostAPI(storyInfoData: StoryInfoDataInSessionStorage) {
        const storyInfo: StoryInfoData = new StoryInfoData();
        storyInfo.storyId = storyInfoData.response.sId;
        storyInfo.subStoryId = storyInfoData.response.subSId;
        storyInfo.storyType = this.storyItem.storyType;
        storyInfo.templateType = this.templateType;
        storyInfo.userInput = storyInfoData.response.uInput;
        storyInfo.expTime = this.storyItem.expiryTime;
        storyInfo.storyIndex = 3;
        this.gamingStoriesService.storyInfoPostRequestList = this.gamingStoriesService.storyInfoPostRequestList.filter(
            (x) => x.storyIndex !== storyInfo.storyIndex,
        );
        this.gamingStoriesService.storyInfoRequestItemsList(storyInfo);
    }

    private validatePreLoginSessionToStoreInPostLoginSession() {
        // if fetch API response available.
        // we are not storing selected data any where when it matches with fetch api response.
        if (this.fetchingGamingStoriesAPIRes) {
            if (sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage')) {
                const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage') as string);
                this.fetchingGamingStoriesAPIRes.response.forEach((fetchData) => {
                    sessionStorageServiceStoryData.forEach((res: any) => {
                        if (
                            res.userId === this.fetchingGamingStoriesAPIRes.uId &&
                            res.response.sId === fetchData.sId &&
                            res.response.subSId == fetchData.subSId &&
                            fetchData.uInput !== undefined
                        ) {
                            sessionStorage.removeItem('GS:StoryItemsInPreLoginSessionStorage');
                        } else {
                            this.fetchUserPreparedRequest.reqStoryIds.forEach((fetchReq) => {
                                if (res.response.sId === fetchReq.storyId && res.response.subSId == fetchReq.subStoryId) {
                                    this.gamingStoriesService.storeFetchAPIDataRes(res);
                                    sessionStorage.removeItem('GS:StoryItemsInPreLoginSessionStorage');
                                }
                            });
                        }
                    });
                });
            }
        }
        // if fetch API response not available or failed scenario.
        // storing selected data in Service level and session level in post-login key.
        else {
            if (sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage')) {
                const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage') as string);
                sessionStorageServiceStoryData.forEach((res: any) => {
                    this.fetchUserPreparedRequest.reqStoryIds.forEach((fetchReq) => {
                        if (res.response.sId === fetchReq.storyId && res.response.subSId == fetchReq.subStoryId) {
                            this.gamingStoriesService.storeFetchAPIDataRes(res);
                            sessionStorage.removeItem('GS:StoryItemsInPreLoginSessionStorage');
                        }
                    });
                });
            }
        }
    }

    private validationCheck() {
        if (this.user.isAuthenticated) {
            // fetch call response
            if (this.fetchingGamingStoriesAPIRes) {
                this.fetchingGamingStoriesAPIRes.response.forEach((sfetchres: any) => {
                    this.uInput = sfetchres.uInput;
                    if (
                        this.fetchingGamingStoriesAPIRes.uId === this.user.id &&
                        sfetchres.sId === this.storyItem.storyId &&
                        sfetchres.subSId == this.storyItem.storyItemId &&
                        this.uInput !== undefined &&
                        !this.isInitialSelect
                    ) {
                        this.bindQuizData(this.uInput);
                        this.isFetchValueAvailable = true;
                    }
                });
            }
            // service level data check bind
            if (this.gamingStoriesService.storeFetchAPIDataResList.length > 0 && !this.isFetchValueAvailable) {
                this.gamingStoriesService.storeFetchAPIDataResList.forEach((sServiceres: any) => {
                    if (
                        sServiceres.userId === this.user.id &&
                        sServiceres.response.sId === this.storyItem.storyId &&
                        sServiceres.response.subSId == this.storyItem.storyItemId &&
                        sServiceres.response.uInput !== undefined &&
                        !this.isInitialSelect
                    ) {
                        this.bindQuizData(sServiceres.response.uInput);
                        this.isServiceValueAvailable = true;
                    }
                });
            }
            // session storage check bind
            if (sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage') && !this.isServiceValueAvailable) {
                const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage') as string);
                this.gamingStoriesService.storeFetchAPIDataResList = [];
                sessionStorageServiceStoryData.forEach((sSessionres: any) => {
                    this.gamingStoriesService.storeFetchAPIDataRes(sSessionres);
                    if (
                        sSessionres.userId === this.user.id &&
                        sSessionres.response.sId === this.storyItem.storyId &&
                        sSessionres.response.subSId == this.storyItem.storyItemId &&
                        sSessionres.response.uInput !== undefined &&
                        !this.isInitialSelect
                    ) {
                        this.bindQuizData(sSessionres.response.uInput);
                    }
                });
            }
            this.gamingStoriesService.pauseStory(false);
        }
    }

    private bindQuizData(uInput: any) {
        this.sliderValue = parseInt(uInput);
        this.slider?.nativeElement?.style.setProperty('--range', `${this.sliderValue}%`);
        this.slideDisable = true;
        this.isInitialSelect = true;
        this.gamingStoriesService.trackingGamingStoriesPhase2Event(
            'teaser - embedded video',
            'stories',
            'click',
            this.storyItem.description.replace(/<[^>]*>/g, ''),
            this.storyItem.title,
            this.storyItem.emojies[0].emojiName + '-' + this.sliderValue,
            this.storyItem.actionTypePosition,
            'not applicable',
        );
        this.changeDetection.detectChanges();
    }
}
