<div *ngIf="!showRaceCard" class="bt-list-container" [ngClass]="last ? 'bingo-list-last' : ''">
    <div class="bt-upcoming-race-list">
        <div class="bt-time-text">
            <div class="bt-text-time">{{ race.startTimeAMPM }}</div>
            <div
                class="bt-dot-dividers"
                [ngClass]="first ? (last ? 'red-dot-first red-dot-last' : 'red-dot-first') : last ? 'red-dot-last' : ''"></div>
            <div class="bt-game-name">
                {{ title }}
                <div class="optin-with-coin" *ngIf="race?.isOptinWithCoins">
                    {{ content?.textTranslations?.versionedList?.OptinCoinText }}
                </div>
            </div>
        </div>
        <div class="bt-prize">
            <div [ngClass]="{ 'bingo-currency-space': !noncurrenyValue }" class="bt-prize-amount">
                {{ race?.additionalParameters | prize: false }}
            </div>
            <div
                class="bt-theme-icon"
                *ngIf="race?.additionalParameters?.awardType?.toLowerCase() != 'coins'"
                [ngClass]="content?.textTranslations?.sharedList[(race?.additionalParameters | prize: true)]"></div>
            <span class="bt-card-total-coin" *ngIf="race?.additionalParameters?.awardType?.toLowerCase() == 'coins'">
                <img class="coin-img" [src]="coinImg" alt="Coin Image" />
            </span>
            <ng-container *ngIf="coinObj?.isDisplayCoin">
                <span class="theme-plus"></span>
                <span class="bt-card-total-coin">
                    <img class="bt-coin-img" [src]="coinObj?.coinSrc" alt="Coin Image" />
                </span>
            </ng-container>
            <div class="bt-theme-icon theme-down" (click)="toggleRaceCardVisibility()"></div>
        </div>
    </div>
</div>

<div *ngIf="showRaceCard">
    <div class="bingo-wrapper-bt-expand">
        <bgl-slot-race-card
            [enableOptin]="enableOptin"
            (hideCard)="toggleRaceCardVisibility()"
            [race]="race"
            [showRaceInfo]="true"
            [showCardMenu]="true"
            [first]="first"
            [last]="last"
            (openRaceInfo)="openRaceInfoDialog()"
            (openPickGameOverlay)="openPickGameDialog()"
            (openNotificationOverlay)="openNotificationDialog()"
            [targetedPlayerCount]="race.targetedPlayerCount"
            [coinImg]="coinImg"
            [selectedOption]="selectedOption">
        </bgl-slot-race-card>
    </div>
</div>
