import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { BingoBeatTheBankerNotificationComponent } from './bingo-beat-the-banker-notification';

@NgModule({
    imports: [CommonModule, SharedModule],
    declarations: [BingoBeatTheBankerNotificationComponent],
    exports: [BingoBeatTheBankerNotificationComponent],
})
export class BingoBeatTheBankerNotificationModule {
    public static forRoot(): ModuleWithProviders<BingoBeatTheBankerNotificationModule> {
        return {
            ngModule: BingoBeatTheBankerNotificationModule,
        };
    }
}
