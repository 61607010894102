<div class="buy-sessionbingo-model">
    <div *ngIf="PackageDetails && !packagePurchased">
        <div class="buy-sessionbingo-header">
            <span class="buy-sessionbingo-title">{{ content.BuyNowPopupHeader }}</span
            ><i class="theme-ex" (click)="close()"></i>
        </div>
        <!-- <div *ngIf="packagePurchased">
            {{content.GamesCompleted}}
        </div> -->
        <bg-buynow-body [buyNowDetails]="PackageDetails" [isPurchased]="isPurchased" [isInGameClient]="isInGameClient"> </bg-buynow-body>
        <div class="buy-sessionbingo-footer">
            <div *ngIf="data.package_type == 'package_bingo' || isInGameClient">
                <bg-custombutton
                    *ngIf="!disableButton && !isPurchased && !PurchaseButton"
                    (click)="buyNowClicked()"
                    [variant]="'filled'"
                    [size]="'medium'"
                    [inverse]="true"
                    [kind]="'primary'"
                    [customClass]="'buy-session-btn btn-primary'"
                    [button_text]="content.BuyNowPopupButtonText"
                    >{{ content.BuyNowPopupButtonText }}
                </bg-custombutton>
                <bg-custombutton
                    *ngIf="PurchaseButton"
                    (click)="buyNowClicked()"
                    [variant]="'filled'"
                    [size]="'medium'"
                    [kind]="'primary'"
                    [inverse]="true"
                    [customClass]="'buy-session-btn btn-primary'"
                    [button_text]="content.PurchaseButton"
                    >{{ content.PurchaseButton }}
                </bg-custombutton>
                <bg-custombutton
                    *ngIf="disableButton || (isPurchased && !PurchaseButton)"
                    [variant]="'filled'"
                    [kind]="'primary'"
                    [inverse]="true"
                    [size]="'medium'"
                    [customClass]="'buy-session-btn btn-primary disabled'"
                    [button_text]="content.Purchased"
                    >{{ content.Purchased }}
                </bg-custombutton>
                <!-- <div class="btn buy-session-btn btn-primary" (click)="buyNowClicked()" *ngIf="!disableButton && !isPurchased && !PurchaseButton">
                    {{ content.BuyNowPopupButtonText }}
                </div>
                <div class="btn buy-session-btn btn-primary" (click)="buyNowClicked()" *ngIf="PurchaseButton">
                    {{ content.PurchaseButton }}
                </div>
                <div class="btn buy-session-btn btn-primary disabled" *ngIf="disableButton || (isPurchased && !PurchaseButton)">
                    {{ content.Purchased }}
                </div> -->
            </div>
            <div *ngIf="data.package_type == 'session_bingo' && !isInGameClient">
                <div class="purchase-block">
                    <!-- <div class="btn buy-session-btn btn-primary" (click)="buyNowClicked()"
                        *ngIf="!disableButton && !isPurchased && !PurchaseButton">
                        {{ content.BuyNowPopupButtonText }}
                    </div> -->

                    <bg-custombutton
                        *ngIf="!disableButton && !isPurchased && !PurchaseButton"
                        (click)="buyNowClicked()"
                        [variant]="'filled'"
                        [size]="'medium'"
                        [inverse]="true"
                        [kind]="'primary'"
                        [customClass]="'btn buy-session-btn btn-primary'"
                        [button_text]="content.BuyNowPopupButtonText"
                        >{{ content.BuyNowPopupButtonText }}
                    </bg-custombutton>
                    <!-- <div class="btn buy-session-btn btn-primary" (click)="buyNowClicked()" *ngIf="PurchaseButton">
                        {{ content.PurchaseButton }}
                    </div> -->

                    <bg-custombutton
                        *ngIf="PurchaseButton"
                        (click)="buyNowClicked()"
                        [variant]="'filled'"
                        [kind]="'primary'"
                        [inverse]="true"
                        [size]="'medium'"
                        [customClass]="'btn buy-session-btn btn-primary'"
                        [button_text]="content.PurchaseButton"
                        >{{ content.PurchaseButton }}
                    </bg-custombutton>
                    <!-- <div class="btn buy-session-btn btn-primary disabled"
                        *ngIf="disableButton || (isPurchased && !PurchaseButton)">
                        {{ content.Purchased }}
                    </div> -->

                    <bg-custombutton
                        *ngIf="disableButton || (isPurchased && !PurchaseButton)"
                        [variant]="'filled'"
                        [kind]="'primary'"
                        [inverse]="true"
                        [size]="'medium'"
                        [customClass]="'btn buy-session-btn btn-primary disabled'"
                        [button_text]="content.Purchased"
                        >{{ content.Purchased }}
                    </bg-custombutton>
                </div>
                <div class="playnow-block">
                    <!-- <div class="btn buy-session-btn btn-primary" (click)="gameLaunch()">
                        {{ content.PlayNow }}
                    </div> -->

                    <bg-custombutton
                        (click)="gameLaunch()"
                        [variant]="'filled'"
                        [kind]="'primary'"
                        [size]="'medium'"
                        [inverse]="true"
                        [customClass]="'btn buy-session-btn btn-primary'"
                        [button_text]="content.PlayNow"
                        >{{ content.PlayNow }}
                    </bg-custombutton>
                </div>
            </div>
        </div>
    </div>
    <i class="theme-ex package-purchased-close" (click)="close()"></i>
    <div *ngIf="packagePurchased" class="package-purchased">
        <div class="package-gamescompleted">
            {{ content.GamesCompleted }}
        </div>
    </div>
</div>
