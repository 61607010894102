import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: casinoClientConfigFactory,
})
@LazyClientConfig({ key: 'bgCasinoConfig', product: ClientConfigProductName.BINGO })
export class CasinoClientConfig {
    GYMLFeedId: string;
    gameIconUrlTemplate: string;
    casinoLobbyWidgetUrl: string;
    casinoRPWidgetUrl: string;
}
export function casinoClientConfigFactory(service: LazyClientConfigService) {
    return service.get(CasinoClientConfig);
}
