import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { BingoConfigProviderService, FeaturesClientConfig } from '@frontend/bingo/core';

@Component({
    selector: 'bg-prebuy-slider',
    templateUrl: 'preBuy-Slider.component.html',
})
export class PreBuySliderComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() maxium: number;
    @Input() id: any;
    @Input() min: number;
    @Input() value: number;
    @Input() toggle: boolean;
    @Output() onSelected: EventEmitter<any> = new EventEmitter<any>();
    step: number = 1;
    addDisbled: boolean;
    disableAllPrebuy: boolean;
    featuresConfig: FeaturesClientConfig;

    constructor(private configProviderService: BingoConfigProviderService) {
        this.featuresConfig = this.configProviderService.provideFeaturesConfig();
    }

    ngOnInit() {
        this.disableAllPrebuy = this.featuresConfig.disableAllPrebuy;
        if (this.value == this.maxium) this.addDisbled = true;
    }

    AppendClass(param: number) {
        if (param == 0) {
            if (this.featuresConfig.enableNewPrebuySliderIcons) {
                if (this.value == this.min) {
                    return 'theme-bingo-gala-minus disabled';
                } else {
                    return 'theme-bingo-gala-minus';
                }
            } else {
                if (this.value == this.min) {
                    return 'theme-minus disabled';
                } else {
                    return 'theme-minus';
                }
            }
        } else if (param == 1) {
            if (this.featuresConfig.enableNewPrebuySliderIcons) {
                if (this.value == this.maxium) {
                    return 'theme-bingo-gala-plus disabled';
                } else {
                    return 'theme-bingo-gala-plus';
                }
            } else {
                if (this.value == this.maxium) {
                    return 'theme-plus disabled';
                } else {
                    return 'theme-plus';
                }
            }
        }
        return 0;
    }

    ngAfterViewInit() {
        this.appendClass();
    }

    ngOnChanges() {
        if (this.value == this.maxium) this.addDisbled = true;
        else this.addDisbled = false;
        this.appendClass();
    }
    OnValueChanged(event: number) {
        this.onSelected.emit({
            selectedValue: event,
            id: this.id,
        });
        // this.PreBuyGridComponentChild?.preBuySliderTracking();
    }
    appendClass() {
        const timeout = this.featuresConfig.ticketIconTimeout ? this.featuresConfig.ticketIconTimeout : 3000;
        setTimeout(() => {
            //let elem = document.querySelectorAll('.bingo-prebuy-slider .mat-slider-thumb-label-text');
            const elem = document.querySelectorAll('.bingo-prebuy-slider .mdc-slider__value-indicator-text');
            elem.forEach((ele: any) => {
                if (ele && ele.classList.contains('theme-ticket-i')) return;
                else if (elem) ele.classList.add('theme-ticket-i');
            });
        }, timeout);
    }

    onPlusclick() {
        this.addDisbled = this.value >= +this.maxium - 1;
        this.onSelected.emit({
            selectedValue: this.value < +this.maxium ? this.value + 1 : +this.maxium,
            id: this.id,
        });
    }

    onMinusclick() {
        if (this.value > +this.min) this.addDisbled = false;
        this.onSelected.emit({
            selectedValue: this.value > +this.min ? this.value - 1 : +this.min,
            id: this.id,
        });
    }
}
