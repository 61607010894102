<div class="fluffy">
    <i [ngClass]="enableNewPrebuyIcons ? 'theme-bingo-gala-minus' : 'theme-minus'" [ngClass]="AppendClass(0)" (click)="onMinusclick()"></i>
    <mat-slider
        min="{{ min }}"
        max="{{ max }}"
        step="{{ step }}"
        discrete
        tickInterval="auto"
        [displayWith]="formatLabel.bind(this)"
        class="cdk-focused">
        <input matSliderThumb thumbLabel (valueChange)="OnValueChanged($event)" value="{{ value }}" />
    </mat-slider>
    <i [ngClass]="enableNewPrebuyIcons ? 'theme-bingo-gala-plus' : 'theme-plus'" [ngClass]="AppendClass(1)" (click)="onPlusclick()"></i>
</div>
