import { LowerCasePipe, NgClass, NgFor, NgIf, NgStyle, NgSwitch, NgSwitchCase } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import {
    FetchUserRequestAPIModel,
    FetchUserResponseAPIModel,
    GamingStoriesPhase2Configuration,
    GamingStoriesService,
    IGamingStoryItem,
    ResponseDataModel,
    StoryInfoData,
    StoryInfoDataInSessionStorage,
} from '@casinocore/platform/core';
import { LoginDialogService, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'cc-gaming-story-emojis-animations',
    templateUrl: 'gaming-story-emojis-animations.component.html',
    standalone: true,
    imports: [NgIf, NgFor, NgSwitch, NgSwitchCase, NgStyle, NgClass, LowerCasePipe],
})
export class GamingStoryEmojisAnimationsComponent implements OnInit, OnDestroy, OnChanges {
    @Input() storyItem: IGamingStoryItem;
    @Input() fetchUserPreparedRequest: FetchUserRequestAPIModel;
    @Input() fetchingGamingStoriesAPIRes: FetchUserResponseAPIModel;

    showEmojies: boolean = false;
    messageTxtClr: string;
    messageBgClr: string;
    messageTxt: string;
    messageClass: string;
    showMessageessageSent: boolean = false;
    show: boolean;
    item: string;
    selectedEmojieName: string;
    disableEmojis: boolean = false;
    emojiBtnPosition: string;

    private userSessionStorageData: StoryInfoDataInSessionStorage;
    private gamingStoriesconfig: GamingStoriesPhase2Configuration;
    private expirationTime: number;
    private templateType: string = 'EmojiSelector';
    private isInitialSelect: boolean = false;
    private isFetchValueAvailable: boolean = false;
    private isServiceValueAvailable: boolean = false;
    private uInput: string = '';
    private userSubscription: Subscription;

    constructor(
        private gamingStoriesService: GamingStoriesService,
        private user: UserService,
        private loginDialog: LoginDialogService,
        private changeDetection: ChangeDetectorRef,
    ) {}

    ngOnChanges() {
        this.setAttributes();
        this.selectedEmojieName = '';
        this.disableEmojis = false;
        this.validationCheck();
    }

    ngOnInit(): void {
        this.setAttributes();
        this.userSubscription = this.user.events.pipe(filter((e: any) => e instanceof UserLoginEvent)).subscribe(() => {
            setTimeout(() => {
                this.bindPreLoginSessionData();
                this.validatePreLoginSessionToStoreInPostLoginSession();
                this.validationCheck('loginEvent');
            }, 1000);
        });
        this.validationCheck();
    }

    clickHandler(eleName: string) {
        this.gamingStoriesconfig = this.gamingStoriesService.config;
        if (this.gamingStoriesconfig) {
            this.expirationTime = this.gamingStoriesconfig.expirationTime;
        }
        if (this.user.isAuthenticated) {
            this.gamingStoriesService.trackingGamingStoriesPhase2Event(
                'teaser - embedded video',
                'stories',
                'click',
                'not applicable',
                this.storyItem.title,
                eleName,
                this.storyItem.actionTypePosition,
                'not applicable',
            );
            this.gamingStoriesService.pauseStory(false);
            const storyInfo: StoryInfoData = new StoryInfoData();
            storyInfo.storyId = this.storyItem.storyId;
            storyInfo.subStoryId = this.storyItem.storyItemId;
            storyInfo.storyType = this.storyItem.storyType;
            storyInfo.templateType = this.templateType;
            storyInfo.userInput = eleName;
            storyInfo.expTime = this.storyItem.expiryTime;
            storyInfo.storyIndex = 0;
            // this.gamingStoriesService.storyInfoPostRequestList = this.gamingStoriesService.storyInfoPostRequestList.filter(
            //     (x) => x.storyIndex !== storyInfo.storyIndex,
            // );
            this.gamingStoriesService.storyInfoRequestItemsList(storyInfo);
            this.mandatorySelection(eleName);
            this.animationFlow(eleName);
            setTimeout(() => {
                this.showMessageessageSent = true;
                setTimeout(() => {
                    this.showMessageessageSent = false;
                }, 500);
            }, 7200);
        } else {
            this.gamingStoriesService.pauseStory(true);
            const loginDialogRef = this.loginDialog.open();
            const afterclosesubcription: Subscription = loginDialogRef.afterClosed().subscribe(() => {
                if (afterclosesubcription) {
                    if (!this.user.isAuthenticated) {
                        this.gamingStoriesService.pauseStory(false);
                        this.selectedEmojieName = '';
                    }
                    afterclosesubcription.unsubscribe();
                }
            });
        }
        this.storeDataToSession(eleName);
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    private setAttributes() {
        this.messageTxtClr = this.storyItem.configurations.MessageTxtClr;
        this.messageBgClr = this.storyItem.configurations.MessageBgClr;
        this.messageTxt = this.storyItem.configurations.MesssageTxt;
        this.messageClass = this.storyItem.configurations.MessageClass;
        this.emojiBtnPosition = this.storyItem.actionTypePosition;
        this.showEmojies = true;
    }

    private animationFlow(eleName: string) {
        this.item = eleName;
        this.show = true;
        this.changeDetection.detectChanges();
        const dateTime: number = Date.now() + 5000;
        setTimeout(() => {
            const emojiFloat: any = this.emojiFloating(dateTime, eleName);
            window.requestAnimationFrame(emojiFloat.bind(this));
        }, 500);
    }

    private emojiFloating(datetime: number, eleName: string) {
        const parent = document.getElementById('emojies-animation'),
            elements = document.getElementsByClassName(eleName),
            vertSpeed = 4,
            horiSpeed = 4;
        const height = parent?.offsetHeight as number,
            width = parent?.offsetWidth as number;
        const items: any = [];
        for (let i = 0; i < elements.length; i++) {
            const element: any = elements[i],
                elementWidth = element.offsetWidth,
                elementHeight = element.offsetHeight;
            element.style.position = 'absolute';
            const item = {
                element: element,
                elementHeight: elementHeight,
                elementWidth: elementWidth,
                ySpeed: -vertSpeed,
                omega: (2 * Math.PI * horiSpeed) / (width * 60),
                random: (Math.random() / 2 + 0.5) * i * 10000,
                x: function (time: any) {
                    return ((Math.sin(this.omega * (time + this.random)) + 1) / 2) * (width - elementWidth);
                },
                y: height + (Math.random() + 1) * i * elementHeight,
            };
            items.push(item);
        }
        let counter = 0;
        if (parent) parent.style.opacity = '1';
        const animationStep = function () {
            const time = +new Date();
            const check = counter % 10 === 0;
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                const transformString = 'translate3d(' + item.x(time) + 'px,' + item.y + 'px,0px)';
                item.element.style.transform = transformString;
                item.element.style.webkitTransform = transformString;
                item.y += item.ySpeed;
                if (check && item.y < -item.elementHeight) {
                    item.y = height;
                }
            }
            counter %= 8;
            counter++;
            if (datetime > time) {
                window.requestAnimationFrame(animationStep.bind(this));
            } else {
                this.show = false;
            }
        };
        return animationStep;
    }

    private storeDataToSession(eleName: string) {
        const currentEpocTime = new Date().getTime() / 1000;
        const storyInfoDataInSessionStorage: StoryInfoDataInSessionStorage = new StoryInfoDataInSessionStorage();
        storyInfoDataInSessionStorage.response = new ResponseDataModel();
        storyInfoDataInSessionStorage.response.sId = this.storyItem.storyId;
        storyInfoDataInSessionStorage.response.subSId = this.storyItem.storyItemId;
        storyInfoDataInSessionStorage.response.uInput = eleName;
        storyInfoDataInSessionStorage.response.tType = this.templateType;
        storyInfoDataInSessionStorage.expiryTime = Math.round(currentEpocTime + this.expirationTime * 60);
        storyInfoDataInSessionStorage.userId = this.user.id ? this.user.id : '';
        storyInfoDataInSessionStorage.storyIndex = 1;
        if (!this.user.isAuthenticated) {
            this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList =
                this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList.filter(
                    (x) => x.storyIndex !== storyInfoDataInSessionStorage.storyIndex,
                );
            this.gamingStoriesService.storingDataInPreLoginSessionStorage(storyInfoDataInSessionStorage);
        } else {
            this.gamingStoriesService.storeFetchAPIDataRes(storyInfoDataInSessionStorage);
        }
    }

    private bindPreLoginSessionData() {
        if (sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage')) {
            this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList.forEach((res) => {
                if (res.response.sId === this.storyItem.storyId && res.response.subSId === this.storyItem.storyItemId && res.userId === '') {
                    res.userId = this.user.id ? this.user.id : '';
                    this.userSessionStorageData = res;
                }
            });
            this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList =
                this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList.filter(
                    (x) => x.storyIndex !== this.userSessionStorageData.storyIndex,
                );
            this.gamingStoriesService.storingDataInPreLoginSessionStorage(this.userSessionStorageData);
            this.preparePreLoginSelectedDataForPostAPI(this.userSessionStorageData);
        }
    }

    private preparePreLoginSelectedDataForPostAPI(storyInfoData: StoryInfoDataInSessionStorage) {
        const storyInfo: StoryInfoData = new StoryInfoData();
        storyInfo.storyId = storyInfoData.response.sId;
        storyInfo.subStoryId = storyInfoData.response.subSId;
        storyInfo.storyType = this.storyItem.storyType;
        storyInfo.templateType = this.templateType;
        storyInfo.userInput = storyInfoData.response.uInput;
        storyInfo.expTime = this.storyItem.expiryTime;
        storyInfo.storyIndex = 1;
        this.gamingStoriesService.storyInfoPostRequestList = this.gamingStoriesService.storyInfoPostRequestList.filter(
            (x) => x.storyIndex !== storyInfo.storyIndex,
        );
        this.gamingStoriesService.storyInfoRequestItemsList(storyInfo);
    }

    private validatePreLoginSessionToStoreInPostLoginSession() {
        // if fetch API response available.
        // we are not storing selected data any where when it matches with fetch api response.
        if (this.fetchingGamingStoriesAPIRes) {
            if (sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage')) {
                const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage') as string);
                this.fetchingGamingStoriesAPIRes.response.forEach((fetchData: any) => {
                    sessionStorageServiceStoryData.forEach((res: any) => {
                        if (
                            res.userId === this.fetchingGamingStoriesAPIRes.uId &&
                            res.response.sId === fetchData.sId &&
                            res.response.subSId == fetchData.subSId &&
                            fetchData.uInput !== undefined
                        ) {
                            sessionStorage.removeItem('GS:StoryItemsInPreLoginSessionStorage');
                        } else {
                            this.fetchUserPreparedRequest.reqStoryIds.forEach((fetchReq) => {
                                if (res.response.sId === fetchReq.storyId && res.response.subSId === fetchReq.subStoryId) {
                                    this.gamingStoriesService.storeFetchAPIDataRes(res);
                                    sessionStorage.removeItem('GS:StoryItemsInPreLoginSessionStorage');
                                }
                            });
                        }
                    });
                });
            }
        }
        // if fetch API response not available or failed scenario.
        // storing selected data in Service level and session level in post-login key.
        else {
            if (sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage')) {
                const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage') as string);
                sessionStorageServiceStoryData.forEach((res: any) => {
                    this.fetchUserPreparedRequest.reqStoryIds.forEach((fetchReq) => {
                        if (res.response.sId === fetchReq.storyId && res.response.subSId === fetchReq.subStoryId) {
                            this.gamingStoriesService.storeFetchAPIDataRes(res);
                            sessionStorage.removeItem('GS:StoryItemsInPreLoginSessionStorage');
                        }
                    });
                });
            }
        }
    }

    private validationCheck(obj?: any) {
        if (this.user.isAuthenticated) {
            // fetch call response
            if (this.fetchingGamingStoriesAPIRes) {
                this.fetchingGamingStoriesAPIRes.response.forEach((afetchres) => {
                    this.uInput = afetchres.uInput;
                    if (
                        this.fetchingGamingStoriesAPIRes.uId === this.user.id &&
                        afetchres.sId === this.storyItem.storyId &&
                        afetchres.subSId === this.storyItem.storyItemId &&
                        this.uInput !== undefined &&
                        !this.isInitialSelect
                    ) {
                        this.isFetchValueAvailable = true;
                        this.bindQuizData(this.uInput, obj);
                    }
                });
            }
            // service level data check bind
            if (this.gamingStoriesService.storeFetchAPIDataResList.length > 0 && !this.isFetchValueAvailable) {
                this.gamingStoriesService.storeFetchAPIDataResList.forEach((aserviceres) => {
                    if (
                        aserviceres.userId === this.user.id &&
                        aserviceres.response.sId === this.storyItem.storyId &&
                        aserviceres.response.subSId === this.storyItem.storyItemId &&
                        aserviceres.response.uInput !== undefined &&
                        !this.isInitialSelect
                    ) {
                        this.isServiceValueAvailable = true;
                        this.bindQuizData(aserviceres.response.uInput, obj);
                    }
                });
            }
            // session storage check bind
            if (sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage') && !this.isServiceValueAvailable && !this.isFetchValueAvailable) {
                const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage') as string);
                this.gamingStoriesService.storeFetchAPIDataResList = [];
                sessionStorageServiceStoryData.forEach((asessionres: any) => {
                    this.gamingStoriesService.storeFetchAPIDataRes(asessionres);
                    if (
                        asessionres.userId === this.user.id &&
                        asessionres.response.sId === this.storyItem.storyId &&
                        asessionres.response.subSId === this.storyItem.storyItemId &&
                        asessionres.response.uInput !== undefined &&
                        !this.isInitialSelect
                    ) {
                        this.bindQuizData(asessionres.response.uInput, obj);
                    }
                });
            }
        }
        this.gamingStoriesService.pauseStory(false);
    }

    private bindQuizData(uInput: any, obj?: any) {
        this.mandatorySelection(uInput, obj);
        this.isInitialSelect = true;
        this.gamingStoriesService.trackingGamingStoriesPhase2Event(
            'teaser - embedded video',
            'stories',
            'click',
            'not applicable',
            this.storyItem.title,
            uInput,
            this.storyItem.actionTypePosition,
            'not applicable',
        );
    }

    private mandatorySelection(eleName: string, obj?: string) {
        this.selectedEmojieName = eleName;
        this.disableEmojis = true;
        if (obj === 'loginEvent' && !this.isFetchValueAvailable) {
            this.animationFlow(this.selectedEmojieName);
            setTimeout(() => {
                this.showMessageessageSent = true;
                setTimeout(() => {
                    this.showMessageessageSent = false;
                }, 500);
            }, 7200);
        }
    }
}
