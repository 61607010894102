<div class="casino-lobby-container-main">
    <div class="casino-race-info-container">
        <div class="race-info-title-container">
            <span *ngIf="enableResultsHistoryPage && fromResult" class="theme-left race-info-back" (click)="close()"></span>
            <span mat-dialog-title class="race-info-title" [ngClass]="{ 'race-info-title-left': enableResultsHistoryPage && fromResult }">{{
                title
            }}</span>
            <span class="theme-ex race-info-close" *ngIf="!fromResult" (click)="close()"></span>
        </div>
        <ng-container *ngIf="enableResultsHistoryPage && fromResult">
            <div class="casino-sr-result-wraper">
                <div class="sr-results-list">
                    <div class="result-list-container">
                        <ul class="list-titles">
                            <li>{{ versionedData.Game }}</li>
                            <li>{{ versionedData.Date }}</li>
                            <li>{{ versionedData.Prize }}</li>
                            <li>{{ versionedData.YourPlace }}</li>
                            <li>{{ versionedData.Points }}</li>
                        </ul>
                        <ul class="list-elements">
                            <li>
                                <cc-slot-races-icon
                                    class="cc-slot-races-icon"
                                    [promoIcon]="raceResult?.content?.promotion?.promoIcon"
                                    [isDraggable]="false"
                                    [gameVariantList]="raceResult?.gameVariantList"
                                    [isClickable]="false"></cc-slot-races-icon>
                            </li>
                            <li>{{ raceResult.dateStr }}</li>
                            <li [ngClass]="{ 'sr-prize-lose-padding': !raceResult?.awardType }">
                                <i class="sr-prize-icon" [ngClass]="sharedData[raceResult?.awardType?.toLowerCase()]"></i>
                                <span class="sr-card-total-prize-value">
                                    {{
                                        displayBonusAsText && raceResult?.awardType?.toLowerCase() === 'bonus'
                                            ? versionedData?.Bonus
                                            : raceResult?.awardValue
                                    }}
                                    <div *ngIf="raceResult?.isDisplayCoin" class="srp-coin-reward">
                                        <span *ngIf="raceResult?.awardValue" class="theme-plus"></span>
                                        <span class="sr-card-total-coin">
                                            <img class="coin-img" [src]="coinImg" alt="Coin Image" />
                                            <span>{{ raceResult?.coinValue }}</span>
                                        </span>
                                    </div>
                                </span>
                            </li>
                            <li>
                                <div class="srp-img" [innerHtml]="raceResult?.prizeIcon | trustAsHtml"></div>
                                {{ raceResult.rank }}
                            </li>
                            <li>{{ raceResult.points }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <cc-slot-races-leaderboard [results]="raceResult" [coinImg]="coinImg"></cc-slot-races-leaderboard>
            <div class="sr-back-to-results">
                <div class="show-result-btn btn btn-primary" (click)="close()">{{ versionedData?.BackToResults }}</div>
            </div>
        </ng-container>
        <ng-container *ngIf="!fromResult">
            <div class="casino-race-info-card-container">
                <cc-slot-race-card
                    [race]="race"
                    [category]="category"
                    [showRaceInfo]="false"
                    [lobbyType]="lobbyType"
                    [enableOptin]="raceinfoopt"
                    [showCardMenu]="false"
                    (closeRaceInfo)="close($event)"
                    (openPickGameOverlay)="openPickGameOverlay()"
                    (openNotificationOverlay)="openNotificationDialog()"
                    (gameLaunched)="close()"
                    [position]="0"
                    [coinImg]="coinImg">
                </cc-slot-race-card>
            </div>
            <div *ngIf="race.isLive; else upcomingRaceInfo">
                <cc-slot-races-leaderboard
                    *ngIf="race.rankDetails && race.rankDetails.length > 0"
                    [race]="race"
                    [lobbyType]="lobbyType"
                    [coinImg]="coinImg"></cc-slot-races-leaderboard>
                <cc-slots-races-rules-and-tnc [coinImg]="coinImg" [race]="race" [showTitleHeader]="false"></cc-slots-races-rules-and-tnc>
            </div>
            <ng-template #upcomingRaceInfo>
                <div class="casino-race-info-tnc-container">
                    <cc-slots-races-rules-and-tnc [race]="race" [coinImg]="coinImg" [showTitleHeader]="false"></cc-slots-races-rules-and-tnc>
                </div>
            </ng-template>
        </ng-container>
    </div>
</div>
