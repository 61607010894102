import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'bg-overlay',
    templateUrl: 'overlay.component.html',
})
export class OverlayComponent implements OnInit {
    message: any;
    heading: any;

    constructor(
        public dialogRef: MatDialogRef<OverlayComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit() {
        this.heading = this.data.heading;
        this.message = this.data.message;
    }

    close() {
        this.dialogRef.close();
    }
}
