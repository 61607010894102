<div class="casino-lobby-container-main">
    <div *ngIf="showTitleHeader">
        <div class="rules-header">
            <div class="header-text">
                {{ sitecoreContent.textTranslations.versionedList.TermsAndConditionsTitle }}
            </div>
            <div mat-dialog-actions>
                <span mat-button (click)="closeButton()" class="ui-icon ui-icon-size-lg ui-close theme-ex ng-star-inserted"></span>
            </div>
        </div>
        <div class="rules-header-image">
            <div class="rules-header-image-section" [ngStyle]="{ 'background-image': 'url(' + sitecoreContent.rulesBanner.image.src + ')' }">
                <div class="rules-image-title">
                    {{ sitecoreContent.rulesBanner.title }}
                </div>
            </div>
        </div>
    </div>
    <div class="casino-rules-tnc-popup">
        <div class="casino-rules-panel" id="casino-prizes-container">
            <cc-slot-races-prizes [showHeader]="true" [isCollapsible]="true" [isExpanded]="true" [race]="race" [coinImg]="coinImg">
            </cc-slot-races-prizes>
        </div>

        <div class="casino-rules-panel">
            <cc-slot-races-rules [showHeader]="true" [isCollapsible]="true" [isExpanded]="true" [race]="race"> </cc-slot-races-rules>
        </div>
        <div class="points-panel" [ngClass]="{ 'disable-tnc-points': !disableSlotRacesPoints }">
            <cc-slot-races-points [showHeader]="true" [isCollapsible]="true" [isExpanded]="true" [race]="race"> </cc-slot-races-points>
        </div>
        <div class="tips-panel">
            <cc-slot-races-tips [showHeader]="true" [isCollapsible]="true" [isExpanded]="true"> </cc-slot-races-tips>
        </div>
        <div class="tnc-panel">
            <cc-slot-races-tnc [showHeader]="true" [isCollapsible]="false" [isExpanded]="true" [race]="race"> </cc-slot-races-tnc>
        </div>
    </div>
</div>
