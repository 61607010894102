import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'bgBingoModularConfigurationFeatures', product: ClientConfigProductName.BINGO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: bingoModularConfigurationFeaturesClientConfigFactory,
})
export class BingoModularConfigurationFeaturesClientConfig {
    enableBingoTournamentsForOtherProducts: boolean;
}
export function bingoModularConfigurationFeaturesClientConfigFactory(service: LazyClientConfigService) {
    return service.get(BingoModularConfigurationFeaturesClientConfig);
}
