import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import {
    BingoConfigProviderService,
    BingoHelperService,
    BingoManager,
    BingoTournamentsService,
    BingoTournamentsTrackingService,
    FILTER_TYPE_ALL,
    FILTER_TYPE_CASH_ENTRY,
    FILTER_TYPE_COINS_ENTRY,
    FILTER_TYPE_COIN_REWARD,
    FILTER_TYPE_FREE_ENTRY,
    Race,
    SitecoreContent,
    SlotRacesLive,
    SlotRacesUpcoming,
} from '@frontend/bingo/core';
import { DeviceService, Page, RtmsService, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { Subscription, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BingoNotificationOverlayComponent } from './notification-overlay/bingo-notification-overlay.component';
import { BingoPickGameOverlayComponent } from './pick-game-overlay/bingo-pick-game-overlay.component';
import { BingoSlotRacesInfoComponent } from './race-info/bingo-race-info.component';
import { BingoSlotRacesResultsComponent } from './results/bingo-results.component';
import { BingoSlotRacesRulesAndTnCComponent } from './rules-points-tips-tnc/bingo-rules-and-tnc.component';

@Component({
    selector: 'bgl-slot-races',
    templateUrl: 'bingo-slot-races.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class BingoSlotRacesComponent implements OnInit, OnDestroy {
    categoryName: string = 'Bingo Tournaments';
    upcomingRaces: SlotRacesUpcoming;
    liveRaces: SlotRacesLive;
    race: Race;
    isTouch: boolean;
    entryDetails: any;
    isLoggedIn: boolean;
    content: SitecoreContent = {
        bingoRaceRulesConfigs: [],
        rules: [],
        rulesBanner: {},
        placeholderCardImage: {},
        coinEconomy: {},
        textTranslations: { sharedList: {}, versionedList: {} },
        tips: [],
        errorMessages: { sharedList: {}, versionedList: {} },
        entryDetails: { sharedList: {}, versionedList: {} },
    };
    @Input() routeCurrentProduct: string;

    contentSubscription: Subscription;
    upcomingRacesSubscription: Subscription;
    liveRacesSubscription: Subscription;
    rtmsSubscription: Subscription;
    auth: boolean;
    popupPurchage: any;
    selectedOption: string;
    toggleOpenUpcomingTodayCard: boolean = false;
    toggleOpenUpcomingTomorrowCard: boolean = false;
    isMobileWidth: boolean;
    isSubCategory: boolean;
    coinsForOptin: number;
    enableBetterVisualization: boolean;
    enableUpcomingOptinForPlaytech: boolean;
    isFreePlaytechinLive: boolean = false;
    isOptinWithCoins: boolean = false;
    enableLiveRacesFilter: boolean;
    selectedOptionValue: any;
    tourment: string;
    messages: { [item: string]: string } = {};
    entryDetailsKeys: string[];
    raceItem: any;
    liveRacesList: any;
    upcomingRacesList: any = {};
    coinImg: string;
    enableCoinEconomy: boolean;
    firstTimeTracking: boolean = true;
    constructor(
        private dialog: MatDialog,
        private userService: UserService,
        private bingoManager: BingoManager,
        private slotRacesService: BingoTournamentsService,
        private configProviderService: BingoConfigProviderService,
        private slotRacesTracking: BingoTournamentsTrackingService,
        private rtmsService: RtmsService,
        private router: Router,
        private page: Page,
        private route: ActivatedRoute,
        private deviceService: DeviceService,
        private bingoHelperService: BingoHelperService,
    ) {
        this.isTouch = this.deviceService.isTouch;
    }
    // @ to detect broswer back button
    @HostListener('window:popstate', ['$event'])
    onPopState() {
        setTimeout(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const filterParam = urlParams.get('filter');
            if (filterParam) {
                this.displayFilterEntryMessage();
                this.onFilterSelect(this.selectedOption);
            }
        }, 100);
    }

    ngOnInit() {
        this.slotRacesService.setSlotRaces();
        this.isMobileWidth = window.innerWidth < 768 || document.body.offsetWidth < 768;
        const slotRaceConfigs = this.configProviderService.provideBingoTournamentsClientConfig();
        this.enableBetterVisualization = this.bingoHelperService.getConfigBasedOnInvokerProduct(slotRaceConfigs.enableBetterVisualization);
        this.enableUpcomingOptinForPlaytech = this.bingoHelperService.getConfigBasedOnInvokerProduct(slotRaceConfigs.enableUpcomingOptinForPlaytech);
        this.slotRacesService.getRaces();
        this.enableCoinEconomy = this.bingoHelperService.getConfigBasedOnInvokerProduct(slotRaceConfigs.enableCoinEconomy);
        if (navigator.userAgent.indexOf('Firefox') != -1) {
            document.getElementsByTagName('html')[0].classList.add('firefox');
        }
        this.subscribeToData();
        this.auth = this.userService.isAuthenticated;
        this.bingoManager.reSizeObservable.subscribe((width: any) => {
            if (width) {
                this.isMobileWidth = window.innerWidth < 768 || document.body.offsetWidth < 768;
                const dialogConfig = new MatDialogConfig();
                this.dialogProperties(dialogConfig);
            }
        });
        this.userService.events.pipe(filter((e: any) => e instanceof UserLoginEvent)).subscribe(() => {
            this.auth = true;
        });

        this.displayFilterEntryMessage();
    }

    onFilterSelect(selectedOption: string) {
        this.firstTimeTracking = true;
        this.selectedOption = selectedOption || 'ALL';
        const seletedEntryOption = this.entryDetails[selectedOption];
        if (seletedEntryOption) {
            const queryParams = { filter: seletedEntryOption.replaceAll(' ', '_') };
            this.subscribeToData();
            if (this.selectedOption.toUpperCase() === FILTER_TYPE_ALL) {
                this.router.navigateByUrl('/' + this.page.lang + '/bingo/bingotournaments'); //TODO : need to move to sitecore or dynacon
                this.tourment = '';
            } else {
                this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
                this.tourment = this.messages.NoTourmentsAreAvailableWith
                    ? this.messages.NoTourmentsAreAvailableWith + ' ' + this.selectedOption
                    : '';
            }
        }
    }

    updateslotRaceUpcomingData(data: SlotRacesUpcoming) {
        let firstPlayTechFound: boolean = false;
        if (data && data.upcomingToday && data.upcomingToday.length > 0) {
            for (let i = 0; i < data.upcomingToday.length; i++) {
                if (data?.upcomingToday[i]?.subType?.toLowerCase() === 'free_play_tech') {
                    data.upcomingToday[i].firstPlayTech = true;
                    firstPlayTechFound = true;
                    break;
                }
            }
        }
        if (!firstPlayTechFound && data && data.upcomingTomorrow && data.upcomingTomorrow.length > 0) {
            for (let i = 0; i < data.upcomingTomorrow.length; i++) {
                if (data?.upcomingTomorrow[i]?.subType?.toLowerCase() === 'free_play_tech') {
                    data.upcomingTomorrow[i].firstPlayTech = true;
                    break;
                }
            }
        }
    }

    subscribeToData() {
        this.liveRacesSubscription = this.slotRacesService.liveRacesData.subscribe((data: SlotRacesLive) => {
            this.liveRaces = data;
            this.liveRacesList = this.liveRaces.liveNow;
            if (this.liveRaces && this.enableCoinEconomy) {
                if (this.selectedOption?.toUpperCase() === FILTER_TYPE_ALL) {
                    this.liveRacesList = this.liveRaces.liveNow;
                } else if (this.selectedOption === FILTER_TYPE_CASH_ENTRY) {
                    this.liveRacesList = this.liveRaces?.liveNow.filter((raceItem: any) => raceItem.subType == 'CASINO_LEADERBOARD');
                } else if (this.selectedOption === FILTER_TYPE_COIN_REWARD) {
                    this.liveRacesList = this.liveRaces?.liveNow.filter(
                        (raceItem: any) =>
                            raceItem?.additionalParameters?.awardType === 'COINS' ||
                            (raceItem?.additionalParameters?.awardType !== 'COINS' && raceItem?.additionalParameters?.isCoinAward === 'YES'),
                    );
                } else {
                    const filteredLiveRaces = this.liveRaces?.liveNow.filter(
                        (raceItem: any) => raceItem.subType === 'FREE_TO_PLAY' || raceItem.subType?.toLowerCase() === 'free_play_tech',
                    );
                    if (this.selectedOption === FILTER_TYPE_COINS_ENTRY) {
                        this.liveRacesList = filteredLiveRaces?.filter((raceItem: any) => raceItem?.isOptinWithCoins);
                    } else if (this.selectedOption === FILTER_TYPE_FREE_ENTRY) {
                        this.liveRacesList = filteredLiveRaces?.filter((raceItem: any) => !raceItem?.isOptinWithCoins);
                    } else {
                        this.liveRacesList = [];
                    }
                }
                this.enableLiveRacesFilter = true;
            }
        });
        this.upcomingRacesSubscription = this.slotRacesService.upcomingRacesData.subscribe((data: SlotRacesUpcoming) => {
            this.upcomingRaces = data;
            this.upcomingRacesList = { ...this.upcomingRaces };
            if (this.upcomingRaces && this.enableCoinEconomy) {
                if (this.selectedOption?.toUpperCase() === FILTER_TYPE_ALL) {
                    this.upcomingRacesList = { ...this.upcomingRaces };
                } else if (this.selectedOption == FILTER_TYPE_CASH_ENTRY) {
                    this.upcomingRacesList.upcomingToday = this.upcomingRaces?.upcomingToday.filter(
                        (raceItem: any) => raceItem.subType == 'CASINO_LEADERBOARD',
                    );
                    this.upcomingRacesList.upcomingTomorrow = this.upcomingRaces?.upcomingTomorrow.filter(
                        (raceItem: any) => raceItem.subType == 'CASINO_LEADERBOARD',
                    );
                } else if (this.selectedOption == FILTER_TYPE_COIN_REWARD) {
                    this.upcomingRacesList.upcomingToday = this.upcomingRaces?.upcomingToday.filter(
                        (raceItem: any) =>
                            raceItem?.additionalParameters?.awardType === 'COINS' ||
                            (raceItem?.additionalParameters?.awardType !== 'COINS' && raceItem?.additionalParameters?.isCoinAward === 'YES'),
                    );
                    this.upcomingRacesList.upcomingTomorrow = this.upcomingRaces?.upcomingTomorrow.filter(
                        (raceItem: any) =>
                            raceItem?.additionalParameters?.awardType === 'COINS' ||
                            (raceItem?.additionalParameters?.awardType !== 'COINS' && raceItem?.additionalParameters?.isCoinAward === 'YES'),
                    );
                } else {
                    const filteredUpcomingToday = this.upcomingRaces?.upcomingToday.filter(
                        (raceItem: any) => raceItem.subType === 'FREE_TO_PLAY' || raceItem.subType?.toLowerCase() === 'free_play_tech',
                    );
                    const filteredUpcomingTomorrow = this.upcomingRaces?.upcomingTomorrow.filter(
                        (raceItem: any) => raceItem.subType === 'FREE_TO_PLAY' || raceItem.subType?.toLowerCase() === 'free_play_tech',
                    );
                    if (this.selectedOption === FILTER_TYPE_COINS_ENTRY) {
                        this.upcomingRacesList.upcomingToday = filteredUpcomingToday?.filter((raceItem: any) => raceItem?.isOptinWithCoins);
                        this.upcomingRacesList.upcomingTomorrow = filteredUpcomingTomorrow?.filter((raceItem: any) => raceItem?.isOptinWithCoins);
                    } else if (this.selectedOption === FILTER_TYPE_FREE_ENTRY) {
                        this.upcomingRacesList.upcomingToday = filteredUpcomingToday?.filter((raceItem: any) => !raceItem?.isOptinWithCoins);
                        this.upcomingRacesList.upcomingTomorrow = filteredUpcomingTomorrow?.filter((raceItem: any) => !raceItem?.isOptinWithCoins);
                    } else {
                        this.upcomingRacesList.upcomingToday = [];
                        this.upcomingRacesList.upcomingTomorrow = [];
                    }
                }
            }
            if (this.enableUpcomingOptinForPlaytech) this.updateslotRaceUpcomingData(data);
        });
        combineLatest(this.slotRacesService.liveRacesData, this.slotRacesService.upcomingRacesData).subscribe((data: any) => {
            if (data[0].liveNow.length === 0) {
                this.toggleFirstCard(true);
            } else {
                this.toggleFirstCard(false);
            }
        });

        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.content = content;
            this.entryDetails = content?.entryDetails ? content.entryDetails.versionedList : {};
            this.entryDetailsKeys = this.entryDetails ? Object.keys(this.entryDetails) : [];
            this.messages = content && content.textTranslations ? content.textTranslations.versionedList : {};
            this.coinImg = this.content?.coinEconomy?.image?.src;
            this.displayTournamentErrorMessage();
        });
        this.rtmsSubscription = this.rtmsService.messages
            .pipe(
                filter(
                    (m: any) => m.type === 'FREE_TECH_OPTIN_LIMIT_EXCEED' || m.type === 'SLOT_RACE_EDIT' || m.type === 'PLAYER_NOTIFICATION_STARTED',
                ),
            )
            .subscribe((message) => {
                if ((this.upcomingRaces?.upcomingToday?.length > 0 || this.upcomingRaces?.upcomingTomorrow?.length > 0) && message.payload) {
                    this.upcomingRaces.upcomingToday.forEach((race: Race) => {
                        if (race.promoId == message.payload.promoId && race.slotUniqueId == message.payload.slotId) {
                            if (message.type == 'FREE_TECH_OPTIN_LIMIT_EXCEED') {
                                race.targetedPlayerCount = race.optinLimit;
                            } else if (message.type === 'PLAYER_NOTIFICATION_STARTED') {
                                race.isNotify = true;
                            }
                            return;
                        }
                    });
                    this.upcomingRaces.upcomingTomorrow.forEach((race: Race) => {
                        if (race.promoId == message.payload.promoId && race.slotUniqueId == message.payload.slotId) {
                            if (message.type == 'FREE_TECH_OPTIN_LIMIT_EXCEED') {
                                race.targetedPlayerCount = race.optinLimit;
                            } else if (message.type === 'PLAYER_NOTIFICATION_STARTED') {
                                race.isNotify = true;
                            }
                            return;
                        }
                    });
                }

                if (message.type == 'SLOT_RACE_EDIT') {
                    if (this.userService.isAuthenticated) this.slotRacesService.getUpcomingRaces();
                }
            });
    }

    showResults(): void {
        const dialogConfig = new MatDialogConfig();
        this.dialogProperties(dialogConfig);
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title: 'your results',
            coinImg: this.coinImg,
        };
        dialogConfig.panelClass = 'bt-results-dialog';
        const activeCategory = this.categoryName;
        this.slotRacesTracking.track(
            'See your Results - CTA Clicked',
            'My Results Ovelay',
            activeCategory,
            'User Clicked on see reults CTA to check the results',
        );
        const dialogRef = this.dialog.open(BingoSlotRacesResultsComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(() => {});
    }

    openDialog(race: Race, dialog: 'race-info' | 'pick-game' | 'notification') {
        const component =
            dialog === 'race-info'
                ? BingoSlotRacesInfoComponent
                : dialog === 'pick-game'
                  ? BingoPickGameOverlayComponent
                  : BingoNotificationOverlayComponent;
        this.openDialogOverlay(race, component);
    }

    openDialogOverlay(race: Race, component: any) {
        this.dialog.closeAll();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        this.dialogProperties(dialogConfig);
        dialogConfig.data = {
            race: race,
            enableOptin: this.enableUpcomingOptinForPlaytech && race.firstPlayTech && !this.isFreePlaytechinLive,
            coinImg: this.coinImg,
        };
        if (component == BingoNotificationOverlayComponent) {
            dialogConfig.panelClass = 'bingo-bt-snotification-mat-dialog';
        } else {
            dialogConfig.panelClass = 'bingo-bt-mat-dialog';
        }
        const dialogRef = this.dialog.open(component, dialogConfig);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                if (result.openRaceInfo) {
                    this.openDialog(race, 'race-info');
                } else if (result.openPickGameOverlay) {
                    this.openDialog(race, 'pick-game');
                } else if (result.openNotificationOverlay) {
                    this.openDialog(race, 'notification');
                } else if (result.errorCode) {
                    this.slotRacesService.addErrorMessage(result.errorCode);
                }
            }
        });
    }

    dialogProperties(dialogConfig: MatDialogConfig) {
        if (window.matchMedia('(orientation: landscape)').matches && window.innerWidth < 900) {
            dialogConfig.width = '100%';
            dialogConfig.height = '100%';
            dialogConfig.maxWidth = '100%';
            dialogConfig.maxHeight = '100%';
        } else if (window.innerWidth >= 768) {
            dialogConfig.width = '668px';
        } else {
            dialogConfig.width = '100%';
            dialogConfig.height = '100%';
            dialogConfig.maxWidth = '100%';
            dialogConfig.maxHeight = '100%';
        }
    }

    ngOnDestroy() {
        this.upcomingRacesSubscription.unsubscribe();
        this.liveRacesSubscription.unsubscribe();
        this.contentSubscription.unsubscribe();
        if (this.rtmsSubscription) {
            this.rtmsSubscription.unsubscribe();
        }
    }

    openFullRulesDialog(): void {
        this.dialog.open(BingoSlotRacesRulesAndTnCComponent, { width: '668px', panelClass: 'bingo-bt-mat-dialog' });
    }

    raceAdded(index: number, item: Race): string {
        return item.promoId + '_' + item.slotUniqueId;
    }

    toggleFirstCard(toggle: boolean) {
        if (toggle) {
            if (this.upcomingRaces.upcomingToday && this.upcomingRaces.upcomingToday.length != 0) {
                this.toggleOpenUpcomingTodayCard = true;
            } else if (this.upcomingRaces.upcomingTomorrow && this.upcomingRaces.upcomingTomorrow.length != 0) {
                this.toggleOpenUpcomingTomorrowCard = true;
            }
        } else {
            this.toggleOpenUpcomingTodayCard = false;
            this.toggleOpenUpcomingTomorrowCard = false;
        }
    }
    PlaytechinLive(event: any) {
        this.isFreePlaytechinLive = event;
    }

    displayTournamentErrorMessage(apiCall: boolean = false) {
        if (this.selectedOption?.toUpperCase() === FILTER_TYPE_ALL) {
            this.tourment = '';
            if (apiCall) {
                this.subscribeToData();
            }
        } else {
            const selectedOptionMessages = this.entryDetails[this.selectedOption];
            if (!selectedOptionMessages) {
                this.tourment = this.messages?.DefaultMessage;
            } else {
                this.tourment = this.messages?.NoTourmentsAreAvailableWith
                    ? this.messages?.NoTourmentsAreAvailableWith + ' ' + this.selectedOption
                    : '';
            }
        }
    }

    displayFilterEntryMessage() {
        if (this.enableCoinEconomy) {
            this.route.queryParams.subscribe((params: any) => {
                const storedFilter = params.filter;
                if (this.isEmptyObject(this.entryDetails)) {
                    this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
                        this.entryDetails = content?.entryDetails ? content.entryDetails.versionedList : {};
                        this.assignSelectedOptionBasedOnFilter(storedFilter);
                    });
                } else {
                    this.assignSelectedOptionBasedOnFilter(storedFilter);
                }
            });
        }
    }

    isEmptyObject(obj: any) {
        return Object.keys(obj).length === 0;
    }

    assignSelectedOptionBasedOnFilter(storedFilter: any) {
        const storedFilterKey = storedFilter
            ? Object.keys(this.entryDetails).find((key) => this.entryDetails[key] === storedFilter.replaceAll('_', ' '))
            : FILTER_TYPE_ALL;
        this.selectedOption = storedFilterKey || FILTER_TYPE_ALL;
        this.displayTournamentErrorMessage(true);
        this.filterTrackingEvent(true);
    }
    filterTrackingEvent(filterTracking: boolean = false) {
        if (this.selectedOption && filterTracking && this.firstTimeTracking) {
            this.firstTimeTracking = false;
        }
    }
}
