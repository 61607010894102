import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { TrackingModel } from '../../../shared/components/Tracking/TrackingModel';
import { TrackingModelService } from '../../../shared/components/Tracking/tracking-modal-service';
import { PriceInfoModalPopupComponent } from '../pricemodalinfo-popup/price-modal-info-popup.component';

@Component({
    selector: 'bg-pricemodal-popup',
    templateUrl: './price-modal-popup.component.html',
    providers: [TrackingModelService],
})
export class PriceModalPopupComponent implements OnInit {
    globalClientConfig: any;
    selectPriceInfo: boolean;
    priceAndPrizeText: string;
    multiStakeText: string;
    currentIndex: any;
    stakeValue: number = 0;
    trackingModel: TrackingModel;
    constructor(
        public dialogRef: MatDialogRef<PriceModalPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog,
        private tracking: TrackingModelService,
    ) {
        this.trackingModel = new TrackingModel();
    }

    onPriceSelectedEmit = new EventEmitter();
    toggleSelectedPriceInfoEmit = new EventEmitter();
    onClosePrice = new EventEmitter();

    ngOnInit(): void {
        this.priceAndPrizeText = this.data?.isMultiPriceComponent
            ? this.data?.multistakeContent?.PriceandprizeText
            : this.data?.currentGame?.isMultistake
              ? this.data?.multistakeContent?.MultiStakePopupText
              : this.data?.multistakeContent?.SlingoMultiStakePopupText;
        this.multiStakeText = this.data?.isMultiPriceComponent
            ? this.data?.multistakeContent?.MultiStakeText
            : this.data?.multistakeContent?.MultiStake;
        this.currentIndex = this.data?.isMultiPriceComponent ? this.data?.currentGame?.selected_index : this.data?.currentGame?.selected_price;
        if (localStorage.getItem('config')) {
            const clientConfig = JSON.parse(localStorage.getItem('config') || '');
            if (!this.globalClientConfig) this.globalClientConfig = clientConfig.global;
        }
    }

    toggleModelSelectPriceInfo() {
        const requiredModalData = {
            currentGame: this.data?.currentGame,
            multistakeContent: this.data?.multistakeContent,
            isMultiPriceComponent: this.data?.isMultiPriceComponent,
        };
        this.dialog.open(PriceInfoModalPopupComponent, {
            data: requiredModalData,
            panelClass: 'priceinfo-modal-popup',
        });
    }

    onPriceSelected(obj: any) {
        this.onPriceSelectedEmit.emit(obj);
        this.stakeValue = obj?.price;
    }

    closePrice() {
        this.multistakeTracking();
        this.dialogRef.close();
        this.onClosePrice.emit();
    }

    multistakeTracking() {
        let updt_value: string = 'update' + '-' + this.stakeValue?.toString();
        this.trackingModel.eventName = 'Event.Tracking';
        this.trackingModel.CategoryEvent = this.data?.iframeFlag ? 'bingo room' : 'bingo';
        this.trackingModel.LabelEvent = this.data?.iframeFlag ? 'room interaction' : 'pre-buy';
        this.trackingModel.ActionEvent = 'click';
        this.trackingModel.PositionEvent = 'multi stake overlay';
        this.trackingModel.LocationEvent = this.data?.roomName;
        this.trackingModel.EventDetails = updt_value;
        this.tracking.submitTracking(this.trackingModel);
    }
}
