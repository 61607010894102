import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: cacheSettingsClientConfigFactory,
})
@LazyClientConfig({ key: 'bgCacheSettingsConfig', product: ClientConfigProductName.BINGO })
export class CacheSettingsClientConfig {
    preBuy: any;
}
export function cacheSettingsClientConfigFactory(service: LazyClientConfigService) {
    return service.get(CacheSettingsClientConfig);
}
