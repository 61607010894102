<div *ngIf="message" class="bg-confirm-message" tabindex="-1" role="dialog" style="display: block !important; z-index: 9999">
    <div *ngIf="message?.type == 'confirm'" class="message-body">
        <div class="row">
            <div class="col-md-12 message-header">
                <div class="text-center">{{ message.header }}</div>
                <div *ngIf="message.closeButton" (click)="message.cancelFn(this)" class="mes-close"><i class="theme-ex"></i></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 message-text">
                <p class="text-center" [innerHTML]="message.text"></p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 footer">
                <p class="text-center">
                    <!-- <a (click)="message.cancelFn(this)">
                        <button class="cancel btn btn-outline-secondary btn-block">{{ message.closeButton }}</button>
                    </a>
                    <a (click)="message.confirmFn(this)" *ngIf="message.confirmButtonText != ''">
                        <button class="purchase btn btn-primary btn-block">{{ message.confirmButtonText }}</button>
                        </a> -->
                    <bg-custombutton
                        (click)="message.cancelFn(this)"
                        [variant]="'outline'"
                        [size]="'medium'"
                        [decoratorClass]="'confirm-btnn'"
                        [kind]="'tertiary'"
                        [customClass]="'cancel btn-outline-secondary btn-block'"
                        [button_text]="message.closeButton"
                        >{{ message.closeButton }}
                    </bg-custombutton>
                    <bg-custombutton
                        (click)="message.confirmFn(this)"
                        *ngIf="message.confirmButtonText != ''"
                        [variant]="'filled'"
                        [size]="'medium'"
                        [decoratorClass]="'confirm-btnn'"
                        [kind]="'primary'"
                        [customClass]="'purchase btn-primary btn-block'"
                        [button_text]="message.confirmButtonText"
                        >{{ message.confirmButtonText }}
                    </bg-custombutton>
                </p>
            </div>
        </div>
        <div class="row" *ngIf="message.note != ''">
            <div class="col-md-12 message-text message-note">
                <p class="text-center">{{ message.note }}</p>
            </div>
        </div>
    </div>
</div>
