import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import {
    BingoConfigProviderService,
    BingoHelperService,
    BingoManager,
    BingoTournamentsService,
    BingoTournamentsTrackingService,
    Race,
    SitecoreContent,
} from '@frontend/bingo/core';

@Component({
    selector: 'bgl-slots-races-rules-and-tnc',
    templateUrl: './bingo-rules-and-tnc.component.html',
})
export class BingoSlotRacesRulesAndTnCComponent implements OnInit, OnDestroy {
    @Input() race: Race;
    @Input() coinImg: string;
    @Input() showTitleHeader: boolean = true;
    sitecoreContent: SitecoreContent;
    contentSubscription: any;
    disableBingoTournamentsPoints: boolean;
    slotRacesConfig: any;

    constructor(
        public dialogRef: MatDialogRef<BingoSlotRacesRulesAndTnCComponent>,
        private configProviderService: BingoConfigProviderService,
        private slotRacesService: BingoTournamentsService,
        private slotRacesTracking: BingoTournamentsTrackingService,
        private bingoManager: BingoManager,
        private bingoHelperService: BingoHelperService,
    ) {}

    ngOnInit() {
        this.slotRacesConfig = this.configProviderService.provideBingoTournamentsClientConfig();
        this.disableBingoTournamentsPoints = this.bingoHelperService.getConfigBasedOnInvokerProduct(
            this.slotRacesConfig.disableBingoTournamentsPoints,
        );
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.sitecoreContent = content;
        });
    }
    ngOnDestroy() {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
    }

    closeButton() {
        const activeCategory = this.bingoManager.trackingBingoTournaments;
        this.slotRacesTracking.track(
            'Close (x) - CTA Clicked',
            'Rules TnC - Overlay',
            activeCategory,
            'User Clicked on Close(x) CTA to close the overlay',
        );
        this.dialogRef.close();
    }
}
