<div
    class="casino-news-feed-item-wrapper"
    [ngStyle]="{ 'border-color': attributes.NewsFeedBoxOuterLineColor, 'background-color': attributes.NewsFeedItemBgcolor }"
    (click)="itemClicked()"
    *ngIf="isTableOnline">
    <div class="casino-news-feed-icon-v2 casino-news-valign">
        <i class="{{ newsFeedItem.iconClass }}" *ngIf="!displayDealerImage" [ngStyle]="{ color: attributes.IconColor }"></i>
        <img [src]="dealerImage" *ngIf="displayDealerImage" />
    </div>
    <div class="casino-news-feed-content-v2 casino-news-valign">
        <div class="news-feed-title-v2" [ngStyle]="{ color: attributes.HeaderTextColor }">{{ gameName }}</div>
        <div class="news-feed-name-v2 livenowdot" [ngStyle]="{ color: attributes.SubHeaderTextColor }" *ngIf="displayLiveStatus">
            <span class="dot"></span>{{ attributes.LiveNowText }}
        </div>
        <div class="news-feed-name-v2" [ngStyle]="{ color: attributes.SubHeaderTextColor }" *ngIf="!displayLiveStatus">
            {{ newsFeedItem.subTitle }}
        </div>
    </div>
    <div class="win-numbers" *ngIf="liveGameInfo.results && liveGameInfo.results.length > 0">
        <ul class="win-numbers-list">
            <li *ngFor="let item of resultsDisplayed" [ngStyle]="{ 'background-color': lastResultNumberColor(item) }">
                {{ item }}
            </li>
        </ul>
    </div>
</div>
<div *ngIf="enablelctimer && !isTableOnline">
    <cc-lc-timer [showTimer]="false" [startTime]="offlineTableTime" (onTimerEnd)="onTimerEnd($event)"></cc-lc-timer>
</div>
