<div class="casino-sr-sub-wrapper" [ngClass]="{ 'new-slotraces-styles': !isSubCategory && enableBetterVisualization }">
    <div>
        <div class="tabs">
            <cc-slot-races-rules [showHeader]="false" [isCollapsible]="false" [isExpanded]="true"></cc-slot-races-rules>
        </div>
        <div class="show-more">
            <a class="showmore-text" (click)="openFullRulesDialog()">{{ content?.textTranslations?.versionedList?.SeeRules }}</a>
        </div>
        <div
            class="coineconmy-filter"
            *ngIf="enableCoinEconomy && entryDetailsKeys?.length > 1"
            [ngClass]="{ 'coineconmy-filter-non-touch': !isTouch }">
            <ul>
                <li *ngFor="let item of entryDetailsKeys; let i = index" [class.active]="selectedOption === item" (click)="onFilterSelect(item)">
                    {{ entryDetails[item] }}
                </li>
            </ul>
        </div>
        <vn-message-panel [scope]="'subslotraces'"></vn-message-panel>
        <div class="casino-race-list-header" *ngIf="liveRacesList?.length > 0">
            <span class="theme-icon theme-slots-race-headline"></span>
            <span class="race-list-title">{{ content?.textTranslations?.versionedList?.LiveNow }}</span>
        </div>
        <cc-slot-races-cards
            *ngIf="liveRacesList?.length > 0"
            [lobbyType]="lobbyType"
            [showLeaderboard]="true"
            [showPlaceholderCard]="false"
            [category]="category"
            (isPlaytechinLive)="PlaytechinLive($event)"
            [liveRacesList]="liveRacesList"
            [enableLiveRacesFilter]="enableLiveRacesFilter"
            [coinImg]="coinImg"
            [selectedOption]="selectedOption"></cc-slot-races-cards>
        <ng-container
            *ngIf="liveRacesList?.length == 0 && upcomingRacesList?.upcomingToday?.length == 0 && upcomingRacesList?.upcomingTomorrow?.length == 0">
            <vn-page-matrix [path]="'MobileCasino-v1.0/CasinoSlotRaces/NoUpcomingRaces/NoMoreRaces'"></vn-page-matrix>
        </ng-container>

        <div [ngClass]="enableUpcomingOptinForPlaytech && !isFreePlaytechinLive ? 'upcoming-section' : 'today-section'">
            <div class="casino-race-list-header" *ngIf="upcomingRacesList?.upcomingToday?.length > 0">
                <div class="theme-icon theme-slots-race-coming-up-today"></div>
                <div class="race-list-title">{{ content?.textTranslations?.versionedList?.UpcomingToday }}</div>
            </div>
            <div
                [ngClass]="{ 'play-tech': race.subType.toLowerCase() === 'free_play_tech' }"
                *ngFor="let race of upcomingRacesList?.upcomingToday; let f = first; let l = last; trackBy: raceAdded">
                <cc-slot-races-item
                    [enableOptin]="enableUpcomingOptinForPlaytech && race.firstPlayTech && !isFreePlaytechinLive"
                    [lobbyType]="lobbyType"
                    [race]="race"
                    [category]="category"
                    [content]="content"
                    [first]="f"
                    [last]="l"
                    [toggleFirstCardOn]="toggleOpenUpcomingTodayCard"
                    (openRaceInfo)="openDialog($event, 'race-info')"
                    (openPickGameOverlay)="openDialog($event, 'pick-game')"
                    (openNotificationOverlay)="openDialog($event, 'notification')"
                    [targetedPlayerCount]="race.targetedPlayerCount"
                    [coinImg]="coinImg"
                    [selectedOption]="selectedOption">
                </cc-slot-races-item>
            </div>
            <div class="casino-race-list-header" *ngIf="upcomingRacesList?.upcomingTomorrow?.length > 0">
                <div class="theme-icon theme-slots-race-coming-tomorrow"></div>
                <div class="race-list-title">{{ content?.textTranslations?.versionedList?.UpcomingTomorrow }}</div>
            </div>
            <div
                [ngClass]="{ 'play-tech': race.subType.toLowerCase() === 'free_play_tech' }"
                *ngFor="let race of upcomingRacesList?.upcomingTomorrow; let f = first; let l = last; trackBy: raceAdded">
                <cc-slot-races-item
                    [enableOptin]="enableUpcomingOptinForPlaytech && race.firstPlayTech && !isFreePlaytechinLive"
                    [lobbyType]="lobbyType"
                    [race]="race"
                    [category]="category"
                    [content]="content"
                    [first]="f"
                    [last]="l"
                    [toggleFirstCardOn]="toggleOpenUpcomingTomorrowCard"
                    (openRaceInfo)="openDialog($event, 'race-info')"
                    (openPickGameOverlay)="openDialog($event, 'pick-game')"
                    (openNotificationOverlay)="openDialog($event, 'notification')"
                    [targetedPlayerCount]="race.targetedPlayerCount"
                    [coinImg]="coinImg"
                    [selectedOption]="selectedOption"></cc-slot-races-item>
            </div>
        </div>
        <!-- <div  *ngIf="(liveRacesList?.length == 0 && upcomingRacesList?.upcomingToday?.length == 0 && upcomingRacesList?.upcomingTomorrow?.length == 0)" class="tourment-message">{{ tourment }}</div> -->
    </div>
    <div *ngIf="auth" class="casino-show-result-btn">
        <!-- <div (click)="showResults()" class="casino-sub-page show-result-btn btn btn-primary">
            {{ content?.textTranslations?.versionedList.SeeResults }}
        </div> -->
        <cs-slotrace-cta
            (click)="showResults()"
            [isLegacy]="isLegacy"
            [kind]="'primary'"
            [cta_text]="content?.textTranslations?.versionedList.SeeResults"
            [csDecoratorClass]="'casino-sub-page show-result-btn'">
        </cs-slotrace-cta>
    </div>
</div>
