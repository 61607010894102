import { ModuleWithProviders, NgModule } from '@angular/core';

import { CasinoModuleLoadDirective } from './casino-module-load.directive';
import { CasinoPlatformLoaderService } from './casino-platform-loader.service';

@NgModule({
    imports: [CasinoModuleLoadDirective],
    exports: [CasinoModuleLoadDirective],
})
export class CasinoPlatformLoaderModule {
    public static forRoot(): ModuleWithProviders<CasinoPlatformLoaderModule> {
        return {
            ngModule: CasinoPlatformLoaderModule,
            providers: [CasinoPlatformLoaderService, CasinoModuleLoadDirective],
        };
    }
}
