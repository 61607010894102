import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: tagManagerClientConfigFactory,
})
@LazyClientConfig({ key: 'bgTagManagerConfig', product: ClientConfigProductName.BINGO })
export class TagManagerClientConfig {
    triggerGoogleTagManagerClientInjection: boolean;
    googleTagManagerInjectionDelay: number;
}
export function tagManagerClientConfigFactory(service: LazyClientConfigService) {
    return service.get(TagManagerClientConfig);
}
