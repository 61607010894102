import { Compiler, Injectable, Injector, NgModuleRef, Type } from '@angular/core';

import {
    CasinoGridOnLobbyTypeComponent,
    NavOnLobbyTypeComponent,
    RpFavWidgetWrapperComponent,
    ThunderbiteIframeLauncherComponent,
} from '@casinocore/platform';
import { GeolocationInstallerComponent } from '@casinocore/platform/core';
import { GamingStoriesPhase2WrapperComponent } from '@casinocore/platform/gaming-stories-phase2';
import { NewsFeedV2WidgetComponent } from '@casinocore/platform/news-feed-v2-section';
import { SlotRacesWidgetComponent } from '@casinocore/platform/slot-races';
import { TeasersWrapperComponent } from '@casinocore/platform/teasers';
import { NativeAppService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CasinoPlatformLoaderService {
    ngModuleRef: NgModuleRef<any>;
    casinoLobbyService: any;
    otherProductService: any;
    favouriteService: any;
    casinoManagerService: any;
    onAppInitHandler: any;
    casinoMenuActionsBootstrapService: any;
    casinoModuleLoadedSubject = new BehaviorSubject<boolean>(false);
    casinoModuleLoadedObservable: Observable<boolean> = this.casinoModuleLoadedSubject.asObservable();
    casinoModuleLoaded: boolean;
    casinoCoreGameLaunchService: any;
    gameLaunchUrlFormationService: any;

    constructor(
        private injector: Injector,
        private compiler: Compiler,
        private nativeAppService: NativeAppService,
    ) {}

    loadCasinoPlatformModule(configs: any): void {
        const isODR: boolean = this.nativeAppService?.applicationName?.toLowerCase() === 'casinowodr';
        const isSportsODR: boolean = this.nativeAppService?.applicationName?.toLowerCase() === 'sportswodr';
        if (!isODR || !isSportsODR) this.lazyloader(configs);
    }

    getLazyComponentFactory(componentName: string): Type<any> {
        return componentsMap[componentName];
    }

    isCasinoModuleLoaded(): boolean {
        return this.casinoModuleLoaded;
    }

    getOtherProductService(): any {
        return this.otherProductService;
    }

    getFavouriteService(): any {
        return this.favouriteService;
    }
    getCasinoLobbyService(): any {
        return this.casinoLobbyService;
    }
    getCasinoManagerService(): any {
        return this.casinoManagerService;
    }

    getCasinoCoreGameLaunchService(): any {
        return this.casinoCoreGameLaunchService;
    }

    getGameLaunchUrlFormationService(): any {
        return this.gameLaunchUrlFormationService;
    }

    updateFavGamesForOtherProducts(): void {
        if (this.casinoModuleLoaded) {
            this.updateBingoFav();
        } else {
            const otherProductServiceModuleLoadSubscription: Subscription = this.casinoModuleLoadedObservable.subscribe((isModuleLoaded: boolean) => {
                if (isModuleLoaded) {
                    this.updateBingoFav();
                    if (otherProductServiceModuleLoadSubscription) {
                        otherProductServiceModuleLoadSubscription.unsubscribe();
                    }
                }
            });
        }
    }

    private updateBingoFav(): void {
        const otherProductServiceInstance: any = this.getOtherProductService();
        otherProductServiceInstance.updateFavGamesForOtherProducts();
    }

    lazyloader(configs: any) {
        import('./services').then(({ dynamicServicesMap }) => {
            this.casinoManagerService = this.injector.get(dynamicServicesMap.casinoManager);
            this.casinoLobbyService = this.injector.get(dynamicServicesMap.lobbyService);
            this.otherProductService = this.injector.get(dynamicServicesMap.otherProductService);
            this.casinoMenuActionsBootstrapService = this.injector.get(dynamicServicesMap.casinoMenuActionsBootstrapService);
            this.favouriteService = this.injector.get(dynamicServicesMap.favouriteService);
            this.onAppInitHandler = this.injector.get(dynamicServicesMap.onAppInitHandler);
            this.casinoCoreGameLaunchService = this.injector.get(dynamicServicesMap.casinoCoreGamelaunchService);
            this.gameLaunchUrlFormationService = this.injector.get(dynamicServicesMap.gameLaunchUrlFormationService);
            this.onAppInitHandler.execute();

            const casinoCoreNpmParams: any = {};
            casinoCoreNpmParams.isDll = false;
            casinoCoreNpmParams.fastLoadingVisibleOnLobby = true;
            if (configs) {
                casinoCoreNpmParams.disableFullLobbyFetch = configs.disableProductLobbyDataFetch;
                casinoCoreNpmParams.customLobbyTypeMaps = configs.lobbyTypeMap;
                casinoCoreNpmParams.disableCasinoLocalStorage = configs.disableCasinoLocalStorage;
                casinoCoreNpmParams.disableSlotracesCallsOnBootstrap = configs.disableSlotracesCallsOnBootstrap;
                casinoCoreNpmParams.isDisableLiveCasinoCalls = configs.isDisableLiveCasinoCalls;
                casinoCoreNpmParams.isDisableJackpotCalls = configs.isDisableJackpotCalls;
                casinoCoreNpmParams.targetOverlayLobby = configs.targetOverlayLobby;
            }
            this.casinoLobbyService.initiateCasinoLobby(casinoCoreNpmParams);

            this.casinoModuleLoaded = true;
            this.casinoModuleLoadedSubject.next(true);
        });
    }
}

export const componentsMap: Record<string, Type<any>> = {
    RpFavWidgetWrapperComponent: RpFavWidgetWrapperComponent,
    ThunderbiteIframeLauncherComponent: ThunderbiteIframeLauncherComponent,
    CasinoGridOnLobbyTypeComponent: CasinoGridOnLobbyTypeComponent,
    TeasersWrapperComponent: TeasersWrapperComponent,
    NavOnLobbyTypeComponent: NavOnLobbyTypeComponent,
    GeolocationInstallerComponent: GeolocationInstallerComponent,
    SportsOverlayComponent: GeolocationInstallerComponent,
    NewsFeedV2WidgetComponent: NewsFeedV2WidgetComponent,
    SlotRacesWidgetComponent: SlotRacesWidgetComponent,
    GamingStoriesPhase2WrapperComponent: GamingStoriesPhase2WrapperComponent,
};
