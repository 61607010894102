import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { AwardIconPipe } from './award-icon.pipe';
import { PrizePipe } from './prize.pipe';
import { ResultsPipe } from './results.pipe';
import { TimeFormatPipe } from './time-format.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [PrizePipe, ResultsPipe, AwardIconPipe, TimeFormatPipe],
    exports: [PrizePipe, ResultsPipe, AwardIconPipe, TimeFormatPipe, DatePipe],
    providers: [CurrencyPipe, DatePipe],
})
export class SharedModule {
    public static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
        };
    }
}
