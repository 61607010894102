import { Injectable } from '@angular/core';

import { NavigationService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
})
export class BingoWidgetService {
    isWidget: boolean;
    constructor(private navigation: NavigationService) {}

    setIsWidget() {
        if (this.navigation.location.pathname.includes('bingowidget')) {
            this.isWidget = true;
        }
    }

    isBingoWidget(): boolean {
        return this.isWidget;
    }

    postMessage(eventName: string, params: any) {
        window.parent.postMessage({ eventName, params }, '*');
    }
}
