import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import {
    BingoConfigProviderService,
    BingoHelperService,
    BingoTournamentsService,
    ResultsHistory,
    SitecoreContent,
    SlotRacesResponseError,
} from '@frontend/bingo/core';
import { cloneDeep } from 'lodash-es';
import { Subscription } from 'rxjs';

import { BingoSlotRacesInfoComponent } from '../race-info/bingo-race-info.component';

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'YYYY/MM/DD',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'bgl-slot-races-results',
    templateUrl: 'bingo-results.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        DatePipe,
    ],
})
export class BingoSlotRacesResultsComponent implements OnInit, OnDestroy {
    title: string;
    showAwardsAndPrizes: boolean;
    showAll: boolean = true;
    maxDate: Date = new Date();
    toMinDate: Date;
    toMaxDate: Date = new Date();
    toDate: Date;
    fromMinDate: Date;
    fromMaxDate: Date = new Date();
    fromDate: Date;
    results: ResultsHistory[];
    initialLoad: boolean = true;
    showNoResults: boolean = false;
    messages: { [item: string]: string };
    resultsHistorySubscription: Subscription;
    contentSubscription: Subscription;
    isMobileView: boolean;
    coinSrc: string;
    currencysymbol: string;
    enableResultsHistoryPage: boolean = false;
    displayBonusAsText: boolean = false;

    content: SitecoreContent = {
        bingoRaceRulesConfigs: [],
        rules: [],
        rulesBanner: {},
        placeholderCardImage: {},
        coinEconomy: {},
        textTranslations: { sharedList: {}, versionedList: {} },
        tips: [],
        errorMessages: { sharedList: {}, versionedList: {} },
        entryDetails: { sharedList: {}, versionedList: {} },
    };
    resultsErrorMsg: string;
    public slotRacesConfig: any;

    constructor(
        public dialogRef: MatDialogRef<BingoSlotRacesResultsComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        @Inject(MAT_DATE_FORMATS) public matDates: any,
        private slotRacesService: BingoTournamentsService,
        private configProviderService: BingoConfigProviderService,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
        private dialog: MatDialog,
        private bingoHelperService: BingoHelperService,
    ) {
        matDates.parse.dateInput = this.configProviderService.bingoTournamentsClientConfig.matDialogResultsDateFormat;
        matDates.display.dateInput = this.configProviderService.bingoTournamentsClientConfig.matDialogResultsDateFormat;
        this.title = data.title;
        this.coinSrc = data.coinImg;
    }

    ngOnInit() {
        this.slotRacesConfig = this.configProviderService.provideBingoTournamentsClientConfig();
        this.showAwardsAndPrizes = this.bingoHelperService.getConfigBasedOnInvokerProduct(this.slotRacesConfig.showAwardsAndPrizes);
        this.enableResultsHistoryPage = this.bingoHelperService.getConfigBasedOnInvokerProduct(this.slotRacesConfig.enableResultsHistoryPage);
        this.displayBonusAsText = this.bingoHelperService.getConfigBasedOnInvokerProduct(this.slotRacesConfig.displayBonusAsText);
        this.getResults();
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.content = content;
            this.messages = content && content.textTranslations ? content.textTranslations.versionedList : {};
        });
    }

    setResults() {
        this.results.forEach((result: any) => {
            result.dateStr = this.datePipe.transform(result.startDate, this.slotRacesConfig.resultsDateFormat);
            result?.configMaps?.forEach((configMap: any) => {
                if (configMap && configMap?.awardType) {
                    if (configMap && configMap?.awardType?.toLowerCase() != 'coins') {
                        if (configMap?.awardType && configMap?.value) {
                            result.awardType = configMap.awardType;
                        }
                        if (result.isWin && configMap?.value) {
                            this.currencysymbol = configMap.currency;
                            if (this.displayBonusAsText) {
                                if (
                                    configMap?.awardType?.toLowerCase() == 'freespins' ||
                                    configMap?.awardType?.toLowerCase() == 'free_spin' ||
                                    configMap?.awardType?.toLowerCase() == 'bingoticket' ||
                                    !configMap?.currency ||
                                    !configMap?.currency?.length
                                ) {
                                    result.awardValue = configMap?.awardType?.toLowerCase() == 'bonus' ? configMap.value : Number(configMap.value);
                                } else {
                                    result.awardValue = this.currencyPipe.transform(configMap.value, configMap.currency, 'symbol-narrow', '1.2-2');
                                }
                            } else {
                                if (configMap && configMap.awardType && configMap.awardType?.toLowerCase().indexOf('cash') > -1) {
                                    result.awardValue = this.currencyPipe.transform(configMap.value, configMap.currency, 'symbol-narrow', '1.2-2');
                                } else {
                                    result.awardValue = Number(configMap.value);
                                }
                            }
                        } else {
                            result.awardValue = '--';
                        }
                    }
                    if (configMap && configMap?.awardType?.toLowerCase() == 'coins') {
                        if (
                            result?.configMaps.filter(function (e: any) {
                                return e.awardType.toLowerCase() != 'coins';
                            }).length == 0
                        ) {
                            result.awardType = configMap?.awardType.toLowerCase();
                        }
                        result.isDisplayCoin = true;
                        result.coinValue = result.isDisplayCoin ? configMap.value : '';
                    }
                } else {
                    result.awardValue = '--';
                }
            });
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }

    fromChangeDate(event: any) {
        //this.eventValue.value.format('DD-MM-YYYY');
        this.fromDate = event?.value._d ? event?.value._d : new Date();
        this.toMinDate = cloneDeep(this.fromDate);
        // this.toMinDate = {...this.fromDate};
        this.toMinDate.setDate(this.toMinDate.getDate() + 1);
    }
    toChangeDate(event: any) {
        this.toDate = event?.value._d ? event?.value._d : new Date();
        this.toDate.setHours(23, 59, 59);
        this.fromMaxDate = cloneDeep(this.toDate);
        // this.fromMaxDate = {...this.toDate};
        this.fromMaxDate.setDate(this.fromMaxDate.getDate() - 1);
    }

    showAllResults() {
        this.showAll = true;
    }

    showWinResults() {
        this.showAll = false;
    }

    showResults() {
        this.initialLoad = false;
        this.getResults(this.toDate, this.fromDate);
    }

    private getResults(to?: Date, from?: Date): void {
        let noOfDays = 0;
        this.results = [];
        this.showNoResults = false;
        if (to && from) {
            noOfDays = Math.round((to.getTime() - from.getTime()) / (1000 * 3600 * 24));
        }
        const resultsForNoOfDays = this.bingoHelperService.getConfigBasedOnInvokerProduct(this.slotRacesConfig.resultsForNoOfDays);
        if (noOfDays > resultsForNoOfDays) {
            this.resultsErrorMsg = this.messages.ResultsNotMoreThan.replace('{noOfDays}', String(resultsForNoOfDays));
            this.showNoResults = true;
        } else {
            const end = to ? to.getTime().toString() : '';
            const start = from ? from.getTime().toString() : '';
            this.resultsHistorySubscription = this.slotRacesService.getResultsHistory(end, start).subscribe(
                (resultsHistory: ResultsHistory[]) => {
                    if (resultsHistory && resultsHistory.length > 0) {
                        this.results = this.initialLoad ? resultsHistory.slice(0, 5) : resultsHistory;
                        this.setResults();
                        this.showNoResults = false;
                    } else {
                        this.resultsErrorMsg = this.messages.NoResultsFound;
                        this.showNoResults = true;
                    }
                },
                (error: SlotRacesResponseError) => {
                    this.resultsErrorMsg = this.slotRacesService.getErrorMessage(error.errorCode)!;
                    this.showNoResults = true;
                },
            );
        }
    }

    ngOnDestroy() {
        this.resultsHistorySubscription.unsubscribe();
        this.contentSubscription.unsubscribe();
    }

    dialogProperties(dialogConfig: MatDialogConfig): void {
        if (window.innerWidth >= 768) {
            dialogConfig.width = '668px';
        } else {
            dialogConfig.width = '100%';
            dialogConfig.height = '100%';
            dialogConfig.maxWidth = '100%';
            dialogConfig.maxHeight = '100%';
        }
    }

    openRaceInfoDialog(result: ResultsHistory) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        this.dialogProperties(dialogConfig);
        dialogConfig.data = {
            raceResult: result,
            resultsDialogRef: this.dialogRef,
            coinImg: this.coinSrc,
        };
        dialogConfig.panelClass = 'bingo-bt-mat-dialog';
        const resultsDialog = document.getElementsByClassName('bt-results-dialog')[0] as HTMLElement;
        resultsDialog.style.display = 'none';
        this.dialog.open(BingoSlotRacesInfoComponent, dialogConfig);
    }
}
