import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';

import { BingoTournamentIconModule } from '../bingo-tournament-icon/bingo-tournament-icon.module';
import { BingoSlotRaceCardModule } from '../cards-wrapper/card/bingo-card.module';
import { BingoSlotRacesLeaderboardModule } from '../leaderboard/bingo-leaderboard.module';
import { BingoSlotRacesrulesAndTnCModule } from '../rules-points-tips-tnc/bingo-rules-and-tnc.module';
import { BingoSlotRacesInfoComponent } from './bingo-race-info.component';
import { BingoSlotRacesPrizesModule } from './prizes/bingo-prizes.module';

@NgModule({
    imports: [
        CommonModule,
        TrustAsHtmlPipe,
        MatDialogModule,
        BingoSlotRacesLeaderboardModule,
        BingoSlotRacesPrizesModule,
        BingoSlotRaceCardModule,
        BingoSlotRacesrulesAndTnCModule,
        BingoTournamentIconModule,
    ],
    declarations: [BingoSlotRacesInfoComponent],
    exports: [BingoSlotRacesInfoComponent],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
    ],
})
export class BingoSlotRacesInfoModule {
    public static forRoot(): ModuleWithProviders<BingoSlotRacesInfoModule> {
        return {
            ngModule: BingoSlotRacesInfoModule,
        };
    }
}
