import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { CollapsibleContainerComponent } from './collapsible-container.component';

@NgModule({
    imports: [CommonModule],
    exports: [CollapsibleContainerComponent],
    declarations: [CollapsibleContainerComponent],
})
export class bgCollapsibleContainerModule {
    public static forRoot(): ModuleWithProviders<bgCollapsibleContainerModule> {
        return {
            ngModule: bgCollapsibleContainerModule,
        };
    }
}
