<div class="example-container madgrid-align-proparty" id="container-1-{{ showTopThree }}" [ngStyle]="{ height: height + 'px' }">
    <table mat-table [dataSource]="visibleStats" [ngClass]="{ 'sr-alternate-colors': !showTopThree }">
        <ng-container matColumnDef="rank">
            <th
                mat-header-cell
                *matHeaderCellDef
                [ngStyle]="{
                    'background-color': leaderboardConfigs?.leaderboardHeaderBgColor,
                    'color': leaderboardConfigs?.leaderboardHeaderTextColor
                }">
                {{ leaderboardConfigs?.rankTitleText }}
            </th>
            <td mat-cell *matCellDef="let transaction" class="casino-sr-rank" [ngStyle]="{ color: leaderboardConfigs?.leaderboardContentTextColor }">
                {{ transaction?.rank }}
            </td>
        </ng-container>
        <ng-container matColumnDef="prize">
            <th
                mat-header-cell
                *matHeaderCellDef
                [ngStyle]="{
                    'background-color': leaderboardConfigs?.leaderboardHeaderBgColor,
                    'color': leaderboardConfigs?.leaderboardHeaderTextColor
                }">
                <i
                    class="sr-prize-icon"
                    [ngClass]="
                        race?.additionalParams
                            ? leaderboardConfigs?.sharedList[(race?.additionalParams | prize: true)]
                            : results && results?.awardType?.toLowerCase() != 'coins'
                              ? leaderboardConfigs?.sharedList[({} | prize: true : results?.content?.promotion?.prizes)]
                              : ''
                    "></i>
                <span
                    class="srp-coin-reward"
                    *ngIf="race?.additionalParams?.awardType?.toLowerCase() == 'coins' || results?.awardType?.toLowerCase() == 'coins'">
                    <span class="sr-card-total-coin">
                        <img class="coin-img" [src]="coinImg" alt="Coin Image" />
                    </span>
                </span>
                <span class="leader-board-prize-align">{{ leaderboardConfigs?.prizeTitleText }}</span>
            </th>
            <td mat-cell *matCellDef="let transaction">
                <div class="casino-Bonus" *ngIf="transaction.prize; else coinblock">
                    {{ transaction.prize }}
                    <div *ngIf="transaction?.isDisplayCoin" class="srp-coin-reward">
                        <span class="theme-plus"></span>
                        <span class="sr-card-total-coin">
                            <img class="coin-img" [src]="coinImg" alt="Coin Image" />
                            <span>{{ transaction.coinValue }}</span>
                        </span>
                    </div>
                </div>
                <ng-template #coinblock>
                    <div *ngIf="transaction?.isDisplayCoin" class="casino-Bonus">
                        <div class="srp-coin-reward">
                            <span class="sr-card-total-coin">
                                <img class="coin-img" [src]="coinImg" alt="Coin Image" />
                                <span>{{ transaction.coinValue }}</span>
                            </span>
                        </div>
                    </div>
                </ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="prize-img">
            <th
                mat-header-cell
                *matHeaderCellDef
                [ngStyle]="{
                    'background-color': leaderboardConfigs?.leaderboardHeaderBgColor,
                    'color': leaderboardConfigs?.leaderboardHeaderTextColor
                }"></th>
            <td mat-cell *matCellDef="let transaction" class="srl-prize-td" [ngStyle]="{ color: leaderboardConfigs?.leaderboardContentTextColor }">
                <div class="srl-prize-container">
                    <div class="brown_trophy" [innerHtml]="transaction?.icon | trustAsHtml"></div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="player">
            <th
                mat-header-cell
                *matHeaderCellDef
                [ngStyle]="{
                    'background-color': leaderboardConfigs?.leaderboardHeaderBgColor,
                    'color': leaderboardConfigs?.leaderboardHeaderTextColor
                }">
                {{ leaderboardConfigs?.playerTitleText }}
            </th>
            <td mat-cell *matCellDef="let transaction" [ngStyle]="{ color: leaderboardConfigs?.leaderboardContentTextColor }">
                {{ transaction?.playerName }}
            </td>
        </ng-container>
        <ng-container matColumnDef="points">
            <th
                mat-header-cell
                *matHeaderCellDef
                [ngStyle]="{
                    'background-color': leaderboardConfigs?.leaderboardHeaderBgColor,
                    'color': leaderboardConfigs?.leaderboardHeaderTextColor
                }">
                {{ leaderboardConfigs?.pointsTitleText }}
            </th>
            <td
                mat-cell
                *matCellDef="let transaction"
                class="casino-sr-points"
                [ngStyle]="{ color: leaderboardConfigs?.leaderboardContentTextColor }">
                {{ transaction?.points }}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{ 'header-font-10': enableResultsHistoryPage && results }"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{
                'sticky': row.playerName === userName,
                'player-position-bg-color': leaderboardConfigs?.leaderboardPlayerPositionBgColorNotNull,
                'player-position-text-color': leaderboardConfigs?.leaderboardPlayerPositionTextColorNotNull,
                'player-position-award-type-bg-color': leaderboardConfigs?.leaderboardPlayerPositionAwardTypeBgColorNotNull,
                'player-position-award-type-text-color': leaderboardConfigs?.leaderboardPlayerPositionAwardTypeTextColorNotNull
            }"
            id="{{ showTopThree }}-{{ row?.playerName }}"></tr>
    </table>
</div>

<div class="example-container-2" *ngIf="!userIsVisible && userEnrolled && !showTopThree && playerStats">
    <table mat-table [dataSource]="playerStats">
        <ng-container matColumnDef="rank">
            <td
                mat-footer-cell
                *matFooterCellDef
                [ngStyle]="{
                    'background-color': leaderboardConfigs?.leaderboardPlayerPositionBgColor,
                    'color': leaderboardConfigs?.leaderboardPlayerPositionTextColor
                }">
                {{ playerStats?.rank }}
            </td>
        </ng-container>
        <ng-container matColumnDef="prize">
            <td
                mat-footer-cell
                *matFooterCellDef
                [ngStyle]="{
                    'background-color': leaderboardConfigs?.leaderboardPlayerPositionBgColor,
                    'color': leaderboardConfigs?.leaderboardPlayerPositionTextColor
                }">
                <div
                    class="casino-Bonus-sticky casino-Bonus"
                    [ngStyle]="{
                        'background-color': leaderboardConfigs?.leaderboardPlayerPositionAwardTypeBgColor,
                        'color': leaderboardConfigs?.leaderboardPlayerPositionAwardTypeTextColor
                    }"
                    *ngIf="playerStats?.prize">
                    {{ playerStats?.prize }}
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="prize-img">
            <td
                mat-footer-cell
                *matFooterCellDef
                class="srl-prize-td"
                [ngStyle]="{
                    'background-color': leaderboardConfigs?.leaderboardPlayerPositionBgColor,
                    'color': leaderboardConfigs?.leaderboardPlayerPositionTextColor
                }">
                <div class="srl-prize-container">
                    <div *ngIf="playerStats?.icon" class="brown_trophy" [innerHtml]="playerStats?.icon | trustAsHtml"></div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="player">
            <td
                mat-footer-cell
                *matFooterCellDef
                [ngStyle]="{
                    'background-color': leaderboardConfigs?.leaderboardPlayerPositionBgColor,
                    'color': leaderboardConfigs?.leaderboardPlayerPositionTextColor
                }">
                {{ playerStats?.playerName }}
            </td>
        </ng-container>
        <ng-container matColumnDef="points">
            <td
                mat-footer-cell
                *matFooterCellDef
                [ngStyle]="{
                    'background-color': leaderboardConfigs?.leaderboardPlayerPositionBgColor,
                    'color': leaderboardConfigs?.leaderboardPlayerPositionTextColor
                }">
                {{ playerStats?.points }}
            </td>
        </ng-container>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
</div>
