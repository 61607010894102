<div id="emojies-animation" *ngIf="show">
    <ng-container [ngSwitch]="item" *ngFor="let emoji of storyItem.emojies; let i = index">
        <span *ngSwitchCase="emoji.emojiName">
            <img *ngFor="let d of ' '.repeat(9).split('')" class="{{ emoji.emojiName }}" [src]="emoji.emojiPath" />
        </span>
    </ng-container>
</div>
<div *ngIf="showMessageessageSent" class="mobilestories-messgae-sent" [ngStyle]="{ 'color': messageTxtClr, 'background-color': messageBgClr }">
    <div ngClass="{{ messageClass }}">{{ messageTxt }}</div>
</div>
<div
    *ngIf="storyItem.emojies.length > 0 && showEmojies"
    class="casino-emojies-container"
    [ngClass]="'emojibtnposition-' + (emojiBtnPosition | lowercase)">
    <div class="btn-emoji">
        <button [disabled]="disableEmojis" *ngFor="let emoji of storyItem.emojies; let i = index" (click)="clickHandler(emoji.emojiName)">
            <span>
                <img
                    [ngStyle]="selectedEmojieName === emoji.emojiName && show ? { transform: 'scale(1.5)' } : { transform: 'unset' }"
                    [src]="emoji.emojiPath" />
            </span>
            <span *ngIf="selectedEmojieName === emoji.emojiName && !show" class="dotresponded"></span>
        </button>
    </div>
</div>
