<div class="bt-tips-expandable-wrapper">
    <mat-expansion-panel
        [expanded]="isExpanded"
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        hideToggle="true"
        [ngClass]="this.isExpansionDisabled">
        <mat-expansion-panel-header>
            <mat-panel-title class="panel-title">
                {{ sitecoreContent?.textTranslations?.versionedList?.Tips }}
            </mat-panel-title>
            <mat-panel-description *ngIf="isCollapsible">
                <mat-icon *ngIf="!panelOpenState">
                    <span class="theme-plus"></span>
                </mat-icon>
                <mat-icon *ngIf="panelOpenState">
                    <span class="theme-minus"></span>
                </mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="bt-tips-row" id="tips-dropdown">
            <div class="bt-tips-column" *ngFor="let data of sitecoreContent.tips">
                <div class="bt-tips-image">
                    <img src="{{ data.image.src }}" draggable="false" />
                </div>
                <div class="bt-tips-content">
                    <div class="bt-tips-title">{{ data.title }}</div>
                    <div class="bt-tips-desc" [innerHTML]="data.text | trustAsHtml"></div>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</div>
