import { Injectable } from '@angular/core';

import { ApiBase, ApiServiceFactory, ClientConfigProductName, ProductService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    useFactory: apiServiceFactory,
    deps: [ApiServiceFactory, ProductService],
})
export class PlatformApiService extends ApiBase {}

export function apiServiceFactory(apiServiceFactory: ApiServiceFactory, productService: ProductService) {
    return apiServiceFactory.createForProduct(PlatformApiService, {
        product: ClientConfigProductName.CASINO,
        area: 'games',
        forwardProductApiRequestHeader: productService.getMetadata(ClientConfigProductName.CASINO).isEnabled,
    });
}
