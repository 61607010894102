<div
    class="bingo-bt-card"
    [ngClass]="[
        cardbackgroundimage != '' ? 'no-blur' : '',
        expandCard ? '' : 'collapse',
        race && race.beatBanker?.length > 0 ? 'bingo-beat-the-banker' : ''
    ]"
    [ngStyle]="
        showimage
            ? { color: cardTextColor }
            : promobackgroundcolor != ''
              ? { color: cardTextColor, background: promobackgroundcolor }
              : { color: cardTextColor, background: cardbackgroundcolor }
    ">
    <div class="bingo-bt-title" *ngIf="race.isLive">
        <div class="bt-theme-icon"><img src="{{ ballTypeImage }}" alt="BallType" /></div>
        <div class="bt-title-text">{{ cardTitle }}</div>
        <div
            *ngIf="race.isLive"
            class="theme-down"
            [ngClass]="{ expanded: expandCard, collapsed: !expandCard }"
            (click)="addOrremoveCollapseClass()"></div>
    </div>

    <img class="bingo-bt-card-bg-image" *ngIf="showimage" src="{{ backgroundImageSrc }}" alt="game-name1" />

    <div class="bingo-bt-title" *ngIf="(!race.isLive && (!showRaceInfo || fromPickGameOverlay)) || (!race.isLive && showPlaceholderCard)">
        <div class="bt-theme-icon"><img src="{{ ballTypeImage }}" alt="BallType" /></div>
        <div class="bt-title-text">{{ cardTitle }}</div>
    </div>

    <div class="bt-upcoming-race-list" *ngIf="showCardMenu">
        <div class="bt-time-text">
            <div class="bt-text-time">{{ race.startTimeAMPM }}</div>
            <div
                class="bt-dot-dividers"
                [ngClass]="first ? (last ? 'red-dot-first red-dot-last' : 'red-dot-first') : last ? 'red-dot-last' : ''"></div>
            <div class="bt-theme-icon"><img src="{{ ballTypeImage }}" alt="BallType" /></div>
            <div class="bt-game-name">{{ cardTitle }}</div>
        </div>
        <div class="bt-prize">
            <div class="bt-theme-icon theme-down" (click)="hideRaceCard()"></div>
        </div>
    </div>

    <div class="bingo-bt-content">
        <div class="bingo-bt-image">
            <bgl-slot-races-icon
                (openPickGameOverlay)="emitPickGameOverlay()"
                (launchSelectedGame)="launchGame()"
                [promoIcon]="race?.content?.promotion?.promoIcon"
                [spinProgress]="race?.spinProgress"
                [gameVariants]="race.gameVariants"
                [position]="position"
                [source]="containerLocation"
                [cta]="race.ctaCollection"
                [isClickable]="!fromPickGameOverlay"
                [isLive]="race.isLive"
                [isFreeTourney]="
                    race?.subType &&
                    (race?.subType?.toLowerCase() == 'free_to_play' ||
                        race?.subType?.toLowerCase() == 'free_play_tech' ||
                        race?.subType?.toLowerCase() === 'bingo_leaderboard')
                ">
            </bgl-slot-races-icon>
        </div>

        <div class="bingo-bt-frame" [ngClass]="{ 'bt-max-bet': displayMaxBet }">
            <div class="bingo-bt-prizes">
                <div class="bingo-bt-total-prizes">
                    {{ messages.TotalPrizes }}
                </div>
                <div class="bingo-bt-freespins">
                    <span class="bt-theme-icon" [ngClass]="content?.textTranslations?.sharedList[(race?.additionalParameters | prize: true)]"></span>
                    <span class="bt-bingo-card-total-coin" *ngIf="race?.additionalParameters?.awardType?.toLowerCase() == 'coins'">
                        <img class="bt-coin-img" [src]="coinImg" alt="Coin Image" />
                    </span>
                    <p [ngClass]="{ 'bingo-currency-space': !noncurrenyValue }">{{ race?.additionalParameters | prize: false }}</p>
                    <ng-container *ngIf="coinObj?.isDisplayCoin">
                        <span class="theme-plus"></span>
                        <span class="bt-bingo-card-total-coin">
                            <img class="bt-coin-img" [src]="coinObj?.coinSrc" alt="Coin Image" />
                        </span>
                    </ng-container>
                </div>
            </div>
            <div class="bingo-bt-info" [ngClass]="{ 'bt-card-counter-bet-box-max': displayMaxBet }">
                <div class="bingo-bt-ends-in">
                    <p>{{ race.isLive ? messages.EndsIn : messages.StartsIn }}</p>
                    <p *ngIf="timeSpan < 86400000 || !race.isLive; else elseblock">
                        {{ timeSpan | timeformat }}
                    </p>
                    <ng-template #elseblock>
                        <div class="bt-card-counter">
                            {{ Days }} <span>{{ messages.Days }}</span>
                        </div>
                    </ng-template>
                </div>
                <div
                    class="bingo-bt-min-bet"
                    *ngIf="
                        !race?.subType ||
                        !(
                            race?.subType &&
                            (race?.subType?.toLowerCase() === 'free_to_play' ||
                                race?.subType?.toLowerCase() === 'free_play_tech' ||
                                race?.subType?.toLowerCase() === 'bingo_leaderboard')
                        )
                    ">
                    <p *ngIf="!enableMaxBet">{{ messages.MinimumBet }}</p>
                    <p *ngIf="!enableMaxBet">
                        {{ race?.criteriaDetails?.total | currency: currencyCode : 'symbol-narrow' : '1.2-2' }}
                    </p>
                    <p *ngIf="enableMaxBet">{{ messages.MinimumBet }}</p>
                    <p *ngIf="enableMaxBet">
                        {{ race?.criteriaDetails?.total | currency: currencyCode : 'symbol-narrow' : '1.2-2' }}
                    </p>
                </div>
                <div class="bingo-bt-min-bet" *ngIf="displayMaxBet">
                    <p class="min-bet">{{ messages.MaxBet }}</p>
                    <p class="btbingo-card-min-bet">
                        {{ race?.criteriaDetails?.max | currency: currencyCode : 'symbol-narrow' : '1.2-2' }}
                    </p>
                </div>
                <div
                    *ngIf="
                        (race?.subType && race?.subType?.toLowerCase() === 'free_to_play') ||
                        race?.subType?.toLowerCase() === 'free_play_tech' ||
                        race?.subType?.toLowerCase() === 'bingo_leaderboard'
                    ">
                    <div class="bingo-bt-min-bet">
                        <p class="min-bet">{{ messages.MinBet }}</p>
                        <p class="btbingo-card-min-bet">{{ messages.Free2Play }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="theme-info" *ngIf="showRaceInfo" (click)="emitRaceInfo()"></div>
    </div>

    <div
        *ngIf="race && race.beatBanker && race.beatBanker?.length > 0"
        class="bingo-beat-the-banker-container"
        [ngStyle]="{ background: bottombackground }"
        [ngClass]="[isprogressBarEnabled && !(race.ctaCollection.indexOf('OPTIN') > -1) ? 'btb-progress-bar' : '']">
        <div class="scrore-text">{{ messages.beatBankerTitle }}</div>

        <div class="bingo-beat-the-banker-progress-bar">
            <mat-slider
                *ngIf="isprogressBarEnabled && !(race.ctaCollection.indexOf('OPTIN') > -1 || race.ctaCollection.indexOf('OPTIN_EXPIRED') > -1)"
                [disabled]="true"
                [max]="minPoints"
                class="bt-bingo-beat-the-banker-progress-bar-color">
                <input matSliderThumb [value]="currentPoints" />
            </mat-slider>
        </div>
        <div
            *ngIf="isprogressBarEnabled && !(race.ctaCollection.indexOf('OPTIN') > -1 || race.ctaCollection.indexOf('OPTIN_EXPIRED') > -1)"
            class="bingo-bt-freespins-count">
            {{ currentPoints }}<span>/{{ minPoints }} Pts</span>
        </div>
        <div
            *ngIf="!(isprogressBarEnabled && !(race.ctaCollection.indexOf('OPTIN') > -1 || race.ctaCollection.indexOf('OPTIN_EXPIRED') > -1))"
            class="bingo-freespins-count">
            <span>{{ minPoints }} Pts</span>
        </div>
    </div>

    <div class="bingo-bt-slider" *ngIf="race.isLive">
        <mat-slider [disabled]="true" [max]="sliderMaxValue" class="bt-progress-bar-color">
            <input matSliderThumb [value]="progressValue" />
        </mat-slider>
    </div>

    <div
        class="bingo-bt-footer"
        [ngStyle]="{ background: bottombackground }"
        [ngClass]="{ 'optin-limit': race?.subType && race?.subType?.toLowerCase() === 'free_play_tech' }">
        <div class="bingo-bt-info">
            <div class="bingo-bt-players">
                <p>{{ messages.PlayerTitle }}</p>
                <p>
                    {{
                        race?.subType && race?.subType?.toLowerCase() === 'free_play_tech' && race.optinLimit !== 0
                            ? playerCount + '/' + race.optinLimit
                            : playerCount
                    }}
                </p>
            </div>
            <div class="bingo-bt-duration">
                <p>{{ messages.Duration }}</p>
                <p [innerHtml]="slotRaceDuration"></p>
            </div>
            <div class="bingo-bt-players">
                <p>
                    {{
                        race.spinProgress && race.spinProgress.current > 0
                            ? race?.subType && race?.subType?.toLowerCase() === 'free_play_tech'
                                ? messages.ChipsLeft
                                : messages.SpinsLeft
                            : race?.subType && race?.subType?.toLowerCase() === 'free_play_tech'
                              ? messages.ChipsLimit
                              : messages.SpinsLimit
                    }}
                </p>
                <p>{{ spinCount }}</p>
            </div>
        </div>

        <div class="btn-section" *ngIf="enableOptin || race.isLive || (race?.subType && race?.subType?.toLowerCase() != 'free_play_tech')">
            <button
                *ngIf="
                    race.isNotify ||
                    race.isLive ||
                    !enableRaceNotification ||
                    (!race.isLive && !(race.ctaCollection.length == 0) && enableRaceNotification)
                "
                [disabled]="
                    fromPickGameOverlay
                        ? race.ctaCollection.indexOf('OPTIN') > -1
                            ? false
                            : true
                        : race?.subType &&
                            race?.subType?.toLowerCase() === 'free_play_tech' &&
                            race.optinLimit !== 0 &&
                            playerCount === race.optinLimit &&
                            race.ctaCollection.indexOf('OPTIN') > -1
                          ? true
                          : race.isLive
                            ? race.ctaCollection.indexOf('OPTIN_EXPIRED') > -1 ||
                              (race?.subType &&
                                  (race?.subType?.toLowerCase() === 'free_to_play' || race?.subType?.toLowerCase() === 'free_play_tech') &&
                                  spinCount <= 0)
                                ? true
                                : false
                            : race.ctaCollection.indexOf('OPTIN') > -1
                              ? false
                              : true
                "
                class="btn btn-primary btn-md"
                (click)="ctaAction()">
                {{
                    race.ctaCollection.length === 0
                        ? playNowText
                        : race?.isOptinWithCoins
                          ? messages.OptinWithText
                          : race?.subType &&
                              race?.subType?.toLowerCase() === 'free_play_tech' &&
                              race.optinLimit !== 0 &&
                              playerCount === race.optinLimit
                            ? messages.OptinLimitReached
                            : race?.subType && (race?.subType?.toLowerCase() === 'free_to_play' || race?.subType?.toLowerCase() === 'free_play_tech')
                              ? messages.Optin4Free
                              : messages.Optin
                }}
                <ng-container
                    *ngIf="race?.isOptinWithCoins && (race.ctaCollection.indexOf('OPTIN') > -1 || race.ctaCollection.indexOf('OPTIN_EXPIRED') > -1)">
                    <span class="bt-card-total-coin">
                        <span>{{ race?.coinsForOptin }}</span>
                        <img class="coin-img" [src]="coinImg" alt="Coin Image" />
                    </span>
                </ng-container>
            </button>
        </div>
        <div
            class="btn btn-primary btn btn-md notification-btn"
            *ngIf="!race.isNotify && enableRaceNotification && !race.isLive && !(race.ctaCollection.indexOf('OPTIN') > -1)"
            [ngClass]="[race.notificationStatus ? slotRacesSharedList.notificationBellOnIcon : slotRacesSharedList.notificationBellOffIcon]"
            (click)="onNotificationClick(!race.notificationStatus)"></div>
    </div>
</div>
