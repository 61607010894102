<!-- <div class="horizontal-separator"></div> -->
<div class="collapsible-container">
    <div
        class="collapsible-container-header"
        (keypress)="toggleContainer($event)"
        (click)="toggleContainer($event)"
        role="button"
        attr.aria-expanded="{{ !isCollapsed }}">
        <div class="collapsible-container-header-text-encloser">
            <div class="img-mytickets" *ngIf="image != ''"><img [src]="image" alt="" /></div>
            <span *ngIf="!showIconImage">
                <span *ngIf="!jackpot" [ngClass]="'bingo-type-' + gameCategory">{{ gameCategory }}</span>
                <span *ngIf="jackpot" [ngClass]="'bingo-type-' + gameCategory + ' pjp-added'"
                    >{{ gameCategory }}
                    <i class="theme-favorite-i"></i>
                </span>
            </span>
            <span *ngIf="showIconImage">
                <img alt="" *ngIf="!jackpot" [ngClass]="'bingo-type-' + gameCategory" class="icon-image" src="{{ iconimage }}" />&nbsp;
                <span *ngIf="jackpot">
                    <img alt="" [ngClass]="'bingo-type-' + gameCategory + 'pjp-added'" class="icon-image" src="{{ iconimage }}" />&nbsp;
                    <i class="theme-favorite-i"></i>
                </span>
            </span>
            <span class="title" [innerText]="title"></span>
            <span *ngIf="subTitle" [innerText]="subTitle" class="sub-title"></span>
            <span [ngClass]="{ 'theme-down': isCollapsed, 'theme-up': !isCollapsed }"> </span>
        </div>
    </div>
    <div *ngIf="!isCollapsed" class="collapsible-container-content">
        <ng-content></ng-content>
    </div>
</div>
