import { Injectable } from '@angular/core';

import { UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BingoClientConfigsService } from '../../bingo-client-configs/bingo-client-configs.service';
import { BingoConfigProviderService } from '../../bingo-config-provider/services/bingo-config-provider.service';
import { BingoManager } from '../../bingo-manager/services/bingo-manager.service';
import { BingoNpmParams } from '../../bingo-npm-params/models/bingo-npm.params';
import { BingoNpmParamsService } from '../../bingo-npm-params/services/bingo-npm-params.service';

@Injectable({
    providedIn: 'root',
})
export class BingoNpmManagerService {
    lobbyLoadedSubject = new BehaviorSubject<any>(null);
    lobbyLoadedSubjectForInvokerProduct = new BehaviorSubject<any>(null);
    //Subscribe to this observable to get the content of the lobby
    lobbyLoadedObservable: Observable<any> = this.lobbyLoadedSubject.asObservable();
    lobbyLoadedObservableForInvokerProduct: Observable<any> = this.lobbyLoadedSubjectForInvokerProduct.asObservable();
    isTouchDevice: boolean;
    postLoginFeedsInitiated: boolean = false;
    constructor(
        private configProviderService: BingoConfigProviderService,
        private bingoManager: BingoManager,
        private user: UserService,
        private bingoClientConfigsService: BingoClientConfigsService,
        private bingoNpmParamsService: BingoNpmParamsService,
    ) {}

    onAppInit() {
        this.configProviderService.bingoClientConfigObservable.subscribe((isClientConfigLoaded: any) => {
            if (isClientConfigLoaded) {
                this.bingoClientConfigsService.fetchClientConfigs();
                //Load the schedule post call here
            }
        });
        //Initiate any feeds that require for Bingo
        // this.lobbyLoadedObservable.subscribe((lobbyLoadEvent) => {
        //   if (lobbyLoadEvent) {
        //   }
        // });
        this.user.events.pipe(filter((e: any) => e instanceof UserLoginEvent)).subscribe(() => {
            // this.postLoginCalls(false);
        });
    }

    intiateBingoLobby(bingoNpmParams: BingoNpmParams) {
        this.bingoNpmParamsService.setBingoNpmParams(bingoNpmParams);
        this.configProviderService.initiateBingo(bingoNpmParams.isDll);
        this.bingoManager.checkForTouchDevice();
    }
}
