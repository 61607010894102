import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import {
    BingoConfigProviderService,
    ConfirmMessageService,
    FeaturesClientConfig,
    GameLaunchService,
    RoomsInfoService,
    ScheduleService,
} from '@frontend/bingo/core';
import { NavigationService, ProductHomepagesConfig, UserService } from '@frontend/vanilla/core';
import { DesignSystemEnablerService } from 'packages/bingo/frontend-lib/core/src/shared/services/ds-enabler.service';
import { Subscription } from 'rxjs';

import { SessionPackageService } from '../../shared/services/session-package.service';
import { SessionBingoConfirmComponent } from '../confirm-model/sessionbingo-confirm.component';

@Component({
    selector: 'bg-buynow-grid',
    templateUrl: 'buy-now-grid.component.html',
})
export class BuyNowGridComponent implements OnInit, OnDestroy {
    PackageDetails: any;
    buyNowItem: any;
    isSessionBingo: boolean;
    isPackageBingo: boolean;
    content: any = [];
    globalClientConfig: any;
    details: any;
    errCount: number = 0;
    sessionId: any;
    isLegacy: boolean = false;
    roomId: any;
    sessionResObs: Subscription;
    ingameStatus: boolean;
    isInGameClient: boolean = false;
    gameId: string;
    disableButton: boolean = false;
    packagePurchased: boolean = false;
    isPurchased: boolean = false;
    roomsConfig: any = [];
    PurchaseButton: boolean = false;
    clientConfig: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<BuyNowGridComponent>,
        private sessionService: SessionPackageService,
        private route: ActivatedRoute,
        private confirmMessageService: ConfirmMessageService,
        private designSystemEnablerService: DesignSystemEnablerService,
        private roomsInfoService: RoomsInfoService,
        private gameLaunchService: GameLaunchService,
        private configProviderService: BingoConfigProviderService,
        private scheduleService: ScheduleService,
        private lhProductHomepagesConfig: ProductHomepagesConfig,
        private user: UserService,
        private navigator: NavigationService,
    ) {
        dialogRef.disableClose = true;
    }
    ngOnDestroy(): void {
        this.sessionResObs?.unsubscribe();
        if (this.isInGameClient) {
            dispatchEvent(new CustomEvent('gs:showFloatingEntry'));
            removeEventListener('gs:productstub:entrypoint:active', this.entrypointactive);
        }
    }
    ngOnInit() {
        this.isLegacy = this.designSystemEnablerService.isEnabled();
        if (localStorage.getItem('config')) {
            this.clientConfig = JSON.parse(localStorage.getItem('config')!);
            if (!this.globalClientConfig) this.globalClientConfig = this.clientConfig.global;
            if (this.roomsConfig.length == 0) this.roomsConfig = this.clientConfig.rooms;
        }
        if (this.data.status_of_package == 'purchased' || (this.data.no_of_tickets && this.data.no_of_tickets > 0)) {
            this.isPurchased = true;
        }
        const iframe_ingame_status = this.route.snapshot.queryParamMap.get('ingame');
        if (iframe_ingame_status) {
            document.getElementById('main-content')!.classList.add('iframe-custom-model');
            document.body.classList.add('sessiobingo-iframe-body');
            this.isInGameClient = true;
        }
        if (this.route.snapshot.queryParamMap.has('sessionId')) {
            this.data = {};
            this.data.session_id = parseInt(this.route?.snapshot?.queryParamMap?.get('sessionId') ?? '0');
            this.data.package_type = this.route?.snapshot?.queryParamMap?.get('type');
            // this.showCloseBtn = false;
            this.dialogRef.disableClose = true;
        }
        if (this.route.snapshot.queryParamMap.has('gameid')) {
            this.gameId = this.route?.snapshot?.queryParamMap?.get('gameid') ?? '';
        }
        this.sessionService.setPackageInfo(this.data);
        this.getPackageDetails(this.data);
        if (this.isInGameClient) {
            addEventListener('gs:productstub:entrypoint:active', this.entrypointactive);
        }
    }

    getPackageDetails(data: any) {
        this.sessionId = data.session_id;
        const package_type = data.package_type;
        this.sessionService.getPackageDetails(package_type, this.sessionId).subscribe((response) => {
            if (response) {
                this.PackageDetails = response;
                if (this.isInGameClient)
                    window.parent.postMessage({ type: 'session_bingo_loaded', sessionid: this.sessionId, gameid: this.gameId }, '*');
                if (
                    this.PackageDetails?.result?.package_list?.packages_info[this.sessionId]?.status == 'error' &&
                    (this.PackageDetails?.result?.package_list?.packages_info[this.sessionId]?.message == 'Games Completed or in progress' ||
                        this.PackageDetails?.result?.package_list?.packages_info[this.sessionId]?.message == 'Package Id is not valid')
                )
                    this.packagePurchased = true;
                if (!this.packagePurchased) {
                    this.packagePurchased = false;
                    this.roomId = this.PackageDetails.result.package_list.packages_info[this.sessionId].package_game_details[0].room_id;
                    if (
                        this.isInGameClient &&
                        this.PackageDetails?.result?.package_list?.packages_info[this.sessionId]?.purchase_status?.no_of_tickets > 0
                    ) {
                        this.disableButton = true;
                        this.isPurchased = true;
                    }
                    this.appendRoomId();
                    this.enableDisabaleButtons();
                }
                if (package_type == 'session_bingo') {
                    this.isSessionBingo = true;
                    this.content = this.clientConfig?.sessionGlobalMessages;
                }
                if (package_type == 'package_bingo') {
                    this.isPackageBingo = true;
                    this.content = this.clientConfig?.packageGlobalMessages;
                }
            }
        });
    }
    appendRoomId() {
        this.buyNowItem = this.PackageDetails.result.package_list.packages_info;
        const buyNowIds = Object.keys(this.buyNowItem);
        this.buyNowItem = Object.values(this.buyNowItem);
        // let val: any;
        for (let val = 0; val < this.buyNowItem.length; val++) {
            this.buyNowItem[val].roomId = buyNowIds[val];
        }
        this.PackageDetails = this.buyNowItem;
    }
    enableDisabaleButtons() {
        const isPurchase = this.sessionService.purshaseValidation(this.PackageDetails[0]);
        this.PurchaseButton = false;
        if (isPurchase) {
            this.disableButton = false;
            this.isPurchased = false;
            this.PurchaseButton = true;
        }
    }
    close() {
        if (this.isInGameClient) {
            window.parent.postMessage({ type: 'session_bingo_closed', sessionid: this.sessionId, gameid: this.roomId }, '*');
            document.body.classList.remove('sessiobingo-iframe-body');
        }
        this.dialogRef.close();
        //window.postMessage({ 'type': 'session_bingo_closed', 'sessionid': this.sessionId, 'gameid': this.roomId }, '*');
    }
    // TicketsSelected(obj: any){
    //     this.finalbuyNowDetails = obj;
    // }
    buyNowClicked() {
        // let datas = data;
        // datas = datas;
        //Validations
        const featuresConfig: FeaturesClientConfig = this.configProviderService.provideFeaturesConfig();
        if (featuresConfig.enableScreenNameForPrebuy && !this.user.screenname) {
            const url = window.location.href;
            this.navigator.goTo(this.lhProductHomepagesConfig.portal + '/mobileportal/screenname', {
                appendReferrer: url,
            });
        } else {
            const response = this.sessionService.getFinalGameObj();
            const no_of_tickets = response.SelectedTickets;
            const game_name = response.gameDetails[0].game_Info[0].room_name;
            const package_type = this.data.package_type;
            //this.sessionResObs =this.sessionService.finalGameDetailsObsevable.subscribe((response: any) => {
            if (response) {
                this.errCount = 0;
                if (this.isSessionBingo && response?.gameDetails[0].isFabGrabRoom) {
                    response.gameDetails[0].game_Info.forEach((element: any) => {
                        if (
                            element.game_context.FomoBingo.fomo_numbers?.length < element.game_context.FomoBingo.minimum_numbers ||
                            element.game_context.FomoBingo.fomo_numbers == null
                        ) {
                            this.errCount++;
                        }
                    });
                } else {
                    response.gameDetails.forEach((rooms: any) => {
                        if (rooms.isFabGrabRoom) {
                            rooms.game_Info.forEach((element: any) => {
                                if (
                                    element.game_context.FomoBingo.fomo_numbers?.length < element.game_context.FomoBingo.minimum_numbers ||
                                    element.game_context.FomoBingo.fomo_numbers == null
                                ) {
                                    this.errCount++;
                                }
                            });
                        }
                    });
                }
                if (this.errCount > 0) {
                    const message = this.globalClientConfig.messages.MinimumTopAndTailNumber,
                        header = this.globalClientConfig.messages.fabGrabAlertHeader,
                        closeBtn = this.globalClientConfig.messages.confirmCloseButton;
                    this.confirmMessageService.confirmThis(
                        header,
                        message,
                        '',
                        '',
                        closeBtn,
                        () => {},
                        () => {},
                    );
                } else {
                    const dialogRef = this.dialog.open(SessionBingoConfirmComponent, {
                        panelClass: 'sessionbingo-confirm-popup',
                        disableClose: true,
                        data: {
                            sessionId: this.sessionId,
                            roomId: this.roomId,
                            game_name: game_name,
                            package_type: package_type,
                        },
                    });
                    this.sessionService.buySessionAandPackage(package_type, 'click', game_name, no_of_tickets);
                    dialogRef.afterClosed().subscribe((closeModalData: any) => {
                        if (closeModalData && closeModalData?.closedFrom != 'cancel') {
                            this.dialogRef.close();
                        }
                    });
                }
            }
        }
        //});
    }

    gameLaunch() {
        this.dialogRef.close();
        if (
            this.PackageDetails &&
            this.PackageDetails[0] &&
            this.PackageDetails[0].package_game_details &&
            this.PackageDetails[0].package_game_details[0]
        ) {
            const data = this.PackageDetails[0].package_game_details[0];
            if (this.scheduleService.checkforscheduleData()) {
                const launchedRoom = this.scheduleService.getFeatureTypesbyRoomId(data.room_id);
                this.gameLaunchService.initiateGameLaunch(
                    launchedRoom.name,
                    'Bingo',
                    'Bingo',
                    launchedRoom.bingo_type,
                    'REGULAR',
                    this.roomsInfoService.getImage(launchedRoom.id, this.roomsConfig),
                    launchedRoom.id,
                    launchedRoom.isFavourite,
                    1, //this.position
                    1, //this.gridSize
                    '',
                    '',
                );
            }
        }
    }

    mapBingoRoom(scheduleData: any, roomId: string) {
        scheduleData.forEach((element: any) => {
            if (element.id === roomId) {
                return element;
            }
        });
    }

    private readonly entrypointactive = (customEvent: CustomEvent) => {
        if (customEvent && customEvent.detail && customEvent.detail.activeFrom && customEvent.detail.activeFrom.toLowerCase() === 'bottom-sticky') {
            dispatchEvent(new CustomEvent('gs:hideFloatingEntry'));
        }
    };
}
