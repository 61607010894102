import { Component, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'bg-team-modal-popup',
    templateUrl: './team-modal-popup.component.html',
})
export class TeamModalPopupComponent {
    constructor(
        public dialogRef: MatDialogRef<TeamModalPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}
    onteamSelected = new EventEmitter();

    onTeamSelected(team: any) {
        this.onteamSelected.emit({
            team: team,
        });
        this.dialogRef.close();
    }
}
