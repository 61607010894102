import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { BingoTournamentsService, Race, Rule, SitecoreContent } from '@frontend/bingo/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'bgl-slot-races-rules',
    templateUrl: './bingo-rules.component.html',
})
export class BingoSlotRacesRulesComponent implements OnInit, OnDestroy {
    @Input() showHeader: boolean;
    @Input() isCollapsible: boolean;
    @Input() isExpanded: boolean;
    @Input() race: Race;

    isVisible: boolean;
    rulesData: Rule[] = [];
    panelOpenState: boolean;
    sitecoreData: SitecoreContent;
    contentSubscription: Subscription;
    isExpansionDisabled: string = '';

    constructor(private slotRacesService: BingoTournamentsService) {}

    ngOnInit() {
        this.isVisible = false;
        this.changeCollapseState();
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.sitecoreData = content;
            this.setRules();
        });
    }

    setRules() {
        if (this.race) {
            this.sitecoreData.rules.forEach((ruleItem: Rule) => {
                const rule = this.race?.content?.rules?.find((r) => r?.metadata?.id?.itemName === ruleItem?.metadata?.id?.itemName);
                if (rule) {
                    this.rulesData.push(rule);
                } else {
                    this.rulesData.push(ruleItem);
                }
            });
        } else {
            this.rulesData = this.sitecoreData.rules;
        }
    }
    ngOnDestroy() {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
    }
    changeCollapseState() {
        this.isVisible = !this.isVisible;
    }
    ExpansionState() {
        if (!this.isCollapsible) {
            this.isExpansionDisabled = 'disabled-pointer';
        }
    }
}
