import { NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'cc-news-feed-v2',
    templateUrl: 'news-feed-v2.component.html',
    standalone: true,
    imports: [NgStyle],
})
export class NewsFeedV2Component {
    @Input() attributes: any;
    newsFeed: any;
    constructor() {}
}
