<div class="tickets-component" *ngIf="!isDataLoading">
    <div class="modal-content" [ngClass]="{ mobileScrollHidden: addFriendModalCss && isMobileView }">
        <!-- <div class="modal-header">
        </div> -->
        <div class="modal-body">
            <div class="model-tabs-content" [ngClass]="{ friendsTeamModalBackdrop: addFriendModalCss }">
                <button *ngIf="showCloseBtn" class="close" (click)="Close()"></button>
                <mat-tab-group [(selectedIndex)]="tabSelectedIndex" (selectedIndexChange)="OnTabSelected($event)">
                    <mat-tab *ngIf="!disableAllPrebuy" label="{{ ticketscollist.columns.preBuy }}">
                        <div class="tab-content">
                            <bg-pre-buy-grid
                                [SelectedRoomId]="selected_room_id"
                                [DropDownRoomData]="dropdown_roomobj"
                                [Date]="selectedDate"
                                [TodayDate]="today_date"
                                [GameSlot]="selectedGameSlot"
                                (OnTotalTicketsSelected)="TotalTicketsSelected($event)"
                                (OnTeamModalOpen)="TeamModalOpend($event)"
                                title="">
                            </bg-pre-buy-grid>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{ ticketscollist.columns.myTickets }}">
                        <div class="tab-content">
                            <bg-my-tickets
                                (OnTotalMyTicketsSelected)="TotalMyTicketsSelected($event)"
                                (OnTeamModalOpen)="TeamModalOpend($event)"></bg-my-tickets>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
        <div class="modal-footer">
            <div class="model-tabs-footer">
                <div class="pb-ticketsCount">
                    <span class="label-price">
                        {{ ticketscollist.columns.totalTickets }}
                        <span class="text-style">{{ total_purchased_tickets }}</span>
                    </span>
                    <span class="label-price"
                        >{{ ticketscollist.columns.totalPrice }}
                        <span class="text-style">{{ total_purchase_price | vnCurrency }}</span>
                    </span>
                </div>
                <!-- <div class="pb-footer-btn" *ngIf="!disableAllPrebuy && !isLegacy"> -->
                <!-- <div class="pb-clear">
                        <button type="button" class="btn 6 btn-ticket pb-btn-clear" (click)="ClearSelection()">{{ticketscollist.columns.clearCta}}
                        </button>
                    </div> -->

                <!-- <button type="button" class="btn btn-light btn-ticket" [disabled]="total_purchased_tickets == 0" (click)="ClearSelection()">
                        {{ ticketscollist.columns.clearCta }}
                    </button>
                    <button type="button" class="btn btn-primary btn-ticket" [disabled]="total_purchased_tickets == 0" (click)="BuyTickets()">
                        {{ buyTicketsLabel }}
                    </button> -->
                <!-- </div> -->
                <div class="pb-footer-btn" *ngIf="!disableAllPrebuy">
                    <bg-custombutton
                        (click)="ClearSelection()"
                        [variant]="'outline'"
                        [kind]="'tertiary'"
                        [size]="'medium'"
                        [decoratorClass]="'footer-btnn'"
                        [disabled]="total_purchased_tickets == 0"
                        [customClass]="'btn-light btn-ticket'"
                        [button_text]="ticketscollist.columns.clearCta"
                        >{{ ticketscollist.columns.clearCta }}
                    </bg-custombutton>
                    <bg-custombutton
                        (click)="BuyTickets()"
                        [variant]="'filled'"
                        [kind]="'primary'"
                        [size]="'medium'"
                        [decoratorClass]="'footer-btnn'"
                        [customClass]="'btn-primary btn-ticket'"
                        [disabled]="total_purchased_tickets == 0"
                        [button_text]="buyTicketsLabel"
                        >{{ buyTicketsLabel }}
                    </bg-custombutton>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <bg-numpad *ngIf="showNumpad" class="testing-zindex"></bg-numpad> -->

<!-- <bg-alert-message></bg-alert-message> -->
<!-- <bg-confirm-message></bg-confirm-message> -->
