import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: superLinksClientConfigFactory,
})
@LazyClientConfig({ key: 'bgSuperLinks', product: ClientConfigProductName.BINGO })
export class SuperLinksClientConfig {
    enableSuperLinkPopup: boolean;
    enableSuperLinks: boolean;
    superlinkText: string;
    superLinksData: any;
    //superLinksSitecoreData: any;
}
export function superLinksClientConfigFactory(service: LazyClientConfigService) {
    return service.get(SuperLinksClientConfig);
}
