<div id="csSlotRaceTnC" *ngIf="!isCollapsible" class="bt-tandcs-expandable-wrapper">
    <div class="bt-bingo-slotraces-tnc">
        <div class="bt-tnc-title">
            <div *ngIf="showHeader" class="panel-title" id="tnc-panel-title">
                {{ sitecoreData?.textTranslations?.versionedList?.TermsAndConditions }}
            </div>
        </div>
        <div class="panel-text">
            <div class="dropdown-text">
                <div class="tnc-text" *ngIf="!race">
                    <vn-page-matrix [path]="'CozyBingo/BingoTournaments/TermsAndConditions/TnC/TnCHtml'"> </vn-page-matrix>
                </div>
                <div class="tnc-text" *ngIf="race">
                    <div [innerHtml]="tncData | trustAsHtml"></div>
                </div>
            </div>
        </div>
    </div>
</div>
