import { Injectable, OnDestroy } from '@angular/core';

import { BingoConfig, BingoConfigProviderService } from '@frontend/bingo/core';
import { Subscription, interval } from 'rxjs';

import { RootServicesModule } from '../../root-services.module';

@Injectable({
    providedIn: RootServicesModule,
})
export class AlertMessageService implements OnDestroy {
    isError: boolean;
    isShown: boolean;
    isInfo: boolean;
    iconClass: string;
    message: string;
    subHideAlert: Subscription;

    constructor(private configProviderService: BingoConfigProviderService) {}

    show(iconClass: string, message: string, isError = false, isInfo = false) {
        const bingoConfig: BingoConfig = this.configProviderService.provideBingoConfig();
        this.isShown = true;
        this.iconClass = iconClass;
        this.message = message;
        this.isError = isError;
        this.isInfo = isInfo;
        this.subHideAlert = interval(bingoConfig.alertMessageTimeout).subscribe(() => {
            this.hide();
        });
    }

    success(iconClass: string, message: string) {
        this.show(iconClass, message, false);
    }

    error(iconClass: string, message: string, isError = true) {
        this.show(iconClass, message, isError);
    }

    info(iconClass: string, message: string, isInfo = true) {
        this.show(iconClass, message, false, isInfo);
    }

    hide() {
        this.isShown = false;
        this.subHideAlert.unsubscribe();
    }

    ngOnDestroy() {
        if (this.subHideAlert) this.subHideAlert.unsubscribe();
    }
}
