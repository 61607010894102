import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';

import { DecoratorClassDirective } from '../../directives/decorator-class.directive';
import { CustomButtonComponent } from '../designsystems/custombutton.component';
import { ConfirmMessageComponent } from './confirm-message.component';

@NgModule({
    declarations: [ConfirmMessageComponent],
    exports: [ConfirmMessageComponent],
    imports: [CommonModule, CustomButtonComponent, DecoratorClassDirective],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ConfirmMessageModule {
    public static forRoot(): ModuleWithProviders<ConfirmMessageModule> {
        return {
            ngModule: ConfirmMessageModule,
        };
    }
}
