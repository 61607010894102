import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { BingoConfigProviderService, FeaturesClientConfig } from '@frontend/bingo/core';
import { DesignSystemEnablerService } from 'packages/bingo/frontend-lib/core/src/shared/services/ds-enabler.service';
import { Subscription } from 'rxjs';

import { AlertMessageService } from '../../shared/components/alert-message/alert-message.service';
import { BonusSupressionPopupComponent } from '../../shared/components/bonus-supression/bonus-supression-popup.component';
import { SessionPackageService } from '../../shared/services/session-package.service';

@Component({
    selector: 'bg-sessionbingo-confirm',
    templateUrl: 'sessionbingo-confirm.component.html',
})
export class SessionBingoConfirmComponent implements OnInit, OnDestroy {
    totalPackageDetails: any;
    totalPrice: number;
    noOfGames: number = 0;
    noOfTickets: number;
    currency: string;
    content: any = [];
    package_info: any;
    errCount: number;
    purchaseObj: any = {
        purchase_context: [],
        // package_type: '',
        // packageId: 0,
        // noOfTickets: 0
    };
    purchaseSuccessMessage: any;
    errorMessage: any;
    isInGameClient: boolean = false;
    packageType: string;
    packageData: any;
    gamename: string;
    isLegacy: boolean = false;
    sessionResObs: Subscription;
    globalClientConfig: any;
    featureConfig: FeaturesClientConfig;
    clientConfig: any;

    constructor(
        public dialogRef: MatDialogRef<SessionBingoConfirmComponent>,
        public dialogRefBonusSupression: MatDialogRef<BonusSupressionPopupComponent>,
        private dialog: MatDialog,
        private sessionService: SessionPackageService,
        private designSystemEnablerService: DesignSystemEnablerService,
        private alertMessageService: AlertMessageService,
        private configProviderService: BingoConfigProviderService,
        private route: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.featureConfig = this.configProviderService.provideFeaturesConfig();
        dialogRef.disableClose = true;
    }
    ngOnDestroy(): void {
        this.sessionResObs?.unsubscribe();
    }
    ngOnInit() {
        this.isLegacy = this.designSystemEnablerService.isEnabled();
        if (localStorage.getItem('config')) {
            this.clientConfig = JSON.parse(localStorage.getItem('config')!);
            if (!this.globalClientConfig) this.globalClientConfig = this.clientConfig?.global;
        }
        this.packageData = this.data;
        if (this.route.snapshot.queryParamMap.get('hideHeader') == 'true' || this.route.snapshot.queryParamMap.get('.box')) {
            this.isInGameClient = true;
        }
        this.errCount = 0;
        this.content = this.clientConfig?.sessionGlobalMessages;
        this.sessionResObs = this.sessionService.finalGameDetailsObsevable.subscribe((response: any) => {
            if (response) {
                this.totalPackageDetails = response;
                this.setData(response);
            }
        });
        this.packageType = this.packageData.package_type;
        this.gamename = this.packageData.game_name;
        this.sessionService.buySessionAandPackage(this.packageType, 'click', this.gamename, 'confirm purchase overlay');
    }

    setData(data: any) {
        this.noOfTickets = 0;
        this.totalPrice = data.totalPrice;
        this.totalPrice = Math.round((this.totalPrice + Number.EPSILON) * 100) / 100;
        data.gameDetails.forEach((room: any) => {
            room.game_Info.forEach((element: any) => {
                this.noOfTickets += element.tickets;
                this.currency = element.currency;
                this.noOfGames++;
            });
        });
    }

    confirm() {
        this.package_info = this.sessionService.getPackageInfo();
        if (this.totalPackageDetails && this.totalPackageDetails.gameDetails) {
            // for (let i = 0; i < this.totalPackageDetails.gameDetails.length; i++) {
            //     purchase_context[i] = this.purchaseContext(this.totalPackageDetails.gameDetails[i]);
            //     this.purchaseObj.no_of_tickets = this.totalPackageDetails.gameDetails[i].tickets;
            // }
            this.totalPackageDetails.gameDetails.forEach((room: any) => {
                room.game_Info.forEach((element: any) => {
                    // if (room.isFriendsRoom || room.isFabGrabRoom || room.isMultistake) {
                    this.purchaseObj.purchase_context.push(this.purchaseContext(element, room.isFriendsRoom, room.isFabGrabRoom, room.isMultistake));
                    // }
                    this.purchaseObj.noOfTickets = element.tickets;
                });
            });
            this.purchaseObj.package_type = this.package_info.package_type;
            this.purchaseObj.packageId = Number(this.package_info.session_id);
        }
        this.close('closing_before_success'); //to close the dialogue before the api call is done to avoid duplicate clicking
        this.sessionService.getConfirmationDetails(this.purchaseObj).subscribe(
            (response: any) => {
                let error_message = '';
                if (
                    response != null &&
                    response.responseCode == '0' &&
                    (response.responseMessage == 'SUCCESS' || response.responseMessage == 'success')
                ) {
                    if (response.result !== null && response.result.responseMessage !== 'error') {
                        this.showPurchaseSuccessAlert(response.result);
                        this.sessionService.setPackageId(this.purchaseObj.packageId);
                        const isPurchase = this.sessionService.purshaseValidation(
                            response?.result?.package_list?.purchase_info[this.purchaseObj.packageId],
                        );
                        this.sessionService.setIsPurchase(this.purchaseObj.packageId, isPurchase);
                        if (this.isInGameClient) this.postSuccessMessage();
                        // this.balanceService.refresh();
                        // // this.alertMessageService.show('checkmark_kick', this.stringFormat(this.globalClientConfig['messages'].ticketsPurchasedSuccessfullyMessage, [this.total_purchased_tickets]));
                        // this.balanceService.refresh();
                        // this.PreBuyGridComponentChild.onSuccessfullPurchase(
                        //     response.result
                        // );
                        // this.MyTicketsModalComponentChild.onSuccessfullPurchase(
                        //     response.result
                        // );
                        localStorage.setItem('confirm', 'true');
                        this.sessionService.buySessionAandPackage(this.packageType, 'confirm cta', this.gamename, 'confirm purchase overlay');
                    } else {
                        error_message = this.globalClientConfig['errors']['technicalErrorMessage'];
                    }
                } else if (response != null) {
                    if (response.result == null && response.responseCode == '104') {
                        this.dialogRefBonusSupression = this.dialog.open(BonusSupressionPopupComponent, {
                            panelClass: 'bonus-supression-container',
                        });
                        this.dialogRefBonusSupression.afterClosed().subscribe(() => {});
                    } else {
                        error_message = this.globalClientConfig['errors'][response.responseCode];
                        if (response.responseCode && error_message == undefined)
                            error_message = this.globalClientConfig.messages['gl_' + response.responseCode];
                        if (error_message == undefined) error_message = this.globalClientConfig['errors']['technicalErrorMessage'];
                    }
                } else error_message = this.globalClientConfig['errors']['technicalErrorMessage'];
                if (error_message != '') this.alertMessageService.error('checkmark_kick', error_message, true);
                this.close('confirm_success');
            },
            (error: any) => {
                if (error) {
                    this.alertMessageService.error('checkmark_kick', this.globalClientConfig['messages'].errorOccuredWhilePurchaseMessage);
                    this.close('confirm_error');
                }
            },
        );
    }

    postSuccessMessage() {
        document.body.classList.remove('sessiobingo-iframe-body');
        setTimeout(() => {
            window.parent.postMessage({ type: 'session_bingo_closed', sessionid: this.data.sessionId, gameid: this.data.roomId }, '*');
        }, this.featureConfig.timeOutForPostMessage);
        //window.postMessage({ 'type': 'session_bingo_loaded', 'sessionid': this.data.sessionId, gameid: this.data.roomId }, '*');
    }

    showPurchaseSuccessAlert(result: any) {
        if (result.responseMessage && result.responseMessage == 'success') {
            if (this.noOfTickets > 0) {
                this.alertMessageService.show(
                    'checkmark_kick',
                    this.stringFormat(this.globalClientConfig['messages'].ticketsPurchasedSuccessfullyMessage, [
                        this.getNumberOfTicketsBuyed(result),
                    ]),
                );
            }
        }
    }
    getNumberOfTicketsBuyed(result: any) {
        return (
            result.package_list.purchase_info[this.purchaseObj.packageId].package_game_details.length *
            result.package_list.purchase_info[this.purchaseObj.packageId].purchase_status.no_of_tickets
        );
    }
    stringFormat(str: string, objs: any[]) {
        let i = 0;
        for (const arg of objs) {
            const strToReplace = '{' + i++ + '}';
            str = str.replace(strToReplace, arg.toString() || '');
        }
        return str;
    }

    purchaseContext(details: any, isFriendsRoom: boolean, isFabGrabRoom: boolean, isMultistake: boolean) {
        const context: any = {
            team: '',
            fomo_numbers: [],
            price: null,
        };
        if (isFriendsRoom) {
            context.team = details.game_context.TeamBingo.current_team;
        }
        if (isFabGrabRoom) {
            context.fomo_numbers = details.game_context.FomoBingo.fomo_numbers;
        }
        if (isMultistake) {
            context.price = details.selected_price_GBP;
        }
        return {
            room_id: details.room_id,
            game_config_id: details.game_id,
            game_start_time: details.start_time,
            context: context,
        };
    }

    close(location: string) {
        localStorage.setItem('confirm', 'false');
        if (location === 'cancel')
            this.sessionService.buySessionAandPackage(this.packageType, 'cancel cta', this.gamename, 'confirm purchase overlay');
        this.dialogRef.close({ closedFrom: location });
    }
}
