import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { BingoTournamentsService, SitecoreContent } from '@frontend/bingo/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'bgl-beat-the-banker-notification',
    templateUrl: './bingo-beat-the-banker-notification.html',
})
export class BingoBeatTheBankerNotificationComponent implements OnInit, OnDestroy {
    @Input() race: any;
    @Input() prizeValueForBeatBanker: any;
    @Input() coinImg: any;
    minPoints: any;
    contentSubscription: Subscription;
    beatBankerPromoIcon: string;
    messages: { [item: string]: string } = {};
    content: SitecoreContent = {
        bingoRaceRulesConfigs: [],
        rules: [],
        rulesBanner: {},
        placeholderCardImage: {},
        coinEconomy: {},
        textTranslations: { sharedList: {}, versionedList: {} },
        tips: [],
        errorMessages: { sharedList: {}, versionedList: {} },
        entryDetails: { sharedList: {}, versionedList: {} },
    };
    constructor(private slotRacesService: BingoTournamentsService) {}

    ngOnInit() {
        this.minPoints = this.race?.beatBanker?.minPoints;
        this.displayCoinInBTB();

        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.content = content;
            this.messages = content && content.textTranslations ? content.textTranslations.versionedList : {};
        });
    }

    displayCoinInBTB() {
        if (this.race?.beatBanker && this.race.beatBanker?.isCoinAward == 'YES') {
            this.race.isDisplayCoin = true;
            this.race.coinValue = this.race.beatBanker?.totalCoins ? this.race.beatBanker.totalCoins : this.race.beatBanker?.value;
        }
        if (this.race?.beatBanker?.awardType.toLowerCase() != 'coins') {
            this.race.displayCash = true;
            if (!this.prizeValueForBeatBanker) {
                this.prizeValueForBeatBanker = this.race.beatBanker;
            }
        }
    }

    ngOnDestroy() {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
    }
}
