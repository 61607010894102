<!--component html goes here -->
<div class="casino-lobby-container-main">
    <div class="result-title">
        {{ messages.MyResults }}
        <div (click)="closeDialog()" class="cls-btn theme-ex"></div>
    </div>
    <div class="casino-sr-result-wraper">
        <div *ngIf="showAwardsAndPrizes" class="sr-statistics-container">
            <div class="awards">
                <span class="category-title">Awards</span>
                <div class="awards-container">
                    <div class="awards-category">
                        <div class="awards theme-competitions"></div>
                        <div class="awards txt awards-type">Gold Thropy</div>
                        <div class="awards value awards-count">1</div>
                    </div>
                    <div class="awards-category">
                        <div class="theme-competitions"></div>
                        <div class="awards txt awards-type">Brown Thropy</div>
                        <div class="awards value awards-count">1</div>
                    </div>
                    <div class="awards-category">
                        <div class="theme-competitions"></div>
                        <div class="awards txt awards-type">4th Place</div>
                        <div class="awards value awards-count">1</div>
                    </div>
                    <div class="awards-category">
                        <div class="theme-competitions"></div>
                        <div class="awards txt awards-type">Badge</div>
                        <div class="awards value awards-count">1</div>
                    </div>
                </div>
            </div>
            <div class="prizes">
                <span class="category-title">Prizes</span>
                <div class="casino-prizes-container">
                    <div class="prizes-category">
                        <div class="prizes theme-competitions"></div>
                        <div class="prizes txt prize-type">Free Spins</div>
                        <div class="prizes value values">1230</div>
                    </div>
                    <div class="prizes-category">
                        <div class="prizes theme-competitions"></div>
                        <div class="prizes txt prize-type">cash</div>
                        <div class="prizes value values">$590</div>
                    </div>
                    <div class="prizes-category">
                        <div class="prizes theme-competitions"></div>
                        <div class="prizes txt prize-type">Bonus</div>
                        <div class="prizes value values">4</div>
                    </div>
                    <div class="prizes-category"></div>
                </div>
            </div>
            <div class="general">
                <span class="category-title">General statistics</span>
                <div class="stats-container">
                    <div class="stats-category">
                        <div class="stats theme-competitions"></div>
                        <div class="stats txt stats-text">Podiums</div>
                        <div class="stats value values">2</div>
                    </div>
                    <div class="stats-category">
                        <div class="stats theme-competitions"></div>
                        <div class="stats txt stats-text">Total Points</div>
                        <div class="stats value values">38220</div>
                    </div>
                    <div class="stats-category">
                        <div class="stats theme-competitions"></div>
                        <div class="stats txt stats-text">Total Races</div>
                        <div class="stats value values">143</div>
                    </div>
                    <div class="stats-category">
                        <div class="stats theme-competitions"></div>
                        <div class="stats txt stats-text">Total Spins</div>
                        <div class="stats value values">3629</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sr-winners-container">
            <span class="casino-winners-title">{{ messages.Searchforresults }}</span>
            <div class="casino-inner-container">
                <div class="results-btn">
                    <!-- <div class="form-group">
                    <div class="form-control-container form-control-tabs-container">
                        <div class="form-control-tabs promo-tabs d-flex">
                            <div class="form-control-tabs-item" (click)="showAllResults()">
                                <input class="form-control-tabs-radio" id="tab10" name="group4" type="radio"
                                    [checked]="showAll">
                                <label class="form-control-tabs-label" for="tab10">{{messages.All}}</label>
                            </div>
                            <div class="form-control-tabs-item" (click)="showWinResults()">
                                <input class="form-control-tabs-radio" id="tab11" name="group4" type="radio"
                                    [checked]="!showAll">
                                <label class="form-control-tabs-label" for="tab11">{{messages.Wins}}</label>
                            </div>
                        </div>
                    </div>
                </div> -->
                    <div class="form-control-tabs-segmented-v4">
                        <input
                            type="radio"
                            name="2tabs-d-enh"
                            value="tb1-d-enh"
                            id="tb1-d-enh"
                            checked=""
                            class="form-control-tabs-segmented-v4-input" />
                        <label for="tb1-d-enh" class="form-control-tabs-segmented-v4-label" (click)="showAllResults()">{{ messages.All }}</label>
                        <input type="radio" name="2tabs-d-enh" value="tb2-d-enh" id="tb2-d-enh" class="form-control-tabs-segmented-v4-input" />
                        <label for="tb2-d-enh" class="form-control-tabs-segmented-v4-label" (click)="showWinResults()">{{ messages.Wins }}</label>
                    </div>
                </div>
                <div class="select-date">
                    <div class="from">
                        <mat-form-field>
                            <input
                                matInput
                                [min]="fromMinDate"
                                [max]="fromMaxDate"
                                [matDatepicker]="pickerFrom"
                                [placeholder]="messages?.From"
                                (dateChange)="fromChangeDate($event)"
                                readonly
                                (click)="pickerFrom.open()" />
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom disabled="false" panelClass="casino-datepicker"></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="to">
                        <mat-form-field>
                            <input
                                matInput
                                [min]="toMinDate"
                                [max]="toMaxDate"
                                [matDatepicker]="pickerTo"
                                (dateChange)="toChangeDate($event)"
                                [placeholder]="messages?.To"
                                readonly
                                (click)="pickerTo.open()" />
                            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                            <mat-datepicker #pickerTo disabled="false" panelClass="casino-datepicker"></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <!-- <div class="show-result-btn btn btn-primary" (click)="showResults()">{{ messages.ShowResults }}</div> -->
                <cs-slotrace-cta
                    (click)="showResults()"
                    [isLegacy]="isLegacy"
                    [kind]="'primary'"
                    [casinoDsClass]="'casino-showresultbtn'"
                    [cta_text]="messages.ShowResults"
                    [csDecoratorClass]="'show-result-btn'">
                </cs-slotrace-cta>
            </div>
        </div>
        <div
            class="sr-results-list"
            *ngIf="results && results.length > 0"
            [ngClass]="{ 'result-history-enabled': slotRacesConfig.enableResultsHistoryPage }">
            <span class="result-list-title" *ngIf="initialLoad">{{ messages.Last5Races }}</span>
            <div class="result-list-container">
                <ul class="list-titles">
                    <li>{{ messages.Game }}</li>
                    <li>{{ messages.Date }}</li>
                    <li>{{ messages.Prize }}</li>
                    <li>{{ messages.YourPlace }}</li>
                    <li>{{ messages.Points }}</li>
                    <li *ngIf="slotRacesConfig.enableResultsHistoryPage"></li>
                </ul>
                <ul class="list-elements" *ngFor="let result of results | resultsType: showAll">
                    <li>
                        <cc-slot-races-icon
                            class="cc-slot-races-icon"
                            [promoIcon]="result?.content?.promotion?.promoIcon"
                            [isDraggable]="false"
                            [isClickable]="false"
                            [gameVariantList]="result?.gameVariantList"></cc-slot-races-icon>
                    </li>
                    <li>{{ result.dateStr }}</li>
                    <li>
                        <i class="sr-prize-icon" [ngClass]="content?.textTranslations?.sharedList[result?.awardType?.toLowerCase()]"></i>
                        <span class="sr-card-total-prize-value" [ngClass]="{ 'no-award-padding': result.awardValue === '--' && !result?.awardType }">
                            {{
                                slotRacesConfig?.displayBonusAsText && result?.awardType?.toLowerCase() === 'bonus'
                                    ? messages?.Bonus
                                    : result?.awardValue
                            }}
                            <div *ngIf="result?.isDisplayCoin" class="srp-coin-reward">
                                <span *ngIf="result?.awardValue" class="theme-plus"></span>
                                <span class="sr-card-total-coin">
                                    <img class="coin-img" [src]="coinSrc" alt="Coin Image" />
                                </span>
                                <span>{{ result?.coinValue }}</span>
                            </div>
                        </span>
                    </li>
                    <li>
                        <div class="srp-img" [innerHtml]="result.prizeIcon | trustAsHtml"></div>
                        {{ result.rank }}
                    </li>
                    <li>{{ result.points }}</li>
                    <li class="theme-right" (click)="openRaceInfoDialog(result)" *ngIf="slotRacesConfig.enableResultsHistoryPage">&nbsp;</li>
                </ul>
            </div>
        </div>
        <div class="no-result-found" *ngIf="showNoResults">{{ resultsErrorMsg }}</div>
    </div>
</div>
