import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

import { Race, Rule, SitecoreContent, SlotRacesService } from '@casinocore/platform/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cc-slot-races-rules',
    templateUrl: './rules.component.html',
    standalone: true,
    imports: [MatExpansionModule, NgClass, NgIf, MatIconModule, NgFor, TrustAsHtmlPipe],
})
export class SlotRacesRulesComponent implements OnInit, OnDestroy {
    @Input() showHeader: boolean;
    @Input() isCollapsible: boolean;
    @Input() isExpanded: boolean;
    @Input() race: Race;

    isVisible: boolean;
    rulesData: Rule[] = [];
    panelOpenState: boolean;
    sitecoreData: SitecoreContent;
    contentSubscription: Subscription;
    isExpansionDisabled: string = '';
    rulesContentColor: string;

    constructor(private slotRacesService: SlotRacesService) {}

    ngOnInit() {
        this.isVisible = false;
        this.changeCollapseState();
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.sitecoreData = content;
            this.setRules();
        });
        this.rulesContentColor = this.sitecoreData.textTranslations.versionedList?.RulesContentColor;
    }

    setRules() {
        if (this.race) {
            this.sitecoreData.rules.forEach((ruleItem: Rule) => {
                const rule = this.race?.content?.rules?.find((r) => r?.metadata?.id?.itemName === ruleItem?.metadata?.id?.itemName);
                if (rule) {
                    this.rulesData.push(rule);
                } else {
                    this.rulesData.push(ruleItem);
                }
            });
        } else {
            this.rulesData = this.sitecoreData.rules;
        }
    }
    ngOnDestroy() {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
    }
    changeCollapseState() {
        this.isVisible = !this.isVisible;
    }
    ExpansionState() {
        if (!this.isCollapsible) {
            this.isExpansionDisabled = 'disabled-pointer';
        }
    }
}
