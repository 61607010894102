import { Component, EventEmitter, Input, OnInit, Output, booleanAttribute, numberAttribute } from '@angular/core';

import { DsButton, DsButtonKind, DsButtonSize, DsButtonVariant } from '@frontend/ui/button';

import { DesignSystemEnablerService } from '../../../shared/services/ds-enabler.service';
import { DecoratorClassDirective } from '../../directives/decorator-class.directive';
import { CapitalizePipe } from '../../pipes/capitalize.pipe';

@Component({
    selector: 'bg-custombutton',
    templateUrl: 'custombutton.component.html',
    standalone: true,
    imports: [DsButton, DecoratorClassDirective, CapitalizePipe],
})
export class CustomButtonComponent implements OnInit {
    @Input() size: DsButtonSize = 'large';
    @Input({ transform: booleanAttribute }) inverse = false;
    @Input() variant: DsButtonVariant = 'filled';
    @Input() kind: DsButtonKind = 'primary';
    @Input() button_text: string = '';
    @Input() disabled: boolean = false;
    @Output() clickEmit = new EventEmitter();
    isLegacy: boolean = false;
    // Color customisation properties, experimental, start
    @Input() customBgColor: string = '';
    @Input() customTextColor: string = '';
    // Color customisation properties, experimental, end
    @Input() customClass: string = '';
    // Legacy properties
    @Input({ transform: numberAttribute }) ctaWidth: number | undefined;
    @Input() customStyle: Object;
    constructor(private designSystemEnablerService: DesignSystemEnablerService) {}
    ngOnInit() {
        this.isLegacy = this.designSystemEnablerService.isEnabled();
    }
}

export { DsButtonKind, DsButtonSize, DsButtonVariant };
