<!--<div class="select-dropdown">-->
<div class="dd-container">
    <div class="roomContainer">
        <div name="dditems" class="dd-control-container" (click)="toggle()" tabindex="0" #displayLabel>
            <span class="dd-display-item" role="textbox">
                <img alt="" *ngIf="image" [src]="image" />&nbsp;
                <span *ngIf="!showIconImage">
                    <span *ngIf="icon" [ngClass]="'bingo-type-' + icon"> {{ icon }} </span> </span
                >&nbsp; <img alt="" *ngIf="showIconImage" class="icon-image" src="{{ iconimage }}" />&nbsp;
                <span *ngIf="IsDateFormat; then DateFormat; else NoDateFormat"></span>
                <ng-template #DateFormat>
                    <span [ngClass]="styleClassVar">{{ display | date: format }}</span>
                </ng-template>
                <ng-template #NoDateFormat>
                    <span [ngClass]="styleClassVar">{{ display }}</span>
                </ng-template>

                <i class="arrow-down"></i
            ></span>
        </div>
        <div class="dd-list-container" [hidden]="isListHide">
            <div class="dd-items-container">
                <div
                    #listItems
                    [ngClass]="{ 'dd-active dd-hightlight-item': isSelected(item) }"
                    class="dd-list-item"
                    *ngFor="let item of items"
                    (click)="onItemSelect(item)"
                    [attr.data-dd-value]="stringify(item)"
                    (mouseover)="onHover($event)"
                    (mouseout)="onHover($event)">
                    <li>
                        <img alt="" *ngIf="item?.image != null" [src]="item?.image" />&nbsp;
                        <span *ngIf="!showIconImage">
                            <span *ngIf="item?.icon != null" [ngClass]="'bingo-type-' + item?.icon"> {{ item?.icon }} </span>&nbsp;
                        </span>
                        <img alt="" *ngIf="showIconImage" class="icon-image" src="{{ item.iconimage }}" />&nbsp;
                        <span *ngIf="IsDateFormat; then DateFormat; else NoDateFormat"></span>
                        <ng-template #DateFormat>
                            <span [ngClass]="styleClassVar" *ngIf="item?.display">{{ item?.display | date: format }}</span>
                        </ng-template>
                        <ng-template #NoDateFormat>
                            <span [ngClass]="styleClassVar">{{ item?.display }}</span>
                        </ng-template>
                    </li>
                </div>
            </div>
        </div>
    </div>
</div>

<!--</div>-->
