<form [formGroup]="dropdownForm">
    <div class="pbFilter dropdown-list">
        <div class="game-dropdown">
            <span class="dd-name">{{ prebuynavcolitems.columns.roomDropdownText }}</span>
            <bg-select-dropdown
                *ngIf="dataLoaded"
                class="roomItem"
                name="SelectedRoomId"
                formControlName="SelectedRoomId"
                [ngModel]="SelectedRoomId"
                (onSelectChange)="onRoomChanged($event)"
                [list]="roomList"
                [styleClassVar]="'RoomStylesOnly'">
            </bg-select-dropdown>
        </div>
        <div class="date-dropdown">
            <span class="dd-name">{{ prebuynavcolitems.columns.dateDropdownText }}</span>
            <bg-select-dropdown
                *ngIf="dataLoaded"
                class="dateItem"
                name="TodayDate"
                formControlName="TodayDate"
                [ngModel]="TodayDate"
                (onSelectChange)="onDateChanged($event)"
                [DateFormat]="dateFormat"
                [list]="dateList"
                [styleClassVar]="'DateStylesOnly'">
            </bg-select-dropdown>
        </div>
        <div class="time-dropdown">
            <span class="dd-name">{{ prebuynavcolitems.columns.timeDropdownText }}</span>
            <bg-select-dropdown
                *ngIf="dataLoaded"
                class="timeItem"
                name="SelectedTime"
                formControlName="SelectedTime"
                (onSelectChange)="onTimeChanged($event)"
                [ngModel]="SelectedTime"
                [list]="timeList"
                [styleClassVar]="'TimeStylesOnly'">
            </bg-select-dropdown>
        </div>
    </div>
</form>
<div class="prebuy-toastr-message" *ngIf="!isLoading && isPreBuyBonusDetails && isGamesAvailCurrRoom">
    <vn-message-panel [scope]="PREBUY_SCOPE"></vn-message-panel>
</div>
<div #prebuyScroll id="scroll" class="tab-content tab-content-width" (scroll)="scrollHandler()">
    <img
        class="img-position"
        *ngIf="isLoading"
        id="loaderGif"
        alt="ajax-loader.gif"
        src="data:image/gif;base64,R0lGODlhKwALAPEAAAAAAP///3x8fP///yH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAKwALAAACMoSOCMuW2diD88UKG95W88uF4DaGWFmhZid93pq+pwxnLUnXh8ou+sSz+T64oCAyTBUAACH5BAkKAAAALAAAAAArAAsAAAI9xI4IyyAPYWOxmoTHrHzzmGHe94xkmJifyqFKQ0pwLLgHa82xrekkDrIBZRQab1jyfY7KTtPimixiUsevAAAh+QQJCgAAACwAAAAAKwALAAACPYSOCMswD2FjqZpqW9xv4g8KE7d54XmMpNSgqLoOpgvC60xjNonnyc7p+VKamKw1zDCMR8rp8pksYlKorgAAIfkECQoAAAAsAAAAACsACwAAAkCEjgjLltnYmJS6Bxt+sfq5ZUyoNJ9HHlEqdCfFrqn7DrE2m7Wdj/2y45FkQ13t5itKdshFExC8YCLOEBX6AhQAADsAAAAAAAAAAAA=" />
    <div *ngIf="!isLoading && isGamesAvailCurrRoom">
        <div
            class="prebuy-grid-container"
            [ngClass]="{
                'fab-container': isFabGrabRoom,
                'friend-container': isFriendsRoom,
                'player-container': isCappedRoom,
                'jackpot-disable': !isJackpotAvailable
            }">
            <div class="prebuy-grid-row prebuy-grid-header">
                <div class="pb-time">
                    <a class="prebuy-grid-header-cell" (click)="SortData('start_time')">
                        <div class="theme-calendar">
                            <span> {{ prebuynavcolitems.columns.timeText }} </span>
                        </div>
                        <i
                            [ngClass]="{
                                'arrow-up': SortingData['start_time'] == 'arrow-up',
                                'arrow-down': SortingData['start_time'] == 'arrow-down'
                            }"></i>
                    </a>
                </div>
                <div class="hide-title pb-title" *ngIf="!isFriendsRoom && !isFabGrabRoom">
                    <a class="prebuy-grid-header-cell" (click)="SortData('display_name')">
                        <div>{{ prebuynavcolitems.columns.titleText }}</div>
                        <i
                            [ngClass]="{
                                'arrow-up': SortingData['display_name'] == 'arrow-up',
                                'arrow-down': SortingData['display_name'] == 'arrow-down'
                            }"></i>
                    </a>
                </div>
                <div class="pb-team" *ngIf="isFriendsRoom">
                    <a class="prebuy-grid-header-cell">
                        <span class="pb-jackpotheader">{{ prebuynavcolitems.columns.teamText }}</span>
                        <!-- <i
              [ngClass]="{'arrow-up': SortingData['jackpot'] == 'arrow-up', 'arrow-down': SortingData['jackpot'] == 'arrow-down'}"></i> -->
                    </a>
                </div>
                <div class="hide-feature pb-feature">
                    <div class="prebuy-grid-header-cell">{{ prebuynavcolitems.columns.featureText }}</div>
                </div>
                <div class="pb-price multi-price">
                    <a class="prebuy-grid-header-cell" (click)="SortData('ticket_cost')">
                        <i [ngClass]="prebuyIconslist.columns.priceIcon"></i>{{ prebuynavcolitems.columns.priceText }}
                        <i
                            [ngClass]="{
                                'arrow-up': SortingData['ticket_cost'] == 'arrow-up',
                                'arrow-down': SortingData['ticket_cost'] == 'arrow-down'
                            }"></i>
                    </a>
                </div>
                <div class="pb-prize">
                    <div class="prebuy-grid-header-cell">
                        <a class="prebuy-grid-header-cell" (click)="SortData('winning_amount')">
                            <i [ngClass]="prebuyIconslist.columns.winPrizeIcon"></i>{{ prebuynavcolitems.columns.minPrizeText }}
                            <i
                                [ngClass]="{
                                    'arrow-up': SortingData['winning_amount'] == 'arrow-up',
                                    'arrow-down': SortingData['winning_amount'] == 'arrow-down'
                                }"></i>
                        </a>
                    </div>
                    <!-- <a class="prebuy-grid-header-cell" (click)="SortData('winning_amount')">
            <i class="theme-bingo-trophy"></i>Min Prize
            <i [ngClass]="{'arrow-up': SortingData['winning_amount'] == 'arrow-up', 'arrow-down': SortingData['winning_amount'] == 'arrow-down'}"></i>
          </a> -->
                </div>
                <div class="hide-jackpot pb-jackpot" *ngIf="!isFriendsRoom && !isFabGrabRoom && isJackpotAvailable">
                    <a class="prebuy-grid-header-cell" (click)="SortData('jackpot')">
                        <i [ngClass]="prebuyIconslist.columns.jackpotIcon"></i>
                        <span class="pb-jackpotheader">{{ prebuynavcolitems.columns.jackpotText }}</span>
                        <i [ngClass]="{ 'arrow-up': SortingData['jackpot'] == 'arrow-up', 'arrow-down': SortingData['jackpot'] == 'arrow-down' }"></i>
                    </a>
                </div>
                <div class="pb-purchased">
                    <a class="prebuy-grid-header-cell" (click)="SortData('purchased_count')">
                        <i [ngClass]="prebuyIconslist.columns.PurchaseIcon"></i>
                        <span class="pb-purchasedheader">{{ prebuynavcolitems.columns.purchasedText }}</span>
                        <i
                            [ngClass]="{
                                'arrow-up': SortingData['purchased_count'] == 'arrow-up',
                                'arrow-down': SortingData['purchased_count'] == 'arrow-down'
                            }"></i>
                    </a>
                </div>
                <div class="players-column" *ngIf="isCappedRoom">
                    <!-- <i class="theme-icons-bingo-players"></i> -->
                    <i [ngClass]="prebuyIconslist.columns.cappedPlayerIcon"></i>
                    <!-- <span class="pb-players prebuy-grid-header-cell">PLAYERS</span> -->
                    <span class="pb-players prebuy-grid-header-cell">{{ prebuynavcolitems.columns.cappedPlayerText }}</span>
                </div>
                <div class="pb-fab" *ngIf="isFabGrabRoom">
                    <div class="prebuy-grid-header-cell">{{ prebuynavcolitems.columns.toptailText }}</div>
                </div>
                <div class="pb-high">
                    <!-- <a *ngIf="prebuynavcolitems.columns.buyingText" class="prebuy-grid-header-cell"
                        (click)="SortData('current_purchased_count')">
                        <span class="pb-purchasedheader">{{prebuynavcolitems.columns.buyingText}}</span>
                        <i
                            [ngClass]="{'arrow-up': SortingData['current_purchased_count'] == 'arrow-up', 'arrow-down': SortingData['current_purchased_count'] == 'arrow-down'}"></i>
                    </a> -->
                    <div class="prebuy-grid-header-cell">
                        <i [ngClass]="prebuyIconslist.columns.ticketsIcon"></i>
                        <span class="pb-tickets prebuy-grid-header-cell">{{ prebuynavcolitems.columns.ticketsText }}</span>
                    </div>
                </div>
                <!-- <div class="pb-slider">&nbsp;
                </div>
                <div class="pb-btns">&nbsp;
                </div> -->
            </div>
            <div *ngIf="isGamesAvailCurrRoom">
                <div *ngFor="let Game of Games; let row_no = index">
                    <div *ngIf="isFriendsRoom">
                        <div
                            class="teamList team-dd-mob"
                            [ngStyle]="{
                                'color': Game.game_context.TeamBingo.current_team != null ? 'white' : '',
                                'background-color':
                                    Game.game_context.TeamBingo.current_team != null ? teamColors[Game.game_context.TeamBingo.current_team] : ''
                            }"
                            (click)="selectTeam(row_no)">
                            <span class="teamName" *ngIf="Game.game_context.TeamBingo.current_team == null">Select your team</span>
                            <span class="teamName" *ngIf="Game.game_context.TeamBingo.current_team != null">{{
                                Game.game_context.TeamBingo.current_team | lowercase
                            }}</span>
                            <span class="teamddArrow theme-arrow-down"></span>
                        </div>
                    </div>

                    <div
                        class="prebuy-grid-row prebuy-grid-element"
                        [ngClass]="
                            isFabGrabRoom
                                ? Game.game_context.FomoBingo.fomo_numbers != null && Game.game_context.FomoBingo.fomo_numbers.length > 0
                                    ? 'fabgrab-room room-selcted'
                                    : 'fabgrab-room room-selcted fabgrab-room'
                                : ''
                        ">
                        <div
                            class="pb-progress-bar-sec"
                            [ngClass]="{ 'full-color': Game.max_player_limit == Game.number_of_players }"
                            *ngIf="isCappedRoom">
                            <div class="user-icon">
                                <span [ngClass]="prebuyIconslist.columns.cappedPlayerIcon"></span>
                                <!-- <span class="span2 theme-bingo-profile"></span> -->
                            </div>
                            <div class="pb-progress-bar">
                                <progress [max]="Game.max_player_limit" [value]="Game.number_of_players">
                                    <div class="progress-bar">
                                        <span style="width: 75%">Progress: 20%</span>
                                    </div>
                                </progress>
                            </div>
                            <div class="pb-avail-tickets">{{ Game.number_of_players }}/{{ Game.max_player_limit }}</div>
                        </div>
                        <div class="pb-info-sec">
                            <div bgTimer class="starttime pb-mob-time">
                                <span class="timeCls 2ndCls" [attr.data-time]="Game.start_time">
                                    <!-- <bg-schedule-timer [timerinput]="Game.start_time"></bg-schedule-timer>[isprebuy]="true" -->
                                    <span
                                        [className]="
                                            prebuycloseApply.indexOf(Game.DisplayTime) > -1 ? 'prebuy-close-active' : 'prebuy-close-inactive'
                                        ">
                                        <span class="hide-title" *ngIf="showGameDate"> {{ Game.DisplayDate }}&nbsp;&nbsp;</span>{{ Game.DisplayTime }}
                                    </span>
                                    <input type="hidden" value="{{ Game.start_time }}" />
                                </span>
                            </div>
                            <div class="hide-title pb-title" *ngIf="!isFriendsRoom && !isFabGrabRoom">
                                <div class="game-name">{{ Game.display_name }}</div>
                            </div>
                            <div class="pb-team" *ngIf="isFriendsRoom">
                                <div
                                    class="teamList team-dd"
                                    [ngStyle]="{
                                        'color': Game.game_context.TeamBingo.current_team != null ? 'white' : '',
                                        'background-color':
                                            Game.game_context.TeamBingo.current_team != null
                                                ? teamColors[Game.game_context.TeamBingo.current_team]
                                                : ''
                                    }"
                                    (click)="selectTeam(row_no)">
                                    <span class="teamName" *ngIf="Game.game_context.TeamBingo.current_team == null">Select team</span>
                                    <span class="teamName" *ngIf="Game.game_context.TeamBingo.current_team != null">{{
                                        Game.game_context.TeamBingo.current_team | lowercase
                                    }}</span>
                                    <span class="teamddArrow theme-arrow-down"></span>
                                </div>
                            </div>
                            <div class="hide-feature pb-feature">
                                <span *ngFor="let item of Game.features" class="prebuy-feature-icons">
                                    <span *ngIf="item == 'cashonly'" class="cash-only"> {{ prebuynavcolitems.columns.featureCashonlyText }}</span>
                                    <span *ngIf="item == 'linked_game'">
                                        <i [ngClass]="prebuyIconslist.columns.linkedIcon"></i>
                                    </span>
                                    <span class="new-only" *ngIf="item == 'new'">{{ prebuynavcolitems.columns.featureNewText }}</span>
                                    <bg-feature-icon [feature]="item"></bg-feature-icon>
                                </span>
                                <span *ngIf="Game.features.length == 0">-</span>
                                <!-- <span *ngFor="let item of Game.game_category;let i = index" [ngSwitch]="item">
                <span class="new-only" *ngIf="(i%2==0)">{{item}}</span>
                <span class="cash-only" *ngIf="(i%2>0)">{{item}}</span>
                </span> -->
                            </div>
                            <div class="pb-price multi-price">
                                <div *ngIf="Game.ticket_cost.Cash && !Game.Free_Games">
                                    <i [ngClass]="prebuyIconslist.columns.cardIcon"></i>
                                    <span class="pb-ticketPrice" *ngIf="!Game.isMultistake && !Game.isSlingoMultiStake && Game.ticket_cost.Cash">{{
                                        Game.ticket_cost.Cash | currency: Game.currency
                                    }}</span>
                                    <span class="pb-ticketPrice" *ngIf="Game.isMultistake && Game.game_context?.VariableCardStake?.price != null">
                                        {{
                                            Game.game_context.VariableCardStake.price[Game.game_context.VariableCardStake.ticket_currency_key]
                                                | currency: Game.currency
                                        }}
                                    </span>
                                    <span
                                        class="pb-ticketPrice multi-price-btn"
                                        (click)="selectPrice(row_no)"
                                        *ngIf="Game.isMultistake && Game.game_context?.VariableCardStake?.price == null">
                                        {{
                                            Game.game_context?.VariableCardStake?.ticket_prices_player_currency[Game.selected_price]
                                                | currency: Game.currency
                                        }}
                                        <span class="theme-arrow-down priceddArrow"></span>
                                    </span>
                                    <span
                                        class="pb-ticketPrice"
                                        *ngIf="Game.isSlingoMultiStake && Game.game_context?.SlingoMultiStake?.price != null">
                                        {{
                                            Game.game_context?.SlingoMultiStake?.price[Game.game_context.SlingoMultiStake.ticket_currency_key]
                                                | currency: Game.currency
                                        }}
                                    </span>
                                    <span
                                        class="pb-ticketPrice multi-price-btn"
                                        (click)="selectPrice(row_no)"
                                        *ngIf="Game.isSlingoMultiStake && Game.game_context?.SlingoMultiStake?.price == null">
                                        {{
                                            Game.game_context?.SlingoMultiStake?.price_points_config_in_player_currency[Game.selected_price].price
                                                | currency: Game.currency
                                        }}
                                        <span class="theme-arrow-down priceddArrow"></span>
                                    </span>
                                </div>
                                <div>
                                    <span class="pb-ticketPrice" *ngIf="Game.Free_Games">
                                        {{ FreeGamesPriceText }}
                                    </span>
                                </div>
                            </div>

                            <div class="pb-prize">
                                <i [ngClass]="prebuyIconslist.columns.winPrizeIcon"></i>
                                <span
                                    *ngIf="
                                        (Game.features
                                            ? Game.features.indexOf('physical_prize') == -1 && !(enableBonusImplementation && Game.bonus_info)
                                            : true) &&
                                        Game.winning_amount &&
                                        !Game.isMultistake &&
                                        !Game.isSlingoMultiStake &&
                                        !(enableBonusImplementation && Game.bonus_info)
                                    ">
                                    {{ Game.winning_amount.Cash | currency: Game.currency }}
                                </span>
                                <span *ngIf="(Game.isMultistake || Game.isSlingoMultiStake) && !(enableBonusImplementation && Game.bonus_info)">
                                    {{ Game.selected_win | currency: Game.currency }}
                                </span>
                                <span
                                    *ngIf="
                                        Game.features &&
                                        Game.features.indexOf('physical_prize') > -1 &&
                                        !(enableBonusImplementation && Game.bonus_info)
                                    ">
                                    {{ physicalPrizeText }}</span
                                >
                                <span *ngIf="Game.features && enableBonusImplementation && Game.bonus_info"> {{ bonusPrizeText }}</span>
                            </div>
                            <div class="hide-jackpot pb-jackpot" *ngIf="!isFriendsRoom && !isFabGrabRoom && isJackpotAvailable">
                                <span class="pjp-value">
                                    <span *ngFor="let pjpvalue of Game?.jackpot" class="pjp-values">{{
                                        pjpvalue.amount.Cash | currency: Game.currency
                                    }}</span>
                                </span>
                                <span *ngIf="Game?.jackpot?.length == 0">{{ prebuynavcolitems.columns.noPJPValueText }}</span>
                            </div>
                            <div class="free-games" *ngIf="Game.Free_Games">
                                {{ freeGamesText }}
                            </div>
                            <div class="pb-purchased">
                                <div *ngIf="Game.maximum_tickets !== Game.purchased_count; else disabled_purchase">
                                    <input
                                        class="pb-tickets-value"
                                        type="tel"
                                        [ngModel]="currentgame_val"
                                        min="0"
                                        max="{{ Game.maximum_tickets - Game.purchased_count }}"
                                        (focus)="clearValue($event)"
                                        (input)="onChange($event, row_no)"
                                        (blur)="onChange($event, row_no)"
                                        value="{{ Game.current_purchased_count }}"
                                        (keypress)="keyHandler($event)"
                                        [class.redCls]="'ture'"
                                        [class.txtborderRed]="Game.isRedClsEnabled"
                                        [class.txtborderGreen]="!Game.isRedClsEnabled && Game.current_purchased_count > 0"
                                        [autofocus]="Game.isRedClsEnabled"
                                        id="{{ row_no }}"
                                        [ngStyle]="{
                                            'border-color':
                                                Game.game_context.TeamBingo && Game.game_context.TeamBingo.current_team != null
                                                    ? teamColors[Game.game_context.TeamBingo.current_team]
                                                    : ''
                                        }" />

                                    <div class="pb-mob-totaltickets">/{{ Game.maximum_tickets - Game.purchased_count }}</div>
                                </div>
                                <ng-template #disabled_purchase>
                                    <div>
                                        <input
                                            class="pb-tickets-value"
                                            type="tel"
                                            [ngModel]="Game.purchased_count"
                                            min="0"
                                            max="{{ Game.maximum_tickets - Game.purchased_count }}"
                                            (focus)="clearValue($event)"
                                            (input)="onChange($event, row_no)"
                                            (blur)="onChange($event, row_no)"
                                            value="{{ Game.purchased_count }}"
                                            (keypress)="keyHandler($event)"
                                            [disabled]="true" />
                                        <div class="pb-mob-totaltickets">/{{ Game.maximum_tickets }}</div>
                                    </div>
                                </ng-template>
                                <div class="pb-totaltickets">
                                    <span>{{ Game.purchased_count }}</span
                                    ><span class="pb-max-tickets"> / {{ Game.maximum_tickets }}</span>
                                </div>
                            </div>
                            <div
                                class="players-column"
                                *ngIf="isCappedRoom"
                                [ngClass]="{ 'full-color': Game.max_player_limit == Game.number_of_players }">
                                <div class="row">
                                    <progress [max]="Game.max_player_limit" [value]="Game.number_of_players">
                                        <div class="progress-bar">
                                            <span style="width: 75%">Progress: 20%</span>
                                        </div>
                                    </progress>
                                    <span class="value-feed">{{ Game.number_of_players }}/ {{ Game.max_player_limit }}</span>
                                </div>
                            </div>
                            <div class="pb-fab" *ngIf="isFabGrabRoom">
                                <div
                                    class="teamList fb-list team-fb"
                                    [ngClass]="
                                        Game.game_context.FomoBingo.fomo_numbers && Game.game_context.FomoBingo.fomo_numbers.length > 0
                                            ? 'fab-selected'
                                            : ''
                                    ">
                                    <span class="teamName">{{ prebuynavcolitems.columns.topnTailNoText }}</span>
                                    <span *ngIf="Game.maximum_tickets > Game.purchased_count" class="numberSelect" (click)="selectNumbers(row_no)">{{
                                        Game.game_context.FomoBingo.fomo_numbers && Game.game_context.FomoBingo.fomo_numbers.length > 0
                                            ? prebuynavcolitems.columns.editText
                                            : prebuynavcolitems.columns.selectText
                                    }}</span>
                                    <span *ngIf="Game.maximum_tickets == Game.purchased_count" class="numberSelect locked">
                                        {{ prebuynavcolitems.columns.lockedText }}</span
                                    >
                                    <span
                                        class="selectCount"
                                        *ngIf="Game.game_context.FomoBingo.fomo_numbers && Game.game_context.FomoBingo.fomo_numbers.length > 0"
                                        >{{ Game.game_context.FomoBingo.fomo_numbers.length }}</span
                                    >
                                </div>
                            </div>
                            <div class="pb-high" *ngIf="!Game.Free_Games">
                                <!-- <div class="pb-inner-high" *ngIf="Game.maximum_tickets > Game.purchased_count">
                                    <div class="btnToggle pb-tickets" [ngClass]="{'multi-price':(Game.maximum_tickets == 1)}">
                                        <bg-btn-toggle id="{{row_no}}" min="0"
                                            max="{{Game.maximum_tickets - Game.purchased_count}}"
                                            [isMultistake]="(Game.maximum_tickets == 1)"
                                            [value]="Game.current_purchased_count == null ? 0 : Game.current_purchased_count"
                                            (onSelected)="Selected($event)"></bg-btn-toggle>
                                    </div>
                                    <div class="slider pb-slider"
                                        [ngStyle]="{ 'display': (Game.maximum_tickets == 1)?'none':'block'}">
                                        <bg-slider id="{{row_no}}" min="0"
                                            max="{{Game.maximum_tickets - Game.purchased_count}}"
                                            [value]="Game.current_purchased_count == null ? 0 : Game.current_purchased_count"
                                            (onSelected)="Selected($event)"></bg-slider>
                                    </div>
                                    <div class="btnSpin pb-btns">
                                        <bg-touch-spin id="{{row_no}}"
                                            [color]="Game.game_context.TeamBingo && Game.game_context.TeamBingo.current_team != null?teamColors[Game.game_context.TeamBingo.current_team]:''"
                                            min="0" max="{{Game.maximum_tickets - Game.purchased_count}}"
                                            [value]="Game.current_purchased_count == null ? 0 : Game.current_purchased_count"
                                            (onSelected)="Selected($event)"></bg-touch-spin>
                                    </div>
                                </div>-->
                                <div
                                    class="pb-max"
                                    *ngIf="Game.maximum_tickets == Game.purchased_count && Game.max_player_limit != Game.number_of_players">
                                    <span
                                        class="max-purchased"
                                        [ngStyle]="{
                                            'background-color':
                                                Game.game_context.TeamBingo && Game.game_context.TeamBingo.current_team != null
                                                    ? teamColors[Game.game_context.TeamBingo.current_team] + '10'
                                                    : ''
                                        }"
                                        ><i class="theme-not-available"></i>{{ maximumTicketsReached }}</span
                                    >
                                </div>
                                <div class="pb-max" *ngIf="Game.max_player_limit == Game.number_of_players">
                                    <span
                                        class="max-purchased"
                                        [ngStyle]="{
                                            'background-color':
                                                Game.game_context.TeamBingo && Game.game_context.TeamBingo.current_team != null
                                                    ? teamColors[Game.game_context.TeamBingo.current_team] + '10'
                                                    : ''
                                        }"
                                        ><i class="theme-not-available"></i>Room Full</span
                                    >
                                </div>
                                <!-- <bg-slider-ang></bg-slider-ang> -->
                                <bg-prebuy-slider
                                    [id]="row_no"
                                    [value]="Game.current_purchased_count == null ? 0 : Game.current_purchased_count"
                                    [min]="0"
                                    [maxium]="Game.maximum_tickets - Game.purchased_count"
                                    (onSelected)="Selected($event)"
                                    [ngStyle]="{
                                        display:
                                            Game.maximum_tickets == Game.purchased_count || Game.max_player_limit == Game.number_of_players
                                                ? 'none'
                                                : 'block'
                                    }">
                                </bg-prebuy-slider>
                            </div>
                            <!-- <div class="pb-fab" *ngIf="isFabGrabRoom">
                                <div class="teamList fb-list team-fb"
                                    [ngClass]="(Game.game_context.FomoBingo.fomo_numbers && Game.game_context.FomoBingo.fomo_numbers.length > 0)?'fab-selected':''">
                                    <span class="teamName">{{prebuynavcolitems.columns.topnTailNoText}}</span>
                                    <span *ngIf="Game.maximum_tickets > Game.purchased_count" class="numberSelect"
                                        (click)="selectNumbers(row_no)">{{Game.game_context.FomoBingo.fomo_numbers && Game.game_context.FomoBingo.fomo_numbers.length > 0 ? prebuynavcolitems.columns.editText
                                        : prebuynavcolitems.columns.selectText}}</span>
                                    <span *ngIf="Game.maximum_tickets == Game.purchased_count" class="numberSelect locked">
                                        {{prebuynavcolitems.columns.lockedText}}</span>
                                    <span class="selectCount"
                                        *ngIf="Game.game_context.FomoBingo.fomo_numbers&&Game.game_context.FomoBingo.fomo_numbers.length>0">{{Game.game_context.FomoBingo.fomo_numbers.length}}</span>
                                </div>
                            </div> -->
                        </div>
                        <div class="pb-fab-mobile-container" *ngIf="isFabGrabRoom">
                            <div
                                class="teamList fb-list team-fb"
                                [ngClass]="
                                    Game.game_context.FomoBingo.fomo_numbers && Game.game_context.FomoBingo.fomo_numbers.length > 0
                                        ? 'fab-selected'
                                        : ''
                                ">
                                <span class="teamName">{{ prebuynavcolitems.columns.topnTailNoText }}</span>
                                <span *ngIf="Game.maximum_tickets > Game.purchased_count" class="numberSelect" (click)="selectNumbers(row_no)">{{
                                    Game.game_context.FomoBingo.fomo_numbers && Game.game_context.FomoBingo.fomo_numbers.length > 0
                                        ? prebuynavcolitems.columns.editText
                                        : prebuynavcolitems.columns.selectText
                                }}</span>
                                <span *ngIf="Game.maximum_tickets == Game.purchased_count" class="numberSelect locked">
                                    {{ prebuynavcolitems.columns.lockedText }}</span
                                >
                                <span
                                    class="selectCount"
                                    *ngIf="Game.game_context.FomoBingo.fomo_numbers && Game.game_context.FomoBingo.fomo_numbers.length > 0"
                                    >{{ Game.game_context.FomoBingo.fomo_numbers.length }}</span
                                >
                            </div>
                        </div>
                        <div
                            class="purchased-comtainer"
                            *ngIf="
                                Game.maximum_tickets == Game.purchased_count && Game.max_player_limit != Game.number_of_players && !Game.Free_Games
                            ">
                            <span
                                class="max-purchased"
                                [ngStyle]="{
                                    'background-color':
                                        Game.game_context.TeamBingo && Game.game_context.TeamBingo.current_team != null
                                            ? teamColors[Game.game_context.TeamBingo.current_team] + '10'
                                            : ''
                                }"
                                ><i class="theme-not-available"></i>{{ maximumTicketsReached }}</span
                            >
                        </div>
                        <div class="purchased-comtainer" *ngIf="Game.max_player_limit == Game.number_of_players">
                            <span
                                class="max-purchased"
                                [ngStyle]="{
                                    'background-color':
                                        Game.game_context.TeamBingo && Game.game_context.TeamBingo.current_team != null
                                            ? teamColors[Game.game_context.TeamBingo.current_team] + '10'
                                            : ''
                                }"
                                ><i class="theme-not-available"></i>Room Full</span
                            >
                        </div>
                        <div class="mobile-tickets-container" *ngIf="!Game.Free_Games">
                            <bg-prebuy-slider
                                [id]="row_no"
                                [value]="Game.current_purchased_count == null ? 0 : Game.current_purchased_count"
                                [min]="0"
                                [maxium]="Game.maximum_tickets - Game.purchased_count"
                                (onSelected)="Selected($event)"
                                [ngStyle]="{
                                    display:
                                        Game.maximum_tickets == Game.purchased_count || Game.max_player_limit == Game.number_of_players
                                            ? 'none'
                                            : 'block'
                                }">
                            </bg-prebuy-slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nodata" *ngIf="!isGamesAvailCurrRoom && !isLoading">
        <div class="prebuy-grid-container">
            <vn-message-panel [scope]="PREBUY_NODATA_SCOPE"></vn-message-panel>
        </div>
        <!-- <span>{{prebuynavcolitems.columns.gamesAvailCurrRoomText}}</span> -->
    </div>
</div>
<!-- <div *ngIf="prebuy_route_status">
  <bg-alert-message></bg-alert-message>
  <bg-confirm-message></bg-confirm-message>
</div> -->

<!-- <div *ngIf="selectTeamModal" class="teamSelectModal-overlay"></div> -->
<!-- <div *ngIf="selectTeamModal" class="teamSelectModal"> -->
<!-- <div class="syt">Select your team <i class="theme-arrow-down" (click)="onTeamSelected('')"></i></div> -->
<!-- <ul> -->
<!-- <li *ngFor="let team of currentGame.game_context.TeamBingo.teams" (click)="onTeamSelected(team)" -->
<!-- [ngStyle]="{ 'color': currentGame.game_context.TeamBingo.current_team == team? 'white':teamColors[team],'background-color': currentGame.game_context.TeamBingo.current_team == team? teamColors[team]:'' }"> -->
<!-- {{team| lowercase}}</li> -->
<!-- <li (click)="onRandomSelected()"
      [ngStyle]="{ 'color':currentGame.game_context.TeamBingo.current_team == randomTeamText? 'white':teamColors[randomTeamText],'background-color': currentGame.game_context.TeamBingo.current_team == randomTeamText? teamColors[randomTeamText]:'' }">
      {{randomTeamText| lowercase}}</li> -->
<!-- </ul> -->
<!-- </div> -->
<!-- <div *ngIf="selectNumbersModal" class="teamSelectModal-overlay"></div> -->
<!-- <div *ngIf="selectNumbersModal" class="teamSelectModal fab">
    <div class="head-part">{{fabgrabPopupHeader}}<i class="theme-ex" (click)="onNumbersClose()"></i></div>
    <div class="sel-numbers">
        <p>{{fabgrabPopupMessage}}</p>
        <div class="specs">
            <span class="lucky-dip" [class.backGround]="currentGame.game_context.FomoBingo.fomo_numbers.length>0"
                (click)="selectPattern('')">{{prebuynavcolitems.columns.luckyDipText}}</span>
             <span (click)="selectPattern('X')">X</span>
      <span (click)="selectPattern('L')">L</span>
      <span (click)="selectPattern('U')">U</span> -->
<!-- <span class="clear" (click)="clearNumbers()">{{prebuynavcolitems.columns.clearText}}</span>
        </div>
        <span *ngFor="let number of numbers" (click)="onNumberSelect(number)" class="numballs"
            [ngClass]="currentGame.game_context.FomoBingo.fomo_numbers.indexOf(number) > -1 ? 'active':''">
            {{number}}</span>
    </div>
    <div class="select-btn">
        <span class="select" (click)="onNumbersClose()">{{prebuynavcolitems.columns.selectText}}</span>
    </div>
</div> -->
<!-- <div *ngIf="selectPriceModal" class="teamSelectModal-overlay"></div> -->
<!-- <div *ngIf="selectPriceModal" class="teamSelectModal fab fluffy">
    <div class="fluffy-title">{{currentGame.isMultistake ? multistakeContent?.MultiStakePopupText : multistakeContent?.SlingoMultiStakePopupText }}<i class="theme-ex fluffy-close" (click)="closePrice()"></i>
    </div>
    <div class="mid-section">
        <div class="multi_price_prize">
            <span class="bingo-feature-icon feature-fluffy-bingo">
                <i class="theme-multistake-feature"></i>
            </span>
            <span class="multistake-content">{{multistakeContent?.MultiStake}}</span>
            <i class="theme-info" (click)="toggleSelectPriceInfo()"></i>
            <span class="right-value">{{currentGame.selected_win  | currency: currentGame.currency }}</span>
            <i [ngClass]="enableNewPrebuyIcons ? 'theme-bingo-gala-trophy' : 'theme-bingo-trophy'"></i>
        </div>
        <bg-slider-ang (ValueChanged)="onPriceSelected($event)" [currency]="currentGame.currency"
            [values]="currentGame.isMultistake ? currentGame.game_context?.VariableCardStake?.ticket_prices_player_currency : currentGame.game_context?.SlingoMultiStake?.price_points_config_in_player_currency"
            [currentindex]="currentGame.selected_price" [isSlingoMultiStake]="currentGame.isSlingoMultiStake">
        </bg-slider-ang>
    </div>
    <div class="bottom-section">
        <span (click)="closePrice()" class="fluffy-ok">{{multistakeContent?.ok}}</span>
    </div>
</div> -->
<!-- <div *ngIf="selectPriceInfo" class="teamSelectModal-overlay overlay-top"></div> -->
<!-- <div *ngIf="selectPriceInfo && currentGame.isMultistake" class="teamSelectModal fab fluffyInfo">
    <div class="fluffy-title-block">
        <i class="theme-multistake-feature"></i>
        <span class="fluffy-title">{{multistakeContent?.ChartText}}</span>
        <i class="theme-ex fluffy-close" (click)="toggleSelectPriceInfo()"></i>
    </div>
    <div class="multi_price_prize_table">
        <table>
            <tr class="t-head">
                <td>{{multistakeContent?.Stake}}</td>
                <td *ngFor="let key of currentGame.keys">{{key}}</td>
                <td>{{multistakeContent?.Total}}</td>
            </tr>
            <tr *ngFor="let winning of currentGame?.game_context?.VariableCardStake?.winning_amount;; let row_no = index;"
                [ngClass]="currentGame.selected_price == row_no? 'active-row':''">
                <td>{{winning.price| currency: currentGame.currency}}</td>
                <td *ngFor="let key of currentGame.keys">
                    {{winning.per_pool_win_amount_split[key][currentGame.game_context.VariableCardStake.ticket_currency_key]| currency: currentGame.currency}}
                </td>
                <td>
                    {{winning.winning_amount[currentGame.game_context.VariableCardStake.ticket_currency_key]| currency: currentGame.currency}}
                </td>
            </tr>
        </table>
    </div>
    <div class="content-container">
        <h4>{{multistakeContent?.ParaText1}}</h4>
        <p>{{multistakeContent?.ParaText2}}
            <br />{{multistakeContent?.ParaText3}}</p>
    </div>
</div>
<div *ngIf="selectPriceInfo && currentGame.isSlingoMultiStake" class="teamSelectModal fab fluffyInfo">
    <div class="fluffy-title-block">
        <i class="theme-multistake-feature"></i>
        <span class="fluffy-title">{{multistakeContent?.ChartText}}</span>
        <i class="theme-ex fluffy-close" (click)="toggleSelectPriceInfo()"></i>
    </div>
    <div class="multi_price_prize_table">
        <table>
            <tr class="t-head">
                <td>{{multistakeContent?.Stake}}</td>
                <td *ngFor="let key of currentGame.keys">{{key}}</td>
                <td>{{multistakeContent?.Total}}</td>
            </tr>
            <tr *ngFor="let priceList of currentGame?.game_context?.SlingoMultiStake?.price_points_config_in_player_currency;; let row_no = index;"
                [ngClass]="currentGame.selected_price == row_no? 'active-row':''">
                <td>{{priceList.price| currency: currentGame.currency}}</td>
                <td>
                    {{currentGame.winning_amount.Cash| currency: currentGame.currency}}
                </td>
                <td>
                    {{currentGame.winning_amount.Cash| currency: currentGame.currency}}
                </td>
            </tr>
        </table>
    </div>
    <div class="content-container">
        <h4>{{multistakeContent?.SlingoParaText1}}</h4>
        <p>{{currentGame?.slingoSecondParaText}}
            <br />{{multistakeContent?.SlingoParaText3}}</p>
    </div>
</div> -->
