import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { SwiperComponent } from '@frontend/vanilla/shared/swiper';

import { BingoSlotRacesLeaderboardModule } from '../leaderboard/bingo-leaderboard.module';
import { BingoSlotRacesInfoModule } from '../race-info/bingo-race-info.module';
import { BingoSlotRacesCardsComponent } from './bingo-cards-wrapper.component';
import { BingoSlotRaceCardModule } from './card/bingo-card.module';

@NgModule({
    imports: [CommonModule, SwiperComponent, BingoSlotRaceCardModule, BingoSlotRacesInfoModule, BingoSlotRacesLeaderboardModule],
    declarations: [BingoSlotRacesCardsComponent],
    exports: [BingoSlotRacesCardsComponent],
    providers: [],
})
export class BingoSlotRacesCardsModule {
    public static forRoot(): ModuleWithProviders<BingoSlotRacesCardsModule> {
        return {
            ngModule: BingoSlotRacesCardsModule,
            providers: [],
        };
    }
}
