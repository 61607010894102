import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gameListSort',
})
export class GameListSortPipe implements PipeTransform {
    transform(gameList: Array<any>, selectedSortOption: any, pinnedRoomId: number[]): Array<any> {
        // if (!pinnedRoomId) {
        //     pinnedRoomId = 0;
        // }
        if (gameList && selectedSortOption && selectedSortOption.sortKey && gameList.length > 0) {
            gameList.sort((a: any, b: any) => {
                const propertyA: number | string | Date | any = this.getProperty(a, selectedSortOption.sortKey);
                const propertyB: number | string | Date | any = this.getProperty(b, selectedSortOption.sortKey);
                if (selectedSortOption.sortKey == '') {
                    return 0;
                }
                if (typeof propertyA === 'string') {
                    return propertyA.localeCompare(propertyB);
                } else if (selectedSortOption.sortKey === 'status.state_end_time') {
                    const ta = this.getTimeVal(propertyA);
                    const tb = this.getTimeVal(propertyB);
                    if (ta === 'started' || ta == undefined) {
                        return -1 * selectedSortOption.sortDirection;
                    } else if (tb == 'started' || tb == undefined) {
                        return 1 * selectedSortOption.sortDirection;
                    } else if (Number(this.convertTimeToSec(ta)) > Number(this.convertTimeToSec(tb))) {
                        return -1 * selectedSortOption.sortDirection;
                    } else {
                        return 1 * selectedSortOption.sortDirection;
                    }
                } else if (typeof propertyB === 'number') {
                    return this.sortNumber(propertyA, propertyB, selectedSortOption.sortDirection);
                } else if (typeof propertyA === 'object') {
                    let totalA = 0;
                    let totalB = 0;
                    for (let i = 0; i < propertyA.length; i++) {
                        totalA += propertyA[i].value.Cash_GBP;
                    }
                    for (let i = 0; i < propertyB.length; i++) {
                        totalB += propertyB[i].value.Cash_GBP;
                    }
                    return this.sortNumber(totalA, totalB, selectedSortOption.sortDirection);
                } else if (typeof propertyB === 'boolean' && propertyB == false) {
                    return -1 * selectedSortOption.sortDirection;
                } else if (typeof propertyB === 'boolean' && propertyB == true) {
                    return 1 * selectedSortOption.sortDirection;
                } else {
                    return 0;
                }
            });
            const item = gameList.filter((game) => {
                return pinnedRoomId.indexOf(game.id) > -1;
            });
            if (item.length > 0) {
                const pinnedIndex = gameList.indexOf(item[0]);
                gameList.splice(pinnedIndex, 1);
                gameList.unshift(item[0]);
            }
        }
        return gameList;
    }

    convertTimeToSec(ta: any): number {
        let timeinSec = 0;
        if (ta.indexOf('d') > 0) {
            timeinSec = ta.slice(0, ta.indexOf('d')) * 86400;
        }
        if (ta.indexOf('h') > 0) {
            if (ta.indexOf('d') > 0) {
                timeinSec = timeinSec + ta.slice(ta.indexOf('d') + 1, ta.indexOf('h')) * 3600;
            } else {
                timeinSec = timeinSec + ta.slice(0, ta.indexOf('h')) * 3600;
            }
        }
        if (ta.indexOf('m') > 0) {
            if (ta.indexOf('h') > 0) {
                timeinSec = timeinSec + ta.slice(ta.indexOf('h') + 1, ta.indexOf('m')) * 60;
            } else {
                timeinSec = timeinSec + ta.slice(0, ta.indexOf('m')) * 60;
            }
        }
        if (ta.indexOf('s') > 0) {
            if (ta.indexOf('m') > 0) {
                timeinSec = timeinSec + ta.slice(ta.indexOf('m') + 1, ta.indexOf('s'));
            } else {
                timeinSec = timeinSec + ta.slice(0, ta.indexOf('s'));
            }
        }
        return timeinSec;
    }
    sortNumber(val1: number, val2: number, sortDirection: any): number {
        if (val1 < val2) {
            return -1 * sortDirection;
        } else if (val1 > val2) {
            return 1 * sortDirection;
        } else {
            return 0;
        }
    }

    getTimeVal(timestring: any): any {
        const timestamp = new Date(timestring * 1000).getTime();
        const current_timestamp = new Date().getTime();
        const time_variation = timestamp - current_timestamp;
        let time_computed;
        if (time_variation <= 0 || timestring === undefined) {
            time_computed = 'started';
        } else {
            const current_date = new Date(time_variation);
            const current_day = Math.floor(time_variation / (1000 * 60 * 60 * 24));
            if (current_day >= 1) {
                if (current_date.getUTCMinutes() <= 0) {
                    time_computed = current_day + 'd' + current_date.getUTCHours() + 'h' + current_date.getUTCSeconds();
                } else {
                    time_computed =
                        current_day +
                        'd' +
                        current_date.getUTCHours() +
                        'h' +
                        current_date.getUTCMinutes() +
                        'm' +
                        current_date.getUTCSeconds() +
                        's';
                }
            } else {
                if (current_date.getUTCHours() >= 1) {
                    if (current_date.getUTCHours() < 0) {
                        time_computed = current_date.getUTCHours() + 'h' + current_date.getUTCSeconds() + 's';
                    } else {
                        time_computed = current_date.getUTCHours() + 'h' + current_date.getUTCMinutes() + 'm' + current_date.getUTCSeconds() + 's';
                    }
                } else {
                    if (current_date.getUTCMinutes() > 0) {
                        time_computed = current_date.getUTCMinutes() + 'm' + current_date.getUTCSeconds() + 's';
                    } else if (current_date.getUTCSeconds() > 0) {
                        time_computed = current_date.getUTCSeconds() + 's';
                    }
                }
            }
        }
        return time_computed;
    }

    // jackpotSortList(filterList: any) {
    //     let sortedResult = [];
    //     let jackpotList = filterList.map(x => ({ id: x.id, value: x.pjps[0] && x.pjps[0].value ? x.pjps[0].value.Cash_GBP : null }));
    //     jackpotList = this.sortListDescending(jackpotList, "value", 1);
    //     for (let i = 0; i < jackpotList.length; i++) {
    //         sortedResult.push(filterList.find(x => x.id == jackpotList[i].id));
    //     }
    //     return sortedResult;
    // }

    // favouriteSortList(filterList: any, favouriteitemList: any) {
    //     let sortResult: any = [];
    //     if (favouriteitemList && favouriteitemList.length > 0) {
    //         //sortResult = filterList.filter(val => favouriteitemList.includes(val.id));
    //         sortResult = filterList.filter(function (item: any) { return favouriteitemList.indexOf(item.id) != -1 })
    //         //sortResult = sortResult.concat(function (item: any) { return favouriteitemList.indexOf(item.id) != -1 })
    //         sortResult = sortResult.concat(filterList.filter(val => !sortResult.includes(val)));
    //         return sortResult;
    //     }
    //     else
    //         return filterList;
    // }
    private getProperty(value: { [key: string]: any }, key: string): number | string | null {
        if (value == null || typeof value !== 'object') {
            return null;
        }

        const keys: string[] = key.split('.');
        let result: any = value[keys.shift()!];

        for (const key of keys) {
            if (result == null) {
                // check null or undefined
                return '';
            }
            if (result.length == 0) return -1;

            result = result[key];
        }

        return result;
    }
}
