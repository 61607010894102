import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EventEmitService {
    private dataSource = new BehaviorSubject<any>('');

    constructor() {}

    /**
     * Raise an event to the subscribers.
     * @param event  name of the event.Eg: 'favorite-updated'.
     */
    raiseEvent(event: any) {
        this.dataSource.next(event);
    }
    /**
     * Subscribe to an event.
     * @returns event observable which you can subscribe for listening to raised events.
     */
    getEventObservable(): Observable<any> {
        return this.dataSource.asObservable();
    }
}
