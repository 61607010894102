import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BingoConfig, BingoConfigProviderService } from '@frontend/bingo/core';

import { PerformanceMarker, PerformanceMarkerIdentifiers } from '../../services/performanceMarker.service';
import { TimerToggleViewDirective } from './timertoggleview.directive';

@Component({
    selector: 'bg-schedule-timer',
    templateUrl: 'timer.component.html',
})
export class TimerComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input() timerinput: any;
    @Input() isprebuy?: boolean = false;
    @Input() toggleTimerView?: boolean = true;
    @Input() isFriendBingoRoom?: boolean = false;
    @Input() displayTime: string;
    @Input() placeholder: boolean = false;
    @Output() timerChange = new EventEmitter<string>();

    timer: any;
    timestamp: any;
    current_timestamp: any;
    current_date: any;
    current_day: any;
    time_variation: any;
    timevalue: any;
    time_string: any = '';
    time_computed: any;
    timer_status_change_limit: number;
    //timer_status_change_color: any;
    //timer_status_default_color: any;
    timer_day_format: any;
    timer_hours_format: any;
    timer_minutes_format: any;
    timer_seconds_format: any;
    timer_computedNotation: any;
    progressValue: number;
    globalclientconfig: any;
    bingoclientconfig: BingoConfig;

    constructor(
        private performanceMarker: PerformanceMarker,
        private activeRoute: ActivatedRoute,
        private configProviderService: BingoConfigProviderService,
        private _timerToggleViewDirective: TimerToggleViewDirective,
    ) {
        this.bingoclientconfig = this.configProviderService.provideBingoConfig();
    }

    ngOnInit() {
        if (this.placeholder) this.time_string = '--:--';
        if (!this.displayTime) {
            this.getTimeVal(this.timerinput);
            this.TimerComposer();
        } else {
            this.time_string = this.displayTime;
        }
        this.globalclientconfig = this.activeRoute.snapshot.data['config'].global;
        this.timer_day_format = this.globalclientconfig['messages'].dayTimerNotation;
        this.timer_hours_format = this.globalclientconfig['messages'].hourTimerNotation;
        this.timer_minutes_format = this.globalclientconfig['messages'].minuteTimerNotation;
        this.timer_seconds_format = this.globalclientconfig['messages'].secondTimerNotation;
        this.timevalue = Number(this.bingoclientconfig['scheduleTimerColorScheme']['specifiedtime'].timevalue);
        this.progressValue = Number(this.bingoclientconfig['scheduleTimerColorScheme']['specifiedtime'].progressValue);
        //this.timer_status_change_color = this.bingoclientconfig['scheduleTimerColorScheme']['specifiedtime'].color;
        //this.timer_status_default_color = this.isFriendBingoRoom ? this.bingoclientconfig['scheduleTimerColorScheme']['friendsRoom'].color : '';
        this.timer_computedNotation = this.globalclientconfig['messages'].startedTimerNotation;
    }

    TimerComposer() {
        this.timer = setInterval(() => {
            this.time_string = this.getTimeVal(this.timerinput);
        }, 1000);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const displayTime: SimpleChange = changes.displayTime;

        if (displayTime && displayTime.previousValue !== displayTime.currentValue) {
            this.time_string = displayTime.currentValue;
        }
    }

    getTimeVal(timestring: any): any {
        if (timestring == null || timestring === undefined) {
            this.time_computed = this.timer_computedNotation;
            this.clearTime();
            this._timerToggleViewDirective.removeClass();
            this.timerChange.emit('0');
            return this.time_computed;
        }
        this.timestamp = new Date(timestring * 1000).getTime();
        this.current_timestamp = new Date().getTime();
        this.time_variation = this.timestamp - this.current_timestamp;
        if (this.time_variation <= 0 || timestring === undefined) {
            this.time_computed = this.timer_computedNotation;
            this.clearTime();
            this.timerChange.emit('0');
            this._timerToggleViewDirective.removeClass();
        } else {
            this.current_date = new Date(this.time_variation);
            this.current_day = Math.floor(this.time_variation / (1000 * 60 * 60 * 24));
            if (this.current_day >= 1) {
                if (this.current_date.getUTCMinutes() <= 0) {
                    this.time_computed =
                        '<span>' +
                        this.current_day +
                        this.timer_day_format +
                        this.current_date.getUTCHours() +
                        this.timer_hours_format +
                        this.current_date.getUTCSeconds() +
                        this.timer_seconds_format +
                        '</span>';
                } else {
                    this.time_computed =
                        '<span>' +
                        this.current_day +
                        this.timer_day_format +
                        this.current_date.getUTCHours() +
                        this.timer_hours_format +
                        this.current_date.getUTCMinutes() +
                        this.timer_minutes_format +
                        this.current_date.getUTCSeconds() +
                        this.timer_seconds_format +
                        '</span>';
                }
                this.timerChange.emit('60');
            } else {
                if (this.current_date.getUTCHours() >= 1) {
                    if (this.current_date.getUTCHours() < 0) {
                        this.time_computed =
                            '<span>' +
                            this.current_date.getUTCHours() +
                            this.timer_hours_format +
                            this.current_date.getUTCSeconds() +
                            this.timer_seconds_format +
                            '</span>';
                    } else {
                        this.time_computed =
                            '<span>' +
                            this.current_date.getUTCHours() +
                            this.timer_hours_format +
                            this.current_date.getUTCMinutes() +
                            this.timer_minutes_format +
                            this.current_date.getUTCSeconds() +
                            this.timer_seconds_format +
                            '</span>';
                    }
                    this.timerChange.emit('60');
                } else {
                    if (!this.isprebuy) {
                        if (this.current_date.getUTCMinutes() <= 0) {
                            this.time_computed = '<span>' + this.current_date.getUTCSeconds() + this.timer_seconds_format + '</span>';
                            this.toggleTimer();
                            this.timerChange.emit(this.current_date.getUTCSeconds());
                        } else if (this.current_date.getUTCMinutes() < this.timevalue) {
                            this.time_computed =
                                '<span>' +
                                this.current_date.getUTCMinutes() +
                                this.timer_minutes_format +
                                this.current_date.getUTCSeconds() +
                                this.timer_seconds_format +
                                '</span>';
                            this.toggleTimer();
                            this.timerChange.emit('60');
                        } else {
                            this.time_computed =
                                '<span>' +
                                this.current_date.getUTCMinutes() +
                                this.timer_minutes_format +
                                this.current_date.getUTCSeconds() +
                                this.timer_seconds_format +
                                '</span>';
                            this.timerChange.emit('60');
                        }
                    } else {
                        if (this.current_date.getUTCMinutes() > 0) {
                            this.time_computed =
                                '<span>' +
                                this.current_date.getUTCMinutes() +
                                this.timer_minutes_format +
                                this.current_date.getUTCSeconds() +
                                this.timer_seconds_format +
                                '</span>';
                            this.timerChange.emit('60');
                        } else {
                            if (this.current_date.getUTCSeconds() > this.timevalue) {
                                this.timerChange.emit('60');
                                this.time_computed = '<span>' + this.current_date.getUTCSeconds() + this.timer_seconds_format + '</span>';
                            } else {
                                this.timerChange.emit(this.current_date.getUTCSeconds());
                                this.time_computed = '<span>' + this.current_date.getUTCSeconds() + this.timer_seconds_format + '</span>';
                                this.toggleTimer();
                            }
                        }
                    }
                }
            }
        }
        return this.time_computed;
    }

    clearTime() {
        clearInterval(this.timer);
    }

    ngOnDestroy() {
        clearInterval(this.timer);
    }

    toggleTimer() {
        if (this.toggleTimerView) {
            this._timerToggleViewDirective.addClass();
        }
    }
    ngAfterViewInit() {
        this.performanceMarker.mark(PerformanceMarkerIdentifiers.TimeToInteractivity);
    }
}
