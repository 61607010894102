import { NgClass, NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogActions, MatDialogRef } from '@angular/material/dialog';

import { CasinoManager, ConfigProviderService, Race, SitecoreContent, SlotRacesService, SlotRacesTrackingService } from '@casinocore/platform/core';

import { SlotRacesPrizesComponent } from '../race-info/prizes/prizes.component';
import { SlotRacesPointsComponent } from './points/points.component';
import { SlotRacesRulesComponent } from './rules/rules.component';
import { SlotRacesTipsComponent } from './tips/tips.component';
import { SlotRacesTnCComponent } from './tnc/tnc.component';

@Component({
    selector: 'cc-slots-races-rules-and-tnc',
    templateUrl: './rules-and-tnc.component.html',
    standalone: true,
    imports: [
        NgIf,
        MatDialogActions,
        NgStyle,
        SlotRacesPrizesComponent,
        SlotRacesRulesComponent,
        NgClass,
        SlotRacesPointsComponent,
        SlotRacesTipsComponent,
        SlotRacesTnCComponent,
    ],
})
export class SlotRacesRulesAndTnCComponent implements OnInit, OnDestroy {
    @Input() race: Race;
    @Input() coinImg: string;
    @Input() showTitleHeader: boolean = true;
    sitecoreContent: SitecoreContent;
    contentSubscription: any;
    disableSlotRacesPoints: boolean;
    slotRacesConfig: any;

    constructor(
        public dialogRef: MatDialogRef<SlotRacesRulesAndTnCComponent>,
        private configProviderService: ConfigProviderService,
        private slotRacesService: SlotRacesService,
        private slotRacesTracking: SlotRacesTrackingService,
        private casinoManager: CasinoManager,
    ) {}

    ngOnInit() {
        this.slotRacesConfig = this.configProviderService.provideSlotRacesConfig();
        this.disableSlotRacesPoints = this.slotRacesConfig.disableSlotRacesPoints;
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.sitecoreContent = content;
        });
    }
    ngOnDestroy() {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
    }

    closeButton() {
        const activeCategory = this.casinoManager.getActiveCategory();
        this.slotRacesTracking.track(
            'Close (x) - CTA Clicked',
            'Rules TnC - Overlay',
            activeCategory,
            'User Clicked on Close(x) CTA to close the overlay',
        );
        this.dialogRef.close();
    }
}
