<div *ngIf="isMultiIcon; else singleIcon" (click)="ctaClick()" class="tourney-icon-container" [style.cursor]="isClickable ? 'pointer' : 'inherit'">
    <mat-grid-list cols="2" rowHeight="1:1">
        <mat-grid-tile *ngFor="let gameIcon of gameVariantIconList; last as isLast; let i = index" [colspan]="gameIcon.colspan">
            <img src="{{ gameIcon.src }}" [draggable]="isDraggable" class="tourney-icon" alt="{{ gameVariants[i] }}" />
            <div *ngIf="isLast && overlayApplicable" class="tourney-icon-overlay">+{{ overlayCount }}</div>
        </mat-grid-tile>
    </mat-grid-list>
</div>
<ng-template #singleIcon>
    <div
        class="coineconomy-imgs"
        (click)="attachClickEvent() ? ctaClick() : {}"
        [style.cursor]="isClickable && attachClickEvent() ? 'pointer' : 'inherit'">
        <img src="{{ singleIconSrc }}" [draggable]="isDraggable" class="img-coineconomy" />
        <div class="bt-card-jackpot" [ngStyle]="{ background: jpBgColor }" *ngIf="showJackpotRibbon && jackpotValue && jackpotValue.length">
            <span class="bt-card-jp-value" [ngStyle]="{ color: jpTextCOlor }">{{ jackpotValue }}</span>
        </div>
    </div>
</ng-template>
