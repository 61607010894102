import { Injectable } from '@angular/core';

import { BingoInvokerProductService } from '../../bingo-invoker-product/services/bingo-invoker-product.service';

@Injectable({
    providedIn: 'root',
})
export class BingoHelperService {
    constructor(private bingoInvokerProductService: BingoInvokerProductService) {}

    getConfigBasedOnInvokerProduct(dynaconConfig: any): any {
        if (dynaconConfig && dynaconConfig?.length > 0 && dynaconConfig[0].invokerProduct) {
            let invokerProduct: string = this.bingoInvokerProductService.getInvokerProduct();
            if (!invokerProduct || invokerProduct === '') {
                invokerProduct = 'bingo';
            }

            let config: any = dynaconConfig.find((x: any) => x?.invokerProduct?.toLowerCase() == invokerProduct?.toLowerCase());
            if (config) {
                return config.value;
            } else {
                invokerProduct = 'bingo';
                let defaultConfig: any = dynaconConfig.find((x: any) => x?.invokerProduct?.toLowerCase() == invokerProduct.toLowerCase());
                if (defaultConfig) {
                    return defaultConfig.value;
                }
            }
        }
        return false;
    }
}
