import { Pipe, PipeTransform } from '@angular/core';

import { CurrencyPipe } from '@frontend/vanilla/core';

@Pipe({
    name: 'bgFloatTruncate',
})
export class BgFloatTruncatePipe extends CurrencyPipe implements PipeTransform {
    override transform(amount: number | null | undefined, playerCount: any, dynaconParams: any, currencyCode?: string, digitInfo?: string): any {
        let result;
        const dynaconPlayerCount = dynaconParams?.dynaconPlayerCount;
        const potPlayerCurrencyDigits = dynaconParams?.potPlayerCurrencyDigits;
        const vnAmnt = super.transform(amount, currencyCode, digitInfo);
        if (playerCount && String(playerCount).length > dynaconPlayerCount) {
            result = String(vnAmnt).split('.');
            if (result?.length > 0 && result[0].length > potPlayerCurrencyDigits) {
                result = result[0];
            } else {
                result = vnAmnt;
            }
        } else {
            result = vnAmnt;
        }
        return result;
    }
}
