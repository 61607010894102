import { Injectable } from '@angular/core';

import { AppInfoConfig, Page, ProductHomepagesConfig } from '@frontend/vanilla/core';

import { BingoNpmParamsService } from '../../bingo-npm-params/services/bingo-npm-params.service';

@Injectable({
    providedIn: 'root',
})
export class BingoUrlUtilityService {
    isDev: boolean;
    devUrl: string;

    constructor(
        private products: ProductHomepagesConfig,
        private page: Page,
        private bingoNpmParamsService: BingoNpmParamsService,
        private appInfoConfig: AppInfoConfig,
    ) {}

    getBingoBaseUrl = (): string => this.getUrl('bingo') || '';

    //getProductBaseUrl = (): string => this.getUrl() || '';
    getProductBaseUrl = (productOverride?: string) => this.getUrl('', true, productOverride);

    getApiBaseUrl = (): string => this.getBingoBaseUrl() + '/' + this.page.lang;

    getProductApiBaseUrl = (productOverride?: string): string => this.getProductBaseUrl(productOverride) + '/' + this.page.lang;

    getApiurl = (url: string): string => this.getApiBaseUrl() + '/bingo/api/' + url;

    getClientConfigUrl = () => this.getApiBaseUrl() + '/api/clientconfig';

    getProductMapConfiguration = (bingoModularConfigurationClientConfig: any) => {
        return bingoModularConfigurationClientConfig?.productMap?.find((f: any) =>
            f?.invokerProduct?.toLowerCase()?.includes(this.appInfoConfig?.product?.toLowerCase()),
        );
    };

    private getUrl = (type: string, isProduct: boolean = false, productOverride?: string) => {
        const product: any = isProduct && productOverride ? productOverride : isProduct ? this.appInfoConfig?.product?.toLowerCase() : type;
        const productUrl = new URL(this.products[product as keyof ProductHomepagesConfig]);
        return productUrl.origin;
    };
}
