import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

import { BingoCoreModule } from '@frontend/bingo/core';
import { DynamicHtmlDirective } from '@frontend/vanilla/core';
import { PageMatrixComponent } from '@frontend/vanilla/features/content';
import { MessagePanelComponent } from '@frontend/vanilla/features/message-panel';

import { BingoSlotRacesWidgetComponent } from './bingo-slot-races-widget.component';
import { BingoSlotRacesWrapperComponent } from './bingo-slot-races-wrapper.component';
import { BingoSlotRacesComponent } from './bingo-slot-races.component';
import { BingoSlotRacesTimelineItemComponent } from './bingo-timeline-item.component';
import { BingoSlotRacesCardsModule } from './cards-wrapper/bingo-cards-wrapper.module';
import { BingoSlotRaceCardModule } from './cards-wrapper/card/bingo-card.module';
import { BingoSlotRacesLeaderboardModule } from './leaderboard/bingo-leaderboard.module';
import { BingoNotificationOverlayModule } from './notification-overlay/bingo-notification-overlay.module';
import { BingoPickGameOverlayModule } from './pick-game-overlay/bingo-pick-game-overlay.module';
import { BingoSlotRacesInfoModule } from './race-info/bingo-race-info.module';
import { BingoSlotRacesResultsModule } from './results/bingo-results.module';
import { BingoSlotRacesrulesAndTnCModule } from './rules-points-tips-tnc/bingo-rules-and-tnc.module';
import { BingoSlotRacesRulesModule } from './rules-points-tips-tnc/bingo-rules/bingo-rules.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DynamicHtmlDirective,
        PageMatrixComponent,
        SharedModule,
        BingoSlotRaceCardModule,
        BingoSlotRacesCardsModule,
        BingoSlotRacesInfoModule,
        BingoSlotRacesResultsModule,
        BingoSlotRacesLeaderboardModule,
        MatExpansionModule,
        MatIconModule,
        BingoSlotRacesrulesAndTnCModule,
        BingoSlotRacesRulesModule,
        BingoPickGameOverlayModule,
        BingoCoreModule,
        MessagePanelComponent,
        BingoNotificationOverlayModule,
    ],
    declarations: [BingoSlotRacesComponent, BingoSlotRacesTimelineItemComponent, BingoSlotRacesWrapperComponent, BingoSlotRacesWidgetComponent],
    exports: [BingoSlotRacesComponent, BingoSlotRacesWidgetComponent],
})
export class BingoSlotRacesModule {
    static rootComponent = BingoSlotRacesWrapperComponent;
    public static forRoot(): ModuleWithProviders<BingoSlotRacesModule> {
        return {
            ngModule: BingoSlotRacesModule,
        };
    }
}
