import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

import {
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    InvokerProductService,
    SitecoreContent,
    SlotRacesService,
} from '@casinocore/platform/core';
import { DynamicHtmlDirective, NavigationService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

import { SlotRacesWrapperComponent } from './slot-races-wrapper.component';

@Component({
    selector: 'cc-slot-races-widget',
    templateUrl: 'slot-races-widget.component.html',
    standalone: true,
    imports: [NgIf, DynamicHtmlDirective, SlotRacesWrapperComponent],
})
export class SlotRacesWidgetComponent implements OnInit, OnDestroy {
    isLobbyLoaded: boolean;
    loadWidget: boolean;
    lobbyType: string;
    category: any;
    header: any;
    categoryTitle: string;
    contentSubscription: Subscription;
    lobbyLoadSubscription: Subscription;
    slotRacesUpdateSubscription: Subscription;
    isSlotRaceSubCategory: boolean;
    enableSlotRacesForOtherProducts: boolean;

    constructor(
        private configProvider: ConfigProviderService,
        private casinoManager: CasinoManager,
        private casinoLobbyService: CasinoLobbyService,
        private invokerProductService: InvokerProductService,
        private slotRacesService: SlotRacesService,
        private navigation: NavigationService,
    ) {}

    ngOnInit() {
        this.lobbyLoadSubscription = this.casinoLobbyService.lobbyLoadedObservable.subscribe((response: any) => {
            if (response) {
                this.setParams();
                this.isLobbyLoaded = true;
            }
        });
    }

    setParams() {
        const slotRacesConfig = this.configProvider.provideSlotRacesConfig();
        const widgetConfig: any = this.configProvider.providewidgetConfig();
        this.slotRacesUpdateSubscription = this.slotRacesService.updateNavigation.subscribe((update: boolean) => {
            if (update) {
                const slotRacesData = this.slotRacesService.getCurrentRaces();
                if (slotRacesConfig.disableWhenNoPromotions) {
                    if (
                        (slotRacesData.liveNow && slotRacesData.liveNow.length) ||
                        (slotRacesData.upcomingToday && slotRacesData.upcomingToday.length) ||
                        (slotRacesData.upcomingTomorrow && slotRacesData.upcomingTomorrow.length)
                    ) {
                        this.loadWidget = true;
                    } else {
                        this.loadWidget = false;
                    }
                } else {
                    this.loadWidget = true;
                }
            }
        });

        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.header = content && content.npmHeader;
        });
        const product = this.invokerProductService.getInvokerProduct();
        this.enableSlotRacesForOtherProducts = widgetConfig.enableSlotRacesForOtherProducts;
        this.lobbyType = widgetConfig.lobbyTypeForSlotRacesOnOtherProducts; // this is used for getting slot races category id and checking for enabling of features like jackpot ribbon
        const subcategoryid: string = widgetConfig.subCatRouteForSlotRacesOnOtherProducts;
        const categoryId = this.casinoManager.getSlotRaceCategoryId(this.lobbyType);
        if (categoryId === subcategoryid || 'home' + categoryId === subcategoryid) {
            this.isSlotRaceSubCategory = true;
        }
        this.category = {
            categoryInfo: {
                categoryid: product + '_lobby_' + categoryId,
                subcategoryid: subcategoryid,
                route: categoryId,
            },
        };
    }

    SeeAllTournaments() {
        this.navigation.goTo(this.header?.titleLink?.url, { replace: false, forceReload: true });
    }

    ngOnDestroy() {
        if (this.lobbyLoadSubscription) {
            this.lobbyLoadSubscription.unsubscribe();
        }
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
        if (this.slotRacesUpdateSubscription) {
            this.slotRacesUpdateSubscription.unsubscribe();
        }
    }
}
