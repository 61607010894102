<div *ngIf="isMultiIcon; else singleIcon" (click)="ctaClick()" class="tourney-icon-container" [style.cursor]="isClickable ? 'pointer' : 'inherit'">
    <mat-grid-list cols="2" rowHeight="1:1">
        <mat-grid-tile *ngFor="let gameIcon of gameVariantIconList; last as isLast; let i = index" [colspan]="gameIcon.colspan">
            <img
                src="{{ gameIcon.src }}"
                (error)="onGameIconError(gameIcon.src)"
                [draggable]="isDraggable"
                class="tourney-icon"
                alt="{{ gameVariantList[i] }}" />
            <div *ngIf="isLast && overlayApplicable" class="tourney-icon-overlay">+{{ overlayCount }}</div>
        </mat-grid-tile>
    </mat-grid-list>
</div>
<ng-template #singleIcon>
    <div
        class="coineconomy-imgs"
        (click)="attachClickEvent() ? ctaClick() : {}"
        [style.cursor]="isClickable && attachClickEvent() ? 'pointer' : 'inherit'">
        <img src="{{ singleIconSrc }}" (error)="onGameIconError(singleIconSrc)" [draggable]="isDraggable" class="img-coineconomy" />
        <div
            class="sr-card-jackpot"
            [ngStyle]="{ background: jpRibbonStyles?.jpBgColor }"
            *ngIf="showJackpotRibbon && jackpotValue && jackpotValue.length">
            <span class="sr-card-jp-value" [ngStyle]="{ color: jpRibbonStyles?.jpTextColor }">{{ jackpotValue }}</span>
        </div>
    </div>
</ng-template>
