import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { ConfirmMessageModule } from './shared/components/confirm-message/confirm-message.module';
import { CustomButtonComponent } from './shared/components/designsystems/custombutton.component';

@NgModule({
    imports: [CommonModule, ConfirmMessageModule, CustomButtonComponent],
    declarations: [],
    exports: [ConfirmMessageModule, CustomButtonComponent],
})
export class BingoCoreModule {
    public static forRoot(): ModuleWithProviders<BingoCoreModule> {
        return {
            ngModule: BingoCoreModule,
        };
    }
}
