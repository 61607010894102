import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, DestroyRef, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import {
    CasinoManager,
    ConfigProviderService,
    DesignSystemEnablerService,
    FILTER_TYPE_ALL,
    FILTER_TYPE_CASH_ENTRY,
    FILTER_TYPE_COINS_ENTRY,
    FILTER_TYPE_COIN_REWARD,
    FILTER_TYPE_FREE_ENTRY,
    Race,
    SitecoreContent,
    SlotRacesLive,
    SlotRacesService,
    SlotRacesTrackingService,
    SlotRacesUpcoming,
} from '@casinocore/platform/core';
import { DecoratorClassDirective, SlotRaceCtaComponent } from '@frontend/casino-ui';
import { DeviceService, Page, RtmsService, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { PageMatrixComponent } from '@frontend/vanilla/features/content';
import { MessagePanelComponent } from '@frontend/vanilla/features/message-panel';
import { Subscription, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SlotRacesCardsComponent } from './cards-wrapper/cards-wrapper.component';
import { NotificationOverlayComponent } from './notification-overlay/notification-overlay.component';
import { PickGameOverlayComponent } from './pick-game-overlay/pick-game-overlay.component';
import { SlotRacesInfoComponent } from './race-info/race-info.component';
import { SlotRacesResultsComponent } from './results/results.component';
import { SlotRacesRulesAndTnCComponent } from './rules-points-tips-tnc/rules-and-tnc.component';
import { SlotRacesRulesComponent } from './rules-points-tips-tnc/rules/rules.component';
import { SlotRacesTimelineItemComponent } from './timeline-item.component';

@Component({
    selector: 'cc-slot-races',
    templateUrl: 'slot-races.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgClass,
        SlotRacesRulesComponent,
        NgIf,
        NgFor,
        MessagePanelComponent,
        SlotRacesCardsComponent,
        PageMatrixComponent,
        SlotRacesTimelineItemComponent,
        DecoratorClassDirective,
        SlotRaceCtaComponent,
    ],
})
export class SlotRacesComponent implements OnInit, OnChanges, OnDestroy {
    @Input() lobbyType: string;
    @Input() category: any;
    categoryName: string = 'Casino Tournaments';
    upcomingRaces: SlotRacesUpcoming;
    liveRaces: SlotRacesLive;
    race: Race;
    isTouch: boolean;
    entryDetails: any;
    isLoggedIn: boolean;
    content: SitecoreContent = {
        raceRuleConfigs: [],
        rules: [],
        rulesBanner: {},
        placeholderCardImage: {},
        coinEconomy: {},
        textTranslations: { sharedList: {}, versionedList: {} },
        tips: [],
        errorMessages: { sharedList: {}, versionedList: {} },
        entryDetails: { sharedList: {}, versionedList: {} },
    };
    @Input() routeCurrentProduct: string;

    contentSubscription: Subscription;
    upcomingRacesSubscription: Subscription;
    liveRacesSubscription: Subscription;
    rtmsSubscription: Subscription;
    auth: boolean;
    popupPurchage: any;
    selectedOption: string;
    toggleOpenUpcomingTodayCard: boolean = false;
    toggleOpenUpcomingTomorrowCard: boolean = false;
    isSubCategory: boolean;
    coinsForOptin: number;
    enableFixedMobileNavigation: boolean;
    isEnableOptimizedTeasers: boolean;
    displayCatHeader: boolean;
    enableBetterVisualization: boolean;
    currentLobbyType: string;
    enableUpcomingOptinForPlaytech: boolean;
    isFreePlaytechinLive: boolean = false;
    entryTypeSelectRef: any;
    selectedValue: string;
    filteredData: any[] = [];
    optInButtonText: any;
    entryData: any = {};
    isOptinWithCoins: boolean = false;
    enableLiveRacesFilter: boolean;
    selectedOptionValue: any;
    tourment: string;
    messages: { [item: string]: string } = {};
    entryDetailsKeys: string[];
    raceItem: any;
    liveRacesList: any;
    upcomingRacesList: any = {};
    coinImg: string;
    enableCoinEconomy: boolean;
    firstTimeTracking: boolean = true;
    isLegacy: boolean = false;
    constructor(
        private dialog: MatDialog,
        private userService: UserService,
        private casinoManager: CasinoManager,
        private slotRacesService: SlotRacesService,
        private configProviderService: ConfigProviderService,
        private slotRacesTracking: SlotRacesTrackingService,
        private rtmsService: RtmsService,
        private router: Router,
        private page: Page,
        private route: ActivatedRoute,
        private deviceService: DeviceService,
        private dsEnablerService: DesignSystemEnablerService,
        private destroyRef: DestroyRef,
    ) {
        this.isTouch = this.deviceService.isTouch;
    }
    // @ to detect broswer back button
    @HostListener('window:popstate', ['$event'])
    onPopState() {
        setTimeout(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const filterParam = urlParams.get('filter');
            if (filterParam) {
                this.displayFilterEntryMessage();
                this.onFilterSelect(this.selectedOption);
            }
        }, 100);
    }

    ngOnInit() {
        this.isSubCategory = !!this.category?.categoryInfo?.subcategoryid;
        this.slotRacesService.setSlotRaces(this.lobbyType);
        const features = this.configProviderService.provideFeaturesConfig();
        const slotRaceConfigs = this.configProviderService.provideSlotRacesConfig();
        this.enableBetterVisualization = slotRaceConfigs.enableBetterVisualization;
        this.enableFixedMobileNavigation = features.enableFixedMobileNavigation;
        this.enableUpcomingOptinForPlaytech = slotRaceConfigs.enableUpcomingOptinForPlaytech;
        const optimizedTeaserConfigs = this.configProviderService.provideOptimizedTeaserConfig();
        this.isEnableOptimizedTeasers = optimizedTeaserConfigs.teaserOptimization;
        this.slotRacesService.getRaces();
        this.displayCatHeader = features.enableHeaderforCategory;
        this.categoryName = this.category && this.category.categoryInfo && this.category.categoryInfo.categoryname;
        this.enableCoinEconomy = this.configProviderService.provideSlotRacesConfig()?.enableCoinEconomy;
        this.isLegacy = !this.dsEnablerService.isEnabled();
        if (navigator.userAgent.indexOf('Firefox') != -1) {
            document.getElementsByTagName('html')[0].classList.add('firefox');
        }
        this.subscribeToData();
        this.auth = this.userService.isAuthenticated;
        this.casinoManager.reSizeObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((width: any) => {
            if (width) {
                const dialogConfig = new MatDialogConfig();
                this.dialogProperties(dialogConfig);
            }
        });
        this.userService.events
            .pipe(
                filter((e: any) => e instanceof UserLoginEvent),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(() => {
                this.auth = true;
            });

        this.displayFilterEntryMessage();
    }

    ngOnChanges() {
        if (this.currentLobbyType !== this.lobbyType) {
            this.currentLobbyType = this.lobbyType;
            this.slotRacesService.setSlotRacesParams(this.currentLobbyType);
        }
    }

    onFilterSelect(selectedOption: string) {
        this.firstTimeTracking = true;
        this.selectedOption = selectedOption || 'ALL';
        const seletedEntryOption = this.entryDetails[selectedOption];
        if (seletedEntryOption) {
            const queryParams = { filter: seletedEntryOption.replaceAll(' ', '_') };
            this.subscribeToData();
            if (this.selectedOption.toUpperCase() === FILTER_TYPE_ALL) {
                this.router.navigateByUrl(
                    '/' +
                        this.page.lang +
                        '/' +
                        this.casinoManager.getProductForGivenLobbyType(this.lobbyType).toLowerCase() +
                        '/c/' +
                        this.category?.categoryInfo.categoryid,
                );
                this.tourment = '';
            } else {
                this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
                this.tourment = this.messages.NoTourmentsAreAvailableWith
                    ? this.messages.NoTourmentsAreAvailableWith + ' ' + this.selectedOption
                    : '';
            }
        }
    }

    updateslotRaceUpcomingData(data: SlotRacesUpcoming) {
        let firstPlayTechFound: boolean = false;
        if (data && data.upcomingToday && data.upcomingToday.length > 0) {
            for (let i = 0; i < data.upcomingToday.length; i++) {
                if (data?.upcomingToday[i]?.subType?.toLowerCase() === 'free_play_tech') {
                    data.upcomingToday[i].firstPlayTech = true;
                    firstPlayTechFound = true;
                    break;
                }
            }
        }
        if (!firstPlayTechFound && data && data.upcomingTomorrow && data.upcomingTomorrow.length > 0) {
            for (let i = 0; i < data.upcomingTomorrow.length; i++) {
                if (data?.upcomingTomorrow[i]?.subType?.toLowerCase() === 'free_play_tech') {
                    data.upcomingTomorrow[i].firstPlayTech = true;
                    break;
                }
            }
        }
    }

    subscribeToData() {
        this.liveRacesSubscription = this.slotRacesService.liveRacesData.subscribe((data: SlotRacesLive) => {
            this.liveRaces = data;
            this.liveRacesList = this.liveRaces.liveNow;
            if (this.liveRaces && this.enableCoinEconomy) {
                if (this.selectedOption?.toUpperCase() === FILTER_TYPE_ALL) {
                    this.liveRacesList = this.liveRaces.liveNow;
                } else if (this.selectedOption === FILTER_TYPE_CASH_ENTRY) {
                    this.liveRacesList = this.liveRaces?.liveNow.filter((raceItem) => raceItem.subType == 'CASINO_LEADERBOARD');
                } else if (this.selectedOption === FILTER_TYPE_COIN_REWARD) {
                    this.liveRacesList = this.liveRaces?.liveNow.filter(
                        (raceItem) =>
                            raceItem?.additionalParams?.awardType === 'COINS' ||
                            (raceItem?.additionalParams?.awardType !== 'COINS' && raceItem?.additionalParams?.isCoinAward === 'YES'),
                    );
                } else {
                    const filteredLiveRaces = this.liveRaces?.liveNow.filter(
                        (raceItem) => raceItem.subType === 'FREE_TO_PLAY' || raceItem.subType?.toLowerCase() === 'free_play_tech',
                    );
                    if (this.selectedOption === FILTER_TYPE_COINS_ENTRY) {
                        this.liveRacesList = filteredLiveRaces?.filter((raceItem) => raceItem?.isOptinWithCoins);
                    } else if (this.selectedOption === FILTER_TYPE_FREE_ENTRY) {
                        this.liveRacesList = filteredLiveRaces?.filter((raceItem) => !raceItem?.isOptinWithCoins);
                    } else {
                        this.liveRacesList = [];
                    }
                }
                this.enableLiveRacesFilter = true;
            }
        });
        this.upcomingRacesSubscription = this.slotRacesService.upcomingRacesData.subscribe((data: SlotRacesUpcoming) => {
            this.upcomingRaces = data;
            this.upcomingRacesList = { ...this.upcomingRaces };
            if (this.upcomingRaces && this.enableCoinEconomy) {
                if (this.selectedOption?.toUpperCase() === FILTER_TYPE_ALL) {
                    this.upcomingRacesList = { ...this.upcomingRaces };
                } else if (this.selectedOption == FILTER_TYPE_CASH_ENTRY) {
                    this.upcomingRacesList.upcomingToday = this.upcomingRaces?.upcomingToday.filter(
                        (raceItem) => raceItem.subType == 'CASINO_LEADERBOARD',
                    );
                    this.upcomingRacesList.upcomingTomorrow = this.upcomingRaces?.upcomingTomorrow.filter(
                        (raceItem) => raceItem.subType == 'CASINO_LEADERBOARD',
                    );
                } else if (this.selectedOption == FILTER_TYPE_COIN_REWARD) {
                    this.upcomingRacesList.upcomingToday = this.upcomingRaces?.upcomingToday.filter(
                        (raceItem) =>
                            raceItem?.additionalParams?.awardType === 'COINS' ||
                            (raceItem?.additionalParams?.awardType !== 'COINS' && raceItem?.additionalParams?.isCoinAward === 'YES'),
                    );
                    this.upcomingRacesList.upcomingTomorrow = this.upcomingRaces?.upcomingTomorrow.filter(
                        (raceItem) =>
                            raceItem?.additionalParams?.awardType === 'COINS' ||
                            (raceItem?.additionalParams?.awardType !== 'COINS' && raceItem?.additionalParams?.isCoinAward === 'YES'),
                    );
                } else {
                    const filteredUpcomingToday = this.upcomingRaces?.upcomingToday.filter(
                        (raceItem) => raceItem.subType === 'FREE_TO_PLAY' || raceItem.subType?.toLowerCase() === 'free_play_tech',
                    );
                    const filteredUpcomingTomorrow = this.upcomingRaces?.upcomingTomorrow.filter(
                        (raceItem) => raceItem.subType === 'FREE_TO_PLAY' || raceItem.subType?.toLowerCase() === 'free_play_tech',
                    );
                    if (this.selectedOption === FILTER_TYPE_COINS_ENTRY) {
                        this.upcomingRacesList.upcomingToday = filteredUpcomingToday?.filter((raceItem) => raceItem?.isOptinWithCoins);
                        this.upcomingRacesList.upcomingTomorrow = filteredUpcomingTomorrow?.filter((raceItem) => raceItem?.isOptinWithCoins);
                    } else if (this.selectedOption === FILTER_TYPE_FREE_ENTRY) {
                        this.upcomingRacesList.upcomingToday = filteredUpcomingToday?.filter((raceItem) => !raceItem?.isOptinWithCoins);
                        this.upcomingRacesList.upcomingTomorrow = filteredUpcomingTomorrow?.filter((raceItem) => !raceItem?.isOptinWithCoins);
                    } else {
                        this.upcomingRacesList.upcomingToday = [];
                        this.upcomingRacesList.upcomingTomorrow = [];
                    }
                }
            }
            if (this.enableUpcomingOptinForPlaytech) this.updateslotRaceUpcomingData(data);
        });
        combineLatest(this.slotRacesService.liveRacesData, this.slotRacesService.upcomingRacesData).subscribe((data: any) => {
            if (data[0].liveNow.length === 0) {
                this.toggleFirstCard(true);
            } else {
                this.toggleFirstCard(false);
            }
        });

        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.content = content;
            this.entryDetails = content?.entryDetails ? content.entryDetails.versionedList : {};
            this.entryDetailsKeys = this.entryDetails ? Object.keys(this.entryDetails) : [];
            this.messages = content && content.textTranslations ? content.textTranslations.versionedList : {};
            this.coinImg = this.content?.coinEconomy?.image?.src;
            this.displayTournamentErrorMessage();
        });
        this.rtmsSubscription = this.rtmsService.messages
            .pipe(filter((m) => m.type === 'FREE_TECH_OPTIN_LIMIT_EXCEED' || m.type === 'SLOT_RACE_EDIT' || m.type === 'PLAYER_NOTIFICATION_STARTED'))
            .subscribe((message) => {
                if ((this.upcomingRaces?.upcomingToday?.length > 0 || this.upcomingRaces?.upcomingTomorrow?.length > 0) && message.payload) {
                    this.upcomingRaces.upcomingToday.forEach((race: Race) => {
                        if (race.promoId == message.payload.promoId && race.slotUniqueId == message.payload.slotId) {
                            if (message.type == 'FREE_TECH_OPTIN_LIMIT_EXCEED') {
                                race.targetedPlayerCount = race.optinLimit;
                            } else if (message.type === 'PLAYER_NOTIFICATION_STARTED') {
                                race.isNotify = true;
                            }
                            return;
                        }
                    });
                    this.upcomingRaces.upcomingTomorrow.forEach((race: Race) => {
                        if (race.promoId == message.payload.promoId && race.slotUniqueId == message.payload.slotId) {
                            if (message.type == 'FREE_TECH_OPTIN_LIMIT_EXCEED') {
                                race.targetedPlayerCount = race.optinLimit;
                            } else if (message.type === 'PLAYER_NOTIFICATION_STARTED') {
                                race.isNotify = true;
                            }
                            return;
                        }
                    });
                }

                if (message.type == 'SLOT_RACE_EDIT') {
                    if (this.userService.isAuthenticated) this.slotRacesService.getUpcomingRaces();
                }
            });
    }

    showResults(): void {
        const dialogConfig = new MatDialogConfig();
        this.dialogProperties(dialogConfig);
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title: 'your results',
            category: this.category,
            coinImg: this.coinImg,
        };
        dialogConfig.panelClass = 'sr-results-dialog';
        const activeCategory = this.category && this.category.categoryInfo && this.category.categoryInfo.categoryid;
        this.slotRacesTracking.track(
            'See your Results - CTA Clicked',
            'My Results Ovelay',
            activeCategory,
            'User Clicked on see reults CTA to check the results',
        );
        const dialogRef = this.dialog.open(SlotRacesResultsComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(() => {});
        setTimeout(() => {
            this.rulesAndTcOverlayTopAllignment();
        }, 0);
    }

    openDialog(race: Race, dialog: 'race-info' | 'pick-game' | 'notification') {
        const component =
            dialog === 'race-info' ? SlotRacesInfoComponent : dialog === 'pick-game' ? PickGameOverlayComponent : NotificationOverlayComponent;
        this.openDialogOverlay(race, component);
    }

    openDialogOverlay(race: Race, component: any) {
        this.dialog.closeAll();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        this.dialogProperties(dialogConfig);
        dialogConfig.data = {
            race: race,
            category: this.category,
            lobbyType: this.lobbyType,
            enableOptin: this.enableUpcomingOptinForPlaytech && race.firstPlayTech && !this.isFreePlaytechinLive,
            coinImg: this.coinImg,
        };
        if (component == NotificationOverlayComponent) {
            dialogConfig.panelClass = 'casino-snotification-mat-dialog';
        } else {
            dialogConfig.panelClass = 'casino-sr-mat-dialog';
        }
        const dialogRef = this.dialog.open(component, dialogConfig);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                if (result.openRaceInfo) {
                    this.openDialog(race, 'race-info');
                } else if (result.openPickGameOverlay) {
                    this.openDialog(race, 'pick-game');
                } else if (result.openNotificationOverlay) {
                    this.openDialog(race, 'notification');
                } else if (result.errorCode) {
                    this.slotRacesService.addErrorMessage(result.errorCode);
                }
            }
        });
    }

    dialogProperties(dialogConfig: MatDialogConfig) {
        if (window.matchMedia('(orientation: landscape)').matches && this.deviceService.windowWidth() < 900) {
            dialogConfig.width = '100%';
            dialogConfig.height = '100%';
            dialogConfig.maxWidth = '100%';
            dialogConfig.maxHeight = '100%';
        } else if (!this.deviceService.isMobileWidth()) {
            dialogConfig.width = '668px';
        } else {
            dialogConfig.width = '100%';
            dialogConfig.height = '100%';
            dialogConfig.maxWidth = '100%';
            dialogConfig.maxHeight = '100%';
        }
    }

    ngOnDestroy() {
        this.upcomingRacesSubscription.unsubscribe();
        this.liveRacesSubscription.unsubscribe();
        this.contentSubscription.unsubscribe();
        if (this.rtmsSubscription) {
            this.rtmsSubscription.unsubscribe();
        }
    }

    openFullRulesDialog(): void {
        this.dialog.open(SlotRacesRulesAndTnCComponent, { width: '668px', panelClass: 'casino-sr-mat-dialog' });
        setTimeout(() => {
            this.rulesAndTcOverlayTopAllignment();
        }, 0);
    }

    raceAdded(item: Race): string {
        return item.promoId + '_' + item.slotUniqueId;
    }
    toggleFirstCard(toggle: boolean) {
        if (toggle) {
            if (this.upcomingRaces.upcomingToday && this.upcomingRaces.upcomingToday.length != 0) {
                this.toggleOpenUpcomingTodayCard = true;
            } else if (this.upcomingRaces.upcomingTomorrow && this.upcomingRaces.upcomingTomorrow.length != 0) {
                this.toggleOpenUpcomingTomorrowCard = true;
            }
        } else {
            this.toggleOpenUpcomingTodayCard = false;
            this.toggleOpenUpcomingTomorrowCard = false;
        }
    }
    PlaytechinLive(event: any) {
        this.isFreePlaytechinLive = event;
    }
    rulesAndTcOverlayTopAllignment() {
        const featuresConfig = this.configProviderService.provideFeaturesConfig();
        if (featuresConfig.enableOverlayMobileSearchTopPosition) {
            if (document.querySelector('.content-messages-header-top .content-message-container')) {
                const contentMessageElement: HTMLElement | null = document.querySelector('.content-messages-header-top .content-message-container');
                const srtc_overlay: HTMLElement = document.querySelector('.cdk-overlay-container') as HTMLElement;
                srtc_overlay.style.top = contentMessageElement?.clientHeight + 'px';
            }
        }
    }

    displayTournamentErrorMessage(apiCall: boolean = false) {
        if (this.selectedOption?.toUpperCase() === FILTER_TYPE_ALL) {
            this.tourment = '';
            if (apiCall) {
                this.subscribeToData();
            }
        } else {
            const selectedOptionMessages = this.entryDetails[this.selectedOption];
            if (!selectedOptionMessages) {
                this.tourment = this.messages?.DefaultMessage;
            } else {
                this.tourment = this.messages?.NoTourmentsAreAvailableWith
                    ? this.messages?.NoTourmentsAreAvailableWith + ' ' + this.selectedOption
                    : '';
            }
        }
    }

    displayFilterEntryMessage() {
        if (this.enableCoinEconomy) {
            this.route.queryParams.subscribe((params) => {
                const storedFilter = params.filter;
                if (this.isEmptyObject(this.entryDetails)) {
                    this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
                        this.entryDetails = content?.entryDetails ? content.entryDetails.versionedList : {};
                        this.assignSelectedOptionBasedOnFilter(storedFilter);
                    });
                } else {
                    this.assignSelectedOptionBasedOnFilter(storedFilter);
                }
            });
        }
    }

    isEmptyObject(obj: any) {
        return Object.keys(obj).length === 0;
    }

    assignSelectedOptionBasedOnFilter(storedFilter: any) {
        const storedFilterKey = storedFilter
            ? Object.keys(this.entryDetails).find((key) => this.entryDetails[key] === storedFilter.replaceAll('_', ' '))
            : FILTER_TYPE_ALL;
        this.selectedOption = storedFilterKey || FILTER_TYPE_ALL;
        this.displayTournamentErrorMessage(true);
        this.filterTrackingEvent(true);
    }
    filterTrackingEvent(filterTracking: boolean = false) {
        if (this.selectedOption && filterTracking && this.firstTimeTracking) {
            const seletedEntryOption = this.entryDetails[this.selectedOption];
            const filterPosition = this.entryDetailsKeys.indexOf(this.selectedOption) + 1;
            this.slotRacesTracking.track('click', filterPosition.toString(), this.category?.categoryInfo.categoryid, seletedEntryOption, true);
            this.firstTimeTracking = false;
        }
    }
}
