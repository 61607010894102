import { CommonModule, CurrencyPipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

import { PageMatrixComponent } from '@frontend/vanilla/features/content';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';

import { PrizePipe } from '../../shared/prize.pipe';
import { BingoSlotRacesTnCComponent } from './bingo-tnc.component';

@NgModule({
    imports: [CommonModule, TrustAsHtmlPipe, PageMatrixComponent, MatExpansionModule, MatIconModule],
    providers: [PrizePipe, CurrencyPipe],
    declarations: [BingoSlotRacesTnCComponent],
    exports: [BingoSlotRacesTnCComponent],
})
export class BingoSlotRacesTnCModule {
    public static forRoot(): ModuleWithProviders<BingoSlotRacesTnCModule> {
        return {
            ngModule: BingoSlotRacesTnCModule,
        };
    }
}
