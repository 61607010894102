<div class="bonus-supression-popup">
    <div class="content-body">
        <div class="message-header">
            <span class="bonus-supression-title">{{ title }}</span>
            <div (click)="close()" class="popup-close">
                <i class="theme-close-small"></i>
            </div>
        </div>
        <p class="bonus-supression-msg" [innerHTML]="bonusSupressionMessage"></p>
        <div class="popup-close-btn">
            <div class="btn close-btn" (click)="close()">{{ closeBtnText }}</div>
        </div>
    </div>
</div>
