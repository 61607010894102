<div class="bingo-bt-lobby-container-main">
    <div class="bingo-bt-pick-game bingo-bt-race-info-container">
        <div class="bingo-bt-info-title-container">
            <span mat-dialog-title class="race-info-title">{{ messages.PickGameOverlayTitle }}</span>
            <span class="theme-ex race-info-close" (click)="close()"></span>
        </div>
        <div>
            <bgl-slot-race-card
                [fromPickGameOverlay]="true"
                [race]="race"
                [showRaceInfo]="true"
                [enableOptin]="raceinfoopt"
                [showCardMenu]="false"
                (openRaceInfo)="openRaceInfoDialog()"
                (openNotificationOverlay)="openNotificationDialog()"
                [showPlaceholderCard]="false"
                [position]="1"
                [coinImg]="coinImg"
                (closePickGameOverlay)="close($event)"></bgl-slot-race-card>
        </div>
        <div class="cs-bt-overlay-games-section">
            <mat-grid-list [cols]="breakpoint" rowHeight="1:1" [gutterSize]="'10px'">
                <mat-grid-tile *ngFor="let game of race.gameVariants; let i = index">
                    <bgl-slot-races-icon
                        class="bgl-slot-races-icon"
                        (launchSelectedGame)="launchGame()"
                        [gameVariants]="[game]"
                        [source]="'Overlay|Slot Races'"
                        [position]="i"
                        [isClickable]="
                            !race.isLive &&
                            race.subType &&
                            (race.subType.toLowerCase() == 'free_to_play' ||
                                race.subType.toLowerCase() == 'free_play_tech' ||
                                race?.subType?.toLowerCase() === 'bingo_leaderboard')
                                ? false
                                : true
                        "
                        [isLive]="race.isLive"
                        [cta]="race.ctaCollection"
                        [spinProgress]="race?.spinProgress"
                        [isFreeTourney]="
                            race.subType &&
                            (race.subType.toLowerCase() == 'free_to_play' ||
                                race.subType.toLowerCase() == 'free_play_tech' ||
                                race?.subType?.toLowerCase() === 'bingo_leaderboard')
                        "></bgl-slot-races-icon>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>
</div>
