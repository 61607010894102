<div class="bingo-lobby-container-main">
    <div class="bt-rules-tandcs-wrapper">
        <mat-expansion-panel
            [expanded]="isExpanded"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            hideToggle="true"
            #dropdown
            [ngClass]="this.isExpansionDisabled">
            <mat-expansion-panel-header>
                <mat-panel-title class="panel-title" *ngIf="showHeader">
                    {{ sitecoreData?.textTranslations?.versionedList?.Rules }}
                </mat-panel-title>
                <mat-panel-description *ngIf="isCollapsible">
                    <mat-icon *ngIf="!panelOpenState">
                        <span class="theme-plus"></span>
                    </mat-icon>
                    <mat-icon *ngIf="panelOpenState">
                        <span class="theme-minus"></span>
                    </mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="bingo-bt-rules-container">
                <div *ngFor="let rule of rulesData" class="bingo-bt-rules-header">
                    <div class="bt-rules-graphic">
                        <img src="{{ rule.image.src }}" alt="rules-graphic1" draggable="false" />
                    </div>
                    <div class="bt-rules-text1">{{ rule.title }}</div>
                    <div class="bt-rules-text2" [innerHTML]="rule.text | trustAsHtml"></div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
</div>
