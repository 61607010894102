import { AsyncPipe, NgClass, NgFor } from '@angular/common';
import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';

import { ConfigProviderService } from '@casinocore/platform/core';
import { DslPipe } from '@frontend/vanilla/shared/browser';

import { NewsFeedV2CustomItemComponent } from '../news-feed-v2-custom-item/news-feed-v2-custom-item.component';
import { NewsFeedV2ItemComponent } from '../news-feed-v2-item/news-feed-v2-item.component';
import { NewsFeedV2Component } from '../news-feed-v2/news-feed-v2.component';

@Component({
    selector: 'cc-news-feed-v2-container',
    templateUrl: 'news-feed-v2-container.component.html',
    standalone: true,
    imports: [NewsFeedV2Component, NgFor, NgClass, NewsFeedV2ItemComponent, NewsFeedV2CustomItemComponent, DslPipe, AsyncPipe],
})
export class NewsFeedV2ContainerComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() newsFeeedItems: any;
    @Input() attributes: any;
    @Input() lobbyType: string;
    @Input() data: any;
    fillingArray: any;
    triggerForMobileDevices: boolean;
    newsFeedAttributes: any = {};

    constructor(private configProviderService: ConfigProviderService) {}

    ngOnInit() {
        this.fillingArray = new Array(5);
        this.triggerForMobileDevices = true;
    }
    ngAfterViewInit() {
        if (document.getElementsByClassName('news-feed-v2-wrapper')) {
            const newsfeedWrapperDoc = document.getElementsByClassName('news-feed-v2-wrapper');
            if (newsfeedWrapperDoc && newsfeedWrapperDoc.length > 0) {
                for (let i = 0; i < newsfeedWrapperDoc.length; i++) {
                    const doc: any = document.getElementsByClassName('news-feed-v2-wrapper')[i];
                    doc.style.animationPlayState = 'running';
                    doc.style.webkitAnimationPlayState = 'running';
                    doc.style.mozAnimationPlayState = 'running';
                    doc.style.oAnimationPlayState = 'running';
                }
            }
        }
    }
    ngOnChanges() {
        this.assigningAttributes();
    }
    touchStarted() {
        this.triggerForMobileDevices = false;
    }
    touchReleased() {
        this.triggerForMobileDevices = true;
    }
    assigningAttributes() {
        this.newsFeedAttributes.NewsFeedBoxOuterLineColor = this.getAttribute('NewsFeedBoxOuterLineColor');
        this.newsFeedAttributes.NewsFeedBoxBgcolor = this.getAttribute('NewsFeedBoxBgcolor');
        this.newsFeedAttributes.NewsFeedAnimationTiming = this.getAttribute('NewsFeedAnimationTiming');
        this.newsFeedAttributes.NewsFeedItemBgcolor = this.getAttribute('NewsFeedItemBgcolor');
        this.newsFeedAttributes.IconColor = this.getAttribute('IconColor');
        this.newsFeedAttributes.HeaderTextColor = this.getAttribute('HeaderTextColor');
        this.newsFeedAttributes.SubHeaderTextColor = this.getAttribute('SubHeaderTextColor');
        this.newsFeedAttributes.JackpotValueColor = this.getAttribute('JackpotValueColor');
        this.newsFeedAttributes.LiveCasinoBlackColor = this.getAttribute('LiveCasinoBlackColor');
        this.newsFeedAttributes.LiveCasinoRedColor = this.getAttribute('LiveCasinoRedColor');
        this.newsFeedAttributes.LiveCasinoGreenColor = this.getAttribute('LiveCasinoGreenColor');
        this.newsFeedAttributes.LiveNowText = this.getAttribute('LiveNowText');
        const NewsFeedAnimationTiming = this.getAttribute('NewsFeedAnimationTiming');
        if (NewsFeedAnimationTiming) {
            document.documentElement.style.setProperty('--newsfeed-animation-timing', NewsFeedAnimationTiming);
        }
    }
    getAttribute(key: string): string {
        const newsFeedConfig = this.configProviderService.provideNewsFeedCasinoConfig();
        return this.attributes && this.attributes[key] ? this.attributes[key] : newsFeedConfig.newsFeedConfiguration[key];
    }
}
