import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SliderComponent } from './slider.component';

@NgModule({
    declarations: [SliderComponent],
    exports: [SliderComponent],
    imports: [FormsModule],
})
export class SliderModule {
    public static forRoot(): ModuleWithProviders<SliderModule> {
        return {
            ngModule: SliderModule,
        };
    }
}
