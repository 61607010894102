<div class="bingo-tournaments-results-container-main">
    <div class="bingo-bt-race-info-container">
        <div class="bingo-bt-info-title-container">
            <span *ngIf="enableResultsHistoryPage && fromResult" class="theme-left bt-race-info-back" (click)="close()"></span>
            <span mat-dialog-title class="bt-race-info-title" [ngClass]="{ 'bt-race-info-title-left': enableResultsHistoryPage && fromResult }">{{
                title
            }}</span>
            <span class="ui-icon-size-lg theme-ex race-info-close" *ngIf="!fromResult" (click)="close()"></span>
        </div>
        <ng-container *ngIf="enableResultsHistoryPage && fromResult">
            <div class="bt-results-list-wrapper">
                <div class="bt-results-list-header">
                    <div class="bt-results-game bt-list-header-item">{{ versionedData.Game }}</div>
                    <div class="bt-results-date bt-list-header-item">{{ versionedData.Date }}</div>
                    <div class="bt-results-prize bt-list-header-item">{{ versionedData.Prize }}</div>
                    <div class="bt-results-place bt-list-header-item">{{ versionedData.YourPlace }}</div>
                    <div class="bt-results-points bt-list-header-item">{{ versionedData.Points }}</div>
                    <div class="bt-empty-div bt-list-header-item"></div>
                </div>
                <div class="bt-results-list-item">
                    <div class="bt-results-game bt-list-item">
                        <bgl-slot-races-icon
                            class="bgl-slot-races-icon"
                            [promoIcon]="raceResult?.content?.promotion?.promoIcon"
                            [isDraggable]="false"
                            [gameVariants]="raceResult?.gameVariants"
                            [isClickable]="false"></bgl-slot-races-icon>
                    </div>
                    <div class="bt-results-date bt-list-item">{{ raceResult.dateStr }}</div>
                    <div class="bt-results-prize bt-list-item" [ngClass]="{ 'bt-prize-lose-padding': !raceResult?.awardType }">
                        <div class="bt-theme-icon" [ngClass]="sharedData[raceResult?.awardType?.toLowerCase()]"></div>
                        <div class="bt-tickets" [ngClass]="{ 'bingo-currency-space': currencysymbol }">
                            {{
                                displayBonusAsText && raceResult?.awardType?.toLowerCase() === 'bonus' ? versionedData?.Bonus : raceResult?.awardValue
                            }}
                        </div>
                        <div *ngIf="raceResult?.isDisplayCoin" class="bt-bingo-coin-reward">
                            <span *ngIf="raceResult?.awardValue" class="theme-plus"></span>
                            <span class="bt-coin-img">
                                <img class="bt-coin-img" [src]="coinImg" alt="Coin Image" />
                                <span>{{ raceResult?.coinValue }}</span>
                            </span>
                        </div>
                        <!-- <div class="bt-prize-coins">15</div> -->
                    </div>
                    <div class="bt-results-place bt-list-item">
                        <div class="bt-trophy-img" [innerHtml]="raceResult?.prizeIcon | trustAsHtml"></div>
                        <div class="bt-trophy-place">{{ raceResult.rank }}</div>
                    </div>
                    <div class="bt-results-points bt-list-item">{{ raceResult.points }}</div>
                    <div class="bt-arrow-info theme-right"></div>
                </div>
            </div>
            <bgl-slot-races-leaderboard [results]="raceResult" [coinImg]="coinImg"></bgl-slot-races-leaderboard>
            <div class="bt-back-to-results">
                <div class="show-result-btn btn btn-primary" (click)="close()">{{ versionedData?.BackToResults }}</div>
            </div>
        </ng-container>
        <ng-container *ngIf="!fromResult">
            <div class="bingo-bt-race-info-card-container">
                <bgl-slot-race-card
                    [race]="race"
                    [showRaceInfo]="false"
                    [enableOptin]="raceinfoopt"
                    [showCardMenu]="false"
                    (closeRaceInfo)="close($event)"
                    (openPickGameOverlay)="openPickGameOverlay()"
                    (openNotificationOverlay)="openNotificationDialog()"
                    (gameLaunched)="close()"
                    [position]="0"
                    [coinImg]="coinImg"
                    [isInfoOpened]="true">
                </bgl-slot-race-card>
            </div>
            <div *ngIf="race.isLive; else upcomingRaceInfo">
                <bgl-slot-races-leaderboard
                    *ngIf="race.rankDetails && race.rankDetails.length > 0"
                    [race]="race"
                    [coinImg]="coinImg"></bgl-slot-races-leaderboard>
                <bgl-slots-races-rules-and-tnc [coinImg]="coinImg" [race]="race" [showTitleHeader]="false"></bgl-slots-races-rules-and-tnc>
            </div>
            <ng-template #upcomingRaceInfo>
                <div class="bingo-bt-race-info-tnc-container">
                    <bgl-slots-races-rules-and-tnc [race]="race" [coinImg]="coinImg" [showTitleHeader]="false"></bgl-slots-races-rules-and-tnc>
                </div>
            </ng-template>
        </ng-container>
    </div>
</div>
