<!-- It containes story containers and stories list-->
<div *ngIf="storyContainerLoad" class="casino-mobilestories-main" [ngStyle]="{ 'background-color': promotionsBgClr }">
    <div
        class="mobilestories-carousel"
        [ngClass]="{
            'storycarousal': !isTouch && storyContainerData?.storyList?.length <= 13 && storyTitlePosition === 'Bottom',
            'touch-mobilestories-carousel': isTouch,
            'touch-mobilestories-ipad': isTouch && storyContainerData?.storyList?.length > 1 && storyContainerData?.storyList?.length <= 8,
            'storycarousalmobile': isTouch && storyContainerData?.storyList?.length <= 4,
            'storycarousalmobileright': storyTitlePosition === 'Right' && storyContainerData?.storyList?.length <= 6
        }">
        <vn-swiper [swiperOptions]="swiperOptions">
            <div class="swiper-slide" *ngFor="let storiesData of storyContainerData.storyList; let i = index">
                <div class="mobilestories-list" [ngClass]="'storytitleposition-' + (storyTitlePosition | lowercase)">
                    <div
                        *ngIf="storiesData"
                        class="{{ storyIconShape }}"
                        [ngClass]="{ viewed: storiesData.storyViewed }"
                        (click)="storyClick(i)"
                        class="mobilestories-story"
                        [ngStyle]="{ 'border-color': storiesData.storyViewed ? viewedPromotionBrClr : notviewedPromotionBrClr }">
                        <img [src]="storiesData?.storyImage?.src" />
                    </div>
                    <span [ngStyle]="{ color: storiesData.storyViewed ? viewedpromotionTxtClr : promotionTxtClr }" class="mobilestories-story-name">{{
                        storiesData.title
                    }}</span>
                </div>
            </div>
        </vn-swiper>
    </div>
</div>
