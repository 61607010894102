import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { BingoSlotRaceCardModule } from '../cards-wrapper/card/bingo-card.module';
import { BingoNotificationPopUpModule } from '../notification-popup/bingo-notification-popup.module';
import { BingoNotificationOverlayComponent } from './bingo-notification-overlay.component';

@NgModule({
    imports: [CommonModule, BingoSlotRaceCardModule, BingoNotificationPopUpModule],
    declarations: [BingoNotificationOverlayComponent],
    exports: [BingoNotificationOverlayComponent],
})
export class BingoNotificationOverlayModule {
    public static forRoot(): ModuleWithProviders<BingoNotificationOverlayModule> {
        return {
            ngModule: BingoNotificationOverlayModule,
        };
    }
}
