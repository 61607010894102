import { NgClass } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { DesignSystemEnablerService, Race, SitecoreContent, SlotRacesResponseError, SlotRacesService } from '@casinocore/platform/core';
import { DecoratorClassDirective, SlotRaceCtaComponent } from '@frontend/casino-ui';
import { Subscription } from 'rxjs';

import { SlotRaceCardComponent } from '../cards-wrapper/card/card.component';
import { NotificationPopUpComponent } from '../notification-popup/notification-popup.component';

@Component({
    selector: 'cc-notification-overlay',
    templateUrl: 'notification-overlay.component.html',
    standalone: true,
    imports: [SlotRaceCardComponent, NgClass, SlotRaceCtaComponent, DecoratorClassDirective],
})
export class NotificationOverlayComponent implements OnInit, OnDestroy {
    race: Race;
    lobbyType: string;
    category: any;
    notificationSubscription: Subscription;
    contentSubscription: Subscription;
    raceNotificationStatus: boolean;
    messages: { [item: string]: string } = {};
    slotRacesSharedList: { [item: string]: string } = {};
    isLegacy: boolean = false;
    content: SitecoreContent = {
        raceRuleConfigs: [],
        rules: [],
        rulesBanner: {},
        placeholderCardImage: {},
        coinEconomy: {},
        textTranslations: { sharedList: {}, versionedList: {} },
        tips: [],
        errorMessages: { sharedList: {}, versionedList: {} },
        entryDetails: { sharedList: {}, versionedList: {} },
    };
    constructor(
        private dialogRef: MatDialogRef<NotificationOverlayComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private slotRacesService: SlotRacesService,
        private dialog: MatDialog,
        private dsEnablerService: DesignSystemEnablerService,
    ) {
        this.race = data.race;
        this.category = data.category;
        this.lobbyType = data.lobbyType;
    }

    ngOnInit() {
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.content = content;
            this.messages = content && content.textTranslations ? content.textTranslations.versionedList : {};
            this.slotRacesSharedList = content && content.textTranslations ? content.textTranslations.sharedList : {};
        });
        this.isLegacy = !this.dsEnablerService.isEnabled();
    }
    close(dialogResult: any = {}) {
        this.dialogRef.close(dialogResult);
        this.openDialog(dialogResult);
    }
    notificationCheck(value: boolean) {
        this.notificationSubscription = this.slotRacesService.updateNotificationStatus(this.race.promoId, this.race.slotUniqueId, value).subscribe(
            (response: any) => {
                if (response) {
                    this.raceNotificationStatus = value;
                    this.slotRacesService.setNotifyStatus(this.race.promoId, this.race.slotUniqueId, value);
                    this.close(value);
                }
            },
            (error: SlotRacesResponseError) => {
                this.slotRacesService.addErrorMessage(error.errorCode);
                this.close();
            },
        );
    }

    openDialog(dialogResult: any): void {
        const requiredModalData = {
            messages: this.messages,
            status: dialogResult,
        };
        const dialogRef = this.dialog.open(NotificationPopUpComponent, {
            width: '790px',
            data: requiredModalData,
            panelClass: 'casino-sr-notification-toaster',
        });

        dialogRef.afterClosed().subscribe(() => {});
    }
    ngOnDestroy() {
        if (this.notificationSubscription) {
            this.notificationSubscription.unsubscribe();
        }
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
    }
}
