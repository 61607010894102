<div *ngIf="race" [ngClass]="[race.beatBanker ? 'casino-beat-the-banker-prize' : '']">
    <div class="casino-lobby-container-main">
        <mat-expansion-panel
            [expanded]="isExpanded"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            hideToggle="true"
            #dropdown
            [ngClass]="isExpansionDisabled">
            <mat-expansion-panel-header>
                <mat-panel-title class="panel-title" *ngIf="showHeader">
                    <div class="srp-title-container">
                        {{ title }}
                    </div>
                </mat-panel-title>
                <mat-panel-description *ngIf="isCollapsible">
                    <mat-icon *ngIf="!panelOpenState">
                        <span class="theme-plus"></span>
                    </mat-icon>

                    <mat-icon *ngIf="panelOpenState">
                        <span class="theme-minus"></span>
                    </mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <div class="casino-srp-container">
                    <div class="casino-beat-the-banker" *ngIf="race.beatBanker">
                        <cc-beat-the-banker-notification
                            [prizeValueForBeatBanker]="prizeValueForBeatBanker"
                            [race]="race"
                            [coinImg]="coinImg"></cc-beat-the-banker-notification>
                    </div>
                    <mat-grid-list
                        *ngIf="prizesOne?.length"
                        cols="3"
                        rowHeight="113px"
                        gutterSize="0"
                        class="srp-grid-list"
                        [ngStyle]="{ width: prizesOneWidth + 'px' }">
                        <mat-grid-tile
                            *ngFor="let prize of prizesOne"
                            class="srp-grid-tile"
                            [ngClass]="{ 'prize-tile-small': !isMobile, 'prize-tile-large': isMobile }">
                            <ng-container *ngFor="let item of prize; let i = index">
                                <div *ngIf="item.awardType != 'COINS'">
                                    <div class="srp-img" *ngIf="item.Icon" [innerHtml]="item.Icon | trustAsHtml"></div>
                                    <div class="srp-rank">{{ item.rank }}</div>
                                    <div class="srp-prize" [innerHtml]="item.Description | trustAsHtml"></div>
                                </div>
                                <div *ngIf="item.awardType == 'COINS' || item?.isBeatBanker || item?.isCoinAward" [ngClass]="item?.beatBankerClass">
                                    <div *ngIf="item.isDisplayCoin" class="srp-coin-reward">
                                        <div class="srp-img" *ngIf="item.Icon && item?.showCoinPrize" [innerHtml]="item.Icon | trustAsHtml"></div>
                                        <div *ngIf="item?.showCoinPrize" class="srp-rank">{{ item.rank }}</div>
                                        <div class="coins-divs">
                                            <span *ngIf="!item?.showCoinPrize" class="theme-plus"></span>
                                            <span class="sr-card-total-coin">
                                                <img class="coin-img" [src]="coinImg" alt="Coin Image" />
                                                <span>{{ item.coinValue }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </mat-grid-tile>
                    </mat-grid-list>
                    <div style="width: 100%"></div>
                    <mat-grid-list
                        *ngIf="prizesTwo?.length"
                        cols="{{ prizesTwo?.length }}"
                        rowHeight="113px"
                        gutterSize="0"
                        class="srp-grid-list"
                        [ngStyle]="{ width: prizesTwoWidth + 'px' }">
                        <mat-grid-tile
                            *ngFor="let prize of prizesTwo"
                            [ngClass]="{ 'prize-tile-small': !isMobile, 'prize-tile-large': isMobile }"
                            class="srp-grid-tile">
                            <ng-container *ngFor="let item of prize; let i = index">
                                <div *ngIf="item.awardType != 'COINS'">
                                    <div class="srp-img" *ngIf="item.Icon" [innerHtml]="item.Icon | trustAsHtml"></div>
                                    <div class="srp-rank">{{ item.rank }}</div>
                                    <div class="srp-prize" [innerHtml]="item.Description | trustAsHtml"></div>
                                </div>
                                <div *ngIf="item.awardType == 'COINS' || item?.isBeatBanker || item?.isCoinAward" [ngClass]="item?.beatBankerClass">
                                    <div *ngIf="item.isDisplayCoin" class="srp-coin-reward coin">
                                        <div class="srp-img" *ngIf="item.Icon && item?.showCoinPrize" [innerHtml]="item.Icon | trustAsHtml"></div>
                                        <div *ngIf="item?.showCoinPrize" class="srp-rank">{{ item.rank }}</div>
                                        <div class="coins-divs">
                                            <span *ngIf="!item?.showCoinPrize" class="theme-plus"></span>
                                            <span class="sr-card-total-coin">
                                                <img class="coin-img" [src]="coinImg" alt="Coin Image" />
                                                <span>{{ item.coinValue }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </mat-grid-tile>
                    </mat-grid-list>
                    <mat-grid-list
                        *ngIf="participateReward"
                        cols="1"
                        rowHeight="113px"
                        gutterSize="0"
                        class="srp-grid-list"
                        [ngStyle]="{ width: participationRewardWidth + 'px' }">
                        <mat-grid-tile [ngClass]="{ 'prize-tile-small': !isMobile, 'prize-tile-large': isMobile }" class="srp-grid-tile">
                            <ng-container>
                                <div class="Participant-Reward">
                                    <div class="sr-card-total-coin">
                                        <img class="coin-img" [src]="coinImg" alt="Coin Image" />
                                    </div>
                                    <div class="Coin-Value">{{ participateReward?.value }} {{ data?.textTranslations?.versionedList?.Coin }}</div>
                                    <div class="Coin-StaticText">({{ data?.textTranslations?.versionedList?.ParticipateReward }})</div>
                                </div>
                            </ng-container>
                        </mat-grid-tile>
                    </mat-grid-list>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
</div>
