<div
    class="casino-news-feed-item-wrapper"
    [ngStyle]="{ 'border-color': attributes.NewsFeedBoxOuterLineColor, 'background-color': attributes.NewsFeedItemBgcolor }"
    (click)="itemCliked()">
    <div class="casino-news-feed-icon-v2 casino-news-valign" *ngIf="newsFeedItem.iconClass">
        <i class="{{ newsFeedItem.iconClass }}" [ngStyle]="{ color: attributes.IconColor }"></i>
    </div>
    <div class="casino-news-feed-content-v2 casino-news-valign">
        <div class="news-feed-title-v2" [ngStyle]="{ color: attributes.HeaderTextColor }">{{ newsFeedItem.header }}</div>
        <div class="news-feed-name-v2" [ngStyle]="{ color: attributes.SubHeaderTextColor }">{{ newsFeedItem.subHeader }}</div>
    </div>
    <div class="news-feed-jackpot-v2 casino-news-valign" [ngStyle]="{ color: attributes.JackpotValueColor }">
        {{ newsFeedItem.value }}
    </div>
</div>
