<div class="casino-lobby-container-main">
    <div class="casino-sr-notification-message">
        <div class="notification-body">
            <div class="col-md-12 notification-header">
                <div>{{ messages.NotificationMsgHeader }}</div>
                <div class="notification-close" (click)="close()"><i class="theme-ex"></i></div>
            </div>
            <div class="col-md-12 notification-footer">
                <cc-slot-race-card
                    [race]="race"
                    [category]="category"
                    [lobbyType]="lobbyType"
                    [position]="0"
                    [raceNotificationStatus]="raceNotificationStatus">
                </cc-slot-race-card>
            </div>
            <div class="col-md-12 notification-text">
                <p>{{ messages.NotificationDescription }}</p>
                <p class="small-text">{{ messages.notificationDiscriptionTwo }}</p>
            </div>

            <div class="col-md-12 notification-btn-container">
                <p>
                    <!-- <button class="no btn btn-md btn-light" (click)="notificationCheck(false)">
                        {{ messages.NotificationOFF }} <i class="alert-icon"
                            [ngClass]="slotRacesSharedList.notificationBellOffIcon"></i>
                    </button>
                    <button class="yes btn btn-md btn-primary" (click)="notificationCheck(true)">
                        {{ messages.NotificationON }} <i class="alert-icon"
                            [ngClass]="slotRacesSharedList.notificationBellOnIcon"></i>
                    </button> -->
                    <cs-slotrace-cta
                        [isLegacy]="isLegacy"
                        [kind]="'secondary'"
                        [variant]="'filled'"
                        [size]="'large'"
                        [csDecoratorClass]="'no btn-md btn-light'"
                        [startIconClass]="slotRacesSharedList.notificationBellOffIcon"
                        [cta_text]="messages.NotificationOFF"
                        (click)="notificationCheck(false)">
                    </cs-slotrace-cta>
                    <cs-slotrace-cta
                        [isLegacy]="isLegacy"
                        [kind]="'primary'"
                        [size]="'large'"
                        [csDecoratorClass]="'yes btn-md'"
                        [startIconClass]="slotRacesSharedList.notificationBellOnIcon"
                        [cta_text]="messages.NotificationON"
                        (click)="notificationCheck(true)">
                    </cs-slotrace-cta>
                </p>
            </div>
        </div>
    </div>
</div>
