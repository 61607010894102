<div class="casino-betathebanker-prize-container">
    <div class="bt-info-container">
        <div class="info-title">{{ messages.beatBankerTitle }}</div>
        <div class="info-label">{{ messages.beatBankerDiscription }}</div>
    </div>

    <div class="points-and-revards">
        <div class="bt-points">
            <span>{{ minPoints }} Pts</span>
        </div>
        <div class="rewards">
            <span class="reward1">{{ messages.beatBankerRevardText }}</span>
            <span *ngIf="race?.displayCash" class="reward2">{{
                prizeValueForBeatBanker?.Description ? prizeValueForBeatBanker?.Description : prizeValueForBeatBanker?.value
            }}</span>
            <div *ngIf="race?.isDisplayCoin" class="srp-coin-reward">
                <span *ngIf="race?.displayCash" class="theme-plus"></span>
                <span class="sr-card-total-coin">
                    <img class="coin-img" [src]="coinImg" alt="Coin Image" />
                    <span>{{ race?.coinValue }}</span>
                </span>
            </div>
        </div>
    </div>
</div>
