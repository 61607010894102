import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';

import { BingoSlotRacesPrizesModule } from '../race-info/prizes/bingo-prizes.module';
import { BingoSlotRacesPointsModule } from './bingo-points/bingo-points.module';
import { BingoSlotRacesRulesAndTnCComponent } from './bingo-rules-and-tnc.component';
import { BingoSlotRacesRulesModule } from './bingo-rules/bingo-rules.module';
import { BingoSlotRacesTipsModule } from './bingo-tips/bingo-tips.module';
import { BingoSlotRacesTnCModule } from './bingo-tnc/bingo-tnc.module';

@NgModule({
    imports: [
        CommonModule,
        BingoSlotRacesTnCModule,
        BingoSlotRacesTipsModule,
        BingoSlotRacesRulesModule,
        BingoSlotRacesPointsModule,
        MatDialogModule,
        MatExpansionModule,
        BingoSlotRacesPrizesModule,
    ],
    declarations: [BingoSlotRacesRulesAndTnCComponent],
    exports: [BingoSlotRacesRulesAndTnCComponent],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
    ],
})
export class BingoSlotRacesrulesAndTnCModule {
    public static forRoot(): ModuleWithProviders<BingoSlotRacesrulesAndTnCModule> {
        return {
            ngModule: BingoSlotRacesrulesAndTnCModule,
        };
    }
}
