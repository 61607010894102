import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';

import { BingoSlotRacesTipsComponent } from './bingo-tips.component';

@NgModule({
    imports: [CommonModule, TrustAsHtmlPipe, MatExpansionModule, MatIconModule],
    declarations: [BingoSlotRacesTipsComponent],
    exports: [BingoSlotRacesTipsComponent],
})
export class BingoSlotRacesTipsModule {
    public static forRoot(): ModuleWithProviders<BingoSlotRacesTipsModule> {
        return {
            ngModule: BingoSlotRacesTipsModule,
            providers: [],
        };
    }
}
