import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BingoTournamentsService, Race, SitecoreContent } from '@frontend/bingo/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'bgl-slot-races-pick-game',
    templateUrl: 'bingo-pick-game-overlay.component.html',
})
export class BingoPickGameOverlayComponent implements OnInit, OnDestroy {
    bgRooms: any;
    race: Race;
    isLive: boolean;
    isUpcoming: boolean;
    raceinfoopt: any;
    breakpoint: number = 4;
    messages: { [item: string]: string } = {};
    contentSubscription: Subscription;
    liveRacesSubscription: Subscription;
    isMobileView: boolean;
    coinImg: string;
    constructor(
        private dialogRef: MatDialogRef<BingoPickGameOverlayComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private slotRacesService: BingoTournamentsService,
    ) {
        this.race = data.race;
        this.isLive = !!this.race.isLive;
        this.isUpcoming = !this.isLive;
        this.raceinfoopt = data.enableOptin;
        this.coinImg = data.coinImg;
    }

    ngOnInit() {
        this.adjustBreakpoint();
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.messages = content && content.textTranslations ? content.textTranslations.versionedList : {};
        });
        this.liveRacesSubscription = this.slotRacesService.liveRacesData.subscribe((data) => {
            if (data) {
                this.updateRaceStatus(data);
            }
        });
    }

    @HostListener('window:orientationchange')
    @HostListener('window:resize')
    adjustBreakpoint() {
        if (window.innerWidth < 768 || document.body.offsetWidth < 768) {
            this.breakpoint = 3;
        } else {
            this.breakpoint = 4;
        }
    }

    close(dialogResult: any = {}) {
        this.dialogRef.close(dialogResult);
    }

    openRaceInfoDialog(): void {
        this.close({ openRaceInfo: true });
    }
    openNotificationDialog() {
        this.close({ openNotificationOverlay: true });
    }
    launchGame() {
        this.slotRacesService.LaunchBingoGame(this.race);
    }

    updateRaceStatus(data: any) {
        const liveRaces: any[] = data.liveNow;
        const isLiveRace = liveRaces.some((race: Race) => {
            if (race.promoId == this.race.promoId && race.slotUniqueId == this.race.slotUniqueId) {
                this.race.isLive = true;
                return this.race.isLive;
            }
            return null;
        });
        const isUpcomingRace = !isLiveRace;
        if (this.isLive !== isLiveRace || this.isUpcoming !== isUpcomingRace) {
            this.close();
        }
    }

    ngOnDestroy() {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
        if (this.liveRacesSubscription) {
            this.liveRacesSubscription.unsubscribe();
        }
    }
}
