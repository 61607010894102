import { Injectable } from '@angular/core';

import { DeviceService, NativeAppService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { BingoConfigProviderService } from '../../bingo-config-provider/services/bingo-config-provider.service';

@Injectable({
    providedIn: 'root',
})
export class BingoManager {
    trackingBingoTournaments = 'BingoTournaments';
    private isTouchDevice: boolean;
    currentWidth: number;
    private reSizePublisher = new BehaviorSubject<any>(null);
    reSizeObservable: Observable<any> = this.reSizePublisher.asObservable();
    constructor(
        private deviceService: DeviceService,
        private nativeAppService: NativeAppService,
        private bingoConfigProviderService: BingoConfigProviderService,
    ) {}

    checkForTouchDevice() {
        try {
            this.isTouchDevice =
                !this.nativeAppService.isDownloadClient &&
                this.deviceService.isTouch &&
                !window.matchMedia('(pointer: fine)').matches &&
                !navigator.userAgent.match(/Win64/ || /WOW64/);
        } catch (error) {
            this.isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        }
    }

    isTouch(): boolean {
        this.checkForTouchDevice();
        return this.isTouchDevice;
    }
    setCurrentWidth() {
        if (this.deviceService.isiOS) {
            this.currentWidth = document.body.offsetWidth;
        } else {
            this.currentWidth = window.innerWidth;
        }
    }

    getCurrentWidth() {
        return this.currentWidth;
    }
    checkForResize(resizeWidth: any) {
        if (this.currentWidth != resizeWidth) {
            this.currentWidth = resizeWidth;
            this.reSizePublisher.next(this.currentWidth);
        }
    }
}
