import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BingoSlotRacesWrapperComponent } from './bingo-slot-races-wrapper.component';
import { BingoSlotRacesModule } from './bingo-slot-races.module';

const routes: Routes = [
    {
        path: '',
        component: BingoSlotRacesWrapperComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes), BingoSlotRacesModule],
    exports: [RouterModule],
})
export class BingoTournamentRoutingModule {}
