import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AlertMessageComponent } from './alert-message.component';

@NgModule({
    declarations: [AlertMessageComponent],
    exports: [AlertMessageComponent],
    imports: [CommonModule],
})
export class AlertMessageModule {}
