import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TouchSpinComponent } from './touch-spin.component';

@NgModule({
    declarations: [TouchSpinComponent],
    exports: [TouchSpinComponent],
    imports: [FormsModule, CommonModule],
})
export class TouchSpinModule {
    public static forRoot(): ModuleWithProviders<TouchSpinModule> {
        return {
            ngModule: TouchSpinModule,
        };
    }
}
