<div class="bingo-tournaments-results-container-main">
    <div class="bt-result-header">
        <div class="bt-results-title">{{ messages.MyResults }}</div>
        <div (click)="closeDialog()" class="ui-icon-size-lg cls-btn theme-ex"></div>
    </div>
    <div class="bt-results-calander">
        <div class="bt-search-for-results">{{ messages.Searchforresults }}</div>
        <div class="bt-calander">
            <div class="bt-results-tabs">
                <div class="form-control-tabs-segmented-v2">
                    <input type="radio" name="2tabs" value="tb1" id="tb1" checked="" class="form-control-tabs-segmented-v2-input" />
                    <label for="tb1" class="form-control-tabs-segmented-v2-label" (click)="showAllResults()">{{ messages.All }}</label>
                    <input type="radio" name="2tabs" value="tb2" id="tb2" class="form-control-tabs-segmented-v2-input" />
                    <label for="tb2" class="form-control-tabs-segmented-v2-label" (click)="showWinResults()">{{ messages.Wins }}</label>
                </div>
            </div>
            <div class="bt-select-date">
                <div class="bt-select-from">
                    <mat-form-field>
                        <input
                            matInput
                            [min]="fromMinDate"
                            [max]="fromMaxDate"
                            [matDatepicker]="pickerFrom"
                            [placeholder]="messages?.From"
                            (dateChange)="fromChangeDate($event)"
                            readonly
                            (click)="pickerFrom.open()" />
                        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                        <mat-datepicker #pickerFrom disabled="false" panelClass="casino-datepicker"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="bt-select-to">
                    <mat-form-field>
                        <input
                            matInput
                            [min]="toMinDate"
                            [max]="toMaxDate"
                            [matDatepicker]="pickerTo"
                            (dateChange)="toChangeDate($event)"
                            [placeholder]="messages?.To"
                            readonly
                            (click)="pickerTo.open()" />
                        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                        <mat-datepicker #pickerTo disabled="false" panelClass="casino-datepicker"></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="bt-show-result-btn">
                <button (click)="showResults()" class="btn-primary btn btn-lg">{{ messages.ShowResults }}</button>
            </div>
        </div>

        <div
            class="bt-results-list-wrapper"
            *ngIf="results && results.length > 0"
            [ngClass]="{ 'bt-result-history-enabled': enableResultsHistoryPage }">
            <div *ngIf="initialLoad" class="bt-last-tounaments-text">{{ messages.Last5Races }}</div>
            <div class="bt-results-list-header">
                <div class="bt-results-game bt-list-header-item">{{ messages.Game }}</div>
                <div class="bt-results-date bt-list-header-item">{{ messages.Date }}</div>
                <div class="bt-results-prize bt-list-header-item">{{ messages.Prize }}</div>
                <div class="bt-results-place bt-list-header-item">{{ messages.YourPlace }}</div>
                <div class="bt-results-points bt-list-header-item">{{ messages.Points }}</div>
                <div *ngIf="enableResultsHistoryPage" class="bt-empty-div bt-list-header-item"></div>
            </div>
            <div class="bt-results-list-item" *ngFor="let result of results | resultsType: showAll">
                <div class="bt-results-game bt-list-item">
                    <bgl-slot-races-icon
                        class="bgl-slot-races-icon"
                        [promoIcon]="result?.content?.promotion?.promoIcon"
                        [isDraggable]="false"
                        [isClickable]="false"
                        [gameVariants]="result?.gameVariants"></bgl-slot-races-icon>
                </div>
                <div class="bt-results-date bt-list-item">{{ result.dateStr }}</div>
                <div class="bt-results-prize bt-list-item">
                    <div class="bt-theme-icon" [ngClass]="content?.textTranslations?.sharedList[result?.awardType?.toLowerCase()]"></div>
                    <div
                        class="bt-tickets"
                        [ngClass]="{ 'no-award-padding': result.awardValue === '--' && !result?.awardType, 'bingo-currency-space': currencysymbol }">
                        {{ displayBonusAsText && result?.awardType?.toLowerCase() === 'bonus' ? messages?.Bonus : result?.awardValue }}
                    </div>
                    <div *ngIf="result?.isDisplayCoin" class="bgl-coin-reward">
                        <span *ngIf="result?.awardValue" class="theme-plus"></span>
                        <div class="bt-coin-img">
                            <img class="coin-img" [src]="coinSrc" alt="Coin Image" />
                        </div>
                    </div>
                    <div class="bt-prize-coins">{{ result?.coinValue }}</div>
                </div>
                <div class="bt-results-place bt-list-item">
                    <div class="bt-trophy-img" [innerHtml]="result.prizeIcon | trustAsHtml"></div>
                    {{ result.rank }}
                </div>
                <div class="bt-results-points bt-list-item">{{ result.points }}</div>
                <div class="bt-arrow-info theme-right" (click)="openRaceInfoDialog(result)" *ngIf="enableResultsHistoryPage">&nbsp;</div>
            </div>
        </div>

        <div class="bt-no-results-found" *ngIf="showNoResults">{{ resultsErrorMsg }}</div>
    </div>
</div>
