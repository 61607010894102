import { NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Race, SitecoreContent, SlotRacesService } from '@casinocore/platform/core';
import { PageMatrixComponent } from '@frontend/vanilla/features/content';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';

@Component({
    selector: 'cc-slot-races-tnc',
    templateUrl: './tnc.component.html',
    standalone: true,
    imports: [NgIf, PageMatrixComponent, TrustAsHtmlPipe],
})
export class SlotRacesTnCComponent implements OnInit, OnDestroy {
    @Input() showHeader: boolean;
    @Input() isCollapsible: boolean;
    @Input() isExpanded: boolean;
    @Input() race: Race;

    isVisible: boolean;
    panelOpenState: boolean;
    contentSubscription: any;
    sitecoreData: any;
    tncData: any;
    constructor(private slotRacesService: SlotRacesService) {}

    ngOnInit() {
        this.isVisible = false;
        this.changeCollapseState();
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.sitecoreData = content;
            this.setTnc();
        });
    }
    ngOnDestroy() {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
    }
    changeCollapseState() {
        this.isVisible = !this.isVisible;
    }

    setTnc() {
        if (this.race) {
            if (this.race.content && this.race.content.promotion && this.race.content.promotion.termsAndConditionsText) {
                this.tncData = eval('`' + this.race.content.promotion.termsAndConditionsText + '`');
            } else {
                this.tncData = '';
            }
        }
    }
}
