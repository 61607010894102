import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { BingoSlotRaceCardModule } from '../cards-wrapper/card/bingo-card.module';
import { BingoNotificationPopUpComponent } from './bingo-notification-popup.component';

@NgModule({
    imports: [CommonModule, BingoSlotRaceCardModule],
    declarations: [BingoNotificationPopUpComponent],
    exports: [BingoNotificationPopUpComponent],
})
export class BingoNotificationPopUpModule {
    public static forRoot(): ModuleWithProviders<BingoNotificationPopUpModule> {
        return {
            ngModule: BingoNotificationPopUpModule,
        };
    }
}
