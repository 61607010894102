import { NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { NewsFeedV2Service } from '@casinocore/platform/core';
import { Subscription } from 'rxjs';

import { NewsFeedV2ContainerComponent } from '../news-feed-v2-container/news-feed-v2-container.component';

@Component({
    selector: 'cc-news-feed-v2-wrapper',
    templateUrl: 'news-feed-v2-wrapper.component.html',
    standalone: true,
    imports: [NgIf, NewsFeedV2ContainerComponent],
})
export class NewsFeedV2WrapperComponent implements OnInit, OnDestroy {
    @Input() containerPath: string;
    @Input() trackData: any;
    newsFeedResponse: any = [];
    newsFeedAttributes: any;
    newFeedWidgetData: Subscription;
    constructor(private newsFeedV2Service: NewsFeedV2Service) {}
    ngOnInit() {
        this.newsFeedV2Service.setNewsFeedContent(this.containerPath);
        this.newFeedWidgetData = this.newsFeedV2Service.newsFeedV2WidgetObservable.subscribe((response: any) => {
            if (response) {
                this.newsFeedResponse = response.newsFeedObjList;
                this.newsFeedAttributes = response.attributes;
                if (this.newsFeedResponse && this.newsFeedResponse.length > 0) {
                    this.createNewsFeedResponseArray(this.newsFeedResponse);
                }
            }
        });
    }
    createNewsFeedResponseArray(newsFeedResponse: any) {
        this.newsFeedResponse = this.newsFeedV2Service.setNewsFeedResponseArray(newsFeedResponse);
    }
    ngOnDestroy() {
        if (this.newFeedWidgetData) {
            this.newFeedWidgetData.unsubscribe();
        }
    }
}
