import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'bgApiPathsConfig', product: ClientConfigProductName.BINGO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: bingoApiPathsClientConfigFactory,
})
export class BingoApiPathsClientConfig {
    bingoTournaments: any;
}
export function bingoApiPathsClientConfigFactory(service: LazyClientConfigService) {
    return service.get(BingoApiPathsClientConfig);
}
