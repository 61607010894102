import { LowerCasePipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import {
    FetchUserRequestAPIModel,
    FetchUserResponseAPIModel,
    GamingStoriesPhase2Configuration,
    GamingStoriesService,
    IGamingStoryContainer,
    IGamingStoryItem,
    IOption,
    ResponseDataModel,
    StoryInfoData,
    StoryInfoDataInSessionStorage,
} from '@casinocore/platform/core';
import { DynamicHtmlDirective, LoginDialogService, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { Subscription, filter } from 'rxjs';

@Component({
    selector: 'cc-gaming-story-yesno-poll',
    templateUrl: 'gaming-story-yesno-poll.component.html',
    standalone: true,
    imports: [NgIf, NgClass, NgStyle, NgFor, DynamicHtmlDirective, LowerCasePipe],
})
export class GamingStoryYesNoPollComponent implements OnInit, OnDestroy, OnChanges {
    @Input() storyItem: IGamingStoryItem;
    @Input() fetchUserPreparedRequest: FetchUserRequestAPIModel;
    @Input() storyContainerData: IGamingStoryContainer;
    @Input() fetchingGamingStoriesAPIRes: FetchUserResponseAPIModel;

    selectedKey: string;
    isDisabled: boolean;
    yesPollTextClr: string;
    yesPollBgClr: string;
    noPollBgClr: string;
    noPollTextClr: string;
    isAuthenticated: boolean;
    yesBtnFontSize: number;
    noBtnFontSize: number;
    descriptionPosition: string;
    subTitlePosition: string;
    subTitleTxtClr: string;
    subTitleFontSize: string;
    descriptionClr: string;
    descriptionFontSize: string;

    private userSessionStorageData: StoryInfoDataInSessionStorage;
    private gamingStoriesconfig: GamingStoriesPhase2Configuration;
    private expirationTime: number;
    private templateType: string = 'YesOrNoPoll';
    private uAvgResponse: any;
    private yesBtnWidth: number;
    private noBtnWidth: number;
    private userSubscription: Subscription;
    private showSiteCoreData: boolean = false;
    private trackStoriesSkip: boolean = false;

    constructor(
        private gamingStoriesService: GamingStoriesService,
        private user: UserService,
        private loginDialog: LoginDialogService,
    ) {}

    ngOnChanges() {
        this.setAttributes();
        this.selectedKey = '';
        this.isDisabled = false;
    }

    ngOnInit(): void {
        this.setAttributes();
        this.userSubscription = this.user.events.pipe(filter((e: any) => e instanceof UserLoginEvent)).subscribe(() => {
            setTimeout(() => {
                this.isAuthenticated = this.user.isAuthenticated;
                this.appendUserIdToPreLoginSession();
                this.validatePreLoginSessionToStoreInPostLoginSession();
            }, 1000);
        });
        this.postvalidationCheck();
    }

    getYesNoPollStyles(itemVal: string, selectedKey: string) {
        let myStyles;
        if (this.isAuthenticated && itemVal?.toLowerCase() === 'yes' && selectedKey?.toLowerCase() === 'yes') {
            myStyles = {
                'color': this.yesPollTextClr,
                'font-size': this.yesBtnFontSize,
                'background-color': this.yesPollBgClr,
            };
        } else if (this.isAuthenticated && itemVal?.toLowerCase() === 'no' && selectedKey?.toLowerCase() === 'no') {
            myStyles = {};
            myStyles = {
                'color': this.noPollTextClr,
                'font-size': this.noBtnFontSize,
                'background-color': this.noPollBgClr,
            };
        } else {
            myStyles = {};
        }
        return myStyles;
    }

    optionsClicked(key: string) {
        this.gamingStoriesconfig = this.gamingStoriesService.config;
        if (this.gamingStoriesconfig) {
            this.expirationTime = this.gamingStoriesconfig.expirationTime;
        }
        this.selectedKey = key;
        if (this.isAuthenticated) {
            this.gamingStoriesService.pauseStory(false);
            if (key !== undefined) {
                this.gamingStoriesService.trackingGamingStoriesPhase2Event(
                    'teaser - embedded video',
                    'stories',
                    'click',
                    this.storyItem.description.replace(/<[^>]*>/g, ''),
                    this.storyItem.title,
                    key,
                    this.storyItem.actionTypePosition,
                    'not applicable',
                );
                const storyInfo: StoryInfoData = new StoryInfoData();
                storyInfo.storyId = this.storyItem.storyId;
                storyInfo.subStoryId = this.storyItem.storyItemId;
                storyInfo.storyType = this.storyItem.storyType;
                storyInfo.templateType = this.templateType;
                storyInfo.userInput = key;
                storyInfo.expTime = this.storyItem.expiryTime;
                storyInfo.storyIndex = 2;
                // this.gamingStoriesService.storyInfoPostRequestList = this.gamingStoriesService.storyInfoPostRequestList.filter(
                //     (x) => x.storyIndex !== storyInfo.storyIndex,
                // );
                this.gamingStoriesService.storyInfoRequestItemsList(storyInfo);
                this.isDisabled = true;
                this.appendInputOrUavgbasedonSelectedPostData(this.selectedKey);
            }
        } else {
            this.gamingStoriesService.storyInfoPostRequestList = [];
            this.gamingStoriesService.pauseStory(true);
            const loginDialogRef = this.loginDialog.open();
            const afterclosesubcription: Subscription = loginDialogRef.afterClosed().subscribe(() => {
                if (afterclosesubcription) {
                    if (!this.isAuthenticated) {
                        this.gamingStoriesService.pauseStory(false);
                        this.isDisabled = false;
                        const current = document.getElementsByClassName('mobilestories-options active');
                        if (current.length > 0) {
                            current[0].className = current[0].className.replace('mobilestories-options active', 'mobilestories-options');
                        }
                    }
                    afterclosesubcription.unsubscribe();
                }
            });
        }
        this.storeDataToPreLoginSession(key);
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    private setAttributes() {
        this.isAuthenticated = this.user.isAuthenticated;
        this.yesPollTextClr = this.storyContainerData.sharedList.yesPollTextClr;
        this.yesPollBgClr = this.storyContainerData.sharedList.yesPollBgClr;
        this.noPollTextClr = this.storyContainerData.sharedList.noPollTextClr;
        this.noPollBgClr = this.storyContainerData.sharedList.noPollBgClr;
        this.yesBtnFontSize = this.storyContainerData.sharedList.yesBtnFontSize;
        this.noBtnFontSize = this.storyContainerData.sharedList.noBtnFontSize;
        this.yesBtnWidth = this.storyContainerData.sharedList.yesBtnWidth;
        this.noBtnWidth = this.storyContainerData.sharedList.noBtnWidth;
        this.subTitlePosition = this.storyItem.subTitlePosition;
        this.descriptionPosition = this.storyItem.descriptionPosition;
        this.subTitleTxtClr = this.storyItem.configurations.subTitleTxtClr;
        this.subTitleFontSize = this.storyItem.configurations.subTitleFontSize;
        this.descriptionClr = this.storyItem.configurations.descriptionClr;
        this.descriptionFontSize = this.storyItem.configurations.descriptionFontSize;
    }

    private storeDataToPreLoginSession(eleName: string) {
        const currentEpocTime = new Date().getTime() / 1000;
        const storyInfoDataInSessionStorage: StoryInfoDataInSessionStorage = new StoryInfoDataInSessionStorage();
        storyInfoDataInSessionStorage.response = new ResponseDataModel();
        storyInfoDataInSessionStorage.response.sId = this.storyItem.storyId;
        storyInfoDataInSessionStorage.response.subSId = this.storyItem.storyItemId;
        storyInfoDataInSessionStorage.response.uInput = eleName;
        storyInfoDataInSessionStorage.response.tType = this.templateType;
        storyInfoDataInSessionStorage.response.uAvg = this.uAvgResponse;
        storyInfoDataInSessionStorage.expiryTime = Math.round(currentEpocTime + this.expirationTime * 60);
        storyInfoDataInSessionStorage.userId = this.user.id ? this.user.id : '';
        storyInfoDataInSessionStorage.storyIndex = 2;
        this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList =
            this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList.filter(
                (x) => x.storyIndex !== storyInfoDataInSessionStorage.storyIndex,
            );
        if (!this.user.isAuthenticated) {
            this.gamingStoriesService.storingDataInPreLoginSessionStorage(storyInfoDataInSessionStorage);
        }
    }

    private appendInputOrUavgbasedonSelectedPostData(selectedOption: string) {
        if (sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage')) {
            const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage') as string);
            sessionStorageServiceStoryData.forEach((ysessionres: any) => {
                if (
                    ysessionres.userId === this.user.id &&
                    ysessionres.response.sId === this.storyItem.storyId &&
                    ysessionres.response.subSId === this.storyItem.storyItemId &&
                    ysessionres.response.uInput === undefined &&
                    ysessionres.response.uAvg === undefined
                ) {
                    ysessionres.response.uInput = selectedOption;
                    ysessionres.response.uAvg = new Map();
                    ysessionres.response.uAvg[ysessionres.response.uInput.toLowerCase()] = 100;
                    this.bindQuizData(ysessionres.response);
                } else if (
                    ysessionres.userId === this.user.id &&
                    ysessionres.response.sId === this.storyItem.storyId &&
                    ysessionres.response.subSId === this.storyItem.storyItemId &&
                    ysessionres.response.uInput === undefined &&
                    ysessionres.response.uAvg !== undefined
                ) {
                    ysessionres.response.uInput = selectedOption;
                    this.bindQuizData(ysessionres.response);
                }
            });
            sessionStorage.setItem('GS:StoryItemsInPostLoginSessionStorage', JSON.stringify(sessionStorageServiceStoryData));
        }
        // if (sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage')) {
        //   let sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage'));
        //   sessionStorageServiceStoryData.forEach(ysessionres => {
        //     if (ysessionres.userId === this.user.id && ysessionres.response.sId === this.storyItem.storyId && ysessionres.response.subSId === this.storyItem.storyItemId
        //       && ysessionres.response.uInput === undefined && ysessionres.response.uAvg !== undefined) {
        //       ysessionres.response.uInput = selectedOption;
        //       this.bindQuizData(ysessionres.response);
        //     }
        //   });
        //   sessionStorage.setItem('GS:StoryItemsInPostLoginSessionStorage', JSON.stringify(sessionStorageServiceStoryData));
        // }
    }

    private appendUserIdToPreLoginSession() {
        if (sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage')) {
            this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList.forEach((res) => {
                if (res.response.sId === this.storyItem.storyId && res.response.subSId === this.storyItem.storyItemId && res.userId === '') {
                    res.userId = this.user.id ? this.user.id : '';
                    this.userSessionStorageData = res;
                }
            });
            if (this.userSessionStorageData !== undefined) {
                this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList =
                    this.gamingStoriesService.storyInfoDataInPreLoginSessionStorageList.filter(
                        (x) => x.storyIndex !== this.userSessionStorageData.storyIndex,
                    );
                this.gamingStoriesService.storingDataInPreLoginSessionStorage(this.userSessionStorageData);
                this.preparePreLoginSelectedDataForPostAPI(this.userSessionStorageData);
            }
        }
    }

    private preparePreLoginSelectedDataForPostAPI(storyInfoData: StoryInfoDataInSessionStorage) {
        const storyInfo: StoryInfoData = new StoryInfoData();
        storyInfo.storyId = storyInfoData.response.sId;
        storyInfo.subStoryId = storyInfoData.response.subSId;
        storyInfo.storyType = this.storyItem.storyType;
        storyInfo.templateType = this.templateType;
        storyInfo.userInput = storyInfoData.response.uInput;
        storyInfo.expTime = this.storyItem.expiryTime;
        storyInfo.storyIndex = 2;
        this.gamingStoriesService.storyInfoPostRequestList = this.gamingStoriesService.storyInfoPostRequestList.filter(
            (x) => x.storyIndex !== storyInfo.storyIndex,
        );
        this.gamingStoriesService.storyInfoRequestItemsList(storyInfo);
    }

    private validatePreLoginSessionToStoreInPostLoginSession() {
        // if fetch API response available.
        // we are not storing selected data any where when it matches with fetch api response.
        if (this.fetchingGamingStoriesAPIRes) {
            if (sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage')) {
                const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage') as string);
                this.fetchingGamingStoriesAPIRes.response.forEach((fetchData: any) => {
                    sessionStorageServiceStoryData.forEach((res: any) => {
                        if (
                            res.userId === this.fetchingGamingStoriesAPIRes.uId &&
                            res.response.sId === fetchData.sId &&
                            res.response.subSId === fetchData.subSId
                        ) {
                            this.storePreLoginSessionToPostLoginSession(res);
                        }
                    });
                });
            }
        }
        // if fetch API response not available or failed scenario.
        // storing selected data in Service level and session level in post-login key.
        else {
            if (sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage')) {
                const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage') as string);
                sessionStorageServiceStoryData.forEach((res: any) => {
                    this.fetchUserPreparedRequest.reqStoryIds.forEach((fetchReq: any) => {
                        if (res.response.sId === fetchReq.storyId && res.response.subSId === fetchReq.subStoryId) {
                            this.storePreLoginSessionToPostLoginSession(res);
                        }
                    });
                });
            }
        }
    }

    private storePreLoginSessionToPostLoginSession(res: StoryInfoDataInSessionStorage) {
        if (sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage')) {
            const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage') as string);
            sessionStorageServiceStoryData.forEach((sessionRes: any) => {
                if (
                    sessionRes.userId === res.userId &&
                    sessionRes.response.sId === res.response.sId &&
                    sessionRes.response.subSId === res.response.subSId &&
                    sessionRes.response.uInput === undefined
                ) {
                    sessionRes.response.uInput = res.response.uInput;
                    this.gamingStoriesService.storeFetchAPIDataRes(sessionRes);
                }
            });
            sessionStorage.removeItem('GS:StoryItemsInPreLoginSessionStorage');
            this.preLoginCheckDataBind();
        }
    }

    private preLoginCheckDataBind() {
        if (this.isAuthenticated) {
            // // fetch call response
            // if (this.fetchingGamingStoriesAPIRes) {
            //   this.fetchingGamingStoriesAPIRes.response.forEach(yfetchres => {
            //     if (this.fetchingGamingStoriesAPIRes.uId === this.user.id && yfetchres.sId === this.storyItem.storyId && yfetchres.subSId === this.storyItem.storyItemId && yfetchres.uAvg !== undefined && yfetchres.uInput !== undefined) {
            //       this.bindQuizData(yfetchres);
            //     }
            //   });
            // }
            // service level data check bind
            if (this.gamingStoriesService.storeFetchAPIDataResList.length > 0) {
                this.gamingStoriesService.storeFetchAPIDataResList.forEach((yserviceres: any) => {
                    if (
                        yserviceres.userId === this.user.id &&
                        yserviceres.response.sId === this.storyItem.storyId &&
                        yserviceres.response.subSId === this.storyItem.storyItemId &&
                        (yserviceres.response.uInput !== undefined || yserviceres.response.uAvg !== undefined)
                    ) {
                        this.bindQuizData(yserviceres.response);
                    }
                });
            }
            // session storage check bind
            else if (sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage')) {
                const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage') as string);
                sessionStorageServiceStoryData.forEach((ysessionres: any) => {
                    if (
                        ysessionres.userId === this.user.id &&
                        ysessionres.response.sId === this.storyItem.storyId &&
                        ysessionres.response.subSId === this.storyItem.storyItemId &&
                        ysessionres.response.uInput !== undefined &&
                        ysessionres.response.uAvg !== undefined
                    ) {
                        this.bindQuizData(ysessionres.response);
                    }
                });
            }
            this.gamingStoriesService.pauseStory(false);
        }
    }

    private postvalidationCheck() {
        if (this.user.isAuthenticated) {
            // session storage check bind
            this.showSiteCoreData = false;
            if (sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage')) {
                if (this.fetchingGamingStoriesAPIRes) {
                    const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage') as string);
                    this.fetchingGamingStoriesAPIRes.response.forEach((fetchData: any) => {
                        sessionStorageServiceStoryData.forEach((res: any) => {
                            if (
                                res.userId === this.fetchingGamingStoriesAPIRes.uId &&
                                res.response.sId === fetchData.sId &&
                                res.response.subSId === fetchData.subSId
                            ) {
                                this.storePreLoginSessionToPostLoginSession(res);
                            }
                        });
                    });
                }
            }
            // if (sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage') && sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage')) {
            //   let preSessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPreLoginSessionStorage'));
            //   let postSessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage'));
            //   postSessionStorageServiceStoryData.forEach(ysessionres => {
            //     if (ysessionres.userId === preSessionStorageServiceStoryData[0].userId && ysessionres.response.sId === preSessionStorageServiceStoryData[0].response.sId &&
            //        ysessionres.response.subSId === preSessionStorageServiceStoryData[0].response.subSId) {
            //         this.bindQuizData(ysessionres.response);
            //       }
            //   });
            // }
            if (sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage')) {
                const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage') as string);
                sessionStorageServiceStoryData.forEach((ysessionres: any) => {
                    if (
                        this.user.id === ysessionres.userId &&
                        this.storyItem.storyId === ysessionres.response.sId &&
                        this.storyItem.storyItemId === ysessionres.response.subSId
                    ) {
                        if (
                            (ysessionres.response.uInput === undefined && ysessionres.response.uAvg === undefined) ||
                            (ysessionres.response.uInput === undefined && ysessionres.response.uAvg !== undefined)
                        ) {
                            this.showSiteCoreData = true;
                        }
                    }
                });
            }
            if (sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage') && !this.showSiteCoreData) {
                const sessionStorageServiceStoryData = JSON.parse(sessionStorage.getItem('GS:StoryItemsInPostLoginSessionStorage') as string);
                sessionStorageServiceStoryData.forEach((ysessionres: any) => {
                    if (
                        ysessionres.userId === this.user.id &&
                        ysessionres.response.sId === this.storyItem.storyId &&
                        ysessionres.response.subSId === this.storyItem.storyItemId &&
                        (ysessionres.response.uInput !== undefined || ysessionres.response.uAvg !== undefined)
                    ) {
                        this.bindQuizData(ysessionres.response);
                    }
                });
            }
            this.gamingStoriesService.pauseStory(false);
        }
    }

    private bindQuizData(res: any) {
        this.isDisabled = true;
        this.uAvgResponse = res.uAvg;
        this.storyItem.yesNoPollOption.forEach((item: IOption) => {
            if (this.uAvgResponse !== undefined) {
                const selectedAnswer = this.uAvgResponse[(item?.key || '').toLowerCase()];
                if (
                    (selectedAnswer === 100 || selectedAnswer === undefined) &&
                    this.storyItem.yesNoPollOption.length !== Object.keys(this.uAvgResponse).length
                ) {
                    const keys: any = Object.keys(this.uAvgResponse);
                    if (keys && keys.length > 0) {
                        for (let i = 0; i < keys.length; i++) {
                            if (item.key.toLowerCase() !== keys[i].toLowerCase()) {
                                this.uAvgResponse[item.key.toLowerCase()] = 0;
                            }
                        }
                    }
                }
                if (selectedAnswer === 0) {
                    item.selectedValue = 0;
                    item.defaultValue = 0;
                    this.selectedKey = res.uInput;
                } else if (selectedAnswer === 100) {
                    item.selectedValue = parseFloat(selectedAnswer);
                    item.defaultValue = item.selectedValue;
                    if (this.selectedKey === undefined || this.selectedKey === '') this.selectedKey = item.key;
                } else if (selectedAnswer) {
                    item.selectedValue = parseFloat(selectedAnswer);
                    item.defaultValue = item.selectedValue;
                    this.selectedKey = res.uInput;
                }
                if (item.key.toLowerCase() === 'yes' && item.selectedValue < this.yesBtnWidth) {
                    item.defaultValue = this.yesBtnWidth;
                } else if (item.key.toLowerCase() === 'no' && item.selectedValue < this.noBtnWidth) {
                    item.defaultValue = this.noBtnWidth;
                }
            } else {
                if (item.key.toLowerCase() === res.uInput.toLowerCase()) {
                    item.selectedValue = 100;
                    item.defaultValue = item.selectedValue;
                    this.selectedKey = item.key;
                }
            }
        });

        this.storyItem.yesNoPollOption.forEach((item: IOption) => {
            if (item.key?.toLowerCase() === this.selectedKey?.toLowerCase() && !this.trackStoriesSkip) {
                this.trackStoriesSkip = true;
                this.gamingStoriesService.trackingGamingStoriesPhase2Event(
                    'teaser - embedded video',
                    'stories',
                    'click',
                    this.storyItem.description.replace(/<[^>]*>/g, ''),
                    this.storyItem.title,
                    item.key + ' ' + ' - ' + ' ' + item.selectedValue + '%',
                    this.storyItem.actionTypePosition,
                    'not applicable',
                );
            }
        });
    }
}
