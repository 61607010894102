<div
    *ngIf="loadFeatures"
    #storyItemsContainer
    class="mobilestories-container"
    style.background-image="{{ storyItem?.backgroundImage?.src ? 'url(' + storyItem?.backgroundImage?.src + ')' : '' }}"
    [ngStyle]="!storyHasVideo && { 'background-color': storyItemBackgroundClr }"
    [ngClass]="{ nobackgroundimage: storyItemBackgroundClr }">
    <div class="mobilestories-header">
        <div class="ms-header-title-leftsection">
            <img class="{{ storyIconShape }}" [src]="storyItem?.storyImage?.src" (error)="onGameIconError(storyItem)" />
            <span class="mobilestory-title">{{ storyItem?.title }}</span>
        </div>
        <div class="ms-header-rightsection">
            <button
                [ngStyle]="{ color: closebtnClr }"
                class="play-pause-btn"
                (click)="togglePausePlay(storyItem, $event)"
                [ngClass]="{ 'theme-play-solid': pause, 'theme-pause': !pause }"
                aria-hidden="true"></button>
            <div class="casino-gamingstory-video-mute-button" *ngIf="storyHasVideo">
                <button
                    [disabled]="soundDisabled"
                    [ngStyle]="{ color: muteunmuteClr }"
                    class="toggle-mute-button"
                    (click)="toggleMute(storyItem, $event)"
                    [ngClass]="{ 'theme-sound-loud': !mute, 'theme-sound-mute': mute, 'disabled': soundDisabled }"
                    aria-hidden="true"></button>
            </div>
            <div class="casino-gamingstory-video-mute-button" *ngIf="!storyHasVideo && storyItem?.showAudio">
                <audio #casinoAudio [src]="storyItem?.audioFile" [muted]="mute" onloadeddata="this.play();"></audio>
                <button
                    [disabled]="soundDisabled"
                    *ngIf="storyItem?.audioFile && storyItem?.showAudio"
                    [ngStyle]="{ color: muteunmuteClr }"
                    class="toggle-mute-button"
                    (click)="onAudioPlay(storyItem, $event)"
                    [ngClass]="{ 'theme-sound-loud': !mute, 'theme-sound-mute': mute, 'disabled': soundDisabled }"
                    aria-hidden="true"></button>
            </div>
            <span class="close theme-ex" (click)="closeModal($event)"></span>
        </div>
    </div>
    <div *ngIf="loadFeatures" class="story-move-up" id="gamingstoriesItemsContainer" #gamingStoriesItemsdrag>
        <div *ngIf="storyHasVideo">
            <video
                class="casino-gamingstory-video"
                id="casino-gamingstory-video"
                *ngIf="videoURL"
                [src]="videoURL"
                [muted]="mute"
                autoplay
                playsinline
                onloadeddata="this.play();"
                (loadeddata)="videoLoaded()"
                (timeupdate)="timeUpdate()"></video>
            <cloudflare-stream
                class="casino-gamingstory-video"
                id="casino-gamingstory-cmsvideo"
                *ngIf="cmsVideoURL"
                [height]="'100%'"
                [controls]="false"
                [src]="cmsVideoURL"
                [muted]="mute"
                [autoplay]="true"
                (timeupdate)="cmsTimeUpdate()"
                preload="auto"></cloudflare-stream>
        </div>
        <cc-gaming-story-progress-bar
            [activeStoriesIndex]="activeStoriesIndex"
            [activeStoryItemIndex]="activeStoryItemIndex"
            [storyContainerData]="storyContainerData"
            (sendStoryCompletionEvent)="storyCompletionEventFromProgressBar($event)"></cc-gaming-story-progress-bar>
        <div [ngSwitch]="storyItem?.storyItemActionType" class="mobilestories-content">
            <div *ngSwitchCase="storyItemActionTypes.EmojiSelector">
                <cc-gaming-story-emojis-animations
                    [storyItem]="storyItem"
                    [fetchingGamingStoriesAPIRes]="fetchingGamingStoriesAPIRes"
                    [fetchUserPreparedRequest]="fetchUserPreparedRequest"></cc-gaming-story-emojis-animations>
            </div>
            <div *ngSwitchCase="storyItemActionTypes.YesNoPoll">
                <cc-gaming-story-yesno-poll
                    [storyItem]="storyItem"
                    [storyContainerData]="storyContainerData"
                    [fetchingGamingStoriesAPIRes]="fetchingGamingStoriesAPIRes"
                    [fetchUserPreparedRequest]="fetchUserPreparedRequest"></cc-gaming-story-yesno-poll>
            </div>
            <div *ngSwitchCase="storyItemActionTypes.EmojiSlider">
                <cc-gaming-story-emoji-slider
                    [storyItem]="storyItem"
                    [fetchingGamingStoriesAPIRes]="fetchingGamingStoriesAPIRes"
                    [fetchUserPreparedRequest]="fetchUserPreparedRequest"></cc-gaming-story-emoji-slider>
            </div>
            <div *ngSwitchCase="storyItemActionTypes.QuestionAndAnswer">
                <cc-gaming-story-quiz
                    [storyItem]="storyItem"
                    [storyContainerData]="storyContainerData"
                    [fetchingGamingStoriesAPIRes]="fetchingGamingStoriesAPIRes"
                    [fetchUserPreparedRequest]="fetchUserPreparedRequest"></cc-gaming-story-quiz>
            </div>
            <div *ngSwitchCase="storyItemActionTypes.CustomCTA">
                <cc-gaming-story-cta [storyItem]="storyItem"></cc-gaming-story-cta>
            </div>
            <div *ngSwitchCase="storyItemActionTypes.Promos">
                <cc-gaming-story-optin
                    *ngIf="userAuthentication && storyItem.isValid"
                    [storyItem]="storyItem"
                    [storyVersionList]="storyContainerData.versionedList"></cc-gaming-story-optin>
            </div>
        </div>
        <div class="btn-move-up" [ngClass]="'swipeupbtnposition-' + (swipeUpBtnPosition | lowercase)">
            <span class="swipeuptxt" [ngStyle]="{ color: swipeupTextColor }" *ngIf="displaySwipeUp">{{ swipeupText }}</span>
            <span
                [ngStyle]="{ 'border-color': swipeupClr, 'color': swipeupClr }"
                class="theme-arrow-left"
                *ngIf="displaySwipeUp"
                (click)="swipeToBottom()"></span>
        </div>
    </div>
</div>
<div #swipeupContentFrame class="story-move-down" (swipedown)="swipeToTop()" [ngStyle]="{ 'z-index': zIndex }">
    <div *ngIf="displaySwipeUpContent" class="story-move-down">
        <div [ngStyle]="{ 'background-color': promotionTitleBgClr, 'color': promotionTitleClr }" class="btn-move-down">
            <span class="title-header">{{ swipeUpContentData.title }}</span>
            <span class="theme-down" *ngIf="displayDownArrow" (click)="swipeToTop()"></span>
        </div>
        <div class="swipeupstory-container">
            <h2>{{ swipeUpContentData.subTitle }}</h2>
            <img [src]="swipeUpContentData?.storyImage?.src" />
            <p>{{ swipeUpContentData.description }}</p>
        </div>
    </div>
</div>
<div
    [ngStyle]="{ 'background-color': storyItem?.configurations?.tncBgClr, 'color': storyItem?.configurations?.tncTxtClr }"
    *ngIf="loadFeatures && storyItem?.showTnC"
    class="optin-tncontent">
    <span>{{ storyItem?.tnCContent }}</span>
</div>

<div class="tilt-landscape-message">
    <img [src]="storyContainerData?.tiltImageUrl?.src" alt="Titling Phone" />
    <div class="casino-tiltmsg-text" [textContent]="storyContainerData?.tiltMessage"></div>
</div>
