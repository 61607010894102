import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'bg-pricemodal-info-popup',
    templateUrl: './price-modal-info-popup.component.html',
})
export class PriceInfoModalPopupComponent implements OnInit {
    globalClientConfig: any;
    selectPriceInfo: boolean;
    chartText: string;
    selectedPrice: any;
    constructor(
        public dialogRef: MatDialogRef<PriceInfoModalPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    toggleSelectedPriceInfo = new EventEmitter();

    ngOnInit(): void {
        this.chartText = this.data?.isMultiPriceComponent
            ? this.data?.multistakeContent?.MultiStakePrizeChart
            : this.data?.multistakeContent?.ChartText;
        this.selectedPrice = this.data?.isMultiPriceComponent ? this.data?.currentGame?.selected_index : this.data?.currentGame?.selected_price;
        if (localStorage.getItem('config')) {
            const clientConfig = JSON.parse(localStorage.getItem('config') || '');
            if (!this.globalClientConfig) this.globalClientConfig = clientConfig.global;
        }
    }

    toggleSelectPriceInfo() {
        this.dialogRef.close();
        this.toggleSelectedPriceInfo.emit(this.selectPriceInfo);
    }
}
