import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: singleEntryPointClientConfigFactory,
})
@LazyClientConfig({ key: 'bgSingleEntryPoint', product: ClientConfigProductName.BINGO })
export class SingleEntryPointClientConfig {
    //singleEntryPointData: any;
    enableRoomsGrouping: boolean;
    groupingRoomsData: any;
    enableGroupingForMultiVariants: boolean;
    enableIconReplacement: boolean;
    //globalSEPTexts: any;
}
export function singleEntryPointClientConfigFactory(service: LazyClientConfigService) {
    return service.get(SingleEntryPointClientConfig);
}
