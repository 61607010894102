import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import {
    BingoConfigProviderService,
    BingoHelperService,
    BingoManager,
    BingoModularConfigurationClientConfig,
    BingoTournamentsService,
    BingoTournamentsTrackingService,
    BingoUrlUtilityService,
    Race,
    SitecoreContent,
    SlotRacesLive,
} from '@frontend/bingo/core';
import { DeviceService, NavigationService, Page, RtmsService } from '@frontend/vanilla/core';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { Subscription, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { BingoNotificationOverlayComponent } from '../notification-overlay/bingo-notification-overlay.component';
import { BingoPickGameOverlayComponent } from '../pick-game-overlay/bingo-pick-game-overlay.component';
import { BingoSlotRacesInfoComponent } from '../race-info/bingo-race-info.component';

@Component({
    selector: 'bgl-slot-races-cards',
    templateUrl: 'bingo-cards-wrapper.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class BingoSlotRacesCardsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() coinImg: string;
    @Input() selectedOption: string;
    @Input() fromBingoHomepage: boolean;
    races: Race[] = [];
    showCarousel: boolean = false;
    enableJPTicking: boolean;
    showJackpots: boolean;
    jackpots: Map<string, any>;
    isNextButtonVisible: boolean;
    isPreButtonVisible: boolean;
    jackpotConfig: any;
    features: any;
    raceAddedDesktop: any;
    slotRacesConfig: any;
    isUkHeading: boolean;
    contentSubscription: Subscription;
    racesSubscription: Subscription;
    jackpotSubscription: Subscription;
    resizeSubscription: Subscription;
    jackpotTickingSubscription: Subscription;
    rtmsSubscription: Subscription;
    isFreeplaytechinLive: boolean = false;
    messages: { [item: string]: string } = {};
    content: SitecoreContent = {
        bingoRaceRulesConfigs: [],
        rules: [],
        rulesBanner: {},
        placeholderCardImage: {},
        coinEconomy: {},
        textTranslations: { sharedList: {}, versionedList: {} },
        tips: [],
        errorMessages: { sharedList: {}, versionedList: {} },
        entryDetails: { sharedList: {}, versionedList: {} },
    };

    totalLeaderboardRanks: number;
    updateLeaderboard: boolean;
    nextUpcoming: Race;
    nearestEndingTime: number;
    enableUpcomingOptinForPlaytech: boolean;
    enableBingoTournamentsAsWidget: boolean;
    @Input() showLeaderboard: boolean = false;
    @Input() addUpcoming: boolean = false;
    @Input() showPlaceholderCard: boolean = false;
    @Input() liveRacesList: any;
    @Input() enableLiveRacesFilter: boolean;

    @Output() isPlaytechinLive = new EventEmitter<boolean>();
    elms: number;
    trackingClass: string = '';
    @ViewChild('bingoTournamentsCarousel') carousel: SwiperComponent;
    //isSubCategory: boolean;
    enableBetterVisualization: boolean;
    autoplay: any = {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
    };
    activeCategory: string;
    swiperOptions: SwiperOptions = {
        modules: [Navigation, Pagination, Autoplay],
        loop: false,
        autoHeight: true,
        allowTouchMove: false,
        spaceBetween: 1,
        direction: 'horizontal',
        slidesPerView: 3,
        centeredSlides: false,
        autoplay: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        slidesPerGroup: 1,
        keyboard: true,
        watchOverflow: true,
        mousewheel: false,
        scrollbar: false,
        preventClicks: false,
        preventClicksPropagation: false,
        observer: true,
        observeParents: true,
        parallax: true,
        pagination: false,
    };

    constructor(
        private slotRacesService: BingoTournamentsService,
        private dialog: MatDialog,
        private deviceManager: DeviceService,
        private configProviderService: BingoConfigProviderService,
        private navigation: NavigationService,
        private slotRacesTracking: BingoTournamentsTrackingService,
        private page: Page,
        private bingoManager: BingoManager,
        private rtmsService: RtmsService,
        private bingoUrlUtilityService: BingoUrlUtilityService,
        private bingoHelperService: BingoHelperService,
    ) {}

    ngOnInit() {
        this.raceAddedDesktop = this.raceAdded.bind(this);
        this.slotRacesService.setSlotRaces();
        this.slotRacesConfig = this.configProviderService.provideBingoTournamentsClientConfig();
        this.enableUpcomingOptinForPlaytech = this.bingoHelperService.getConfigBasedOnInvokerProduct(
            this.slotRacesConfig.enableUpcomingOptinForPlaytech,
        );
        this.enableBingoTournamentsAsWidget = this.bingoHelperService.getConfigBasedOnInvokerProduct(
            this.slotRacesConfig.enableBingoTournamentsAsWidget,
        );
        this.slotRacesService.startLiveSlotPolling();
        this.activeCategory = this.slotRacesService.getActiveCategory();
        setTimeout(() => {
            if (this.carousel) {
                this.carousel.swiper.update();
            }
        }, 500);
        this.enableBetterVisualization = this.bingoHelperService.getConfigBasedOnInvokerProduct(this.slotRacesConfig.enableBetterVisualization);
        this.initShowCarousel();
        this.subscribeToObservables();
    }

    initShowCarousel() {
        this.showCarousel = this.deviceManager.isTouch
            ? window.innerWidth === 1024 && this.showPlaceholderCard
                ? true
                : (this.enableBetterVisualization ? window.innerWidth === 310 : window.innerWidth === 320) || window.innerWidth > 1023
                  ? !this.showPlaceholderCard
                  : window.innerWidth === 768
                    ? this.showPlaceholderCard
                    : false
            : true;
    }

    showCarouselArrows() {
        this.initShowCarousel();
        if (this.showCarousel) {
            this.setCarouselConfigs();
        }
    }

    subscribeToObservables(): void {
        this.initRaces();
        this.resizeSubscription = this.bingoManager.reSizeObservable.subscribe((width: any) => {
            if (width) {
                this.showCarouselArrows();
            }
        });

        this.rtmsSubscription = this.rtmsService.messages.pipe(filter((m) => m.type === 'FREE_TECH_OPTIN_LIMIT_EXCEED')).subscribe((message) => {
            if (message.type == 'FREE_TECH_OPTIN_LIMIT_EXCEED' && this.races.length > 0 && message.payload) {
                this.races.forEach((race: Race) => {
                    if (race.promoId == message.payload.promoId && race.slotUniqueId == message.payload.slotId) {
                        race.targetedPlayerCount = race.optinLimit;
                        return;
                    }
                });
            }
        });

        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.content = content;
            this.messages = content && content.textTranslations ? content.textTranslations.versionedList : {};
            this.coinImg = this.content?.coinEconomy?.image?.src;
        });
    }

    ngOnChanges() {
        if (this.races && this.races.length > 0) {
            if (this.enableLiveRacesFilter) {
                this.races = [...this.liveRacesList];
            }
            this.updateRaces();
        }
    }

    initRaces(): void {
        if (this.addUpcoming) {
            this.racesSubscription = combineLatest(this.slotRacesService.liveRacesData, this.slotRacesService.upcomingRacesData).subscribe(
                (races: any) => {
                    //copying data in order to avoid reference sharing
                    let liveRaces = races[0] && races[0].liveNow && races[0].liveNow.slice(0, races[0].liveNow.length);
                    if (this.enableLiveRacesFilter) {
                        liveRaces = [...this.liveRacesList];
                    }
                    const upcomingToday = races[1] && races[1].upcomingToday && races[1].upcomingToday.slice(0, races[1].upcomingToday.length);
                    const upcomingTomorrow =
                        races[1] && races[1].upcomingTomorrow && races[1].upcomingTomorrow.slice(0, races[1].upcomingTomorrow.length);

                    const totalRaceCards = this.bingoHelperService.getConfigBasedOnInvokerProduct(this.slotRacesConfig.noOfCards) - 1;
                    if (liveRaces && liveRaces.length >= totalRaceCards) {
                        this.races = liveRaces.slice(0, totalRaceCards);
                    } else {
                        if (liveRaces) {
                            this.races = liveRaces;
                        }
                        if (upcomingToday && upcomingToday.length) {
                            const optedInRaces = upcomingToday.filter((r: Race) => {
                                return r.ctaCollection.indexOf('OPTIN') === -1;
                            });
                            this.races = this.races.concat(optedInRaces.slice(0, totalRaceCards - this.races.length));
                            if (this.races.length < totalRaceCards) {
                                const notOptedInRaces = upcomingToday.filter((r: Race) => {
                                    return r.ctaCollection.indexOf('OPTIN') !== -1;
                                });
                                this.races = this.races.concat(notOptedInRaces.slice(0, totalRaceCards - this.races.length));
                            }
                        }
                        if (this.races.length < totalRaceCards) {
                            if (upcomingTomorrow && upcomingTomorrow.length) {
                                const optedInRaces = upcomingTomorrow.filter((r: Race) => {
                                    return r.ctaCollection.indexOf('OPTIN') === -1;
                                });
                                this.races = this.races.concat(optedInRaces.slice(0, totalRaceCards - this.races.length));
                                if (this.races.length < totalRaceCards) {
                                    const notOptedInRaces = upcomingTomorrow.filter((r: Race) => {
                                        return r.ctaCollection.indexOf('OPTIN') !== -1;
                                    });
                                    this.races = this.races.concat(notOptedInRaces.slice(0, totalRaceCards - this.races.length));
                                }
                            }
                        }
                    }
                    this.updateRaces();
                },
            );
        } else {
            this.racesSubscription = this.slotRacesService.liveRacesData.subscribe((races: SlotRacesLive) => {
                if (races && races.liveNow) {
                    this.races = races.liveNow.slice(0, races.liveNow.length);
                    if (this.enableLiveRacesFilter) {
                        this.races = [...this.liveRacesList];
                    }
                    this.updateRaces();
                }
            });
        }
        this.showCarouselArrows();
    }

    updateRaces(): void {
        this.isFreeplaytechinLive = false;
        if (this.showCarousel && this.races && this.races.length && this.showPlaceholderCard) {
            this.races.push(this.races[0]);
        }

        if (this.showCarousel) {
            this.setCarouselConfigs();
        }
        if (this.races && this.races.length > 0) {
            this.setUpdateStatus();
            // if (this.category && this.category.categoryInfo)
            //     this.trackingClass = 'track_' + this.category.categoryInfo.categoryid;
            if (this.enableUpcomingOptinForPlaytech) {
                for (let i = 0; i < this.races.length; i++) {
                    if (this.races[i].isLive && this.races[i]?.subType?.toLowerCase() === 'free_play_tech') {
                        this.isFreeplaytechinLive = true;
                        break;
                    }
                }
                if (!this.isFreeplaytechinLive) {
                    for (let i = 0; i < this.races.length; i++) {
                        if (!this.races[i].isLive && this.races[i]?.subType?.toLowerCase() === 'free_play_tech') {
                            this.races[i].firstPlayTech = true;
                            break;
                        }
                    }
                }
            }
            document.getElementsByTagName('html')[0].classList.remove('hide-bingo-tournaments');
        }
        //adding class when no races are present
        if (!this.races || this.races.length == 0) {
            if (this.bingoHelperService.getConfigBasedOnInvokerProduct(this.slotRacesConfig.disableWhenNoPromotions)) {
                document.getElementsByTagName('html')[0].classList.add('hide-bingo-tournaments');
            }
        }

        this.isPlaytechinLive.emit(this.isFreeplaytechinLive);
    }

    setUpdateStatus(): void {
        let totalRanks = 0;
        for (let i = 0; i < this.races.length; i++) {
            totalRanks += this.races[i].rankDetails && this.races[i].rankDetails.length;
        }
        if (totalRanks !== this.totalLeaderboardRanks) {
            this.totalLeaderboardRanks = totalRanks;
            this.updateLeaderboard = !this.updateLeaderboard;
        }
        this.nextUpcoming = this.races.find((r) => r.startDate.getTime() > Date.now())!;
        this.nearestEndingTime = this.races[0].endDate.getTime();
        for (let i = 0; i < this.races.length; i++) {
            if (this.races[i].endDate.getTime() < this.nearestEndingTime) {
                this.nearestEndingTime = this.races[i].endDate.getTime();
            }
        }
    }

    showFullLeaderboard(race: Race): void {
        this.openDialog(race, 'race-info');
    }

    gotoSlotRaces(): void {
        const bingoModularConfig: BingoModularConfigurationClientConfig = this.configProviderService.provideBingoModularConfigurationClientConfig();
        const productMap = this.bingoUrlUtilityService.getProductMapConfiguration(bingoModularConfig);
        const url = productMap.defaultRedirectURL + '/' + this.page.lang + '/bingo/bingotournaments';
        this.slotRacesTracking.track('click', 'not applicable', this.activeCategory, this.messages.SeeAllRaces.toLowerCase() + ' view', url);
        if (url) {
            this.navigation.goTo(url);
        }
    }

    openDialog(race: Race, dialog: 'race-info' | 'pick-game' | 'notification') {
        const component =
            dialog === 'race-info'
                ? BingoSlotRacesInfoComponent
                : dialog === 'pick-game'
                  ? BingoPickGameOverlayComponent
                  : BingoNotificationOverlayComponent;
        this.openDialogOverlay(race, component);
    }

    openDialogOverlay(race: Race, component: any) {
        this.dialog.closeAll();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        this.dialogProperties(dialogConfig);
        dialogConfig.data = {
            race: race,
            // category: this.category,
            enableOptin: this.enableUpcomingOptinForPlaytech && race.firstPlayTech && !this.isFreeplaytechinLive,
            coinImg: this.coinImg,
        };
        if (component == BingoNotificationOverlayComponent) {
            dialogConfig.panelClass = 'bingo-bt-snotification-mat-dialog';
        } else {
            dialogConfig.panelClass = 'bingo-bt-mat-dialog';
        }
        const dialogRef = this.dialog.open(component, dialogConfig);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                if (result.openRaceInfo) {
                    this.openDialog(race, 'race-info');
                } else if (result.openPickGameOverlay) {
                    this.openDialog(race, 'pick-game');
                } else if (result.openNotificationOverlay) {
                    this.openDialog(race, 'notification');
                } else if (result.errorCode) {
                    this.slotRacesService.addErrorMessage(result.errorCode);
                }
            }
        });
    }

    dialogProperties(dialogConfig: MatDialogConfig): void {
        if (window.matchMedia('(orientation: landscape)').matches && window.innerWidth < 900) {
            dialogConfig.width = '100%';
            dialogConfig.height = '100%';
            dialogConfig.maxWidth = '100%';
            dialogConfig.maxHeight = '100%';
        } else if (window.innerWidth >= 768) {
            dialogConfig.width = '668px';
        } else {
            dialogConfig.width = '100%';
            dialogConfig.height = '100%';
            dialogConfig.maxWidth = '100%';
            dialogConfig.maxHeight = '100%';
        }
    }

    setCarouselConfigs(): void {
        const casinoNpmParams = { isDll: true };
        //this.bingoNpmParamService.getBingoNpmParams();
        if (casinoNpmParams.isDll) {
            this.elms =
                window.innerWidth === 320
                    ? 1
                    : window.innerWidth === 768
                      ? 2
                      : this.races.length === 1 && !this.showPlaceholderCard
                        ? 1
                        : this.races.length >= 2 && !this.showPlaceholderCard
                          ? 2
                          : 3;
        } else {
            this.elms =
                window.innerWidth === 320
                    ? 1
                    : window.innerWidth === 768
                      ? 2
                      : window.innerWidth === 1024
                        ? 3
                        : this.races.length === 1 && !this.showPlaceholderCard
                          ? 1
                          : this.races.length >= 2 && !this.showPlaceholderCard
                            ? 2
                            : 3.2;
        }

        this.swiperOptions.slidesPerView = this.elms;
        if (this.carousel) {
            this.carousel.swiper.update();
        }
    }

    raceAdded(index: number, item: Race): string {
        if (item) {
            return item.isLive + '_' + item.promoId + '_' + item.slotUniqueId;
        } else {
            //this is a fix (workaround) added for the desktop carousel issue where the cards were not getting destroyed
            //until the actual trackBy in the carousel code is fixed - when (if) it is fixed - remove the else part
            //and the method raceAddedDesktop created with this method and bind()
            if (this.races) {
                let trackingString = '_';
                trackingString = this.nearestEndingTime + '_';
                if (this.nextUpcoming) {
                    trackingString += this.nextUpcoming.isLive + '_' + this.nextUpcoming.promoId + '_' + this.nextUpcoming.slotUniqueId;
                }
                trackingString += this.updateLeaderboard;
                return trackingString;
            }
            return 'no_races';
        }
    }

    ngOnDestroy() {
        this.slotRacesService.stopLiveSlotPolling();

        if (this.contentSubscription) this.contentSubscription.unsubscribe();
        if (this.jackpotSubscription) this.jackpotSubscription.unsubscribe();
        if (this.jackpotTickingSubscription) this.jackpotTickingSubscription.unsubscribe();
        if (this.resizeSubscription) this.resizeSubscription.unsubscribe();
        if (this.racesSubscription) {
            this.racesSubscription.unsubscribe();
        }
        if (this.rtmsSubscription) {
            this.rtmsSubscription.unsubscribe();
        }
    }
}
