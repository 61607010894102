import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'bgl-coin-economy-confirm-popup',
    templateUrl: 'bingo-coin-economy-confirm-popup.component.html',
})
export class BingoCoinEconomyConfirmPopupComponent {
    messages: any;
    showConfirmPopup: boolean;

    constructor(
        private dialogRef: MatDialogRef<BingoCoinEconomyConfirmPopupComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
    ) {
        this.messages = data?.messages;
        this.showConfirmPopup = data?.showConfirmPopup;
    }

    onConfirmClick(event: any) {
        this.dialogRef.close({ type: 'confirm', event: event });
    }

    closeDialog(ctaText: string) {
        this.dialogRef.close({ type: ctaText });
    }
}
