<div class="bingo-bt-lobby-container-main">
    <div class="bingo-bt-notification-message">
        <div class="bt-notification-body">
            <div class="col-md-12 notification-header">
                <div>{{ messages.NotificationMsgHeader }}</div>
                <div class="notification-close" (click)="close()"><i class="theme-ex"></i></div>
            </div>
            <div class="col-md-12 notification-footer">
                <bgl-slot-race-card [race]="race" [position]="0" [raceNotificationStatus]="raceNotificationStatus"> </bgl-slot-race-card>
            </div>
            <div class="col-md-12 bt-notification-text">
                <p>{{ messages.NotificationDescription }}</p>
                <p class="small-text">{{ messages.notificationDiscriptionTwo }}</p>
            </div>

            <div class="col-md-12 bt-notification-btn-container">
                <p>
                    <button class="no btn btn-md btn-light" (click)="notificationCheck(false)">
                        {{ messages.NotificationOFF }} <i class="alert-icon" [ngClass]="slotRacesSharedList.notificationBellOffIcon"></i>
                    </button>
                    <button class="yes btn btn-md btn-primary" (click)="notificationCheck(true)">
                        {{ messages.NotificationON }} <i class="alert-icon" [ngClass]="slotRacesSharedList.notificationBellOnIcon"></i>
                    </button>
                </p>
            </div>
        </div>
    </div>
</div>
