import { Injectable } from '@angular/core';

import { BingoConfigProviderService } from '../../bingo-config-provider/services/bingo-config-provider.service';
import { FeaturesClientConfig } from '../../client-config/features-client.config';

@Injectable({
    providedIn: 'root',
})
export class RoomsInfoService {
    constructor(private configProviderService: BingoConfigProviderService) {}

    getRoomInfo(roomId: any, roomsConfig: any): any {
        const room = roomsConfig.find((x: any) => x?.roomId?.toLowerCase() == encodeURI(roomId?.toLowerCase()));
        return room ? room : roomsConfig.find((x: any) => x?.roomId?.toLowerCase() == 'default');
    }

    getImage(roomId: any, roomsConfig: any): any {
        let imageItem = roomsConfig.find((x: any) => x.roomId.toString().toLowerCase() == encodeURI(roomId.toString().toLowerCase()));
        return imageItem ? imageItem.mobileImage.src : roomsConfig.find((x: any) => x.roomId.toString().toLowerCase() == 'default').mobileImage.src;
    }

    getIconImage(roomId: any, roomsConfig: any): any {
        const featureConfig: FeaturesClientConfig = this.configProviderService.provideFeaturesConfig();
        const imageItem = roomsConfig.find((x: any) => x.roomId.toLowerCase() == encodeURI(roomId.toLowerCase()));
        if (featureConfig.showIconImage) {
            return imageItem ? imageItem.iconImage.src : roomsConfig.find((x: any) => x.roomId.toLowerCase() == 'default').iconImage.src;
        } else {
            return '';
        }
    }

    getFriendsLogo(roomId: any, roomsConfig: any): any {
        const imageItem = roomsConfig.find((x: any) => x.roomId.toLowerCase() == encodeURI(roomId.toLowerCase()));
        return imageItem ? imageItem.desktopImage.src : roomsConfig.find((x: any) => x.roomId.toLowerCase() == 'default').desktopImage.src;
    }

    getFriendsCRContent(roomId: any, roomsConfig: any): any {
        const imageItem = roomsConfig.find((x: any) => x.roomId.toLowerCase() == encodeURI(roomId.toLowerCase()));
        return imageItem ? imageItem.description : '';
    }

    getFriendsCRLegalContent(roomId: any, roomsConfig: any): any {
        const roomDetails = roomsConfig.find((x: any) => x.roomId.toLowerCase() == encodeURI(roomId.toLowerCase()));
        return roomDetails ? roomDetails.legalLineDescription : '';
    }
}
