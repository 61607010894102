import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'bgBingoTournaments', product: ClientConfigProductName.BINGO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: bingoTournamentsClientConfigFactory,
})
export class BingoTournamentsClientConfig {
    enableOptIn: any;
    host: string;
    joinUrlTemplate: string;
    leaderboardUrl: string;
    raceListUrl: string;
    racesListPollingInterval: any;
    notificationPopupTimeout: any;
    leaderboardPollingInterval: any;
    showAwardsAndPrizes: any;
    maxLeaderboardRows: any;
    upcomingSlotsDuration: any;
    noOfCards: any;
    resultsForNoOfDays: any;
    enablePreLogin: any;
    disableWhenNoPromotions: any;
    enableResultsHistoryPage: any;
    enableMaxBet: any;
    enableStickersFreeToPlay: boolean;
    enableStickersFreePlayTech: any;
    enableSlotRacesStickers: boolean;
    enableJpRibbonOnFreeTourney: any;
    enable24HrTimeFormat: any;
    displayBonusAsText: any;
    upcomingSlotStickersDuration: any;
    enableBetterVisualization: any;
    hideGenericPoints: any;
    enableUpcomingOptinForPlaytech: any;
    disablePreloginApiCalls: any;
    disablePrizesApiCall: any;
    disableBingoTournamentsPoints: any;
    enableRaceNotification: any;
    enableCoinEconomy: any;
    enableConfigMaps: any;
    enableCoinShower: any;
    scheduleFeedTimeInterval: any;
    bingoTournamentSource: string;
    enableBingoTournamentsAsWidget: any;
    enableBingoTournaments: any;
    displayTournamentsOnTop: boolean;
    btSitecoreItemText: string;
    resultsDateFormat: string;
    matDialogResultsDateFormat: string;
    enableBingoTournamentsWidget: boolean;
}
export function bingoTournamentsClientConfigFactory(service: LazyClientConfigService) {
    return service.get(BingoTournamentsClientConfig);
}
