import { LowerCasePipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { GamingStoriesService, IGamingStory, IGamingStoryItem } from '@casinocore/platform/core';
import { NavigationService, TrackingService, UserService } from '@frontend/vanilla/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'cc-gaming-story-optin',
    templateUrl: 'gaming-story-optin.component.html',
    standalone: true,
    imports: [NgIf, NgClass, NgStyle, LowerCasePipe],
})
export class GamingStoryOptInComponent implements OnInit, OnDestroy, OnChanges {
    @Input() storyItem: IGamingStoryItem;
    @Input() storyVersionList: any;
    @Input() storyName: string;

    optInDisabled: boolean;
    promoOptinBtnClr: string;
    promoOptinBtnBgClr: string;
    displayOptIn: boolean;
    displayError: boolean;
    optInButtonText: string;
    errorMessage: string;
    optinBtnPosition: string;
    subTitleFontSize: string;
    optinBtnBgClr: string;
    optinBtnTxtClr: string;
    descriptionClr: string;
    subTitleTxtClr: string;
    titlePosition: string;
    descriptionPosition: string;
    subTitlePosition: string;
    enableOptedInUrlBtn: boolean;
    optedInMoreInfoText: string;
    descriptionFontSize: string;

    private destroy$ = new Subject<void>();
    private activeStoryData: IGamingStory;
    private activeStoriesIndex: number;
    private gamingStoryOptInData: any;
    private activeStoryItemIndex: number;
    private gamingStoryItemListData: Array<IGamingStoryItem>;
    private optInClickedStatus: string;
    private customCTAText: string;
    private actionTypePosition: string;

    constructor(
        private user: UserService,
        private gamingStoriesService: GamingStoriesService,
        private navigation: NavigationService,
        private ref: ChangeDetectorRef,
        private tracking: TrackingService,
    ) {}

    ngOnInit(): void {
        this.optInData();
        this.ref.checkNoChanges();
    }
    optInData() {
        this.subTitleTxtClr = this.storyItem?.configurations?.subTitleTxtClr;
        this.subTitleFontSize = this.storyItem?.configurations.subTitleFontSize;
        this.descriptionClr = this.storyItem?.configurations?.descriptionClr;
        this.descriptionFontSize = this.storyItem?.configurations?.descriptionFontSize;
        this.actionTypePosition = this.storyItem?.actionTypePosition;
        this.titlePosition = this.storyItem?.titlePosition;
        this.subTitlePosition = this.storyItem?.subTitlePosition ?? '';
        this.descriptionPosition = this.storyItem?.descriptionPosition;
        if (this.storyItem?.targetPromoStatus) {
            this.setOptInButtonConfigurations(this.storyItem?.targetPromoStatus);
        }
        this.activeStoriesIndex = this.storyItem?.activeStoriesIndex;
        this.activeStoryItemIndex = this.storyItem?.activeStoryItemIndex;
        this.optinBtnPosition = this.storyItem?.actionTypePosition;
    }

    optInClicked() {
        if (this.user?.isAuthenticated) {
            this.promoOptinBtnClr = this.storyItem?.configurations?.promoAlreadyOptedinBtnClr;
            this.promoOptinBtnBgClr = this.storyItem?.configurations?.promoAlreadyOptedinBtnBgClr;
            this.optInDisabled = true;
            const params = {
                type: this.storyItem?.storyItemActionType,
                id: this.storyItem?.targetPromoId,
                optIn: true,
                source: 'INBOX',
            };
            this.gamingStoriesService.getGamingOptIn(params.type, params?.id, params?.optIn, params.source);
            this.gamingStoriesService.optInObservable.pipe(takeUntil(this.destroy$)).subscribe({
                next: (isReady: any) => {
                    if (isReady) {
                        const gamingStoryItemOptInStatus: any = this.gamingStoriesService.gamingStoryItemOptIn;
                        if (gamingStoryItemOptInStatus) {
                            this.optInClickedStatus = gamingStoryItemOptInStatus;
                            this.setOptInResponse(this.optInClickedStatus);
                            this.setOptInButtonConfigurations(this.optInClickedStatus);
                            this.storyItem.targetPromoStatus = gamingStoryItemOptInStatus;
                        }
                    }
                },
                error: (error: any) => this.setOptInResponseError(error),
                complete: () => {},
            });
            this.trackGamingStories();
            this.ref.detectChanges();
        }
    }
    onClick(event: Event): void {
        event.preventDefault();
        const url = this.storyItem?.optedInUrl?.url;
        if (url) {
            this.navigation.goTo(url);
        }
    }
    ngOnChanges() {
        this.optInData();
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private setOptInResponse(status: string): void {
        if (status === 'OPTED_IN') {
            const statusObj = {
                activeStoriesIndex: this.activeStoriesIndex,
                activeStoryItemIndex: this.activeStoryItemIndex,
                promoStatus: status,
            };
            this.gamingStoriesService.changePromoStatus(statusObj);
        }
    }

    private setOptInResponseError(error: any): void {
        this.promoOptinBtnClr = this.storyItem?.configurations?.promoOptinBtnClr;
        this.promoOptinBtnBgClr = this.storyItem?.configurations?.promoOptinBtnBgClr;
        this.optInDisabled = false;
        this.displayError = true;
        if (error.optedPromoStatus) {
            const errorKey = error.optedPromoStatus;
            this.errorMessage = this.storyVersionList[errorKey];
        } else {
            this.errorMessage = 'TechnicalError';
        }
    }

    private setOptInButtonConfigurations(status: string): void {
        this.displayOptIn = true;
        if (status === 'OFFERED' || status === 'OPTED_OUT') {
            this.optInDisabled = false;
            this.optInButtonText = this.storyItem?.optInCTAText;
            this.promoOptinBtnClr = this.storyItem?.configurations?.promoOptinBtnClr;
            this.promoOptinBtnBgClr = this.storyItem?.configurations?.promoOptinBtnBgClr;
        } else if (status == 'OPTED_IN') {
            this.optInDisabled = true;
            this.optInButtonText = this.storyItem?.optedInCTAText;
            this.promoOptinBtnClr = this.storyItem?.configurations?.promoAlreadyOptedinBtnClr;
            this.promoOptinBtnBgClr = this.storyItem?.configurations?.promoAlreadyOptedinBtnBgClr;
            if (this.optInButtonText && this.storyItem?.optedInUrl?.url) {
                this.enableOptedInUrlBtn = true;
                this.optedInMoreInfoText = this.storyItem?.optedInUrl?.text;
            }
        }
        this.ref.detectChanges();
    }
    private trackGamingStories() {
        this.tracking.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'teaser - embedded video',
            'component.LabelEvent': 'stories',
            'component.ActionEvent': 'click',
            'component.PositionEvent': 'not applicable', //this.activePromoName,
            'component.LocationEvent': this.storyItem?.title,
            'component.EventDetails': this.storyItem?.optInCTAText,
            'component.URLClicked': 'not applicable',
        });
    }
}
