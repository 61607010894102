import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {
    BingoConfigProviderService,
    BingoHelperService,
    BingoTournamentsService,
    BingoTournamentsTrackingService,
    Race,
    SitecoreContent,
    SlotRacesLive,
    SlotRacesResponseError,
} from '@frontend/bingo/core';
import { ClaimsService, LoginDialogService, MessageQueueService, UserService } from '@frontend/vanilla/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BingoCoinEconomyConfirmPopupComponent } from '../../coin-economy-confirm-popup/bingo-coin-economy-confirm-popup.component';
import { BingoNotificationPopUpComponent } from '../../notification-popup/bingo-notification-popup.component';

@Component({
    selector: 'bgl-slot-race-card',
    templateUrl: 'bingo-card.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class BingoSlotRaceCardComponent implements OnInit, OnDestroy, OnChanges {
    @Input() race: Race;
    @Input() coinImg: string;
    @Input() selectedOption: string;
    @Input() isInfoOpened: boolean;
    // @Input() sourceInfo:SourceInfo;
    @Input() showRaceInfo: boolean = false;
    @Input() showCardMenu: boolean = false;
    @Input() first: boolean;
    @Input() last: boolean;
    @Input() position: number;
    @Input() showPlaceholderCard: boolean = false;
    @Input() fromPickGameOverlay: boolean = false;
    @Input() firstItem: boolean = false;
    @Input() targetedPlayerCount: number;
    @Input() enableOptin: boolean;
    @Input() raceNotificationStatus: boolean = false;
    @Output() openRaceInfo = new EventEmitter<Race>();
    @Output() closeRaceInfo = new EventEmitter<any>();
    @Output() hideCard = new EventEmitter();
    @Output() gameLaunched = new EventEmitter();
    @Output() openPickGameOverlay = new EventEmitter();
    @Output() closePickGameOverlay = new EventEmitter<any>();
    @Output() openNotificationOverlay = new EventEmitter();
    notificationStatus: boolean;
    activeCategory: string;
    timeSpan: number = 0;
    Days: number = 0;
    millisec: number = 86400000;
    durationdays: number;
    sliderMaxValue: number;
    bottombackground: string;
    progressValue: number = 0;
    duration: number;
    gameIconSrc: string;
    backgroundImageSrc: string;
    cardbackgroundimage: string;
    cardbackgroundcolor: string;
    optinExpiryTimeOut: any;
    cardTextColor: string;
    currencyCode: string;
    spinsText: string;
    spinsCount: number = 0;
    forceOptinSubscription: any;
    forceCoinBalanceSubscription: Subscription;
    playerCount: number;
    spinCount: number;
    isprogressBarEnabled: boolean;
    showCoinBalancePopup: boolean = false;
    optInSubscription: Subscription;
    optInWithCoinsSubscription: Subscription;
    timerSubscription: Subscription;
    dataUpdateSubscription: Subscription;
    notificationSubscription: Subscription;
    raceNotifySubscription: Subscription;
    messages: { [item: string]: string } = {};
    slotRacesSharedList: { [item: string]: string } = {};
    contentSubscription: Subscription;
    notificationStatusSubcription: Subscription;
    content: SitecoreContent = {
        bingoRaceRulesConfigs: [],
        rules: [],
        rulesBanner: {},
        placeholderCardImage: {},
        coinEconomy: {},
        textTranslations: { sharedList: {}, versionedList: {} },
        tips: [],
        errorMessages: { sharedList: {}, versionedList: {} },
        entryDetails: { sharedList: {}, versionedList: {} },
    };
    showimage: boolean = true;
    // activeCategory: string;
    displayMaxBet: boolean = false;
    enableMaxBet: boolean = false;
    isNotify: boolean;
    playNowText: string;
    cardTitle: string;
    containerLocation: string;
    promobackgroundimage: string;
    promobackgroundcolor: string;
    // isSubCategory: boolean;
    expandCard: boolean;
    enableBetterVisualization: boolean;
    enableRaceNotification: boolean;
    slotRacesConfig: any;
    currentPoints: any;
    minPoints: any;
    configs: any = {};
    beatBankerPromoIcon: string;
    private destroy$ = new Subject<void>();
    coinObj: any;
    showToasterMessage: boolean;
    ballTypeImage: any;
    clientConfig: any;
    noncurrenyValue: boolean = false;

    constructor(
        private slotRacesService: BingoTournamentsService,
        // private gameLaunchService: CasinoCoreGamelaunchService,
        private claimsService: ClaimsService,
        private messageQueue: MessageQueueService,
        private user: UserService,
        private loginDialog: LoginDialogService,
        private slotRacesTracking: BingoTournamentsTrackingService,
        private configProviderService: BingoConfigProviderService,
        // private cacheManager: CacheManagerService,
        private dialog: MatDialog,
        private bingoHelperService: BingoHelperService,
    ) {}

    ngOnInit() {
        if (localStorage.getItem('config')) {
            this.clientConfig = JSON.parse(localStorage.getItem('config') || '');
        }
        this.activeCategory = this.isInfoOpened ? 'info overlay' : this.slotRacesService.getActiveCategory();
        this.currencyCode = ((this.race?.additionalParameters && this.race?.additionalParameters.currency) || this.claimsService.get('currency'))!;
        this.currentPoints = parseFloat(this.race?.beatBanker?.currPoints);
        this.minPoints = this.race?.beatBanker?.minPoints;
        this.sliderMaxValue = this.race?.endDate.getTime() - this.race?.startDate.getTime();
        this.playerCount = this.race?.targetedPlayerCount;
        this.spinCount = this.race?.spinProgress && this.race?.spinProgress.total;
        this.cardTitle =
            this.race?.gameVariants && this.race?.gameVariants.length > 1
                ? this.race.content?.promotion?.promoName
                    ? this.race.content.promotion.promoName
                    : this.race.gameId
                : this.race?.content?.promotion?.promoName.startsWith('#')
                  ? this.race.content.promotion.promoName.replace('#', '').trim()
                  : this.race.gameId;
        this.containerLocation = this.showRaceInfo ? 'Page Conatiner' : 'Overlay';
        this.containerLocation = this.containerLocation.concat('|Slot Races');
        this.ballTypeImage = this.clientConfig?.ballTypeImages[this.race.ballType];
        this.slotRacesConfig = this.configProviderService.provideBingoTournamentsClientConfig();
        this.enableBetterVisualization = this.bingoHelperService.getConfigBasedOnInvokerProduct(this.slotRacesConfig.enableBetterVisualization);
        this.enableRaceNotification = this.bingoHelperService.getConfigBasedOnInvokerProduct(this.slotRacesConfig.enableRaceNotification);
        this.forceOptin();
        this.subscribeToDataUpdates();
        this.initializeTimerCountdown();
        this.setOptinExpiryTimeOut();
        this.subscribeToNotifyStatus();
        this.coinObj = this.slotRacesService.bindCoinImg(this.race, this.coinImg);
        this.contentSubscription = this.slotRacesService.content.subscribe((content: SitecoreContent) => {
            this.content = content;
            this.messages = content && content.textTranslations ? content.textTranslations.versionedList : {};
            this.slotRacesSharedList = content && content.textTranslations ? content.textTranslations.sharedList : {};
            this.playNowText =
                this.messages && (this.race.gameVariants && this.race.gameVariants.length > 1 ? this.messages.PickGame : this.messages.PlayNow);
            this.duration = Math.ceil(this.sliderMaxValue / (1000 * 60));
            this.updateCardSitecoreData();
            this.forceCoinRaceBalance();
        });
        this.enableMaxBet = this.bingoHelperService.getConfigBasedOnInvokerProduct(this.slotRacesConfig.enableMaxBet);
        this.displayMaxBet = this.enableMaxBet && this.race.criteriaDetails.max;
    }

    private subscribeToNotifyStatus() {
        this.notificationStatusSubcription = this.slotRacesService.notifyObservable.subscribe((response: any) => {
            if (
                !(this.race?.ctaCollection.indexOf('OPTIN') > -1) &&
                response &&
                this.race?.promoId === response?.promoId &&
                this.race?.slotUniqueId === response?.slotUniqueId
            ) {
                this.race.notificationStatus = response.status;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.firstItem) {
            this.expandCard = true;
        }
        if (changes?.targetedPlayerCount?.currentValue) {
            this.playerCount = changes.targetedPlayerCount.currentValue;
        }
        this.coinObj = this.slotRacesService.bindCoinImg(this.race, this.coinImg);
        this.subscribeToNotifyStatus();
        this.ballTypeImage = this.clientConfig?.ballTypeImages[this.race.ballType];
        const awardTypeArray = ['bingotickets', 'freespins', 'free_spins'];
        if (
            !this.race?.additionalParameters?.awardType ||
            (this.race?.additionalParameters?.awardType && awardTypeArray[0].indexOf(this.race?.additionalParameters?.awardType.toLowerCase()) > -1)
        )
            this.noncurrenyValue = true;
    }

    get slotRaceDuration() {
        if (this.duration < 1440) {
            return `${this.duration} <span>${this.messages.Minutes}</span>`;
        } else {
            const days = Math.floor(this.duration / 1440);
            return `${days} <span>${this.messages.Days}</span>`;
        }
    }

    ngOnDestroy() {
        if (this.optinExpiryTimeOut) {
            clearTimeout(this.optinExpiryTimeOut);
        }
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
        if (this.forceOptinSubscription) {
            this.forceOptinSubscription.unsubscribe();
        }
        this.forceCoinBalanceSubscription?.unsubscribe();
        if (this.optInSubscription) {
            this.optInSubscription.unsubscribe();
        }
        if (this.optInWithCoinsSubscription) {
            this.optInWithCoinsSubscription.unsubscribe();
        }
        if (this.dataUpdateSubscription) {
            this.dataUpdateSubscription.unsubscribe();
        }
        if (this.notificationSubscription) {
            this.notificationSubscription.unsubscribe();
        }
        if (this.notificationStatusSubcription) {
            this.notificationStatusSubcription.unsubscribe();
        }
        this.destroy$.complete();
    }

    ctaAction(): void {
        this.messageQueue.clear();

        if (this.race.ctaCollection.indexOf('OPTIN') > -1) {
            if (this.race?.isOptinWithCoins) {
                this.slotRacesTracking.track(
                    'click',
                    this.race.gameId,
                    this.activeCategory,
                    this.messages?.OptinWithText ? this.messages?.OptinWithText + ' coin cta' : 'Optin With coin cta',
                );
            } else {
                const ctaText = this.getCTATextBasedOnRace(this.race);
                this.slotRacesTracking.track('click', this.race.gameId, this.activeCategory, ctaText + ' cta');
            }

            if (!this.user.isAuthenticated) {
                const race: any = this.race;
                if (race?.isOptinWithCoins) {
                    sessionStorage.setItem('forceCoinBalance', JSON.stringify(race));
                } else {
                    sessionStorage.setItem('ForceOptin', JSON.stringify(race));
                }
                this.loginDialog.whenReady.pipe(takeUntil(this.destroy$)).subscribe(() => {
                    const loginDialogRef = this.loginDialog.open({ loginMessageKey: 'OptInPromotion' });
                    const afterclosesubcription: Subscription = loginDialogRef.afterClosed().subscribe(() => {
                        afterclosesubcription?.unsubscribe();
                        if (!this.user.isAuthenticated) {
                            sessionStorage.removeItem('forceCoinBalance');
                            sessionStorage.removeItem('ForceOptin');
                        }
                    });
                });

                if (!this.showRaceInfo) {
                    this.closeRaceInfo.emit();
                }
            } else {
                if (this.race?.isOptinWithCoins) {
                    if (!this.showCoinBalancePopup) {
                        this.checkCoinBalace();
                    }
                } else {
                    this.optin();
                }
            }
        } else {
            if (this.race && this.race.gameVariants && this.race.gameVariants.length > 1 && !this.fromPickGameOverlay) {
                this.emitPickGameOverlay('cta');
            } else {
                this.launchGame();
            }
        }
    }

    getCTATextBasedOnRace(race: any) {
        if (race.subType && race.subType.toLowerCase() === 'free_play_tech' && race.optinLimit !== 0 && this.playerCount === race.optinLimit) {
            return this.messages.OptinLimitReached;
        }
        if (race.subType && (race.subType.toLowerCase() === 'free_to_play' || race.subType.toLowerCase() === 'free_play_tech')) {
            return this.messages.Optin4Free;
        } else {
            return this.messages.Optin;
        }
    }
    closePopup(): void {
        this.showToasterMessage = false;
    }

    checkCoinBalace() {
        this.showCoinBalancePopup = true;
        if (this.race.isOptinWithCoins) {
            this.slotRacesService.GetCoinBalnce().subscribe(
                (coinBalRes: any) => {
                    this.showConfirmToaster(coinBalRes);
                },
                () => {
                    this.showCoinBalancePopup = false;
                },
            );
        }
    }

    showConfirmToaster(coinBalRes: any) {
        if (this?.race?.coinsForOptin <= coinBalRes?.balance) {
            this.openConfirmToasterDialog(true);
        } else {
            this.showCoinBalancePopup = false;
            this.openConfirmToasterDialog(false);
        }
    }

    openConfirmToasterDialog(showConfirmPopup: boolean): void {
        const requiredModalData = {
            messages: this.messages,
            showConfirmPopup: showConfirmPopup,
        };
        if (showConfirmPopup) {
            this.slotRacesTracking.track('load', 'optin with coin', this.activeCategory + ',' + this.race.gameId, this.messages?.Purchase);
        } else {
            this.slotRacesTracking.track('load', 'optin with coin', this.activeCategory + ',' + this.race.gameId, 'oops! toaster popup');
        }

        const dialogRef = this.dialog.open(BingoCoinEconomyConfirmPopupComponent, {
            data: requiredModalData,
            panelClass: 'casino-sr-confirm-toaster',
        });

        dialogRef.afterClosed().subscribe((event: any) => {
            this.showCoinBalancePopup = false;
            this.confirmPopupTracking(event?.type);
            if (event?.type == 'confirm') {
                this.optin(true);
            } else {
                this.slotRacesService.forceCoinBalancepublisher.next({});
            }
        });
    }

    confirmPopupTracking(ctaType: string) {
        if (ctaType == 'confirm') {
            this.slotRacesTracking.track('click', 'optin with coin', this.activeCategory + ',' + this.race.gameId, this.messages?.Confrim + ' cta');
        } else if (ctaType == 'close') {
            this.slotRacesTracking.track('close', 'optin with coin', this.activeCategory + ',' + this.race.gameId, 'confirmation popup');
        } else if (ctaType == 'cancel') {
            this.slotRacesTracking.track('click', 'optin with coin', this.activeCategory + ',' + this.race.gameId, this.messages?.Cancel + ' cta');
        } else if (ctaType == 'toasterClose') {
            this.slotRacesTracking.track('close', 'optin with coin', this.activeCategory + ',' + this.race.gameId, 'oops! toaster popup');
        }
    }

    launchGame(): void {
        this.slotRacesService.LaunchBingoGame(this.race);
    }

    forceOptin(): void {
        this.forceOptinSubscription = this.slotRacesService.forceRace.subscribe((response: { race: Race }) => {
            if (response.race && response.race.promoId == this.race.promoId && response.race.slotUniqueId == this.race.slotUniqueId) {
                const index = this.race.ctaCollection.findIndex((i) => i === 'OPTIN');
                if (!this.race.isLive && this.enableRaceNotification && !this.race.isNotify) {
                    this.openNotificationOverlay.emit(this.race);
                }
                if (index > -1) {
                    this.race.ctaCollection.splice(index, 1);
                }
                this.slotRacesService.forcRacepublisher.next({});
            }
        });
    }

    forceCoinRaceBalance(): void {
        this.forceCoinBalanceSubscription = this.slotRacesService.forceCoinBalanceObs.subscribe((coinBalRes: any) => {
            if (
                coinBalRes &&
                coinBalRes.race &&
                coinBalRes.race.promoId == this.race.promoId &&
                coinBalRes.race.slotUniqueId == this.race.slotUniqueId &&
                coinBalRes?.coinBal?.balance
            ) {
                this.showConfirmToaster(coinBalRes.coinBal);
            }
        });
    }
    addOrremoveCollapseClass() {
        if (this.expandCard) {
            this.slotRacesTracking.track(
                'Collapse (-) - CTA Clicked',
                this.race.gameId,
                this.activeCategory,
                'User clicked on Collapse(-) to see the slot race details',
            );
        } else {
            this.slotRacesTracking.track(
                'Expand (+) - CTA Clicked',
                this.race.gameId,
                this.activeCategory,
                'User clicked on Expand(+) to see the slot race details',
            );
        }
        this.expandCard = !this.expandCard;
    }

    optin(coinBalanceOptin: boolean = false): void {
        this.optInSubscription = this.slotRacesService
            .updateOptinStatus(this.race.promoId, this.race.slotUniqueId, this.race.coinsForOptin)
            .subscribe(
                () => {
                    const index = this.race.ctaCollection.findIndex((i) => i === 'OPTIN');
                    this.race.ctaCollection.splice(index, 1);
                    if (!this.race.isLive && this.enableRaceNotification && !this.race.isNotify) {
                        this.openNotificationOverlay.emit(this.race);
                    }
                    if (coinBalanceOptin) {
                        this.slotRacesService.forceCoinBalancepublisher.next({});
                    }
                },
                (error: SlotRacesResponseError) => {
                    if (coinBalanceOptin) {
                        this.slotRacesService.forceCoinBalancepublisher.next({});
                    }
                    this.slotRacesService.addErrorMessage(error.errorCode);
                    this.closePickGameOverlay.emit({ errorCode: error.errorCode });
                    this.closeRaceInfo.emit({ errorCode: error.errorCode });
                },
            );
    }

    // optin expiry
    setOptinExpiryTimeOut(): void {
        if (this.race.ctaCollection.indexOf('OPTIN_EXPIRED') < 0) {
            const expiryTime: number = this.race && this.race.optinExpiryDate && this.race.optinExpiryDate.getTime();
            if (expiryTime && expiryTime < this.race.endDate.getTime() && expiryTime > Date.now()) {
                this.optinExpiryTimeOut = setTimeout(() => {
                    const optinIndex = this.race.ctaCollection.indexOf('OPTIN');
                    if (optinIndex > -1) {
                        this.race.ctaCollection.splice(optinIndex, 1, 'OPTIN_EXPIRED');
                    }
                }, expiryTime - Date.now());
            } else if (expiryTime && expiryTime < Date.now()) {
                const index = this.race.ctaCollection.indexOf('OPTIN');
                if (index > -1) {
                    this.race.ctaCollection.splice(index, 1, 'OPTIN_EXPIRED');
                }
            }
        } else {
            if (this.optinExpiryTimeOut) {
                clearTimeout(this.optinExpiryTimeOut);
            }
        }
    }

    initializeTimerCountdown(): void {
        if (this.race.isLive) {
            this.timeSpan = this.race.endDate.getTime() - Date.now();
            if (this.timeSpan == 0) {
                this.race.isLive = false;
                this.slotRacesService.updateRacesArrays();
            }
        } else {
            this.timeSpan = this.race.startDate.getTime() - Date.now();
        }
        this.progressValue = Date.now() - this.race.startDate.getTime();
        this.timerSubscription = this.slotRacesService.globalTimerObservable.subscribe((response: any) => {
            if (this.timeSpan > 1000) {
                if (response && response.timerInterval) {
                    if (this.race.isLive) {
                        this.timeSpan = this.race.endDate.getTime() - Date.now();
                        if (this.timeSpan > this.millisec) {
                            this.Days = this.timeSpan / (1000 * 60 * 60 * 24);
                            this.Days = Math.floor(this.Days);
                        }
                    } else {
                        this.timeSpan = this.race.startDate.getTime() - Date.now();
                    }
                    this.progressValue = Date.now() - this.race.startDate.getTime();
                }
            } else {
                this.race.isLive = false;
                if (this.timerSubscription) {
                    this.timerSubscription.unsubscribe();
                }
                this.slotRacesService.updateRacesArrays();
            }
        });
    }

    updateCardSitecoreData(): void {
        this.bottombackground = this.messages.CardBottomColor ? this.messages.CardBottomColor : '';
        this.promobackgroundimage = this.race?.content?.promotion?.promoBackgroundImage?.src ?? '';
        this.promobackgroundcolor = this.race?.content?.promotion?.promoBackgroundColor ?? '';
        this.cardbackgroundimage = this.messages?.CardBackgroundImage ?? '';
        this.cardbackgroundcolor = this.messages?.CardBackgroundColor ?? '';
        this.cardTextColor = this.messages.CardTextColor ? this.messages.CardTextColor : '';
        if (this.promobackgroundimage) {
            this.showimage = true;
            this.backgroundImageSrc = this.promobackgroundimage;
        } else if (this.promobackgroundcolor) {
            this.showimage = false;
        } else if (this.cardbackgroundimage) {
            this.showimage = true;
            this.backgroundImageSrc = this.cardbackgroundimage;
        } else if (this.cardbackgroundcolor) {
            this.showimage = false;
        } else {
            this.showimage = true;
        }
    }

    emitRaceInfo() {
        this.slotRacesTracking.track('click', this.race.gameId, this.activeCategory, 'info overlay');
        this.openRaceInfo.emit(this.race);
    }

    hideRaceCard(): void {
        this.hideCard.emit();
    }

    subscribeToDataUpdates(): void {
        this.dataUpdateSubscription = this.slotRacesService.liveRacesData.subscribe((liveRaces: SlotRacesLive) => {
            if (liveRaces && liveRaces.liveNow && liveRaces.liveNow.length) {
                const updatedRace: Race = liveRaces.liveNow.find(
                    (r) => r.promoId === this.race.promoId && r.slotUniqueId === this.race.slotUniqueId,
                )!;
                if (updatedRace) {
                    this.isprogressBarEnabled = this.race.isLive && this.user.isAuthenticated && this.race.ctaCollection.indexOf('OPTIN_EXPIRED') < 0;
                    this.playerCount = updatedRace.targetedPlayerCount;
                    this.currentPoints = parseFloat(updatedRace.beatBanker?.currPoints);
                    this.spinCount =
                        updatedRace.spinProgress && updatedRace.spinProgress?.total && updatedRace.spinProgress?.current > 0
                            ? updatedRace.spinProgress.total - updatedRace.spinProgress.current
                            : updatedRace.spinProgress?.total;
                    this.race.ctaCollection = updatedRace.ctaCollection;
                }
            }
        });
    }

    onNotificationClick(status: boolean) {
        this.notificationSubscription = this.slotRacesService.updateNotificationStatus(this.race.promoId, this.race.slotUniqueId, status).subscribe(
            (response: any) => {
                if (response) {
                    this.race.notificationStatus = status;
                    this.slotRacesService.setNotifyStatus(this.race.promoId, this.race.slotUniqueId, status);
                    this.openDialog(this.race.notificationStatus);
                }
            },
            (error: SlotRacesResponseError) => {
                this.slotRacesService.addErrorMessage(error.errorCode);
            },
        );
    }
    openDialog(dialogResult: any): void {
        const requiredModalData = {
            messages: this.messages,
            status: dialogResult,
        };
        const dialogRef = this.dialog.open(BingoNotificationPopUpComponent, {
            width: '790px',
            data: requiredModalData,
            panelClass: 'casino-sr-notification-toaster',
        });

        dialogRef.afterClosed().subscribe(() => {
            // eslint-disable-next-line no-console
            console.log('The dialog was closed');
        });
    }

    emitPickGameOverlay(actionLocation: string = 'promo icon') {
        this.slotRacesTracking.track(
            `Pick Game - ${actionLocation} clicked`,
            this.race && this.race.content && this.race.content.promotion && this.race.content.promotion.promoName,
            this.activeCategory,
            `User opened the pick-game overlay from the ${actionLocation} for the Slots Race`,
        );
        this.openPickGameOverlay.emit(this.race);
    }
}
