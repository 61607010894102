import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';

import { BingoTournamentIconModule } from '../bingo-tournament-icon/bingo-tournament-icon.module';
import { BingoSlotRaceCardModule } from '../cards-wrapper/card/bingo-card.module';
import { BingoPickGameOverlayComponent } from './bingo-pick-game-overlay.component';

@NgModule({
    imports: [CommonModule, BingoSlotRaceCardModule, BingoTournamentIconModule, MatGridListModule],
    declarations: [BingoPickGameOverlayComponent],
    exports: [BingoPickGameOverlayComponent],
})
export class BingoPickGameOverlayModule {
    public static forRoot(): ModuleWithProviders<BingoPickGameOverlayModule> {
        return {
            ngModule: BingoPickGameOverlayModule,
        };
    }
}
