import { LowerCasePipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { IGamingStoryItem } from '@casinocore/platform/core';

@Component({
    selector: 'cc-gaming-story-cta',
    templateUrl: 'gaming-story-cta.component.html',
    standalone: true,
    imports: [NgIf, NgClass, NgStyle, LowerCasePipe],
})
export class GamingStoryCtaComponent implements OnInit {
    @Input() storyItem: IGamingStoryItem;

    subTitleTxtClr: string;
    subTitleFontSize: string;
    OptinBtnBgClr: string;
    OptinBtnTxtClr: string;
    descriptionClr: string;
    descriptionFontSize: string;
    customCTAUrl: string;
    customCTAText: string;
    actionTypePosition: string;
    titlePosition: string;
    descriptionPosition: string;
    subTitlePosition: string;

    constructor() {}

    ngOnInit(): void {
        //console.log(this.storyItem, "storyItem");
        if (this.storyItem && this.storyItem.customCTA) {
            this.customCTAText = this.storyItem.customCTA.text;
            this.customCTAUrl = this.storyItem.customCTA.url;
            this.subTitleTxtClr = this.storyItem.configurations.subTitleTxtClr;
            this.subTitleFontSize = this.storyItem.configurations.subTitleFontSize;
            this.descriptionClr = this.storyItem.configurations.descriptionClr;
            this.OptinBtnBgClr = this.storyItem.configurations.OptinBtnBgClr;
            this.OptinBtnTxtClr = this.storyItem.configurations.OptinBtnTxtClr;
            this.actionTypePosition = this.storyItem.actionTypePosition;
            this.titlePosition = this.storyItem.titlePosition;
            this.descriptionPosition = this.storyItem.descriptionPosition;
            this.subTitlePosition = this.storyItem.subTitlePosition;
            this.descriptionFontSize = this.storyItem.configurations.descriptionFontSize;
        }
    }
}
