import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({ key: 'bgBingoModularConfiguration', product: ClientConfigProductName.BINGO })
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: bingoModularConfigurationClientConfigFactory,
})
export class BingoModularConfigurationClientConfig {
    productMap: any;
}
export function bingoModularConfigurationClientConfigFactory(service: LazyClientConfigService) {
    return service.get(BingoModularConfigurationClientConfig);
}
