import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';

import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';

import { SharedModule } from '../shared/shared.module';
import { BingoSlotRacesLeaderboardComponent } from './bingo-leaderboard.component';

@NgModule({
    imports: [CommonModule, TrustAsHtmlPipe, MatTableModule, SharedModule],
    declarations: [BingoSlotRacesLeaderboardComponent],
    exports: [BingoSlotRacesLeaderboardComponent],
})
export class BingoSlotRacesLeaderboardModule {
    public static forRoot(): ModuleWithProviders<BingoSlotRacesLeaderboardModule> {
        return {
            ngModule: BingoSlotRacesLeaderboardModule,
        };
    }
}
